var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          [
            _c("el-lable", { staticStyle: { color: "#808080" } }, [
              _vm._v("Attribute Lable Name*(属性标题)"),
            ]),
            _vm._v(" "),
            _c("el-autocomplete", {
              attrs: {
                "fetch-suggestions": _vm.querySearchAsync,
                placeholder: "请输入内容",
                clearable: "",
              },
              on: { select: _vm.handleSelect },
              model: {
                value: _vm.attr.name,
                callback: function ($$v) {
                  _vm.$set(_vm.attr, "name", $$v)
                },
                expression: "attr.name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { disabled: _vm.checkPermission("attributeGroup.save") },
                on: { click: _vm.handleSave },
              },
              [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.handleCancel } },
              [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "Type*(选择方式)" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.multpleSelectInd,
                        callback: function ($$v) {
                          _vm.multpleSelectInd = $$v
                        },
                        expression: "multpleSelectInd",
                      },
                    },
                    [_vm._v("单选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.multpleSelectInd,
                        callback: function ($$v) {
                          _vm.multpleSelectInd = $$v
                        },
                        expression: "multpleSelectInd",
                      },
                    },
                    [_vm._v("多选")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用于变化类型" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.isVariable,
                        callback: function ($$v) {
                          _vm.isVariable = $$v
                        },
                        expression: "isVariable",
                      },
                    },
                    [_vm._v("NO")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.isVariable,
                        callback: function ($$v) {
                          _vm.isVariable = $$v
                        },
                        expression: "isVariable",
                      },
                    },
                    [_vm._v("YES")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "required*(是否必填)" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.mandatoryInd,
                        callback: function ($$v) {
                          _vm.mandatoryInd = $$v
                        },
                        expression: "mandatoryInd",
                      },
                    },
                    [_vm._v("NO")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.mandatoryInd,
                        callback: function ($$v) {
                          _vm.mandatoryInd = $$v
                        },
                        expression: "mandatoryInd",
                      },
                    },
                    [_vm._v("YES")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.attr.attrValues },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "Seq(顺序)", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "attrValues." + scope.$index + ".seqNo",
                                },
                              },
                              [
                                scope.row.edit
                                  ? _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: { clearable: "" },
                                      model: {
                                        value: scope.row.seqNo,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "seqNo", $$v)
                                        },
                                        expression: "scope.row.seqNo",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.seqNo)),
                                    ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Attribute name(属性名称)", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              [
                                scope.row.edit
                                  ? _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      model: {
                                        value: scope.row.name,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "name", $$v)
                                        },
                                        expression: "scope.row.name",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.name)),
                                    ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-button", {
                              attrs: { icon: "el-icon-delete", circle: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.delRow(scope.$index)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("el-button", {
                              attrs: { icon: "el-icon-edit", circle: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.editRow(scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-button", {
                attrs: { icon: "el-icon-circle-plus" },
                on: {
                  click: function ($event) {
                    return _vm.addLanguage()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-titless" }, [
      _c("p", { staticClass: "text-title" }, [_vm._v("Attribute list")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }