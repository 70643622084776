<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="170px"
        >
          <el-row :gutter="20">
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('Admin Tag')">
                <el-input
                  v-model="query.adminTag"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t(productNameLabel)">
                <el-input
                  v-model="query.name"
                  :placeholder="$t('COMPONENTS.incontent')"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('Category/Subcategory')">
                <el-cascader
                  v-model="query.category"
                  :options="temp.categoryOptions"
                  :props="props"
                  :placeholder="$t('COMPONENTS.inselect')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.tag')">
                <el-select
                  v-model="query.tag"
                  :placeholder="$t('COMPONENTS.inselect')"
                >
                  <el-option label="" value="">{{
                    $t("COMPONENTS.inselect")
                  }}</el-option>
                  <el-option
                    v-for="item in temp.tagOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.label')">
                <el-input
                  v-model="query.label"
                  :placeholder="$t('COMPONENTS.incontent')"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.price')">
                <el-input
                  v-model="query.minPrice"
                  placeholder=""
                  style="width: 40%"
                />
                ~
                <el-input
                  v-model="query.maxPrice"
                  placeholder=""
                  style="width: 40%"
                />
              </el-form-item>
            </el-col>

            <!--
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.visible')">
                <el-select v-model="query.visible" :placeholder="$t('COMPONENTS.inselect')">
                  <el-option label="" value="">{{ $t('COMPONENTS.inselect') }}</el-option>
                  <el-option :label="$t('COMPONENTS.true')" value="true">{{ $t('COMPONENTS.true') }}</el-option>
                  <el-option :label="$t('COMPONENTS.false')" value="false">{{ $t('COMPONENTS.false') }}</el-option>
                </el-select>
              </el-form-item>
            </el-col>
 -->
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.shop')">
                <el-select
                  v-model="query.shop"
                  :placeholder="$t('COMPONENTS.inselect')"
                >
                  <el-option label="" value="">{{
                    $t("COMPONENTS.inselect")
                  }}</el-option>
                  <el-option
                    v-for="item in temp.shopOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.brand')">
                <el-select
                  v-model="query.brand"
                  :placeholder="$t('COMPONENTS.inselect')"
                >
                  <el-option label="" value="">{{
                    $t("COMPONENTS.inselect")
                  }}</el-option>
                  <el-option
                    v-for="item in temp.brandList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <!--
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.language')">
                <el-select v-model="query.language" :placeholder="$t('COMPONENTS.inselect')">
                  <el-option label="" value="">{{ $t('COMPONENTS.inselect') }}</el-option>
                  <el-option
                    v-for="item in temp.languageList"
                    :key="item.language"
                    :label="item.title"
                    :value="item.language"
                  />
                </el-select>
              </el-form-item>
            </el-col>
 -->

            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.status')">
                <el-select
                  v-model="query.itemStatusForSearch"
                  :placeholder="$t('COMPONENTS.inselect')"
                  multiple
                  clearable
                >
                  <el-option
                    v-for="item in temp.itemStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('Created By')">
                <el-input
                  v-model="query.createdBy"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>

            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('New Version Release Status')">
                <el-select
                  v-model="query.publicStatus"
                  :placeholder="$t('COMPONENTS.inselect')"
                  clearable
                >
                  <el-option :label="$t('Published')" value="HAS_PUBLISHED" />
                  <el-option :label="$t('Published (Already the latest version)')" value="LAST_VERSION_HAS_PUBLISHED" />
                  <el-option :label="$t('Published (There is a new version)')" value="LAST_VERSION_NOT_PUBLISHED" />
                  <el-option :label="$t('Not yet published')" value="NOT_YET_PUBLISHED" />

                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row :gutter="20">
            <el-col v-show="false" :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.hot')">
                <el-radio v-model="query.hot" label="true">{{
                  $t("COMPONENTS.true")
                }}</el-radio>
                <el-radio v-model="query.hot" label="false">{{
                  $t("COMPONENTS.false")
                }}</el-radio>
              </el-form-item>
            </el-col>
            <el-col v-show="false" :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.store')">
                <el-radio v-model="query.qtyStatus" label="true">{{
                  $t("COMPONENTS.have")
                }}</el-radio>
                <el-radio v-model="query.qtyStatus" label="false">{{
                  $t("COMPONENTS.without")
                }}</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <div style="float: right;">
              <el-button
                :disabled="checkPermission('SHOP_PRODUCT.ADD')"
                @click="addProduct"
              >{{ $t("COMPONENTS.add") }}</el-button>
              <el-button
                type="primary"
                :disabled="checkPermission('SHOP_PRODUCT.LIST')"
                @click="searchBtnJob"
              >
                {{ $t("COMPONENTS.search") }}
              </el-button>
            </div>
          </el-row>
        </el-form>
      </div>
    </transition>
    <el-table
      :data="data"
      tooltip-effect="dark"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
    >
      <!-- <el-table-column type="selection" width="50px" align="center" /> -->
      <el-table-column
        :label="$t('Admin Tag')"
        prop="adminTag"
        width="150px"
        align="left"
      />
      <el-table-column
        :label="$t(productNameLabel)"
        prop="name"
        width="150px"
        align="left"
      />

      <el-table-column :label="$t('New Version Release Status')" width="180px" align="left">
        <template slot-scope="{ row }">
          <span v-if="row.publishStatus==='HAS_PUBLISHED'">{{ $t('Published') }}</span>
          <span v-if="row.publishStatus==='LAST_VERSION_HAS_PUBLISHED'">{{ $t('Published (Already the latest version)') }}</span>
          <span v-if="row.publishStatus==='LAST_VERSION_NOT_PUBLISHED'" style="color: red;">{{ $t('Published (There is a new version)') }}</span>
          <span v-if="row.publishStatus==='NOT_YET_PUBLISHED'">{{ $t('Not yet published') }}</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('productItem.img')"
        prop="imgUrl"
        width="120px"
        align="left"
      >
        <template slot-scope="{ row }">
          <el-image :src="row.imgUrl" />
          <!-- <img :src="row.imgUrl" :alt="row.name" width="100%" v-else> -->
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('productItem.shop')"
        prop="shopName"
        width="110px"
        align="left"
      />
      <el-table-column
        :label="$t('productItem.shopStruct')"
        prop="shopStruct"
        width="110px"
        align="left"
      />
      <el-table-column
        :label="$t('productItem.price')"
        prop="priceStr"
        width="100px"
        align="left"
      />

      <!--
      <el-table-column :label="$t('productItem.visible')" prop="visible" width="150px" align="left">
        <template slot-scope="{ row }">{{
          row.visible ? "是" : "否"
        }}</template>
      </el-table-column>

       -->

      <!--
      <el-table-column :label="$t('productItem.status')" prop="status" width="150px" align="left">
        <template slot-scope="{ row }">{{
          row.status === "DRAFT" ? "启用" : "禁用"
        }}</template>
      </el-table-column>
 -->

      <el-table-column
        :label="$t('productItem.status')"
        prop="itemStatus"
        width="150px"
        align="left"
      />
      <el-table-column
        :label="$t('Total Views')"
        prop="pageRefreshCount"
        width="85px"
        align="left"
      />
      <el-table-column
        :label="$t('Category/Subcategory')"
        prop="category"
        width="150px"
        align="left"
      />
      <el-table-column
        :label="$t('productItem.tag')"
        prop="tag"
        width="150px"
        align="left"
      />
      <el-table-column
        :label="$t('productItem.hot')"
        prop="hot"
        width="80px"
        align="left"
      >
        <template slot-scope="{ row }">{{ row.hot ? "是" : "否" }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('productItem.lastModifiedDate')"
        prop="lastModifiedDate"
        width="150px"
        align="left"
      />
      <el-table-column
        :label="$t('productItem.skuStatus')"
        prop="visible"
        width="150px"
        align="left"
      >
        <template slot-scope="{ row }">
          <span v-if="row.qtyStatus">有库存</span>
          <span v-if="!row.qtyStatus">没有库存</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('Created By')"
        prop="createdBy"
        width="150px"
        align="left"
      />
      <el-table-column
        :label="$t('Last Modified By')"
        prop="lastModifiedBy"
        width="150px"
        align="left"
      />

      <el-table-column
        :label="$t('COMPONENTS.operation')"
        prop="name"
        width="180px"
        align="left"
        fixed="right"
      >
        <template slot-scope="scope">
          <span v-if="scope !== null" />
          <!-- FIX error  'scope' is defined but never used -->
          <el-button
            :key="1"
            size="mini"
            type="primary"
            :disabled="checkPermission('SHOP_PRODUCT.EDIT')"
            @click.prevent="handleEdit(scope.row)"
          >{{ $t("COMPONENTS.edit") }}</el-button>
          <el-button
            :key="2"
            size="mini"
            :disabled="checkPermission('SHOP_PRODUCT.DELETE')"
            @click.prevent="handleDelete(scope.row)"
          >{{ $t("COMPONENTS.delete") }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="query.page"
      :limit.sync="query.size"
      :style="pageStyle"
      layout="total, sizes, prev, pager, next, jumper"
      @pagination="loadData"
    /> -->

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="query.page"
      :limit.sync="query.size"
      :style="pageStyle"
      layout="total, sizes, prev, pager, next, jumper"
      @pagination="searchJob"
    />

  </div>
</template>

<script>
import {
  searchList,
  deleteProduct,
  tagQuery,
  shopList,
  brandQuery
} from '@/api/productItem'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import Pagination from '@/components/Pagination'
// import demoData from './demoData'
import { getPageParm } from '@/utils/page-common'
import { getLanguage } from '@/lang/index'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import { fetchLanguageList } from '@/api/languageApi'
import { checkCfsPermission } from '@/utils/permission'
import { getLan } from '@/utils/productMenuFunction'
import { getCfsTreeList } from '@/api/cfsApi/cfsTreeLIstApi'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      allProductStatus: [],
      props: {
        multiple: true,
        checkStrictly: true,
        label: 'title',
        value: 'itemId'
      },
      total: 0,
      pageStyle: {
        margin: '0px',
        padding: '5px'
      },
      productNameLabel: 'productItem.productName', // 商品名稱
      // productNameLabel: 'Recipe Name', // 食譜名稱
      query: {
        goodsSellType: 'SIMPLE-ITEM',
        size: 10,
        page: 1,
        name: '',
        imgUrl: '',
        status: '',
        minPrice: '',
        maxPrice: '',
        price: '',
        category: [],
        tag: '',
        hot: '',
        qtyStatus: '',
        visible: '',
        lastModifiedDate: '',
        shop: '',
        label: '',
        brand: '',
        createdBy: null,
        publicStatus: 'HAS_PUBLISHED',
        itemStatusForSearch: [],
        categorySearchScope: []
      },
      data: [],
      temp: {
        categoryOptions: [],
        tagOptions: [],
        shopOptions: [],
        brandList: [],
        languageLIst: [],
        itemStatusList: []
      },
      allowDefaultCategory: false,
      functionSetting: null
    }
  },
  mounted() {
    this.checkRouterSetting()
    this.loadData()
  },
  methods: {
    checkRouterSetting() {
      // 路由有category參數時執行此操作
      if (this.$parent) {
        if (this.$parent.$route) {
          if (this.$parent.$route.meta) {
            if (this.$parent.$route.meta.functionSetting) {
              const functionSetting = this.$parent.$route.meta.functionSetting
              console.log('把路由配置的設定，一起抓過來放到functionSetting', functionSetting)
              this.functionSetting = functionSetting

              // 一些label的替換
              this.productNameLabel = getLan(functionSetting.label_2)

              // category設定
              if (functionSetting.categorys) {
                this.allowDefaultCategory = true
              }
            }
          }
        }
      }
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    processData(list) {
      list &&
        list.map((item) => {
          item.title = item.treeItemTag.name
          item.label = item.treeItemTag.name
          item.key = item.id
          item.itemId = item.id
          if (item.children && item.children.length > 0) {
            this.processData(item.children)
          }
        })
      console.log('category list', list)
      return list
    },
    disabledCategoryItem(list, isParentDisabled) {
      list &&
        list.map((item) => {
          if (this.allowDefaultCategory) {
            if (isParentDisabled === undefined) {
              item.disabled = !this.functionSetting.categorys.includes(item.id)
            } else {
              item.disabled = isParentDisabled
            }
            if (!item.disabled) {
              if (!this.query.categorySearchScope.includes(item.id)) { this.query.categorySearchScope.push(item.id) }
            }

            if (item.children && item.children.length > 0) {
              this.disabledCategoryItem(item.children, item.disabled)
            }
          }
        })

      return list
    },
    getStaticSourcePath,
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    loadData() {
      // 把路由配置的設定，一起抓過來放到meta

      var numReg = /^\d+(\.\d+)?$/
      var numRe = new RegExp(numReg)
      if (this.query.minPrice !== null && this.query.minPrice.length > 0) {
        if (!numRe.test(this.query.minPrice)) {
          this.$message(this.$t('rule.checkNumber'))
          return false
        }
      }

      if (this.query.maxPrice !== null && this.query.maxPrice.length > 0) {
        if (!numRe.test(this.query.maxPrice)) {
          this.$message(this.$t('rule.checkNumber'))
          return false
        }
      }

      const query = { ...this.query }
      // var param = getPageParm(query)

      //  廚師在後台只能查自己建立的產品
      //  CFS那邊有兩個權限
      //  SEARCH_ALL_PRODUCT
      //  SEARCH_OWN_PRODUCT

      query.createdBy = 'NA'

      // 只能搜尋自建立的產品
      const searchOwn = !checkCfsPermission('SEARCH_OWN_PRODUCT')
      if (searchOwn) {
        const loginName = localStorage.getItem('user_profile')
        query.createdBy = loginName
      }

      // 可以搜尋所有人的產品
      const searchAll = !checkCfsPermission('SEARCH_ALL_PRODUCT')
      if (searchAll) {
        query.createdBy = null
      }
      query.language = localStorage.getItem('languageCode')

      var permissionList = []
      permissionList.push(
        getCfsDomainData({ domainCode: 'SHOP_PRODUCT_STATUS' })
      )
      permissionList.push(shopList())
      permissionList.push(fetchLanguageList())
      permissionList.push(
        getCfsTreeList({ belongId: 'DEFAULT', usageTypeCode: 'category' })
      )
      permissionList.push(brandQuery())
      permissionList.push(tagQuery())

      this.query = query

      Promise.all(permissionList).then((resp) => {
        console.log('Promise >>>>>', resp)

        // ////////////////////////////////////////
        let allProductStatus = []
        if (resp[0]) {
          allProductStatus = resp[0].data
          this.temp.itemStatusList = []
          allProductStatus.map((data, i) => {
            this.temp.itemStatusList.push({
              value: data.domainDataCode,
              label:
                this.translateCfsValue(data) + ' (' + data.domainDataCode + ')'
            })
          })
          console.log('拿產品的狀態>', allProductStatus)

          this.allProductStatus = allProductStatus
        }

        // ////////////////////////////////////////

        if (resp[1] && resp[1].data) {
          const shopData = resp[1].data
          const _shopData = []
          let shopStr = ''
          for (let i = 0; i < shopData.length; i++) {
            const d = shopData[i]
            if (i === 0) {
              shopStr = d.id
            } else {
              shopStr += ',' + d.id
            }
            _shopData.push({
              value: d.id,
              label: d.name
            })
          }
          this.temp.shopAll = shopStr
          this.temp.shopOptions = _shopData
          console.log('拿商店>', _shopData)
        }

        // ////////////////////////////////////////
        if (resp[2] && resp[2].data) {
          const lanData = resp[2].data
          if (lanData && lanData.supportLanguageList) {
            this.temp.languageList = lanData.supportLanguageList || []
            console.log('拿語言>', lanData.supportLanguageList)
          }
        }

        // ///////////////////////////////////////
        let categoryData = []
        if (resp[3] && resp[3].data) {
          categoryData = resp[3].data
          categoryData = this.processData(categoryData)
          categoryData = this.disabledCategoryItem(categoryData)

          this.temp.categoryOptions = categoryData
          console.log('拿category2>', categoryData)
        }

        // ///////////////////////////////////////
        if (resp[4] && resp[4].data) {
          const brandData = resp[4].data
          const _brandData = []
          if (brandData !== null) {
            for (var i = 0; i < brandData.length; i++) {
              var obj = brandData[i]
              _brandData.push({
                value: obj.id,
                label: obj.name
              })
            }
          }
          this.temp.brandList = _brandData
          console.log('拿brand>', _brandData)
        }

        // ///////////////////////////////////////
        if (resp[5] && resp[5].data) {
          const tagData = resp[5].data
          const _tagData = []
          for (let i = 0; i < tagData.length; i++) {
            const obj = tagData[i]
            _tagData.push({
              value: obj.id,
              label: obj.tagName
            })
          }
          this.temp.tagOptions = _tagData
          console.log('拿tag>', _tagData)
        }

        // ///////////////////////////////////////

        this.searchJob()
      })
    },

    searchBtnJob() {
      this.query.page = 1
      this.searchJob()
    },

    searchJob() {
      const query = { ...this.query }
      var param = getPageParm(query)

      console.log('shtvrdjybrhyv query', query)

      console.log('shtvrdjybrhyv param', param)

      searchList(param, this.query).then(qureyRes => {
        if (qureyRes) {
          console.log('sssssssssssssssss', qureyRes.data)
          var data = qureyRes.data
          this.total = qureyRes.pageCount
          this.query.page = qureyRes.pageNum
          this.query.size = qureyRes.pageSize
          var resultData = []
          if (data !== null) {
            for (let i = 0; i < data.length; i++) {
              var d = data[i]
              var shopId = d.shopId
              var shopStruct = this.$t('productItem.SIMPLE-ITEM')

              if (d.shopStruct === 'VARIABLE-ITEM') {
                shopStruct = this.$t('productItem.VARIABLE-ITEM')
              }
              if (d.shopStruct === 'MATCH-ITEM') {
                shopStruct = this.$t('productItem.Match-ITEM')
              }

              var shopName = ''
              for (var j = 0; j < this.temp.shopOptions.length; j++) {
                var item = this.temp.shopOptions[j]
                if (item.value === shopId) {
                  shopName = item.label
                }
              }

              this.allProductStatus.map((data, i) => {
                if (data.domainDataCode === d.itemStatus) {
                  d.itemStatus =
                  this.translateCfsValue(data) +
                  ' (' +
                  data.domainDataCode +
                  ')'
                }
              })

              resultData.push({
                publishStatus: d.publishStatus,
                baseProductId: d.baseProductId,
                name: d.name,
                // imgUrl: d.apiUrl + d.imgUrl,
                imgUrl: d.mediaUrl,
                shopName: shopName,
                shopStruct: shopStruct,
                // status: d.status,
                itemStatus: d.itemStatus,
                priceStr: d.priceStr,
                category: d.category,
                tag: d.tag,
                hot: d.hot,
                qtyStatus: d.qtyStatus,
                visible: d.visible,
                lastModifiedDate: d.lastModifiedDate,
                id: d.id,
                lastModifiedBy: d.lastModifiedBy,
                createdBy: d.createdBy,
                pageRefreshCount: d.pageRefreshCount,
                adminTag: d.adminTag,
                adminRemark: d.adminRemark,
                public: d.public
              })
            }
          }

          this.data = resultData
          console.log('00000000000', this.data)
        }
      })
    },
    addProduct() {
      const functionSetting = this.functionSetting
      const pathObj = {
        path: '/goods/productItem/create'
      }
      if (functionSetting !== null) {
        pathObj.path = '/goods/' + functionSetting.id + '/create'
      }

      console.log('functionSetting add ', pathObj)
      this.$router.push(pathObj)
    },
    handleEdit(row) {
      console.log('functionSetting handleEdit ', row)

      const functionSetting = this.functionSetting
      const pathObj = {
        path: '/goods/productItem/edit',
        query: { baseProductId: row.baseProductId }
      }
      if (functionSetting !== null) {
        pathObj.path = '/goods/' + functionSetting.id + '/edit'
      }
      this.$router.push(pathObj)

      // this.$router.push({
      //   path: '/goods/productItem/edit',
      //   query: { productId: row.id }
      // })
    },
    handleDelete(row) {
      this.$confirm(
        this.$t('COMPONENTS.delete_Title2'),
        this.$t('COMPONENTS.tip'),
        {
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t('COMPONENTS.sure'),
          cancelButtonText: this.$t('COMPONENTS.cancel')
        }
      ).then(() => {
        deleteProduct(row.baseProductId).then((response) => {
          if (response === 'DELETE_SUCCESS') {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.delete_Success'),
              type: 'success'
            })
            this.searchJob()
          } else {
            this.$notify({
              title: this.$t('COMPONENTS.fail'),
              message: response,
              type: 'error'
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
