var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 12 } },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("attribute.add"),
                      },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "attr",
          attrs: { model: _vm.attr, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xl: 12 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("attribute.attrName"),
                                    prop: "name",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.attr.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.attr, "name", $$v)
                                      },
                                      expression: "attr.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("attribute.dataType"),
                                    prop: "dataType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "attribute.Please-select-type"
                                        ),
                                      },
                                      model: {
                                        value: _vm.attr.dataType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.attr, "dataType", $$v)
                                        },
                                        expression: "attr.dataType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "TEXT", value: "TEXT" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "IMAGE",
                                          value: "IMAGE",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: _vm.$t("attribute.rowNum") },
                                },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { sm: 6, xs: 12 } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "attribute.Number-of-rows-added"
                                              ),
                                            },
                                            model: {
                                              value: _vm.rowCount,
                                              callback: function ($$v) {
                                                _vm.rowCount = $$v
                                              },
                                              expression: "rowCount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { sm: 6, xs: 12 } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addLanguage()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("COMPONENTS.add"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "value-table",
                                      staticStyle: { width: "100%" },
                                      attrs: { data: _vm.attr.attrValues },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t("attribute.valueName"),
                                          width: "180px",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "attrValues." +
                                                        scope.$index +
                                                        ".name",
                                                      rules: _vm.rules.value,
                                                    },
                                                  },
                                                  [
                                                    scope.row.edit
                                                      ? _c("el-input", {
                                                          staticStyle: {
                                                            width: "160px",
                                                          },
                                                          attrs: {
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.name,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.name",
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.name
                                                            )
                                                          ),
                                                        ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t(
                                            "attribute.ValueDescription"
                                          ),
                                          width: "200",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    scope.row.edit
                                                      ? _c("el-input", {
                                                          staticStyle: {
                                                            width: "180px",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .description,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "description",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.description",
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.name
                                                            )
                                                          ),
                                                        ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t("COMPONENTS.operation"),
                                          width: "200",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-button", {
                                                  attrs: {
                                                    icon: "el-icon-delete",
                                                    circle: "",
                                                    disabled:
                                                      _vm.checkPermission(
                                                        "attribute.add"
                                                      ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delRow(
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-button", {
                                                  attrs: {
                                                    icon: "el-icon-edit",
                                                    circle: "",
                                                    disabled:
                                                      _vm.checkPermission(
                                                        "attribute.add"
                                                      ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editRow(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }