import Cookies from 'js-cookie'

// const languages = Cookies.get('language') ? JSON.parse(Cookies.get('language')) : { label: '繁体中文', code: 'zh-TW' }
const languages = Cookies.get('language') ? JSON.parse(Cookies.get('language')) : null

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  languageCode: languages,
  permissionList:[]
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_LANAUGE_CODE: (state, languageCode) => {
    state.languageCode = languageCode
    Cookies.set('language', languageCode)
  },
  SET_PERMISSION_LIST:(state,permissionList)=>{
    state.permissionList = permissionList;
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setLangugeCode({ commit }, languageCode) {
    commit('SET_LANAUGE_CODE', languageCode)
  },
  setPermissionList({commit},permissionList){
    commit('SET_PERMISSION_LIST',permissionList)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
