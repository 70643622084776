<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="150px"
        >
        <el-row :gutter="20">
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('brand.brandName')">
                <el-input v-model="query.name" :placeholder="$t('COMPONENTS.incontent')" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">&nbsp;</el-col>
            <el-col :xl="24" :md="24" :sm="24" :xs="24">
              <el-button :disabled="checkPermission('brand')" @click="loadData">{{ $t('COMPONENTS.search') }}</el-button>
              <el-button :disabled="checkPermission('brand.add')" @click="handleCreate">{{ $t('COMPONENTS.add') }}</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </transition>

    <el-table
      :data="list"
      tooltip-effect="dark"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
    >
      <el-table-column :label="$t('brand.brandName')" prop="name" width="300" />
      <el-table-column :label="$t('brand.brandImg')" prop="logoUrl" width="150px" align="left">
        <template slot-scope="scope">
          <el-image :src="scope.row.logoUrl" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('brand.remark')" prop="remark" width="" />
      <el-table-column :label="$t('COMPONENTS.operation')" prop="action" lign="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="checkPermission('brand.edit')"
            @click="handleEdit(scope.row)"
          >{{ $t('COMPONENTS.edit') }}</el-button>
          <el-button
            size="mini"
            :disabled="checkPermission('brand.delete')"
            @click="handleDelete(scope.row)"
          >{{ $t('COMPONENTS.delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-col>
        <pagination
          v-show="query.total > 0"
          class="hidden-xs-only"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="query.total > 0"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="7"
          layout="pager, prev, next"
          @pagination="loadData"
        />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {
  deleteBrand,
  searchList
} from '@/api/brand'

import { getStaticSourcePath } from '@/utils/serverPathUtil'
import { fetchLanguageList } from '@/api/languageApi'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'ComplexTable',
  components: { Pagination },
  data() {
    return {
      list: {
        id: '',
        name: '',
        logUrlId: '',
        logoUrl: '',
        remark: ''
      },
      language: [],
      query: {
        size: 10,
        page: 1,
        total: 100,
        name: ''
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadData() {
      var query = { ...this.query }
      var param = getPageParm(query)
      searchList(param, query).then((resp) => {
        var data = resp.data
        this.query.total = resp.pageCount
        this.query.page = resp.pageNum
        this.query.size = resp.pageSize
        var resultData = []
        if (data == null) {
          this.list = null
        } else {
          for (var i = 0; i < data.length; i++) {
            var d = data[i]
            resultData.push({
              id: d.id,
              name: d.name,
              logUrlId: d.logUrlId,
              logoUrl: getStaticSourcePath(d.logoUrl),
              remark: d.remark
            })
          }
          this.list = resultData
        }
        console.log('list', this.list)
        fetchLanguageList().then(response => {
          this.language = response.data.items
        })
      })
    },
    handleEdit(row) {
      this.$router.push({ path: 'brand/edit', query: { brandId: row.id }})
    },
    handleCreate() {
      this.$router.push({ path: 'brand/create' })
    },
    handleDelete(row) {
      this.$confirm(this.$t('COMPONENTS.delete_Title2'), this.$t('COMPONENTS.tip'), {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel')
      }).then(() => {
        deleteBrand(row.id).then(response => {
          this.$notify({
            title: this.$t('COMPONENTS.success'),
            message: this.$t('COMPONENTS.delete_Success'),
            type: 'success'
          })

          var query = { ...this.query }
          var param = getPageParm(query)
          searchList(param, query).then((resp) => {
            var data = resp.data
            this.query.total = resp.pageCount
            this.query.page = resp.pageNum
            this.query.size = resp.pageSize
            var resultData = []
            if (data == null) {
              this.list = null
            } else {
              for (var i = 0; i < data.length; i++) {
                var d = data[i]
                resultData.push({
                  id: d.id,
                  name: d.name,
                  logUrlId: d.logUrlId,
                  logoUrl: getStaticSourcePath(d.logoUrl),
                  remark: d.remark
                })
              }
              this.list = resultData
            }

            console.log('list', this.list)

            fetchLanguageList().then(response => {
              this.language = response.data.items
            })
          })
        })
      })
    }
  }
}

</script>
