<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <el-form
        class="search-form-inline"
        label-suffix=":"
        label-position="left"
        hide-required-asterisk
        label-width="150px"
      >
        <el-row :gutter="20">
          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('productItem.shop')">
              <el-select v-model="query.shopId" :placeholder="$t('COMPONENTS.inselect')" filterable clearable>
                <el-option label="" value="">{{ $t('COMPONENTS.inselect') }}</el-option>
                <el-option
                  v-for="shopName in shop.shopName"
                  :key="shopName.value"
                  :label="shopName.label"
                  :value="shopName.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('Member Account')">
              <el-input v-model="query.memberName" clearable class="filter-item" />
            </el-form-item>
          </el-col>
          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('review.productName')">
              <el-select v-model="query.productId" :placeholder="$t('COMPONENTS.inselect')" filterable clearable>
                <el-option label="" value="">{{ $t('COMPONENTS.inselect') }}</el-option>
                <el-option
                  v-for="product in productList"
                  :key="product.baseProductId"
                  :label="product.adminTag"
                  :value="product.baseProductId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('productItem.status')">
              <el-select v-model="query.status" :placeholder="$t('COMPONENTS.inselect')" filterable clearable>
                <el-option label="" value="">{{ $t('COMPONENTS.inselect') }}</el-option>
                <el-option
                  v-for="status in statusList"
                  :key="status.value"
                  :label="status.label"
                  :value="status.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-button class="allButton" :disabled="checkPermission('comment')" @click="loadData">{{ $t('COMPONENTS.search') }}</el-button>
          <!-- <el-button class="allButton" @click="handleOneClickReject">一键拒絕</el-button> -->
          <el-button class="allButton" :disabled="checkPermission('comment.edit')" @click="handleOneClickApproval">{{ $t('review.oneClickApproval') }}</el-button>
        </el-row>
      </el-form>
    </transition>

    <el-table
      tooltip-effect="dark"
      :data="data"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
      @selection-change="handleSelectionChange"
    >
      >
      <el-table-column type="selection" align="center" fixed="left" />
      <el-table-column :label="$t('review.productName')" prop="productName" align="left" />
      <el-table-column :label="$t('Member Account')" prop="memberName" align="left" />
      <el-table-column :label="$t('productItem.shop')" prop="shopName" align="left" />
      <el-table-column :label="$t('review.rate')" prop="reviewRate" align="left" width="150">
        <template slot-scope="scope">
          <el-rate v-model="scope.row.reviewRate" disabled />
        </template>
      </el-table-column>
      <el-table-column :label="$t('review.title')" align="left" prop="reviewTitle" />
      <el-table-column :label="$t('review.content')" align="left" prop="reviewContent" />
      <el-table-column :label="$t('productItem.status')" align="left" prop="statusLabel" />

      <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" min-width="180px">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleView(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.view') }}</el-button>
          <!-- <el-button
            v-if="scope.row.status !== 'SUBMIT'"
            size="mini"
            @click="saveOneClickAction([scope.row],'SUBMIT' )"
          >{{ $t('review.submit') }}</el-button> -->
          <el-button
            v-if="scope.row.status !== 'APPROVED'"
            size="mini"
            :disabled="checkPermission('comment.edit')"
            @click="saveOneClickAction([scope.row], 'APPROVED')"
          >{{ $t('review.approve') }}</el-button>
          <el-button
            v-if="scope.row.status !== 'REJECT'"
            size="mini"
            :disabled="checkPermission('comment.edit')"
            @click="saveOneClickAction([scope.row],'REJECT' )"
          >{{ $t('review.reject') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-col>
        <pagination
          v-show="query.total > 0"
          class="hidden-xs-only"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="query.total > 0"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="7"
          layout="pager, prev, next"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import { getAllCommentList, findAllShopName, updateDataStatus } from '@/api/commentApi'
import { queryAllProductName } from '@/api/productItem'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
import { getLanguage } from '@/lang/index'

export default {
  name: 'Comment',
  components: { Pagination },
  directives: { waves },
  filters: {},
  data() {
    return {
      productList: [],
      statusList: [],
      noChoosedMode: [],
      choosedMode: [],
      noChooseList: [],
      chooseList: [],
      tableKey: 0,
      selectList: [],
      listLoading: true,
      listQuery: {
        page: 0,
        size: 10,
        code: null,
        sort: null
      },
      list: null,
      attribute: {},
      data: [],
      shop: {
        shopName: []
      },
      pageStyle: {
        margin: '0px',
        padding: '5px'
      },
      query: {
        shopName: '',
        size: 10,
        page: 1,
        total: 0,
        function: 'usercomment',
        status: 'SUBMIT'
      },
      acceptVideoType: ['.mp4', '.AVI', '.mpg', '.wmv', '.mov'],
      acceptImageType: ['.jpg', '.jpeg', '.png', '.gif'],
      multipleSelection: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    loadData() {
      const query = { ...this.query }
      var param = getPageParm(query)
      const permissionList = []
      permissionList.push(getAllCommentList(param, query))
      permissionList.push(findAllShopName())
      permissionList.push(queryAllProductName())
      permissionList.push(getCfsDomainData({ domainCode: 'SHOP_PRODUCT_REVIEW_STATUS' }))
      Promise.all(permissionList).then(resp => {
        const reviews = resp[0]
        const shops = resp[1]
        const products = resp[2]
        const statusListRes = resp[3].data
        var resultData = []
        if (shops && shops.data) {
          resultData = shops.data.map(item => {
            item.value = item.id
            item.label = item.name
            return item
          })
        }
        this.shop.shopName = resultData
        if (products && products.data) {
          this.productList = products.data
        } else {
          this.productList = []
        }

        const statusList = []
        if (statusListRes) {
          statusListRes.map((data, i) => {
            statusList.push({
              value: data.domainDataCode,
              label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')'
              //              disabled: checkCfsPermission('SHOP_PRODUCT_REVIEW_STATUS.' + data.domainDataCode)
            })
          })
          this.statusList = statusList
        }

        if (reviews && reviews.data) {
          this.query.total = reviews.pageCount
          this.query.page = reviews.pageNum
          this.query.size = reviews.pageSize
          this.data = reviews.data.map(item => {
            // 狀態改語言
            statusList.map((data, i) => {
              if (data.value === item.status) {
                item.statusLabel = data.label
              }
            })
            item.memberName = item.member ? item.member.loginName : ''
            item.shopName = resultData[0].name // 目前只有一個商店，如果後續有新的商店請重新修改logic
            // item.fileList = item.fileList.map(i => {
            //   return {
            //     name: i.filePath,
            //     url: i.filePath,
            //     status: 'done',
            //     type: this.acceptVideoType.includes(i.filePath.substring(i.filePath.lastIndexOf('.'))) ? 'VIDEO' : 'IMAGE'
            //   }
            // })
            return item
          })
        } else {
          this.data = []
        }
      })
    },
    handleView(index, row) {
      this.$router.push({ path: 'comment/edit', query: { reviewId: row.id, shopName: row.shopName, parmas: { ...row }}})
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleOneClickReject() {
      const status = 'REJECT'
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.saveOneClickAction(this.multipleSelection, status)
        return
      }
      this.commonConfirmModal(
        this.$t('review.modal.reject.tip'),
        status
      )
    },
    handleOneClickApproval() {
      const status = 'APPROVED'
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.saveOneClickAction(this.multipleSelection, status)
        return
      }
      this.commonConfirmModal(
        this.$t('review.modal.approve.tip'),
        status
      )
    },
    commonConfirmModal(msgTip, type) {
      const h = this.$createElement
      this.$msgbox({
        title: this.$t('review.modal.title'),
        message: h('p', null, [
          h('span', null, msgTip)
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('COMMON.confirm'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            // instance.confirmButtonText = '执行中...';
            done()
            this.saveOneClickAction([], type)
            // setTimeout(() => {
            setTimeout(() => {
              instance.confirmButtonLoading = false
            }, 300)
            // }, 3000);
          } else {
            done()
          }
        }
      }).then(action => {
        this.$message({
          type: 'info',
          message: this.$t('COMMON.update.success')
        })
      })
    },
    async saveOneClickAction(data, type) {
      await updateDataStatus({ data, status: type })
      // .then(resp=>{
      this.loadData()
      // })
    }
  }
}
</script>
