var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "product-form" } },
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 999,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "header", attrs: { span: 12 } }, [
                _vm._v(_vm._s(_vm.menuNameLabel)),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.showTranslateDrawer()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.translate")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.submitBtn()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                  _vm._v(" "),
                  !!_vm.temp.id &&
                  _vm.temp.queryVersion !== _vm.temp.publicVersion
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.publicBtn("UP")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Publish")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.temp.id &&
                  _vm.temp.queryVersion === _vm.temp.publicVersion
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.publicBtn("DOWN")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Discontinue")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "temp",
          staticClass: "search-form-inline",
          attrs: {
            model: _vm.temp,
            rules: _vm.rules,
            "label-suffix": ":",
            "label-position": "left",
            "label-width": "200px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _vm.pageType !== "CREATE"
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 24, xs: 24 } },
                                    [
                                      _c("el-form-item", [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Basic ID")))]
                                        ),
                                        _vm._v(
                                          "\n                   " +
                                            _vm._s(_vm.temp.baseProductId) +
                                            "\n                 "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                { key: _vm.temp.id, attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 24, xs: 24 } },
                                    [
                                      _c("el-form-item", [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Version ID")))]
                                        ),
                                        _vm._v(
                                          "\n                   " +
                                            _vm._s(_vm.temp.id) +
                                            "\n                 "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pageType !== "CREATE"
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, xs: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Select Version"),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "408px" },
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "COMPONENTS.inselect"
                                            ),
                                            clearable: "",
                                          },
                                          on: { change: _vm.onChangeVersion },
                                          model: {
                                            value: _vm.temp.queryVersion,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "queryVersion",
                                                $$v
                                              )
                                            },
                                            expression: "temp.queryVersion",
                                          },
                                        },
                                        _vm._l(
                                          _vm.temp.productVersionHistory,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                label:
                                                  item +
                                                  (_vm.temp.publicVersion ===
                                                  item
                                                    ? "  (" +
                                                      _vm.$t("Publish") +
                                                      ")"
                                                    : ""),
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "adminTag",
                                    label: _vm.$t("Admin Tag"),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "408px" },
                                    model: {
                                      value: _vm.temp.adminTag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.temp, "adminTag", $$v)
                                      },
                                      expression: "temp.adminTag",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t(
                                          "Admin.adminTag.tooltip"
                                        ),
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Admin Remark") } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "408px" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "Admin.adminRemark.tooltip"
                                      ),
                                    },
                                    model: {
                                      value: _vm.temp.adminRemark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.temp, "adminRemark", $$v)
                                      },
                                      expression: "temp.adminRemark",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t(
                                          "Admin.adminRemark.tooltip"
                                        ),
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "name",
                                    label: _vm.$t(_vm.productNameLabel),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "408px" },
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.temp.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.temp, "name", $$v)
                                      },
                                      expression: "temp.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("productItem.remarkTpl"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "408px" },
                                      attrs: {
                                        disabled: _vm.pageType !== "CREATE",
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                        clearable: "",
                                      },
                                      on: { change: _vm.onChangeRemarkTpl },
                                      model: {
                                        value: _vm.temp.remarkTmpId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "remarkTmpId", $$v)
                                        },
                                        expression: "temp.remarkTmpId",
                                      },
                                    },
                                    _vm._l(_vm.remarkTplList, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "category",
                                    label: _vm.$t("Category/Subcategory"),
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    ref: "cascader",
                                    staticStyle: { width: "408px" },
                                    attrs: {
                                      options: _vm.temp.categoryOptions,
                                      props: _vm.props,
                                      clearable: "",
                                      placeholder: _vm.$t(
                                        "COMPONENTS.inselect"
                                      ),
                                    },
                                    on: {
                                      "visible-change": function (v) {
                                        return _vm.visibleChange(
                                          v,
                                          "cascader",
                                          _vm.addTreeNode,
                                          "category"
                                        )
                                      },
                                      change: _vm.changeCategory,
                                      "remove-tag": _vm.removeCatrhory,
                                    },
                                    model: {
                                      value: _vm.temp.category,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.temp, "category", $$v)
                                      },
                                      expression: "temp.category",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "tag",
                                    label: _vm.$t("productItem.multiTag"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      ref: "tagRef",
                                      staticStyle: { width: "408px" },
                                      attrs: {
                                        disabled:
                                          _vm.checkPermission(
                                            "SHOP_PRODUCT_TAG"
                                          ),
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                        multiple: "",
                                        "popper-append-to-body": true,
                                      },
                                      on: {
                                        "visible-change": function (v) {
                                          return _vm.visibleChange2(
                                            v,
                                            "tagRef",
                                            _vm.addTreeNode,
                                            "tag"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.temp.tag,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "tag", $$v)
                                        },
                                        expression: "temp.tag",
                                      },
                                    },
                                    _vm._l(
                                      _vm.temp.tagOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            title: _vm.$t("COMPONENTS.add"),
                            visible: _vm.treeDialogVisible,
                            width: "38%",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.treeDialogVisible = $event
                            },
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "font-weight": "bold" },
                                  attrs: { xs: 8, sm: 6, md: 8, lg: 8, xl: 8 },
                                },
                                [_vm._v(_vm._s(_vm.$t("tree.name")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 12,
                                    xl: 12,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "250px" },
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.saveData.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.saveData, "name", $$v)
                                      },
                                      expression: "saveData.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { style: _vm.treeIsTagType, attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "font-weight": "bold" },
                                  attrs: { xs: 8, sm: 6, md: 8, lg: 8, xl: 8 },
                                },
                                [_vm._v(_vm._s(_vm.$t("tree.nestLabelUnder")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 12,
                                    xl: 12,
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    staticStyle: { width: "250px" },
                                    attrs: {
                                      options: _vm.temp.categoryOptions,
                                      props: _vm.treeProps,
                                      "get-checked-nodes": _vm.getCheckedNodes,
                                      clearable: "",
                                      placeholder: _vm.$t(
                                        "COMPONENTS.inselect"
                                      ),
                                    },
                                    model: {
                                      value: _vm.saveData.parentId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.saveData, "parentId", $$v)
                                      },
                                      expression: "saveData.parentId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handCancelAddTree()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitNewTreeNode()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "brand",
                                    label: _vm.$t("productItem.brand"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "408px" },
                                      attrs: {
                                        disabled:
                                          _vm.checkPermission(
                                            "SHOP_PRODUCT_BRAND"
                                          ),
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                      },
                                      model: {
                                        value: _vm.temp.brand,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "brand", $$v)
                                        },
                                        expression: "temp.brand",
                                      },
                                    },
                                    _vm._l(_vm.temp.brandList, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: _vm.$t("productItem.label") },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "408px" },
                                    attrs: {
                                      disabled: _vm.checkPermission(
                                        "SHOP_PRODUCT_LEVEL_OF_SALES"
                                      ),
                                      placeholder: "",
                                    },
                                    model: {
                                      value: _vm.temp.label,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.temp, "label", $$v)
                                      },
                                      expression: "temp.label",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("productItem.hot") } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      disabled:
                                        _vm.checkPermission("SHOP_PRODUCT_HOT"),
                                      "active-color": "#13ce66",
                                      "inactive-color": "#808080",
                                    },
                                    model: {
                                      value: _vm.temp.hot,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.temp, "hot", $$v)
                                      },
                                      expression: "temp.hot",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "productItem.lastModifiedDate"
                                    ),
                                  },
                                },
                                [_vm._v(_vm._s(_vm.temp.lastModifiedDate))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.temp.id
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, xs: 24 } },
                                [
                                  !_vm.temp.id
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "productItem.Outlets_limited"
                                            ),
                                            prop: "shop",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { display: "none" },
                                            attrs: { placeholder: "" },
                                            model: {
                                              value: _vm.temp.shopAll,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.temp,
                                                  "shopAll",
                                                  $$v
                                                )
                                              },
                                              expression: "temp.shopAll",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                disabled: _vm.checkPermission(
                                                  "SHOP_PRODUCT_SHOP_LIMITE"
                                                ),
                                              },
                                              on: {
                                                change: _vm.checkShopRadio,
                                              },
                                              model: {
                                                value: _vm.temp.shopRadio,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.temp,
                                                    "shopRadio",
                                                    $$v
                                                  )
                                                },
                                                expression: "temp.shopRadio",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "false" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "productItem.all_Outlets"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "true" } },
                                                [
                                                  _vm._v(
                                                    "\n                     " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "productItem.multiOutlets_limited"
                                                        )
                                                      ) +
                                                      "\n                     "
                                                  ),
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.checkPermission(
                                                            "SHOP_PRODUCT_SHOP_LIMITE"
                                                          ),
                                                        placeholder: _vm.$t(
                                                          "COMPONENTS.inselect"
                                                        ),
                                                        multiple: "",
                                                      },
                                                      on: {
                                                        change: _vm.checkShop,
                                                      },
                                                      model: {
                                                        value: _vm.temp.shop,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.temp,
                                                            "shop",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "temp.shop",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.temp.shopOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "productItem.Outlets_limited"
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                 " +
                                              _vm._s(_vm.shopStr) +
                                              "\n               "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("productItem.status"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "408px" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                      },
                                      model: {
                                        value: _vm.temp.itemStatus,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "itemStatus", $$v)
                                        },
                                        expression: "temp.itemStatus",
                                      },
                                    },
                                    _vm._l(
                                      _vm.temp.itemStatusList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                            disabled: item.disabled,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "card-title", attrs: { span: 24 } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("productItem.css_setting.label"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "productItem.css_setting.photo.style"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "400px" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onChangeCssPhoto(
                                            _vm.option.key
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.temp.cssPhoto,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "cssPhoto", $$v)
                                        },
                                        expression: "temp.cssPhoto",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "COVER",
                                        attrs: {
                                          label:
                                            _vm.$t(
                                              "productItem.css_setting.photo.cover"
                                            ) +
                                            "(" +
                                            _vm.$t("Default") +
                                            ")",
                                          value: "COVER",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "CONTAIN",
                                        attrs: {
                                          label: _vm.$t(
                                            "productItem.css_setting.photo.contain"
                                          ),
                                          value: "CONTAIN",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.remarkReply.allowIngredient
                ? _c(
                    "el-col",
                    { key: _vm.componentKey, attrs: { xl: 24 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "card-box" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "card-title",
                                  attrs: { span: 24 },
                                },
                                [_vm._v(_vm._s(_vm.$t("Product Parameter")))]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.temp.infos, function (item, index) {
                            return _c(
                              "el-row",
                              {
                                key: index,
                                attrs: { name: String(index), gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: item.infoKey,
                                          label: _vm.$t(item.infoKey),
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.infoValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "infoValue", $$v)
                                            },
                                            expression: "item.infoValue",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { key: _vm.remarkTypecomponentKey, attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "card-title", attrs: { span: 24 } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t(_vm.productIntroductionLabel))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-tabs",
                                {
                                  staticClass: "demo-tabs",
                                  attrs: { type: "card", editable: "" },
                                  on: {
                                    edit: _vm.handleTabsEdit,
                                    "tab-click": _vm.tabClickEvent,
                                  },
                                  model: {
                                    value: _vm.editableTabsValue,
                                    callback: function ($$v) {
                                      _vm.editableTabsValue = $$v
                                    },
                                    expression: "editableTabsValue",
                                  },
                                },
                                _vm._l(
                                  _vm.remarkReply.remarkSectionList,
                                  function (item, index) {
                                    return _c(
                                      "el-tab-pane",
                                      {
                                        key: index,
                                        attrs: {
                                          name: String(index),
                                          closable: false,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.sectionName)
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n\n                   " +
                                            _vm._s(_vm.$t("Tab name")) +
                                            "： "
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: {
                                            disabled: !!item.remarkTplSectionId,
                                          },
                                          model: {
                                            value: item.sectionName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "sectionName", $$v)
                                            },
                                            expression: "item.sectionName",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                       \n\n                   " +
                                            _vm._s(_vm.$t("Tab position")) +
                                            "："
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                index === 0 ||
                                                !!item.remarkTplSectionId ||
                                                _vm.remarkReply.tempIndex ===
                                                  index - 1,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.moveSectionForward(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-arrow-left",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                index ===
                                                  _vm.remarkReply
                                                    .remarkSectionList.length -
                                                    1 ||
                                                !!item.remarkTplSectionId,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.moveSectionBack(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-right",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticStyle: { float: "right" } },
                                          [
                                            _vm._v(
                                              "\n                     " +
                                                _vm._s(
                                                  _vm.$t("Pay to unlock")
                                                ) +
                                                "："
                                            ),
                                            _vm._v(" "),
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#13ce66",
                                                "inactive-color": "#808080",
                                                disabled:
                                                  !!item.remarkTplSectionId,
                                              },
                                              model: {
                                                value: item.needPay,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "needPay", $$v)
                                                },
                                                expression: "item.needPay",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            item.optionType &&
                                            item.optionType === "INGREDIENTS"
                                              ? _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "el-card",
                                                      {
                                                        staticClass:
                                                          "ingredient-transfer-card",
                                                      },
                                                      [
                                                        _c("el-transfer", {
                                                          attrs: {
                                                            id: "productIngredientTransfer",
                                                            "target-order":
                                                              "unshift",
                                                            titles: [
                                                              _vm.$t("Source"),
                                                              _vm.$t("Target"),
                                                            ],
                                                            filterable: "",
                                                            "filter-placeholder":
                                                              _vm.$t("Search"),
                                                            data: _vm.ingredientData,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.selectIngredient,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var option =
                                                                    ref.option
                                                                  return _c(
                                                                    "div",
                                                                    {},
                                                                    [
                                                                      [
                                                                        _c(
                                                                          "el-row",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "920px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    span: 5,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      option.label
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.ingredientDataSelectId.includes(
                                                                              option.key
                                                                            )
                                                                              ? _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 3,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    option.showQty
                                                                                      ? _c(
                                                                                          "el-input",
                                                                                          {
                                                                                            key: option.key,
                                                                                            attrs:
                                                                                              {
                                                                                                placeholder:
                                                                                                  _vm.$t(
                                                                                                    "Qty"
                                                                                                  ),
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.onChangeQty(
                                                                                                    option.key,
                                                                                                    $event
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  option.qty,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      option,
                                                                                                      "qty",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "option.qty",
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    !option.showQty
                                                                                      ? _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.ingredientDataSelectId.includes(
                                                                              option.key
                                                                            )
                                                                              ? _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 4,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-select",
                                                                                      {
                                                                                        key: option.key,
                                                                                        attrs:
                                                                                          {
                                                                                            placeholder:
                                                                                              _vm.$t(
                                                                                                "Uom"
                                                                                              ),
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.onChangeUomId(
                                                                                                option.key,
                                                                                                $event
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              option.uomId,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  option,
                                                                                                  "uomId",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "option.uomId",
                                                                                          },
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm.ingredientUom,
                                                                                        function (
                                                                                          i
                                                                                        ) {
                                                                                          return _c(
                                                                                            "el-option",
                                                                                            {
                                                                                              key: i.value,
                                                                                              attrs:
                                                                                                {
                                                                                                  label:
                                                                                                    i.label,
                                                                                                  value:
                                                                                                    i.value,
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.ingredientDataSelectId.includes(
                                                                              option.key
                                                                            )
                                                                              ? _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 4,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-select-tree",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "a-c":
                                                                                              "",
                                                                                            clearable:
                                                                                              "",
                                                                                            data: _vm.ingredientType,
                                                                                            multiple: false,
                                                                                            "check-strictly":
                                                                                              "",
                                                                                            "check-on-click-node":
                                                                                              "",
                                                                                            "render-after-expand": false,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              data
                                                                                            ) {
                                                                                              return _vm.onChangeIngredientType(
                                                                                                option.key,
                                                                                                data
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              option.ingredientProductTreeId,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  option,
                                                                                                  "ingredientProductTreeId",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "option.ingredientProductTreeId",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.ingredientDataSelectId.includes(
                                                                              option.key
                                                                            )
                                                                              ? _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 4,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-input",
                                                                                      {
                                                                                        key: option.key,
                                                                                        attrs:
                                                                                          {
                                                                                            placeholder:
                                                                                              _vm.$t(
                                                                                                "Description"
                                                                                              ),
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.onChangeIngredientDescription(
                                                                                                option.key,
                                                                                                $event
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              option.productDescription,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  option,
                                                                                                  "productDescription",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "option.productDescription",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.ingredientDataSelectId.includes(
                                                                              option.key
                                                                            ) &&
                                                                            option
                                                                              .videos
                                                                              .length !==
                                                                              0
                                                                              ? _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 4,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-select",
                                                                                      {
                                                                                        key: option.key,
                                                                                        attrs:
                                                                                          {
                                                                                            placeholder:
                                                                                              _vm.$t(
                                                                                                "Prepare Video"
                                                                                              ),
                                                                                            clearable:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.onChangePrepareVideo(
                                                                                                option.key,
                                                                                                $event
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              option.prepareMediaId,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  option,
                                                                                                  "prepareMediaId",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "option.prepareMediaId",
                                                                                          },
                                                                                      },
                                                                                      _vm._l(
                                                                                        option.videos,
                                                                                        function (
                                                                                          i
                                                                                        ) {
                                                                                          return _c(
                                                                                            "el-option",
                                                                                            {
                                                                                              key: i.id,
                                                                                              attrs:
                                                                                                {
                                                                                                  label:
                                                                                                    i.tag,
                                                                                                  value:
                                                                                                    i.id,
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                    ],
                                                                    2
                                                                  )
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.ingredientDataSelectId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.ingredientDataSelectId =
                                                                $$v
                                                            },
                                                            expression:
                                                              "ingredientDataSelectId",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "el-card",
                                                      _vm._l(
                                                        item.remarkItemList,
                                                        function (
                                                          remarkItem,
                                                          remarkIndex
                                                        ) {
                                                          return _c(
                                                            "el-card",
                                                            {
                                                              key: remarkIndex,
                                                              staticClass:
                                                                "card-box",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "el-row",
                                                                    {
                                                                      attrs: {
                                                                        gutter: 20,
                                                                      },
                                                                    },
                                                                    [
                                                                      item.notAllowUpdate &&
                                                                      !!item.remarkTplSectionId
                                                                        ? _c(
                                                                            "el-col",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "remark-value-disable",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "Summernote",
                                                                                    {
                                                                                      key: remarkItem.itemValue,
                                                                                      attrs:
                                                                                        {
                                                                                          disable:
                                                                                            "true",
                                                                                          tagid:
                                                                                            _vm.generateKey(
                                                                                              index
                                                                                            ),
                                                                                          value:
                                                                                            remarkItem.itemValue,
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          function (
                                                                                            value
                                                                                          ) {
                                                                                            return _vm.changeRemarkItemValue(
                                                                                              value,
                                                                                              index,
                                                                                              remarkIndex
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "el-col",
                                                                            [
                                                                              _c(
                                                                                "Summernote",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      tagid:
                                                                                        _vm.generateKey(
                                                                                          index
                                                                                        ),
                                                                                      value:
                                                                                        remarkItem.itemValue,
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        value
                                                                                      ) {
                                                                                        return _vm.changeRemarkItemValue(
                                                                                          value,
                                                                                          index,
                                                                                          remarkIndex
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "card-title", attrs: { span: 24 } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Preview pictures and videos"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("productItem.mainImg"),
                                    prop: "mainImgUrl",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "avatar-uploader",
                                      attrs: {
                                        action: "#",
                                        "http-request": _vm.uploadMainImages,
                                        "file-list": _vm.mainBannerUrl,
                                        accept: _vm.acceptImageType,
                                        "show-file-list": false,
                                        "before-upload": function (file) {
                                          return _vm.beforeFileUpload(
                                            file,
                                            "IMAGE"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.mainImgUrl
                                        ? _c("img", {
                                            staticClass: "avatar",
                                            attrs: { src: _vm.mainImgUrl },
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-plus avatar-uploader-icon",
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: _vm.$t("Preview pictures") },
                                },
                                [
                                  _vm._l(
                                    _vm.previewImageListTemp,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "avatar" },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              "object-fit": "cover",
                                            },
                                            attrs: {
                                              src: item.url,
                                              width: "145",
                                              height: "145",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewImageHandleRemove(
                                                    item.name
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewImageHandleReplace(
                                                    item.name
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-edit",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: "#",
                                        "http-request": _vm.uploadPreviewImage,
                                        accept: _vm.acceptImageType,
                                        multiple: "",
                                        "list-type": "picture-card",
                                        "before-upload": function (file) {
                                          return _vm.beforeFileUpload(
                                            file,
                                            "IMAGE"
                                          )
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  ),
                                  _vm._v(" "),
                                  _c("el-upload", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false",
                                      },
                                    ],
                                    staticClass: "replacePreviewImageClass",
                                    attrs: {
                                      action: "#",
                                      "http-request": _vm.replacePreviewImage,
                                      accept: _vm.acceptImageType,
                                      multiple: "",
                                      "list-type": "picture-card",
                                      "before-upload": function (file) {
                                        return _vm.beforeFileUpload(
                                          file,
                                          "IMAGE"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _c("br"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Preview videos") } },
                                [
                                  _vm._l(
                                    _vm.previewVideoListTemp,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "avatar" },
                                        [
                                          _c(
                                            "video",
                                            {
                                              staticClass: "avatar",
                                              attrs: {
                                                src: item.url,
                                                controls: "controls",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                     您的浏览器不支持视频播放\n                   "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewVideoHandleRemove(
                                                    item.name
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewVideoHandleReplace(
                                                    item.name
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-edit",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: "#",
                                        "http-request": _vm.uploadPreviewVideo,
                                        accept: _vm.acceptVideoType,
                                        multiple: "",
                                        "list-type": "picture-card",
                                        "before-upload": function (file) {
                                          return _vm.beforeFileUpload(
                                            file,
                                            "VIDEO"
                                          )
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  ),
                                  _vm._v(" "),
                                  _c("el-upload", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false",
                                      },
                                    ],
                                    staticClass: "replacePreviewVideoClass",
                                    attrs: {
                                      action: "#",
                                      "http-request": _vm.replacePreviewVideo,
                                      accept: _vm.acceptVideoType,
                                      multiple: "",
                                      "list-type": "picture-card",
                                      "before-upload": function (file) {
                                        return _vm.beforeFileUpload(
                                          file,
                                          "VIDEO"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.temp.goodsType === "VIRTUAL_GOODS"
                ? _c(
                    "el-col",
                    { attrs: { xl: 24 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "card-box" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "card-title",
                                  attrs: { span: 24 },
                                },
                                [
                                  _vm._v(
                                    "\n               " +
                                      _vm._s(
                                        _vm.$t(
                                          "Pictures and videos displayed after purchase"
                                        )
                                      ) +
                                      "\n                         \n               "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-size": "12px",
                                        "font-weight": "normal",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.buyFileError))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Pictures after purchase"
                                        ),
                                        prop: "buyVideoOrImg",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.buyImageListTemp,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "avatar",
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  "object-fit": "cover",
                                                },
                                                attrs: {
                                                  src: item.url,
                                                  width: "145",
                                                  height: "145",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.buyImageHandleRemove(
                                                        item.name
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.buyImageHandleReplace(
                                                        item.name
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-edit",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: "#",
                                            "http-request": _vm.uploadBuyImage,
                                            accept: _vm.acceptImageType,
                                            multiple: "",
                                            "list-type": "picture-card",
                                            "before-upload": function (file) {
                                              return _vm.beforeFileUpload(
                                                file,
                                                "IMAGE"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-plus",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("el-upload", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: false,
                                            expression: "false",
                                          },
                                        ],
                                        staticClass: "replaceBuyImageClass",
                                        attrs: {
                                          action: "#",
                                          "http-request": _vm.replaceBuyImage,
                                          accept: _vm.acceptImageType,
                                          multiple: "",
                                          "list-type": "picture-card",
                                          "before-upload": function (file) {
                                            return _vm.beforeFileUpload(
                                              file,
                                              "IMAGE"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("br"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Videos after purchase"),
                                        prop: "buyVideoOrImg",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.buyVideoListTemp,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "avatar",
                                            },
                                            [
                                              _c(
                                                "video",
                                                {
                                                  staticClass: "avatar",
                                                  attrs: {
                                                    src: item.url,
                                                    controls: "controls",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                     您的浏览器不支持视频播放\n                   "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.buyVideoHandleRemove(
                                                        item.name
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.buyVideoHandleReplace(
                                                        item.name
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-edit",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: "#",
                                            "http-request": _vm.uploadBuyVideo,
                                            accept: _vm.acceptVideoType,
                                            multiple: "",
                                            "list-type": "picture-card",
                                            "before-upload": function (file) {
                                              return _vm.beforeFileUpload(
                                                file,
                                                "VIDEO"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-plus",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("el-upload", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: false,
                                            expression: "false",
                                          },
                                        ],
                                        staticClass: "replaceBuyVideoClass",
                                        attrs: {
                                          action: "#",
                                          "http-request": _vm.replaceBuyVideo,
                                          accept: _vm.acceptVideoType,
                                          multiple: "",
                                          "list-type": "picture-card",
                                          "before-upload": function (file) {
                                            return _vm.beforeFileUpload(
                                              file,
                                              "VIDEO"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "card-title", attrs: { span: 24 } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "productItem.commodity_structure_definition"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "commodity-structure",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("productItem.shopStruct"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                      },
                                      on: { change: _vm.selGoodsModel },
                                      model: {
                                        value: _vm.temp.shopStruct,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "shopStruct", $$v)
                                        },
                                        expression: "temp.shopStruct",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "productItem.SIMPLE-ITEM"
                                          ),
                                          value: "SIMPLE-ITEM",
                                          disabled: _vm.checkPermission(
                                            "SHOP_PRODUCT_STRUCTURE.SIMPLE_ITEM"
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "productItem.VARIABLE-ITEM"
                                          ),
                                          value: "VARIABLE-ITEM",
                                          disabled: _vm.checkPermission(
                                            "SHOP_PRODUCT_STRUCTURE.VARIABLE_ITEM"
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "productItem.Match-ITEM"
                                          ),
                                          value: "MATCH-ITEM",
                                          disabled: _vm.checkPermission(
                                            "SHOP_PRODUCT_STRUCTURE.MIX_MARTCH_ITEM"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.temp.shopStruct === "SIMPLE-ITEM"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: _vm.$t("Goods type") } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "500px" },
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "COMPONENTS.inselect"
                                            ),
                                          },
                                          model: {
                                            value: _vm.temp.goodsType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "goodsType",
                                                $$v
                                              )
                                            },
                                            expression: "temp.goodsType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "Virtual goods - (Unlock pictures or videos after purchase)"
                                              ),
                                              value: "VIRTUAL_GOODS",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "Virtual goods - (Go to the e-Training system to take classes after purchase)"
                                              ),
                                              value: "VIRTUAL_ETRAINING_GOODS",
                                              disabled: true,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "Physical goods - (Shipped to the customer after purchase)"
                                              ),
                                              value: "PHYSICAL_GOODS",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.temp.shopStruct === "SIMPLE-ITEM"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Purchase Limit"),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "500px" },
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "COMPONENTS.inselect"
                                            ),
                                            de: "",
                                          },
                                          model: {
                                            value: _vm.temp.purchaseLimit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "purchaseLimit",
                                                $$v
                                              )
                                            },
                                            expression: "temp.purchaseLimit",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "Unlimited, can be purchased separately"
                                              ),
                                              value: "NA",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "Can only be purchased through the plan"
                                              ),
                                              value: "PLAN_ONLY",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "No purchase required, view only"
                                              ),
                                              value: "VIEW_ONLY",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.temp.purchaseLimit !== "PLAN_ONLY" &&
                          _vm.temp.purchaseLimit !== "VIEW_ONLY"
                            ? _c(
                                "el-col",
                                { attrs: { xs: 24 } },
                                [
                                  _vm.sretailPrice ||
                                  _vm.sretailPrice === 0 ||
                                  (_vm.sisShow && _vm.pageType === "CREATE")
                                    ? _c("simple-goods-tabs", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.sisShow,
                                            expression: "sisShow",
                                          },
                                        ],
                                        ref: "simpleGoodsTabs",
                                        attrs: {
                                          "sretail-price": _vm.sretailPrice,
                                          "spromotion-price":
                                            _vm.spromotionPrice,
                                          "spromotion-type": _vm.spromotionType,
                                          "spromotion-value":
                                            _vm.spromotionValue,
                                          "sku-num": _vm.skuNum,
                                          "goods-id": _vm.temp.id,
                                          "sku-items": _vm.skuItems,
                                          "shop-struct": _vm.temp.shopStruct,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.vretailPrice ||
                                  (_vm.visShow && _vm.pageType === "CREATE")
                                    ? _c("variable-goods-tabs", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.visShow,
                                            expression: "visShow",
                                          },
                                        ],
                                        ref: "variableGoodsTabs",
                                        attrs: {
                                          "vretail-price": _vm.vretailPrice,
                                          "vpromotion-price":
                                            _vm.vpromotionPrice,
                                          "vpromotion-type": _vm.vpromotionType,
                                          "vpromotion-value":
                                            _vm.vpromotionValue,
                                          "vgoods-id": _vm.vgoodsId,
                                          "vsku-data": _vm.vskuData,
                                          "vshop-struct": _vm.vshopStruct,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.misShow && _vm.isRead
                                    ? _c("match-goods-tabs", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.misShow,
                                            expression: "misShow",
                                          },
                                        ],
                                        ref: "matchGoodsTabs",
                                        attrs: {
                                          "mretail-price": _vm.mretailPrice,
                                          "mpromotion-price":
                                            _vm.mpromotionPrice,
                                          "msku-num": _vm.mskuNum,
                                          "mgoods-id": _vm.mgoodsId,
                                          "mshop-struct": _vm.mshopStruct,
                                          "mproduct-list-by-data":
                                            _vm.mproductListByData,
                                          "option-list": _vm.itemList,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "translate-drawer",
          attrs: {
            title: _vm.$t("COMPONENTS.translate"),
            visible: _vm.tDrawer,
            size: "60%",
            "show-close": false,
            "modal-append-to-body": false,
            "append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tDrawer = $event
            },
            opened: _vm.getIframe,
          },
        },
        [
          _vm.tDrawer
            ? _c("iframe", {
                ref: "iframe",
                staticClass: "iframe-class",
                attrs: { src: _vm.ifreamSrc, height: _vm.getClientHeight() },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }