<template>
  <div>
    <sticky :z-index="999" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="header">
          <label v-if=isload>
            {{ $t('shopList.shopEdit.label') }}
          </label>
          <label v-else>
            {{ $t('shopList.shopCreate.label') }}
          </label>
        </el-col>
        <el-col :span="12">
          <el-button @click="$router.back(-1)">{{ $t('COMPONENTS.return') }}</el-button>
          <el-button @click="saveModel" :disabled='checkPermission("shop.add")'>{{ $t('COMPONENTS.save') }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form
      ref="shop"
      :model="shop"
      :rules="rules"
      class="search-form-inline"
      label-suffix=":"
      label-position="left"
      label-width="120px"
    >
      <el-row>
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{ $t('shopList.shopCreate.shopInfo') }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.shopName')" prop="name" required>
                  <el-input v-model="shop.name" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.type')" prop="shopType">
                  <el-radio v-model="shop.shopType" label="BRANCH-STORE">{{ $t('shopList.shopCreate.branchShop') }}</el-radio>
                  <el-radio v-model="shop.shopType" label="MAIN-STORE">{{ $t('shopList.shopCreate.HQShop') }}</el-radio>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="shop.shopType === 'BRANCH-STORE'" :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.HQShop')" prop="parentShopId">
                  <el-select v-model="shop.parentShopId">
                    <el-option
                      v-for="item in mainStoreList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.mobile')" prop="mobile">
                  <el-input v-model="shop.mobile" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.phone')" prop="phone">
                  <el-input v-model="shop.phone" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.fix')" prop="fax">
                  <el-input v-model="shop.fax" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.ifRecommend')" prop="recommend">
                  <el-radio-group v-model="shop.recommend">
                    <el-radio v-model="shop.recommend" label="Y">{{ $t('shopList.shopCreate.recommend') }}</el-radio>
                    <el-radio v-model="shop.recommend" label="N">{{ $t('shopList.shopCreate.noRecommend') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.businessTime')" required>
                  <el-row>
                    <el-col :span="10">
                      <el-form-item prop="openTime">
                        <el-time-picker
                          v-model="shop.openTime"
                          clearable
                          format="HH:mm"
                          value-format="HH:mm"
                          :placeholder="$t('shopList.shopCreate.beginTime')"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" class="line" style="text-align: center"> - </el-col>
                    <el-col :span="10">
                      <el-form-item prop="endTime">
                        <el-time-picker
                          v-model="shop.endTime"
                          clearable
                          format="HH:mm"
                          value-format="HH:mm"
                          :placeholder="$t('shopList.shopCreate.endTime')"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.logo')" prop="logoUrl">
                  <el-upload
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadImg"
                    class="avatar-uploader"
                    accept=".jpg,.jpeg,.png,.gif"
                    :before-upload="beforeFileUpload"
                  >
                    <img
                      v-if="shop.logoUrl"
                      class="avatar"
                      :src="getImagePath(shop.logoUrl)"
                    >
                    <i class="el-icon-plus avatar-uploader-icon" />
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.banner')">
                  <el-upload
                    action="#"
                    :limit="5"
                    :on-exceed="uploadOverrun"
                    :http-request="uploadImages"
                    :on-remove="handleRemove"
                    :on-preview="handlePictureCardPreview"
                    :file-list="bannerUrl"
                    multiple
                    accept=".jpg,.jpeg,.png,.gif"
                    list-type="picture-card"
                    :before-upload="beforeFileUpload"
                  >
                    <i class="el-icon-plus" />
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <el-image width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <!-- config card-->
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{$t('shopList.stripePay.title')}}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.stripePay.livePublishabelKey')" prop="wublishabel">
                  <el-input v-model="shop.stripePay.wublishabel" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.stripePay.liveSecretKey')" prop="secret">
                  <el-input v-model="shop.stripePay.secret" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.stripePay.webhookSecret')" prop="webhook">
                  <el-input v-model="shop.stripePay.webhook" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{$t('shopList.servicePlan.title')}}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.servicePlan.time')" prop="planTimeConfig">
                  <el-select v-model="shop.servicePlan.planTimeConfig">
                    <el-option
                      v-for="item in planConfig"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                  <span style="color:red">{{$t('shopList.servicePlan.msg.no')}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                  <span style="color:red">  {{$t('shopList.servicePlan.msg.yes')}}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <!--config card end -->
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col>
                <el-form-item :label="$t('shopList.shopCreate.address')" prop="addressDes">
                  <el-row :gutter="20">
                    <el-col :span="5">
                      <el-select
                        v-model="countryId"
                        filterable
                        @change="handleCountryChange"
                      >
                        <el-option
                          v-for="item in countrysList"
                          :key="item.id"
                          :label="item.description"
                          :value="item.id"
                        />
                      </el-select>
                    </el-col>
                    <el-col v-for="(type, i) in countryType" :key="type.id" :span="5">
                      <el-select
                        v-model="parentId[i]"
                        filterable
                        @change="handleLocTypeChange(i)"
                      >
                        <el-option
                          v-for="item in getLocation(i)"
                          :key="item.id"
                          :label="item.description"
                          :value="item.id"
                        />
                      </el-select>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.addressInfo')" prop="desc">
                  <el-input v-model="shop.desc" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.mapType')">
                  <el-select v-model="shop.mapType">
                    <el-option label="百度地图" value="baidu" />
                    <el-option label="谷歌地图" value="google" />
                    <el-option label="高德地图" value="gaode" />
                    <el-option label="腾讯地图" value="tenxun" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="20">
                <el-form-item :label="$t('shopList.shopCreate.searchAddress')">
                  <el-input v-model="addressKeyword" />
                </el-form-item>
                <baidu-map
                  class="bmView"
                  :scroll-wheel-zoom="true"
                  :zoom="zoom"
                  :center="{ lng: center.lng, lat: center.lat }"
                  :ak="map.ak"
                  @click="getLocationPoint"
                  @ready="handler"
                >
                  <bm-view style="width: 100%; height: 500px; flex: 1" />
                  <bm-local-search
                    :keyword="addressKeyword"
                    :auto-viewport="true"
                    style="display: none"
                  />
                </baidu-map>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.longitude')">
                  <el-input v-model="shop.lon" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.latitude')">
                  <el-input v-model="shop.lat" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { imgUpload } from '@/api/uploadApi'
import { countryList, loctionTypeList, locList } from '@/api/countryApi'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import { shopSave, findMainStore } from '@/api/shop'
import { checkCfsPermission } from '@/utils/permission';
// 百度地图
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
import { fetchLanguageList } from '@/api/languageApi'
import { getLanguage } from '../../lang'
import { fileSizeConfig } from '@/api/mallorderApi/systemConfigApi'
import { getFileType, parseStrToInt } from '@/utils'
export default {
  name: 'ShopCreate',
  components: { Sticky, BaiduMap, BmView, BmLocalSearch },
  data() {
    return {
      planConfig:["Y","N"],
      language: [],
      map: {
        ak: 'FWMGusIlEj652F3iS5h2Q8FXb7hUddz6'
      },
      center: {
        lng: 116.403964,
        lat: 39.915097
      },
      zoom: 15,
      addressKeyword: '',
      dialogVisible: false,
      dialogImageUrl: '',
      isload: false,
      shopId: null,
      countryType: [],
      bannerUrl: [],
      parentId: [],
      location: [],
      countrysList: [],
      countryId: '',
      country: {
        id: '',
        description: ''
      },
      locationDtl: [],
      mainStoreList: [],
      shop: {
        id: '',
        name: '',
        bannerGroupUrl: [],
        bannerGroupUrlId: '',
        logoUrlId: '',
        logoUrl: '',
        shopType: 'BRANCH-STORE',
        address: '',
        recommend: 'Y',
        mobile: '',
        phone: '',
        fax: '',
        lat: '',
        lon: '',
        mapType: '',
        openTime: '09:00',
        endTime: '18:00',
        score: '',
        mapUrl: '',
        desc: '',
        addressDes: '',
        parentShopId: '',
        stripePay:{
          wublishabel:'',
          secret:'',
          webhook:''
        },
        servicePlan:{
          planTimeConfig:''
        }
      },
      rules: {
        shopType: [{ required: true, message: this.$t('rule.shopType'), trigger: 'change' }],
        name: [{ required: true, message: this.$t('rule.shopName'), trigger: 'blur' }],
        phone: [{ required: true, message: this.$t('rule.shopPhone'), trigger: 'blur' }],
        fax: [{ required: true, message: this.$t('rule.shopFax'), trigger: 'blur' }],
        recommend: [{ required: true, message: this.$t('rule.shopRecommend'), trigger: 'change' }],
        parentShopId: [{ required: true, message: this.$t('rule.parentShopId'), trigger: 'change' }],
        openTime: [
          {
            required: true,
            message: this.$t('rule.beginTime'),
            trigger: 'change'
          }
        ],
        endTime: [
          {
            required: true,
            message: this.$t('rule.endTime'),
            trigger: 'change'
          }
        ],
        logoUrl: [{ required: true, message: this.$t('rule.logo'), trigger: 'change' }],
        addressDes: [
          {
            required: true,
            validator: (rule, value, cb) => {
              console.log('shop---', this.shop)
              if (!this.shop.addressDes.country) {
                cb(new Error(this.$t('rule.country')))
              } else {
                for (var i = 0; i < this.countryType.length; i++) {
                  if (!this.shop.addressDes.addressLocations) {
                    cb(new Error(this.$t('rule.addressLocations')))
                  }
                }
              }
              cb()
            },
            trigger: 'change'
          }
        ]
      },
      fileImageSize: null
    }
  },
  mounted() {
    this.loadData()
  },
  created() {
    this.getList()
  },

  methods: {
    async loadData() {
      const imgConfig = await fileSizeConfig('IMAGE')
      if (imgConfig) {
        const itemValue = imgConfig.itemValue
        this.fileImageSize = parseStrToInt(itemValue)
      }
      const _this = this
      fetchLanguageList().then(response => {
        _this.language = response.data.items
      })
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    handler({ BMap, map }) {
      console.log('map---', map)
      var point = new BMap.Point(this.center.lng, this.center.lat)
      var geolocation = new BMap.Geolocation()
      var _this = this
      geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() === 0) {
          var mk = new BMap.Marker(r.point)
          map.addOverlay(mk)
          map.panTo(r.point)
          _this.shop.lon = r.point.lng
          _this.shop.lat = r.point.lat
          console.log(r.point.lng, r.point.lat)
        } else {
          console.log('failed' + this.getStatus())
          alert('failed location!' + this.getStatus())
        }
      })
      var marker = new BMap.Marker(point)
      map.addOverlay(marker)
      window.map = map
      window.BMap = BMap
    },
    getLocationPoint(e) {
      console.log(e)
      console.log(e.point.lng)
      console.log(e.point.lat)
      this.shop.lon = e.point.lng
      this.shop.lat = e.point.lat
      window.map.clearOverlays()
      var point = new window.BMap.Point(e.point.lng, e.point.lat)
      var marker = new window.BMap.Marker(point)
      window.map.addOverlay(marker) // 将标注添加到地图中
    },
    handlePictureCardPreview(file) {
      console.log('file-----', file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getList() {
      countryList().then((res) => {
        this.countrysList = res
      })
      findMainStore().then((res) => {
        this.mainStoreList = res.data
      })
    },
    beforeFileUpload(file) {
      const fileType = getFileType(file)
      if (fileType !== 'IMAGE') {
        this.$message.error(this.$t('file.formatErrMsg'))
        return false
      }
      const size = file.size
      const defaultSize = this.fileImageSize
      if (defaultSize && defaultSize > 0) {
        const filterSize = defaultSize * 1024
        if (size > filterSize) {
          const msg = this.$t('file.sizeLimitMsg') + ': ' + defaultSize + 'KB'
          this.$message.error(msg)
          return false
        }
      }
      return true
    },
    uploadImg(file) {
      console.log(file)
      var newFile = file.file
      var formData = new FormData()
      formData.append('files', newFile)
      imgUpload(formData).then((res) => {
        this.shop.logoUrl = res[0].url
      })
    },
    uploadImages(file) {
      var newFile = file.file
      var formData = new FormData()
      formData.append('files', newFile)
      imgUpload(formData).then((res) => {
        console.log(res[0])

        this.shop.bannerGroupUrl.push({ id: null, url: res[0].url })
        this.bannerUrl.push({ name: res[0].url, url: getStaticSourcePath(res[0].url) })
        console.log(this.shop.bannerGroupUrl)
      })
    },
    uploadOverrun: function() {
      this.$message({
        type: 'error',
        message: this.$t('rule.uploadMax')
      })
    },
    handleRemove(file, fileList) {
      for (var i = 0; i < this.shop.bannerGroupUrl.length; i++) {
        var url
        if (file.response === undefined) {
          url = file.url
        } else {
          url = file.response.data.url
        }
        console.log(url)
        if (getStaticSourcePath(this.shop.bannerGroupUrl[i].url) === url) {
          this.shop.bannerGroupUrl.splice(i, 1)
          this.bannerUrl.splice(i, 1)
        }
      }
      console.log(this.shop.bannerGroupUrl)
    },
    handleCountryChange() {
      for (var i = 0; i < this.countrysList.length; i++) {
        if (this.countryId === this.countrysList[i].id) {
          this.country.id = this.countryId
          this.country.description = this.countrysList[i].description
          this.countryType = this.countrysList[i].locationTypes
          console.log('country', this.country)
          this.shop.addressDes = { country: this.country }
          loctionTypeList(this.countryId, this.countrysList[i].locationTypes[0].id).then(
            (res) => {
              console.log(res)
              this.location.splice(0, this.location.length, { loc: res })
            }
          )
        }
      }
      this.parentId = []
    },
    getLocation(index) {
      if (this.location[index]) {
        return this.location[index].loc
      }
      return null
    },
    handleLocTypeChange(i) {
      var description = ''
      for (var j = 0; j < this.location[i].loc.length; j++) {
        if (this.parentId[i] === this.location[i].loc[j].id) {
          description = this.location[i].loc[j].description
        }
      }
      var addressLocation = {
        id: this.parentId[i],
        description: description,
        locTypeId: this.countryType[i].id
      }
      this.locationDtl.splice(i, this.locationDtl.length - i, addressLocation)
      this.shop.addressDes = {
        country: this.country,
        addressLocations: this.locationDtl
      }
      locList(this.countryId, this.parentId[i]).then((res) => {
        this.location.splice(i + 1, this.location.length - 1 - i, { loc: res })
        for (var j = i + 1; j < this.parentId.length; j++) {
          this.parentId[j] = ''
        }
      })
    },
    getImagePath(path) {
      return getStaticSourcePath(path)
    },
    saveModel() {
      this.shop.addressDes = {
        country: this.country,
        addressLocations: this.locationDtl,
        addressLocationDesc: this.shop.desc
      }
      console.log('+++', this.shop)
      this.$refs['shop'].validate((valid) => {
        if (valid) {
          shopSave(this.shop)
            .then((res) => {
              this.$message({
                type: 'success',
                message: this.$t('COMPONENTS.save_Success')
              })
              this.$router.back(-1)
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: this.$t('COMPONENTS.save_Filad')
              })
            })
        } else {
          return false
        }
      })
    },
    sleep(delay) {
      for (var t = Date.now(); Date.now() - t <= delay;);
    }
  }
}
</script>

<style scoped lang="scss">
.el-tag + .el-tag {
  margin-left: 10px;
}

.input-new-keyword {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

/deep/.avatar-uploader .el-upload {
  width: 145px;
  height: 145px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

//在样式把这个is-ready给隐藏掉就好了
/deep/.el-upload-list__item.is-ready {
  display: none;
}

.avatar {
  width: 145px;
  height: 145px;
  display: block;
}
.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}
.card-box {
  margin: 0.5rem 0.5rem 0;
  .card-title {
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
}
</style>
