import Vue from 'vue'

// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading = null

let needLoadingRequestCount = 0 // 声明一个对象用于存储请求个数
function startLoading(targetdq) {
  loading = Vue.prototype.$loading({
    lock: true,
    text: 'loading...',
    background: 'rgba(255,255,255,.4)',
    target: document.querySelector(targetdq) // 设置加载动画区域
  })

  setInterval(() => {
    // 每隔5秒发一次请求，获取到百分比
    const numText = sessionStorage.getItem('loadingTimePercent')// 得到百分比
    if (numText) {
      loading.setText('loading...' + numText + '%')
    }
  }, 1000)
}

function endLoading() {
  loading.close()
}
export function showFullScreenLoading(targetdq) {
  if (needLoadingRequestCount === 0) {
    startLoading(targetdq)
  }
  needLoadingRequestCount++
}
export function hideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

export default {
  showFullScreenLoading,
  hideFullScreenLoading
}
