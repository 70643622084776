<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">
          {{ edit ? $t("Edit Ingredient") : $t("Add New Ingredient") }}
        </el-col>
        <el-col :span="12">
          <el-button
            :disabled="disabledTranslateBtn"
            @click="translate()"
          >{{
            $t("COMPONENTS.translate")
          }}</el-button>
          <el-button @click="$router.back(-1)">{{
            $t("COMPONENTS.return")
          }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            @click="handleSave"
          >{{ $t("COMPONENTS.save") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form :model="editingRecord" label-width="200px">
      <el-row>
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Ingredient Name')">
                  <el-input v-model="editingRecord.name" style="width: 200px" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Ingredient Category (Product Page)')">
                  <el-select-tree
                    v-model="editingRecord.defaultIngredientProductTreeId"
                    clearable
                    :data="ingredientProductTreeData"
                    :multiple="false"
                    check-strictly
                    check-on-click-node
                    :render-after-expand="false"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Ingredient Category (Daily Menu Page)')">
                  <el-select-tree
                    v-model="editingRecord.defaultIngredientDailyMenuTreeId"
                    clearable
                    :data="ingredientDailyMenuTreeData"
                    :multiple="false"
                    check-strictly
                    check-on-click-node
                    :render-after-expand="false"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Link Type')">
                  <el-select v-model="editingRecord.linkType">
                    <el-option
                      v-for="data in linkTypeList"
                      :key="data.value"
                      :label="data.label"
                      :value="data.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- 之後再開放

            <el-row v-if="editingRecord.linkType === 'MUTI_PRODUCT_ID'" :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Please choose mutiple product')">
                  <el-transfer
                    v-model="editingRecord.selectProductId"
                    filterable
                    :titles="[$t('tagList.unselected'), $t('tagList.selected')]"
                    filter-placeholder="Search here"
                    :data="productList"
                  />
                </el-form-item>
              </el-col>
            </el-row>
 -->

            <el-row v-if="editingRecord.linkType === 'ONE_PRODUCT_ID'" :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Please choose one product')">
                  <el-transfer
                    id="ingredientPageTransfer"
                    v-model="editingRecord.selectProductId"
                    filterable
                    :titles="[$t('tagList.unselected'), $t('tagList.selected')]"
                    filter-placeholder="Search here"
                    :filter-method="filterMethod"
                    :data="productList"
                    @change="handleTransferChange"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="editingRecord.linkType === 'URL'" :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('URL')">
                  <el-input
                    v-model="editingRecord.linkUrl"
                    style="width: 500px"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Status')">
                  <el-select v-model="editingRecord.status">
                    <el-option
                      v-for="(item, index) in statusOptions"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Wiki Url')">
                  <el-input
                    v-model="editingRecord.wikiUrl"
                    style="width: 200px"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Image')" prop="imgUrl2">
                  <el-upload
                    action="#"
                    :http-request="uploadImages"
                    :file-list="elImgObj"
                    accept=".jpg,.jpeg,.png,.gif"
                    class="avatar-uploader"
                    :show-file-list="false"
                    :before-upload="(file) => beforeFileUpload(file, 'IMAGE')"
                  >
                    <img v-if="imgUrl2" :src="imgUrl2" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon" />
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Description')">
                  <el-input
                    v-model="editingRecord.description"
                    type="textarea"
                    :rows="5"
                    style="width: 500px"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Ingredient Prepare Video')">

                  <el-table
                    :key="componentKey"
                    tooltip-effect="dark"
                    :data="editingRecord.videos"
                    style="width: 800px"
                    header-row-class-name="table-header"
                    size="mini"
                  >
                    <el-table-column :label="$t('Video Tag') + ' (' + $t('For admin page') + ')'" prop="tag" width="250px" align="left" />
                    <el-table-column :label="$t('Video')" prop="file" width="200px" align="left">
                      <template slot-scope="{ row }">
                        <video v-if="row.file !== null" :src="row.file.url" class="avatar" controls="controls" />
                      </template>
                    </el-table-column>

                    <el-table-column prop="applyNum" width="150px" align="left">
                      <template v-slot:header="scope">
                        <span>
                          {{ $t('Apply Quantity') }}
                          <el-tooltip
                            :aa="scope"
                            class="item"
                            effect="dark"
                            placement="top"
                          >
                            <i class="el-icon-question" />
                            <div slot="content">
                              {{ $t('apply_quantity_remark') }}
                            </div>
                          </el-tooltip>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" width="200px">
                      <template slot-scope="scope">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="editVideo(scope.$index, scope.row)"
                        >{{ $t('COMPONENTS.edit') }}</el-button>
                        <el-button
                          :disabled="scope.row.applyNum !== 0"
                          size="mini"
                          @click="deleteVideoItem(scope.$index, scope.row)"
                        >{{ $t('COMPONENTS.delete') }}</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <p />
                  <el-button type="primary" style="width: 750px" @click="addVideo()">{{ $t('COMPONENTS.add') }}</el-button>

                  <el-dialog
                    custom-class="dialog-class"
                    :title="$t('Ingredient Prepare Video')"
                    :visible.sync="showEditVideoDialog"
                    width="50%"
                  >
                    <el-form ref="temp" :model="videoDialogTempData" label-width="180px">
                      <el-form-item prop="tag" :label="$t('Video Tag')">
                        <el-input
                          v-model="videoDialogTempData.tag"
                          style="width: 200px"
                        />
                      </el-form-item>
                      <p />
                      <el-form-item :label="$t('Video')">

                        <el-upload
                          v-if="videoDialogTempData.file === null"
                          action="#"
                          :http-request="uploadVideo"
                          :accept="acceptVideoType"
                          list-type="picture-card"
                          :before-upload="(file) => beforeFileUpload(file, 'VIDEO')"
                        >
                          <i class="el-icon-plus" />
                        </el-upload>
                        <video v-if="videoDialogTempData.file !== null" :src="videoDialogTempData.file.url" class="avatar" controls="controls" />
                        <br>
                        <a v-if="videoDialogTempData.file !== null" href="javascript:void(0)" @click="deleteVideoFile()"><i class="el-icon-delete" /></a>

                      </el-form-item>
                      <p />
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                      <el-button @click="showEditVideoDialog = false">{{ $t("COMPONENTS.return") }}</el-button>
                      <el-button type="primary" @click="saveVideo()">{{ $t("COMPONENTS.save") }}</el-button>
                    </span>
                  </el-dialog>

                </el-form-item>
              </el-col>
            </el-row>

          </el-card>
        </el-col>
      </el-row>
    </el-form>
    <el-drawer
      class="translate-drawer"
      :title="$t('COMPONENTS.translate')"
      :visible.sync="tDrawer"
      size="60%"
      :show-close="false"
      :modal-append-to-body="false"
      :append-to-body="false"
      destroy-on-close
      @opened="getIframe"
    >
      <iframe
        v-if="tDrawer"
        ref="iframe"
        :src="ifreamSrc"
        class="iframe-class"
        :height="getClientHeight()"
      />
    </el-drawer>
  </div>
</template>
<script>
import { API_CFS_SERVER_URL } from '@/Config'
import Sticky from '@/components/Sticky'
import { adminGetIngredientById, saveOrUpdateIngredient } from '@/api/ingredientApi'
import { getCfsTreeList } from '@/api/cfsApi/cfsTreeLIstApi'
import { imgUploadToOrderService } from '@/api/uploadApi'
import ElSelectTree from 'el-select-tree'
import { getLanguage } from '@/lang/index'
import { queryAllProductName } from '@/api/productItem'
import { findStorageConfig } from '@/api/mallorderApi/systemConfigApi'
import { getFileType, parseStrToInt } from '@/utils'
export default {
  name: 'IngredientCreate',
  components: { Sticky, ElSelectTree },
  data() {
    return {
      acceptVideoType: '.mp4,.AVI,.mpg,.wmv,.mov',
      fileImageSize: null,
      fileVideoSize: null,
      storageType: 'DISK',
      showEditVideoDialog: false,
      componentKey: 0,
      productList: [],
      linkTypeList: [
        { value: 'NA', label: this.$t('None') }, // 無
        { value: 'URL', label: this.$t('Go to URL') }, // 前往URL
        { value: 'ONE_PRODUCT_ID', label: this.$t('Go to a product') } // 前往到一個產品
        // { value: "MUTI_PRODUCT_ID", label: this.$t("Go to multiple products") },   //前往到多個產品  //之後再開放
      ],
      ifreamSrc: '',
      tDrawer: false,
      edit: true,
      rowCount: 1,
      ingredientDailyMenuTreeData: [],
      ingredientProductTreeData: [],
      categoryName: '',
      statusOptions: [
        { value: 'A', label: this.$t('Active') },
        { value: 'I', label: this.$t('Inactive') }
      ],
      imgUrl2: '',
      elImgObj: [],
      editingRecord: {
        id: undefined,
        name: '',
        description: '',
        wikiUrl: '',
        defaultIngredientDailyMenuTreeId: [],
        defaultIngredientProductTreeId: '',
        imgUrl: '',
        status: 'A',

        selectProductId: [], // 如果linkType是ONE_PRODUCT_ID或者MUTI_PRODUCT_ID，才會用到
        linkUrl: null, // 如果linkType是URL，才會用到
        linkType: 'NA', // 預設值NA
        videos: []
      },
      languageOptions: [],
      countryList: [],
      locationTypes: null,
      ingredientTypeData: [],
      ingredientTypeDataSelect: [],
      videoDialogTempData: {
        id: null,
        tag: null,
        file: null,
        applyNum: 0
      },
      disabledTranslateBtn: false
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {

    addVideo() {
      this.showEditVideoDialog = true
      this.videoDialogTempData = {
        id: null,
        tag: null,
        file: null,
        applyNum: 0
      }
    },
    checkVideos() {
      const saveData = this.videoDialogTempData

      if (this.isNull(saveData.tag) || this.isNull(saveData.tag.trim())) {
        const msg = this.$t('Tag is required')
        this.$message.error(msg)
        return false
      } else if (saveData.file == null) {
        const msg = this.$t('Video is required')
        this.$message.error(msg)
        return false
      }

      return true
    },
    saveVideo() {
      if (this.checkVideos()) {
        this.showEditVideoDialog = false
        if (!this.editingRecord.videos) {
          this.editingRecord.videos = []
        }

        let isNewData = true
        const saveData = JSON.parse(JSON.stringify(this.videoDialogTempData))
        const videos = []
        this.editingRecord.videos.map(item => {
          if (!this.isNull(saveData.id) && item.id === saveData.id) {
            videos.push(saveData)
            isNewData = false
          } else {
            videos.push(item)
          }
        })

        if (isNewData) {
          videos.push(saveData)
        }

        this.editingRecord.videos = videos

        this.videoDialogTempData = {
          id: null,
          tag: null,
          file: null,
          applyNum: 0
        }
        this.disabledTranslateBtn = true
        // this.forceRerender()
      }
    },
    deleteVideoItem(index, row) {
      console.log('deleteVideo row===>', row)
      const videos = []
      this.editingRecord.videos.map(item => {
        if (item.id !== row.id) {
          videos.push(item)
        }
      })
      this.editingRecord.videos = videos
      this.videoDialogTempData = {
        id: null,
        tag: null,
        file: null,
        applyNum: 0
      }
    },
    deleteVideoFile() {
      this.videoDialogTempData.file = null
      console.log('deleteVideoFile')
    },
    editVideo(index, row) {
      console.log('editVideo row===>', row)
      this.showEditVideoDialog = true
      this.videoDialogTempData = JSON.parse(JSON.stringify(row))
    },

    handleTransferChange(value, direction, movedKeys) {
      if (direction === 'right') {
        if (movedKeys.length !== 1) {
          this.editingRecord.selectProductId = []

          this.$message.error(this.$t('Only one product can be selected'))
        } else if (this.editingRecord.selectProductId.length > 1 || movedKeys.length !== 1) {
          this.editingRecord.selectProductId = [this.editingRecord.selectProductId[0]]

          this.$message.error(this.$t('Only one product can be selected')) // 只允許添加一個商品
        }
      }
    },
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    translate() {
      if (this.width > 0) {
        return
      }
      this.tDrawer = true
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    loadData() {
      const permissionList = []

      permissionList.push(getCfsTreeList({ belongId: 'DEFAULT', usageTypeCode: 'PRODUCT_INGREDIENT' }))
      permissionList.push(getCfsTreeList({ belongId: 'DEFAULT', usageTypeCode: 'INGREDIENT' }))

      permissionList.push(queryAllProductName())

      permissionList.push(findStorageConfig())

      let i = 0

      Promise.all(permissionList).then((respon) => {
        console.log('image config size ======>', respon[3])
        if (respon[3]) {
          const allConfig = respon[3]
          this.fileImageSize = parseStrToInt(allConfig['IMAGE'].itemValue)
          this.fileVideoSize = parseStrToInt(allConfig['VIDEO'].itemValue)
          this.storageType = parseStrToInt(allConfig['STORAGETYPE'].itemValue)
        }

        const ingredientProductTree = respon[i++].data
        this.ingredientProductTreeData = this.renderTree(ingredientProductTree)

        const ingredientDailyMenuTree = respon[i++].data
        this.ingredientDailyMenuTreeData = this.renderTree(ingredientDailyMenuTree)

        console.log('asdcfxzxcv this.ingredientDailyMenuTreeData', this.ingredientDailyMenuTreeData)
        console.log('asdcfxzxcv this.ingredientProductTreeData', this.ingredientProductTreeData)

        // 取食材
        const ingredientId = this.$route.query.attrId
        if (ingredientId) {
          this.getIngredient(ingredientId)
        } else {
          this.disabledTranslateBtn = true
        }

        // const ingredientTypeData = respon[1].data
        // const ingredientTypeDataSelect = []

        // ingredientTypeData.map((data) => {
        //   ingredientTypeDataSelect.push({
        //     value: data.id,
        //     label: this.translateCfsValue(data)
        //   })
        // })

        // this.ingredientTypeData = ingredientTypeData
        // this.ingredientTypeDataSelect = ingredientTypeDataSelect

        const allProduct = respon[i++].data
        const productListTmp = []
        if (allProduct && allProduct.length !== 0) {
          allProduct.map((item) => {
            const label = item.adminTag + ' (' + item.itemStatus + ')'
            productListTmp.push({
              pinyin: label,
              key: item.baseProductId,
              label: label
            })
          })
        }
        this.productList = productListTmp
      })
    },
    getFormData(file) {
      const newFile = file.file
      const formData = new FormData()
      formData.append('files', newFile)
      return formData
    },
    uploadVideo(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        const absoluteUrl = res[0].mediaUrl

        this.videoDialogTempData.file = {
          name: name,
          url: absoluteUrl,
          urlPath: url
        }
        console.log('this.videoDialogTempData', this.videoDialogTempData)
      })
    },

    beforeFileUpload(file, type) {
      const fileType = getFileType(file)
      if (fileType && fileType !== type) {
        this.$message.error(this.$t('file.formatErrMsg'))
        return false
      }
      const size = file.size
      let defaultSize = -1
      if (type === 'IMAGE') {
        defaultSize = this.fileImageSize
      } else if (type === 'VIDEO') {
        defaultSize = this.fileVideoSize
      }
      if (defaultSize && defaultSize > 0) {
        const filterSize = defaultSize * 1024
        if (size > filterSize) {
          const msg = this.$t('file.sizeLimitMsg') + ': ' + defaultSize + 'KB'
          this.$message.error(msg)
          return false
        }
      }
      return true
    },
    uploadImages(file) {
      var newFile = file.file
      var formData = new FormData()
      formData.append('files', newFile)
      imgUploadToOrderService(formData).then((res) => {
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl
        this.elImgObj.push({
          name: url,
          url: absoluteUrl
        })
        this.imgUrl2 = absoluteUrl
        this.editingRecord.imgUrl = url
      })
    },
    renderTree(treeData) {
      const tree = []
      treeData.map((node, i) => {
        const id = node.id
        const title = node.treeItemTag.name
        const children = node.children
        // const hasChildren = node.children.length !== 0
        let hasChildren = false
        if (children && children.length !== 0) {
          hasChildren = true
        }
        const date = {
          value: id,
          label: title
        }
        if (hasChildren) {
          date.children = this.renderTree(children)
        }
        tree.push(date)
      })
      return tree
    },
    forceRerender() {
      this.componentKey += 1
    },
    filterMethod(query, item) {
      let result = true
      if (!this.isNull(query)) {
        var queryArray = query.split(' ')
        if (!this.isNull(queryArray) && queryArray.length !== 0) {
          // result = true

          for (const data of queryArray) {
            if (item.pinyin.indexOf(data) === -1) {
              result = false
            }
          }
        }
      }
      return result
    },
    getIngredient(ingredientId) {
      adminGetIngredientById(ingredientId).then((response) => {
        const data = response.data
        console.log('adminGetIngredientById', data)

        if (!this.isNull(data.imgUrl)) {
          // 放圖片
          const url = data.imgUrl
          const apiUrl = data.apiUrl
          const absoluteUrl = apiUrl + url
          this.elImgObj.push({
            name: url,
            url: absoluteUrl
          })
          this.imgUrl2 = absoluteUrl
        }

        // 放資料
        if (data !== null) {
          if (data.videos && data.videos.length !== 0) {
            data.videos.map(item => {
              item.file.url = item.file.mediaUrl
            })
          }

          this.editingRecord = data
        }

        this.forceRerender()
      })
    },
    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    isValidURL(url) {
      if (!url) {
        return false
      }
      var RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      if (RegExp.test(url)) {
        return true
      } else {
        return false
      }
    },
    checkData() {
      const record = this.editingRecord
      console.log('checkDatacheckDatacheckDatacheckData', record)
      let msg = null

      if (this.isNull(record.name)) {
        msg = this.$t('Ingredient Name')
      } else if (record.defaultIngredientProductTreeId === undefined || record.defaultIngredientProductTreeId === null || record.defaultIngredientProductTreeId.length === 0) {
        msg = this.$t('Ingredient Category (Product Page)')
      } else if (record.defaultIngredientDailyMenuTreeId === undefined || record.defaultIngredientDailyMenuTreeId === null || record.defaultIngredientDailyMenuTreeId.length === 0) {
        msg = this.$t('Ingredient Category (Daily Menu Page)')
      } else if (this.isNull(record.imgUrl)) {
        msg = this.$t('Image')
      }

      if (this.editingRecord.linkType === 'URL') {
        if (this.isNull(this.editingRecord.linkUrl)) {
          msg = this.$t('URL')
        } else if (!this.isValidURL(this.editingRecord.linkUrl)) {
          msg = this.$t('Wrong URL')
          this.$message.error(msg)
          return false
        }
      }

      if (this.editingRecord.linkType === 'ONE_PRODUCT_ID') {
        if (this.editingRecord.selectProductId.length === 0) {
          msg = this.$t('Product')
        }
      }

      if (msg !== null) {
        msg += ' ' + this.$t('cannot be empty')
        this.$message.error(msg)
        return false
      } else {
        return true
      }
    },
    handleSave() {
      console.log('saveOrUpdateIngredient=>', this.editingRecord)

      // return;

      if (this.checkData()) {
        saveOrUpdateIngredient(this.editingRecord).then((data) => {
          console.log('saveOrUpdateIngredient=>', data)

          if (
            data.returnType === 'FAIL' &&
            data.msg === 'INGREDIENT_NAME_EXSITS'
          ) {
            this.$message.error(this.$t('Ingredient name already exists')) // Ingredient name already exists!
            return
          } else {
            this.$message.success(this.$t('COMPONENTS.save_Success'))
            this.$router.back(-1)
          }
        })
      }
    },
    handleMessage(e) {
      console.log(e.data)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleDrawerClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleDrawerClose()
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleDrawerClose() {
      this.tDrawer = false
    },
    getIframe(e) {
      const id = this.editingRecord.id

      if (!id) {
        return
      }

      var defaultLang = getLanguage()
      var systemLanguageCodes = JSON.parse(localStorage.getItem('SYSTEM_LANGUAGE_CODES'))
      if (systemLanguageCodes && systemLanguageCodes.data && systemLanguageCodes.data.defaultLanguage) {
        defaultLang = systemLanguageCodes.data.defaultLanguage
      }
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      this.ifreamSrc =
        ifreamSrc +
        '?system=gallery&module=admin&function=ingredient&isIframe=true&language=' +
        defaultLang + '&sytemLanguage=' + getLanguage() + '&no=' + Math.ceil(Math.random() * 100)

      var data = [
        { name: this.$t('Ingredient Name'), key: id + '.recipe.ingredient.name', type: 'input', value: this.editingRecord.name },
        { name: this.$t('Wiki Url'), key: id + '.recipe.ingredient.wikiUrl', type: 'input', value: this.editingRecord.wikiUrl },
        { name: this.$t('Description'), key: id + '.recipe.ingredient.description', type: 'input', value: this.editingRecord.description }
      ]

      console.log('iframe 对象', this.$refs.iframe)

      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
      console.log('iframe 对象', this.$refs.iframe)
    }
  }
}
</script>

<style lang="scss">
//左邊的框框
#ingredientPageTransfer > .el-transfer-panel:first-child{
  width: 410px;
};
//右邊的框框
#ingredientPageTransfer > .el-transfer-panel:last-child{
  width: 410px;
};

.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.el-upload-list__item.is-ready {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  float: left;
  margin-right: 10px;
}
#product-form {
  padding-bottom: 0.5rem;
}
.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}
.card-box {
  margin: 0.5rem 0.5rem 0;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}
.el-drawer {
  overflow: scroll;
}
.el-drawer__body {
  margin-left: 20px;
}
.el-drawer__header {
  background-color: #2ea6f1;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.el-drawer__header > :first-child {
  margin-bottom: 18px;
}
.el-drawer__body {
  margin-top: -22px;
}
.iframe-class {
  width: 100%;
  border: 0px;
}

</style>
