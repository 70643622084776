import axios from 'axios'
import { Message } from 'element-ui'
import { getAccessToken } from '@/service/AuthService'
import pageLoading from '@/utils/pageLoading'
import { logout } from '@/service/AuthService'
import { getLanguage } from '@/lang/index'
// import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 60// request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    pageLoading.showFullScreenLoading()
    // do something before request is sent
    if (getAccessToken()) { // store.getters.token
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getAccessToken()

      // jimmy for oidc
      config.headers['Authorization'] = 'Bearer ' + getAccessToken()
    }
    if (getLanguage()) {
      config.headers['language'] = getLanguage()
    }

    config.onUploadProgress = progressEvent => {
      const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      sessionStorage.setItem('loadingTimePercent', percent)
      console.log('請求進度', percent)
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    pageLoading.hideFullScreenLoading()
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    // if (!res.code) {
    //   return res
    // }
    if (!res) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      // return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    pageLoading.hideFullScreenLoading()
    console.log('err' + error) // for debug
    console.log('resp:', error.response)
    if (error.request.status === 401 || error.request.status === 403) {
      // location.href = '/login'
      // router.history.push("/login");
      logout()
      // console.log('routerrouterrouterrouter', router)
      return Promise.reject(error)
    }
    if (error.response.data.message) {
      Message({
        message: error.response.data.message,
        type: 'error',
        duration: 5 * 1000
      })
    } else if (error.response.data.error) {
      Message({
        message: error.response.data.error,
        type: 'error',
        duration: 5 * 1000
      })
    } else if (error.response.data) {
      Message({
        message: error.response.data,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
