var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "simple-goods-tabs" },
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "left" } },
        [
          [
            _c(
              "el-tab-pane",
              { attrs: { label: _vm.$t("productItem.basic_price") } },
              [
                _c(
                  "el-form",
                  {
                    ref: "simpleForm",
                    attrs: { model: _vm.simpleForm, rules: _vm.rules },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("productItem.retailPrice"),
                                  prop: "retailPrice",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { placeholder: "" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onChangeRetailPrice($event)
                                      },
                                      input: function ($event) {
                                        return _vm.onChangeRetailPrice($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.simpleForm.retailPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.simpleForm,
                                          "retailPrice",
                                          $$v
                                        )
                                      },
                                      expression: "simpleForm.retailPrice",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("HK$"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { key: _vm.componentKey9, attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("productItem.promotionPrice"),
                                  prop: "promotionPrice",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.simpleForm.promotionPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.simpleForm,
                                          "promotionPrice",
                                          $$v
                                        )
                                      },
                                      expression: "simpleForm.promotionPrice",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("HK$"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("productItem.kcNum") } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.simpleForm.kcNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.simpleForm, "kcNum", $$v)
                                    },
                                    expression: "simpleForm.kcNum",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: _vm.$t("productItem.attribute"),
                  disabled: _vm.checkPermission("SHOP_PRODUCT_ATTRIBUTE"),
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { "margin-left": "0.5rem" },
                            attrs: {
                              placeholder: _vm.$t("COMPONENTS.inselect"),
                            },
                            on: { change: _vm.selProductAttribute },
                            model: {
                              value: _vm.productAttribute,
                              callback: function ($$v) {
                                _vm.productAttribute = $$v
                              },
                              expression: "productAttribute",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("productItem.custom-attribute"),
                                value: "",
                              },
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.attributesGroup, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.addCard } }, [
                          _vm._v(_vm._s(_vm.$t("COMPONENTS.add"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            on: { click: _vm.saveAttribute },
                          },
                          [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.customAttribute,
                        expression: "customAttribute",
                      },
                    ],
                    attrs: { gutter: 20 },
                  },
                  [
                    _c(
                      "el-col",
                      _vm._l(_vm.list, function (item) {
                        return _c(
                          "el-card",
                          {
                            key: item.id,
                            staticClass: "card-box",
                            style: item.styleObject,
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", [
                                  _c("i", {
                                    staticClass: "el-icon-close",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-left": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCard(item.id)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-minus",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.showUp,
                                        expression: "item.showUp",
                                      },
                                    ],
                                    staticClass: "el-icon-arrow-up",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-right": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showRow(item)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.showDown,
                                        expression: "item.showDown",
                                      },
                                    ],
                                    staticClass: "el-icon-arrow-down",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-right": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hideRow(item)
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isShow,
                                    expression: "item.isShow",
                                  },
                                ],
                                attrs: { gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  { staticStyle: { "padding-top": "20px" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#606266",
                                              "font-weight": "700",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "productItem.attributeTitle"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-autocomplete", {
                                          ref: "attrs_demo",
                                          refInFor: true,
                                          attrs: {
                                            "fetch-suggestions":
                                              _vm.querySearchAsync,
                                            placeholder: _vm.$t(
                                              "COMPONENTS.incontent"
                                            ),
                                          },
                                          on: {
                                            select: function ($event) {
                                              return _vm.handleSelect(
                                                item.attrTitle,
                                                item
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.attrTitle,
                                            callback: function ($$v) {
                                              _vm.$set(item, "attrTitle", $$v)
                                            },
                                            expression: "item.attrTitle",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-top": "15px",
                                            },
                                            attrs: {
                                              label: _vm.$t(
                                                "productItem.isRequired"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value: item.isRequired,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isRequired",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.isRequired",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "0" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "COMPONENTS.true"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "1" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "COMPONENTS.false"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-top": "15px",
                                            },
                                            attrs: {
                                              label: _vm.$t(
                                                "productItem.isMultple"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value: item.isMultple,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isMultple",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.isMultple",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "0" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "COMPONENTS.true"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "1" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "COMPONENTS.false"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#606266",
                                            "font-weight": "700",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("productItem.attribute")
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            border: "1px solid #d4d4d4",
                                            height: "140px",
                                            width: "580px",
                                            padding: "10px",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            item.attributeContents,
                                            function (tag) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: tag,
                                                  attrs: {
                                                    closable: "",
                                                    "disable-transitions": false,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.handleClose(
                                                        item,
                                                        tag
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                         " +
                                                      _vm._s(tag) +
                                                      "\n                       "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          item.inputVisible
                                            ? _c("el-input", {
                                                ref: "saveTagInput",
                                                refInFor: true,
                                                staticClass: "input-new-tag",
                                                attrs: { size: "small" },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.handleInputConfirm(
                                                      item
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.handleInputConfirm(
                                                      item
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.inputValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "inputValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.inputValue",
                                                },
                                              })
                                            : _c(
                                                "el-button",
                                                {
                                                  staticClass: "button-new-tag",
                                                  attrs: { size: "small" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showInput(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v("+ New Tag")]
                                              ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }