import request from '@/utils/request'
import { API_SERVER_URL } from '../Config'

export function saveFamilyPlan(param) {
  return request({
    url: API_SERVER_URL + '/api/admin/saveFamilyPlan',
    method: 'post',
    data: param
  })
}

export function findFamilyPlan(planId) {
  return request({
    url: API_SERVER_URL + '/api/admin/findFamilyPlan/' + planId,
    method: 'get'
  })
}

export function listFamilyPlan(param, query) {
  return request({
    url: API_SERVER_URL + '/api/admin/listFamilyPlan' + param,
    method: 'post',
    data: query
  })
}

export function adminDeleteFamilyPlan(query) {
  return request({
    url: API_SERVER_URL + '/api/admin/deleteFamilyPlan',
    method: 'delete',
    data: query
  })
}

export function adminStopFamilyPlan(id) {
  return request({
    url: API_SERVER_URL + '/api/admin/stopFamilyPlan',
    method: 'post',
    data: { id }
  })
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  )
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}
