import Layout from '@/layout'
import { checkCfsPermission } from '@/utils/permission'
import { getProductFunction } from '@/utils/productMenuFunction'

const goodsRouter = {
  path: '/goods',
  component: Layout,
  redirect: '/goods/attribute',
  alwaysShow: true,
  name: 'goods',
  meta: {
    title: 'navbar.goods_Manage.label',
    icon: 'table'
  },
  hidden: false,
  children: [

    // 維護產品拆分成很多種
    // 可以設定每一個Menu對應的category有哪些
    ...getProductFunction(),

    {
      path: 'ingredient',
      component: () => import('@/views/ingredient/ingredient'),
      name: 'ingredient',
      hidden: checkCfsPermission('INGREDIENT.LIST'),
      meta: { title: 'Ingredient' }
    },
    {
      path: 'ingredient/edit',
      component: () => import('@/views/ingredient/ingredientEdit'),
      name: 'ingredientEdit',
      meta: {
        title: 'Edit Ingredient',
        noCache: false
      },
      hidden: true
    },
    {
      path: 'ingredient/create',
      component: () => import('@/views/ingredient/ingredientEdit'),
      name: 'ingredientEdit',
      meta: {
        title: 'Add New Ingredient',
        noCache: false
      },
      hidden: true
    },

    {
      path: 'attribute',
      component: () => import('@/views/productAttribute/productAttribute'),
      name: 'attribute',
      hidden: checkCfsPermission('attribute'),
      meta: { title: 'navbar.goods_Manage.attribute.label', noCache: false }
    },
    {
      path: 'attribute/create',
      component: () => import('@/views/productAttribute/productAttributeCreat'),
      name: 'productAttrCreat',
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'attribute.attributeCreat.label', noCache: false, activeMenu: 'productAttribute/main' },
      hidden: true
    },
    {
      path: 'attribute/edit',
      component: () => import('@/views/productAttribute/productAttributeEdit'),
      name: 'productAttrEdit',
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'attribute.attributeEdit.label', noCache: false, activeMenu: 'productAttribute/main' },
      hidden: true
    },
    {
      path: 'tag',
      component: () => import('@/views/tag/tag'),
      name: 'tag',
      hidden: checkCfsPermission('tag'),
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'navbar.goods_Manage.tagList.label', noCache: false }
    },
    {
      path: 'tag/create',
      component: () => import('@/views/tag/tagEdit'),
      name: 'tagCreact',
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'tagList.tagCreat.label', noCache: false, activeMenu: 'tag/main' },
      hidden: true
    },
    {
      path: 'tag/edit',
      component: () => import('@/views/tag/tagEdit'),
      name: 'tagEdit',
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'tagList.tagEdit.label', noCache: false, activeMenu: 'tag/main' },
      hidden: true
    },
    // {
    //   path: 'category',
    //   component: () => import('@/views/category/category'),
    //   name: 'category',
    //   meta: { title: 'category', noCache: false },
    //   hidden: false
    // },
    {
      path: 'new_category',
      component: () => import('@/views/category/new_category'),
      name: 'category',
      hidden: checkCfsPermission('category'),
      meta: { title: 'navbar.goods_Manage.category.label', noCache: true }
    },
    // {
    //  path: 'categoryList',
    //  component: () => import('@/views/category/categoryList'),
    //  name: 'categoryList',
    // meta: { title: 'Sales Item', noCache: false }
    //  meta: { title: 'categoryList', noCache: false },
    //  hidden: false
    // },
    {
      path: 'attributeGroup',
      component: () => import('@/views/attributeGroup/attributeGroup'),
      name: 'attributeGroup',
      meta: { title: 'navbar.goods_Manage.attributeGroup.label' },
      hidden: checkCfsPermission('attributeGroup')
    },
    {
      path: 'attributeGroup/create',
      component: () => import('@/views/attributeGroup/attributeGroupCreate'),
      name: 'attributeGroupCreate',
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'attributeGroup.attributeGroupCreate.label', noCache: false, activeMenu: 'attributeGroup/main' },
      hidden: true
    },
    {
      path: 'attributeGroup/edit',
      component: () => import('@/views/attributeGroup/attributeGroupEdit'),
      name: 'attributeGroupEdit',
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'attributeGroup.attributeGroupEdit.label', noCache: false, activeMenu: 'attributeGroup/main' },
      hidden: true
    },
    {
      path: 'brand',
      component: () => import('@/views/brand/brand'),
      name: 'brand',
      meta: { title: 'navbar.goods_Manage.brand.label' },
      hidden: checkCfsPermission('brand')
    },
    {
      path: 'brand/create',
      component: () => import('@/views/brand/brandEdit'),
      name: 'brandEdit',
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'brand.brandCreat.label', noCache: false, activeMenu: 'brand/main' },
      hidden: true
    },
    {
      path: 'brand/edit',
      component: () => import('@/views/brand/brandEdit'),
      name: 'brandEdit',
      // meta: { title: 'Sales Item', noCache: false }
      meta: { title: 'brand.brandEdit.label', noCache: false, activeMenu: 'brand/main' },
      hidden: true
    },

    {
      path: 'productTypeTemplate',
      component: () => import('@/views/productTypeTemplate/productTypeTemplate'),
      name: 'productTypeTemplate',
      meta: { title: 'productTypeTemplate.title' },
      hidden: checkCfsPermission('PRODUCT_TYPE_TEMP.SEARCH')
    },
    {
      path: 'productTypeTemplate/create',
      component: () => import('@/views/productTypeTemplate/productTypeTemplateEdit'),
      name: 'productTypeTemplateEdit',
      meta: { title: 'productTypeTemplate.add', noCache: false, activeMenu: 'productTypeTemplate/main' },
      hidden: true
    },
    {
      path: 'productTypeTemplate/edit',
      component: () => import('@/views/productTypeTemplate/productTypeTemplateEdit'),
      name: 'productTypeTemplateEdit',
      meta: { title: 'productTypeTemplate.edit', noCache: false, activeMenu: 'productTypeTemplate/main' },
      hidden: true
    }
  ]
}
export default goodsRouter
