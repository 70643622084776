import { decrypt, jsonParse } from './CryptoJSUtils'
export const VERSION_NO = localStorage.getItem('VERSION_NO')
const clientConfigEncrypted = localStorage.getItem('mallAdmin_client_config')
console.log('# clientConfigEncrypted is:', clientConfigEncrypted)

// var aa = encrypt("van");
// console.log("# encrypt result is:", aa);
var clientConfigDecrypted = decrypt(clientConfigEncrypted)
var CLIENT_CONFIG_OBJ = jsonParse(clientConfigDecrypted)
// for dev please uncomment the url config

// export const domainUri = process.env.VUE_APP_DOMAIN
// const realm = process.env.VUE_APP_REALM
// const clientRootPath = process.env.VUE_APP_CLIENTROOT
// const apiServerUrl = process.env.VUE_APP_API_SERVER_URL
// const authServerUrl = process.env.VUE_APP_AUTH_SERVER_URL
// const redriectUri = process.env.VUE_APP_REDIRECT_URI
// const apiMemberServerUrl = process.env.VUE_APP_API_MEMBER_SERVER_URL
// const apiCfsServerUrl = process.env.VUE_APP_API_CFS_SERVER_URL
// const apiCfsServiceUrl = process.env.VUE_APP_API_CFS_SERVER_URL_SERVICE

export const domainUri = CLIENT_CONFIG_OBJ.domainUri
const realm = CLIENT_CONFIG_OBJ.realm
// const clientRootPath = CLIENT_CONFIG_OBJ.clientRootPath
const apiServerUrl = CLIENT_CONFIG_OBJ.apiServerUrl
export const authServerUrl = CLIENT_CONFIG_OBJ.authServerUrl
const redriectUri = CLIENT_CONFIG_OBJ.redriectUri
const apiMemberServerUrl = CLIENT_CONFIG_OBJ.apiMemberServerUrl
const apiCfsServerUrl = CLIENT_CONFIG_OBJ.apiCfsServerUrl
const apiCfsServiceUrl = CLIENT_CONFIG_OBJ.apiCfsServiceUrl
export const SMART_FORM_URL = CLIENT_CONFIG_OBJ.smartFormServerUrl

export const API_SERVER_URL = apiServerUrl
export const REQUEST_TIMEOUT = 60 * 60 * 1000
export const GUI_DATE_FORMAT = 'MM/DD/YYYY'
export const DB_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
// export const STATIC_DATA_URL = clientRootPath
export const REDIRECT_URI = redriectUri
export const API_MEMBER_SERVER_URL = apiMemberServerUrl
export const API_CFS_SERVER_URL = apiCfsServerUrl
export const API_CFS_SERVICE_URL = apiCfsServiceUrl

// / NOT USED ///
export const DATA_SERVER_URL = API_SERVER_URL + '/mallsales'

export const adminConsoleURI =
  authServerUrl + '/auth/admin/' + realm + '/console/'
export const updateuserUnfoURI =
  authServerUrl + '/auth/realms/' + realm + '/account/'

// ////////////////////////////////////////////////////////////////////////////////OIDC information - Start

const authUrl = authServerUrl
export const keycloakConfig = {
  url: authUrl + '/auth',
  realm: realm,
  clientId: 'malladmin'
}

// ////////////////////////////////////////////////////////////////////////////////OIDC information - End
export const API_BASE_URL_ORDER = API_SERVER_URL + '/mallsales'

export const API_BASE_URL_REFERENCE = API_SERVER_URL + '/mallreference'

export const systemcontextApi = API_SERVER_URL + '/systemcontext/api/'

export const API_BASE_URL_PRODUCT = API_SERVER_URL

// member server url
export const API_BASE_URL_MEMBER = API_MEMBER_SERVER_URL
// smart form url
// export const SMART_FORM_URL = 'https://smartform.ettest.twdcsit.tophenix.com'
