<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="150px"
        >
          <el-row :gutter="20">
            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('Member Account')">
                <el-input
                  v-model="query.loginName"
                  clearable
                  class="filter-item"
                />
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('Phone Number')">
                <el-input
                  v-model="query.mobileNo"
                  clearable
                  class="filter-item"
                />
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('Email')">
                <el-input v-model="query.email" clearable class="filter-item" />
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="12" :sm="12" :xs="24">

              <el-form-item :label="$t('Created By')">

                <el-select
                  :key="JSON.stringify(memberCreatedByQueryList)"
                  v-model="query.memberCreatedByQuery"
                  multiple
                  placeholder="Select"
                  style="width: 240px"
                  clearable
                  :disabled="memberCreatedByQueryList.length === 1"
                >
                  <el-option
                    v-for="item in memberCreatedByQueryList"
                    :key="item.value"
                    :label="item.label === '_______SEARCH_OWN_REG_MEMBER_______' ? $t('MemberPage.MEMBER_REG_SELF') : item.label"
                    :value="item.value"
                  />

                </el-select>

                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content>
                    <!-- 為什麼我只能選擇這些帳號 -->
                    <span style="font-size: 16px">{{ $t('MemberPage.TOOLTIP1_TITLE1') }}?</span>
                    <br>
                    <!-- 取決於您的帳號權限如下 -->
                    <span style="font-size: 12px">{{ $t('MemberPage.TOOLTIP1_TITLE2') }}
                      <br>
                      <br>
                      <div v-if="!checkPermission('MEMBER.SEARCH_REG_SELF')">
                        <i style="color: green" class="el-icon-success" /><span>{{ $t('MemberPage.ROLE_SEARCH_REG_SELF') }}</span><!-- 會員-搜尋-會員自己註冊的 -->
                      </div>
                      <div v-else>
                        <span style="color: gray"><i class="el-icon-error" />{{ $t('MemberPage.ROLE_SEARCH_REG_SELF') }}</span><!-- 會員-搜尋-會員自己註冊的 -->
                      </div>
                      <div v-if="!checkPermission('MEMBER.SEARCH_OWN_CREATE')">
                        <i style="color: green" class="el-icon-success" /><span>{{ $t('MemberPage.ROLE_SEARCH_OWN_CREATE') }}</span><!-- 會員-搜尋-我建立的 -->
                      </div>
                      <div v-else>
                        <span style="color: gray"><i class="el-icon-error" />{{ $t('MemberPage.ROLE_SEARCH_OWN_CREATE') }}</span><!-- 會員-搜尋-我建立的 -->
                      </div>
                      <div v-if="!checkPermission('MEMBER.SEARCH_OWN_PARTY_CREATE')">
                        <i style="color: green" class="el-icon-success" /><span>{{ $t('MemberPage.ROLE_SEARCH_OWN_PARTY_CREATE') }}</span><!-- 會員-搜尋-我公司組建立的 -->
                      </div>
                      <div v-else>
                        <span style="color: gray"><i class="el-icon-error" />{{ $t('MemberPage.ROLE_SEARCH_OWN_PARTY_CREATE') }}</span><!-- 會員-搜尋-我公司組建立的 -->
                      </div>
                      <div v-if="!checkPermission('MEMBER.SEARCH_ALL')">
                        <i style="color: green" class="el-icon-success" /><span>{{ $t('MemberPage.ROLE_SEARCH_ALL') }}</span><!-- 會員-搜尋-全會員(最大權限) -->
                      </div>
                      <div v-else>
                        <span style="color: gray"><i class="el-icon-error" />{{ $t('MemberPage.ROLE_SEARCH_ALL') }}</span><!-- 會員-搜尋-全會員(最大權限) -->
                      </div>
                    </span>
                  </template>
                  <i class="el-icon-info" />
                </el-tooltip>

              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('Store Contract')">
                <el-select
                  v-model="query.chefStatus"
                  placeholder="Select"
                  style="width: 240px"
                  clearable
                >
                  <el-option
                    v-for="item in chefStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row :gutter="20">
            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('Member Tag')">
                <el-select
                  v-model="query.memberTag"
                  multiple
                  placeholder="Select"
                  style="width: 240px"
                  clearable
                >
                  <el-option
                    v-for="item in memberTagList"
                    :key="item.id"
                    :label="item.tag"
                    :value="item.id"
                  />
                </el-select>
                <el-button
                  icon="el-icon-edit"
                  @click="openAddMemberTagDialog()"
                />
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('COMPONENTS.registerDate')">
                <el-date-picker
                  v-model="query.createdDateQuery"
                  type="daterange"
                  range-separator="~"
                  :start-placeholder="$t('COMPONENTS.beginTime')"
                  :end-placeholder="$t('COMPONENTS.endTime')"
                />
              </el-form-item>
            </el-col>

            <el-col :xl="24" :md="24" :sm="24" :xs="24">
              <div style="float: left">
                <el-button
                  :disabled="checkPermission('MEMBER.ADD')"
                  @click="handleExport"
                >{{ $t("COMPONENTS.export") }}</el-button>
                <el-button
                  :disabled="checkPermission('MEMBER.ADD')"
                  @click="openImportMemberExcelDialog"
                >{{ $t("COMPONENTS.import") }}</el-button>
              </div>
              <div style="float: right">
                <el-button
                  @click="openSendCouponModal"
                >{{ $t('Send Coupon') }}</el-button>
                <el-button
                  :disabled="data.length === 0"
                  @click="openBatchEditTagDialog"
                >{{ $t("Batch Edit Tag") }}
                </el-button>
                <el-button
                  :disabled="checkPermission('MEMBER.ADD')"
                  @click="handleCreate"
                >{{ $t("COMPONENTS.add") }}</el-button>
                <el-button type="primary" @click="btnSearchData">{{
                  $t("COMPONENTS.search")
                }}</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </transition>

    <el-table
      tooltip-effect="dark"
      :data="data"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" align="center" />
      <el-table-column
        :label="$t('Member Account')"
        align="left"
        prop="loginName"
        width="180px"
      />
      <el-table-column
        :label="$t('First Name')"
        prop="firstName"
        width="100px"
        align="left"
      />
      <el-table-column
        :label="$t('Last Name')"
        prop="lastName"
        width="120px"
        align="left"
      />

      <el-table-column :label="$t('Member Tag')" align="left" prop="memberTag" width="340px">
        <template slot-scope="scope">
          <el-tag
            v-for="item in scope.row.tags"
            :key="item.id"
            closable
            :disable-transitions="false"
            style="margin: 0 1px;"
            @close="deleteMemberTag(scope.row, item.id)"
          >
            {{ item.tag }}
          </el-tag>
          <el-button
            class="button-new-tag"
            size="small"
            @click="openSelectMemberTagDialog(scope.row)"
          >+</el-button>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Email')" align="left" prop="email" width="240px" />
      <!-- <el-table-column :label="$t('Phone Number')" align="left" prop="mobileNo" /> -->
      <el-table-column
        :label="$t('COMPONENTS.registerDate')"
        align="left"
        prop="createdDate"
        width="180px"
      />
      <el-table-column
        :label="$t('Created By')"
        prop="memberCreatedBy"
        width="150px"
        align="left"
      />
      <el-table-column :label="$t('Store Contract')" prop="chefStatus" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.chefStatus === 'NON_SUBMIT'" style="color: #707070; ">{{ $t('SHOP_CHEF_STATUS.NON_SUBMIT') }}</span>
          <span v-if="scope.row.chefStatus === 'SUBMIT'" style="color: red; font-weight: 800;">{{ $t('SHOP_CHEF_STATUS.SUBMIT') }}</span>
          <span v-if="scope.row.chefStatus === 'APPROVED'" style="color: green; font-weight: 800;">{{ $t('SHOP_CHEF_STATUS.APPROVED') }}</span>
          <span v-if="scope.row.chefStatus === 'REJECT'" style="color: red; font-weight: 800;">{{ $t('SHOP_CHEF_STATUS.REJECT') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('COMPONENTS.operation')"
        fixed="right"
        width="260px"
      >
        <template slot-scope="scope">
          <el-button
            :disabled="checkPermission('MEMBER.EDIT')"
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t("COMPONENTS.edit") }}</el-button>
          <el-button
            :disabled="checkPermission('COUPON.SEND')"
            size="mini"
            type="primary"
            @click="openCouponDIv(scope.row.id)"
          >{{ $t("Send Coupon") }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-col>
        <pagination
          v-show="query.total > 0"
          class="hidden-xs-only"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="searchDate"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="query.total > 0"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="7"
          layout="pager, prev, next"
          @pagination="searchDate"
        />
      </el-col>
    </el-row>

    <el-dialog
      custom-class="dialog-class"
      :title="$t('Send Coupon')"
      :visible.sync="couponDialogVisible"
      width="800px"
      :before-close="closeCouponDIv"
    >
      <el-table
        header-row-class-name="table-header"
        :data="couponLoglist"
        size="small"
        border
        fit
        highlight-current-row
        height="250"
      >
        <el-table-column
          :label="$t('Sending Method')"
          prop="sendFromType"
          width="190"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.sendFromType === 'SYS_SEND'">{{
              $t("Administrator Redemption")
            }}</span>
            <span v-if="scope.row.sendFromType === 'MEMBER_SEND_SELF'">{{
              $t("Member Redemption")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('Coupon Code')"
          prop="couponCode"
          width="150"
        />
        <el-table-column :label="$t('Issued By')" prop="issuedBy" width="150" />
        <el-table-column :label="$t('COMPONENTS.time')" prop="createdDate" />
      </el-table>
      <br>
      <br>
      <br>
      <br>
      <el-form ref="temp" :model="couponReq">
        <el-form-item prop="couponCode" :label="$t('Coupon Code')">
          <el-input v-model="couponReq.couponCode" style="width: 200px" />
          <el-button
            :disabled="checkPermission('COUPON.SEND')"
            type="primary"
            @click="startSendCoupon()"
          >{{ $t("Send Coupon") }}</el-button>
          <el-button
            style="float: right"
            type="default"
            @click="closeCouponDIv()"
          >{{ $t("COMPONENTS.cancel") }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- batch send coupon dialog -->
    <el-dialog
      custom-class="dialog-class"
      :title="$t('Send Coupon')"
      :visible.sync="batchCouponDialogVisible"
      width="500px"
      :before-close="closeBatchCouponDIv"
    >
      <span>
        {{ $t('MemberPage.BATCH_EDIT_TAG_SELECT_TYPE') }} :
      </span><!-- 所選的會員 -->
      <span style="color: red;">*</span>
      <br>
      <br>
      <el-select
        v-model="selectBatchMemberCouponsTarget"
        placeholder="Select"
        style="width: 100%;"
        clearable
      >
        <el-option key="PLS_CHOOSE" value="PLS_CHOOSE" :label="$t('COMPONENTS.inselect')" /> <!-- 請選擇 -->
        <el-option v-if="selectMemberList.length !==0" key="SELECT_MEMBER" value="SELECT_MEMBER" :label="$t('MemberPage.BATCH_EDIT_TAG_SELECT_TYPE_1') + ' (' + $t('Qty') + selectMemberList.length + ')'" /> <!-- 僅本頁打勾的會員 -->
        <el-option v-if="selectMemberList.length === 0 || query.size === selectMemberList.length" key="ALL_MEMBER" value="ALL_MEMBER" :label="$t('MemberPage.BATCH_EDIT_TAG_SELECT_TYPE_2') + ' (' + $t('Qty') + query.total + ')'" /> <!-- 所有搜尋到的會員 -->
      </el-select>
      <br>
      <br>
      <br>
      <span>{{ $t('Coupon Code') }}</span>
      <br>
      <br>
      <el-input v-model="batchCouponCode" style="width: 100%;" />
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          @click="closeBatchCouponDIv"
        >{{ $t("COMPONENTS.cancel") }}</el-button>
        <el-button
          type="primary"
          :disabled="selectBatchMemberCouponsTarget === 'PLS_CHOOSE'"
          @click="batchSendCoupon"
        >{{ $t("Send Coupon") }}</el-button>
      </span>
    </el-dialog>

    <!-- 批次編輯會員標籤 -->
    <el-dialog
      :visible.sync="batchEditTagDialog"
      :title="$t('MemberPage.BATCH_EDIT_TAG_DIOALOG_TITLE')"
      width="730px"
      :before-close="closeBatchEditTagDialog"
    >
      <br>
      <br>
      <span>
        {{ $t('MemberPage.BATCH_EDIT_TAG_SELECT_TYPE') }} :
      </span><!-- 所選的會員 -->
      <span style="color: red;">*</span>
      <br>
      <br>
      <el-select
        v-model="selectBatchMemberTagsTarget"
        placeholder="Select"
        style="width: 400px"
        clearable
      >
        <el-option key="PLS_CHOOSE" value="PLS_CHOOSE" :label="$t('COMPONENTS.inselect')" /> <!-- 請選擇 -->
        <el-option v-if="selectMemberList.length !==0" key="SELECT_MEMBER" value="SELECT_MEMBER" :label="$t('MemberPage.BATCH_EDIT_TAG_SELECT_TYPE_1') + ' (' + $t('Qty') + selectMemberList.length + ')'" /> <!-- 僅本頁打勾的會員 -->
        <el-option v-if="selectMemberList.length === 0 || query.size === selectMemberList.length" key="ALL_MEMBER" value="ALL_MEMBER" :label="$t('MemberPage.BATCH_EDIT_TAG_SELECT_TYPE_2') + ' (' + $t('Qty') + query.total + ')'" /> <!-- 所有搜尋到的會員 -->
      </el-select>
      <br>
      <br>
      <br>
      <br>
      <span>{{ $t('Member Tag') }} :</span><!-- 會員標籤 -->
      <br>
      <br>
      <el-select
        v-model="selectMemberTags"
        multiple
        placeholder="Select"
        style="width: 400px"
        clearable
      >
        <el-option
          v-for="item in memberTagList"
          :key="item.id"
          :label="item.tag"
          :value="item.id"
        />
      </el-select>
      <el-button icon="el-icon-edit" @click="openAddMemberTagDialog()" />
      <br>
      <br>
      <br>
      <br>
      <el-divider content-position="center">
        <span style="color: red;">
          {{ $t('ACTIVITY_PAGE.NOTE.0') }}<!-- 注意 -->
        </span>
      </el-divider>
      <center>
        <span style="color: red;">
          {{ $t('MemberPage.BATCH_EDIT_TAG_DIOALOG_TIP') }}
        </span>
        <!-- 批次維護會員標籤時，舊Tag將會清除，並以本次的設定覆寫 -->
      </center>
      <br>
      <br>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeBatchEditTagDialog">{{
            $t("COMPONENTS.cancel")
          }}</el-button>
          <el-button :disabled="selectBatchMemberTagsTarget === 'PLS_CHOOSE'" type="primary" @click="submitBatchEditTagDialog">
            {{ $t("COMPONENTS.save") }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 會員選擇標籤 -->
    <el-dialog
      :visible.sync="selectMemberTagDialog"
      :title="$t('MemberPage.EDIT_TAG_DIOALOG_TITLE')"
      width="700px"
      :before-close="closeSelectMemberTagDialog"
    >
      <br>
      <span>{{ $t('Member Tag') }} :</span><!-- 會員標籤 -->
      <br>
      <br>
      <el-select
        v-model="selectMemberTags"
        multiple
        placeholder="Select"
        style="width: 400px"
        clearable
      >
        <el-option
          v-for="item in memberTagList"
          :key="item.id"
          :label="item.tag"
          :value="item.id"
        />
      </el-select>
      <el-button icon="el-icon-edit" @click="openAddMemberTagDialog()" />
      <br>
      <br>
      <br>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeSelectMemberTagDialog">{{
            $t("COMPONENTS.cancel")
          }}</el-button>
          <el-button type="primary" @click="saveSelectMemberTagDialog">
            {{ $t("COMPONENTS.save") }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 會員標籤維護 -->
    <el-dialog
      :visible.sync="addMemberTagDialog"
      :title="$t('MemberPage.MEMBER_TAG_DIALOG_TITLE')"
      width="700px"
      :before-close="closeAddMemberTagDialog"
    >
      <br>
      <el-input
        v-model="addTagValue"
        clearable
        style="width: 150px"
        placeholder="新增Tag"
      />
      <el-button
        type="primary"
        :disabled="isNull(addTagValue)"
        icon="el-icon-plus"
        @click="addTagJob()"
      />
      <br>
      <br>
      <br>
      <el-table
        tooltip-effect="dark"
        :data="memberTagList"
        style="width: 100%"
        header-row-class-name="table-header"
        size="mini"
      >
        <el-table-column :label="$t('Tag Name')" align="left" prop="tag" />
        <el-table-column :label="$t('Data usage')" align="left" prop="count" />
        <el-table-column
          :label="$t('COMPONENTS.operation')"
          fixed="right"
          width="200px"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="deleteTagConfirm(scope.row)"
            >{{ $t("COMPONENTS.delete") }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeAddMemberTagDialog">{{
            $t("COMPONENTS.cancel")
          }}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批次匯入會員 -->
    <el-dialog
      :visible.sync="importMemberDialogVisible"
      :title="$t('MemberPage.IMPORT_MEMBER_BY_EXCEL')"
      width="700px"
      :before-close="closeImportMemberExcelDialog"
    >
      <span>{{ $t("MemberPage.PLEASE_UPLOAD_YOUR_EXCEL_FILE") }}</span>
      <br><br>
      <input id="uploadInput" type="file" @change="handleImport">
      <br><br>
      <span style="font-size: 24px; font-weight: 800">{{ exportMsg }}</span>
      <br><br>

      <div
        v-if="
          errorObject !== null &&
            errorObject.RESULT === 'ERROR' &&
            errorObject.ERROR_LIST !== null
        "
      >
        <div v-for="errorItem in errorObject.ERROR_LIST" :key="errorItem">
          <span
            v-if="errorItem === 'ID_SHOULD_BE_NULL'"
            style="color: red"
          >{{ $t("MemberPage.ID_SHOULD_BE_NULL") }}<br></span>
          <span
            v-if="errorItem === 'LOGIN_NAME_CANNOT_NULL'"
            style="color: red"
          >{{ $t("MemberPage.LOGIN_NAME_CANNOT_NULL") }}<br></span>
          <span
            v-if="errorItem === 'PASSWORD_CANNOT_NULL'"
            style="color: red"
          >{{ $t("MemberPage.PASSWORD_CANNOT_NULL") }}<br></span>
          <span
            v-if="errorItem === 'EMAIL_CANNOT_NULL'"
            style="color: red"
          >{{ $t("MemberPage.EMAIL_CANNOT_NULL") }}<br></span>
          <span
            v-if="errorItem === 'FIRST_NAME_CANNOT_NULL'"
            style="color: red"
          >{{ $t("MemberPage.FIRST_NAME_CANNOT_NULL") }}<br></span>
          <span
            v-if="errorItem === 'LAST_NAME_CANNOT_NULL'"
            style="color: red"
          >{{ $t("MemberPage.LAST_NAME_CANNOT_NULL") }}<br></span>
          <span
            v-if="errorItem === 'MOBILE_CANNOT_NULL'"
            style="color: red"
          >{{ $t("MemberPage.MOBILE_CANNOT_NULL") }}<br></span>
          <span
            v-if="errorItem === 'BIRTHDAY_WRONG_FORMAT'"
            style="color: red"
          >{{ $t("MemberPage.BIRTHDAY_WRONG_FORMAT") }}<br></span>
          <span
            v-if="errorItem === 'LANGUAGE_CODE_WRONG_FORMAT'"
            style="color: red"
          >{{ $t("MemberPage.LANGUAGE_CODE_WRONG_FORMAT") }}<br></span>

          <!-- Email格式錯誤 -->
          <div v-if="errorItem === 'EMAIL_WRONG_FORMAT'">
            <span
              style="color: red"
            >{{ $t("MemberPage.EMAIL_WRONG_FORMAT") }}&nbsp;&nbsp;:<br></span>
            <div v-for="item in errorObject.EMAIL_WRONG_FORMAT" :key="item">
              <span>{{ item }}<br></span>
            </div>
            <br>
          </div>

          <!-- Email已經被使用 -->
          <div v-if="errorItem === 'EMAIL_HAS_REG'">
            <span
              style="color: red"
            >{{ $t("MemberPage.EMAIL_HAS_REG") }}&nbsp;&nbsp;:<br></span>
            <div v-for="item in errorObject.EMAIL_HAS_REG" :key="item">
              <span>{{ item }}<br></span>
            </div>
            <br>
          </div>

          <!-- 帳號已經被註冊 -->
          <div v-if="errorItem === 'LOGIN_NAME_HAS_REG'">
            <span
              style="color: red"
            >{{ $t("MemberPage.LOGIN_NAME_HAS_REG") }}&nbsp;&nbsp;:<br></span>
            <div v-for="item in errorObject.LOGIN_NAME_HAS_REG" :key="item">
              <span>{{ item }}<br></span>
            </div>
            <br>
          </div>

          <!-- 帳號重複 -->
          <div v-if="errorItem === 'LOGIN_NAME_CANNOT_MULTIPLE'">
            <span
              style="color: red"
            >{{
              $t("MemberPage.LOGIN_NAME_CANNOT_MULTIPLE")
            }}&nbsp;&nbsp;:<br></span>
            <div
              v-for="item in errorObject.LOGIN_NAME_CANNOT_MULTIPLE"
              :key="item"
            >
              <span>{{ item }}<br></span>
            </div>
            <br>
          </div>

          <!-- Email重複 -->
          <div v-if="errorItem === 'EMAIL_CANNOT_MULTIPLE'">
            <span
              style="color: red"
            >{{ $t("MemberPage.EMAIL_CANNOT_MULTIPLE") }}&nbsp;&nbsp;:<br></span>
            <div v-for="item in errorObject.EMAIL_CANNOT_MULTIPLE" :key="item">
              <span>{{ item }}<br></span>
            </div>
            <br>
          </div>
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeImportMemberExcelDialog">{{
            $t("COMPONENTS.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :disabled="
              exportMemberData == null || exportMemberData.length === 0
            "
            @click="importMemberJob"
          >
            {{ $t("COMPONENTS.import") }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { findMembers, exportExcel, batchImport } from '@/api/memberApi'
import {
  findAgentAllMemberTag,
  deleteTagObject,
  deleteTag,
  addMemeberTag,
  resetMemberTagByQuery,
  isAgentTagRepeat,
  resetMemberTag
} from '@/api/memberTagApi'
import { shopList } from '@/api/shop'
import { adminSendCoupon } from '@/api/couponApi'
import { getPageParm } from '@/utils/page-common'
import readXlsxFile from 'read-excel-file'
import { checkCfsPermission } from '@/utils/permission'
import moment from 'moment'
import { DB_DATE_FORMAT } from '@/Config'
import { adminFoundAllCouponLog } from '@/api/couponApi'
import pageLoading from '@/utils/pageLoading'
import { foundUserByPartyGroupId, foundAddAdminUser } from '@/api/cfsApi/getUserAndPormission'
import { getLanguage } from '@/lang/index'
export default {
  name: 'Member',
  components: { Pagination },
  directives: { waves },
  filters: {},
  data() {
    return {
      searchPermissionList: [],
      selectMemberTagDialog: false,
      selectMemberTags: false,
      selectMemberIds: [],
      selectBatchMemberTagsTarget: 'PLS_CHOOSE',
      selectBatchMemberCouponsTarget: 'PLS_CHOOSE',
      batchEditTagDialog: false,
      addMemberTagDialog: false,
      addTagValue: null,
      errorObject: null,
      exportMemberData: null,
      exportMsg: '',
      importMemberDialogVisible: false,
      couponDialogVisible: false,
      data: [],
      couponLoglist: [],
      shopOptions: [],
      shopId: null,
      shop: {
        shopName: []
      },
      chefStatusList: [],
      memberCreatedByQueryList: [], // 給select用的List
      query: {
        createdDateQuery: [], // createdDateQuery[0]=開始，createdDateQuery[1]=結束
        memberTag: [],
        shopName: '',
        size: 10,
        page: 1,
        total: 100,
        memberCreatedByQuery: [], // 搜尋建立者，當沒有提供的時候，實際上會被allMemberCreatedBy取代，去限制搜尋的帳號
        allMemberCreatedBy: [], // 提供給後台，當沒有搜尋建立者的時候，仍然要限制搜尋的帳號
        chefStatus: null
      },
      couponReq: {
        couponCode: null,
        memberId: null
      },
      memberTagList: [],
      batchCouponDialogVisible: false,
      selectMemberList: [],
      batchCouponCode: null,
      lastSearchQuery: null
    }
  },
  mounted() {
    this.loadDate()
  },
  methods: {
    errorMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.error'),
        message: message,
        type: 'error'
      })
    },
    successMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.success'),
        message: message,
        type: 'success'
      })
    },
    cleanCoupon() {
      console.log('cleanCoupon')
      this.couponReq.memberId = null
      this.couponReq.couponCode = null
    },
    closeCouponDIv(event) {
      this.couponDialogVisible = false
      this.couponLoglist = []
      this.cleanCoupon()
    },
    openCouponDIv(memberId) {
      this.couponDialogVisible = true
      this.searchMemberCoupon(memberId)
    },
    searchMemberCoupon(memberId) {
      this.couponLoglist = []
      const param = '?page=0&size=9999'
      const query = {
        page: 0,
        size: 9999,
        memberId: memberId
      }
      this.couponReq.memberId = memberId
      this.couponReq.couponCode = null
      adminFoundAllCouponLog(param, query).then((response) => {
        const list = response.data
        this.couponLoglist = list
      })
    },
    startSendCoupon() {
      const memberId = this.couponReq.memberId
      // const list = [this.couponReq]
      const data = {
        shopId: this.shopId,
        memberCoupon: this.couponReq
      }
      this.sendCouponFun(data, memberId)
    },
    sendCouponFun(data, memberId) {
      const partyGroupId = localStorage.getItem('partyGroupId')
      data.partyGroupId = partyGroupId
      adminSendCoupon(data)
        .then((res) => {
          let value = this.couponReq.couponCode
          if (this.isNull(memberId)) {
            value = this.batchCouponCode
          }
          if (res === 'SEND_SUCCESS') {
            const msg = this.$t('ADMIN_SEND_COUPON_MSG.SEND_SUCCESS.label')
            this.successMsg(msg + ' : ' + value)
            if (!this.isNull(memberId)) {
              this.searchMemberCoupon(memberId)
            }
          } else if (res === 'SENT_BEFORE') {
            const msg = this.$t('ADMIN_SEND_COUPON_MSG.SENT_BEFORE.label')
            this.errorMsg(msg + ' : ' + value)
          } else if (res === 'COUPON_CAN_NOT_NULL') {
            const msg = this.$t(
              'ADMIN_SEND_COUPON_MSG.COUPON_CAN_NOT_NULL.label'
            )
            this.errorMsg(msg)
          } else if (res === 'NO_THIS_COUPON') {
            const msg = this.$t('ADMIN_SEND_COUPON_MSG.NO_THIS_COUPON.label')
            this.errorMsg(msg + ' : ' + value)
          } else if (res === 'ADMIN_NO_PERMISSION') {
            const msg = this.$t(
              'ADMIN_SEND_COUPON_MSG.ADMIN_NO_PERMISSION.label'
            )
            this.errorMsg(msg + ' : ' + value)
          } else if (res === 'EXCHANGE_DATE_NOT_IN_RANGE') {
            const msg = this.$t(
              'ADMIN_SEND_COUPON_MSG.EXCHANGE_DATE_NOT_IN_RANGE.label'
            )
            this.errorMsg(msg + ' : ' + value)
          } else if (res === 'NON_STOCK') {
            const msg = this.$t('ADMIN_SEND_COUPON_MSG.NON_STOCK.label')
            this.errorMsg(msg + ' : ' + value)
          } else if (res === 'ALREADY_USED') {
            const msg = this.$t('ADMIN_SEND_COUPON_MSG.ALREADY_USED.label')
            this.errorMsg(msg + ' : ' + value)
          } else if (res === 'NON_STOCK') {
            const msg = this.$t('ADMIN_SEND_COUPON_MSG.NON_STOCK.label')
            this.errorMsg(msg + ' : ' + value)
          } else {
            this.errorMsg(res)
          }
        })
        .catch((e) => {
          this.errorMsg(e)
        })
    },
    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    limitSearchFinishedJob() {
      // 當這個下拉選單只有一個可以選
      // 則readonly
      // 讓USER認知上一定要填這個搜尋條件
      const memberCreatedByQueryList = this.memberCreatedByQueryList

      if (memberCreatedByQueryList.length === 1) {
        const defaultValue = memberCreatedByQueryList[0].value
        this.query.memberCreatedByQuery = [defaultValue]
      }
    },
    limitSearch() {
      return new Promise((resolve, reject) => {
        // 只能搜尋自己建立的會員
        const role0 = !this.checkPermission('MEMBER.SEARCH_REG_SELF') // 會員-搜尋-會員自己註冊的
        const role1 = !this.checkPermission('MEMBER.SEARCH_OWN_CREATE') // 會員-搜尋-我建立的
        const role2 = !this.checkPermission('MEMBER.SEARCH_OWN_PARTY_CREATE') // 會員-搜尋-我公司組建立的
        const roleTop = !this.checkPermission('MEMBER.SEARCH_ALL') // 會員-搜尋-全會員(最大權限)

        if (role0) {
          this.searchPermissionList.push('MEMBER.SEARCH_REG_SELF')
          // 可以搜尋自行註冊的
          const account = '_______SEARCH_OWN_REG_MEMBER_______'
          this.memberCreatedByQueryList.push({ value: account, label: account })
          this.query.allMemberCreatedBy.push(account)
        } else {
          // 不可以搜尋自行註冊的
        }

        if (roleTop) {
          this.searchPermissionList.push('MEMBER.SEARCH_ALL')
          foundAddAdminUser().then(res => {
            console.log('foundAddAdminUser res', res)
            res.data.map(account => {
              this.memberCreatedByQueryList.push({ value: account, label: account })
              this.query.allMemberCreatedBy.push(account)
            })

            resolve()
          })
        } else if (role2) {
          this.searchPermissionList.push('MEMBER.SEARCH_OWN_PARTY_CREATE')
          const partyGroupId = localStorage.getItem('partyGroupId')
          foundUserByPartyGroupId(partyGroupId).then(res => {
            console.log('foundUserByPartyGroupId res', res)
            res.data.map(account => {
              this.memberCreatedByQueryList.push({ value: account, label: account })
              this.query.allMemberCreatedBy.push(account)
            })
            resolve()
          })
        } else if (role1) {
          this.searchPermissionList.push('MEMBER.SEARCH_REG_SELF')
          const account = localStorage.getItem('user_profile')
          this.memberCreatedByQueryList.push({ value: account, label: account })
          this.query.allMemberCreatedBy.push(account)
          resolve()
        } else {
          resolve()
        }
      })
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    loadDate() {
      this.limitSearch().then(res => {
        this.limitSearchFinishedJob()
        this.searchDate()
      })

      shopList().then((resp) => {
        const shopData = resp.data
        if (shopData && shopData.length) {
          shopData.map((d) => {
            this.shopOptions.push({
              value: d.id,
              label: d.name
            })
          })
          // 預設搜尋的商店ID
          this.shopId = this.shopOptions[0].value
        }
      })

      getCfsDomainData({ domainCode: 'SHOP_CHEF_STATUS' }).then((resp) => {
        const cData = resp.data
        this.chefStatusList = []
        cData.map((data, i) => {
          this.chefStatusList.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')'
          })
        })
      })

      this.findAgentAllMemberTagJob()
    },
    findAgentAllMemberTagJob() {
      this.memberTagList = []
      findAgentAllMemberTag().then((res) => {
        if (res && res.length) {
          this.memberTagList = res

          console.log('asrgawergaerdfg', res)
        }
      })
    },
    btnSearchData() {
      this.query.page = 0
      this.searchDate()
    },
    searchDate() {
      console.log('resasdgfahgfdsgfhsdrfg searchDate')

      const query = this.query
      this.lastSearchQuery = JSON.parse(JSON.stringify(query))
      var param = getPageParm(query)
      findMembers(param, query).then((resp) => {
        console.log('=======param========', param)
        console.log('=======query========', query)
        console.log('=======fff========', resp)
        if (resp.data) {
          this.data = resp.data.map((item) => {
            if (item.createdDate) {
              item.createdDate = moment(item.createdDate).format(
                DB_DATE_FORMAT
              )
            }
            return item
          })
        } else {
          this.data = []
        }
        this.query.total = resp.pageCount
        this.query.page = resp.pageNum
        this.query.size = resp.pageSize
      }).catch(e => {
        console.log('resasdgfahgfdsgfhsdrfg e', e)
      })
    },

    handleEdit(index, row) {
      this.$router.push({ path: 'member/edit', query: { attrId: row.id }})
    },
    handleCreate() {
      this.$router.push({ path: 'member/create' })
    },
    handleExport() {
      const query = this.query
      const that = this
      this.$confirm(
        this.$t('COMPONENTS.export_confirm'),
        this.$t('COMPONENTS.warning'),
        {
          confirmButtonText: this.$t('COMPONENTS.confirm'),
          cancelButtonText: this.$t('COMPONENTS.cancel'),
          type: 'warning'
        }
      ).then(function() {
        pageLoading.showFullScreenLoading()
        exportExcel(query)
          .then((res) => {
            if (res.status === 400) {
              throw new Error('exceed limit')
            }
            return res.blob()
          })
          .then((blob) => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            var dateFormat = moment(new Date()).format('YYYYMMDD_HHmmss')
            link.download = 'MemberList_' + dateFormat + '.xlsx'
            link.onclick = function() {
              document.body.removeChild(link)
              pageLoading.hideFullScreenLoading()
            }
            document.body.appendChild(link)
            link.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              })
            )
          })
          .catch((error) => {
            pageLoading.hideFullScreenLoading()
            if (error.message === 'exceed limit') {
              that.$alert(
                this.$t('COMPONENTS.export_limit_error'),
                this.$t('COMPONENTS.error'),
                {
                  confirmButtonText: this.$t('COMPONENTS.confirm'),
                  type: 'error'
                }
              )
            } else {
              console.error('There was an error!', error)
            }
          })
      })
    },
    openSelectMemberTagDialog(row) {
      const memberId = row.id
      this.selectMemberTagDialog = true

      const tagIds = []
      if (row.tags !== null && row.tags.length !== 0) {
        row.tags.map((item) => {
          tagIds.push(item.id)
        })
      }
      this.selectMemberTags = tagIds
      this.selectMemberIds = [memberId]
      console.log('srgcerg', tagIds)
    },
    closeSelectMemberTagDialog() {
      this.selectMemberTagDialog = false
      this.selectMemberTags = []
    },
    saveSelectMemberTagDialog() {
      const tagIds = this.selectMemberTags
      const memberIds = this.selectMemberIds

      resetMemberTag(memberIds, tagIds).then((res) => {
        if (res === 'SUCCESS') {
          // alert('Update Tag success.')
          this.searchDate()
        }
        this.closeSelectMemberTagDialog()
      })
    },
    openImportMemberExcelDialog() {
      this.importMemberDialogVisible = true
      this.exportMsg = ''
      this.errorObject = null
      this.exportMemberData = null
    },
    closeImportMemberExcelDialog() {
      this.importMemberDialogVisible = false
      this.exportMsg = ''
      document.getElementById('uploadInput').value = ''
    },
    closeAddMemberTagDialog() {
      this.addMemberTagDialog = false
      this.addTagValue = null
    },
    openAddMemberTagDialog() {
      this.findAgentAllMemberTagJob()
      this.addMemberTagDialog = true
      this.addTagValue = null
    },
    addTagJob() {
      let addTagValue = this.addTagValue
      addTagValue = addTagValue.trim()

      isAgentTagRepeat(addTagValue).then((res) => {
        if (res === 'ERROR') {
          alert('錯誤')
        } else if (res === 'REPEAT') {
          const msg = this.$t('MemberPage.TAG_CAN_NOT_REPEAT')
          this.errorMsg(msg)
        } else if (res === 'NOT_REPEAT') {
          addMemeberTag(addTagValue).then((res) => {
            if (res === 'SUCCESS') {
              this.findAgentAllMemberTagJob()
              this.addTagValue = null
            }
          })
        }
      })
    },

    deleteMemberTag(member, tagId) {
      const memberId = member.id
      deleteTagObject(tagId, memberId).then((res) => {
        if (res === 'SUCCESS') {
          this.searchDate()
        }
      })
    },
    deleteTagConfirm(row) {
      console.log('axcfsdg', row)
      const count = row.count
      if (count !== 0) {
        const title = this.$t('MemberPage.delete_tag_Title') // 你確定要刪除嗎
        const msg = this.$t('MemberPage.delete_tag_msg_1') + ' ' + count + ' ' + this.$t('MemberPage.delete_tag_msg_2') // 這個標籤已經被XX個會員使用，將會取消關聯
        this.$confirm(
          msg,
          title,
          {
            confirmButtonText: this.$t('COMPONENTS.sure'),
            cancelButtonText: this.$t('COMPONENTS.cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.deleteTagJob(row)
        })
      } else {
        this.deleteTagJob(row)
      }
    },
    deleteTagJob(row) {
      const tagId = row.id
      deleteTag(tagId).then((res) => {
        if (res === 'SUCCESS') {
          // 如果有加入搜尋條件，也要協助移除
          const memberTag = this.query.memberTag
          this.query.memberTag = memberTag.filter(id => id !== tagId)

          // 如果上一個動作是+的button也有談窗編輯這個tag，也要協助移除
          const selectMemberTags = this.selectMemberTags
          this.selectMemberTags = selectMemberTags.filter(id => id !== tagId)

          this.findAgentAllMemberTagJob()
          this.searchDate()
        }
      })
    },
    handleImport(event) {
      this.exportMemberData = null
      this.exportMsg = ''
      this.errorObject = null

      const xlsxfile = event.target.files ? event.target.files[0] : null
      readXlsxFile(xlsxfile)
        .then((rows) => {
          let count = rows.length
          if (count === 0 || count === 1) {
            this.exportMsg = this.$t('MemberPage.uploadExcelMsg_01')
          } else {
            count--
            rows.shift() // 移除第一列
            this.exportMemberData = rows
            this.exportMsg =
              count + '  ' + this.$t('MemberPage.uploadExcelMsg_02')
          }
        })
        .catch((e) => {
          this.exportMsg = this.$t('MemberPage.uploadExcelMsg_03')
        })
    },
    importMemberJob() {
      const size = this.exportMemberData.length
      this.exportMsg =
        this.$t('MemberPage.uploadExcelMsg_05') +
        size * 2 +
        this.$t('MemberPage.uploadExcelMsg_06')
      // 處理中，大約需要XX秒

      batchImport(this.exportMemberData).then((res) => {
        this.exportMsg = ''
        // document.getElementById("uploadInput").value = "";
        this.exportMemberData = null

        if (res.RESULT === 'SUCCESS') {
          this.closeImportMemberExcelDialog()
          this.searchDate()
          this.successMsg(this.$t('COMPONENTS.save_Success'))
        } else if (res.RESULT === 'ERROR') {
          const errorSize = res.ERROR_LIST.length

          this.exportMsg =
            this.$t('MemberPage.uploadExcelMsg_04') + ' (' + errorSize + ')'

          // {
          //   "EMAIL_HAS_REG": [
          //       "tester2@nmt.com"
          //   ],
          //   "EMAIL_CANNOT_MULTIPLE": [
          //       "tester2@nmt.com"
          //   ],
          //   "LOGIN_NAME_HAS_REG": [
          //       "tester2",
          //       "mac003",
          //       "visa"
          //   ],
          //   "LOGIN_NAME_CANNOT_MULTIPLE": [
          //       "mac003",
          //       "tester2"
          //   ],
          //   "ERROR_LIST": [
          //       "ID_SHOULD_BE_NULL",
          //       "LOGIN_NAME_CANNOT_NULL",
          //       "PASSWORD_CANNOT_NULL",
          //       "EMAIL_CANNOT_NULL",
          //       "FIRST_NAME_CANNOT_NULL",
          //       "LAST_NAME_CANNOT_NULL",
          //       "MOBILE_CANNOT_NULL",
          //       "LOGIN_NAME_HAS_REG",
          //       "EMAIL_HAS_REG",
          //       "EMAIL_WRONG_FORMAT",
          //       "LOGIN_NAME_CANNOT_MULTIPLE",
          //       "BIRTHDAY_WRONG_FORMAT",
          //       "EMAIL_CANNOT_MULTIPLE"
          //   ],
          //   "EMAIL_WRONG_FORMAT": [
          //       "ujujhjh",
          //       "jhk@fdg",
          //       "@dfs.asd"
          //   ],
          //   "RESULT": "ERROR"
          //   }

          this.errorObject = res
        }
      })
    },
    handleSelectionChange(val) {
      this.selectMemberList = val
    },
    openSendCouponModal() {
      console.log('open batch send coupon modal')
      this.batchCouponDialogVisible = true
      this.batchCouponCode = null
      this.selectBatchMemberCouponsTarget = 'PLS_CHOOSE'
    },
    closeBatchCouponDIv() {
      console.log('close batch send coupon modal')
      this.batchCouponDialogVisible = false
      this.batchCouponCode = null
      this.selectBatchMemberCouponsTarget = 'PLS_CHOOSE'
    },
    batchSendCoupon() {
      console.log('start batch send coupon')
      const couponCode = this.batchCouponCode
      if (this.isNull(couponCode)) {
        const msg = this.$t(
          'ADMIN_SEND_COUPON_MSG.COUPON_CAN_NOT_NULL.label'
        )
        this.errorMsg(msg)
        return
      }
      const data = { shopId: this.shopId, many: true, batchCouponCode: couponCode }
      const selectBatchMemberCouponsTarget = this.selectBatchMemberCouponsTarget
      if (selectBatchMemberCouponsTarget === 'SELECT_MEMBER') {
        const batchMembers = this.selectMemberList
        const list = batchMembers.map(res => {
          return { memberId: res.id, couponCode: couponCode }
        })
        data.memberCouponList = list
        data.code = 'BATCH_SELECT'
      } else if (selectBatchMemberCouponsTarget === 'ALL_MEMBER') {
        data.member = this.lastSearchQuery
        data.code = 'BATCH_ALL'
      }
      console.log('reqest data', data)
      this.sendCouponFun(data)
    },
    openBatchEditTagDialog() {
      this.batchEditTagDialog = true
      this.selectMemberTags = []
      this.selectBatchMemberTagsTarget = 'PLS_CHOOSE'
    },
    closeBatchEditTagDialog() {
      this.batchEditTagDialog = false
      this.selectMemberTags = []
      this.selectBatchMemberTagsTarget = 'PLS_CHOOSE'
      this.selectMemberList = []
    },
    submitBatchEditTagDialog() {
      const selectBatchMemberTagsTarget = this.selectBatchMemberTagsTarget
      const tagIds = this.selectMemberTags
      const selectMemberList = this.selectMemberList

      const memberIds = []
      if (selectBatchMemberTagsTarget !== 'PLS_CHOOSE') {
        if (selectBatchMemberTagsTarget === 'SELECT_MEMBER') {
          selectMemberList.map(member => {
            memberIds.push(member.id)
          })
          // //////////////////////////////////////////////////////////////////////////////////////////////只有更新所選的會員
          resetMemberTag(memberIds, tagIds).then((res) => {
            if (res === 'SUCCESS') {
              // alert('Update Tag success.')
              this.searchDate()
            }
            this.closeBatchEditTagDialog()
          })
        } else if (selectBatchMemberTagsTarget === 'ALL_MEMBER') {
          const query = this.lastSearchQuery
          // //////////////////////////////////////////////////////////////////////////////////////////////更新全部搜尋的會員
          resetMemberTagByQuery(query, tagIds).then((res) => {
            if (res === 'SUCCESS') {
              // alert('Update Tag success.')
              this.searchDate()
            }
            this.closeBatchEditTagDialog()
          })
        }
      }
    }
  }
}
</script>
