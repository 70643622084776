import request from '@/utils/request'
import { API_BASE_URL_REFERENCE } from '../Config'

export function fetchList(query) {
  return request({
    url: API_BASE_URL_REFERENCE + '/api/domain/criteria',
    method: 'post',
    data: query
  })
}

export function inputSearch(query) {
  return request({
    url: API_BASE_URL_REFERENCE + '/api/domain/criteria',
    method: 'post',
    data: query
  })
}

export function searchList(pageUrl, query) {
  return request({
    url: API_BASE_URL_REFERENCE + '/api/domain/criteria' + pageUrl,
    method: 'post',
    data: query
  })
}

export function createDomain(fromData) {
  return request({
    url: API_BASE_URL_REFERENCE + '/api/domain/',
    method: 'post',
    data: fromData
  })
}

export function updateDomain(fromData) {
  return request({
    url: API_BASE_URL_REFERENCE + '/api/domain/' + fromData.id,
    method: 'put',
    data: fromData
  })
}

export function deleteDomain(fromData) {
  return request({
    url: API_BASE_URL_REFERENCE + '/api/domain/',
    method: 'delete',
    data: fromData
  })
}
