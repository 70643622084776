import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'

export function saveOrUpdate(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/saveOrUpdate',
    method: 'post',
    data: query
  })
}

export function saveOrUpdateToCfs(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/saveOrUpdateToCfs',
    method: 'post',
    data: query
  })
}

export function deleteTag(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/delete/' + id,
    method: 'delete'
  })
}

export function deleteFromCfs(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/deleteFromCfs/' + id,
    method: 'delete'
  })
}

export function searchList(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/list' + param,
    method: 'post',
    data: query
  })
}

export function getTagList(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/tagList' + param,
    method: 'post',
    data: query
  })
}

export function toEdit(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/toEdit',
    method: 'get',
    params: query
  })
}

export function getTagListFromCfs(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/getTagListFromCfs' + param,
    method: 'post',
    data: query
  })
}
