var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 12 } },
                [_vm._v(_vm._s(_vm.$t("shopPic.addShopPic.label")))]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("shopPicture.add"),
                      },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { model: _vm.shopPic, "label-width": "80px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xl: 12 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("shopPic.imageName"),
                                    prop: "name",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "shopPic.Please-enter-imageName"
                                      ),
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.shopPic.picName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.shopPic, "picName", $$v)
                                      },
                                      expression: "shopPic.picName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xl: 12 } },
                            [
                              _c(
                                "el-card",
                                { staticClass: "card-box" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "shopPic.select-image"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "el-upload",
                                                {
                                                  staticClass:
                                                    "avatar-uploader",
                                                  attrs: {
                                                    action: "#",
                                                    "http-request":
                                                      _vm.uploadMainImages,
                                                    accept:
                                                      ".jpg,.jpeg,.png,.gif",
                                                    "show-file-list": false,
                                                    "before-upload":
                                                      _vm.beforeFileUpload,
                                                  },
                                                },
                                                [
                                                  _vm.mainImgUrl
                                                    ? _c("img", {
                                                        staticClass: "avatar",
                                                        attrs: {
                                                          src: _vm.mainImgUrl,
                                                          alt: "",
                                                        },
                                                      })
                                                    : _c("em", {
                                                        staticClass:
                                                          "el-icon-plus avatar-uploader-icon",
                                                      }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }