import { render, staticRenderFns } from "./attributeGroupCreate.vue?vue&type=template&id=65ffb1c1"
import script from "./attributeGroupCreate.vue?vue&type=script&lang=js"
export * from "./attributeGroupCreate.vue?vue&type=script&lang=js"
import style0 from "./attributeGroupCreate.vue?vue&type=style&index=0&id=65ffb1c1&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("W:\\project\\HK\\twdcettest-onlinetraining-dev\\mall-admin-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65ffb1c1')) {
      api.createRecord('65ffb1c1', component.options)
    } else {
      api.reload('65ffb1c1', component.options)
    }
    module.hot.accept("./attributeGroupCreate.vue?vue&type=template&id=65ffb1c1", function () {
      api.rerender('65ffb1c1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/attributeGroup/attributeGroupCreate.vue"
export default component.exports