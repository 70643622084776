import AES from 'crypto-js'

const key = AES.enc.Utf8.parse('EC6DCB88759992921724BD9B') // 十六位十六进制数作为密钥
const iv = AES.enc.Utf8.parse('EC6DCB88759992921724BD9B') // 十六位十六进制数作为密钥偏移量
console.log('# key/iv is:', key, iv)

export function jsonParse(jsonStr) {
  if (!jsonStr) {
    return {}
  }
  return JSON.parse(jsonStr)
}

// 加密
export function encrypt(word) {
  // console.log("### encrypt word: ", word);
  const src = AES.enc.Utf8.parse(word)
  // console.log("### encrypt src: ", src);
  const encrypted = AES.AES.encrypt(src, key, { iv, mode: AES.mode.CBC, padding: AES.pad.Pkcs7 })
  // console.log("### encrypt encrypted: ", encrypted);
  return encrypted.ciphertext.toString(AES.enc.Base64)// .toUpperCase();
}

// 解密
export function decrypt(word) {
  if (!word) {
    return undefined
  }
  // console.log("### decrypt word: ", word);
  const decrypt = AES.AES.decrypt(word, key, { iv, mode: AES.mode.CBC, padding: AES.pad.Pkcs7 })
  // console.log("### decrypt decrypt: ", decrypt);
  const decryptedStr = decrypt.toString(AES.enc.Utf8)
  // console.log("### decrypt decryptedStr: ", decryptedStr);
  return decryptedStr
}

/*
// 加密
export function encrypt_(word) {
    console.log("### encrypt word: ", word);
    const src = AES.enc.Utf8.parse(word);
    console.log("### encrypt src: ", src);
    const encrypted = AES.AES.encrypt(src, key, { iv, mode: AES.mode.CBC, padding: AES.pad.Pkcs7 });
    console.log("### encrypt encrypted: ", encrypted);
    return encrypted.ciphertext.toString();//.toUpperCase();
};

// 解密
export function decrypt_(word) {
    console.log("### decrypt word: ", word);
    const encryptedHexStr = AES.enc.Hex.parse(word);
    console.log("### decrypt encryptedHexStr: ", encryptedHexStr);
    const src = AES.enc.Base64.stringify(encryptedHexStr);
    console.log("### decrypt src: ", src);
    const decrypt = AES.AES.decrypt(src, key, { iv, mode: AES.mode.CBC, padding: AES.pad.Pkcs7 });
    console.log("### decrypt decrypt: ", decrypt);
    const decryptedStr = decrypt.toString(AES.enc.Utf8);
    console.log("### decrypt decryptedStr: ", decryptedStr);
    return decryptedStr.toString();
}
*/
