import request from '@/utils/request'

import { API_SERVER_URL } from '../Config'

export function adminStopActivity(id) {
  return request({
    url: API_SERVER_URL + '/api/admin/activity/adminStopActivity',
    method: 'post',
    data: { id }
  })
}

export function adminFoundAllData(param, query) {
  return request({
    url: API_SERVER_URL + '/api/admin/activity/adminFoundAllData' + param,
    method: 'post',
    data: query
  })
}

export function adminFoundOneData(id) {
  return request({
    url: API_SERVER_URL + '/api/admin/activity/adminFoundOneData',
    method: 'post',
    data: { id }
  })
}

export function adminDeleteData(query) {
  return request({
    url: API_SERVER_URL + '/api/admin/activity/adminDeleteData',
    method: 'delete',
    data: query
  })
}

export function adminSaveData(data) {
  return request({
    url: API_SERVER_URL + '/api/admin/activity/adminSaveData',
    method: 'post',
    data: data
  })
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  )
}
