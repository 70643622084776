<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">
          {{ edit?$t("Edit Member"):$t("Add New Member") }}
        </el-col>
        <el-col :span="12">
          <el-button :disabled="checkPermission('MEMBER.DELETE')" @click="showDeleteDialog">{{ $t("Delete Member") }}</el-button>
          <el-button @click="$router.back(-1)">{{ $t("COMPONENTS.return") }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"

            @click="handleSave"
          >{{ $t("COMPONENTS.save") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form :model="member" label-width="160px">
      <el-row>
        <el-col :xl="24">
          <el-card class="card-box">

            <el-row v-if=" member.id !== null" :gutter="20">
              <el-col :sm="12" :xs="12">
                <el-form-item :label="$t('Member ID')">
                  <span>{{ member.id }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="12">
                <el-form-item :label="$t('COMPONENTS.registerDate')">
                  <span>{{ member.registerDate }}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Member Account') + '*'">
                  <el-input v-model="member.loginName" :disabled="edit" style="width: 200px" />
                </el-form-item>
              </el-col>
              <el-col v-if="isProfileNeedGender" :sm="12" :xs="24">
                <el-form-item :label="$t('Gender') + (isProfileNeedGender ? '*' : '') " prop="gender">
                  <el-radio-group v-model="member.gender">
                    <el-radio label="1">{{ $t('Man') }}</el-radio>
                    <el-radio label="2">{{ $t('Woman') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('First Name') + '*'">
                  <el-input v-model="member.firstName" style="width: 200px" clearable />
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Last Name') + '*'">
                  <el-input v-model="member.lastName" style="width: 200px" clearable />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Phone Number') + (isProfileNeedMobile ? '*' : '' )">
                  <el-input v-model="member.mobileNo" style="width: 200px" clearable />
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Password') + '*'">
                  <el-input v-model="member.password" style="width: 200px" type="password" autocomplete="new-password" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Email') + '*'">
                  <el-input v-model="member.email" style="width: 200px" clearable />
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Email Verified')">
                  <el-switch
                    v-model="member.emailVerified"
                    prop="emailVerified"
                    active-color="#13ce66"
                    inactive-color="#a9a9a9"
                  />

                  <span style="color:#707070; font-size: 10px;">
                    ({{ $t('Default need to send verify email') }})
                  </span>

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col v-if="isProfileNeedBirthday" :sm="12" :xs="24">
                <el-form-item :label="$t('Birthday') + (isProfileNeedBirthday ? '*' : '') ">
                  <el-date-picker
                    v-model="member.birthday"
                    clearable
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Enabled')">
                  <el-switch
                    v-model="member.enabled"
                    prop="status"
                    active-color="#13ce66"
                    inactive-color="#a9a9a9"
                    @change="onChangeStatus"
                  />
                </el-form-item>
              </el-col>

            </el-row>

            <!-- :key="componentKey" 拿來重新渲染頁面的 -->
            <div :key="componentKey">

              <!--   Country   -->
              <el-row :gutter="20">
                <el-col v-if="isProfileNeedAddrss" :sm="12" :xs="24">
                  <el-form-item :label="$t('Country')">
                    <el-select
                      :v-model="member.country ? member.country.description : ''"
                      :value="member.country ? member.country.description : ''"
                      filterable
                      placeholder="请选择"
                      @change="(e) => handlerCountryOnChange(e, true)"
                    >
                      <el-option
                        v-for="item in countryList"
                        :key="item.id"
                        :label="item.description"
                        :value="item.id"
                      />
                    </el-select>

                  </el-form-item>
                </el-col>
                <el-col :sm="12" :xs="24">
                  <el-form-item :label="$t('Language')">
                    <el-select v-model="member.languageCode" placeholder="请选择">
                      <el-option
                        v-for="item in languageOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <!--    Country   Detail   -->
              <div v-if="isProfileNeedAddrss" v-show="locationTypes !== null">
                <el-row v-for="(item, index) in locationTypes" :key="item.id" :gutter="20">
                  <el-col :sm="24" :xs="24">
                    <el-form-item :label="$t(item.description)">
                      <el-select
                        :clearable="item.allowUserAdd"
                        :v-model="member.addressLocations[index] ? member.addressLocations[index].description : ''"
                        :value="member.addressLocations[index] ? member.addressLocations[index].description : ''"
                        placeholder="请选择"
                        @change="(value) =>addressChange(value, undefined, item, index, undefined)"
                      >
                        <el-option
                          v-for="location in item.locationList"
                          :key="location.id"
                          :label="location.description"
                          :value="location.id"
                        />

                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>

            <el-row v-if="isProfileNeedAddrss" :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Detail Address')">
                  <el-input v-model="member.address" style="width: 600px" clearable />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Store Contract')">

                  <el-select v-model="member.chefStatus" style="width: 600px" placeholder="请选择">
                    <el-option
                      v-for="item in chefStatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Member Created By')">
                  <el-select v-model="member.memberCreatedBy" style="width: 200px" :readonly="!isAdmin" clearable>
                    <el-option
                      v-for="item in memberCreatedByList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Admin Remark')">
                  <el-input
                    v-model="member.adminRemark"
                    type="textarea"
                    :placeholder="$t('Admin.adminRemark.tooltip')"
                    style="width: 600px;"
                    :autosize="{ minRows: 8 }"
                  />
                  <el-tooltip class="item" effect="dark" :content="$t('Admin.adminRemark.tooltip')" placement="top">
                    <i class="el-icon-warning" />
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>

          </el-card>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog
      :title="$t('Delete member (Only for test team used)')"
      :visible.sync="deleteDialog"
      width="600px"
    >

      <el-form>
        <el-row :gutter="20">
          <el-col :sm="24" :xs="24">
            <el-form-item :label="$t('Password')">
              <el-input v-model="deletePassword" style="width: 300px" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDeleteDialog">{{ $t('COMPONENTS.cancel') }}</el-button>
        <el-button @click="startDeleteMember">{{ $t('COMPONENTS.sure') }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { findMemberByAdmin, saveMemberInfoByAdmin, deleteMember, deleteMemberOnOrderSystem } from '@/api/memberApi'
import { checkCfsPermission } from '@/utils/permission'
import { fetchLanguageList } from '@/api/languageApi'
import { apiGetCountrys, apiGetStateOrProvince, getNextOneLocation } from '@/api/addressApi'
import { findBySysName, deleteUserByGalleryAdmin, foundAddAdminUser } from '@/api/cfsApi/getUserAndPormission'
import moment from 'moment'
import { DB_DATE_FORMAT } from '@/Config'
import { getSystmeConfig } from '@/api/mallorderApi/systemConfigApi'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { getLanguage } from '@/lang/index'

export default {
  name: 'MemberCreate',
  components: { Sticky },
  data() {
    return {
      isAdmin: false,
      deletePassword: null,
      deleteDialog: false,
      componentKey: 0,
      edit: true,
      rowCount: 1,
      member: {
        addressId: null,
        address: null,
        avatarUrlId: null,
        birthday: null,
        codeSubscriber: null,
        chefStatus: 'NON_SUBMIT',
        createSourceCode: null,
        createdBy: null,
        createdDate: null,
        email: null,
        firstName: null,
        friendNotifyCount: null,
        gender: null,
        id: null,
        languageCode: 'en-US',
        lastModifiedBy: null,
        lastModifiedDate: null,
        lastName: null,
        loginName: null,
        mobileNo: null,
        nickname: null,
        status: null,
        emailVerified: false,
        password: null,
        addressLocations: [],
        country: null,
        enabled: false,
        registerDate: null,
        memberCreatedBy: null,
        adminRemark: null
      },

      languageOptions: [],
      countryList: [],
      locationTypes: [],
      cfsSysId: null,
      isProfileNeedBirthday: false,
      isProfileNeedGender: false,
      isProfileNeedAddrss: false,
      isProfileNeedMobile: false,
      chefStatusList: [],
      memberCreatedByList: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    onChangeStatus(value) {
      if (this.member.hasFamilyUser && !value) {
        this.$message({
          message: this.$t('disable user tip'),
          type: 'warning'
        })
      }
      this.member.enabled = value
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    async loadData() {
      if (localStorage.getItem('user_profile') === 'admin') {
        this.isAdmin = true
      }
      const configJob = []
      configJob.push(getSystmeConfig('PROFILE_BRITHDAY'))
      configJob.push(getSystmeConfig('PROFILE_SEX'))
      configJob.push(getSystmeConfig('PROFILE_ADDRESS'))
      configJob.push(getSystmeConfig('PROFILE_MOBILE'))
      Promise.all(configJob).then(responseList => {
        console.log('會員資料-', responseList)

        let i = 0
        if (responseList[i]) {
          this.isProfileNeedBirthday = responseList[i].itemValue === '1'
          console.log('會員資料-是否需要提供生日', this.isProfileNeedBirthday)
          i++
        }
        if (responseList[i]) {
          this.isProfileNeedGender = responseList[i].itemValue === '1'
          console.log('會員資料-是否需要提供性別', this.isProfileNeedGender)
          i++
        }
        if (responseList[i]) {
          this.isProfileNeedAddrss = responseList[i].itemValue === '1'
          console.log('會員資料-是否需要提供地址', this.isProfileNeedAddrss)
          i++
        }
        if (responseList[i]) {
          this.isProfileNeedMobile = responseList[i].itemValue === '1'
          console.log('會員資料-是否需要提供電話', this.isProfileNeedMobile)
          i++
        }
      })

      const promiseList = []
      promiseList.push(fetchLanguageList())
      promiseList.push(apiGetCountrys())
      promiseList.push(findBySysName('gallery'))
      promiseList.push(getCfsDomainData({ domainCode: 'SHOP_CHEF_STATUS' }))
      promiseList.push(foundAddAdminUser())

      Promise.all(promiseList).then(responseList => {
        // 取語言
        if (responseList[0]) {
          const { data = {}} = responseList[0]
          data.supportLanguageList && data.supportLanguageList.forEach(element => {
            this.languageOptions.push({
              value: element.language,
              label: element.title
            })
          })
          console.log('取語言', responseList[0].data)
        }

        // 取地址
        if (responseList[1]) {
          this.countryList = responseList[1]
        }

        // 取Online Mall在CFS上註冊的系統ID
        if (responseList[2]) {
          this.cfsSysId = responseList[2]
          console.log('取Online Mall在CFS上註冊的系統ID', responseList[2])
        }

        // 取廚師審核所有的狀態
        const cData = responseList[3].data
        this.chefStatusList = []
        cData.map((data, i) => {
          this.chefStatusList.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')'
          })
        })
        console.log('拿SHOP_CHEF_STATUS的狀態>', this.chefStatusList)

        const adminUserData = responseList[4].data
        adminUserData.map(account => {
          this.memberCreatedByList.push({ value: account, label: account })
        })

        // 取會員
        const memberId = this.$route.query.attrId
        if (memberId) {
          this.getMember(memberId)
        } else {
          this.edit = false

          // ADD會員的時候，memberCreatedBy預設值是admin登入帳號
          this.member.memberCreatedBy = localStorage.getItem('user_profile')
        }
      })
    },
    getMember(memberId) {
      findMemberByAdmin(memberId).then((response) => {
        const res = response.data
        console.log('scgfhtejruy', res)

        if (res !== null) {
          this.member = res
          this.member.password = 'PASSWORD_NO_CHANGE'
          this.member.registerDate = moment(res.createdDate).format(DB_DATE_FORMAT)

          let country = null
          if (this.member.country && this.member.country.id) {
            country = this.countryList.find(item => { return item.id === this.member.country.id })
          }

          let _locationTypes = []
          if (country) {
            _locationTypes = JSON.parse(JSON.stringify(country.locationTypes))
          }
          this.locationTypes = _locationTypes
          console.log('_locationTypes', _locationTypes)

          // 設定國家
          if (country) {
            this.handlerCountryOnChange(this.member.country.id, false)
          }

          // 設定國家的地址
          if (country && this.member.addressLocations) {
            const { addressLocations } = this.member

            const _addressLocations = JSON.parse(JSON.stringify(addressLocations))// 目的是要讓記憶體地址不同，以下操作就不影響原資料

            _addressLocations && _addressLocations.map((loca, index) => {
              loca.countryId = this.member.country.id

              setTimeout(() => {
                this.handlerChildrenOnChange(loca.id, undefined, _locationTypes[index], index, { item: loca })
              }, 500)
            })
          }
        }
      })
    },
    addressChange(id, option, locType, index, loadCountry) {
      // VUE跟REACT抄起來有點差異
      // 所以抄的時候，加了這個function用來補齊參數

      const loc = locType.locationList.find(loc => { return loc.id === id })
      option = { item: loc }
      this.handlerChildrenOnChange(id, option, locType, index, loadCountry)
    },

    handlerChildrenOnChange(id, option, locType, index, loadCountry) {
      console.log('handlerChildrenOnChange 123123', id, option, locType, index, loadCountry)

      const { item } = option || loadCountry || {}

      this.addressClear(index)

      this.member.addressLocations[index] = {
        id: item.id,
        description: item.description,
        locTypeId: item.locTypeId
      }

      const locationTypes = this.locationTypes

      locationTypes && locationTypes.length > 1 && locationTypes.map((itemLocType, index) => {
        if (itemLocType.locLevel === locType.locLevel + 1) {
          getNextOneLocation(item.countryId, item.id).then(resp => {
            console.log('getNextOneLocation 123123 LEV:' + locType.locLevel, resp)
            itemLocType.locationList = resp
            this.locationTypes = locationTypes
            this.forceRerender()
          })
        }
      })
    },
    forceRerender() {
      // (坑)
      // 用途:
      // Country地址的下拉選單數據更新後
      // VUE卻沒有重新渲染
      // 呼叫此方法，
      // 利用key的屬性來手動重新渲染
      console.log('forceRerender....start')
      setTimeout(() => {
        const key = new Date().getTime()
        this.componentKey = key
        console.log('forceRerender....end', this.locationTypes)
      }, 100)
    },
    handlerCountryOnChange(countryId, clean) {
      let country = null
      this.countryList.map((item) => {
        if (item.id === countryId) {
          country = item
        }
      })

      if (clean) {
        this.addressClear(0)
      }

      if (country) {
        this.member.country = { id: country.id, description: country.description }
        const locationTypes = country.locationTypes

        if (locationTypes) {
          locationTypes.map((item) => {
            item.locLevel === 1 && apiGetStateOrProvince(country.id, item.id).then(resp => {
              console.log('apiGetStateOrProvince  123123123', resp)
              item.locationList = resp
              this.locationTypes = locationTypes

              console.log('apiGetStateOrProvince  123123123 this.locationTypes', this.locationTypes)
            })
          })
        }
      }
      this.forceRerender()
    },
    addressClear(startIndex) {
      if (this.member.addressLocations) {
        this.member.addressLocations.splice(startIndex, this.member.addressLocations.length)
      }
    },

    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    checkTextHasSpace(txet) {
      const hasSpace = txet !== txet.replace(/\s/g, '')
      return hasSpace
    },
    isLowerCase(providedStr) {
      let allLowerCase = true

      for (const str of providedStr) {
        if (str !== str.toLowerCase()) {
          allLowerCase = false
          console.log(`Letter ${str} 不是小寫.`)
        }
      }
      return allLowerCase
    },
    checkData() {
      const member = this.member
      let msg = null

      if (this.isNull(member.loginName)) {
        msg = this.$t('Member Account')
      } else if (this.checkTextHasSpace(member.loginName)) { // 會員帳號不可以有空格
        msg = this.$t('Member account cannot have space')
        this.$message.error(msg)
        return false
      } else if (!this.isLowerCase(member.loginName)) { // 會員帳號不可以有大寫
        msg = this.$t('Member accounts cannot have uppercase')
        this.$message.error(msg)
        return false
      } else if (this.isNull(member.firstName)) {
        msg = this.$t('First Name')
      } else if (this.isNull(member.lastName)) {
        msg = this.$t('Last Name')
      } else if (this.isProfileNeedMobile && this.isNull(member.mobileNo)) {
        msg = this.$t('Phone Number')
      } else if (this.isNull(member.password)) {
        msg = this.$t('Password')
      } else if (this.isNull(member.email)) {
        msg = this.$t('Email')
      } else if (!this.isNull(member.email) && !member.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        msg = this.$t('Email format error')
        this.$message.error(msg)
        return false
      } else if (this.isProfileNeedBirthday && this.isNull(member.birthday)) {
        msg = this.$t('Birthday')
      } else if (this.isProfileNeedAddrss && this.isNull(member.country)) {
        msg = this.$t('Country')
        // } else if (!this.isNull(member.country) && member.addressLocations.length !== this.locationTypes.length) {
        //   msg = this.$t('Country')

      // 檢查地址的=>區
      } else if (this.isProfileNeedAddrss && member.addressLocations[0] === undefined) {
        msg = this.$t(this.locationTypes[0].description)

      // 檢查地址的=>路
      } else if (this.isProfileNeedAddrss && member.addressLocations[1] === undefined) {
        msg = this.$t(this.locationTypes[1].description)

        // 檢查地址的=>大廈  不檢查!!!!
        // } else if (member.addressLocations[2] === undefined) {
        //   msg = this.$t(this.locationTypes[2].description)
      } else if (this.isProfileNeedAddrss && this.isNull(member.address)) {
        msg = this.$t('Detail Address')
      }

      if (msg !== null) {
        msg += ' ' + this.$t('cannot be empty')
        this.$message.error(msg)
        return false
      } else {
        return true
      }
    },
    handleSave() {
      if (this.checkData()) {
        saveMemberInfoByAdmin(this.member).then((resp) => {
          console.log('saveMemberInfoByAdmin=>', resp)

          const code = resp.code
          const msg = resp.msg
          if (code === '200' && msg === 'REQUEST SUCCESS') {
            this.$message.success(this.$t('COMPONENTS.save_Success'))
            this.$router.back(-1)
          } else if (code === 'LOGIN_NAME_EXIST') {
            this.$message.error(this.$t('Member account already exists'))
          } else if (code === 'EMAIL_EXIST') {
            this.$message.error(this.$t('Email is already used'))
          } else {
            this.$message.error(this.$t('COMPONENTS.save_Filad'))
          }
        })
      }
    },
    showDeleteDialog() {
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    },
    startDeleteMember() {
      console.log('deleteMember=>', this.member)
      if (this.member.loginName === 'support' || this.member.loginName === 'admin' || this.member.loginName === 'kathy') {
        alert(this.$t('This account can not delete.'))
        this.deletePassword = null
        return
      }

      if (this.deletePassword === '123456789') {
        deleteMemberOnOrderSystem(this.member).then((resp) => {
          if (resp === 200) {
            deleteMember(this.member).then((resp2) => {
              if (resp2 === 200) {
                const preffixUnitName = 'mall_member'
                deleteUserByGalleryAdmin(preffixUnitName, this.member.loginName, this.cfsSysId).then(res => {
                  alert(this.$t('Delete member success'))
                  this.$router.back(-1)
                })
              }
            })
          }
        })
      } else {
        alert(this.$t('Wrong password'))
        this.deletePassword = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.component {
  margin: 10px;
}
.tree-box {
  margin: 15px 0 100px 55px;
  border: #a6e0d8 solid 2px;
  padding: 15px;
}
.form-item-head {
  margin-bottom: 8px;
  font-size: 12px;
}
.hide .el-upload--picture-card {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.value-table /deep/ .cell {
  padding: 0px 10px 16px 10px;
}
</style>
