var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-form",
              {
                staticClass: "search-form-inline",
                attrs: {
                  "label-suffix": ":",
                  "label-position": "left",
                  "hide-required-asterisk": "",
                  "label-width": "150px",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Member Account") } },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.query.loginName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "loginName", $$v)
                                },
                                expression: "query.loginName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Phone Number") } },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.query.mobileNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "mobileNo", $$v)
                                },
                                expression: "query.mobileNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Email") } },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.query.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "email", $$v)
                                },
                                expression: "query.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Created By") } },
                          [
                            _c(
                              "el-select",
                              {
                                key: JSON.stringify(
                                  _vm.memberCreatedByQueryList
                                ),
                                staticStyle: { width: "240px" },
                                attrs: {
                                  multiple: "",
                                  placeholder: "Select",
                                  clearable: "",
                                  disabled:
                                    _vm.memberCreatedByQueryList.length === 1,
                                },
                                model: {
                                  value: _vm.query.memberCreatedByQuery,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.query,
                                      "memberCreatedByQuery",
                                      $$v
                                    )
                                  },
                                  expression: "query.memberCreatedByQuery",
                                },
                              },
                              _vm._l(
                                _vm.memberCreatedByQueryList,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label:
                                        item.label ===
                                        "_______SEARCH_OWN_REG_MEMBER_______"
                                          ? _vm.$t("MemberPage.MEMBER_REG_SELF")
                                          : item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: { effect: "dark", placement: "top" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "16px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "MemberPage.TOOLTIP1_TITLE1"
                                                )
                                              ) + "?"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "MemberPage.TOOLTIP1_TITLE2"
                                                )
                                              ) + "\n                    "
                                            ),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            !_vm.checkPermission(
                                              "MEMBER.SEARCH_REG_SELF"
                                            )
                                              ? _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-success",
                                                    staticStyle: {
                                                      color: "green",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "MemberPage.ROLE_SEARCH_REG_SELF"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              : _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "gray",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-error",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "MemberPage.ROLE_SEARCH_REG_SELF"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                            _vm._v(" "),
                                            !_vm.checkPermission(
                                              "MEMBER.SEARCH_OWN_CREATE"
                                            )
                                              ? _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-success",
                                                    staticStyle: {
                                                      color: "green",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "MemberPage.ROLE_SEARCH_OWN_CREATE"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              : _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "gray",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-error",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "MemberPage.ROLE_SEARCH_OWN_CREATE"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                            _vm._v(" "),
                                            !_vm.checkPermission(
                                              "MEMBER.SEARCH_OWN_PARTY_CREATE"
                                            )
                                              ? _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-success",
                                                    staticStyle: {
                                                      color: "green",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "MemberPage.ROLE_SEARCH_OWN_PARTY_CREATE"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              : _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "gray",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-error",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "MemberPage.ROLE_SEARCH_OWN_PARTY_CREATE"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                            _vm._v(" "),
                                            !_vm.checkPermission(
                                              "MEMBER.SEARCH_ALL"
                                            )
                                              ? _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-success",
                                                    staticStyle: {
                                                      color: "green",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "MemberPage.ROLE_SEARCH_ALL"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              : _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "gray",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-error",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "MemberPage.ROLE_SEARCH_ALL"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              },
                              [
                                _vm._v(" "),
                                _c("i", { staticClass: "el-icon-info" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Store Contract") } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "240px" },
                                attrs: { placeholder: "Select", clearable: "" },
                                model: {
                                  value: _vm.query.chefStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "chefStatus", $$v)
                                  },
                                  expression: "query.chefStatus",
                                },
                              },
                              _vm._l(_vm.chefStatusList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Member Tag") } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "240px" },
                                attrs: {
                                  multiple: "",
                                  placeholder: "Select",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.query.memberTag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "memberTag", $$v)
                                  },
                                  expression: "query.memberTag",
                                },
                              },
                              _vm._l(_vm.memberTagList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.tag, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("el-button", {
                              attrs: { icon: "el-icon-edit" },
                              on: {
                                click: function ($event) {
                                  return _vm.openAddMemberTagDialog()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("COMPONENTS.registerDate") },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "daterange",
                                "range-separator": "~",
                                "start-placeholder": _vm.$t(
                                  "COMPONENTS.beginTime"
                                ),
                                "end-placeholder": _vm.$t("COMPONENTS.endTime"),
                              },
                              model: {
                                value: _vm.query.createdDateQuery,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "createdDateQuery", $$v)
                                },
                                expression: "query.createdDateQuery",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 24, md: 24, sm: 24, xs: 24 } },
                      [
                        _c(
                          "div",
                          { staticStyle: { float: "left" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.checkPermission("MEMBER.ADD"),
                                },
                                on: { click: _vm.handleExport },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.export")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.checkPermission("MEMBER.ADD"),
                                },
                                on: { click: _vm.openImportMemberExcelDialog },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.import")))]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "el-button",
                              { on: { click: _vm.openSendCouponModal } },
                              [_vm._v(_vm._s(_vm.$t("Send Coupon")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: _vm.data.length === 0 },
                                on: { click: _vm.openBatchEditTagDialog },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Batch Edit Tag")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.checkPermission("MEMBER.ADD"),
                                },
                                on: { click: _vm.handleCreate },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.btnSearchData },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.search")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            "tooltip-effect": "dark",
            data: _vm.data,
            "header-row-class-name": "table-header",
            size: "mini",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Member Account"),
              align: "left",
              prop: "loginName",
              width: "180px",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("First Name"),
              prop: "firstName",
              width: "100px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Last Name"),
              prop: "lastName",
              width: "120px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Member Tag"),
              align: "left",
              prop: "memberTag",
              width: "340px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._l(scope.row.tags, function (item) {
                      return _c(
                        "el-tag",
                        {
                          key: item.id,
                          staticStyle: { margin: "0 1px" },
                          attrs: { closable: "", "disable-transitions": false },
                          on: {
                            close: function ($event) {
                              return _vm.deleteMemberTag(scope.row, item.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.tag) + "\n        "
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "button-new-tag",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.openSelectMemberTagDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v("+")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Email"),
              align: "left",
              prop: "email",
              width: "240px",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("COMPONENTS.registerDate"),
              align: "left",
              prop: "createdDate",
              width: "180px",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Created By"),
              prop: "memberCreatedBy",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Store Contract"),
              prop: "chefStatus",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.chefStatus === "NON_SUBMIT"
                      ? _c("span", { staticStyle: { color: "#707070" } }, [
                          _vm._v(_vm._s(_vm.$t("SHOP_CHEF_STATUS.NON_SUBMIT"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.chefStatus === "SUBMIT"
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "red", "font-weight": "800" },
                          },
                          [_vm._v(_vm._s(_vm.$t("SHOP_CHEF_STATUS.SUBMIT")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.chefStatus === "APPROVED"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "green",
                              "font-weight": "800",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("SHOP_CHEF_STATUS.APPROVED")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.chefStatus === "REJECT"
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "red", "font-weight": "800" },
                          },
                          [_vm._v(_vm._s(_vm.$t("SHOP_CHEF_STATUS.REJECT")))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("COMPONENTS.operation"),
              fixed: "right",
              width: "260px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.checkPermission("MEMBER.EDIT"),
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.$index, scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.checkPermission("COUPON.SEND"),
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openCouponDIv(scope.row.id)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Send Coupon")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.query.total > 0,
                    expression: "query.total > 0",
                  },
                ],
                staticClass: "hidden-xs-only",
                attrs: {
                  total: _vm.query.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.searchDate,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                staticClass: "hidden-sm-and-up",
                attrs: {
                  "hide-on-single-pag": _vm.query.total > 0,
                  total: _vm.query.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  "pager-count": 7,
                  layout: "pager, prev, next",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.searchDate,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "dialog-class",
            title: _vm.$t("Send Coupon"),
            visible: _vm.couponDialogVisible,
            width: "800px",
            "before-close": _vm.closeCouponDIv,
          },
          on: {
            "update:visible": function ($event) {
              _vm.couponDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                "header-row-class-name": "table-header",
                data: _vm.couponLoglist,
                size: "small",
                border: "",
                fit: "",
                "highlight-current-row": "",
                height: "250",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("Sending Method"),
                  prop: "sendFromType",
                  width: "190",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sendFromType === "SYS_SEND"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("Administrator Redemption"))
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.sendFromType === "MEMBER_SEND_SELF"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("Member Redemption"))),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("Coupon Code"),
                  prop: "couponCode",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("Issued By"),
                  prop: "issuedBy",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("COMPONENTS.time"),
                  prop: "createdDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-form",
            { ref: "temp", attrs: { model: _vm.couponReq } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "couponCode", label: _vm.$t("Coupon Code") } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    model: {
                      value: _vm.couponReq.couponCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponReq, "couponCode", $$v)
                      },
                      expression: "couponReq.couponCode",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.checkPermission("COUPON.SEND"),
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.startSendCoupon()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Send Coupon")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.closeCouponDIv()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "dialog-class",
            title: _vm.$t("Send Coupon"),
            visible: _vm.batchCouponDialogVisible,
            width: "500px",
            "before-close": _vm.closeBatchCouponDIv,
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchCouponDialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("MemberPage.BATCH_EDIT_TAG_SELECT_TYPE")) +
                " :\n    "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select", clearable: "" },
              model: {
                value: _vm.selectBatchMemberCouponsTarget,
                callback: function ($$v) {
                  _vm.selectBatchMemberCouponsTarget = $$v
                },
                expression: "selectBatchMemberCouponsTarget",
              },
            },
            [
              _c("el-option", {
                key: "PLS_CHOOSE",
                attrs: {
                  value: "PLS_CHOOSE",
                  label: _vm.$t("COMPONENTS.inselect"),
                },
              }),
              _vm._v(" "),
              _vm.selectMemberList.length !== 0
                ? _c("el-option", {
                    key: "SELECT_MEMBER",
                    attrs: {
                      value: "SELECT_MEMBER",
                      label:
                        _vm.$t("MemberPage.BATCH_EDIT_TAG_SELECT_TYPE_1") +
                        " (" +
                        _vm.$t("Qty") +
                        _vm.selectMemberList.length +
                        ")",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectMemberList.length === 0 ||
              _vm.query.size === _vm.selectMemberList.length
                ? _c("el-option", {
                    key: "ALL_MEMBER",
                    attrs: {
                      value: "ALL_MEMBER",
                      label:
                        _vm.$t("MemberPage.BATCH_EDIT_TAG_SELECT_TYPE_2") +
                        " (" +
                        _vm.$t("Qty") +
                        _vm.query.total +
                        ")",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("Coupon Code")))]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "100%" },
            model: {
              value: _vm.batchCouponCode,
              callback: function ($$v) {
                _vm.batchCouponCode = $$v
              },
              expression: "batchCouponCode",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default" },
                  on: { click: _vm.closeBatchCouponDIv },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled:
                      _vm.selectBatchMemberCouponsTarget === "PLS_CHOOSE",
                  },
                  on: { click: _vm.batchSendCoupon },
                },
                [_vm._v(_vm._s(_vm.$t("Send Coupon")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.batchEditTagDialog,
            title: _vm.$t("MemberPage.BATCH_EDIT_TAG_DIOALOG_TITLE"),
            width: "730px",
            "before-close": _vm.closeBatchEditTagDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchEditTagDialog = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeBatchEditTagDialog } },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled:
                              _vm.selectBatchMemberTagsTarget === "PLS_CHOOSE",
                            type: "primary",
                          },
                          on: { click: _vm.submitBatchEditTagDialog },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("COMPONENTS.save")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("MemberPage.BATCH_EDIT_TAG_SELECT_TYPE")) +
                " :\n    "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "400px" },
              attrs: { placeholder: "Select", clearable: "" },
              model: {
                value: _vm.selectBatchMemberTagsTarget,
                callback: function ($$v) {
                  _vm.selectBatchMemberTagsTarget = $$v
                },
                expression: "selectBatchMemberTagsTarget",
              },
            },
            [
              _c("el-option", {
                key: "PLS_CHOOSE",
                attrs: {
                  value: "PLS_CHOOSE",
                  label: _vm.$t("COMPONENTS.inselect"),
                },
              }),
              _vm._v(" "),
              _vm.selectMemberList.length !== 0
                ? _c("el-option", {
                    key: "SELECT_MEMBER",
                    attrs: {
                      value: "SELECT_MEMBER",
                      label:
                        _vm.$t("MemberPage.BATCH_EDIT_TAG_SELECT_TYPE_1") +
                        " (" +
                        _vm.$t("Qty") +
                        _vm.selectMemberList.length +
                        ")",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectMemberList.length === 0 ||
              _vm.query.size === _vm.selectMemberList.length
                ? _c("el-option", {
                    key: "ALL_MEMBER",
                    attrs: {
                      value: "ALL_MEMBER",
                      label:
                        _vm.$t("MemberPage.BATCH_EDIT_TAG_SELECT_TYPE_2") +
                        " (" +
                        _vm.$t("Qty") +
                        _vm.query.total +
                        ")",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("Member Tag")) + " :")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "400px" },
              attrs: { multiple: "", placeholder: "Select", clearable: "" },
              model: {
                value: _vm.selectMemberTags,
                callback: function ($$v) {
                  _vm.selectMemberTags = $$v
                },
                expression: "selectMemberTags",
              },
            },
            _vm._l(_vm.memberTagList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.tag, value: item.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-button", {
            attrs: { icon: "el-icon-edit" },
            on: {
              click: function ($event) {
                return _vm.openAddMemberTagDialog()
              },
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("el-divider", { attrs: { "content-position": "center" } }, [
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("\n        " + _vm._s(_vm.$t("ACTIVITY_PAGE.NOTE.0"))),
            ]),
          ]),
          _vm._v(" "),
          _c("center", [
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("MemberPage.BATCH_EDIT_TAG_DIOALOG_TIP")) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.selectMemberTagDialog,
            title: _vm.$t("MemberPage.EDIT_TAG_DIOALOG_TITLE"),
            width: "700px",
            "before-close": _vm.closeSelectMemberTagDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectMemberTagDialog = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeSelectMemberTagDialog } },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveSelectMemberTagDialog },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("COMPONENTS.save")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("Member Tag")) + " :")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "400px" },
              attrs: { multiple: "", placeholder: "Select", clearable: "" },
              model: {
                value: _vm.selectMemberTags,
                callback: function ($$v) {
                  _vm.selectMemberTags = $$v
                },
                expression: "selectMemberTags",
              },
            },
            _vm._l(_vm.memberTagList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.tag, value: item.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-button", {
            attrs: { icon: "el-icon-edit" },
            on: {
              click: function ($event) {
                return _vm.openAddMemberTagDialog()
              },
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.addMemberTagDialog,
            title: _vm.$t("MemberPage.MEMBER_TAG_DIALOG_TITLE"),
            width: "700px",
            "before-close": _vm.closeAddMemberTagDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addMemberTagDialog = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeAddMemberTagDialog } },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("br"),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "150px" },
            attrs: { clearable: "", placeholder: "新增Tag" },
            model: {
              value: _vm.addTagValue,
              callback: function ($$v) {
                _vm.addTagValue = $$v
              },
              expression: "addTagValue",
            },
          }),
          _vm._v(" "),
          _c("el-button", {
            attrs: {
              type: "primary",
              disabled: _vm.isNull(_vm.addTagValue),
              icon: "el-icon-plus",
            },
            on: {
              click: function ($event) {
                return _vm.addTagJob()
              },
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                data: _vm.memberTagList,
                "header-row-class-name": "table-header",
                size: "mini",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("Tag Name"),
                  align: "left",
                  prop: "tag",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("Data usage"),
                  align: "left",
                  prop: "count",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("COMPONENTS.operation"),
                  fixed: "right",
                  width: "200px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteTagConfirm(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("COMPONENTS.delete")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.importMemberDialogVisible,
            title: _vm.$t("MemberPage.IMPORT_MEMBER_BY_EXCEL"),
            width: "700px",
            "before-close": _vm.closeImportMemberExcelDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.importMemberDialogVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeImportMemberExcelDialog } },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled:
                              _vm.exportMemberData == null ||
                              _vm.exportMemberData.length === 0,
                          },
                          on: { click: _vm.importMemberJob },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("COMPONENTS.import")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("MemberPage.PLEASE_UPLOAD_YOUR_EXCEL_FILE"))),
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            attrs: { id: "uploadInput", type: "file" },
            on: { change: _vm.handleImport },
          }),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { "font-size": "24px", "font-weight": "800" } },
            [_vm._v(_vm._s(_vm.exportMsg))]
          ),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _vm.errorObject !== null &&
          _vm.errorObject.RESULT === "ERROR" &&
          _vm.errorObject.ERROR_LIST !== null
            ? _c(
                "div",
                _vm._l(_vm.errorObject.ERROR_LIST, function (errorItem) {
                  return _c("div", { key: errorItem }, [
                    errorItem === "ID_SHOULD_BE_NULL"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("MemberPage.ID_SHOULD_BE_NULL"))
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "LOGIN_NAME_CANNOT_NULL"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("MemberPage.LOGIN_NAME_CANNOT_NULL"))
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "PASSWORD_CANNOT_NULL"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("MemberPage.PASSWORD_CANNOT_NULL"))
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "EMAIL_CANNOT_NULL"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("MemberPage.EMAIL_CANNOT_NULL"))
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "FIRST_NAME_CANNOT_NULL"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("MemberPage.FIRST_NAME_CANNOT_NULL"))
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "LAST_NAME_CANNOT_NULL"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("MemberPage.LAST_NAME_CANNOT_NULL"))
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "MOBILE_CANNOT_NULL"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("MemberPage.MOBILE_CANNOT_NULL"))
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "BIRTHDAY_WRONG_FORMAT"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("MemberPage.BIRTHDAY_WRONG_FORMAT"))
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "LANGUAGE_CODE_WRONG_FORMAT"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("MemberPage.LANGUAGE_CODE_WRONG_FORMAT")
                            )
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "EMAIL_WRONG_FORMAT"
                      ? _c(
                          "div",
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("MemberPage.EMAIL_WRONG_FORMAT")
                                ) + "  :"
                              ),
                              _c("br"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.errorObject.EMAIL_WRONG_FORMAT,
                              function (item) {
                                return _c("div", { key: item }, [
                                  _c("span", [_vm._v(_vm._s(item)), _c("br")]),
                                ])
                              }
                            ),
                            _vm._v(" "),
                            _c("br"),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "EMAIL_HAS_REG"
                      ? _c(
                          "div",
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("MemberPage.EMAIL_HAS_REG")) +
                                  "  :"
                              ),
                              _c("br"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.errorObject.EMAIL_HAS_REG,
                              function (item) {
                                return _c("div", { key: item }, [
                                  _c("span", [_vm._v(_vm._s(item)), _c("br")]),
                                ])
                              }
                            ),
                            _vm._v(" "),
                            _c("br"),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "LOGIN_NAME_HAS_REG"
                      ? _c(
                          "div",
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("MemberPage.LOGIN_NAME_HAS_REG")
                                ) + "  :"
                              ),
                              _c("br"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.errorObject.LOGIN_NAME_HAS_REG,
                              function (item) {
                                return _c("div", { key: item }, [
                                  _c("span", [_vm._v(_vm._s(item)), _c("br")]),
                                ])
                              }
                            ),
                            _vm._v(" "),
                            _c("br"),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "LOGIN_NAME_CANNOT_MULTIPLE"
                      ? _c(
                          "div",
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "MemberPage.LOGIN_NAME_CANNOT_MULTIPLE"
                                  )
                                ) + "  :"
                              ),
                              _c("br"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.errorObject.LOGIN_NAME_CANNOT_MULTIPLE,
                              function (item) {
                                return _c("div", { key: item }, [
                                  _c("span", [_vm._v(_vm._s(item)), _c("br")]),
                                ])
                              }
                            ),
                            _vm._v(" "),
                            _c("br"),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    errorItem === "EMAIL_CANNOT_MULTIPLE"
                      ? _c(
                          "div",
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("MemberPage.EMAIL_CANNOT_MULTIPLE")
                                ) + "  :"
                              ),
                              _c("br"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.errorObject.EMAIL_CANNOT_MULTIPLE,
                              function (item) {
                                return _c("div", { key: item }, [
                                  _c("span", [_vm._v(_vm._s(item)), _c("br")]),
                                ])
                              }
                            ),
                            _vm._v(" "),
                            _c("br"),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }