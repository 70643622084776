<template>
  <div>
    <sticky :z-index="999" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="header">
          <label v-if="isload">
            {{ $t('shopList.shopEdit.label') }}
          </label>
          <label v-else>
            {{ $t('shopList.shopCreate.label') }}
          </label>
        </el-col>
        <el-col :span="12">
          <el-button :disabled="disabledTranslateBtn" @click="showTranslateDrawer()">{{ $t("COMPONENTS.translate") }}</el-button>
          <el-button @click="$router.back(-1)">{{ $t('COMPONENTS.return') }}</el-button>
          <el-button :disabled="checkPermission('shop.edit')" @click="saveModel">{{ $t('COMPONENTS.save') }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form
      ref="shop"
      :model="shop"
      :rules="rules"
      class="search-form-inline"
      label-suffix=":"
      label-position="left"
      label-width="120px"
    >
      <el-row>
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{ $t('shopList.shopCreate.shopInfo') }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.shopName')" prop="name" required>
                  <el-input v-model="shop.name" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.type')" prop="shopType">
                  <el-radio v-model="shop.shopType" label="BRANCH-STORE">{{ $t('shopList.shopCreate.branchShop') }}</el-radio>
                  <el-radio v-model="shop.shopType" label="MAIN-STORE">{{ $t('shopList.shopCreate.HQShop') }}</el-radio>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="shop.shopType === 'BRANCH-STORE'" :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.HQShop')" prop="parentShopId">
                  <el-select v-model="shop.hierarchyId">
                    <el-option
                      v-for="item in mainStoreList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.mobile')" prop="mobile">
                  <el-input v-model="shop.mobile" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.phone')" prop="phone">
                  <el-input v-model="shop.phone" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.fix')" prop="fax">
                  <el-input v-model="shop.fax" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.ifRecommend')" prop="recommend">
                  <el-radio-group v-model="shop.recommend">
                    <el-radio v-model="shop.recommend" label="Y">{{ $t('shopList.shopCreate.recommend') }}</el-radio>
                    <el-radio v-model="shop.recommend" label="N">{{ $t('shopList.shopCreate.noRecommend') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.businessTime')" required>
                  <el-row>
                    <el-col :span="10">
                      <el-form-item prop="openTime">
                        <el-time-picker
                          v-model="shop.openTime"
                          clearable
                          format="HH:mm"
                          value-format="HH:mm"
                          :placeholder="$t('shopList.shopCreate.beginTime')"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" class="line" style="text-align: center"> - </el-col>
                    <el-col :span="10">
                      <el-form-item prop="endTime">
                        <el-time-picker
                          v-model="shop.endTime"
                          clearable
                          format="HH:mm"
                          value-format="HH:mm"
                          :placeholder="$t('shopList.shopCreate.endTime')"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shop.header.logo')" prop="logoUrl">
                  <el-upload
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadImg"
                    class="avatar-uploader"
                    accept=".jpg,.jpeg,.png,.gif"
                    :before-upload="beforeFileUpload"
                  >
                    <img
                      v-if="shop.logoUrl"
                      class="avatar"
                      :src="getImagePath(shop.logoUrl)"
                      alt=""
                    >
                    <em class="el-icon-plus avatar-uploader-icon" />
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shop.banner.img')" prop="bannerUrl">
                  <el-upload
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadBannerUrl"
                    class="avatar-uploader"
                    accept=".jpg,.jpeg,.png,.gif"
                    :before-upload="beforeFileUpload"
                  >
                    <img
                      v-if="bannerUrl"
                      class="avatar"
                      :src="getImagePath(bannerUrl)"
                      alt=""
                    >
                    <em class="el-icon-plus avatar-uploader-icon" />
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shop.banner.content')" prop="appIntroduce">
                  <el-input
                    v-model="shop.appIntroduce"
                    type="textarea"
                    :rows="3"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{ $t('Header Item(PC)') }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24">

                <el-table
                  :key="pcMenuItemRendorIndex"
                  tooltip-effect="dark"
                  :data="shop.pcMenuItem"
                  style="width: 1200px"
                  size="mini"
                  row-key="id"
                  border
                  default-expand-all
                  :row-class-name="pcMenuItemTableRowClassName"
                >

                  <el-table-column :label="$t('Menu Item')" prop="title" width="280">
                    <template slot-scope="scop">
                      <span v-if="!scop.row.children || scop.row.children.length === 0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <!-- 這行是為了FIX VUE的外觀缺陷，也許是版本不夠新吧-->
                      <el-input v-model="scop.row.title" style="width: 190px;" />
                    </template>
                  </el-table-column>

                  <!-- <el-table-column prop="title" label="Title" sortable /> -->
                  <el-table-column :label="$t('Target')" prop="type" width="120">
                    <template slot-scope="scop">

                      <el-select v-model="scop.row.type" filterable>
                        <el-option
                          v-for="item in linkOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('URL')" prop="path">
                    <template slot-scope="scop">
                      <el-input v-if="scop.row.type === 'LINK'" v-model="scop.row.path" />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Hide When Family Acount')" prop="path" width="180">
                    <template slot-scope="scop">
                      <el-switch
                        v-model="scop.row.hideWhenFamilyAcount"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" align="right" width="210">
                    <template slot-scope="scope">

                      <!-- 第一層專用的 -->
                      <div v-if="scope.row.level === 1">
                        <el-button :disabled="scope.row.type === 'CATEGORY' || scope.row.type === 'LANGUAGE'" type="primary" icon="el-icon-plus" circle @click="addPcMenuItemChildren(scope.row.id)" />
                        <el-button type="danger" icon="el-icon-close" circle @click="deletePcMenuItem(scope.row)" />
                        <el-button icon="el-icon-arrow-up" circle :disabled="isFirstItem(scope.row.id, shop.pcMenuItem)" @click="movePcMenuItem(scope.row.id, 'up')" />
                        <el-button icon="el-icon-arrow-down" circle :disabled="isLastItem(scope.row.id, shop.pcMenuItem)" @click="movePcMenuItem(scope.row.id, 'down')" />
                      </div>

                      <!-- 第二層專用的 -->
                      <div v-if="scope.row.level === 2">
                        <el-button type="danger" icon="el-icon-close" circle @click="deletePcMenuItemChildren(scope.row)" />
                        <el-button icon="el-icon-arrow-up" circle :disabled="isFirstItem(scope.row.id, shop.pcMenuItem)" @click="movePcMenuItemChildren(scope.row.id, 'up')" />
                        <el-button icon="el-icon-arrow-down" circle :disabled="isLastItem(scope.row.id, shop.pcMenuItem)" @click="movePcMenuItemChildren(scope.row.id, 'down')" />
                      </div>

                    </template>
                  </el-table-column>

                </el-table>
                <p />

                <div style="width: 600px">
                  <el-button type="primary" circle icon="el-icon-plus" style="float: right;" @click="addPcMenuItem()" />
                </div>

              </el-col>
            </el-row>

          </el-card>
        </el-col>

        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{ $t('Menu Item(Mobile)') }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24">

                <el-table
                  :key="mobMenuItemRendorIndex"
                  tooltip-effect="dark"
                  :data="shop.mobMenuItem"
                  style="width: 1200px"
                  size="mini"
                  row-key="id"
                  border
                  default-expand-all
                  :row-class-name="mobMenuItemTableRowClassName"
                >

                  <el-table-column :label="$t('Menu Item')" prop="title" width="280">
                    <template slot-scope="scop">
                      <span v-if="!scop.row.children || scop.row.children.length === 0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <!-- 這行是為了FIX VUE的外觀缺陷，也許是版本不夠新吧-->
                      <el-input v-model="scop.row.title" style="width: 190px;" />
                    </template>
                  </el-table-column>

                  <!-- <el-table-column prop="title" label="Title" sortable /> -->
                  <el-table-column :label="$t('Target')" prop="type" width="120">
                    <template slot-scope="scop">

                      <el-select v-model="scop.row.type" filterable>
                        <el-option
                          v-for="item in linkOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('URL')" prop="path">
                    <template slot-scope="scop">
                      <el-input v-if="scop.row.type === 'LINK'" v-model="scop.row.path" />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Hide When Family Acount')" prop="path" width="180">
                    <template slot-scope="scop">
                      <el-switch
                        v-model="scop.row.hideWhenFamilyAcount"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" align="right" width="210">
                    <template slot-scope="scope">

                      <!-- 第一層專用的 -->
                      <div v-if="scope.row.level === 1">
                        <el-button :disabled="scope.row.type === 'CATEGORY' || scope.row.type === 'LANGUAGE'" type="primary" icon="el-icon-plus" circle @click="addMobMenuItemChildren(scope.row.id)" />
                        <el-button type="danger" icon="el-icon-close" circle @click="deleteMobMenuItem(scope.row)" />
                        <el-button icon="el-icon-arrow-up" circle :disabled="isFirstItem(scope.row.id, shop.mobMenuItem)" @click="moveMobMenuItem(scope.row.id, 'up')" />
                        <el-button icon="el-icon-arrow-down" circle :disabled="isLastItem(scope.row.id, shop.mobMenuItem)" @click="moveMobMenuItem(scope.row.id, 'down')" />
                      </div>

                      <!-- 第二層專用的 -->
                      <div v-if="scope.row.level === 2">
                        <el-button type="danger" icon="el-icon-close" circle @click="deleteMobMenuItemChildren(scope.row)" />
                        <el-button icon="el-icon-arrow-up" circle :disabled="isFirstItem(scope.row.id, shop.mobMenuItem)" @click="moveMobMenuItemChildren(scope.row.id, 'up')" />
                        <el-button icon="el-icon-arrow-down" circle :disabled="isLastItem(scope.row.id, shop.mobMenuItem)" @click="moveMobMenuItemChildren(scope.row.id, 'down')" />
                      </div>

                    </template>
                  </el-table-column>

                </el-table>
                <p />

                <div style="width: 600px">
                  <el-button type="primary" circle icon="el-icon-plus" style="float: right;" @click="addMobMenuItem()" />
                </div>

              </el-col>
            </el-row>

          </el-card>
        </el-col>

        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->

        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">
                {{ $t('Header Left Item(Mobile)') }}
                <el-tooltip class="item" effect="dark" :content="$t('mobile.header.layout.config.tip')" placement="top">
                  <i class="el-icon-warning" />
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24">

                <el-table
                  :key="mobHeaderLeftItemRendorIndex"
                  tooltip-effect="dark"
                  :data="shop.mobHeaderLeftItem"
                  style="width: 1200px"
                  size="mini"
                  row-key="id"
                  border
                  default-expand-all
                >

                  <el-table-column :label="$t('Icon')" prop="icon" width="280">

                    <template slot-scope="scop">

                      <!--  因為不知道select要怎麼顯示圖片上去....那就先在旁邊另外顯示圖片好了-->
                      <el-image v-if="scop.row.icon === 'RiGlobalLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiGlobalLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiHeartLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiHeartLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiLogoutBoxRLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiLogoutBoxRLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiShoppingCartLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiShoppingCartLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiUser3Line'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiUser3Line.png')" />
                      <el-image v-if="scop.row.icon === 'RiUserAddLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiUserAddLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiQuestionLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiQuestionLine.png')" />

                      <el-select v-model="scop.row.icon" style="width: 200px;">
                        <el-option
                          v-for="item in iconHeaderOption"
                          :key="item.value"
                          :value="item.value"
                        >
                          <div class="option_box">
                            <el-image class="option_img" :src="item.label" />
                            <span v-text="item.value" />
                          </div>

                        </el-option>

                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Target')" prop="type" width="120">
                    <template slot-scope="scop">

                      <el-select v-model="scop.row.type" filterable>
                        <el-option
                          v-for="item in linkOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('URL')" prop="path">
                    <template slot-scope="scop">
                      <el-input v-if="scop.row.type === 'LINK'" v-model="scop.row.path" />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Hide When Family Acount')" prop="path" width="180">
                    <template slot-scope="scop">
                      <el-switch
                        v-model="scop.row.hideWhenFamilyAcount"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" align="right" width="210">
                    <template slot-scope="scope">

                      <el-button type="danger" icon="el-icon-close" circle @click="deleteHeaderLeftItem(scope.row)" />
                      <el-button icon="el-icon-arrow-up" circle :disabled="isFirstItem(scope.row.id, shop.mobHeaderLeftItem)" @click="moveHeaderLeftItem(scope.row.id, 'up')" />
                      <el-button icon="el-icon-arrow-down" circle :disabled="isLastItem(scope.row.id, shop.mobHeaderLeftItem)" @click="moveHeaderLeftItem(scope.row.id, 'down')" />

                    </template>
                  </el-table-column>

                </el-table>
                <p />

                <div style="width: 600px">
                  <el-button type="primary" circle icon="el-icon-plus" style="float: right;" @click="addHeaderLeftItem()" />
                </div>

              </el-col>
            </el-row>

          </el-card>
        </el-col>

        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->

        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">
                {{ $t('Header Right Item(Mobile)') }}
                <el-tooltip class="item" effect="dark" :content="$t('mobile.header.layout.config.tip')" placement="top">
                  <i class="el-icon-warning" />
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24">

                <el-table
                  :key="mobHeaderRightItemRendorIndex"
                  tooltip-effect="dark"
                  :data="shop.mobHeaderRightItem"
                  style="width: 1200px"
                  size="mini"
                  row-key="id"
                  border
                  default-expand-all
                >

                  <el-table-column :label="$t('Icon')" prop="icon" width="280">

                    <template slot-scope="scop">

                      <!--  因為不知道select要怎麼顯示圖片上去....那就先在旁邊另外顯示圖片好了-->
                      <el-image v-if="scop.row.icon === 'RiGlobalLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiGlobalLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiHeartLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiHeartLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiLogoutBoxRLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiLogoutBoxRLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiShoppingCartLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiShoppingCartLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiUser3Line'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiUser3Line.png')" />
                      <el-image v-if="scop.row.icon === 'RiUserAddLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiUserAddLine.png')" />
                      <el-image v-if="scop.row.icon === 'RiQuestionLine'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/header/RiQuestionLine.png')" />

                      <el-select v-model="scop.row.icon" style="width: 200px;">
                        <el-option
                          v-for="item in iconHeaderOption"
                          :key="item.value"
                          :value="item.value"
                        >
                          <div class="option_box">
                            <el-image class="option_img" :src="item.label" />
                            <span v-text="item.value" />
                          </div>

                        </el-option>

                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Target')" prop="type" width="120">
                    <template slot-scope="scop">

                      <el-select v-model="scop.row.type" filterable>
                        <el-option
                          v-for="item in linkOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('URL')" prop="path">
                    <template slot-scope="scop">
                      <el-input v-if="scop.row.type === 'LINK'" v-model="scop.row.path" />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Hide When Family Acount')" prop="path" width="180">
                    <template slot-scope="scop">
                      <el-switch
                        v-model="scop.row.hideWhenFamilyAcount"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" align="right" width="210">
                    <template slot-scope="scope">

                      <el-button type="danger" icon="el-icon-close" circle @click="deleteHeaderRightItem(scope.row)" />
                      <el-button icon="el-icon-arrow-up" circle :disabled="isFirstItem(scope.row.id, shop.mobHeaderRightItem)" @click="moveHeaderRightItem(scope.row.id, 'up')" />
                      <el-button icon="el-icon-arrow-down" circle :disabled="isLastItem(scope.row.id, shop.mobHeaderRightItem)" @click="moveHeaderRightItem(scope.row.id, 'down')" />

                    </template>
                  </el-table-column>

                </el-table>
                <p />

                <div style="width: 600px">
                  <el-button type="primary" circle icon="el-icon-plus" style="float: right;" @click="addHeaderRightItem()" />
                </div>

              </el-col>
            </el-row>

          </el-card>
        </el-col>

        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->

        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">
                {{ $t('Footer Item(Mobile)') }}
                <el-tooltip class="item" effect="dark" :content="$t('mobile.header.layout.config.tip')" placement="top">
                  <i class="el-icon-warning" />
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24">

                <el-table
                  :key="mobFooterItemRendorIndex"
                  tooltip-effect="dark"
                  :data="shop.mobFooterItem"
                  style="width: 1200px"
                  size="mini"
                  row-key="id"
                  border
                  default-expand-all
                >

                  <el-table-column :label="$t('Menu Item')" prop="title" width="200">
                    <template slot-scope="scop">
                      <el-input v-model="scop.row.title" style="width: 170px;" />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Icon')" prop="icon" width="230">
                    <template slot-scope="scop">
                      <!--  因為不知道select要怎麼顯示圖片上去....那就先在旁邊另外顯示圖片好了-->
                      <el-image v-if="scop.row.icon === 'HomeOutlined'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/HomeOutlined.png')" />
                      <el-image v-if="scop.row.icon === 'VideoCameraAddOutlined'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/VideoCameraAddOutlined.png')" />
                      <el-image v-if="scop.row.icon === 'FaCalendarAlt'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/FaCalendarAlt.png')" />
                      <el-image v-if="scop.row.icon === 'UserOutlined'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/UserOutlined.png')" />
                      <el-image v-if="scop.row.icon === 'SettingOutlined'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/SettingOutlined.png')" />
                      <el-image v-if="scop.row.icon === 'CommentOutlined'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/CommentOutlined.png')" />
                      <el-image v-if="scop.row.icon === 'QuestionCircleOutlined'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/QuestionCircleOutlined.png')" />
                      <el-image v-if="scop.row.icon === 'HeartOutlined'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/HeartOutlined.png')" />
                      <el-image v-if="scop.row.icon === 'PiListChecksFill'" style="margin-bottom: -10px;" :src="require('@/assets/icon_collection/footer/PiListChecksFill.png')" />

                      <el-select v-model="scop.row.icon" style="width: 150px;">
                        <el-option
                          v-for="item in iconFooterOption"
                          :key="item.value"
                          :value="item.value"
                        >
                          <div class="option_box">
                            <el-image class="option_img" :src="item.label" />
                            <span v-text="item.value" />
                          </div>

                        </el-option>

                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Target')" prop="type" width="120">
                    <template slot-scope="scop">

                      <el-select v-model="scop.row.type" filterable>
                        <el-option
                          v-for="item in linkOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('URL')" prop="path">
                    <template slot-scope="scop">
                      <el-input v-if="scop.row.type === 'LINK'" v-model="scop.row.path" />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('Hide When Family Acount')" prop="path" width="180">
                    <template slot-scope="scop">
                      <el-switch v-model="scop.row.hideWhenFamilyAcount" />
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" align="right" width="170">
                    <template slot-scope="scope">
                      <el-button type="danger" icon="el-icon-close" circle @click="deleteMobFooterItem(scope.row)" />
                      <el-button icon="el-icon-arrow-up" circle :disabled="isFirstItem(scope.row.id, shop.mobFooterItem)" @click="moveMobFooterItem(scope.row.id, 'up')" />
                      <el-button icon="el-icon-arrow-down" circle :disabled="isLastItem(scope.row.id, shop.mobFooterItem)" @click="moveMobFooterItem(scope.row.id, 'down')" />
                    </template>
                  </el-table-column>

                </el-table>
                <p />

                <div style="width: 600px">
                  <el-button type="primary" circle icon="el-icon-plus" style="float: right;" @click="addMobFooterItem()" />
                </div>

              </el-col>
            </el-row>

          </el-card>
        </el-col>

        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->
        <!-- -------------------------------------------------------------------------------------------->

        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col>
                <el-form-item :label="$t('shopList.shopCreate.address')" prop="addressDes">
                  <el-row :gutter="20">
                    <el-col :span="5">
                      <el-select
                        v-model="countryId"
                        filterable
                        @change="handleCountryChange"
                      >
                        <el-option
                          v-for="item in countrysList"
                          :key="item.id"
                          :label="item.description"
                          :value="item.id"
                        />
                      </el-select>
                    </el-col>
                    <el-col v-for="(type, i) in countryType" :key="type.id" :span="5">
                      <el-select
                        v-model="parentId[i]"
                        filterable
                        @change="handleLocTypeChange(i)"
                      >
                        <el-option
                          v-for="item in getLocation(i)"
                          :key="item.id"
                          :label="item.description"
                          :value="item.id"
                        />
                      </el-select>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.addressInfo')" prop="desc">
                  <el-input v-model="shop.desc" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.mapType')">
                  <el-select v-model="shop.mapType">
                    <el-option label="百度地图" value="baidu" />
                    <el-option label="谷歌地图" value="google" />
                    <el-option label="高德地图" value="gaode" />
                    <el-option label="腾讯地图" value="tenxun" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="20">
                <el-form-item :label="$t('shopList.shopCreate.searchAddress')">
                  <el-input v-model="addressKeyword" />
                </el-form-item>
                <baidu-map
                  class="bmView"
                  :scroll-wheel-zoom="true"
                  :zoom="zoom"
                  :center="{ lng: center.lng, lat: center.lat }"
                  :ak="map.ak"
                  @click="getLocationPoint"
                  @ready="handler"
                >
                  <bm-view style="width: 100%; height: 500px; flex: 1" />
                  <bm-local-search
                    :keyword="addressKeyword"
                    :auto-viewport="true"
                    style="display: none"
                  />
                </baidu-map>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.longitude')">
                  <el-input v-model="shop.lon" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopList.shopCreate.latitude')">
                  <el-input v-model="shop.lat" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>

    </el-form>

    <el-drawer
      class="translate-drawer"
      :title="$t('COMPONENTS.translate')"
      :visible.sync="tDrawer"
      size="60%"
      :show-close="false"
      :modal-append-to-body="false"
      :append-to-body="false"
      destroy-on-close
      @opened="getIframe"
    >
      <iframe
        v-if="tDrawer"
        ref="iframe"
        :src="ifreamSrc"
        class="iframe-class"
        :height="getClientHeight()"
      />
    </el-drawer>

  </div>

</template>
<script>
import Sticky from '@/components/Sticky'
import { imgUpload } from '@/api/uploadApi'
import { countryList, loctionTypeList, locList } from '@/api/countryApi'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import { shopSave, findMainStore } from '@/api/shop'
import { checkCfsPermission } from '@/utils/permission'
// 百度地图
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
// import { fetchLanguageList } from '@/api/languageApi'
import { getLanguage } from '../../lang'
import { API_CFS_SERVER_URL } from '@/Config'
import { findShopInfoById, findShopConfig } from '../../api/shop'
import { v4 as uuidv4 } from 'uuid'
import { getFileType, parseStrToInt } from '@/utils'
import { fileSizeConfig } from '@/api/mallorderApi/systemConfigApi'

export default {
  name: 'ShopCreate',
  components: { Sticky, BaiduMap, BmView, BmLocalSearch },
  data() {
    return {
      disabledTranslateBtn: false,
      ifreamSrc: '',
      tDrawer: false,
      linkOptions: [
        { value: 'HOME', label: this.$t('menu.type.HOME') },
        { value: 'LOGIN', label: this.$t('menu.type.LOGIN') },
        { value: 'REG', label: this.$t('menu.type.REG') },
        { value: 'CATEGORY', label: this.$t('menu.type.CATEGORY') },
        { value: 'FRIEND', label: this.$t('menu.type.FRIEND') },
        { value: 'PLAN', label: this.$t('menu.type.PLAN') },
        { value: 'MY', label: this.$t('menu.type.MY') },
        { value: 'MY_FAV', label: this.$t('menu.type.MY_FAV') },
        { value: 'LANGUAGE', label: this.$t('menu.type.LANGUAGE') },
        { value: 'LOGOUT', label: this.$t('menu.type.LOGOUT') },
        { value: 'SHOPPING_CART', label: this.$t('menu.type.SHOPPING_CART') },
        { value: 'PLAN_TO_COOK', label: this.$t('menu.type.PLAN_TO_COOK') },
        { value: 'PLAN_TO_BUY', label: this.$t('menu.type.PLAN_TO_BUY') },
        { value: 'LINK', label: this.$t('menu.type.LINK') },
        { value: 'SETTING', label: this.$t('menu.type.SETTING') },
        { value: 'CHAT', label: this.$t('menu.type.CHAT') }
      ],
      iconHeaderOption: [
        { value: 'RiGlobalLine', label: require('@/assets/icon_collection/header/RiGlobalLine.png') },
        { value: 'RiHeartLine', label: require('@/assets/icon_collection/header/RiHeartLine.png') },
        { value: 'RiLogoutBoxRLine', label: require('@/assets/icon_collection/header/RiLogoutBoxRLine.png') },
        { value: 'RiShoppingCartLine', label: require('@/assets/icon_collection/header/RiShoppingCartLine.png') },
        { value: 'RiUser3Line', label: require('@/assets/icon_collection/header/RiUser3Line.png') },
        { value: 'RiQuestionLine', label: require('@/assets/icon_collection/header/RiQuestionLine.png') },
        { value: 'RiUserAddLine', label: require('@/assets/icon_collection/header/RiUserAddLine.png') }

      ],
      iconFooterOption: [
        { value: 'HomeOutlined', label: require('@/assets/icon_collection/footer/HomeOutlined.png') },
        { value: 'HeartOutlined', label: require('@/assets/icon_collection/footer/HeartOutlined.png') },
        { value: 'VideoCameraAddOutlined', label: require('@/assets/icon_collection/footer/VideoCameraAddOutlined.png') },
        { value: 'FaCalendarAlt', label: require('@/assets/icon_collection/footer/FaCalendarAlt.png') },
        { value: 'UserOutlined', label: require('@/assets/icon_collection/footer/UserOutlined.png') },
        { value: 'SettingOutlined', label: require('@/assets/icon_collection/footer/SettingOutlined.png') },
        { value: 'CommentOutlined', label: require('@/assets/icon_collection/footer/CommentOutlined.png') },
        { value: 'QuestionCircleOutlined', label: require('@/assets/icon_collection/footer/QuestionCircleOutlined.png') },
        { value: 'PiListChecksFill', label: require('@/assets/icon_collection/footer/PiListChecksFill.png') }
      ],
      mobFooterItemRendorIndex: 0,
      mobHeaderRightItemRendorIndex: 0,
      mobHeaderLeftItemRendorIndex: 0,
      mobMenuItemRendorIndex: 0,
      pcMenuItemRendorIndex: 0,
      // ////////////////////////////////////////////////////////////////////////////////
      // ////////////////////////////////////////////////////////////////////////////////
      // ////////////////////////////////////////////////////////////////////////////////
      planConfigStyle: 'display:none',
      planConfig: [{ value: 'Y', label: 'Y' }, { value: 'N', label: 'N' }],
      language: [],
      map: {
        ak: 'FWMGusIlEj652F3iS5h2Q8FXb7hUddz6'
      },
      center: {
        lng: 116.403964,
        lat: 39.915097
      },
      zoom: 15,
      addressKeyword: '',
      dialogVisible: false,
      dialogImageUrl: '',
      isload: false,
      shopId: null,
      countryType: [],
      bannerUrl: '',
      parentId: [],
      location: [],
      countrysList: [],
      countryId: '',
      country: {
        id: '',
        description: ''
      },
      locationDtl: [],
      mainStoreList: [],
      shop: {
        id: '',
        hierarchyId: '',
        name: '',
        bannerGroupUrl: [],
        bannerGroupUrlId: '',
        logoUrlId: '',
        logoUrl: '',
        x: '',
        shopType: 'BRANCH-STORE',
        address: '',
        recommend: 'Y',
        mobile: '',

        mobMenuItem: [],
        mobHeaderRightItem: [],
        mobHeaderLeftItem: [],
        mobFooterItem: [],
        pcMenuItem: [],

        phone: '',
        fax: '',
        lat: '',
        lon: '',
        mapType: '',
        openTime: '09:00',
        endTime: '18:00',
        score: '',
        mapUrl: '',
        desc: '',
        addressDes: '',
        parentShopId: '',
        servicePlan: {
          planTimeConfig: 'N',
          planTimeConfigId: '',
          cardName: '',
          days: '',
          price: '',
          amount: ''
        },
        appIntroduce: ''
      },
      rules: {
        shopType: [{ required: true, message: this.$t('rule.shopType'), trigger: 'change' }],
        name: [{ required: true, message: this.$t('rule.shopName'), trigger: 'blur' }],
        phone: [{ required: true, message: this.$t('rule.shopPhone'), trigger: 'blur' }],
        fax: [{ required: true, message: this.$t('rule.shopFax'), trigger: 'blur' }],
        recommend: [{ required: true, message: this.$t('rule.shopRecommend'), trigger: 'change' }],
        parentShopId: [{ required: true, message: this.$t('rule.parentShopId'), trigger: 'change' }],
        openTime: [
          {
            required: true,
            message: this.$t('rule.beginTime'),
            trigger: 'change'
          }
        ],
        endTime: [
          {
            required: true,
            message: this.$t('rule.endTime'),
            trigger: 'change'
          }
        ],
        logoUrl: [{ required: true, message: this.$t('rule.logo'), trigger: 'change' }],
        addressDes: [
          {
            required: true,
            validator: (rule, value, cb) => {
              console.log('shop---', this.shop)
              if (!this.shop.addressDes.country) {
                cb(new Error(this.$t('rule.country')))
              } else {
                for (var i = 0; i < this.countryType.length; i++) {
                  if (!this.shop.addressDes.addressLocations) {
                    cb(new Error(this.$t('rule.addressLocations')))
                  }
                }
              }
              cb()
            },
            trigger: 'change'
          }
        ]
      },
      fileImageSize: null
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('message', this.handleMessage)

    // 為什麼要重複的放這些數據?
    // 因為常常頁面load完之後$t還是沒有翻譯
    // 所以延遲三秒
    // 再翻譯一次

    const _this = this
    setTimeout(() => {
      _this.linkOptions = [
        { value: 'HOME', label: _this.$t('menu.type.HOME') },
        { value: 'LOGIN', label: _this.$t('menu.type.LOGIN') },
        { value: 'REG', label: _this.$t('menu.type.REG') },
        { value: 'CATEGORY', label: _this.$t('menu.type.CATEGORY') },
        { value: 'FRIEND', label: _this.$t('menu.type.FRIEND') },
        { value: 'PLAN', label: _this.$t('menu.type.PLAN') },
        { value: 'MY', label: _this.$t('menu.type.MY') },
        { value: 'MY_FAV', label: _this.$t('menu.type.MY_FAV') },
        { value: 'LANGUAGE', label: _this.$t('menu.type.LANGUAGE') },
        { value: 'LOGOUT', label: _this.$t('menu.type.LOGOUT') },
        { value: 'SHOPPING_CART', label: _this.$t('menu.type.SHOPPING_CART') },
        { value: 'PLAN_TO_COOK', label: _this.$t('menu.type.PLAN_TO_COOK') },
        { value: 'PLAN_TO_BUY', label: _this.$t('menu.type.PLAN_TO_BUY') },
        { value: 'LINK', label: _this.$t('menu.type.LINK') },
        { value: 'SETTING', label: _this.$t('menu.type.SETTING') },
        { value: 'CHAT', label: _this.$t('menu.type.CHAT') }

      ]
    }, 3000)
  },
  destroyed() {
    window.removeEventListener('message', this.handleMessage)
  },
  created() {
    this.getList()
  },

  methods: {
    forceRerender() {
      this.mobFooterItemRerendorIndex()
      this.mobHeaderRightItemRerendorIndex()
      this.mobHeaderLeftItemRerendorIndex()
      this.mobMenuItemRerendorIndex()
      this.pcMenuItemRerendorIndex()
    },
    mobFooterItemRerendorIndex() {
      this.mobFooterItemRendorIndex += 1
    },
    mobHeaderRightItemRerendorIndex() {
      this.mobHeaderRightItemRendorIndex += 1
    },
    mobHeaderLeftItemRerendorIndex() {
      this.mobHeaderLeftItemRendorIndex += 1
    },
    mobMenuItemRerendorIndex() {
      this.mobMenuItemRendorIndex += 1
    },
    pcMenuItemRerendorIndex() {
      this.pcMenuItemRendorIndex += 1
    },

    isFirstItem(id, arr) {
      console.log('isFirstItem', id, arr)
      let result = false
      if (arr && arr.length) {
        arr.map((item, index) => {
          if (item.id === id && index === 0) {
            result = true
          }
          if (item.children) {
            item.children.map((c, cindex) => {
              if (c.id === id && cindex === 0) {
                result = true
              }
            })
          }
        })
      }
      return result
    },
    isLastItem(id, arr) {
      console.log('isLastItem', id, arr)
      let result = false
      if (arr && arr.length) {
        arr.map((item, index) => {
          if (item.id === id && index === arr.length - 1) {
            result = true
          }
          if (item.children) {
            item.children.map((c, cindex) => {
              if (c.id === id && cindex === item.children.length - 1) {
                result = true
              }
            })
          }
        })
      }
      return result
    },
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    movePcMenuItemChildren(id, action) {
      console.log('sthdfgh', id, action)
      const arr = this.shop.pcMenuItem
      let childIndex = null
      let parentIndex = null
      arr.map((item, pindex) => {
        if (item.children) {
          item.children.map((c, cindex) => {
            if (c.id === id) {
              childIndex = cindex
              parentIndex = pindex
            }
          })
        }
      })
      if (childIndex !== null && parentIndex !== null) {
        if (action === 'up') {
          const moveItem = arr[parentIndex].children[childIndex]
          const targetItem = arr[parentIndex].children[childIndex - 1]

          arr[parentIndex].children[childIndex] = targetItem
          arr[parentIndex].children[childIndex - 1] = moveItem
        } else if (action === 'down') {
          const moveItem = arr[parentIndex].children[childIndex]
          const targetItem = arr[parentIndex].children[childIndex + 1]

          arr[parentIndex].children[childIndex] = targetItem
          arr[parentIndex].children[childIndex + 1] = moveItem
        }
      }
      this.shop.pcMenuItem = arr
      this.pcMenuItemRerendorIndex()
    },
    movePcMenuItem(id, action) {
      const arr = this.shop.pcMenuItem
      let index = null
      arr.map((item, i) => {
        if (item.id === id) {
          index = i
        }
      })

      if (action === 'up') {
        const moveItem = arr[index]
        const targetItem = arr[index - 1]

        arr[index] = targetItem
        arr[index - 1] = moveItem
      } else if (action === 'down') {
        const moveItem = arr[index]
        const targetItem = arr[index + 1]

        arr[index] = targetItem
        arr[index + 1] = moveItem
      }

      this.shop.pcMenuItem = arr
      this.pcMenuItemRerendorIndex()
    },

    addPcMenuItem() {
      let pcMenuItem = this.shop.pcMenuItem
      if (!pcMenuItem || !pcMenuItem.length) {
        pcMenuItem = []
      }
      pcMenuItem.push({
        id: uuidv4(),
        level: 1,
        title: '新按鈕' + pcMenuItem.length,
        type: 'LINK',
        path: '/',
        hideWhenFamilyAcount: false,
        children: []
      })
      this.shop.pcMenuItem = pcMenuItem
      this.disabledTranslateBtn = true
      this.pcMenuItemRerendorIndex()
    },
    deletePcMenuItem(row) {
      console.log('deletePcMenuItem..', row)
      const pcMenuItem = this.shop.pcMenuItem
      const data = []
      pcMenuItem.map((item, i) => {
        if (item.id !== row.id) {
          data.push(item)
        }
      })
      this.shop.pcMenuItem = data
      this.pcMenuItemRerendorIndex()
    },
    deletePcMenuItemChildren(row) {
      console.log('deletePcMenuItemChildren..', row)
      const pcMenuItem = this.shop.pcMenuItem
      const data = []
      pcMenuItem.map((item) => {
        if (item.children) {
          const childData = []
          item.children.map(c => {
            if (c.id !== row.id) {
              childData.push(c)
            }
          })
          item.children = childData
        }

        data.push(item)
      })
      this.shop.pcMenuItem = data
      this.pcMenuItemRerendorIndex()
    },
    addPcMenuItemChildren(id) {
      const pcMenuItem = this.shop.pcMenuItem
      const data = []
      pcMenuItem.map((item) => {
        if (item.id === id) {
          let children = item.children
          if (!children) {
            children = []
          }
          children.push({
            id: uuidv4(),
            level: 2,
            title: '新按鈕' + children.length,
            type: 'LINK',
            path: '/',
            hideWhenFamilyAcount: false
          })
          item.children = children
        }
        data.push(item)
      })
      this.disabledTranslateBtn = true
      this.shop.pcMenuItem = data
      this.pcMenuItemRerendorIndex()
    },
    pcMenuItemTableRowClassName(rowData) {
      if (rowData.row.level === 1) {
        return ''
      } else {
        return 'greyColor'
      }
    },
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    moveMobMenuItemChildren(id, action) {
      console.log('sthdfgh', id, action)
      const arr = this.shop.mobMenuItem
      let childIndex = null
      let parentIndex = null
      arr.map((item, pindex) => {
        if (item.children) {
          item.children.map((c, cindex) => {
            if (c.id === id) {
              childIndex = cindex
              parentIndex = pindex
            }
          })
        }
      })
      if (childIndex !== null && parentIndex !== null) {
        if (action === 'up') {
          const moveItem = arr[parentIndex].children[childIndex]
          const targetItem = arr[parentIndex].children[childIndex - 1]

          arr[parentIndex].children[childIndex] = targetItem
          arr[parentIndex].children[childIndex - 1] = moveItem
        } else if (action === 'down') {
          const moveItem = arr[parentIndex].children[childIndex]
          const targetItem = arr[parentIndex].children[childIndex + 1]

          arr[parentIndex].children[childIndex] = targetItem
          arr[parentIndex].children[childIndex + 1] = moveItem
        }
      }
      this.shop.mobMenuItem = arr
      this.mobMenuItemRerendorIndex()
    },
    moveMobMenuItem(id, action) {
      const arr = this.shop.mobMenuItem
      let index = null
      arr.map((item, i) => {
        if (item.id === id) {
          index = i
        }
      })

      if (action === 'up') {
        const moveItem = arr[index]
        const targetItem = arr[index - 1]

        arr[index] = targetItem
        arr[index - 1] = moveItem
      } else if (action === 'down') {
        const moveItem = arr[index]
        const targetItem = arr[index + 1]

        arr[index] = targetItem
        arr[index + 1] = moveItem
      }

      this.shop.mobMenuItem = arr
      this.mobMenuItemRerendorIndex()
    },

    addMobMenuItem() {
      let mobMenuItem = this.shop.mobMenuItem
      if (!mobMenuItem || !mobMenuItem.length) {
        mobMenuItem = []
      }
      mobMenuItem.push({
        id: uuidv4(),
        level: 1,
        title: '新按鈕' + mobMenuItem.length,
        type: 'LINK',
        path: '/',
        hideWhenFamilyAcount: false,
        children: []
      })
      this.shop.mobMenuItem = mobMenuItem
      this.disabledTranslateBtn = true
      this.mobMenuItemRerendorIndex()
    },
    deleteMobMenuItem(row) {
      console.log('deleteMobMenuItem..', row)
      const mobMenuItem = this.shop.mobMenuItem
      const data = []
      mobMenuItem.map((item, i) => {
        if (item.id !== row.id) {
          data.push(item)
        }
      })
      this.shop.mobMenuItem = data
      this.mobMenuItemRerendorIndex()
    },
    deleteMobMenuItemChildren(row) {
      console.log('deleteMobMenuItemChildren..', row)
      const mobMenuItem = this.shop.mobMenuItem
      const data = []
      mobMenuItem.map((item) => {
        if (item.children) {
          const childData = []
          item.children.map(c => {
            if (c.id !== row.id) {
              childData.push(c)
            }
          })
          item.children = childData
        }

        data.push(item)
      })
      this.shop.mobMenuItem = data
      this.mobMenuItemRerendorIndex()
    },
    addMobMenuItemChildren(id) {
      const mobMenuItem = this.shop.mobMenuItem
      const data = []
      mobMenuItem.map((item) => {
        if (item.id === id) {
          let children = item.children
          if (!children) {
            children = []
          }
          children.push({
            id: uuidv4(),
            level: 2,
            title: '新按鈕' + children.length,
            type: 'LINK',
            path: '/',
            hideWhenFamilyAcount: false
          })
          item.children = children
        }
        data.push(item)
      })
      this.disabledTranslateBtn = true
      this.shop.mobMenuItem = data
      this.mobMenuItemRerendorIndex()
    },
    mobMenuItemTableRowClassName(rowData) {
      if (rowData.row.level === 1) {
        return ''
      } else {
        return 'greyColor'
      }
    },
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    moveHeaderLeftItem(id, action) {
      const arr = this.shop.mobHeaderLeftItem
      let index = null
      arr.map((item, i) => {
        if (item.id === id) {
          index = i
        }
      })

      if (action === 'up') {
        const moveItem = arr[index]
        const targetItem = arr[index - 1]

        arr[index] = targetItem
        arr[index - 1] = moveItem
      } else if (action === 'down') {
        const moveItem = arr[index]
        const targetItem = arr[index + 1]

        arr[index] = targetItem
        arr[index + 1] = moveItem
      }

      this.shop.mobHeaderLeftItem = arr
      this.mobHeaderLeftItemRerendorIndex()
    },

    addHeaderLeftItem() {
      let mobHeaderLeftItem = this.shop.mobHeaderLeftItem
      if (!mobHeaderLeftItem || !mobHeaderLeftItem.length) {
        mobHeaderLeftItem = []
      }
      mobHeaderLeftItem.push({
        id: uuidv4(),
        icon: 'RiGlobalLine',
        type: 'LINK',
        path: '/',
        hideWhenFamilyAcount: false
      })
      this.shop.mobHeaderLeftItem = mobHeaderLeftItem
      this.disabledTranslateBtn = true
      this.mobHeaderLeftItemRerendorIndex()
    },
    deleteHeaderLeftItem(row) {
      console.log('deleteHeaderLeftItem..', row)
      const mobHeaderLeftItem = this.shop.mobHeaderLeftItem
      const data = []
      mobHeaderLeftItem.map((item, i) => {
        if (item.id !== row.id) {
          data.push(item)
        }
      })
      this.shop.mobHeaderLeftItem = data
      this.mobHeaderLeftItemRerendorIndex()
    },
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    moveHeaderRightItem(id, action) {
      const arr = this.shop.mobHeaderRightItem
      let index = null
      arr.map((item, i) => {
        if (item.id === id) {
          index = i
        }
      })

      if (action === 'up') {
        const moveItem = arr[index]
        const targetItem = arr[index - 1]

        arr[index] = targetItem
        arr[index - 1] = moveItem
      } else if (action === 'down') {
        const moveItem = arr[index]
        const targetItem = arr[index + 1]

        arr[index] = targetItem
        arr[index + 1] = moveItem
      }

      this.shop.mobHeaderRightItem = arr
      this.mobHeaderRightItemRerendorIndex()
    },

    addHeaderRightItem() {
      let mobHeaderRightItem = this.shop.mobHeaderRightItem
      if (!mobHeaderRightItem || !mobHeaderRightItem.length) {
        mobHeaderRightItem = []
      }
      mobHeaderRightItem.push({
        id: uuidv4(),
        icon: 'RiGlobalLine',
        type: 'LINK',
        path: '/',
        hideWhenFamilyAcount: false
      })
      this.shop.mobHeaderRightItem = mobHeaderRightItem
      this.disabledTranslateBtn = true
      this.mobHeaderRightItemRerendorIndex()
    },
    deleteHeaderRightItem(row) {
      console.log('deleteHeaderRightItem..', row)
      const mobHeaderRightItem = this.shop.mobHeaderRightItem
      const data = []
      mobHeaderRightItem.map((item, i) => {
        if (item.id !== row.id) {
          data.push(item)
        }
      })
      this.shop.mobHeaderRightItem = data
      this.mobHeaderRightItemRerendorIndex()
    },
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    moveMobFooterItem(id, action) {
      const arr = this.shop.mobFooterItem
      let index = null
      arr.map((item, i) => {
        if (item.id === id) {
          index = i
        }
      })

      if (action === 'up') {
        const moveItem = arr[index]
        const targetItem = arr[index - 1]

        arr[index] = targetItem
        arr[index - 1] = moveItem
      } else if (action === 'down') {
        const moveItem = arr[index]
        const targetItem = arr[index + 1]

        arr[index] = targetItem
        arr[index + 1] = moveItem
      }

      this.shop.mobFooterItem = arr
      this.mobFooterItemRerendorIndex()
    },

    addMobFooterItem() {
      let mobFooterItem = this.shop.mobFooterItem
      if (!mobFooterItem || !mobFooterItem.length) {
        mobFooterItem = []
      }
      mobFooterItem.push({
        id: uuidv4(),
        title: '新按鈕' + mobFooterItem.length,
        icon: 'HomeOutlined',
        type: 'LINK',
        path: '/',
        hideWhenFamilyAcount: false
      })
      this.shop.mobFooterItem = mobFooterItem
      this.disabledTranslateBtn = true
      this.mobFooterItemRerendorIndex()
    },
    deleteMobFooterItem(row) {
      console.log('deleteFooterItem..', row)
      const mobFooterItem = this.shop.mobFooterItem
      const data = []
      mobFooterItem.map((item, i) => {
        if (item.id !== row.id) {
          data.push(item)
        }
      })
      this.shop.mobFooterItem = data
      this.mobFooterItemRerendorIndex()
    },
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    async loadData() {
      const imgConfig = await fileSizeConfig('IMAGE')
      if (imgConfig) {
        const itemValue = imgConfig.itemValue
        this.fileImageSize = parseStrToInt(itemValue)
      }
      var vid = this.$route.query.id
      findShopInfoById(vid).then(res => {
        console.log('shop data =====>', res)
        var data = res.data
        this.shop = data
        if (!data.parentShopId) {
          this.shop.parentShopId = data.id
        }
        if (data.bannerGroupUrl && data.bannerGroupUrl.length > 0) {
          this.bannerUrl = data.bannerGroupUrl[0].url
        }

        var arr = [
          { parentId: data.id, functionCode: 'payment', item: 'wublishabel' },
          { parentId: data.id, functionCode: 'payment', item: 'secret' },
          { parentId: data.id, functionCode: 'payment', item: 'webhook' },
          { parentId: data.id, functionCode: 'plan', item: 'plan' }
        ]

        this.forceRerender()

        findShopConfig(arr).then(res => {
          var ll = res
          ll && ll.map(item => {
            console.log(item)

            if (item && item.item === 'plan' && item.itemValue) {
              this.shop.servicePlan.planTimeConfigId = item.id
              this.shop.servicePlan.planTimeConfig = item.itemValue
              if (this.shop.servicePlan.planTimeConfig && this.shop.servicePlan.planTimeConfig === 'N') {
                this.planConfigStyle = 'display:block;'
              }
              if (item.domainJson) {
                var damainJson = JSON.parse(item.domainJson)
                if (damainJson.cardName) this.shop.servicePlan.cardName = damainJson.cardName
                if (damainJson.days) this.shop.servicePlan.days = damainJson.days
                if (damainJson.price) this.shop.servicePlan.price = damainJson.price
                if (damainJson.amount) this.shop.servicePlan.amount = damainJson.amount
              }
            }
          })
        })
      })
    },
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    showTranslateDrawer() {
      this.tDrawer = true
    },
    handleMessage(e) {
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleDrawerClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleDrawerClose()
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleDrawerClose() {
      this.tDrawer = false
    },
    getIframe(e) {
      var defaultLang = getLanguage()
      var systemLanguageCodes = JSON.parse(localStorage.getItem('SYSTEM_LANGUAGE_CODES'))
      if (systemLanguageCodes && systemLanguageCodes.data && systemLanguageCodes.data.defaultLanguage) {
        defaultLang = systemLanguageCodes.data.defaultLanguage
      }
      const ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      this.ifreamSrc =
        ifreamSrc +
        '?system=gallery&module=admin&function=menuItem&isIframe=true&language=' +
        defaultLang + '&sytemLanguage=' + getLanguage() + '&no=' + Math.ceil(Math.random() * 100)
      var data = []

      // ///////////////////////////////////商店名稱
      data.push(
        { name: this.$t('shopList.shopCreate.shopName'), key: this.shop.id + '.shop.name', type: 'input', value: this.shop.name },
        { name: this.$t('shopList.shop.banner.content'), key: this.shop.id + '.shop.appIntroduce', type: 'input', value: this.shop.appIntroduce }
      )

      let translateItemTitle = null
      // ///////////////////////////////////Header Item(PC)

      translateItemTitle = this.$t(' Header Item(PC)') + ' - ' + this.$t('Menu Item')
      this.shop.pcMenuItem.map(item => {
        data.push(
          { name: translateItemTitle, key: item.id + '.menuItem.title', type: 'input', value: item.title }
        )
        if (item.children) {
          item.children.map(c => {
            data.push(
              { name: translateItemTitle, key: c.id + '.menuItem.title', type: 'input', value: c.title }
            )
          })
        }
      })

      // ///////////////////////////////////Header Item(Mobile)

      translateItemTitle = this.$t('Menu Item(Mobile)') + ' - ' + this.$t('Menu Item')
      this.shop.mobMenuItem.map(item => {
        data.push(
          { name: translateItemTitle, key: item.id + '.menuItem.title', type: 'input', value: item.title }
        )
        if (item.children) {
          item.children.map(c => {
            data.push(
              { name: translateItemTitle, key: c.id + '.menuItem.title', type: 'input', value: c.title }
            )
          })
        }
      })

      // ///////////////////////////////////
      translateItemTitle = this.$t('Footer Item(Mobile)') + ' - ' + this.$t('Menu Item')
      this.shop.mobFooterItem.map(item => {
        data.push(
          { name: translateItemTitle, key: item.id + '.menuItem.title', type: 'input', value: item.title }
        )
      })

      console.log('iframe 对象', this.$refs.iframe)

      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
      console.log('iframe 对象', this.$refs.iframe)
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    handCheckData() {
      if (this.shop.servicePlan.planTimeConfig && this.shop.servicePlan.planTimeConfig === 'N') {
        this.planConfigStyle = 'display:block'
      } else {
        this.planConfigStyle = 'display:none'
        this.shop.servicePlan.cardName = ''
        this.shop.servicePlan.days = ''
        this.shop.servicePlan.price = ''
        this.shop.servicePlan.amount = ''
      }
    },
    getPlanConfig() {
      if (this.shop.servicePlan.planTimeConfig && this.shop.servicePlan.planTimeConfig === 'N') {
        return "display:'';"
      }
      return 'display:none;'
    },
    handler({ BMap, map }) {
      console.log('map---', map)
      var point = new BMap.Point(this.center.lng, this.center.lat)
      var geolocation = new BMap.Geolocation()
      var _this = this
      geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() === 0) {
          var mk = new BMap.Marker(r.point)
          map.addOverlay(mk)
          map.panTo(r.point)
          _this.shop.lon = r.point.lng
          _this.shop.lat = r.point.lat
          console.log(r.point.lng, r.point.lat)
        } else {
          console.log('failed' + this.getStatus())
          alert('failed location!' + this.getStatus())
        }
      })
      var marker = new BMap.Marker(point)
      map.addOverlay(marker)
      window.map = map
      window.BMap = BMap
    },
    getLocationPoint(e) {
      console.log(e)
      console.log(e.point.lng)
      console.log(e.point.lat)
      this.shop.lon = e.point.lng
      this.shop.lat = e.point.lat
      window.map.clearOverlays()
      var point = new window.BMap.Point(e.point.lng, e.point.lat)
      var marker = new window.BMap.Marker(point)
      window.map.addOverlay(marker) // 将标注添加到地图中
    },
    handlePictureCardPreview(file) {
      console.log('file-----', file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getList() {
      countryList().then((res) => {
        this.countrysList = res
      })
      findMainStore().then((res) => {
        this.mainStoreList = res.data
      })
    },
    beforeFileUpload(file) {
      const fileType = getFileType(file)
      if (fileType !== 'IMAGE') {
        this.$message.error(this.$t('file.formatErrMsg'))
        return false
      }
      const size = file.size
      const defaultSize = this.fileImageSize
      if (defaultSize && defaultSize > 0) {
        const filterSize = defaultSize * 1024
        if (size > filterSize) {
          const msg = this.$t('file.sizeLimitMsg') + ': ' + defaultSize + 'KB'
          this.$message.error(msg)
          return false
        }
      }
      return true
    },
    uploadImg(file) {
      console.log(file)
      var newFile = file.file
      var formData = new FormData()
      formData.append('files', newFile)
      imgUpload(formData).then((res) => {
        this.shop.logoUrl = res[0].url
      })
    },
    uploadBannerUrl(file) {
      var newFile = file.file
      var formData = new FormData()
      formData.append('files', newFile)
      imgUpload(formData).then((res) => {
        this.bannerUrl = res[0].url
        this.shop.bannerGroupUrl = [{ id: null, url: res[0].url }]
        console.log('uploadBannerUrl>>>>>>>>', this.shop)
      })
    },
    uploadOverrun: function() {
      this.$message({
        type: 'error',
        message: this.$t('rule.uploadMax')
      })
    },
    handleRemove(file, fileList) {
      for (var i = 0; i < this.shop.bannerGroupUrl.length; i++) {
        var url
        if (file.response === undefined) {
          url = file.url
        } else {
          url = file.response.data.url
        }
        console.log(url)
        if (getStaticSourcePath(this.shop.bannerGroupUrl[i].url) === url) {
          this.shop.bannerGroupUrl.splice(i, 1)
          this.bannerUrl.splice(i, 1)
        }
      }
      console.log(this.shop.bannerGroupUrl)
    },
    handleCountryChange() {
      for (var i = 0; i < this.countrysList.length; i++) {
        if (this.countryId === this.countrysList[i].id) {
          this.country.id = this.countryId
          this.country.description = this.countrysList[i].description
          this.countryType = this.countrysList[i].locationTypes
          console.log('country', this.country)
          this.shop.addressDes = { country: this.country }
          loctionTypeList(this.countryId, this.countrysList[i].locationTypes[0].id).then(
            (res) => {
              console.log(res)
              this.location.splice(0, this.location.length, { loc: res })
            }
          )
        }
      }
      this.parentId = []
    },
    getLocation(index) {
      if (this.location[index]) {
        return this.location[index].loc
      }
      return null
    },
    handleLocTypeChange(i) {
      var description = ''
      for (var j = 0; j < this.location[i].loc.length; j++) {
        if (this.parentId[i] === this.location[i].loc[j].id) {
          description = this.location[i].loc[j].description
        }
      }
      var addressLocation = {
        id: this.parentId[i],
        description: description,
        locTypeId: this.countryType[i].id
      }
      this.locationDtl.splice(i, this.locationDtl.length - i, addressLocation)
      this.shop.addressDes = {
        country: this.country,
        addressLocations: this.locationDtl
      }
      locList(this.countryId, this.parentId[i]).then((res) => {
        this.location.splice(i + 1, this.location.length - 1 - i, { loc: res })
        for (var j = i + 1; j < this.parentId.length; j++) {
          this.parentId[j] = ''
        }
      })
    },
    getImagePath(path) {
      console.log('image url >>', path)
      return getStaticSourcePath(path)
    },
    isNull(data) {
      return data === null || data === undefined || data === '' || data.trim() === ''
    },
    checkMobMenuItem() {
      const mobMenuItem = this.shop.mobMenuItem
      let result = true
      mobMenuItem.map(item => {
        if (this.isNull(item.title)) {
          result = false
        }
        if (item.children) {
          item.children.map((c) => {
            if (this.isNull(c.title)) {
              result = false
            }
          })
        }
      })
      return result
    },
    saveModel() {
      this.shop.addressDes = {
        country: this.country,
        addressLocations: this.locationDtl,
        addressLocationDesc: this.shop.desc
      }

      if (!this.checkMobMenuItem()) {
        this.$message({
          type: 'error',
          message: this.$t('Please check data in Header Button(Mobile), The title can not be null')
        })
        return
      }
      console.log('this shop data is', this.shop)
      this.$refs['shop'].validate((valid) => {
        if (valid) {
          shopSave(this.shop)
            .then((res) => {
              this.$message({
                type: 'success',
                message: this.$t('COMPONENTS.save_Success')
              })
              this.$router.back(-1)
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: this.$t('COMPONENTS.save_Filad')
              })
            })
        } else {
          return false
        }
      })
    },
    sleep(delay) {
      for (var t = Date.now(); Date.now() - t <= delay;);
    }
  }
}
</script>

<style scoped lang="scss">
.el-tag + .el-tag {
  margin-left: 10px;
}

.input-new-keyword {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

/deep/.avatar-uploader .el-upload {
  width: 145px;
  height: 145px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

//在样式把这个is-ready给隐藏掉就好了
/deep/.el-upload-list__item.is-ready {
  display: none;
}

.avatar {
  width: 145px;
  height: 145px;
  display: block;
}
.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}
.card-box {
  margin: 0.5rem 0.5rem 0;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}

</style>
<style>
  .el-table .greyColor {
    background-color: #f3f3f3;
  }
  .option_box {
    display: flex;
    align-items: center;
  }

  .option_img {
    width: 25px;
    height: 25px;
    margin-right: 7px;
  }

  .page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.el-upload-list__item.is-ready {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  float: left;
  margin-right: 10px;
}
#product-form {
  padding-bottom: 0.5rem;
}
.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}

.el-drawer {
  overflow: scroll;
}
.el-drawer__body {
  margin-left: 20px;
}
.el-drawer__header {
  background-color: #2ea6f1;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.el-drawer__header > :first-child {
  margin-bottom: 18px;
}
.el-drawer__body {
  margin-top: -22px;
}
.iframe-class {
  width: 100%;
  border: 0px;
}

.el-transfer-panel{
  width:300px;
}

</style>
