import request from '@/utils/request'
import { API_BASE_URL_MEMBER, API_BASE_URL_PRODUCT } from '../Config'
import { getAccessToken } from '@/service/AuthService'
import { getLanguage } from '@/lang'

export function findMembers(param, query) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/member/findMembers' + param,
    method: 'post',
    data: query
  })
}

export function findMemberByAdmin(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/member/findMemberByAdmin/' + id,
    method: 'get'
  })
}

export function saveMemberInfoByAdmin(data) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/member/saveMemberInfoByAdmin',
    method: 'post',
    data: data
  })
}

export function deleteMember(data) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/member/deleteMember',
    method: 'post',
    data: data
  })
}

export function deleteMemberOnOrderSystem(data) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/member/deleteMemberOrder/' + data.id + '/',
    method: 'post',
    data: data
  })
}

export function batchImport(data) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/member/batchImport',
    method: 'post',
    data: data
  })
}

export function exportExcel(query) {
  const requestHeaders = {
    'Content-Type': 'application/json'
  }
  if (getAccessToken()) {
    requestHeaders['Authorization'] = 'Bearer ' + getAccessToken()
  }
  if (getLanguage()) {
    requestHeaders['language'] = getLanguage()
  }
  return fetch(API_BASE_URL_MEMBER + '/api/member/exportExcel', {
    method: 'POST',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify(query)
  })
}

export function adminGetBankJsonInfo(memberId) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/store/adminGetBankJsonInfo/' + memberId,
    method: 'get'
  })
}

