<template>
  <div v-if="isRouterAlive" id="app">
    <router-view />
  </div>
</template>

<script>
import { fetchLableLanguageList } from './api/languageApi'
import { getLanguage } from './lang'
import lodash from 'lodash'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      isLoad: false
    }
  },
  // beforeCreate(){
  //   this.$nextTick(function () {

  //   });

  // },
  beforeMount() {
    // window.addEventListener("setItem", this.handleMessage);
    // var isLoad = localStorage.getItem("isLoad");
    // if (isLoad) {
    this.loadData()
    // }
  },
  destroyed() {
    // window.removeEventListener("setItem", this.handleMessage);
  },
  methods: {
    reload() {
      this.isRouterAlive = false // 先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true // 再打开
      })
    },
    handleMessage(e) {
      // if (e.key === "permissionList") {
      //   localStorage.setItem("isLoad", true);
      //   window.location.reload();
      // }
    },
    loadData() {
      const lableLanguage = {}
      fetchLableLanguageList({
        system: 'gallery',
        module: 'admin'
      }).then((res) => {
        const { data } = res
        data &&
          data.forEach((item) => {
            item.languageJson &&
              item.languageJson.forEach((element) => {
                lodash.set(
                  lableLanguage,
                  item.language + '.' + element.key,
                  element.value
                )
              })
          })
        console.log(
          'messages $this',
          this.$i18n.getLocaleMessage(getLanguage())
        )
        this.$i18n.setLocaleMessage(
          getLanguage(),
          lodash.get(lableLanguage, getLanguage())
        )
        console.log(
          'messages $this',
          this.$i18n.getLocaleMessage(getLanguage())
        )
      })
    }
  }
}
</script>
