<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col v-if="disabledTranslateBtn" :span="12" class="page-header">{{ $t('COMPONENTS.add') }}</el-col>
        <el-col v-else :span="12" class="page-header">{{ $t("COMPONENTS.edit") }}</el-col>
        <el-col :span="12">
          <el-button
            :disabled="disabledTranslateBtn"
            @click="setting()"
          >{{ $t('COMPONENTS.translate') }}</el-button>
          <el-button @click="$router.back(-1)">{{
            $t("COMPONENTS.return")
          }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            @click="submitBtn('temp')"
          >{{ $t("COMPONENTS.save") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-card class="card-box">
      <el-row>
        <el-col>
          <el-form ref="temp" :model="temp" :rules="rules" label-width="120px">
            <el-form-item prop="name" :label="$t('brand.brandName')">
              <el-input
                v-model="temp.name"
                :value="temp.name"
                style="width: 38%"
              />
            </el-form-item>
            <el-form-item prop="logoUrl" :label="$t('brand.brandImg')">
              <el-upload
                action="#"
                :http-request="uploadMainImages"
                :file-list="mainBannerUrl"
                accept=".jpg,.jpeg,.png,.gif"
                class="avatar-uploader"
                :show-file-list="false"
                :before-upload="beforeFileUpload"
              >
                <img v-if="logoUrl" :src="logoUrl" class="avatar" alt="">
                <em v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-form-item>
            <el-form-item prop="remark" :label="$t('brand.remark')">
              <el-input
                v-model="temp.remark"
                :value="temp.remark"
                style="width: 38%"
              />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-drawer
        class="translate-drawer"
        :title="$t('COMPONENTS.translate')"
        :visible.sync="drawer"
        size="60%"
        :show-close="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        @opened="getIframe"
      >
        <iframe
          ref="iframe"
          :src="ifreamSrc"
          class="iframe-class"
          :height="getClientHeight()"
          title=""
        />
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
import { saveOrUpdate, toEdit } from '@/api/brand'
import { imgUpload } from '@/api/uploadApi'
import Sticky from '@/components/Sticky'
import { API_CFS_SERVER_URL } from '@/Config'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getLanguage } from '../../lang'
import { checkCfsPermission } from '@/utils/permission'
import { fileSizeConfig } from '@/api/mallorderApi/systemConfigApi'
import { getFileType, parseStrToInt } from '@/utils'
export default {
  name: 'BrandCreat',
  components: { Sticky },
  data() {
    var validateBrandName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('rule.Please-enter-brand-name')))
      } else {
        callback()
      }
    }
    return {
      disabledTranslateBtn: false,
      ifreamSrc: '',
      mainBannerUrl: [],
      count: 0,
      drawer: false,
      logoUrl: '',
      rules: {
        name: [
          { required: true, validator: validateBrandName, trigger: 'blur' }
        ],
        logoUrl: [
          {
            required: true,
            message: this.$t('rule.mainImgUrl'),
            trigger: 'change'
          }
        ]
      },
      temp: {
        id: '',
        name: '',
        logoUrl: '',
        remark: ''
      },
      fileImageSize: null
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
      } else {
        clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    handleMessage(e) {
      console.log(e.data)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleClose()
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleClose() {
      this.drawer = false
    },
    getIframe() {
      var defaultLang = getLanguage()
      var systemLanguageCodes = JSON.parse(localStorage.getItem('SYSTEM_LANGUAGE_CODES'))
      if (systemLanguageCodes && systemLanguageCodes.data && systemLanguageCodes.data.defaultLanguage) {
        defaultLang = systemLanguageCodes.data.defaultLanguage
      }
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      this.ifreamSrc =
        ifreamSrc + '?system=gallery&module=mobile&function=brand&isIframe=true&language=' + defaultLang + '&sytemLanguage=' + getLanguage()

      var data = [
        { name: this.$t('brand.brandName'), key: this.temp.id + '.brandName', type: 'input', value: this.temp.name, usageTypeCode: 'brand', refId: this.temp.id },
        { name: this.$t('brand.remark'), key: this.temp.id + '.remark', type: 'input', value: this.temp.remark, usageTypeCode: 'brand', refId: this.temp.id }
      ]
      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
    },
    setting() {
      if (this.width > 0) {
        return
      }
      this.drawer = true
    },
    async loadData() {
      const imgConfig = await fileSizeConfig('IMAGE')
      if (imgConfig) {
        const itemValue = imgConfig.itemValue
        this.fileImageSize = parseStrToInt(itemValue)
      }
      if (this.$route.query && this.$route.query.brandId) {
        var vid = this.$route.query.brandId
        toEdit(vid).then((response) => {
          var data = response.data
          this.temp.id = data.id
          this.temp.name = data.name
          this.temp.logoUrl = data.logoUrl
          this.logoUrl = getStaticSourcePath(data.logoUrl)
          this.temp.remark = data.remark
        })
      } else {
        this.disabledTranslateBtn = true
      }
    },
    beforeFileUpload(file) {
      const fileType = getFileType(file)
      if (fileType !== 'IMAGE') {
        this.$message.error(this.$t('file.formatErrMsg'))
        return false
      }
      const size = file.size
      const defaultSize = this.fileImageSize
      if (defaultSize && defaultSize > 0) {
        const filterSize = defaultSize * 1024
        if (size > filterSize) {
          const msg = this.$t('file.sizeLimitMsg') + ': ' + defaultSize + 'KB'
          this.$message.error(msg)
          return false
        }
      }
      return true
    },
    uploadMainImages(file) {
      var newFile = file.file
      var formData = new FormData()
      formData.append('files', newFile)
      imgUpload(formData).then((res) => {
        this.mainBannerUrl.push({
          name: res[0].url,
          url: getStaticSourcePath(res[0].url)
        })
        this.logoUrl = getStaticSourcePath(res[0].url)
        this.temp.logoUrl = res[0].url
      })
    },
    submitBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            id: this.temp.id || '',
            name: this.temp.name,
            logoUrl: this.temp.logoUrl,
            remark: this.temp.remark
          }

          saveOrUpdate(params).then((response) => {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.save_Success'),
              type: 'success'
            })
            this.$router.back(-1)
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss">
.el-upload-list__item.is-ready {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
#product-form {
  padding-bottom: 0.5rem;
}
.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}
.card-box {
  margin: 0.5rem 0.5rem 0;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.iframe-class {
  width: 100%;
  border: 0px;
}
.iframe-antho {
  height: 100%;
}
.el-drawer__header{
  background-color: #2ea6f1;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
  // margin-bottom: 30px;
}
.el-drawer__header>:first-child{
  margin-bottom: 18px;
}
.el-drawer__body{
  margin-top: -22px
}
</style>
