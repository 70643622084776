var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-form",
              {
                staticClass: "search-form-inline",
                attrs: {
                  "label-suffix": ":",
                  "label-position": "left",
                  "hide-required-asterisk": "",
                  "label-width": "150px",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 10, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.shop") } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                },
                                model: {
                                  value: _vm.listQuery.shopId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "shopId", $$v)
                                  },
                                  expression: "listQuery.shopId",
                                },
                              },
                              _vm._l(_vm.shopOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 10, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Admin Tag") } },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.listQuery.adminTag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "adminTag", $$v)
                                },
                                expression: "listQuery.adminTag",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 10, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Activity Type") } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                },
                                model: {
                                  value: _vm.listQuery.activityType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "activityType", $$v)
                                  },
                                  expression: "listQuery.activityType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    value: "NEW_REG",
                                    label: _vm.$t("NEW_REG.label"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 10, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Status") } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                },
                                model: {
                                  value: _vm.listQuery.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "status", $$v)
                                  },
                                  expression: "listQuery.status",
                                },
                              },
                              _vm._l(_vm.statusOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                    disabled: item.disabled,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-row", [
                  _c(
                    "div",
                    { staticStyle: { float: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.checkPermission("ACTIVITY.ADD"),
                          },
                          on: { click: _vm.handleCreate },
                        },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.checkPermission("ACTIVITY.SEARCH"),
                            type: "primary",
                          },
                          on: { click: _vm.searchJob },
                        },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.search")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            "header-row-class-name": "table-header",
            data: _vm.list,
            size: "small",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Schedule"),
              prop: "schedule",
              width: "190",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.schedule === "WAIT_APPROVED"
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(_vm._s(_vm.$t("WAIT_APPROVED.label"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.schedule === "NON_START"
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v(_vm._s(_vm.$t("NON_START.label"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.schedule === "GOING"
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(_vm._s(_vm.$t("GOING.label"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.schedule === "FINISHED"
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v(_vm._s(_vm.$t("FINISHED.label"))),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Admin Tag"),
              prop: "adminTag",
              width: "200",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.shop"),
              prop: "shopId",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getShopName(scope.row.shopId)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Activity Title"),
              prop: "activityTitle",
              width: "200",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Activity Type"),
              prop: "activityType",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.activityType === "NEW_REG"
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("NEW_REG.label")))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("COMPONENTS.time"), width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.startPublicDate !== null
                      ? _c("span", [_vm._v(_vm._s(scope.row.startPublicDate))])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.endPublicDate !== null
                      ? _c("span", [
                          _vm._v("  ~"),
                          _c("br"),
                          _vm._v(_vm._s(scope.row.endPublicDate)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.status"),
              prop: "newStatus",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.lastModifiedDate"),
              prop: "lastModifiedDate",
              width: "150px",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Last Modified By"),
              prop: "lastModifiedBy",
              width: "140px",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("COMPONENTS.operation"),
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "mini", round: "" } },
                          [_c("em", { staticClass: "el-icon-more" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    _vm.checkPermission("ACTIVITY.EDIT"),
                                  icon: "el-icon-edit",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleUpdate(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("COMPONENTS.edit")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    _vm.checkPermission("ACTIVITY.DELETE"),
                                  icon: "el-icon-close",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.deleteActivity(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("COMPONENTS.delete")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            scope.row.schedule === "GOING"
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.checkPermission("ACTIVITY.DELETE"),
                                      icon: "el-icon-time",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.stopSold(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("COMPONENTS.endEarly")) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.searchData,
        },
      }),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          attrs: { placement: "top", content: _vm.$t("COMPONENTS.return-top") },
        },
        [_c("back-to-top", { attrs: { "visibility-height": 100 } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }