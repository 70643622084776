<template>
  <div class="match-goods-tabs">
    <el-tabs tab-position="left">
      <template>
        <el-tab-pane :label="$t('productItem.basic_price')">
          <el-form ref="mixForm" :model="mixForm" :rules="rules">
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.retailPrice')" prop="retailPrice">
                  <el-input v-model="mixForm.retailPrice" placeholder="">
                    <template slot="prepend">HK$</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.promotionPrice')">
                  <el-input v-model="mixForm.promotionPrice" placeholder="">
                    <template slot="prepend">HK$</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.kcNum')" prop="kcNum">
                  <el-input v-model="mixForm.kcNum" placeholder="" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('productItem.attribute')" :disabled="checkPermission('SHOP_PRODUCT_ATTRIBUTE')">
          <el-row :gutter="20">
            <el-col>
              <el-select v-model="productAttribute" placeholder="请选择" style="margin-left:0.5rem" @change="selProductAttribute">
                <el-option :label="$t('productItem.custom-attribute')" value="自定义属性" />
                <el-option v-for="(item,index) in attributesGroup" :key="index" :label="item.name" :value="item.id" />
              </el-select>
              <el-button @click="addCard">{{ $t("COMPONENTS.add") }}</el-button>
              <el-button v-show="false" @click="saveAttribute">{{ $t("COMPONENTS.save") }}</el-button>
            </el-col>
          </el-row>

          <el-row v-show="customAttribute" :gutter="20">
            <el-col>
              <el-card v-for="(item,index) in list" :key="index" class="card-box" :style="item.styleObject">
                <el-row>
                  <el-col>
                    <i class="el-icon-close" style="float:right;margin-top:-10px;margin-left:5px" @click="deleteCard(item.id)" />
                    <i class="el-icon-minus" style="float:right;margin-top:-10px" />
                    <i v-show="item.showUp" class="el-icon-arrow-up" style="float:right;margin-top:-10px;margin-right:5px" @click="showRow(item)" />
                    <i v-show="item.showDown" class="el-icon-arrow-down" style="float:right;margin-top:-10px;margin-right:5px" @click="hideRow(item)" />
                  </el-col>
                </el-row>
                <el-row v-show="item.isShow" :gutter="20">
                  <el-col style="padding-top:20px;">
                    <el-col :span="8">
                      <span style="font-size: 14px;color: #606266;font-weight: 700;">{{ $t("productItem.attributeTitle") }}</span>
                      <el-autocomplete
                        ref="attrs_demo"
                        v-model="item.attrTitle"
                        :fetch-suggestions="querySearchAsync"
                        :placeholder="$t('COMPONENTS.incontent')"
                        @select="handleSelect(item.attrTitle,item)"
                      />
                      <el-form-item :label="$t('productItem.isRequired')" style="margin-top:15px">
                        <el-radio-group v-model="item.isRequired">
                          <el-radio label="0">No</el-radio>
                          <el-radio label="1">Yes</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item :label="$t('productItem.isMultple')" style="margin-top:15px">
                        <el-radio-group v-model="item.isMultple">
                          <el-radio label="0">No</el-radio>
                          <el-radio label="1">Yes</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <span style="font-size: 14px;color: #606266;font-weight: 700;">{{ $t("productItem.attribute") }}</span>
                      <br>
                      <div style="border:1px solid #d4d4d4;height:140px;width:580px;padding:10px">
                        <el-tag
                          v-for="tag in item.attributeContents"
                          :key="tag"
                          closable
                          :disable-transitions="false"
                          @close="handleClose(item,tag)"
                        >
                          {{ tag }}
                        </el-tag>
                        <el-input
                          v-if="item.inputVisible"
                          ref="saveTagInput"
                          v-model="item.inputValue"
                          class="input-new-tag"
                          size="small"
                          @keyup.enter.native="handleInputConfirm(item)"
                          @blur="handleInputConfirm(item)"
                        />
                        <el-button v-else class="button-new-tag" size="small" @click="showInput(item)">+ New Tag</el-button>
                      </div>
                    </el-col>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('productItem.Match-ITEM')">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{ $t('productItem.product_mix_and_match_list') }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-button @click="addItem">{{ $t('productItem.add_mix_match_item') }}</el-button>
            </el-row>
            <el-row>
              <el-table
                :data="itemList"
                :highlight-current-row="true"
                @row-click="handdle"
              >
                <el-table-column :label="$t('productItem.name')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.name" />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.isRequired')" prop="isNeed" width="150px" align="left">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.isNeed" active-color="#13ce66" inactive-color="#808080" />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.desc')" width="300px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.description" />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.isMultple')" prop="isMultiSelect" width="150px" align="left">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.isMultiSelect" active-color="#13ce66" inactive-color="#808080" />
                    <el-col v-if="scope.row.isMultiSelect">
                      <el-row :gutter="20">
                        <el-col>
                          <label>{{ $t('productItem.min_select') }}</label>
                          <el-input v-model="scope.row.minNum" placeholder="" />
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col>
                          <label>{{ $t('productItem.max_select') }}</label>
                          <el-input v-model="scope.row.maxNum" placeholder="" />
                        </el-col>
                      </el-row>
                    </el-col>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.operation')">
                  <template slot-scope="scope">
                    <el-button @click="deleteItem(scope.row)">{{ $t('COMPONENTS.delete') }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-card>

          <el-card v-if="isItemShow" class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{ $t('productItem.choose_product_list') }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-button @click="addProduct">{{ $t('productItem.Add_Goods') }}</el-button>
            </el-row>
            <el-row>
              <el-table :data="productList">
                <el-table-column :label="$t('productItem.picture')">
                  <template slot-scope="scope">
                    <el-image :src="getStaticSourcePath(scope.row.url)" fit="scale-down" />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.product')">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.product"
                      :placeholder="$t('COMPONENTS.inselect')"
                      @change="selProduct(scope.row,scope.row.product)"
                    >
                      <el-option v-for="(item,index) in productListByData" :key="index" :label="item.name" :value="item.id" />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.price_adjustment')">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.priceType"
                      :placeholder="$t('COMPONENTS.inselect')"
                    >
                      <el-option :label="$t('productItem.fixed_price')" :value="1" />
                      <el-option :label="$t('productItem.floating_price')" :value="2" />
                      <el-option :label="$t('productItem.product_original_price')" :value="3" />
                      <el-option :label="$t('productItem.no_pricing')" :value="4" />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.price')">
                  <template slot-scope="scope">
                    <el-input
                      v-if="scope.row.priceType == 1"
                      v-model="scope.row.priceList[0].price"
                      :value="scope.row.priceList[0].price"
                    />
                    <el-button
                      v-if="scope.row.priceType == 2"
                      @click="priceSetting(scope.$index)"
                    >{{ $t('productItem.setup') }}</el-button>
                    <span v-if="scope.row.priceType == 3">-</span>
                    <span v-if="scope.row.priceType == 4">0</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.adjust_quantity')">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.numSelect" />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.select')">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.isSelect" />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('productItem.operation')">
                  <template slot-scope="scope">
                    <el-button @click="deleteProduct(scope.row)">{{ $t('COMPONENTS.delete') }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-card>
        </el-tab-pane>
        <el-dialog title="添加" :visible.sync="isVisible" width="60%">
          <el-table :data="priceList">
            <el-table-column label="数量区间" width="400">
              <template slot-scope="scope">
                <el-row v-if="scope.row.isEdit" :gutter="20">
                  <el-col :sm="8" :xs="16">
                    <el-input
                      v-model="
                        productList[productIndex].priceList[scope.$index].minNum
                      "
                      size="mini"
                    >
                      <template slot="append" style="width: 30px">qty</template>
                    </el-input>
                  </el-col>
                  <el-col :sm="1" :xs="1">~</el-col>
                  <el-col :sm="8" :xs="16">
                    <el-input
                      v-model="
                        productList[productIndex].priceList[scope.$index].maxNum
                      "
                      size="mini"
                    >
                      <template slot="append" style="width: 30px">qty</template>
                    </el-input>
                  </el-col>
                </el-row>
                <el-row
                  v-else
                >{{
                  productList[productIndex].priceList[scope.$index].minNum
                }}qty~{{
                  productList[productIndex].priceList[scope.$index].maxNum
                }}qty
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="价格" width="150px">
              <template slot-scope="scope">
                <el-row v-if="scope.row.isEdit" :gutter="20">
                  <el-col :sm="24" :xs="12">
                    <el-input
                      v-model="
                        productList[productIndex].priceList[scope.$index].price
                      "
                      placeholder=""
                      size="mini"
                    />
                  </el-col>
                </el-row>
                <el-row v-else>{{
                  productList[productIndex].priceList[scope.$index].price
                }}</el-row>
              </template>
            </el-table-column>
            <el-table-column label="动作" width="150px">
              <template slot-scope="scope">
                <el-row :gutter="20">
                  <el-col :sm="12" :xs="12">
                    <el-button v-if="!scope.row.isEdit" size="mini" @click="editPrice(scope.$index)">编辑</el-button>
                  </el-col>
                  <el-col :sm="12" :xs="12">
                    <el-button size="mini">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-button size="mini" @click="addPrice">增加</el-button>
          </el-row>
        </el-dialog>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import {
  getAttributes,
  saveAttributes,
  getTemplate,
  getGroupTemplate,
  productGroupList
} from '@/api/productItem'
import {
  deleteOptionById, deleteOptionChoiceById
} from '@/api/MgProductOptionApi'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
export default {
  name: 'MatchGoodsTabs',
  props: ['mretailPrice', 'mpromotionPrice', 'mskuNum', 'mgoodsId', 'mshopStruct', 'mproductListByData', 'optionList'],
  data() {
    var checkNumber = (rule, value, callback) => {
      var numReg = /^\d+(\.\d+)?$/
      var numRe = new RegExp(numReg)

      if (!numRe.test(value)) {
        return callback(new Error('请输入数字'))
      }
    }
    return {
      rules: {
        retailPrice: [
          { required: true, message: '请输入原始价格', trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' }
        ],
        promotionPrice: [
          { required: true, message: '请输入促销价格', trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' }
        ],
        kcNum: [
          { required: true, message: '请输入库存数量', trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' }
        ]
      },
      query: {
        id: '',
        size: 10000,
        page: 1,
        attrName: '',
        valueName: ''
      },
      productListByData: [{}],
      isItemShow: false,
      isShow: true,
      isGroup: true,
      save: false,
      attributes: [],
      attributesGroup: [],
      mixForm: {
        retailPrice: '',
        promotionPrice: '',
        kcNum: ''
      },
      choiceList: [],
      productAttribute: '自定义属性',
      itemList: [],
      productList: [],
      isVisible: false,
      priceList: [],
      productIndex: null,
      attrList: [{}],
      groupAttr: [],
      groupIds: [],
      customAttribute: true,
      macAttribute: false,
      list: [
        { 'id': 0,
          'isShow': true,
          'showUp': false,
          'showDown': true,
          'styleObject': {
            height: '240px'
          },
          'attrTitle': '',
          'isRequired': '0',
          'isMultple': '0',
          'attributeContents': [],
          'inputVisible': false,
          'inputValue': ''
        }
      ]
    }
  },
  mounted() {
    const self = this
    self.$nextTick(() => {
      this.loadData()
    })
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getStaticSourcePath,
    loadAll() {
      var array = []

      var query = { ...this.query }
      var param = getPageParm(query)

      getAttributes(param, query).then((resp) => {
        var data = resp.data
        if (data != null) {
          for (var i = 0; i < data.length; i++) {
            var d = data[i]
            array.push({
              id: d.id,
              name: d.name,
              attrValues: d.attrValues
            })
          }
        }
      })
      return array
    },
    querySearchAsync(queryString, cb) {
      var results = []

      for (var i = 0; i < this.attributes.length; i++) {
        var attribute = this.attributes[i]

        if (attribute.name.indexOf(queryString) !== -1) {
          results.push({
            value: attribute.name,
            id: attribute.id
          })
        }
      }
      cb(results)
    },
    selProductAttribute() {
      if (this.productAttribute === '自定义属性') {
        this.save = false
        this.list = [
          { 'id': 0,
            'isShow': true,
            'showUp': false,
            'showDown': true,
            'styleObject': {
              height: '240px'
            },
            'attrTitle': '',
            'attributeContents': [],
            'inputVisible': false,
            'inputValue': '',
            'isMultple': '0',
            'isRequired': '0'
          }
        ]
      } else {
        this.save = true
        // 商品组模板属性
        getGroupTemplate(this.productAttribute).then((resp) => {
          var data = resp.data
          var templates = data.groupTemplateList
          if (templates != null && templates.length > 0) {
            var array = []
            for (var i = 0; i < templates.length; i++) {
              var obj = templates[i]
              array.push({
                'id': i,
                'isShow': true,
                'showUp': false,
                'showDown': true,
                'styleObject': {
                  height: '240px'
                },
                'attrTitle': obj.attributeNameByGroup,
                'attributeContents': obj.attributeValueByGroup,
                'inputVisible': false,
                'inputValue': '',
                'isMultple': '0',
                'isRequired': '0'
              })
            }
            this.list = array
          }
        })
      }
      console.log('customAttribute:' + this.customAttribute + 'macAttribute:' + this.macAttribute)
    },
    handleSelect(attrTitle, item) {
      // console.log("attrTitle:"+attrTitle+";item:"+item);
      var array = []
      for (var i = 0; i < this.attributes.length; i++) {
        var attribute = this.attributes[i]
        if (attrTitle === attribute.name) {
          var contents = attribute.attrValues
          for (var j = 0; j < contents.length; j++) {
            var content = contents[j]
            array.push(content.name)
          }
        }
      }
      item.attributeContents = array
    },
    loadData() {
      // setTimeout(() =>{
      console.log('------------------1', this.optionList)
      console.log('------------------2', this.productId)

      this.mixForm.retailPrice = this.mretailPrice
      this.mixForm.promotionPrice = this.mpromotionPrice
      this.mixForm.kcNum = this.mskuNum
      this.productListByData = this.mproductListByData

      var mgoodsId = this.mgoodsId

      this.attributes = this.loadAll()

      var query = { ...this.query }
      var param = getPageParm(query)

      productGroupList(param, query).then((response) => {
        this.attributesGroup = response.data
      })

      console.log('mgoodsId', mgoodsId, 'mshopStruct', this.mshopStruct)

      // 商品属性
      if (mgoodsId !== '' && this.mshopStruct === 'MATCH-ITEM') {
        getTemplate(mgoodsId).then((resp) => {
          var data = resp.data
          var templates = data.templateList
          if (templates != null && templates.length > 0) {
            var array = []
            for (var i = 0; i < templates.length; i++) {
              var obj = templates[i]
              array.push({
                'id': i,
                'isShow': true,
                'showUp': false,
                'showDown': true,
                'styleObject': {
                  height: '240px'
                },
                'attrTitle': obj.attributeName,
                'isRequired': obj.isRequired,
                'isMultple': obj.isMultple,
                'attributeContents': obj.attributeValue,
                'inputVisible': false,
                'inputValue': ''
              })
            }
            this.list = array
          }
        })
      }
      if (this.optionList) {
        this.itemList = this.optionList
        this.choiceList = this.itemList.choiceList
      }
      var item = this.itemList
      if (this.itemList != null) {
        item.forEach(element => {
          this.productList = element.productList
          // element.productList.forEach(element => {
          //   // this.productListByData.push({
          //   //   id: element.product,
          //   //   name: element.name,
          //   //   url: element.url
          //   // })
          // })
        })
        console.log('this.productListByData', this.productListByData)
        this.isItemShow = true
      }
      // },2000);
    },
    addCard() {
      var length = this.list.length
      var obj = {
        'id': length,
        'isShow': true,
        'showUp': false,
        'showDown': true,
        'styleObject': {
          height: '240px'
        },
        'attrTitle': '',
        'isRequired': '1',
        'isVariable': '1',
        'attributeContents': [],
        'inputVisible': false,
        'inputValue': ''
      }
      this.list.push(obj)
    },
    hideRow(item) {
      this.$set(item, 'isShow', false)
      item.showUp = true
      item.showDown = false
      item.styleObject = {
        height: '60px'
      }
    },
    showRow(item) {
      this.$set(item, 'isShow', true)
      item.showUp = false
      item.showDown = true
      item.styleObject = {
        height: '240px'
      }
    },
    deleteCard(id) {
      for (var i = 0; i < this.list.length; i++) {
        var did = this.list[i].id
        if (id === did) {
          this.list.splice(id, 1)
        }
      }
    },
    saveAttribute() {
      var vattributeList = []

      for (var i = 0; i < this.list.length; i++) {
        var obj = this.list[i]
        vattributeList.push({
          attrTitle: obj.attrTitle,
          isRequired: obj.isRequired,
          isMultple: obj.isMultple,
          attributeContents: obj.attributeContents
        })
      }

      var query = {
        attributeGroupId: this.productAttribute,
        attributeList: vattributeList
      }

      saveAttributes(query).then((resp) => {
        this.$notify({
          title: '成功',
          message: '属性组保存成功',
          type: 'success'
        })
      })
    },
    handleClose(item, tag) {
      item.attributeContents.splice(item.attributeContents.indexOf(tag), 1)
    },
    showInput(item) {
      item.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput[0].focus()
      })
    },
    handleInputConfirm(item) {
      const inputValue = item.inputValue
      if (inputValue) {
        item.attributeContents.push(inputValue)
      }
      item.inputVisible = false
      item.inputValue = ''
    },
    addAttr() {
      this.isShow = false
    },
    selectGroup() {
      this.isGroup = false
    },
    groupChange() {
      this.isGroup = true
      this.groupAttr.push({})
    },
    addItem() {
      this.itemList.push(
        {
          id: '',
          name: '',
          description: '',
          isNeed: false,
          isMultiSelect: false,
          minNum: '',
          maxNum: '',
          isProductList: false,
          productList: []
        }
      )
    },
    deleteProduct(row) {
      console.log('sssssssssssssssssss', row)
      if (row.choiceId) {
        this.$confirm('此操作将删除该数据,是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log('-=-=-=-=-=-=-=-=-=', row)
          deleteOptionChoiceById(row.choiceId).then((res) => {
            if (res) {
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              })
              this.productList = this.productList.filter((t) => t.name !== row.name)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.productList = this.productList.filter((t) => t.name !== row.name)
      }
    },
    addProduct() {
      this.productList.push({
        url:
          'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1310532206,1045676857&fm=26&gp=0.jpg',
        priceList: [
          { price: '' }
        ]
      })
    },
    saveProduct() {
      this.isItemShow = false
    },
    saveItem() {
      this.isItemShow = false
    },
    cancelItem() {
      this.isItemShow = false
    },
    priceSetting(index) {
      this.isVisible = true
      this.productIndex = index
    },
    addPrice() {
      this.priceList.push({ isEdit: true })
      if (this.priceList.length > 1) {
        this.priceList[this.priceList.length - 2].isEdit = false
      }
      this.productList[this.productIndex].priceList.push({})
    },
    editPrice(index) {
      this.priceList[index].isEdit = !this.priceList[index].isEdit
    },
    selProduct(row, product) {
      if (this.productListByData != null && this.productListByData.length > 0) {
        for (var i = 0; i < this.productListByData.length; i++) {
          var obj = this.productListByData[i]
          if (product === obj.id) {
            row.url = getStaticSourcePath(obj.img)
            row.name = obj.name
            break
          }
        }
      }
    },
    handdle(row, event, column) {
      console.log('进入', row.id, event, column)
      this.isItemShow = true
      this.productList = row.productList
      this.itemRowIndex = row.id
    },
    deleteItem(row) {
      if (row.id) {
        console.log('row.id', row.id)
        this.$confirm('此操作将删除该数据,是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteOptionById(row.id).then((res) => {
            if (res) {
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              })
              console.log('name', row.name)
              this.itemList = this.itemList.filter((t) => t.name !== row.name)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        console.log('name', row.name)
        this.itemList = this.itemList.filter((t) => t.name !== row.name)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.card-box {
  border:1px solid #d4d4d4;
  margin: 0.5rem 0.5rem 0;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .option-row{
    background-color: red;
  }

</style>
