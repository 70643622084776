var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("iframe", {
      ref: "categoryIfream",
      staticClass: "iframe-class",
      attrs: { src: _vm.categoryIfreamSrc, height: _vm.getClientHeight() },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "old-container" },
      [
        _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-form",
                {
                  staticClass: "search-form-inline",
                  attrs: {
                    "label-suffix": ":",
                    "label-position": "left",
                    "hide-required-asterisk": "",
                    "label-width": "75px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "title-top" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "title-add" },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    disabled:
                                      _vm.checkPermission("category.add"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showCreate()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-row",
          { staticClass: "category-list-box" },
          [
            _c(
              "el-col",
              { staticStyle: { float: "right" }, attrs: { span: 8 } },
              [
                _c(
                  "el-input",
                  {
                    attrs: {
                      id: "search-input",
                      placeholder: _vm.$t("category.Quick-search-item-by-word"),
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-input__icon el-icon-search",
                      attrs: { slot: "suffix", id: "search-i" },
                      on: { click: _vm.CategoryItem },
                      slot: "suffix",
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              {
                staticClass: "category-list-relationship-box",
                attrs: { span: 16 },
              },
              _vm._l(
                _vm.categoryElementList,
                function (categoryElement, elementIndex) {
                  return _c(
                    "draggable",
                    _vm._b(
                      {
                        key: elementIndex,
                        staticClass: "list-group",
                        attrs: { move: _vm.onMove },
                        on: {
                          start: function ($event) {
                            _vm.isDragging = true
                          },
                          end: function ($event) {
                            _vm.isDragging = false
                          },
                          add: _vm.onAdd,
                        },
                        model: {
                          value: categoryElement.dataList,
                          callback: function ($$v) {
                            _vm.$set(categoryElement, "dataList", $$v)
                          },
                          expression: "categoryElement.dataList",
                        },
                      },
                      "draggable",
                      _vm.dragOptions,
                      false
                    ),
                    [
                      _c(
                        "transition-group",
                        { attrs: { type: "transition", name: "flip-list" } },
                        _vm._l(
                          categoryElement.dataList,
                          function (category, index) {
                            return _c(
                              "li",
                              {
                                key: category.itemId,
                                staticClass: "list-group-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAppend(
                                      elementIndex,
                                      category,
                                      index
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  class: category.fixed
                                    ? "el-icon-lock"
                                    : "el-icon-s-unfold",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "sequence" }, [
                                  _vm._v(_vm._s(index + 1)),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v(_vm._s(category.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      position: "relative",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "append",
                                        attrs: {
                                          size: "mini",
                                          disabled:
                                            _vm.checkPermission("category"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onAppend(
                                              elementIndex,
                                              category,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("COMPONENTS.unfold"))
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", {
                                      class: _vm.categoryElementList[
                                        elementIndex
                                      ].dataList[index].isTree
                                        ? "el-icon-right"
                                        : 1,
                                      staticStyle: { float: "right" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                }
              ),
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { staticClass: "category-stateless-box", attrs: { span: 8 } },
              [
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "list-group",
                      attrs: { move: _vm.onMove },
                      on: {
                        start: function ($event) {
                          _vm.isDragging = true
                        },
                        end: function ($event) {
                          _vm.isDragging = false
                        },
                      },
                      model: {
                        value: _vm.categoryNameAndCountList,
                        callback: function ($$v) {
                          _vm.categoryNameAndCountList = $$v
                        },
                        expression: "categoryNameAndCountList",
                      },
                    },
                    "draggable",
                    _vm.dragOptions,
                    false
                  ),
                  [
                    _c(
                      "transition-group",
                      { attrs: { type: "transition", name: "flip-list" } },
                      _vm._l(
                        _vm.categoryNameAndCountList,
                        function (category, index) {
                          return _c(
                            "li",
                            {
                              key: category.itemId,
                              staticClass: "list-group-item",
                            },
                            [
                              _c("i", {
                                class: category.fixed
                                  ? "el-icon-lock"
                                  : "el-icon-s-unfold",
                                attrs: { "aria-hidden": "true" },
                                on: {
                                  click: function ($event) {
                                    category.fixed = !category.fixed
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "sequence" }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(category.name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "name" }, [
                                _vm._v("(" + _vm._s(category.num) + ")"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { width: "180px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "append",
                                      attrs: {
                                        size: "mini",
                                        disabled:
                                          _vm.checkPermission("category.edit"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showEdit(category)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "append",
                                      attrs: {
                                        size: "mini",
                                        disabled:
                                          _vm.checkPermission("category.copy"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showCopy(category)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.copy")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.$t("category.item.title"),
              visible: _vm.dialogFormVisible,
              width: "70%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogFormVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("category.item.name") } },
                  [
                    _c("el-input", {
                      staticClass: "text-name",
                      model: {
                        value: _vm.map.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.map, "name", $$v)
                        },
                        expression: "map.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("category.item.mainImg"),
                              prop: "mainImgUrl",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  action: "#",
                                  "http-request": _vm.uploadMainImages,
                                  "file-list": _vm.map.mainBannerUrl,
                                  accept: ".jpg,.jpeg,.png,.gif",
                                  "show-file-list": false,
                                },
                              },
                              [
                                _vm.map.mainImgUrl
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: { src: _vm.map.mainImgUrl },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("category.item.product") } },
                  [
                    _c("el-row"),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c("el-transfer", {
                          staticStyle: {
                            "text-align": "left",
                            display: "inline-block",
                          },
                          attrs: {
                            filterable: "",
                            "filter-placeholder": _vm.$t("rule.productName"),
                            titles: [
                              _vm.$t("COMPONENTS.Not-choose"),
                              _vm.$t("COMPONENTS.Choosed"),
                            ],
                            format: {
                              noChecked: "${total}",
                              hasChecked: "${checked}/${total}",
                            },
                            data: _vm.goodsList,
                          },
                          model: {
                            value: _vm.map.parentId,
                            callback: function ($$v) {
                              _vm.$set(_vm.map, "parentId", $$v)
                            },
                            expression: "map.parentId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "60%",
                  title: _vm.$t("COMPONENTS.translate"),
                  "custom-class": "dialog-class",
                  visible: _vm.innerVisible,
                  "append-to-body": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.innerVisible = $event
                  },
                  opened: _vm.getIframe,
                },
              },
              [
                _c("iframe", {
                  ref: "iframe",
                  staticClass: "iframe-class",
                  attrs: { src: _vm.ifreamSrc, height: "100%" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisible = false
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.categoryRelation()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("COMPONENTS.sure")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }