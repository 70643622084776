<template>
  <div class="product-create">
    <product-form :title="$t('productItem.productItemCreate.label')" />
  </div>
</template>

<script>
import { ProductForm } from '../components'
export default {
  components: { ProductForm }
}
</script>

<style lang="scss" scoped>

</style>
