exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#5160e4",
	"subMenuActiveText": "#fff",
	"menuBg": "#4059b5",
	"menuHover": "#4059b5",
	"subMenuBg": "#4059b5",
	"subMenuHover": "#4059b5",
	"sideBarWidth": "268px"
};