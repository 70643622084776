import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'
import { API_BASE_URL_MEMBER } from '../Config'

export function searchList(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/query' + param,
    method: 'post',
    data: query
  })
}

export function queryAllProductName() {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/queryAllProductName',
    method: 'post'
  })
}

export function queryProductForPlanCreate(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/product/queryProductForPlanCreate',
    method: 'post',
    data: query
  })
}

export function categoryQuery() {
  return request({
    url: API_BASE_URL_PRODUCT + '/public/admin/menuCategoryRule',
    method: 'get'
  })
}

export function tagQuery() {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/tag/query',
    method: 'post'
  })
}

export function brandQuery() {
  return request({
    url: API_BASE_URL_PRODUCT + '/public/brand/listAllByMutilLang',
    method: 'post'
  })
}

export function shopList(query) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/findAllShop',
    method: 'get',
    data: query
  })
}

export function save(param) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/save',
    method: 'post',
    data: param
  })
}

export function deleteProduct(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/delete/' + id,
    method: 'delete'
  })
}

export function getProductInfo(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/getProductInfo',
    method: 'get',
    params: query
  })
}

export function getAttributes(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/findAll' + param,
    method: 'post',
    data: query
  })
}

export function saveAttributes(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/saveProductInfoAttribute',
    method: 'post',
    data: query
  })
}

export function getTemplate(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/getTemplate/' + id,
    method: 'get'
  })
}

export function getVariableSku(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/getVariableSku/' + id,
    method: 'get'
  })
}

export function getGroupTemplate(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/getGroupTemplate/' + id,
    method: 'get'
  })
}

export function productGroupList(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/group/list' + param,
    method: 'post',
    data: query
  })
}

export function getProductBySellType(type) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/shop/getProductBySellType/' + type,
    method: 'get'
  })
}

export function productUpdatedMediaLog(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/log/product/videolog',
    method: 'post',
    data: query
  })
}

export function publicProduct(baseProductId, version, type) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/publicProduct',
    method: 'post',
    data: { baseProductId, version, type }
  })
}

