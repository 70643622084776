var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-form",
              {
                staticClass: "search-form-inline",
                attrs: {
                  "label-suffix": ":",
                  "label-position": "left",
                  "hide-required-asterisk": "",
                  "label-width": "230px",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Admin Tag") } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.incontent"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.query.adminTag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "adminTag", $$v)
                                },
                                expression: "query.adminTag",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("plan.table.name") } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.incontent"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.query.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "name", $$v)
                                },
                                expression: "query.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.status") } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.query.itemStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "itemStatus", $$v)
                                  },
                                  expression: "query.itemStatus",
                                },
                              },
                              _vm._l(_vm.itemStatusList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Plan Type") } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                  clearable: "",
                                },
                                on: { change: _vm.onChangePlanType },
                                model: {
                                  value: _vm.query.planType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "planType", $$v)
                                  },
                                  expression: "query.planType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "FOR_MEMBER_BUY",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.FOR_MEMBER_BUY.des"
                                    ),
                                    value: "FOR_MEMBER_BUY",
                                    disabled: false,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "FOR_COUPON_USE",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.FOR_COUPON_USE.des"
                                    ),
                                    value: "FOR_COUPON_USE",
                                    disabled: false,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "FOR_ACTIVITY_USE",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.FOR_ACTIVITY_USE.des"
                                    ),
                                    value: "FOR_ACTIVITY_USE",
                                    disabled: false,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _vm.query.planType === "FOR_MEMBER_BUY"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("New Version Release Status"),
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "COMPONENTS.inselect"
                                      ),
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.query.publicStatus,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.query, "publicStatus", $$v)
                                      },
                                      expression: "query.publicStatus",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t("Published"),
                                        value: "HAS_PUBLISHED",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "Published (Already the latest version)"
                                        ),
                                        value: "LAST_VERSION_HAS_PUBLISHED",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "Published (There is a new version)"
                                        ),
                                        value: "LAST_VERSION_NOT_PUBLISHED",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t("Not yet published"),
                                        value: "NOT_YET_PUBLISHED",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 24, md: 24, sm: 24, xs: 24 } },
                      [
                        _c(
                          "div",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.checkPermission("plan.add"),
                                },
                                on: { click: _vm.handleCreate },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.checkPermission("plan"),
                                },
                                on: { click: _vm.searchBtnJob },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.search")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            "tooltip-effect": "dark",
            data: _vm.data,
            "header-row-class-name": "table-header",
            size: "mini",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Admin Tag"),
              prop: "adminTag",
              "min-width": "180px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("plan.table.name"),
              prop: "name",
              "min-width": "180px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Recipes Count"),
              prop: "planRecipesCount",
              "min-width": "120px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("New Version Release Status"),
              width: "190px",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticStyle: { "font-size": "10px" } }, [
                      row.publishStatus === "HAS_PUBLISHED"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("Published")))])
                        : _vm._e(),
                      _vm._v(" "),
                      row.publishStatus === "LAST_VERSION_HAS_PUBLISHED"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Published (Already the latest version)")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      row.publishStatus === "LAST_VERSION_NOT_PUBLISHED"
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Published (There is a new version)")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      row.publishStatus === "NOT_YET_PUBLISHED"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("Not yet published"))),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Plan Type"),
              "min-width": "150px",
              prop: "planType",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scop) {
                  return [
                    scop.row.planType === "FOR_MEMBER_BUY"
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("planType.FOR_MEMBER_BUY.des"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scop.row.planType === "FOR_COUPON_USE"
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("planType.FOR_COUPON_USE.des"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scop.row.planType === "FOR_ACTIVITY_USE"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("planType.FOR_ACTIVITY_USE.des"))
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.status"),
              prop: "itemStatus",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Plan Detail"),
              prop: "planDetail",
              width: _vm.planDetailColumnSize,
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.planDetail, function (item) {
                    return _c("div", { key: item }, [
                      item === "ALLOW_VIEW_SOME_RECIPE"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "10px",
                                "line-height": "0px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("planType.ALLOW_VIEW_SOME_RECIPE.des")
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item === "ALLOW_PLAN_TO_BUY"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "10px",
                                "line-height": "0px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("planType.ALLOW_PLAN_TO_BUY.des"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item === "ALLOW_PLAN_TO_EAT"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "10px",
                                "line-height": "0px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("planType.ALLOW_PLAN_TO_EAT.des"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item === "ALLOW_ADD_FAMILY"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "10px",
                                "line-height": "0px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("planType.ALLOW_ADD_FAMILY.des"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("br"),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Created By"),
              prop: "createdBy",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Last Modified By"),
              prop: "lastModifiedBy",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("COMPONENTS.operation"),
              fixed: "right",
              width: "300px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.itemStatus === "APPROVED"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm.checkPermission("plan.edit"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("COMPONENTS.view")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.itemStatus !== "APPROVED"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: _vm.checkPermission("plan.edit"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          disabled: _vm.checkPermission("plan.delete"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deletePlan(scope.$index, scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("COMPONENTS.delete")) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.query.total > 0,
                    expression: "query.total > 0",
                  },
                ],
                staticClass: "hidden-xs-only",
                attrs: {
                  total: _vm.query.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.loadData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                staticClass: "hidden-sm-and-up",
                attrs: {
                  "hide-on-single-pag": _vm.query.total > 0,
                  total: _vm.query.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  "pager-count": 7,
                  layout: "pager, prev, next",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.loadData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }