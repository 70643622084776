import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'

export function mgProductGoodsAdd(map) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/menuCategoryAdd',
    method: 'post',
    data: map
  })
}

export function mgProductGoodsAddIntoCfs(map) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/menuCategoryAddIntoCfs',
    method: 'post',
    data: map
  })
}

export function listParent(itemParentId) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/goodsId/' + itemParentId,
    method: 'get'
  })
}
