import request from '@/utils/request'
import { API_BASE_URL_MEMBER } from '../Config'

import { API_CFS_SERVICE_URL } from '../Config'

export function fetchLanguageList(query) {
  // return request({
  //   url: '/salesitem/api/admin/language',
  //   method: 'get'
  // })
  return request({
    url: API_CFS_SERVICE_URL + '/multipleLanguage/gallery/setting',
    method: 'post'
  })
}

export function fetchLableLanguageList(data){
  return request({
    url: API_CFS_SERVICE_URL + '/multipleLanguage/lableData/load',
    method: 'post',
    data: data
  })
}

export function saveLanguageList(data){
  return request({
    url: API_CFS_SERVICE_URL + '/multipleLanguage/dicDataLan/save',
    method: 'post',
    data: data
  })
}

export function findLanguageList(data){
  return request({
    url: API_CFS_SERVICE_URL + '/multipleLanguage/dicDataLan/search',
    method: 'post',
    data: data
  })
}

export function findLanguageListByKey(data) {
  return request({
    url: API_CFS_SERVICE_URL + '/multipleLanguage/dicDataLan/searchByKeyList',
    method: 'post',
    data: data
  })
}
