import request from '@/utils/request'

import { API_BASE_URL_PRODUCT } from '../Config'

export function productGroupList(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/group/list' + param,
    method: 'post',
    data: query
  })
}

export function saveAttrGroup(data) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/group/save',
    method: 'post',
    data: data
  })
}

export function deleteByGroupId(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/group/delete/' + id,
    method: 'delete'
  })
}

export function findByGroupId(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/findGroupId/' + id,
    method: 'get'
  })
}

export function saveCopy(data) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/groupCopy/save',
    method: 'post',
    data: data
  })
}
