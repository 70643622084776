var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 12 } },
                [_vm._v(_vm._s(_vm.$t("comment.commentEdit.label")))]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("comment.edit"),
                      },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "temp",
          staticClass: "search-form-inline",
          attrs: {
            model: _vm.review,
            "label-suffix": ":",
            "label-position": "left",
            "label-width": "200px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xl: 12 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Member Account"),
                                    prop: "memberName",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.review.memberName)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("productItem.shop"),
                                    prop: "shopName",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.review.shopName)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("review.productName"),
                                    prop: "productName",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.review.productName)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("review.rate"),
                                    prop: "reviewRate",
                                  },
                                },
                                [
                                  _c("el-rate", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.review.reviewRate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.review, "reviewRate", $$v)
                                      },
                                      expression: "review.reviewRate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("review.title"),
                                    prop: "reviewTitle",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.review.reviewTitle)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("review.content"),
                                    prop: "reviewContent",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.review.reviewContent)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("review.attachment"),
                                    prop: "attachment",
                                  },
                                },
                                _vm._l(
                                  _vm.review.fileList,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      item.type === "IMAGE"
                                        ? _c("img", {
                                            staticClass: "avatar",
                                            attrs: { src: item.url },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.type === "VIDEO"
                                        ? _c(
                                            "video",
                                            {
                                              staticClass: "avatar",
                                              attrs: {
                                                src: item.url,
                                                controls: "controls",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    您的浏览器不支持视频播放\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("productItem.status"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.review.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.review, "status", $$v)
                                        },
                                        expression: "review.status",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-option",
                                        { attrs: { label: "", value: "" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("COMPONENTS.inselect")
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(_vm.statusList, function (status) {
                                        return _c("el-option", {
                                          key: status.value,
                                          attrs: {
                                            label: status.label,
                                            value: status.value,
                                            disabled: status.disabled,
                                          },
                                        })
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }