import request from '@/utils/request'

import { API_BASE_URL_PRODUCT } from '../Config'

export function deleteOptionById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/option/delete/' + id,
    method: 'delete'
  })
}

export function deleteOptionChoiceById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/option/deleteByChoiceId/' + id,
    method: 'delete'
  })
}
