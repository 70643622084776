import request from '@/utils/request'

import { API_CFS_SERVICE_URL } from '../../Config'
export function getCfsTreeList(data) { // 快的
  return request({
    url: API_CFS_SERVICE_URL + '/auth/service/tree/treeStructure',
    method: 'post',
    data: data
  })
}

export function saveNewTreeNode(data) {
  return request({
    url: API_CFS_SERVICE_URL + '/auth/service/treeItem/saveTreeItem',
    method: 'post',
    data: data
  })
}

export function getCfsTreeNodeList(data) {
  return request({
    url: API_CFS_SERVICE_URL + '/auth/service/treeItem/search',
    method: 'post',
    data: data
  })
}

