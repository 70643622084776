<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <el-form
        class="search-form-inline"
        label-suffix=":"
        label-position="left"
        hide-required-asterisk
        label-width="158px"
      >

        <el-row :gutter="20">

          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('Admin Tag')">
              <el-input v-model="query.adminTag" style="width: 100%" :placeholder="$t('COMPONENTS.incontent')" clearable />
            </el-form-item>
          </el-col>

          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('familyProgram.name')">
              <el-input
                v-model="query.name"
                style="max-width: 210px"
                clearable
                class="filter-item"
              />
            </el-form-item>
          </el-col>

          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('productItem.status')">
              <el-select
                v-model="query.itemStatus"
                :placeholder="$t('COMPONENTS.inselect')"
              >
                <el-option label="" value="">{{
                  $t("COMPONENTS.inselect")
                }}</el-option>
                <el-option
                  v-for="item in itemStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row style="margin: 5px;">

          <div style="float: right;">
            <el-button
              :disabled="checkPermission('FAMILY.ADD')"
              @click="handleCreate"
            >{{ $t("COMPONENTS.add") }}
            </el-button>
            <el-button
              :disabled="checkPermission('FAMILY.SEARCH')"
              type="primary"
              @click="search"
            >{{ $t("COMPONENTS.search") }}
            </el-button>
          </div>

        </el-row>
      </el-form>
    </transition>

    <el-table
      tooltip-effect="dark"
      :data="data"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
    >
      <el-table-column
        :label="$t('Schedule')"
        prop="schedule"
        width="190"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.schedule === 'WAIT_APPROVED'" type="danger">{{ $t('WAIT_APPROVED.label') }}</el-tag>
          <el-tag v-if="scope.row.schedule === 'NON_START'" type="info">{{ $t('NON_START.label') }}</el-tag>
          <el-tag v-if="scope.row.schedule === 'GOING'" type="success">{{ $t('GOING.label') }}</el-tag>
          <el-tag v-if="scope.row.schedule === 'FINISHED'" type="info">{{ $t('FINISHED.label') }}</el-tag>
        </template>

      </el-table-column>
      <el-table-column :label="$t('Admin Tag')" prop="adminTag" min-width="180px" width="220px" align="left" />
      <el-table-column
        :label="$t('familyProgram.name')"
        prop="name"
        width="250px"
        align="left"
      />

      <el-table-column :label="$t('COMPONENTS.time')" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.effectiveDate !== null">{{
            scope.row.effectiveDate
          }}</span>
          <span
            v-if="scope.row.invalidDate !== null"
          >&nbsp;&nbsp;~<br>{{ scope.row.invalidDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('productItem.lastModifiedDate')"
        prop="lastModifiedDate"
        align="left"
      />

      <el-table-column
        :label="$t('productItem.status')"
        prop="itemStatus"
        width="150px"
        align="left"
      />

      <el-table-column
        :label="$t('COMPONENTS.operation')"
        fixed="right"
        width="280px"
      >
        <template slot-scope="scope">

          <el-button
            :disabled="checkPermission('FAMILY.ADD')"
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t("COMPONENTS.edit") }}
          </el-button>
          <el-button
            :disabled="checkPermission('FAMILY.DELETE')"
            size="mini"
            @click="handleDelete(scope.$index, scope.row)"
          >{{ $t("COMPONENTS.delete") }}
          </el-button>

          <el-button
            v-if="scope.row.schedule === 'GOING'"
            :disabled="checkPermission('FAMILY.DELETE')"
            size="mini"
            @click="stopSold(scope.row)"
          >{{ $t("COMPONENTS.endEarly") }}
          </el-button>

        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="query.page"
      :limit.sync="query.size"
      @pagination="search"
    />

  </div>
</template>

<script>
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import { listFamilyPlan, adminDeleteFamilyPlan, adminStopFamilyPlan, formatDate } from '@/api/familyApi'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { getLanguage } from '@/lang/index'
import 'el-select-tree/lib/element-ui'
export default {
  name: 'Ingredient',
  components: { Pagination },
  directives: { waves },
  filters: {},
  data() {
    return {
      data: [],
      query: {
        adminTag: null,
        name: '',
        itemStatus: 'APPROVED',
        size: 10,
        page: 1,
        total: 100,
        sort: 'lastModifiedDate,desc'
      },
      itemStatusList: [],
      ingredientDailyMenuTreeData: [],
      ingredientProductTreeData: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadData() {
      this.getStatus()
      this.search()
    },
    getStatus() {
      getCfsDomainData({ domainCode: 'SHOP_PLAN_STATUS' }).then(res => {
        const allPlanStatus = res.data
        this.itemStatusList = []
        allPlanStatus.map((data, i) => {
          this.itemStatusList.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')',
            disabled: checkCfsPermission('SHOP_PLAN_STATUS.' + data.domainDataCode)
          })
        })
        console.log('拿Plan的狀態>', allPlanStatus)
      })
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    handleCreate() {
      this.$router.push({ path: '/spread/familyEdit', query: { planId: null }})
    },
    handleEdit(index, row) {
      console.log('id======>', row.id)
      this.$router.push({ path: '/spread/familyEdit', query: { planId: row.id }})
    },
    search() {
      const query = { ...this.query }
      const param = getPageParm(query)

      this.data = []
      this.total = 0

      listFamilyPlan(param, query).then(resp => {
        console.log('resp==>', resp)
        const list = resp.data

        if (list !== null && list.length !== 0) {
          list.map(item => {
            item.schedule = this.checkSchedule(item)

            if (item.effectiveDate !== null) {
              item.effectiveDate = formatDate(new Date(item.effectiveDate))
            }
            if (item.invalidDate !== null) {
              item.invalidDate = formatDate(new Date(item.invalidDate))
            }
            item.lastModifiedDate = formatDate(new Date(item.lastModifiedDate))
          })
        }

        this.data = list
        this.total = resp.pageCount
      })
    },
    handleDelete(index, row) {
      this.confirmJob(this.$t('FAMILY_PAGE.delete.msg'), () => { // 你確定要刪除嗎?如果沒有被顧客下單，才允許刪除
        adminDeleteFamilyPlan({ id: row.id }).then((response) => {
          if (response === 'SUCCESS') {
            this.search()
            this.successMsg(this.$t('FAMILY_PAGE.delete.success.msg')) // 刪除成功
          } else if (response === 'ORDER_BEEN_USED') {
            this.errorMsg(this.$t('FAMILY_PAGE.delete.error.inUsed.msg')) // 家庭成員已經被購買，禁止刪除
          } else {
            this.errorMsg('錯誤')
          }
        })
      })
    },
    errorMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.error'),
        message: message,
        type: 'error'
      })
    },
    successMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.success'),
        message: message,
        type: 'success'
      })
    },
    confirmJob(msg, job) {
      this.$confirm(msg, this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        job()
      })
    },
    checkSchedule(row) {
      console.log('checkSchedule', row)

      let result = null
      if (!this.isNull(row.id)) {
        const now = new Date()
        const startPublicDate = new Date(row.effectiveDate)
        const endPublicDate = new Date(row.invalidDate)

        if (row.itemStatus === 'APPROVED') {
          if (now < startPublicDate) {
            result = 'NON_START' // 活動未開始
          } else if (startPublicDate < now && now < endPublicDate) {
            result = 'GOING' // 活動進行中
          } else if (endPublicDate < now) {
            result = 'FINISHED' // 活動已結束
          }
        } else {
          result = 'WAIT_APPROVED' // 等待批准
        }
      }
      return result
    },
    stopSold(row) {
      console.log('gatfcwergxaferw', row)
      // 只有APPROVED且在運行中的活動
      // 才能夠提早結束
      if (row.schedule === 'GOING' && row.itemStatus === 'APPROVED') {
        this.confirmJob(this.$t('FAMILY_PAGE.stopActivity.confirm.msg'), () => { // 你確定要提早結束活動嗎
          adminStopFamilyPlan(row.id).then((response) => {
            if (response === 'SUCCESS') {
              this.search()
              this.successMsg(this.$t('FAMILY_PAGE.stopActivity.success.msg')) // 活動已經提早結束
            } else {
              this.errorMsg(this.$t('FAMILY_PAGE.stopActivity.error.msg')) // 不允許提早結束
            }
          })
        })
      } else {
        this.errorMsg(this.$t('FAMILY_PAGE.stopActivity.error.msg')) // 不允許提早結束
      }
    },
    isNull(data) {
      return data === null || data === '' || data === undefined
    }
  }
}
</script>
