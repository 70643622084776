import request from '@/utils/request'

import { API_SERVER_URL } from '../Config'

export function searchArticleList(quary) {
  return request({
    url: API_SERVER_URL + '/public/api/admin/article/search',
    method: 'post',
    data: quary
  })
}

export function saveArticle(data) {
  return request({
    url: API_SERVER_URL + '/public/api/admin/article/save',
    method: 'post',
    data: data
  })
}

export function deleteArticleById(id) {
  return request({
    url: API_SERVER_URL + '/public/api/admin/deleteById/' + id,
    method: 'get'
  })
}

export function findArticleById(id) {
  return request({
    url: API_SERVER_URL + '/public/api/admin/article/findById/' + id,
    method: 'get'
  })
}
