<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="230px"
        >
          <el-row :gutter="20">
            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('Admin Tag')">
                <el-input v-model="query.adminTag" style="width: 100%" :placeholder="$t('COMPONENTS.incontent')" clearable />
              </el-form-item>
            </el-col>
            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('plan.table.name')">
                <el-input v-model="query.name" style="width: 100%" :placeholder="$t('COMPONENTS.incontent')" clearable />
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.status')">
                <el-select
                  v-model="query.itemStatus"
                  :placeholder="$t('COMPONENTS.inselect')"
                  clearable
                >
                  <el-option
                    v-for="item in itemStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>

            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$t('Plan Type')">
                <el-select
                  v-model="query.planType"
                  :placeholder="$t('COMPONENTS.inselect')"
                  clearable
                  @change="onChangePlanType"
                >

                  <!-- 開放讓會員購買 -->
                  <el-option key="FOR_MEMBER_BUY" :label="$t('planType.FOR_MEMBER_BUY.des')" value="FOR_MEMBER_BUY" :disabled="false" />
                  <!-- 僅供優惠券設定，會員無法直接購買 -->
                  <el-option key="FOR_COUPON_USE" :label="$t('planType.FOR_COUPON_USE.des')" value="FOR_COUPON_USE" :disabled="false" />
                  <!-- 僅供活動設定，會員無法直接購買 -->
                  <el-option key="FOR_ACTIVITY_USE" :label="$t('planType.FOR_ACTIVITY_USE.des')" value="FOR_ACTIVITY_USE" :disabled="false" />

                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="12" :sm="12" :xs="24">
              <el-form-item v-if="query.planType === 'FOR_MEMBER_BUY'" :label="$t('New Version Release Status')">
                <el-select
                  v-model="query.publicStatus"
                  :placeholder="$t('COMPONENTS.inselect')"
                  clearable
                >
                  <el-option :label="$t('Published')" value="HAS_PUBLISHED" />
                  <el-option :label="$t('Published (Already the latest version)')" value="LAST_VERSION_HAS_PUBLISHED" />
                  <el-option :label="$t('Published (There is a new version)')" value="LAST_VERSION_NOT_PUBLISHED" />
                  <el-option :label="$t('Not yet published')" value="NOT_YET_PUBLISHED" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xl="24" :md="24" :sm="24" :xs="24">
              <div style="float: right;">
                <el-button :disabled="checkPermission('plan.add')" @click="handleCreate">{{ $t('COMPONENTS.add') }}</el-button>
                <el-button type="primary" :disabled="checkPermission('plan')" @click="searchBtnJob">{{ $t('COMPONENTS.search') }}</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </transition>

    <el-table
      tooltip-effect="dark"
      :data="data"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
    >
      <!-- <el-table-column type="selection" align="center" /> -->
      <el-table-column :label="$t('Admin Tag')" prop="adminTag" min-width="180px" align="left" />
      <el-table-column :label="$t('plan.table.name')" prop="name" min-width="180px" align="left" />
      <el-table-column :label="$t('Recipes Count')" prop="planRecipesCount" min-width="120px" align="left" />

      <el-table-column :label="$t('New Version Release Status')" width="190px" align="left">
        <template slot-scope="{ row }">
          <span style="font-size: 10px;">
            <span v-if="row.publishStatus === 'HAS_PUBLISHED'">{{ $t('Published') }}</span>
            <span v-if="row.publishStatus === 'LAST_VERSION_HAS_PUBLISHED'">{{ $t('Published (Already the latest version)') }}</span>
            <span v-if="row.publishStatus === 'LAST_VERSION_NOT_PUBLISHED'" style="color: red;">{{ $t('Published (There is a new version)') }}</span>
            <span v-if="row.publishStatus === 'NOT_YET_PUBLISHED'">{{ $t('Not yet published') }}</span>
          </span>
        </template>
      </el-table-column>
      <!--

      <el-table-column :label="$t('Release Date')" prop="effectiveDate" width="180px" align="left">
        <template slot-scope="scop">
          {{ scop.row.dateText }}
        </template>
      </el-table-column>

       -->

      <el-table-column :label="$t('Plan Type')" min-width="150px" prop="planType" align="left">
        <template slot-scope="scop">
          <span v-if="scop.row.planType === 'FOR_MEMBER_BUY'">{{ $t('planType.FOR_MEMBER_BUY.des') }}</span><!-- 開放讓會員購買 -->
          <span v-if="scop.row.planType === 'FOR_COUPON_USE'">{{ $t('planType.FOR_COUPON_USE.des') }}</span><!-- 僅供優惠券設定，會員無法直接購買 -->
          <span v-if="scop.row.planType === 'FOR_ACTIVITY_USE'">{{ $t('planType.FOR_ACTIVITY_USE.des') }}</span><!-- 僅供活動設定，會員無法直接購買 -->

        </template>
      </el-table-column>

      <el-table-column
        :label="$t('productItem.status')"
        prop="itemStatus"
        width="150px"
        align="left"
      />

      <el-table-column :label="$t('Plan Detail')" prop="planDetail" :width="planDetailColumnSize" align="left">
        <template slot-scope="scope">
          <div v-for="item in scope.row.planDetail" :key="item">
            <span v-if="item === 'ALLOW_VIEW_SOME_RECIPE'" style="font-size: 10px; line-height: 0px;">{{ $t('planType.ALLOW_VIEW_SOME_RECIPE.des') }}</span>
            <span v-if="item === 'ALLOW_PLAN_TO_BUY'" style="font-size: 10px; line-height: 0px;">{{ $t('planType.ALLOW_PLAN_TO_BUY.des') }}</span>
            <span v-if="item === 'ALLOW_PLAN_TO_EAT'" style="font-size: 10px; line-height: 0px;">{{ $t('planType.ALLOW_PLAN_TO_EAT.des') }}</span>
            <span v-if="item === 'ALLOW_ADD_FAMILY'" style="font-size: 10px; line-height: 0px;">{{ $t('planType.ALLOW_ADD_FAMILY.des') }}</span>
            <br>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Created By')" prop="createdBy" width="150px" align="left" />
      <el-table-column :label="$t('Last Modified By')" prop="lastModifiedBy" width="150px" align="left" />
      <!-- <el-table-column label="ServiceCard" prop="ServiceCard" width="150px" align="left" /> -->
      <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" width="300px">
        <template slot-scope="scope">

          <el-button
            v-if="scope.row.itemStatus === 'APPROVED'"
            size="mini"
            :disabled="checkPermission('plan.edit')"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.view') }}</el-button>

          <el-button
            v-if="scope.row.itemStatus !== 'APPROVED'"
            size="mini"
            type="primary"
            :disabled="checkPermission('plan.edit')"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t("COMPONENTS.edit") }}</el-button>

          <!-- 下架 -->
          <!--
          <el-button
            v-if="!scope.row.removedFromShelf "
            size="mini"
            :disabled="checkPermission('plan.delete') || scope.row.planType !== 'FOR_MEMBER_BUY'"
            @click="stopSold(scope.$index, scope.row)"
          >{{ $t('Discontinue') }} </el-button>
-->

          <!-- 已下架 -->
          <!--
          <el-button
            v-if="scope.row.removedFromShelf"
            :disabled="true"
            size="mini"
          >{{ $t('COMPONENTS.Removed') }}
        </el-button>
        -->

          <el-button size="mini" :disabled="checkPermission('plan.delete')" @click="deletePlan(scope.$index, scope.row)">{{ $t('COMPONENTS.delete') }} </el-button>

        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-col>
        <pagination
          v-show="query.total > 0"
          class="hidden-xs-only"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="query.total > 0"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="7"
          layout="pager, prev, next"
          @pagination="loadData"
        />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import {
  findAllPlanList,
  // stopSoldById,
  deletePlanById
} from '@/api/planApi'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { getLanguage } from '@/lang/index'
// import { DB_DATE_FORMAT } from '@/Config'
import moment from 'moment'
export default {
  name: 'Plan',
  components: { Pagination },
  directives: { waves },
  filters: {},
  data() {
    return {
      planDetailColumnSize: '270px',
      data: [],
      query: {
        adminTag: null,
        name: '',
        size: 10,
        page: 1,
        total: 100,
        sellState: 'onsale',
        itemStatus: null,
        publicStatus: null,
        planType: null
      },
      itemStatusList: []
    }
  },
  mounted() {
    this.checkLan()
    this.loadData()
    this.getStatus()
  },
  methods: {
    onChangePlanType(planType) {
      if (planType !== 'FOR_MEMBER_BUY') {
        this.query.publicStatus = ''
      }
    },
    getStatus() {
      getCfsDomainData({ domainCode: 'SHOP_PLAN_STATUS' }).then(res => {
        const allPlanStatus = res.data
        this.itemStatusList = []
        allPlanStatus.map((data, i) => {
          this.itemStatusList.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')',
            disabled: checkCfsPermission('SHOP_PLAN_STATUS.' + data.domainDataCode)
          })
        })
        console.log('拿Plan的狀態>', allPlanStatus)
      })
    },
    checkLan() {
      const languageCode = getLanguage()
      if (languageCode === 'zh-HK') {
        this.planDetailColumnSize = '140px'
      } else if (languageCode === 'en-US') {
        this.planDetailColumnSize = '190px'
      }
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    isNull(data) {
      return data === null || data === '' || data === undefined || data === 'undefined'
    },
    formatDate(date) {
      if (!this.isNull(date)) {
        return moment(date).format('YYYY-MM-DD')
      }
    },
    searchBtnJob() {
      this.query.page = 1
      this.loadData()
    },
    loadData() {
      const query = { ...this.query }

      //  廚師在後台只能查自己建立的計畫
      //  CFS那邊有兩個權限
      //  SEARCH_ALL_PLAN
      //  SEARCH_OWN_PLAN

      query.createdBy = 'NA'
      // 只能搜尋自建立的計畫
      const searchOwn = !checkCfsPermission('SEARCH_OWN_PLAN')
      if (searchOwn) {
        const loginName = localStorage.getItem('user_profile')
        query.createdBy = loginName
      }

      // 可以搜尋所有人的計畫
      const searchAll = !checkCfsPermission('SEARCH_ALL_PLAN')
      if (searchAll) {
        query.createdBy = null
      }
      var param = getPageParm(query)
      this.loadAllPlan(param, query)
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadAllPlan(param, quary) {
      findAllPlanList(param, quary).then((resp) => {
        console.log('findAllPlanList param==>', param)
        let data = resp.data
        if (data && data.length > 0) {
          data.map(item => {
            if (!this.isNull(item.effectiveDate) && !this.isNull(item.invalidDate)) {
              item.dateText = this.formatDate(item.effectiveDate) + '  ~  ' + this.formatDate(item.invalidDate)
            }
          })
        } else {
          data = []
        }
        this.data = data

        this.query.total = resp.pageCount
        this.query.page = resp.pageNum
        this.query.size = resp.pageSize
      })
    },

    handleEdit(index, row) {
      console.log('handleEdit======>', row)
      this.$router.push({ path: 'plan/edit', query: { baseProductId: row.baseProductId }})
    },

    handleCreate() {
      this.$router.push({ path: 'plan/create', query: {}})
    },

    // stopSold(index, row) {
    //   this.$confirm(this.$t('Admin.Plan.msg.STOP_SALLING'), this.$t('COMPONENTS.tip'), {
    //     confirmButtonText: this.$t('COMPONENTS.sure'),
    //     cancelButtonText: this.$t('COMPONENTS.cancel'),
    //     type: 'warning'
    //   }).then(() => {
    //     stopSoldById(row.baseProductId).then(resp => {
    //       console.log(resp)
    //       if (resp) {
    //         this.$notify({
    //           title: this.$t('COMPONENTS.success'),
    //           message: this.$t('Admin.Plan.msg.STOP_SALE.SUCCESS'),
    //           type: 'success'
    //         })
    //         this.loadData()
    //         // this.$router.back(-1);
    //       } else {
    //         this.$notify({
    //           title: this.$t('COMPONENTS.tip'),
    //           message: this.$t('Admin.Plan.msg.ORDER_BEEN_USED'),
    //           type: 'warning'
    //         })
    //         return
    //       }
    //     })
    //     console.log('delect success')
    //   })
    // },

    deletePlan(index, row) {
      this.$confirm(this.$t('Admin.Plan.msg.DELETE.SURE'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        deletePlanById(row.baseProductId).then(res => {
          // 已經有訂單使用此數據，禁止刪除
          if (res === 'ORDER_BEEN_USED') {
            this.$notify({
              title: this.$t('COMPONENTS.error'),
              message: this.$t('Admin.Plan.msg.ORDER_BEEN_USED'),
              type: 'error'
            })
          }
          // 操作失敗
          if (res === 'ERROR') {
            this.$notify({
              title: this.$t('COMPONENTS.error'),
              message: this.$t('COMPONENTS.error'),
              type: 'success'
            })
          }
          // 刪除成功
          if (res === 'SUCCESS') {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('Admin.Plan.msg.DELETE.SUCCESS'),
              type: 'success'
            })
            this.searchBtnJob()
          }
        })
        console.log('delect success')
      })
    }

  }
}
</script>
