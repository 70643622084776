var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "match-goods-tabs" },
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "left" } },
        [
          [
            _c(
              "el-tab-pane",
              { attrs: { label: _vm.$t("productItem.basic_price") } },
              [
                _c(
                  "el-form",
                  {
                    ref: "mixForm",
                    attrs: { model: _vm.mixForm, rules: _vm.rules },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("productItem.retailPrice"),
                                  prop: "retailPrice",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.mixForm.retailPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.mixForm,
                                          "retailPrice",
                                          $$v
                                        )
                                      },
                                      expression: "mixForm.retailPrice",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("HK$"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("productItem.promotionPrice"),
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.mixForm.promotionPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.mixForm,
                                          "promotionPrice",
                                          $$v
                                        )
                                      },
                                      expression: "mixForm.promotionPrice",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("HK$"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("productItem.kcNum"),
                                  prop: "kcNum",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.mixForm.kcNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.mixForm, "kcNum", $$v)
                                    },
                                    expression: "mixForm.kcNum",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: _vm.$t("productItem.attribute"),
                  disabled: _vm.checkPermission("SHOP_PRODUCT_ATTRIBUTE"),
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { "margin-left": "0.5rem" },
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.selProductAttribute },
                            model: {
                              value: _vm.productAttribute,
                              callback: function ($$v) {
                                _vm.productAttribute = $$v
                              },
                              expression: "productAttribute",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("productItem.custom-attribute"),
                                value: "自定义属性",
                              },
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.attributesGroup, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.addCard } }, [
                          _vm._v(_vm._s(_vm.$t("COMPONENTS.add"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            on: { click: _vm.saveAttribute },
                          },
                          [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.customAttribute,
                        expression: "customAttribute",
                      },
                    ],
                    attrs: { gutter: 20 },
                  },
                  [
                    _c(
                      "el-col",
                      _vm._l(_vm.list, function (item, index) {
                        return _c(
                          "el-card",
                          {
                            key: index,
                            staticClass: "card-box",
                            style: item.styleObject,
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", [
                                  _c("i", {
                                    staticClass: "el-icon-close",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-left": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCard(item.id)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-minus",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.showUp,
                                        expression: "item.showUp",
                                      },
                                    ],
                                    staticClass: "el-icon-arrow-up",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-right": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showRow(item)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.showDown,
                                        expression: "item.showDown",
                                      },
                                    ],
                                    staticClass: "el-icon-arrow-down",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-right": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hideRow(item)
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isShow,
                                    expression: "item.isShow",
                                  },
                                ],
                                attrs: { gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  { staticStyle: { "padding-top": "20px" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#606266",
                                              "font-weight": "700",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "productItem.attributeTitle"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-autocomplete", {
                                          ref: "attrs_demo",
                                          refInFor: true,
                                          attrs: {
                                            "fetch-suggestions":
                                              _vm.querySearchAsync,
                                            placeholder: _vm.$t(
                                              "COMPONENTS.incontent"
                                            ),
                                          },
                                          on: {
                                            select: function ($event) {
                                              return _vm.handleSelect(
                                                item.attrTitle,
                                                item
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.attrTitle,
                                            callback: function ($$v) {
                                              _vm.$set(item, "attrTitle", $$v)
                                            },
                                            expression: "item.attrTitle",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-top": "15px",
                                            },
                                            attrs: {
                                              label: _vm.$t(
                                                "productItem.isRequired"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value: item.isRequired,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isRequired",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.isRequired",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "0" } },
                                                  [_vm._v("No")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "1" } },
                                                  [_vm._v("Yes")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-top": "15px",
                                            },
                                            attrs: {
                                              label: _vm.$t(
                                                "productItem.isMultple"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value: item.isMultple,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isMultple",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.isMultple",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "0" } },
                                                  [_vm._v("No")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "1" } },
                                                  [_vm._v("Yes")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            color: "#606266",
                                            "font-weight": "700",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("productItem.attribute")
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            border: "1px solid #d4d4d4",
                                            height: "140px",
                                            width: "580px",
                                            padding: "10px",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            item.attributeContents,
                                            function (tag) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: tag,
                                                  attrs: {
                                                    closable: "",
                                                    "disable-transitions": false,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.handleClose(
                                                        item,
                                                        tag
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(tag) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          item.inputVisible
                                            ? _c("el-input", {
                                                ref: "saveTagInput",
                                                refInFor: true,
                                                staticClass: "input-new-tag",
                                                attrs: { size: "small" },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.handleInputConfirm(
                                                      item
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.handleInputConfirm(
                                                      item
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.inputValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "inputValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.inputValue",
                                                },
                                              })
                                            : _c(
                                                "el-button",
                                                {
                                                  staticClass: "button-new-tag",
                                                  attrs: { size: "small" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showInput(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v("+ New Tag")]
                                              ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: _vm.$t("productItem.Match-ITEM") } },
              [
                _c(
                  "el-card",
                  { staticClass: "card-box" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "card-title", attrs: { span: 24 } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("productItem.product_mix_and_match_list")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-button", { on: { click: _vm.addItem } }, [
                          _vm._v(
                            _vm._s(_vm.$t("productItem.add_mix_match_item"))
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.itemList,
                              "highlight-current-row": true,
                            },
                            on: { "row-click": _vm.handdle },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: _vm.$t("productItem.name") },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        model: {
                                          value: scope.row.name,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "name", $$v)
                                          },
                                          expression: "scope.row.name",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("productItem.isRequired"),
                                prop: "isNeed",
                                width: "150px",
                                align: "left",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#808080",
                                        },
                                        model: {
                                          value: scope.row.isNeed,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "isNeed", $$v)
                                          },
                                          expression: "scope.row.isNeed",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("productItem.desc"),
                                width: "300px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        model: {
                                          value: scope.row.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.description",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("productItem.isMultple"),
                                prop: "isMultiSelect",
                                width: "150px",
                                align: "left",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#808080",
                                        },
                                        model: {
                                          value: scope.row.isMultiSelect,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "isMultiSelect",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.isMultiSelect",
                                        },
                                      }),
                                      _vm._v(" "),
                                      scope.row.isMultiSelect
                                        ? _c(
                                            "el-col",
                                            [
                                              _c(
                                                "el-row",
                                                { attrs: { gutter: 20 } },
                                                [
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "productItem.min_select"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.minNum,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "minNum",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.minNum",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                { attrs: { gutter: 20 } },
                                                [
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "productItem.max_select"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.maxNum,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "maxNum",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.maxNum",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: _vm.$t("productItem.operation") },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("COMPONENTS.delete"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isItemShow
                  ? _c(
                      "el-card",
                      { staticClass: "card-box" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "card-title",
                                attrs: { span: 24 },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("productItem.choose_product_list")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-button", { on: { click: _vm.addProduct } }, [
                              _vm._v(_vm._s(_vm.$t("productItem.Add_Goods"))),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-table",
                              { attrs: { data: _vm.productList } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("productItem.picture"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-image", {
                                              attrs: {
                                                src: _vm.getStaticSourcePath(
                                                  scope.row.url
                                                ),
                                                fit: "scale-down",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1374220534
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("productItem.product"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "COMPONENTS.inselect"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.selProduct(
                                                      scope.row,
                                                      scope.row.product
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.product,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "product",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.product",
                                                },
                                              },
                                              _vm._l(
                                                _vm.productListByData,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    577653142
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t(
                                      "productItem.price_adjustment"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "COMPONENTS.inselect"
                                                  ),
                                                },
                                                model: {
                                                  value: scope.row.priceType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "priceType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.priceType",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "productItem.fixed_price"
                                                    ),
                                                    value: 1,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "productItem.floating_price"
                                                    ),
                                                    value: 2,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "productItem.product_original_price"
                                                    ),
                                                    value: 3,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "productItem.no_pricing"
                                                    ),
                                                    value: 4,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    785487539
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: _vm.$t("productItem.price") },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.priceType == 1
                                              ? _c("el-input", {
                                                  attrs: {
                                                    value:
                                                      scope.row.priceList[0]
                                                        .price,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.priceList[0]
                                                        .price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row.priceList[0],
                                                        "price",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.priceList[0].price",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.priceType == 2
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.priceSetting(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "productItem.setup"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.priceType == 3
                                              ? _c("span", [_vm._v("-")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.priceType == 4
                                              ? _c("span", [_vm._v("0")])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    227081023
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t(
                                      "productItem.adjust_quantity"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-checkbox", {
                                              model: {
                                                value: scope.row.numSelect,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "numSelect",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.numSelect",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    851224818
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("productItem.select"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-checkbox", {
                                              model: {
                                                value: scope.row.isSelect,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "isSelect",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.isSelect",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4270841726
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("productItem.operation"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteProduct(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("COMPONENTS.delete")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3638453915
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: { title: "添加", visible: _vm.isVisible, width: "60%" },
                on: {
                  "update:visible": function ($event) {
                    _vm.isVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-table",
                  { attrs: { data: _vm.priceList } },
                  [
                    _c("el-table-column", {
                      attrs: { label: "数量区间", width: "400" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.isEdit
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { sm: 8, xs: 16 } },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { size: "mini" },
                                              model: {
                                                value:
                                                  _vm.productList[
                                                    _vm.productIndex
                                                  ].priceList[scope.$index]
                                                    .minNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productList[
                                                      _vm.productIndex
                                                    ].priceList[scope.$index],
                                                    "minNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                      productList[productIndex].priceList[scope.$index].minNum\n                    ",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                {
                                                  staticStyle: {
                                                    width: "30px",
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v("qty")]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { sm: 1, xs: 1 } },
                                        [_vm._v("~")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { sm: 8, xs: 16 } },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { size: "mini" },
                                              model: {
                                                value:
                                                  _vm.productList[
                                                    _vm.productIndex
                                                  ].priceList[scope.$index]
                                                    .maxNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productList[
                                                      _vm.productIndex
                                                    ].priceList[scope.$index],
                                                    "maxNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                      productList[productIndex].priceList[scope.$index].maxNum\n                    ",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                {
                                                  staticStyle: {
                                                    width: "30px",
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v("qty")]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c("el-row", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.productList[_vm.productIndex]
                                          .priceList[scope.$index].minNum
                                      ) +
                                        "qty~" +
                                        _vm._s(
                                          _vm.productList[_vm.productIndex]
                                            .priceList[scope.$index].maxNum
                                        ) +
                                        "qty\n              "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "价格", width: "150px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.isEdit
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { sm: 24, xs: 12 } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              size: "mini",
                                            },
                                            model: {
                                              value:
                                                _vm.productList[
                                                  _vm.productIndex
                                                ].priceList[scope.$index].price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productList[
                                                    _vm.productIndex
                                                  ].priceList[scope.$index],
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                      productList[productIndex].priceList[scope.$index].price\n                    ",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c("el-row", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.productList[_vm.productIndex]
                                          .priceList[scope.$index].price
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "动作", width: "150px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 12, xs: 12 } },
                                    [
                                      !scope.row.isEdit
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editPrice(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 12, xs: 12 } },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { size: "mini" } },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.addPrice } },
                      [_vm._v("增加")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }