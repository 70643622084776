import request from '@/utils/request'
import { API_SERVER_URL } from '../Config'

export function adminFindIngredient(param, query) {
  if (!param) {
    param = ''
  }
  return request({
    url: API_SERVER_URL + '/api/admin/ingredient/search' + param,
    method: 'post',
    data: query
  })
}

export function adminDeleteIngredient(ingredientId) {
  return request({
    url: API_SERVER_URL + '/api/admin/ingredient/delete/' + ingredientId,
    method: 'post'
  })
}

export function adminGetIngredientById(id) {
  return request({
    url: API_SERVER_URL + '/api/admin/ingredient/search/' + id,
    method: 'get'
  })
}

export function saveOrUpdateIngredient(data) {
  return request({
    url: API_SERVER_URL + '/api/ingredient/saveOrUpdate',
    method: 'post',
    data: data
  })
}

export function findAllIngredient() {
  return request({
    url: API_SERVER_URL + '/api/admin/ingredient/searchAll',
    method: 'post'
  })
}
