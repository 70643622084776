var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { "label-width": "100px", rules: _vm.rules, model: _vm.ruleForm },
    },
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 12 } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.ruleForm.id == null
                        ? _vm.$t("COMPONENTS.add")
                        : _vm.$t("COMPONENTS.edit")
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm(_vm.ruleForm)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("notice.edit"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.release")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("COMPONENTS.title"), prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("COMPONENTS.content"), prop: "content" } },
            [
              _c(
                "div",
                { staticStyle: { width: "80%" } },
                [
                  _c("Summernote", {
                    attrs: { value: _vm.ruleForm.content, height: "150" },
                    on: { change: _vm.changeContentInfo },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }