import request from '@/utils/request'
import { API_BASE_URL_MEMBER, API_SERVER_URL } from '../Config'

export function findAllList(query) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/memberToShop/comment/queryAll',
    method: 'get',
    data: query
  })
}
export function getAllCommentList(param, query) {
  return request({
    url: API_SERVER_URL + '/api/admin/product/findAllReviews' + param,
    method: 'post',
    data: query
  })
}

export function findCommentByShopName(shopNmae) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/memberToShop/comment/findCommentByShopName/' + shopNmae,
    method: 'get'
  })
}
export function findAllShopName(query) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/memberToShop/comment/findAllshopName',
    method: 'get',
    data: query
  })
}

export function findById(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/memberToShop/comment/findById/' + id,
    method: 'get'
  })
}

export function addComment(comment) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/memberToShop/comment/addComment',
    method: 'post',
    data: comment
  })
}

export function updateComment(comment) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/memberToShop/comment/updateComment',
    method: 'post',
    data: comment
  })
}

export function deleteById(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/memberToShop/comment/deleteById/' + id,
    method: 'delete',
    data: id
  })
}

export function updateDataStatus(params) {
  return request({
    url: API_SERVER_URL + '/api/product/updateReviewsStatus',
    method: 'post',
    data: params
  })
}

