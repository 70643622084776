<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">{{ $t('shopPic.addShopPic.label') }}</el-col>
        <el-col :span="12">
          <el-button @click="$router.back(-1)">{{ $t('COMPONENTS.return') }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission('shopPicture.add')"
            @click="handleSave"
          >{{ $t('COMPONENTS.save') }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form :model="shopPic" label-width="80px">
      <el-row>
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">

              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('shopPic.imageName')" prop="name">
                  <el-input v-model="shopPic.picName" style="width: 200px" :placeholder="$t('shopPic.Please-enter-imageName')" clearable />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">

              <el-col :xl="12">
                <el-card class="card-box">
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('shopPic.select-image')">
                        <el-upload
                          action="#"
                          :http-request="uploadMainImages"
                          accept=".jpg,.jpeg,.png,.gif"
                          class="avatar-uploader"
                          :show-file-list="false"
                          :before-upload="beforeFileUpload"
                        >
                          <img v-if="mainImgUrl" :src="mainImgUrl" class="avatar" alt="">
                          <em v-else class="el-icon-plus avatar-uploader-icon" />
                        </el-upload>
                      </el-form-item>

                    </el-col>
                  </el-row>
                </el-card>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { submitUpload } from '@/api/shopPicApi'
import { imgUpload } from '@/api/uploadApi'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { checkCfsPermission } from '@/utils/permission'
import { fileSizeConfig } from '@/api/mallorderApi/systemConfigApi'
import { getFileType, parseStrToInt } from '@/utils'

export default {
  name: 'ShopPic',
  components: { Sticky },
  data() {
    return {

      fileList: [],
      mainImgUrl: '',
      mainBannerUrl: [],
      edit: true,
      rowCount: 1,
      shopPic: {
        picName: ''

      },
      fileImageSize: null
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const imgConfig = await fileSizeConfig('IMAGE')
      if (imgConfig) {
        const itemValue = imgConfig.itemValue
        this.fileImageSize = parseStrToInt(itemValue)
      }
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    handleSave() {
      console.log('+++' + this.mainBannerUrl[0])
      var shopPic = {
        'name': this.shopPic.picName,
        'urlPath': this.mainBannerUrl[0].url
      }
      submitUpload(shopPic).then((resp) => {
        if (resp.data) {
          this.$message.success(this.$t('COMPONENTS.save_Success'))
        } else {
          this.$message.error(this.$t('COMPONENTS.save_Filad'))
        }
        this.$router.back(-1)
      })
    },
    beforeFileUpload(file) {
      const fileType = getFileType(file)
      if (fileType !== 'IMAGE') {
        this.$message.error(this.$t('file.formatErrMsg'))
        return false
      }
      const size = file.size
      const defaultSize = this.fileImageSize
      if (defaultSize && defaultSize > 0) {
        const filterSize = defaultSize * 1024
        if (size > filterSize) {
          const msg = this.$t('file.sizeLimitMsg') + ': ' + defaultSize + 'KB'
          this.$message.error(msg)
          return false
        }
      }
      return true
    },
    uploadMainImages(file) {
      var newFile = file.file
      console.log('=======' + newFile)
      var formData = new FormData()
      formData.append('files', newFile)
      imgUpload(formData).then((res) => {
        console.log('文件返回对象---' + res)
        this.mainBannerUrl.push({ name: res[0].url, url: getStaticSourcePath(res[0].url) })
        this.mainImgUrl = getStaticSourcePath(res[0].url)
      })
    }

  }
}
</script>

<style lang="scss">

.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}

.el-upload-list__item.is-ready {
  display: none;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
  .avatar {
  width: 148px;
  height: 148px;
  display: block;
}

#product-form {
  padding-bottom: 0.5rem;
}
.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}
.card-box {
  margin: 0.5rem 0.5rem 0;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}
</style>
