import request from '@/utils/request'

import { API_BASE_URL_MEMBER, API_SERVER_URL } from '../Config'

export function shopList() {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/findAllShop',
    method: 'get'
  })
}

export function searchShopList(param, quary) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/searchShopList' + param,
    method: 'post',
    data: quary
  })
}

export function shopSave(shop) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/shop/save',
    method: 'post',
    data: shop
  })
}

export function findShopInfoById(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/shop/findById/' + id,
    method: 'get'
  })
}

export function delGroupDtl(group, id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/shop/delGourpDtl/' + group + '/' + id,
    method: 'delete'
  })
}

export function delShop(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/shop/delShopById/' + id,
    method: 'delete'
  })
}

export function findMainStore() {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/shop/findMainStore',
    method: 'get'
  })
}

export function shopSaveConfig(param) {
  return request({
    url: API_SERVER_URL + '/api/admin/shop/config',
    method: 'post',
    data: param
  })
}

export function findShopConfig(param) {
  return request({
    url: API_SERVER_URL + '/api/admin/shop/findConfig',
    method: 'post',
    data: param
  })
}
