<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col v-if="familyProgram.id" :span="10" class="page-header">{{ $t("COMPONENTS.edit") }}</el-col>
        <el-col v-else :span="10" class="page-header">{{ $t('COMPONENTS.add') }}</el-col>
        <el-col :span="14">

          <el-button @click="back()">{{
            $t("COMPONENTS.return")
          }}</el-button>
          <el-button v-if="oldStatus === 'APPROVED' && schedule === 'GOING'" :disabled="checkPermission('FAMILY.ADD')" @click="stopSold()">{{ $t("COMPONENTS.endEarly") }}</el-button>        <!-- 提早結束 -->
          <el-button :disabled="!familyProgram || !familyProgram.id" @click="setting()">{{
            $t("COMPONENTS.translate")
          }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission('FAMILY.ADD')"
            @click="submitBtn()"
          >{{ $t("COMPONENTS.save") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-card>
      <el-row>
        <el-col>
          <el-form ref="temp" :model="familyProgram" label-width="180px">

            <el-form-item prop="name" :label="$t('Admin Tag')">
              <el-input
                v-model="familyProgram.adminTag"
                :value="familyProgram.adminTag"
                style="width: 300px"
              />
              <el-tooltip class="item" effect="dark" :content="$t('Admin.adminTag.tooltip')" placement="top">
                <i class="el-icon-warning" />
              </el-tooltip>
            </el-form-item>

            <el-form-item prop="name" :label="$t('Admin Remark')">
              <el-input
                v-model="familyProgram.adminRemark"
                :value="familyProgram.adminRemark"
                style="width: 300px"
              />
              <el-tooltip class="item" effect="dark" :content="$t('Admin.adminRemark.tooltip')" placement="top">
                <i class="el-icon-warning" />
              </el-tooltip>

            </el-form-item>

            <el-form-item prop="name" :label="$t('familyProgram.name')">
              <el-input
                v-model="familyProgram.name"
                :value="familyProgram.name"
                style="width: 38%"
              />
            </el-form-item>

            <el-form-item :label="$t('COMPONENTS.time')" required>
              <el-row>

                <el-col :span="24">
                  <el-form-item prop="dateRange">
                    <el-date-picker
                      v-model="familyProgram.date"
                      type="datetimerange"
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                      format="yyyy-MM-dd HH:mm:ss"
                      :disabled="oldStatus === 'APPROVED'"
                      :default-time="defaultTime"
                    />
                    <el-tooltip placement="top">
                      <template #content>

                        {{ $t('ACTIVITY_PAGE.NOTE.0') }}<br>
                        {{ $t('ACTIVITY_PAGE.NOTE.1') }}<br>
                        {{ $t('ACTIVITY_PAGE.NOTE.2') }}<br>
                        {{ $t('ACTIVITY_PAGE.NOTE.3') }}<br>
                        {{ $t('ACTIVITY_PAGE.NOTE.4') }}<br>
                        <!-- 注意<br/>
                        1.活動結束時間不可小於今日<br/>
                        2.活動啟用後，起始日期不可以改，只能提早結束<br/>
                        3.活動結束後，不能再重複使用，但是可以再另外建立同樣的活動<br/>
                        4.提早結束活動時，活動結束時間會改當下的時間<br/> -->
                      </template>
                      <i class="el-icon-warning" />
                    </el-tooltip>
                  </el-form-item>
                </el-col>

              </el-row>
            </el-form-item>

            <el-form-item :label="$t('productItem.status')">
              <el-select
                v-model="familyProgram.itemStatus"
                :placeholder="$t('COMPONENTS.inselect')"
                style="width: 408px"
                :disabled="oldStatus === 'APPROVED'"
              >
                <el-option
                  v-for="item in itemStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="loaded"
              :label="$t('plan.description')"
              prop="description"
            >
              <Summernote :value="familyProgram.description" @change="changeDescription" />
            </el-form-item>

            <el-form-item :label="$t('familyProgram.card.table')">
              <el-row>
                <el-col :span="24" class="page-header" />
              </el-row>
              <el-table
                tooltip-effect="dark"
                :data="familyProgram.cards"
                style="width: 60vw"
                header-row-class-name="table-header"
                size="mini"
              >
                <el-table-column :label="$t('Apple Product ID')" prop="appleProductId" width="200px" align="left" />
                <el-table-column :label="$t('familyProgram.card.num')" prop="num" align="left" />
                <el-table-column :label="$t('familyProgram.card.price')" prop="price" align="left" />
                <el-table-column :label="$t('familyProgram.card.amount')" prop="amount" align="left" />
                <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" width="180px">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      :disabled="oldStatus === 'APPROVED'"
                      @click="handleEdit(scope.$index, scope.row)"
                    >{{ $t('COMPONENTS.edit') }}</el-button>
                    <el-button
                      v-if="scope.$index === 0 || scope.$index === (familyProgram.cards.length -1)"
                      :style="planConfigStyle"
                      size="mini"
                      :disabled="oldStatus === 'APPROVED'"
                      @click="handleDelete(scope.$index, scope.row)"
                    >{{ $t('COMPONENTS.delete') }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <p />
              <el-button :disabled="oldStatus === 'APPROVED'" type="primary" style="width: 60vw" @click="addCard()">{{ $t('COMPONENTS.add') }}</el-button>
              <el-dialog
                custom-class="dialog-class"
                :title="$t('plan.card.title')"
                :visible.sync="dialogVisible"
                width="50%"
              >
                <!-- :before-close="handleClose" -->
                <el-form ref="temp" :model="card" label-width="180px">
                  <el-form-item prop="appleProductId" :label="$t('Apple Product ID')">
                    <el-input
                      v-model="card.appleProductId"
                      style="width: 100px"
                    />
                  </el-form-item>
                  <p />
                  <el-form-item :label="$t('familyProgram.card.num')">
                    <el-input
                      v-model="card.num"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"
                      onafterpaste="this.value=this.value.replace(/\D/g,'')"
                      style="width: 200px"
                      :disabled="!(card.sequence === 1 || card.sequence === familyProgram.cards.length || !card.sequence)"
                      type="number"
                      :min="0"
                    />
                  </el-form-item>
                  <p />

                  <el-form-item :label="$t('familyProgram.card.price')" prop="price">
                    <el-input
                      v-model="card.price"
                      on-key-press="if((event.keyCode<48 || event.keyCode>57) && event.keyCode!=46 || /\.\d\d$/.test(value))event.returnValue=false"
                      style="width: 200px"
                      clearable
                      type="number"
                      :min="0"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"
                      onafterpaste="this.value=this.value.replace(/\D/g,'')"
                    >
                      <template slot="prepend">HK$</template>
                    </el-input>
                  </el-form-item>
                  <p />

                  <el-form-item prop="amount" :label="$t('familyProgram.card.amount')">
                    <el-input
                      v-model="card.amount"
                      on-key-press="if((event.keyCode<48 || event.keyCode>57) && event.keyCode!=46 || /\.\d\d$/.test(value))event.returnValue=false"
                      clearable
                      style="width: 200px"
                      type="number"
                      :min="0"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"
                      onafterpaste="this.value=this.value.replace(/\D/g,'')"
                    >
                      <template slot="prepend">HK$</template>
                    </el-input>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">{{ $t("COMPONENTS.return") }}</el-button>
                  <el-button type="primary" @click="addNewCard()">{{ $t("COMPONENTS.save") }}</el-button>
                </span>
              </el-dialog>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div style="height:200px" />
    </el-card>
    <el-drawer
      class="translate-drawer"
      :title="$t('COMPONENTS.translate')"
      :visible.sync="drawer"
      size="60%"
      :show-close="false"
      :modal-append-to-body="false"
      :append-to-body="false"
      destroy-on-close
      @opened="getIframe"
    >
      <iframe
        v-if="drawer"
        ref="iframe"
        :src="ifreamSrc"
        class="iframe-class"
        :height="getClientHeight()"
      />
    </el-drawer>
  </div>
</template>

<script>
import Sticky from '@/components/Sticky'
import { findFamilyPlan, saveFamilyPlan, formatDate, adminStopFamilyPlan } from '@/api/familyApi'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getLanguage } from '../../lang'
import { API_CFS_SERVER_URL } from '@/Config'
import { shopList, findShopConfig } from '../../api/shop'
import { checkCfsPermission } from '@/utils/permission'
import Summernote from '@/components/Summernote'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'

export default {
  name: 'PlanEdit',
  components: { Sticky, Summernote },
  props: {
    attrId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loaded: false,
      schedule: null,
      oldStatus: null,
      defaultTime: ['00:00:00', '23:59:59'],
      itemStatusList: [],
      ifreamSrc: '',
      drawer: false,
      planConfigStyle: 'display:default',
      rowCount: 0,
      familyProgram: {
        isFamilyCard: true,
        cards: [],
        effectiveDate: null,
        invalidDate: null,
        itemStatus: 'NEW',
        description: undefined
      },
      productList: [],
      selectList: [],
      dialogVisible: false,
      cardPrice: {},
      card: {
        times: 0,
        cardName: '',
        price: undefined,
        amount: undefined,
        cardPrice: [
          {
            amount: 0,
            price: 0,
            edit: false
          }
        ]
      },
      rules: {
        cardName: [{ required: true, message: this.$t('rule.attributeName-cannot-be-empty'), trigger: 'blur' }],
        times: [{ required: true, message: this.$t('attributeType-cannot-be-empty'), trigger: 'change' }],
        value: [{ required: true, message: this.$t('attributeValue-cannot-be-empty'), trigger: 'blur' }],
        price: [{ required: true, message: this.$t('attributeValue-cannot-be-empty'), trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    handleMessage(e) {
      console.log(e.data)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleDrawerClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleDrawerClose()
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleDrawerClose() {
      this.drawer = false
    },
    changeDescription(value) {
      this.familyProgram.description = value
    },
    getIframe(e) {
      var defaultLang = getLanguage()
      var systemLanguageCodes = JSON.parse(localStorage.getItem('SYSTEM_LANGUAGE_CODES'))
      if (systemLanguageCodes && systemLanguageCodes.data && systemLanguageCodes.data.defaultLanguage) {
        defaultLang = systemLanguageCodes.data.defaultLanguage
      }
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      this.ifreamSrc =
        ifreamSrc +
        '?system=gallery&module=admin&function=productItem&isIframe=true&language=' +
        defaultLang + '&sytemLanguage=' + getLanguage() + '&no=' + Math.ceil(Math.random() * 100)
      var data = [
        { name: this.$t('familyProgram.name'), key: this.familyProgram.id + '.mul.productItem.productName', type: 'input', value: this.familyProgram.name },
        { name: this.$t('plan.description'), key: this.familyProgram.id + '.planDescription', type: 'summernote', value: this.familyProgram.description }
      ]
      console.log('iframe 对象', this.$refs.iframe)
      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
      console.log('iframe 对象', this.$refs.iframe)
    },
    setting() {
      if (this.width > 0) {
        return
      }
      this.drawer = true
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadData() {
      this.getFamilyData()
      this.getStatus()
      this.getShopConfig()
    },
    getStatus() {
      getCfsDomainData({ domainCode: 'SHOP_PLAN_STATUS' }).then(res => {
        const allPlanStatus = res.data
        this.itemStatusList = []
        allPlanStatus.map((data, i) => {
          this.itemStatusList.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')',
            disabled: checkCfsPermission('SHOP_PLAN_STATUS.' + data.domainDataCode)
          })
        })
        console.log('拿Plan的狀態>', allPlanStatus)
      })
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    getShopConfig() {
      shopList().then((res) => {
        var shopId = ''
        if (res && res.data && res.data[0]) {
          shopId = res.data[0].id
          var arr = []
          if (shopId) { arr.push({ parentId: shopId, functionCode: 'plan', item: 'plan' }) }
          findShopConfig(arr).then((res) => {
            if (res.length > 0 && res[0]) {
              var shopConfigValue = res[0].itemValue
              var domainJson = JSON.parse(res[0].domainJson)
              console.log('domainJson', domainJson)
              if (shopConfigValue === 'Y') {
                this.planConfigStyle = 'display:block'
              } else {
                this.shopConfigValue = true
              }
            }
          })
        }
      })
    },
    addCard() {
      this.dialogVisible = true
      this.card = {}
      this.card.cardPrice = []
    },
    handleEdit(index, row) {
      var newCard = {
        id: row.id,
        cardName: row.cardName,
        num: row.cardName,
        days: row.days,
        amount: row.amount,
        price: row.price,
        cardPriceId: row.cardPrice[0].id,
        index: index,
        sequence: row.sequence
      }
      this.card = newCard
      this.dialogVisible = true
    },
    handleDelete(index, row) {
      this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        var cards = [...this.familyProgram.cards]
        var newCards = []
        if (cards.length > 0) {
          if (cards.length === 1) {
            this.familyProgram.cards = []
          } else {
            for (var i = 0; i < cards.length; i++) {
              if (i !== index) {
                newCards.push(cards[i])
              }
            }
          }
        }
        this.familyProgram.cards = newCards.sort((a, b) => { return Number(a.num) - Number(b.num) }).map((item, index) => { item.sequence = index + 1; return item })
      })
    },
    checkCardData(data) {
      const check = false
      console.log('checkCardData>>>>>', data)

      if (!data.appleProductId) {
        this.$message({
          type: 'error',
          message: this.$t('蘋果In-App-Purchase的產品ID不能為空') // 名称不能為空
        })
        return true
      }

      if (!data.num || parseInt(data.num) === 0) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.num.empty') // 人數不能為空或为0
        })
        return true
      }
      if (!data.price || parseInt(data.price) === 0) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.price.empty')
        })
        return true
      }
      if (parseInt(data.amount) === 0) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.amount.zero') // 優惠價格不能為0
        })
        return true
      }
      if (!this.isNull(data.amount) && !this.isNull(data.price) && Number(data.amount) >= Number(data.price)) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.amount.lessthan.price') // 優惠售價應該更便宜
        })
        return true
      }
      return check
    },
    addNewCard() {
      const check = this.checkCardData(this.card)
      if (check) {
        return
      }
      const existCards = [...this.familyProgram.cards]
      let cannotAdd = false
      if (existCards.length > 0 && this.card.sequence && this.card.sequence === existCards[0].sequence) {
        // 1.修改第一個數據，num 只能保持不變或者大於最後一個 +1
        if (Number(existCards[0].num) !== Number(this.card.num) && Number(this.card.num) !== Number(existCards[existCards.length - 1].num) + 1) {
          cannotAdd = true
        }
      } else if (existCards.length > 0 && this.card.sequence && this.card.sequence === existCards[existCards.length - 1].sequence) {
        // 2.修改最後一個數據，num 只能保持或者小於第一個 -1
        if (Number(existCards[0].num) - 1 !== Number(this.card.num) && Number(this.card.num) !== Number(existCards[existCards.length - 1].num)) {
          cannotAdd = true
        }
      } else if (existCards.length > 0 && !this.card.sequence) {
        // 3.新增數據，只能小於第一個或者大於最後一個，+1 or -1
        if ((Number(existCards[existCards.length - 1].num) + 1 !== Number(this.card.num)) && (Number(existCards[0].num) - 1 !== Number(this.card.num))) {
          cannotAdd = true
        }
      }
      if (cannotAdd) {
        this.$message({
          type: 'error',
          message: this.$t('familyProgram.rule.card.numwarm')
        })
        return
      }
      this.checkCardsIsExist(this.card, this.familyProgram.cards)
      const newCards = [...this.familyProgram.cards]
      var newCard = {
        num: Math.abs(this.card.num),
        cardName: this.card.num,
        id: this.card.id,
        cardPrice: [],
        times: -1,
        price: Math.abs(this.card.price),
        amount: this.card.amount ? Math.abs(this.card.amount) : '',
        days: 100000,
        sequence: this.card.sequence
      }
      newCard.cardPrice.push({
        id: this.card.cardPriceId,
        price: Math.abs(this.card.price),
        amount: this.card.amount ? Math.abs(this.card.amount) : ''
      })
      newCards.push(newCard)
      this.familyProgram.cards = newCards.sort((a, b) => { return Number(a.num) - Number(b.num) }).map((item, index) => { item.sequence = index + 1; return item })
      this.dialogVisible = false
    },
    checkCardsIsExist(card, cards) {
      var newTab = []
      if (cards.length > 0) {
        for (const el of cards) {
          if (el.sequence !== card.sequence) {
            newTab.push(el)
          }
        }
        this.familyProgram.cards = newTab
      }
    },
    handleClose(done) {
      this.$confirm(this.$t('COMPONENTS.close.tip'))
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    getFamilyData() {
      var planId = this.$route.query.planId

      if (planId !== null) {
        findFamilyPlan(planId).then((resp) => {
          const currentData = resp.data
          console.log('findFamilyPlan currentData', currentData)

          if (currentData.id) {
            const cards = []
            const newData = {
              adminTag: currentData.adminTag,
              adminRemark: currentData.adminRemark,
              id: currentData.id,
              name: currentData.name,
              description: currentData.description,
              goodsSkuIds: currentData.goodsSkuIds || [],
              publish: currentData.publish,
              itemStatus: currentData.itemStatus,
              effectiveDate: currentData.effectiveDate,
              invalidDate: currentData.invalidDate
            }
            currentData.cards &&
            currentData.cards.length > 0 &&
            currentData.cards.forEach((item, index) => {
              item.num = item.cardName
              item.price = item.cardPrice[0].price
              item.amount = item.cardPrice[0].amount
              cards.push(item)
            })
            newData.cards = cards.sort((a, b) => { return Number(a.num) - Number(b.num) }).map((item, index) => { item.sequence = index + 1; return item })

            newData.date = []
            if (!this.isNull(currentData.effectiveDate) && !this.isNull(currentData.invalidDate)) {
              newData.date.push(formatDate(new Date(currentData.effectiveDate)))
              newData.date.push(formatDate(new Date(currentData.invalidDate)))
            }
            newData.lastModifiedDate = formatDate(new Date(newData.lastModifiedDate))

            this.familyProgram = newData

            console.log('findFamilyPlan familyProgram', newData)
            this.oldStatus = currentData.itemStatus

            this.schedule = this.checkSchedule()
          }
          this.loaded = true
        })
      } else {
        this.familyProgram.description = null
        this.loaded = true
      }
    },
    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    submitBtn() {
      const isOk = this.checkData()
      if (!isOk) {
        return
      }

      const saveData = this.familyProgram
      saveData.goodsSkuIds = null

      if (saveData.date[0]) {
        saveData.effectiveDate = new Date(saveData.date[0])
      }
      if (saveData.date[1]) {
        saveData.invalidDate = new Date(saveData.date[1])
      }

      saveFamilyPlan(saveData).then((resp) => {
        if (resp && resp.data && resp.data.resCode === 'DUPLICATE') {
          this.$notify({
            title: this.$t('COMPONENTS.tip'),
            message: this.$t('dupicate plan'),
            type: 'error'
          })
          return
        }
        if (resp && resp.data && resp.data.resCode === 'ADMIN_TAG_DUPLICATE') {
          this.$notify({
            title: this.$t('COMPONENTS.tip'),
            message: this.$t('Admin Tag is already used'),
            type: 'error'
          })
          return
        }
        if (resp && resp.data && resp.data.resCode === 'DATE_DUPLICATE') {
          this.$notify({
            title: this.$t('COMPONENTS.tip'),
            message: this.$t('日期與其他活動有重複'),
            type: 'error'
          })
          return
        }
        if (resp && resp.data && resp.data.resCode === 'SUCCESS') {
          this.$notify({
            title: this.$t('COMPONENTS.success'),
            message: this.$t('COMPONENTS.save_Success'),
            type: 'success'
          })
          this.back()
        } else {
          this.$notify({
            title: this.$t('COMPONENTS.tip'),
            message: this.$t('save plan error'),
            type: 'warning'
          })
          return
        }
      })
    },
    checkData() {
      const data = this.familyProgram

      if (!data.name) {
        this.errorMsg(this.$t('rule.plan.name'))
        return false
      }

      const noNeedCheck = this.oldStatus !== 'APPROVED'
      if (noNeedCheck) {
        if (this.isNull(data.date) || data.date.length !== 2) {
          this.errorMsg(this.$t('Date cannot be empty'))
          return false
        }

        const startPublicDate = new Date(data.date[0])
        const endPublicDate = new Date(data.date[1])
        const now = new Date()
        // 注意
        // 1.活動結束時間不可小於今日
        if (endPublicDate < now) {
          this.errorMsg(this.$t('FAMILY_PAGE.check.1')) // 活動結束時間不可小於今日
          return false
        }

        if (+endPublicDate === +startPublicDate) {
          this.errorMsg(this.$t('FAMILY_PAGE.check.2')) // 活動時間錯誤，不可以為同一個時間
          return false
        }

        // 2.活動一旦啟用，起始日期不可以改，只能修改結束日期
        if (this.oldStatus === 'APPROVED' && this.data.effectiveDate !== startPublicDate) {
          this.errorMsg(this.$t('FAMILY_PAGE.check.3')) // 活動一旦啟用，日期不可以改，只能提早結束
          return false
        }
      }

      if (!data.cards || data.cards.length === 0) {
        this.errorMsg(this.$t('rule.plan.cards'))
        return false
      }
      return true
    },
    filterMethod(query, item) {
      return item.pinyin.indexOf(query) > -1
    },
    back() {
      const pathObj = {
        path: '/spread/family'
      }
      this.$router.push(pathObj)
    },
    checkSchedule() {
      const row = this.familyProgram
      let result = null
      if (!this.isNull(row.id)) {
        const now = new Date()
        const startPublicDate = new Date(row.effectiveDate)
        const endPublicDate = new Date(row.invalidDate)

        if (this.oldStatus === 'APPROVED') {
          if (now < startPublicDate) {
            result = 'NON_START' // 活動未開始
          } else if (startPublicDate < now && now < endPublicDate) {
            result = 'GOING' // 活動進行中
          } else if (endPublicDate < now) {
            result = 'FINISHED' // 活動已結束
          }
        } else {
          result = 'WAIT_APPROVED' // 等待批准
        }
      }
      console.log('scdfgdcfg', result)
      return result
    },
    stopSold() {
      console.log('sdfgsdfg')
      const id = this.familyProgram.id
      // 只有APPROVED且在運行中的活動
      // 才能夠提早結束
      if (this.schedule === 'GOING' && this.oldStatus === 'APPROVED') {
        this.confirmJob(this.$t('FAMILY_PAGE.stopActivity.confirm.msg'), () => { // 你確定要提早結束活動嗎
          adminStopFamilyPlan(id).then((response) => {
            if (response === 'SUCCESS') {
              this.getFamilyData()
              this.successMsg(this.$t('FAMILY_PAGE.stopActivity.success.msg')) // 活動已經提早結束
            } else {
              this.errorMsg(this.$t('FAMILY_PAGE.stopActivity.error.msg')) // 不允許提早結束
            }
          })
        })
      } else {
        this.errorMsg(this.$t('FAMILY_PAGE.stopActivity.error.msg')) // 不允許提早結束
      }
    },
    errorMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.error'),
        message: message,
        type: 'error'
      })
    },
    successMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.success'),
        message: message,
        type: 'success'
      })
    },
    confirmJob(msg, job) {
      this.$confirm(msg, this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        job()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.card-box {
  border: 1px solid #d4d4d4;
  margin: 0.5rem 0.5rem 0;
  height: 350px;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}

.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.component {
  margin: 10px;
}
.tree-box {
  margin: 15px 0 100px 55px;
  border: #a6e0d8 solid 2px;
  padding: 15px;
}
.form-item-head {
  margin-bottom: 8px;
  font-size: 12px;
}
.hide .el-upload--picture-card {
  display: none;
}
.iframe-class {
  width: 100%;
  border: 0px;
}
/deep/ .el-drawer .el-drawer__header{
  background-color: #2ea6f1 !important;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
/deep/ .el-drawer .el-drawer__header :first-child{
  margin-bottom: 18px;
}
/deep/ .el-drawer .el-drawer__body{
  margin-top: -22px
}
</style>
