import request from '@/utils/request'
import { API_BASE_URL_MEMBER } from '../Config'

export function apiGetCountrys() {
  return request({
    url: API_BASE_URL_MEMBER + '/public/countrys',
    method: 'get'
  })
}

export function apiGetStateOrProvince(countryId, locTypeId) {
  return request({
    url: API_BASE_URL_MEMBER + `/public/country/locType/${countryId}/${locTypeId}`,
    method: 'get'
  })
}

// export function apiGetStateOrProvinceChildren(countryId, parentId) {
//   return request({
//     url: API_BASE_URL_MEMBER + `/public/country/${countryId}/${parentId}`,
//     method: 'get'
//   })
// }

export function getNextOneLocation(countryId, parentId) {
  return request({
    url: API_BASE_URL_MEMBER + `/public/country/nextOneLocation/${countryId}/${parentId}`,
    method: 'get'
  })
}
