var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _vm.disabledTranslateBtn
                ? _c(
                    "el-col",
                    { staticClass: "page-header", attrs: { span: 10 } },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                  )
                : _c(
                    "el-col",
                    { staticClass: "page-header", attrs: { span: 10 } },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                  ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.setting()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.translate")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  !!_vm.plan.id &&
                  _vm.plan.queryVersion !== _vm.plan.publicVersion &&
                  _vm.plan.planType === "FOR_MEMBER_BUY"
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.publicBtn("UP")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Publish")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.plan.id &&
                  _vm.plan.queryVersion === _vm.plan.publicVersion &&
                  _vm.plan.planType === "FOR_MEMBER_BUY"
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.publicBtn("DOWN")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Discontinue")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("plan.add"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitBtn()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")) + "\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    {
                      ref: "temp",
                      attrs: { model: _vm.plan, "label-width": "180px" },
                    },
                    [
                      _vm.pageType !== "CREATE"
                        ? _c(
                            "el-row",
                            [
                              _c("el-form-item", [
                                _c(
                                  "span",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [_vm._v(_vm._s(_vm.$t("Basic ID")))]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.plan.baseProductId) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-form-item", [
                                _c(
                                  "span",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [_vm._v(_vm._s(_vm.$t("Version ID")))]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.plan.id) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Select Version") } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "408px" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                        clearable: "",
                                      },
                                      on: { change: _vm.onChangeVersion },
                                      model: {
                                        value: _vm.plan.queryVersion,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.plan,
                                            "queryVersion",
                                            $$v
                                          )
                                        },
                                        expression: "plan.queryVersion",
                                      },
                                    },
                                    _vm._l(
                                      _vm.plan.productVersionHistory,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item,
                                          attrs: {
                                            label:
                                              item +
                                              (_vm.plan.publicVersion === item
                                                ? "  (" +
                                                  _vm.$t("Publish") +
                                                  ")"
                                                : ""),
                                            value: item,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(_vm._s(_vm.$t("Admin Tag"))),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { value: _vm.plan.adminTag },
                            model: {
                              value: _vm.plan.adminTag,
                              callback: function ($$v) {
                                _vm.$set(_vm.plan, "adminTag", $$v)
                              },
                              expression: "plan.adminTag",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("Admin.adminTag.tooltip"),
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-warning" })]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "adminRemark",
                            label: _vm.$t("Admin Remark"),
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { value: _vm.plan.adminRemark },
                            model: {
                              value: _vm.plan.adminRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.plan, "adminRemark", $$v)
                              },
                              expression: "plan.adminRemark",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("Admin.adminRemark.tooltip"),
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-warning" })]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(_vm._s(_vm.$t("plan.table.name"))),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { value: _vm.plan.name },
                            model: {
                              value: _vm.plan.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.plan, "name", $$v)
                              },
                              expression: "plan.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(_vm._s(_vm.$t("productItem.status"))),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "408px" },
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.inselect"),
                                disabled: !_vm.allowEdit,
                              },
                              model: {
                                value: _vm.plan.itemStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.plan, "itemStatus", $$v)
                                },
                                expression: "plan.itemStatus",
                              },
                            },
                            _vm._l(_vm.itemStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value,
                                  disabled: item.disabled,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.loaded
                        ? _c(
                            "el-form-item",
                            {
                              key: _vm.plan.description,
                              attrs: {
                                label: _vm.$t("plan.description"),
                                prop: "description",
                              },
                            },
                            [
                              _c("Summernote", {
                                attrs: { value: _vm.plan.description },
                                on: { change: _vm.changeDescription },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(_vm._s(_vm.$t("Plan Type"))),
                            ]
                          ),
                          _vm._v(" "),
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                  disabled: _vm.pageType !== "CREATE",
                                },
                                on: { change: _vm.selectPlanTypeChange },
                                model: {
                                  value: _vm.plan.planType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.plan, "planType", $$v)
                                  },
                                  expression: "plan.planType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "FOR_MEMBER_BUY",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.FOR_MEMBER_BUY.des"
                                    ),
                                    value: "FOR_MEMBER_BUY",
                                    disabled: false,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "FOR_COUPON_USE",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.FOR_COUPON_USE.des"
                                    ),
                                    value: "FOR_COUPON_USE",
                                    disabled: false,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "FOR_ACTIVITY_USE",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.FOR_ACTIVITY_USE.des"
                                    ),
                                    value: "FOR_ACTIVITY_USE",
                                    disabled: false,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.plan.planType !== "FOR_MEMBER_BUY"
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(_vm._s(_vm.$t("Effective Days"))),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { width: "150px" },
                                attrs: {
                                  min: 1,
                                  step: "1",
                                  value: _vm.plan.days,
                                  type: "number",
                                },
                                on: { input: _vm.filterInputToNum },
                                model: {
                                  value: _vm.plan.days,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.plan, "days", $$v)
                                  },
                                  expression: "plan.days",
                                },
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("Days") +
                                      " (" +
                                      _vm.$t(
                                        "Calculated from the date of holding"
                                      ) +
                                      ")"
                                  ) +
                                  "\n          "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.plan.planType === "FOR_MEMBER_BUY"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "effectiveDateData",
                                label: _vm.$t("Release Date"),
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  "range-separator": "To",
                                  "start-placeholder": _vm.$t("Start date"),
                                  "end-placeholder": _vm.$t("End date"),
                                },
                                model: {
                                  value: _vm.effectiveDateData,
                                  callback: function ($$v) {
                                    _vm.effectiveDateData = $$v
                                  },
                                  expression: "effectiveDateData",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.plan.planType === "FOR_MEMBER_BUY"
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(_vm._s(_vm.$t("plan.card.table"))),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "60vw" },
                                  attrs: {
                                    "tooltip-effect": "dark",
                                    data: _vm.plan.cards,
                                    "header-row-class-name": "table-header",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("plan.card.lable"),
                                      prop: "cardName",
                                      align: "left",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("Apple Product ID"),
                                      prop: "appleProductId",
                                      width: "200px",
                                      align: "left",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("plan.card.days"),
                                      prop: "days",
                                      width: "150px",
                                      align: "left",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("plan.card.price"),
                                      prop: "price",
                                      width: "150px",
                                      align: "left",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("plan.card.amount"),
                                      prop: "amount",
                                      width: "150px",
                                      align: "left",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("COMPONENTS.operation"),
                                      fixed: "right",
                                      width: "180px",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled: !_vm.allowEdit,
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEdit(
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("COMPONENTS.edit")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled: !_vm.allowEdit,
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelete(
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "COMPONENTS.delete"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      69080656
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p"),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "60vw" },
                                  attrs: {
                                    disabled: !_vm.allowEdit,
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCard()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dialog",
                                {
                                  attrs: {
                                    "custom-class": "dialog-class",
                                    title: _vm.$t("plan.card.title"),
                                    visible: _vm.dialogVisible,
                                    width: "50%",
                                    "before-close": _vm.handleClose,
                                  },
                                  on: {
                                    "update:visible": function ($event) {
                                      _vm.dialogVisible = $event
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "temp",
                                      attrs: {
                                        model: _vm.card,
                                        "label-width": "180px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "cardName",
                                            label: _vm.$t("plan.card.lable"),
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "200px" },
                                            model: {
                                              value: _vm.card.cardName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.card,
                                                  "cardName",
                                                  $$v
                                                )
                                              },
                                              expression: "card.cardName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("p"),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "appleProductId",
                                            label: _vm.$t("Apple Product ID"),
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100px" },
                                            model: {
                                              value: _vm.card.appleProductId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.card,
                                                  "appleProductId",
                                                  $$v
                                                )
                                              },
                                              expression: "card.appleProductId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("p"),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("plan.card.days"),
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              type: "number",
                                              min: 0,
                                              onkeyup:
                                                "this.value=this.value.replace(/\\D/g,'')",
                                              onafterpaste:
                                                "this.value=this.value.replace(/\\D/g,'')",
                                            },
                                            model: {
                                              value: _vm.card.days,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.card, "days", $$v)
                                              },
                                              expression: "card.days",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("Days"))),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("p"),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("plan.card.price"),
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                "on-key-press":
                                                  "if((event.keyCode<48 || event.keyCode>57) && event.keyCode!=46 || /\\.\\d\\d$/.test(value))event.returnValue=false",
                                                clearable: "",
                                                type: "number",
                                                min: 0,
                                                onkeyup:
                                                  "this.value=this.value.replace(/\\D/g,'')",
                                                onafterpaste:
                                                  "this.value=this.value.replace(/\\D/g,'')",
                                              },
                                              model: {
                                                value: _vm.card.price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card,
                                                    "price",
                                                    $$v
                                                  )
                                                },
                                                expression: "card.price",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "prepend" },
                                                [_vm._v("HK$")]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("p"),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "amount",
                                            label: _vm.$t("plan.card.amount"),
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                "on-key-press":
                                                  "if((event.keyCode<48 || event.keyCode>57) && event.keyCode!=46 || /\\.\\d\\d$/.test(value))event.returnValue=false",
                                                clearable: "",
                                                type: "number",
                                                min: 0,
                                                onkeyup:
                                                  "this.value=this.value.replace(/\\D/g,'')",
                                                onafterpaste:
                                                  "this.value=this.value.replace(/\\D/g,'')",
                                              },
                                              model: {
                                                value: _vm.card.amount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card,
                                                    "amount",
                                                    $$v
                                                  )
                                                },
                                                expression: "card.amount",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "prepend" },
                                                [_vm._v("HK$")]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dialog-footer",
                                      attrs: { slot: "footer" },
                                      slot: "footer",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.dialogVisible = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("COMPONENTS.return"))
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addNewCard()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("COMPONENTS.save"))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(_vm._s(_vm.$t("Plan Detail"))),
                            ]
                          ),
                          _vm._v(" "),
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                  "default-first-option":
                                    "ALLOW_VIEW_SOME_RECIPE",
                                  multiple: "",
                                },
                                on: { change: _vm.selectChange },
                                model: {
                                  value: _vm.plan.planDetail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.plan, "planDetail", $$v)
                                  },
                                  expression: "plan.planDetail",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "ALLOW_VIEW_SOME_RECIPE",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.ALLOW_VIEW_SOME_RECIPE.des"
                                    ),
                                    value: "ALLOW_VIEW_SOME_RECIPE",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "ALLOW_PLAN_TO_BUY",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.ALLOW_PLAN_TO_BUY.des"
                                    ),
                                    value: "ALLOW_PLAN_TO_BUY",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "ALLOW_PLAN_TO_EAT",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.ALLOW_PLAN_TO_EAT.des"
                                    ),
                                    value: "ALLOW_PLAN_TO_EAT",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "ALLOW_ADD_FAMILY",
                                  attrs: {
                                    label: _vm.$t(
                                      "planType.ALLOW_ADD_FAMILY.des"
                                    ),
                                    value: "ALLOW_ADD_FAMILY",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.plan.planDetail.includes("ALLOW_VIEW_SOME_RECIPE")
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    _vm._s(_vm.$t("plan.include.product"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              [
                                _c("el-transfer", {
                                  key: _vm.plan.id,
                                  attrs: {
                                    id: "planPageTransfer",
                                    "tag-list-unselected": "",
                                    filterable: "",
                                    titles: [
                                      _vm.$t("tagList.unselected"),
                                      _vm.$t("tagList.selected"),
                                    ],
                                    "filter-method": _vm.filterMethod,
                                    "filter-placeholder": "Search here",
                                    data: _vm.productList,
                                  },
                                  model: {
                                    value: _vm.plan.goodsSkuIds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.plan, "goodsSkuIds", $$v)
                                    },
                                    expression: "plan.goodsSkuIds",
                                  },
                                }),
                              ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "200px" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "translate-drawer",
          attrs: {
            title: _vm.$t("COMPONENTS.translate"),
            visible: _vm.drawer,
            size: "60%",
            "show-close": false,
            "modal-append-to-body": false,
            "append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
            opened: _vm.getIframe,
          },
        },
        [
          _vm.drawer
            ? _c("iframe", {
                ref: "iframe",
                staticClass: "iframe-class",
                attrs: { src: _vm.ifreamSrc, height: _vm.getClientHeight() },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }