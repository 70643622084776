import { checkCfsPermission, checkUserInPartyId } from '@/utils/permission'
import Cookies from 'js-cookie'

export function getProductFunction() {
  console.log('getProductFunction start')

  const menuItem = []

  try {
    const productMenuFunctionStr = localStorage.getItem('productMenuFunction')

    if (productMenuFunctionStr) {
      const productMenuFunction = JSON.parse(productMenuFunctionStr)
      console.log('productMenuFunction', productMenuFunction)

      productMenuFunction.map((item, index) => {
        const menuName = getLan(item.menuName)
        const allow = checkUserInPartyId(item.partyGrpId, menuName)

        if (allow) {
          menuItem.push(
            {
              path: item.id,
              component: () => import('@/views/productItem'),
              name: 'productItem' + index,
              hidden: checkCfsPermission('SHOP_PRODUCT.LIST'),
              meta: { title: menuName, noCache: false, functionSetting: item }
            }
          )
          menuItem.push(
            {
              path: item.id + '/create',
              component: () => import('@/views/productItem/create'),
              name: 'productItemCreate' + index,
              hidden: true,
              meta: { title: menuName, functionSetting: item, type: 'CREATE' }
            }
          )
          menuItem.push(
            {
              path: item.id + '/edit',
              component: () => import('@/views/productItem/edit'),
              name: 'productItemEdit' + index,
              hidden: true,
              meta: { title: menuName, functionSetting: item, type: 'EDIT' }
            }
          )
        }
      })
    }

    return menuItem
  } catch (e) {
    console.error('getProductFunction error', e)

    // 預設值
    const defaultItem = [
      {
        path: 'productItem',
        component: () => import('@/views/productItem'),
        name: 'productItem',
        hidden: checkCfsPermission('SHOP_PRODUCT.LIST'),
        meta: { title: 'Product Maintenance', noCache: false }
      },
      {
        path: 'productItem/create',
        component: () => import('@/views/productItem/create'),
        name: 'productItemCreate',
        meta: { title: 'productItem.productItemCreate.label', type: 'CREATE' },
        hidden: true
      },
      {
        path: 'productItem/edit',
        component: () => import('@/views/productItem/edit'),
        name: 'productItemEdit',
        meta: { title: 'productItem.productItemEdit.label', type: 'EDIT' },
        hidden: true
      }
    ]

    return defaultItem
  }
}

export function getLan(lanObj) {
  let lan = null

  // Cookie是放admin後來選的語言
  const cookieData = Cookies.get('language')

  // localstorage是放"預設"語言
  const localStorageData = localStorage.getItem('SYSTEM_LANGUAGE_CODES')

  try {
    // 當admin沒有特別指定系統的語言
    // 則找預設值
    if (cookieData) {
      const lanData = JSON.parse(cookieData)
      lan = lanData.code
    } else {
      const lanData = JSON.parse(localStorageData)
      if (lanData) {
        const { data = {}} = lanData
        data.supportLanguageList && data.supportLanguageList.forEach(element => {
          element.code = element.language
          element.label = element.title
          if (element.code === data.defaultLanguage) {
            lan = element.code
          }
        })
      }
    }
  } catch (e) {
    lan = 'en-US'
  }

  let lanTxt = ''
  if (lanObj && lanObj.length !== 0) {
    lanObj.map((item, index) => {
      if (index === 0) {
        lanTxt = item.value
      }
      if (item.language === lan) {
        lanTxt = item.value
      }
    })
  }

  return lanTxt
}
