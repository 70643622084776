import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'

export function findById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attrVal/attrid?id=' + id,
    method: 'get'
  })
}

export function findByAttrId(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attrValue/productId/' + id,
    method: 'get'
  })
}

export function deleteById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attrVal/del/' + id,
    method: 'delete'
  })
}
