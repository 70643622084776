<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">{{
          title == "create" ? $t("COMPONENTS.add") : $t("COMPONENTS.edit")
        }}</el-col>
        <el-col :span="12">
          <el-button @click="$router.back(-1)">{{
            $t("COMPONENTS.return")
          }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission('coupon.add')"
            @click="createData"
          >{{ $t("COMPONENTS.save") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form
      ref="dataForm"
      :rules="rules"
      :model="dataForm"
      status-icon
      label-position="left"
      label-width="120px"
      class="search-form-inline"
      label-suffix=":"
    >
      <el-row>
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('coupon.title')" prop="title">
                  <el-input v-model="dataForm.title" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('coupon.shopName')" prop="publishId">
                  <el-select
                    v-model="dataForm.publishId"
                    :placeholder="$t('rule.Please-select-shop')"
                  >
                    <el-option
                      v-for="item in shopList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('coupon.desc')" prop="desc">
                  <el-input v-model="dataForm.desc" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('coupon.time')" required>
                  <el-row>
                    <el-col :span="10">
                      <el-form-item prop="beginTime">
                        <el-date-picker
                          v-model="dataForm.beginTime"
                          clearable
                          format="yyyy/MM/dd"
                          value-format="yyyy/MM/dd"
                          :placeholder="$t('COMPONENTS.beginTime')"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" class="line" style="text-align: center">
                      -
                    </el-col>
                    <el-col :span="10">
                      <el-form-item prop="endTime">
                        <el-date-picker
                          v-model="dataForm.endTime"
                          clearable
                          format="yyyy/MM/dd"
                          value-format="yyyy/MM/dd"
                          :placeholder="$t('COMPONENTS.endTime')"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item
                  :label="$t('COMPONENTS.content')"
                  prop="contentInfo"
                >
                  <!-- <quillEditor v-model="dataForm.contentInfo" />
                   -->
                   <div v-if="loaded" style="width: 90%">
                    <Summernote :value="dataForm.contentInfo" @change="changeContentInfo" height="150"/>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('COMPONENTS.label')" prop="backLabel">
                  <el-input v-model="dataForm.backLabel" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item
                  :label="$t('COMPONENTS.background')"
                  prop="status"
                >
                  <el-radio v-model="dataForm.status" label="1">{{
                    $t("COMPONENTS.colour")
                  }}</el-radio>
                  <el-radio v-model="dataForm.status" label="0">{{
                    $t("COMPONENTS.picture")
                  }}</el-radio>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item>
                  <el-color-picker
                    v-if="dataForm.status === '1'"
                    v-model="dataForm.backColor"
                    class="el-color-select"
                    color-format="rgb"
                    @change="pickerChange"
                  />
                  <el-upload
                    v-if="dataForm.status === '0'"
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadImg"
                    class="avatar-uploader"
                    accept=".jpg,.jpeg,.png,.gif"
                  >
                    <el-image
                      v-if="dataForm.backImageUrl"
                      class="avatar"
                      :src="getImagePath(dataForm.backImageUrl)"
                      @change="imageChange"
                    />
                    <em v-else class="el-icon-plus avatar-uploader-icon" />
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { couponSave, findById } from '@/api/couponApi'
import { imgUpload } from '@/api/uploadApi'
import { quillEditor } from 'vue-quill-editor'
import { shopList } from '@/api/shop'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import { checkCfsPermission } from '@/utils/permission'
import Summernote from '@/components/Summernote' 
export default {
  name: 'ProductAttributeCreate',
  components: { Sticky, quillEditor, Summernote },
  data() {
    return {
      loaded:false,
      edit: true,
      shopList: [],
      id: null,
      title: null,
      dataForm: {
        id: '',
        backImageUrl: '',
        backColor: '',
        backImageId: '',
        effectiveDate: '',
        title: '',
        type: '',
        beginTime: '',
        endTime: '',
        desc: '',
        contentInfo: '',
        backLabel: '',
        shopName: '',
        publishId: '',
        status: '1'
      },
      rules: {
        title: [
          {
            required: true,
            message: this.$t('COMPONENTS.Title-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        publishId: [
          {
            required: true,
            message: this.$t('COMPONENTS.Shops-can-not-be-empty'),
            trigger: 'change'
          }
        ],
        contentInfo: [
          {
            required: true,
            message: this.$t('COMPONENTS.Content-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        status: [
          {
            required: true,
            message: this.$t('COMPONENTS.State-cannot-be-empty'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {},
  watch: {
    'dataForm.backImageUrl': {
      handler() {
        if (this.dataForm.backImageUrl) {
          this.dataForm.backColor = null
        }
      }
    }
  },
  mounted() {
    this.getShop()
  },
  methods: {
    changeContentInfo(value){
      this.dataForm.contentInfo = value
    },
    checkPermission(value) {
      if (this.title === 'update') {
        return checkCfsPermission('coupon.edit')
      } else {
        return checkCfsPermission('coupon.add')
      }
    },
    getImagePath(path) {
      return getStaticSourcePath(path)
    },
    changeBackColor() {
      this.dataForm.backColor = null
    },
    getShop() {
      this.title = this.$route.query.title
      if (this.title === 'update') {
        this.id = this.$route.query.id
        findById(this.id)
          .then((res) => {
            console.log('article----', res.data)
            this.dataForm.id = res.data.id
            this.dataForm.backImageUrl = res.data.backImageUrl
            this.dataForm.title = res.data.title
            this.dataForm.type = res.data.type
            this.dataForm.desc = res.data.desc
            this.dataForm.contentInfo = res.data.contentInfo
            this.dataForm.publishId = res.data.publishId
            this.dataForm.backLabel = res.data.backLabel
            this.dataForm.backColor = res.data.backColor
            this.dataForm.backImageId = res.data.backImageId
            if (res.data.backImageUrl) {
              this.dataForm.status = '0'
            } else {
              this.dataForm.status = '1'
            }
            this.loaded = true
          })
          .catch(() => {})
      }

      console.log('title----', this.dataForm)
      shopList().then((res) => {
        this.shopList = res.data
        console.info('+++++', this.shopList)
        this.loaded = true
      })
    },
    pickerChange() {
      this.dataForm.backImageUrl = null
    },
    imageChange() {
      this.dataForm.backColor = null
    },
    createData() {
      var article = this.dataForm
      console.info('+++', article)
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          couponSave(article)
            .then((res) => {
              this.$message.success(this.$t('COMPONENTS.save_Success'))
              this.$router.back(-1)
            })
            .catch(() => {
              this.$message.error(this.$t('COMPONENTS.save_Filad'))
            })
        } else {
          return false
        }
      })
    },
    uploadImg(file) {
      console.log(file)
      var newFile = file.file
      var formData = new FormData()
      formData.append('files', newFile)
      imgUpload(formData).then((res) => {
        this.dataForm.backImageUrl = res[0].url
      })
    }
  }
}
</script>

<style >
.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.el-color-picker--medium .el-color-picker__trigger {
  width: 178px;
  height: 178px;
}
.component {
  margin: 10px;
}
.tree-box {
  margin: 15px 0 100px 55px;
  border: #a6e0d8 solid 2px;
  padding: 15px;
}
.form-item-head {
  margin-bottom: 8px;
  font-size: 12px;
}
.hide .el-upload--picture-card {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.el-color-picker__empty.el-icon-close {
  display: none;
}
.el-color-picker__icon.el-icon-arrow-down {
  display: none;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
