<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="180px"
        >
          <el-row :gutter="20">
            <!-- <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('COMPONENTS.Date')">
                <el-date-picker
                  v-model="listQuery.orderDate"
                  clearable
                  format="yyyy/MM/dd"
                  value-format="yyyy/MM/dd"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col> -->
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('order_Manage.orderNo')">
                <el-input v-model="listQuery.orderNo" clearable class="filter-item" />
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('Member Account')">
                <el-input v-model="listQuery.loginName" clearable class="filter-item" />
              </el-form-item>
            </el-col>
            <!-- 目前沒有用到，堂食、訂餐外送、訂餐自取

            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('order_Manage.orderType')">
                <el-select v-model="listQuery.orderType" :placeholder="$t('COMPONENTS.inselect')">
                  <el-option :label="$t('order_Manage.OFFLINE_APPOINTMENT')" value="OFFLINE_APPOINTMENT" />
                  <el-option :label="$t('order_Manage.EXPRESS_DELIVERY')" value="EXPRESS_DELIVERY" />
                  <el-option :label="$t('order_Manage.PICKUP_SELF')" value="PICKUP_SELF" />
                </el-select>
              </el-form-item>
            </el-col>
-->
            <!--

            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('Phone Number')">
                <el-input v-model="listQuery.buyerPhone" clearable :placeholder="$t('order_Manage.Please-enter-buyer-phone')" />
              </el-form-item>
            </el-col>

 -->

          </el-row>
          <el-row :gutter="20">

            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('Payment status')">
                <el-select v-model="listQuery.state" filterable clearable>
                  <el-option :label="$t('COMPONENTS.NEW')" value="NEW" />
                  <el-option :label="$t('COMPONENTS.PAYMENT')" value="PAYMENT" />
                  <!-- <el-option :label="$t('COMPONENTS.READY')" value="READY" /> -->
                  <!-- <el-option :label="$t('COMPONENTS.WIP')" value="WIP" /> -->
                  <!-- <el-option :label="$t('COMPONENTS.SHIPPING')" value="SHIPPING" /> -->
                  <el-option :label="$t('COMPONENTS.REFUND')" value="REFUND" />
                  <!-- <el-option :label="$t('COMPONENTS.COMPLETED')" value="COMPLETED" /> -->
                  <!-- <el-option :label="$t('COMPONENTS.EVALUATE')" value="EVALUATE" /> -->
                  <!-- <el-option :label="$t('COMPONENTS.CLOSE')" value="CLOSE" /> -->
                  <el-option :label="$t('COMPONENTS.CANCEL')" value="CANCEL" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('Product Created By')">
                <el-input
                  v-model="listQuery.productCreatedBy"
                  clearable
                  :readonly="checkPermission('SEARCH_ALL_ORDER')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="12" :md="8" :sm="24" :xs="24">
              <el-form-item :label="$t('COMPONENTS.time')">

                <el-date-picker
                  v-model="listQuery.beginTime"
                  clearable
                  type="datetime"
                  :placeholder="$t('COMPONENTS.beginTime')"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 45%"
                />
                ~
                <el-date-picker
                  v-model="listQuery.endTime"
                  clearable
                  type="datetime"
                  :placeholder="$t('COMPONENTS.endTime')"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 45%"
                />

              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('order.payment.type.title')">
                <el-select v-model="listQuery.orderType" filterable clearable>
                  <el-option
                    v-for="option in orderTypeCodes"
                    :key="option.value"
                    :label="$t(option.labelKey)"
                    :value="option.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('order_Manage.goodsType')">
                <el-select v-model="listQuery.productType" filterable clearable @change="changeProductType">
                  <el-option
                    v-for="option in productTypes"
                    :key="option.value"
                    :label="$t(option.labelKey)"
                    :value="option.value"
                  />

                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="goodsVisible" :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t(productLabel)">
                <el-select v-model="listQuery.productId" filterable clearable>
                  <el-option
                    v-for="product in productList"
                    :key="product.value"
                    :label="product.label"
                    :value="product.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <div style="float: right;">
              <el-button class="filter-item" :disabled="checkPermission('order')" @click="handleExport">{{ $t('COMPONENTS.export') }}</el-button>
              <el-button type="primary" class="filter-item" :disabled="checkPermission('order')" @click="handleFilter">{{ $t('COMPONENTS.search') }}</el-button>
            </div>
          </el-row>
        </el-form>
      </div>
    </transition>

    <!-- 查询结果 -->
    <!-- 如果要預設展開，請在el-table加上default-expand-all屬性 -->
    <el-table
      :data="list"
      border
      row-key="id"
      :row-class-name="tableRowClassName"
      header-row-class-name="table-header"
      tooltip-effect="dark"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <div>
            <el-row>
              <el-col>
                <el-table :data="props.row.orderItems" :border="true" size="small">
                  <el-table-column width="200px" :label="$t('order_Manage.goodName')" prop="goodName" />
                  <el-table-column width="200px" :label="$t('order_Manage.price')" prop="price" />
                  <el-table-column width="200px" :label="$t('order_Manage.qty')" prop="qty" />
                  <el-table-column width="200px" :label="$t('order.orderItem.type.title')" prop="">
                    <template slot-scope="scope">
                      {{ scope.row.shopGoodId=='-1'?$t('order.orderItem.type.plan'):$t('order.orderItem.type.product') }}
                    </template>
                  </el-table-column>
                  <el-table-column width="200px" :label="$t('Created By')" prop="productCreatedBy" />
                  <el-table-column :label="$t('order_Manage.attributes')">
                    <template slot-scope="scope">
                      <el-row v-for="item in scope.row.attributes" :key="item.id" class="attr-value-show" type="flex">
                        <el-col :span="12" class="attr-show">{{ item.title }}&nbsp;: </el-col>
                        <el-col v-for="value in item.attributes" :key="value.id" :span="4" class="value-show">{{ value.title }}&nbsp; </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" :label="$t('order_Manage.shoppingType')" width="150" prop="shoppingType" /> -->
      <el-table-column align="left" :label="$t('order_Manage.orderNo')" width="200" prop="orderNo" />
      <el-table-column align="center" :label="$t('COMPONENTS.Date')" width="100" prop="date" />
      <el-table-column align="center" :label="$t('COMPONENTS.time')" width="100" prop="time" />
      <el-table-column align="center" :label="$t('COMPONENTS.sum')" width="100" prop="price" />
      <el-table-column align="center" :label="$t('Payment status')" width="150" prop="state">
        <template slot-scope="scope">
          {{ scope.row.state=='NEW'?$t('COMPONENTS.NEW'):scope.row.state=='PAYMENT'?$t('COMPONENTS.PAYMENT'):scope.row.state=='REFUND'?$t('COMPONENTS.REFUND'):$t('COMPONENTS.CANCEL') }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('order.payment.type.title')" width="210" prop="orderTypeCode">
        <template slot-scope="scope">
          <!-- {{scope.row.orderTypeCode=='OFFLINE_PAY'?$t('order.payment.type.offline'):$t('order.payment.type.other')}} -->
          <span>{{ $t(scope.row.orderTypeLabel) }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" :label="$t('Member Account')" width="100" prop="loginName" />
      <el-table-column align="center" :label="$t('order_Manage.buyer')" width="150" prop="consignee">
        <template slot-scope="scope">
          <span>{{ $t(scope.row.firstName) + ' '+ $t(scope.row.lastName) }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" :label="$t('order_Manage.buyerPhone')" width="120" prop="mobileNo" />

      <el-table-column align="left" :label="$t('Shipping address')" prop="address" width="400" />  <!-- 運送地址 -->
      <el-table-column align="left" :label="$t('Shipping No')" prop="shippingNo" width="200" />  <!-- 物流編號 -->
      <el-table-column align="left" :label="$t('Shipping status')" prop="shippingStatus" width="140">  <!-- 物流狀態 -->
        <template slot-scope="scope">
          <span v-if="scope && scope.row && scope.row.shippingStatus && scope.row.shippingStatus === 'NOT_DELIVERED'">{{ $t('Not delivered') }}</span>
          <span v-if="scope && scope.row && scope.row.shippingStatus && scope.row.shippingStatus === 'SENDING'">{{ $t('Sending') }}</span>
          <span v-if="scope && scope.row && scope.row.shippingStatus && scope.row.shippingStatus === 'SENT'">{{ $t('Sent') }}</span>
        </template>
      </el-table-column>

      <el-table-column align="left" :label="$t('Member order remark')" prop="remark" width="300" />    <!-- 訂單備註 -->
      <el-table-column align="left" :label="$t('Email')" prop="email" width="300" />
      <el-table-column align="left" :label="$t('Admin order remark')" prop="adminRemark" width="300" />    <!-- 管理員訂單備註 -->

      <el-table-column align="center" :label="$t('COMPONENTS.operation')" fixed="right">
        <template slot-scope="scope">
          <el-dropdown>
            <el-button size="mini" round>
              <em class="el-icon-more" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="checkPermission('order.print')" icon="el-icon-printer" @click.native="handlePrint(scope.row)">{{ $t('order_Manage.print') }}</el-dropdown-item>
              <el-dropdown-item :disabled="checkPermission('order.infomation')" icon="el-icon-info" @click.native="handleInfo(scope.row)">{{ $t('COMPONENTS.info') }}</el-dropdown-item>
              <el-dropdown-item :disabled="permissionOrderComplate(scope.row)" icon="el-icon-check" @click.native="handleOrderComplate(scope.row)">{{ $t('order_Manage.action.payment.comfirm') }}</el-dropdown-item><!--確認付款-->
              <el-dropdown-item :disabled="permissionRefund(scope.row)" icon="el-icon-sold-out" @click.native="handleRefund(scope.row)">{{ $t('order_Manage.action.payment.refund') }}</el-dropdown-item><!--退款-->
              <el-dropdown-item :disabled="permissionOrderCancel(scope.row)" icon="el-icon-close" @click.native="handleOrderCancel(scope.row)">{{ $t('order_Manage.action.payment.cancel') }}</el-dropdown-item><!--取消訂單-->
              <el-dropdown-item icon="el-icon-edit" @click.native="editAdminRemark(scope.row)">{{ $t('Admin order remark') }}</el-dropdown-item>

              <div v-if="scope.row.needShipping">
                <el-dropdown-item divided @click.native="editShippingNo(scope.row)">{{ $t('Shipping No') }}</el-dropdown-item><!--物流編號-->
                <el-dropdown-item @click.native="updateShippingStatus(scope.row, 'NOT_DELIVERED')">{{ $t('Not delivered') }}</el-dropdown-item><!--未寄送-->
                <el-dropdown-item @click.native="updateShippingStatus(scope.row, 'SENDING')">{{ $t('Sending') }}</el-dropdown-item><!--寄送中-->
                <el-dropdown-item @click.native="updateShippingStatus(scope.row, 'SENT')">{{ $t('Sent') }}</el-dropdown-item><!--已寄送-->
              </div>

              <el-dropdown-item v-if="scope.row.orderTypeCode === 'APPLE_IAP'" divided :disabled="permissionAppleIAPOrderCheck(scope.row)" @click.native="handleAppleIAPOrderCheck(scope.row)">
                <svg-icon icon-class="appstore" /> {{ $t('order_Manage.update_payment_status') }}
              </el-dropdown-item><!--更新狀態-->

            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>

    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.size"
      @pagination="getList"
    />
    <!--
    <el-dialog :visible.sync="editDialogVisible">
      <el-form
        class="search-form-inline"
        label-suffix=":"
        label-position="left"
        hide-required-asterisk
        label-width="75px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('COMPONENTS.state')">
              <el-select v-model="orderEdit.state" :placeholder="$t('COMPONENTS.inselect')">
                <el-option :label="$t('COMPONENTS.NEW')" value="NEW" />
                <el-option :label="$t('COMPONENTS.PAYMENT')" value="PAYMENT" />
                <el-option :label="$t('COMPONENTS.READY')" value="READY" />
                <el-option :label="$t('COMPONENTS.WIP')" value="WIP" />
                <el-option :label="$t('COMPONENTS.SHIPPING')" value="SHIPPING" />
                <el-option :label="$t('COMPONENTS.REFUND')" value="REFUND" />
                <el-option :label="$t('COMPONENTS.COMPLETED')" value="COMPLETED" />
                <el-option :label="$t('COMPONENTS.EVALUATE')" value="EVALUATE" />
                <el-option :label="$t('COMPONENTS.CLOSE')" value="CLOSE" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('order_Manage.remark')">
              <el-input type="textarea" v-model="orderEdit.remark" clearable class="filter-item" :placeholder="$t('order_Manage.Please-enter-remark')" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24" align="center">
            <el-button size="mini" @click="editOrderSubmit()" :disabled='checkPermission("order.print")'>{{$t('order_Manage.submit')}}</el-button>
            <el-button size="mini" @click="cancelEdit()" :disabled='checkPermission("order.print")'>{{$t('order_Manage.cancel')}}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>    -->

    <!-- 編輯admin remark -->
    <el-dialog :title="$t('Admin order remark')" :visible.sync="adminRemarkDialog.drawerShow">
      <div style="padding : 15px">
        <el-input v-model="adminRemarkDialog.text" :rows="4" type="textarea" />
      </div>
      <div style="padding : 15px">
        <el-button style="float: right; margin-right: 10px;" size="mini" @click="cancelEditAdminRemark()">{{ $t('COMPONENTS.cancel') }}</el-button>
        <el-button style="float: right; margin-right: 10px;" type="primary" size="mini" @click="editAdminRemarkSubmit()">{{ $t('COMPONENTS.save') }}</el-button>
      </div>
    </el-dialog>

    <!-- 編輯Shipping No -->
    <el-dialog :title="$t('Shipping No')" :visible.sync="shippingNoDialog.drawerShow">
      <div style="padding : 15px">
        <el-input v-model="shippingNoDialog.text" type="input" />
      </div>
      <div style="padding : 15px">
        <el-button style="float: right; margin-right: 10px;" size="mini" @click="cancelEditShippingNo()">{{ $t('COMPONENTS.cancel') }}</el-button>
        <el-button style="float: right; margin-right: 10px;" type="primary" size="mini" @click="editShippingNoSubmit()">{{ $t('COMPONENTS.save') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('APPLE_IAP_DIALOG.TITLE')" :visible.sync="appleIAP.drawerShow" :width="'700px'"> <!-- 蘋果交易確認 -->

      <span style="font-weight: 900;">
        {{ $t('APPLE_IAP_DIALOG.MSG1') }}：
        <!-- 應用場景 -->
      </span>
      <br>
      <span>
        {{ $t('APPLE_IAP_DIALOG.MSG2') }}
        <!-- 當用戶交易時出現錯誤，有機會造成我們網站的訂單無法匹配，此時請跟用戶索取蘋果的交易ID，並且查詢我們蘋果的官網後台，務必確認需要與這條交易紀錄的時間匹配 -->
      </span>
      <br>
      <br>
      <br>
      <span style="font-weight: 900;">
        {{ $t('APPLE_IAP_DIALOG.MSG3') }}
        <!-- 使用這個功能之前，首先，需先查證這張未支付的訂單，是屬於蘋果的交易紀錄 -->
      </span>
      <br>
      <span>
        {{ $t('APPLE_IAP_DIALOG.MSG4') }}
        <!-- 目前這張訂單，沒有蘋果In-App parchase的交易訊息 -->
      </span>
      <br>
      <br>

      <span>
        {{ $t('APPLE_IAP_DIALOG.MSG5') }}
        <!-- 用戶端：查詢自己的訂單 (可以提供給用戶)-->
      </span>
      <br>
      <a href="https://reportaproblem.apple.com/" target="_blank"><span style="color:blue;">https://reportaproblem.apple.com/</span></a>
      <br>
      <br>
      <span>
        {{ $t('APPLE_IAP_DIALOG.MSG6') }}：
        <!-- 管理員端：查詢所有的交易紀錄-->
      </span>
      <br>
      <a href="https://appstoreconnect.apple.com/" target="_blank"><span style="color:blue;">https://appstoreconnect.apple.com/</span></a>
      <br>
      <br>
      <br>
      <br>
      <span>
        {{ $t('APPLE_IAP_DIALOG.MSG7') }}
        <!-- 請輸入蘋果的交易ID -->
      </span>
      <br>
      <br>
      <el-checkbox v-model="appleIAP.isCheck">
        <span>
          {{ $t('APPLE_IAP_DIALOG.MSG8') }}
          <!-- 我了解這個操作無法復原 -->
        </span>
      </el-checkbox>
      <br>

      <div style="padding : 15px">
        <el-input v-model="appleIAP.appleOrderId" type="input" placeholder="e.g. MLV1B88G66" />
      </div>
      <div style="padding : 15px">
        <el-button style="float: right; margin-right: 10px;" size="mini" @click="cancelAppleIAP()">{{ $t('COMPONENTS.cancel') }}</el-button>
        <el-button :disabled="isNull(appleIAP.appleOrderId) || !appleIAP.isCheck" style="float: right; margin-right: 10px;" type="primary" size="mini" @click="restoreAppleOrderJob()">{{ $t('COMPONENTS.save') }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination' //
import { getPageParm } from '@/utils/page-common'
import { exportOrderList, searchOrderList, updateOrderStatus, updateOrderShippingStatus,
  updateOrderAdminRemark, updateOrderShippingNo, restoreAppleOrder, reCheckAppleOrder } from '@/api/orderApi'
import { getProductBySellType } from '@/api/productItem'
import { checkCfsPermission } from '@/utils/permission'
import moment from 'moment'
import pageLoading from '@/utils/pageLoading'
import lang from '@/lang'
import { DB_DATE_FORMAT } from '@/Config'

export default {
  name: 'OrderList',
  components: { Pagination },
  data() {
    return {
      appleIAP: {
        drawerShow: false,
        appleOrderId: null,
        orderId: null,
        isCheck: false
      },
      goodsVisible: false,
      adminRemarkDialog: {
        text: null,
        drawerShow: false
      },
      shippingNoDialog: {
        text: null,
        drawerShow: false
      },
      editDialogVisible: false,
      list: [],
      total: 0,
      orderEdit: {
        // id: null,
        // orderNo: null,
        // buyer: null,
        // buyerPhone: null,
        // orderType: null,
        // state: null,
        // remark: null,
        // shoppingType: null,
        // price: null
      },
      listQuery: {
        page: 0,
        size: 10,
        orderDate: '',
        orderNo: '',
        loginName: '',
        buyer: '',
        buyerPhone: '',
        productCreatedBy: '',
        eqProductCreatedBy: null,
        likeProductCreatedBy: null,
        state: '',
        orderType: '',
        beginTime: '',
        endTime: '',
        productType: '',
        productId: ''
      },
      orderTypeCodes: [
        { value: 'PAYMENT_GATEWAY_SRTIPE_CREDIT_CARD', labelKey: 'const.order.payment_method.payment_gateway_stripe_card' },
        { value: 'PAYMENT_GATEWAY_WXPAY_INTERNATIONAL', labelKey: 'const.order.payment_method.payment_gateway_wx_pay_international' },
        { value: 'OFFLINE_PAY', labelKey: 'const.order.status.offline_pay' },
        { value: 'PAYMENT_GATEWAY', labelKey: 'const.order.payment_method.payment_gateway' },
        { value: 'PAYMENT_GATEWAY_ALIPAYHK', labelKey: 'const.order.payment_method.payment_gateway_alipay_hk' },
        { value: 'PAYMENT_GATEWAY_ALIPAYCN', labelKey: 'const.order.payment_method.payment_gateway_alipay_cn' },
        { value: 'APPLE_IAP', labelKey: 'page.settlement.components.PaymentMethod.APP_IN_APP_PURCHASE' }
      ],
      productTypes: [
        { value: 'SIMPLE-ITEM', labelKey: 'productItem.SIMPLE-ITEM' },
        { value: 'SERVICE-PLAN', labelKey: 'plan.title' },
        { value: 'MEMBER-FAMILY-CARD', labelKey: 'familyProgram.title' }

      ],
      productList: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async changeProductType(value) {
      this.goodsVisible = false
      this.listQuery.productType = value
      this.listQuery.productId = ''
      let goodSList = []
      if (value) {
        goodSList = await getProductBySellType(value)
      }
      if (!goodSList) goodSList = []
      this.productList = goodSList.map(item => {
        return {
          value: item.id,
          // label: item.name + '(' + item.lastModifiedDate + ')'
          label: item.adminTag
        }
      })
      this.goodsVisible = !!(value && goodSList)
      if (!value) return
      const productLabels = this.productTypes.filter(i => { return i.value === value && value === 'SIMPLE-ITEM' })
      let productLabel = ''
      if (productLabels && productLabels.length > 0) {
        productLabel = 'order_Manage.goods'
      } else {
        productLabel = 'plan.table.name'
      }
      this.productLabel = productLabel
    },
    tableRowClassName(rowData) {
      // 如果要走物流，而且還沒寄出，整行背景顏色改變
      const row = rowData.row
      const needShipping = row.needShipping
      const shippingStatus = row.shippingStatus
      const paymentStatus = row.state

      if (needShipping && shippingStatus !== 'SENT' && paymentStatus === 'PAYMENT') {
        return 'warning-row'
      } else {
        return ''
      }
    },

    // 設定物流狀態
    updateShippingStatus(row, status) {
      console.log('updateOrderShippingStatus', row)
      this.orderEdit = row
      this.orderEdit.shippingStatus = status
      updateOrderShippingStatus(this.orderEdit).then(res => {
        this.cancelEditAdminRemark()
      })
    },

    // 彈窗修改Admin Remark
    editAdminRemarkSubmit() {
      this.orderEdit.adminRemark = this.adminRemarkDialog.text
      updateOrderAdminRemark(this.orderEdit).then(res => {
        this.cancelEditAdminRemark()
      })
    },
    editAdminRemark(row) {
      this.orderEdit = row
      this.adminRemarkDialog.text = row.adminRemark
      this.adminRemarkDialog.drawerShow = true
    },
    cancelEditAdminRemark() {
      this.adminRemarkDialog.drawerShow = false
    },

    // 彈窗修改Shipping No
    editShippingNoSubmit() {
      this.orderEdit.shippingNo = this.shippingNoDialog.text
      updateOrderShippingNo(this.orderEdit).then(res => {
        this.cancelEditShippingNo()
      })
    },
    editShippingNo(row) {
      this.orderEdit = row
      this.shippingNoDialog.text = row.shippingNo
      this.shippingNoDialog.drawerShow = true
    },
    cancelEditShippingNo() {
      this.shippingNoDialog.drawerShow = false
    },
    cancelAppleIAP() {
      this.appleIAP.orderId = null
      this.appleIAP.appleOrderId = null
      this.appleIAP.drawerShow = false
      this.appleIAP.isCheck = false
    },
    restoreAppleOrderJob() {
      const orderId = this.appleIAP.orderId
      const appleOrderId = this.appleIAP.appleOrderId

      if (!this.isNull(orderId) && !this.isNull(appleOrderId)) {
        // 開始同步
        restoreAppleOrder(appleOrderId, orderId).then(res => {
          if (res === 'ORDER_NOT_FOUND') {
            const msg = this.$t('APPLE_IAP_ERROR_ORDER_NOT_FOUND') // APPLE_IAP_ERROR_ORDER_NOT_FOUND 錯誤，查無此訂單
            alert(msg)
          } else {
            let msg = this.$t('APPLE_IAP_MSG_1') + ':  ' // APPLE_IAP_MSG_1已更新訂單最新狀態
            if (res === 'PAYMENT') {
              msg += this.$t('COMPONENTS.PAYMENT')
              alert(msg)
            } else if (res === 'REFUND') {
              msg += this.$t('COMPONENTS.REFUND')
              alert(msg)
            }
            this.getList()
            this.appleIAP.drawerShow = false
          }
        })
      }
    },
    reCheckAppleOrderJob(orderId) {
      if (!this.isNull(orderId)) {
        // 開始同步
        reCheckAppleOrder(orderId).then(res => {
          if (res === 'ORDER_NOT_FOUND') {
            const msg = this.$t('APPLE_IAP_ERROR_ORDER_NOT_FOUND') // APPLE_IAP_ERROR_ORDER_NOT_FOUND 錯誤，查無此訂單
            alert(msg)
          } else {
            let msg = this.$t('APPLE_IAP_MSG_1') + ':  ' // APPLE_IAP_MSG_1已更新訂單最新狀態
            if (res === 'PAYMENT') {
              msg += this.$t('COMPONENTS.PAYMENT')
              alert(msg)
            } else if (res === 'REFUND') {
              msg += this.$t('COMPONENTS.REFUND')
              alert(msg)
            }
            this.getList()
          }
        })
      }
    },
    handlePrint(row) {
      console.log('handlePrint', row)
    },
    handleInfo(row) {
      console.log('handleInfo', row)
    },

    handleRefund(row) {
      console.log('handleRefund', row)
      if (row.orderTypeCode !== 'APPLE_IAP') {
        this.changeStatus(row, 'REFUND')
      } else {
        this.$message({
          type: 'error',
          message: this.$t('APPLE_IAP_MSG_2')//  APPLE_IAP_MSG_2 後台管理員無法退款，請用戶自行與蘋果處理
        })
      }
    },
    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    handleAppleIAPOrderCheck(row) {
      console.log('handleAppleIAPOrderCheck', row)

      const appleIAPTransactionId = row.appleIAPTransactionId
      const orderId = row.id
      const allow = !this.permissionAppleIAPOrderCheck(row)

      if (allow) {
        if (this.isNull(appleIAPTransactionId)) {
          // 沒有收到蘋果的交易通知，請輸入客戶提供的交易ID
          this.appleIAP.drawerShow = true
          this.appleIAP.appleOrderId = null
          this.appleIAP.orderId = orderId
          this.appleIAP.isCheck = false
        } else {
          // 有收到蘋果的交易ID
          this.reCheckAppleOrderJob(orderId)
        }
      }
    },

    handleOrderComplate(row) {
      console.log('handleOrderComplate', row)
      this.changeStatus(row, 'PAYMENT')
    },
    handleOrderCancel(row) {
      console.log('handleOrderCancel', row)
      this.changeStatus(row, 'CANCEL')
    },
    permissionRefund(row) {
      return !(!this.checkPermission('order.edit') && row.state !== 'CANCEL' && row.state !== 'NEW' && row.state !== 'REFUND')
    },
    permissionAppleIAPOrderCheck(row) {
      return !(!this.checkPermission('order.edit') && row.orderTypeCode === 'APPLE_IAP')
    },
    permissionOrderComplate(row) {
      const orderTypeCode = row.orderTypeCode
      console.log('orderTypeCode', row)
      if (orderTypeCode === 'OFFLINE_PAY') {
        // 線下支付可以另外手動收款，可以由老闆自行修改成"已支付"狀態
        console.log('orderTypeCode11', row)
        return !(!this.checkPermission('order.edit') && row.state !== 'CANCEL' && row.state !== 'REFUND' && row.state !== 'PAYMENT')
      } else {
        // 線上支付不允許手動改成=>已支付
        return !(!this.checkPermission('order.edit') && row.state !== 'CANCEL' && row.state !== 'REFUND' && row.state !== 'PAYMENT' && row.state !== 'NEW')
      }
    },
    permissionOrderCancel(row) {
      return !(!this.checkPermission('order.edit') && row.state !== 'CANCEL')
    },
    handleCommand(command) {
      this.$message('click on item ' + command)
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getList() {
      var query = { ...this.listQuery }

      //  廚師在後台只能查自己建立的計畫
      //  CFS那邊有兩個權限
      //  SEARCH_ALL_PLAN
      //  SEARCH_OWN_PLAN

      const searchOwn = !checkCfsPermission('SEARCH_OWN_ORDER')
      const searchAll = !checkCfsPermission('SEARCH_ALL_ORDER')

      const loginName = localStorage.getItem('user_profile')

      // 只能搜尋自建立的產品訂單
      if (searchOwn && !searchAll) {
        query.likeProductCreatedBy = null
        query.eqProductCreatedBy = loginName
        this.listQuery.productCreatedBy = loginName
      } else if (searchAll) {
        query.eqProductCreatedBy = null
        query.likeProductCreatedBy = query.productCreatedBy
      }

      // 沒有允許搜尋任何訂單
      if (!searchAll && !searchOwn) {
        alert('No Permission, Please check cfs system: SEARCH_ALL_ORDER, SEARCH_OWN_ORDER')
      } else {
        var param = getPageParm(query)
        searchOrderList(param, query).then(res => {
          this.list = res.data ? this.filterList(res.data) : []
          console.log('searchOrderList---res:', res)
          this.listQuery.page = res.pageNum + 1
          this.listQuery.size = res.pageSize
          this.total = res.pageCount
        }).catch(() => {

        })
      }
    },
    handleExport() {
      const query = { ...this.listQuery }
      const param = getPageParm(query)
      const that = this
      this.$confirm(`${lang.t('COMPONENTS.export_confirm')}`, `${lang.t('COMPONENTS.warning')}`, {
        confirmButtonText: `${lang.t('COMPONENTS.confirm')}`,
        cancelButtonText: `${lang.t('COMPONENTS.cancel')}`,
        type: 'warning'
      }).then(function() {
        pageLoading.showFullScreenLoading()
        exportOrderList(param, query)
          .then(res => {
            if (res.status === 400) {
              throw new Error('exceed limit')
            }
            return res.blob()
          })
          .then(blob => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            var dateFormat = moment(new Date()).format(DB_DATE_FORMAT)
            link.download = 'orders-' + dateFormat + '.xlsx'
            link.onclick = function() {
              document.body.removeChild(link)
              pageLoading.hideFullScreenLoading()
            }
            document.body.appendChild(link)
            link.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              })
            )
          })
          .catch(error => {
            pageLoading.hideFullScreenLoading()
            if (error.message === 'exceed limit') {
              that.$alert(`${lang.t('COMPONENTS.export_limit_error')}`, `${lang.t('COMPONENTS.error')}`, {
                confirmButtonText: `${lang.t('COMPONENTS.confirm')}`,
                type: 'error'
              })
            } else {
              console.error('There was an error!', error)
            }
          })
      })
    },
    filterList(list) {
      list.map(data => {
        const orderTypeCode = data.orderTypeCode
        data.orderDate = this.formatDateTime(new Date(data.orderCreateTime))
        const orderTypeCodeObj = this.orderTypeCodes.filter(i => { return i.value === orderTypeCode })
        if (orderTypeCodeObj && orderTypeCodeObj.length > 0) {
          data.orderTypeLabel = orderTypeCodeObj[0].labelKey
        }
        return data
      })
      return list
    },
    formatDateTime(date) {
      if (date) {
        var m = moment(date)
        return m.format('HH:mm:ss')
      }
      return null
    },

    handleFilter() {
      this.getList()
    },
    // handleUpdate(row) {

    // },
    changeStatus(row, status) {
      this.orderEdit = { ...row, remark: null }
      updateOrderStatus(this.orderEdit, status).then(res => {
        const result = res.RESULT
        if (result === 'SUCCESS') {
          // alert('Submit success');
          alert(this.$t('operation successful'))
          console.log(res.MSG)
          row.state = status
        } else {
          console.log(res.MSG)
          alert(this.$t('operation failed'))
        }
      }).catch((err) => {
        console.log('---updateOrderStatus error:', err)
      })
    }

    // cancelEdit() {
    //   this.orderEdit = {};
    //   this.editDialogVisible = false;
    // },
    // showEditDialog(row) {
    //   this.orderEdit = {...row, remark: null};
    //   this.editDialogVisible = true;
    // },

  }
}
</script>

<style scoped lang="scss">
  .attr-value-show{
    .attr-show{
      width: auto;
    }
    .value-show{
      width: auto;
    }
  }

</style>
<style>
  .el-table .warning-row {
    background-color: antiquewhite;
  }
</style>
