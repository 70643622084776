import Layout from '@/layout'
import { checkCfsPermission } from '@/utils/permission'

const marketRouter = {
  path: '/market',
  component: Layout,
  alwaysShow: true,
  redirect: '/',
  name: 'market',
  hidden: false,
  meta: {
    title: 'navbar.mall_Manage.label',
    icon: 'chart'
  },
  children: [
    {
      path: 'order',
      component: () => import('@/views/market/order/order'),
      name: 'order',
      meta: { title: 'navbar.mall_Manage.order_Manage.label', noCache: false },
      hidden: checkCfsPermission('order')
    },
    {
      path: 'plan_to_eat_function_use_category',
      component: () => import('@/views/market/function_use_category/index'),
      name: 'function_use_category',
      meta: { title: 'MENU_BTN.CATEGORY_LIMITED.PLAN_TO_COOK', noCache: false, functionSetting: 'PLAN_TO_EAT_CATEGORIES' },
      // 每日訂餐-使用種類
      hidden: checkCfsPermission('DAILY_FUNCTION_CATEGORY_LIMIT')
    }
    // {
    //   path: 'plan_to_buy_function_use_category',
    //   component: () => import('@/views/market/function_use_category/index'),
    //   name: 'function_use_category',
    //   meta: { title: '採購單-使用種類', noCache: false, functionSetting: 'PLAN_TO_BUY_CATEGORIES' },
    //   hidden: checkCfsPermission('DAILY_FUNCTION_CATEGORY_LIMIT')
    // }
  ]
}
export default marketRouter
