var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-form",
              {
                staticClass: "search-form-inline",
                attrs: {
                  "label-suffix": ":",
                  "label-position": "left",
                  "hide-required-asterisk": "",
                  "label-width": "180px",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("order_Manage.orderNo") } },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.listQuery.orderNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "orderNo", $$v)
                                },
                                expression: "listQuery.orderNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Member Account") } },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.listQuery.loginName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "loginName", $$v)
                                },
                                expression: "listQuery.loginName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Payment status") } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { filterable: "", clearable: "" },
                                model: {
                                  value: _vm.listQuery.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "state", $$v)
                                  },
                                  expression: "listQuery.state",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.NEW"),
                                    value: "NEW",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.PAYMENT"),
                                    value: "PAYMENT",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.REFUND"),
                                    value: "REFUND",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.CANCEL"),
                                    value: "CANCEL",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Product Created By") } },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                readonly:
                                  _vm.checkPermission("SEARCH_ALL_ORDER"),
                              },
                              model: {
                                value: _vm.listQuery.productCreatedBy,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.listQuery,
                                    "productCreatedBy",
                                    $$v
                                  )
                                },
                                expression: "listQuery.productCreatedBy",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 12, md: 8, sm: 24, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("COMPONENTS.time") } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "45%" },
                              attrs: {
                                clearable: "",
                                type: "datetime",
                                placeholder: _vm.$t("COMPONENTS.beginTime"),
                                format: "yyyy-MM-dd HH:mm:ss",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                              },
                              model: {
                                value: _vm.listQuery.beginTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "beginTime", $$v)
                                },
                                expression: "listQuery.beginTime",
                              },
                            }),
                            _vm._v("\n                ~\n                "),
                            _c("el-date-picker", {
                              staticStyle: { width: "45%" },
                              attrs: {
                                clearable: "",
                                type: "datetime",
                                placeholder: _vm.$t("COMPONENTS.endTime"),
                                format: "yyyy-MM-dd HH:mm:ss",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                              },
                              model: {
                                value: _vm.listQuery.endTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "endTime", $$v)
                                },
                                expression: "listQuery.endTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("order.payment.type.title"),
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { filterable: "", clearable: "" },
                                model: {
                                  value: _vm.listQuery.orderType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "orderType", $$v)
                                  },
                                  expression: "listQuery.orderType",
                                },
                              },
                              _vm._l(_vm.orderTypeCodes, function (option) {
                                return _c("el-option", {
                                  key: option.value,
                                  attrs: {
                                    label: _vm.$t(option.labelKey),
                                    value: option.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("order_Manage.goodsType") },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { filterable: "", clearable: "" },
                                on: { change: _vm.changeProductType },
                                model: {
                                  value: _vm.listQuery.productType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "productType", $$v)
                                  },
                                  expression: "listQuery.productType",
                                },
                              },
                              _vm._l(_vm.productTypes, function (option) {
                                return _c("el-option", {
                                  key: option.value,
                                  attrs: {
                                    label: _vm.$t(option.labelKey),
                                    value: option.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.goodsVisible
                      ? _c(
                          "el-col",
                          { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t(_vm.productLabel) } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { filterable: "", clearable: "" },
                                    model: {
                                      value: _vm.listQuery.productId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "productId",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.productId",
                                    },
                                  },
                                  _vm._l(_vm.productList, function (product) {
                                    return _c("el-option", {
                                      key: product.value,
                                      attrs: {
                                        label: product.label,
                                        value: product.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-row", [
                  _c(
                    "div",
                    { staticStyle: { float: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { disabled: _vm.checkPermission("order") },
                          on: { click: _vm.handleExport },
                        },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.export")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            type: "primary",
                            disabled: _vm.checkPermission("order"),
                          },
                          on: { click: _vm.handleFilter },
                        },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.search")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.list,
            border: "",
            "row-key": "id",
            "row-class-name": _vm.tableRowClassName,
            "header-row-class-name": "table-header",
            "tooltip-effect": "dark",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: props.row.orderItems,
                                      border: true,
                                      size: "small",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "200px",
                                        label: _vm.$t("order_Manage.goodName"),
                                        prop: "goodName",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "200px",
                                        label: _vm.$t("order_Manage.price"),
                                        prop: "price",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "200px",
                                        label: _vm.$t("order_Manage.qty"),
                                        prop: "qty",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "200px",
                                        label: _vm.$t(
                                          "order.orderItem.type.title"
                                        ),
                                        prop: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      scope.row.shopGoodId ==
                                                        "-1"
                                                        ? _vm.$t(
                                                            "order.orderItem.type.plan"
                                                          )
                                                        : _vm.$t(
                                                            "order.orderItem.type.product"
                                                          )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "200px",
                                        label: _vm.$t("Created By"),
                                        prop: "productCreatedBy",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: _vm.$t(
                                          "order_Manage.attributes"
                                        ),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return _vm._l(
                                                scope.row.attributes,
                                                function (item) {
                                                  return _c(
                                                    "el-row",
                                                    {
                                                      key: item.id,
                                                      staticClass:
                                                        "attr-value-show",
                                                      attrs: { type: "flex" },
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        {
                                                          staticClass:
                                                            "attr-show",
                                                          attrs: { span: 12 },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title) +
                                                              " : "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        item.attributes,
                                                        function (value) {
                                                          return _c(
                                                            "el-col",
                                                            {
                                                              key: value.id,
                                                              staticClass:
                                                                "value-show",
                                                              attrs: {
                                                                span: 4,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  value.title
                                                                ) + "  "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                }
                                              )
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: _vm.$t("order_Manage.orderNo"),
              width: "200",
              prop: "orderNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("COMPONENTS.Date"),
              width: "100",
              prop: "date",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("COMPONENTS.time"),
              width: "100",
              prop: "time",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("COMPONENTS.sum"),
              width: "100",
              prop: "price",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("Payment status"),
              width: "150",
              prop: "state",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          scope.row.state == "NEW"
                            ? _vm.$t("COMPONENTS.NEW")
                            : scope.row.state == "PAYMENT"
                            ? _vm.$t("COMPONENTS.PAYMENT")
                            : scope.row.state == "REFUND"
                            ? _vm.$t("COMPONENTS.REFUND")
                            : _vm.$t("COMPONENTS.CANCEL")
                        ) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("order.payment.type.title"),
              width: "210",
              prop: "orderTypeCode",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t(scope.row.orderTypeLabel))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("Member Account"),
              width: "100",
              prop: "loginName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("order_Manage.buyer"),
              width: "150",
              prop: "consignee",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(scope.row.firstName) +
                            " " +
                            _vm.$t(scope.row.lastName)
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("order_Manage.buyerPhone"),
              width: "120",
              prop: "mobileNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: _vm.$t("Shipping address"),
              prop: "address",
              width: "400",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: _vm.$t("Shipping No"),
              prop: "shippingNo",
              width: "200",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: _vm.$t("Shipping status"),
              prop: "shippingStatus",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope &&
                    scope.row &&
                    scope.row.shippingStatus &&
                    scope.row.shippingStatus === "NOT_DELIVERED"
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("Not delivered")))])
                      : _vm._e(),
                    _vm._v(" "),
                    scope &&
                    scope.row &&
                    scope.row.shippingStatus &&
                    scope.row.shippingStatus === "SENDING"
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("Sending")))])
                      : _vm._e(),
                    _vm._v(" "),
                    scope &&
                    scope.row &&
                    scope.row.shippingStatus &&
                    scope.row.shippingStatus === "SENT"
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("Sent")))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: _vm.$t("Member order remark"),
              prop: "remark",
              width: "300",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: _vm.$t("Email"),
              prop: "email",
              width: "300",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: _vm.$t("Admin order remark"),
              prop: "adminRemark",
              width: "300",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("COMPONENTS.operation"),
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "mini", round: "" } },
                          [_c("em", { staticClass: "el-icon-more" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled: _vm.checkPermission("order.print"),
                                  icon: "el-icon-printer",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handlePrint(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("order_Manage.print")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    _vm.checkPermission("order.infomation"),
                                  icon: "el-icon-info",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleInfo(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.info")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled: _vm.permissionOrderComplate(
                                    scope.row
                                  ),
                                  icon: "el-icon-check",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleOrderComplate(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "order_Manage.action.payment.comfirm"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled: _vm.permissionRefund(scope.row),
                                  icon: "el-icon-sold-out",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleRefund(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("order_Manage.action.payment.refund")
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled: _vm.permissionOrderCancel(
                                    scope.row
                                  ),
                                  icon: "el-icon-close",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleOrderCancel(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("order_Manage.action.payment.cancel")
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: { icon: "el-icon-edit" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.editAdminRemark(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Admin order remark")))]
                            ),
                            _vm._v(" "),
                            scope.row.needShipping
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: { divided: "" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editShippingNo(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Shipping No")))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.updateShippingStatus(
                                              scope.row,
                                              "NOT_DELIVERED"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Not delivered")))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.updateShippingStatus(
                                              scope.row,
                                              "SENDING"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Sending")))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.updateShippingStatus(
                                              scope.row,
                                              "SENT"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Sent")))]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.orderTypeCode === "APPLE_IAP"
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      divided: "",
                                      disabled:
                                        _vm.permissionAppleIAPOrderCheck(
                                          scope.row
                                        ),
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleAppleIAPOrderCheck(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "appstore" },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "order_Manage.update_payment_status"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Admin order remark"),
            visible: _vm.adminRemarkDialog.drawerShow,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.adminRemarkDialog, "drawerShow", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "15px" } },
            [
              _c("el-input", {
                attrs: { rows: 4, type: "textarea" },
                model: {
                  value: _vm.adminRemarkDialog.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.adminRemarkDialog, "text", $$v)
                  },
                  expression: "adminRemarkDialog.text",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "15px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "10px" },
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelEditAdminRemark()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "10px" },
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.editAdminRemarkSubmit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Shipping No"),
            visible: _vm.shippingNoDialog.drawerShow,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.shippingNoDialog, "drawerShow", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "15px" } },
            [
              _c("el-input", {
                attrs: { type: "input" },
                model: {
                  value: _vm.shippingNoDialog.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.shippingNoDialog, "text", $$v)
                  },
                  expression: "shippingNoDialog.text",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "15px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "10px" },
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelEditShippingNo()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "10px" },
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.editShippingNoSubmit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("APPLE_IAP_DIALOG.TITLE"),
            visible: _vm.appleIAP.drawerShow,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.appleIAP, "drawerShow", $event)
            },
          },
        },
        [
          _c("span", { staticStyle: { "font-weight": "900" } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("APPLE_IAP_DIALOG.MSG1")) +
                "：\n        "
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("APPLE_IAP_DIALOG.MSG2")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticStyle: { "font-weight": "900" } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("APPLE_IAP_DIALOG.MSG3")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("APPLE_IAP_DIALOG.MSG4")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("APPLE_IAP_DIALOG.MSG5")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://reportaproblem.apple.com/",
                target: "_blank",
              },
            },
            [
              _c("span", { staticStyle: { color: "blue" } }, [
                _vm._v("https://reportaproblem.apple.com/"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("APPLE_IAP_DIALOG.MSG6")) +
                "：\n        "
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://appstoreconnect.apple.com/",
                target: "_blank",
              },
            },
            [
              _c("span", { staticStyle: { color: "blue" } }, [
                _vm._v("https://appstoreconnect.apple.com/"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("APPLE_IAP_DIALOG.MSG7")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.appleIAP.isCheck,
                callback: function ($$v) {
                  _vm.$set(_vm.appleIAP, "isCheck", $$v)
                },
                expression: "appleIAP.isCheck",
              },
            },
            [
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("APPLE_IAP_DIALOG.MSG8")) +
                    "\n          "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "15px" } },
            [
              _c("el-input", {
                attrs: { type: "input", placeholder: "e.g. MLV1B88G66" },
                model: {
                  value: _vm.appleIAP.appleOrderId,
                  callback: function ($$v) {
                    _vm.$set(_vm.appleIAP, "appleOrderId", $$v)
                  },
                  expression: "appleIAP.appleOrderId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "15px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "10px" },
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelAppleIAP()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "10px" },
                  attrs: {
                    disabled:
                      _vm.isNull(_vm.appleIAP.appleOrderId) ||
                      !_vm.appleIAP.isCheck,
                    type: "primary",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.restoreAppleOrderJob()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }