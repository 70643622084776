<template>
  <el-form
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
    :rules="rules"
    :model="ruleForm"
  >
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">{{
          ruleForm.id == null ? $t('COMPONENTS.add') : $t('COMPONENTS.edit')
        }}</el-col>
        <el-col :span="12">
          <el-button @click="resetForm(ruleForm)">{{$t('COMPONENTS.cancel')}}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            @click="submitForm()"
            :disabled='checkPermission("notice.edit")'
          >{{$t('COMPONENTS.release')}}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-card class="box-card">
      <el-form-item :label="$t('COMPONENTS.title')" prop="title">
        <el-input v-model="ruleForm.title" style="width: 400px" />
      </el-form-item>
      <el-form-item :label="$t('COMPONENTS.content')" prop="content">
        <!-- <quillEditor v-model="ruleForm.content" style="width: 400px" /> -->
        <div style="width: 80%">
          <Summernote :value="ruleForm.content" @change="changeContentInfo" height="150"/>
        </div>
      </el-form-item>
    </el-card>
  </el-form>
</template>

<script>
import { mbNoticeSave } from '@/api/noticeApi'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Sticky from '@/components/Sticky'
import { checkCfsPermission } from '@/utils/permission';
import Summernote from '@/components/Summernote' 
export default {
  name: 'NoticeSave',
  components: { quillEditor, Sticky,Summernote },
  data() {
    return {
      ruleForm: {
        id: '',
        title: '',
        content: ''
      },
      rules: {
        title: [{ required: true, message: this.$t('rule.Please-enter-title'), trigger: 'blur' }],
        content: [
          { required: true, message: this.$t('COMPONENTS.incontent'), trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getParams()
  },
  methods: {
    changeContentInfo(value){
      this.ruleForm.content = value
    },
    checkPermission(value){
      return checkCfsPermission(value)
    },
    submitForm(ruleForm) {
      const save = this.ruleForm
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          mbNoticeSave(save).then((response) => {
            this.$message.success('save success')
            this.$router.push({ path: '/spread/notice' })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$router.push({ path: '/spread/notice' })
    },
    getParams() {
      var title = this.$route.params.title
      var content = this.$route.params.content
      var id = this.$route.params.id
      this.ruleForm.title = title
      this.ruleForm.content = content
      this.ruleForm.id = id
    }
  }
}
</script>

<style>
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
</style>
