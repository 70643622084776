// lang/index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import store from '@/store'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import elementTwLocale from 'element-ui/lib/locale/lang/zh-TW'
import elementHkLocale from 'element-ui/lib/locale/lang/zh-TW'

Vue.use(VueI18n)

export const messages = {
  "en-US": {
    ...require('./en.json'),
    ...elementEnLocale
  },
  "zh-CN": {
    ...require('./zh_CH.json'),
    ...elementZhLocale
  },
  "zh-HK": {
    ...require('./zh_HK.json'),
    ...elementHkLocale
  },
  "zh-TW": {
    ...require('./zh_TW.json'),
    ...elementTwLocale
  }
}
export function getLanguage() {
  // const chooseLanguage = Cookies.get('language')
  // if (chooseLanguage) return chooseLanguage

  // // if has not choose language
  // const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  // const locales = Object.keys(messages)
  // for (const locale of locales) {
  //   if (language.indexOf(locale) > -1) {
  //     return locale
  //   }
  // }
  if(store.getters.languageCode && store.getters.languageCode.code){
    store.getters.languageCode.code = store.getters.languageCode.code.replace('_','-')
  }
  if(!store.getters.languageCode){
    return ''
  }
  return store.getters.languageCode.code
}


const i18n = new VueI18n({
  // set locale
  locale: getLanguage(),
  messages
})

export default i18n

