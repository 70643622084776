var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _vm.familyProgram.id
                ? _c(
                    "el-col",
                    { staticClass: "page-header", attrs: { span: 10 } },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                  )
                : _c(
                    "el-col",
                    { staticClass: "page-header", attrs: { span: 10 } },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                  ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _vm.oldStatus === "APPROVED" && _vm.schedule === "GOING"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.checkPermission("FAMILY.ADD"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.stopSold()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.endEarly")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.familyProgram || !_vm.familyProgram.id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setting()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.translate")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("FAMILY.ADD"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitBtn()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    {
                      ref: "temp",
                      attrs: {
                        model: _vm.familyProgram,
                        "label-width": "180px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name", label: _vm.$t("Admin Tag") } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { value: _vm.familyProgram.adminTag },
                            model: {
                              value: _vm.familyProgram.adminTag,
                              callback: function ($$v) {
                                _vm.$set(_vm.familyProgram, "adminTag", $$v)
                              },
                              expression: "familyProgram.adminTag",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("Admin.adminTag.tooltip"),
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-warning" })]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "name",
                            label: _vm.$t("Admin Remark"),
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { value: _vm.familyProgram.adminRemark },
                            model: {
                              value: _vm.familyProgram.adminRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.familyProgram, "adminRemark", $$v)
                              },
                              expression: "familyProgram.adminRemark",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("Admin.adminRemark.tooltip"),
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-warning" })]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "name",
                            label: _vm.$t("familyProgram.name"),
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "38%" },
                            attrs: { value: _vm.familyProgram.name },
                            model: {
                              value: _vm.familyProgram.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.familyProgram, "name", $$v)
                              },
                              expression: "familyProgram.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("COMPONENTS.time"),
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "dateRange" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "datetimerange",
                                          "range-separator": "To",
                                          "start-placeholder": "Start date",
                                          "end-placeholder": "End date",
                                          format: "yyyy-MM-dd HH:mm:ss",
                                          disabled:
                                            _vm.oldStatus === "APPROVED",
                                          "default-time": _vm.defaultTime,
                                        },
                                        model: {
                                          value: _vm.familyProgram.date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.familyProgram,
                                              "date",
                                              $$v
                                            )
                                          },
                                          expression: "familyProgram.date",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: { placement: "top" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "content",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "ACTIVITY_PAGE.NOTE.0"
                                                        )
                                                      )
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "ACTIVITY_PAGE.NOTE.1"
                                                        )
                                                      )
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "ACTIVITY_PAGE.NOTE.2"
                                                        )
                                                      )
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "ACTIVITY_PAGE.NOTE.3"
                                                        )
                                                      )
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "ACTIVITY_PAGE.NOTE.4"
                                                        )
                                                      )
                                                  ),
                                                  _c("br"),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-warning",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("productItem.status") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "408px" },
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.inselect"),
                                disabled: _vm.oldStatus === "APPROVED",
                              },
                              model: {
                                value: _vm.familyProgram.itemStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.familyProgram, "itemStatus", $$v)
                                },
                                expression: "familyProgram.itemStatus",
                              },
                            },
                            _vm._l(_vm.itemStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value,
                                  disabled: item.disabled,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.loaded
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("plan.description"),
                                prop: "description",
                              },
                            },
                            [
                              _c("Summernote", {
                                attrs: { value: _vm.familyProgram.description },
                                on: { change: _vm.changeDescription },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("familyProgram.card.table") },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", {
                                staticClass: "page-header",
                                attrs: { span: 24 },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "60vw" },
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.familyProgram.cards,
                                "header-row-class-name": "table-header",
                                size: "mini",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("Apple Product ID"),
                                  prop: "appleProductId",
                                  width: "200px",
                                  align: "left",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("familyProgram.card.num"),
                                  prop: "num",
                                  align: "left",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("familyProgram.card.price"),
                                  prop: "price",
                                  align: "left",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("familyProgram.card.amount"),
                                  prop: "amount",
                                  align: "left",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("COMPONENTS.operation"),
                                  fixed: "right",
                                  width: "180px",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                              disabled:
                                                _vm.oldStatus === "APPROVED",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEdit(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("COMPONENTS.edit"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        scope.$index === 0 ||
                                        scope.$index ===
                                          _vm.familyProgram.cards.length - 1
                                          ? _c(
                                              "el-button",
                                              {
                                                style: _vm.planConfigStyle,
                                                attrs: {
                                                  size: "mini",
                                                  disabled:
                                                    _vm.oldStatus ===
                                                    "APPROVED",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete(
                                                      scope.$index,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("COMPONENTS.delete")
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p"),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "60vw" },
                              attrs: {
                                disabled: _vm.oldStatus === "APPROVED",
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addCard()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                "custom-class": "dialog-class",
                                title: _vm.$t("plan.card.title"),
                                visible: _vm.dialogVisible,
                                width: "50%",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "temp",
                                  attrs: {
                                    model: _vm.card,
                                    "label-width": "180px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "appleProductId",
                                        label: _vm.$t("Apple Product ID"),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value: _vm.card.appleProductId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.card,
                                              "appleProductId",
                                              $$v
                                            )
                                          },
                                          expression: "card.appleProductId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("p"),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("familyProgram.card.num"),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          onkeyup:
                                            "this.value=this.value.replace(/\\D/g,'')",
                                          onafterpaste:
                                            "this.value=this.value.replace(/\\D/g,'')",
                                          disabled: !(
                                            _vm.card.sequence === 1 ||
                                            _vm.card.sequence ===
                                              _vm.familyProgram.cards.length ||
                                            !_vm.card.sequence
                                          ),
                                          type: "number",
                                          min: 0,
                                        },
                                        model: {
                                          value: _vm.card.num,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.card, "num", $$v)
                                          },
                                          expression: "card.num",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("p"),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "familyProgram.card.price"
                                        ),
                                        prop: "price",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "200px" },
                                          attrs: {
                                            "on-key-press":
                                              "if((event.keyCode<48 || event.keyCode>57) && event.keyCode!=46 || /\\.\\d\\d$/.test(value))event.returnValue=false",
                                            clearable: "",
                                            type: "number",
                                            min: 0,
                                            onkeyup:
                                              "this.value=this.value.replace(/\\D/g,'')",
                                            onafterpaste:
                                              "this.value=this.value.replace(/\\D/g,'')",
                                          },
                                          model: {
                                            value: _vm.card.price,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.card, "price", $$v)
                                            },
                                            expression: "card.price",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "prepend" }, [
                                            _vm._v("HK$"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("p"),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "amount",
                                        label: _vm.$t(
                                          "familyProgram.card.amount"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "200px" },
                                          attrs: {
                                            "on-key-press":
                                              "if((event.keyCode<48 || event.keyCode>57) && event.keyCode!=46 || /\\.\\d\\d$/.test(value))event.returnValue=false",
                                            clearable: "",
                                            type: "number",
                                            min: 0,
                                            onkeyup:
                                              "this.value=this.value.replace(/\\D/g,'')",
                                            onafterpaste:
                                              "this.value=this.value.replace(/\\D/g,'')",
                                          },
                                          model: {
                                            value: _vm.card.amount,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.card, "amount", $$v)
                                            },
                                            expression: "card.amount",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "prepend" }, [
                                            _vm._v("HK$"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.dialogVisible = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("COMPONENTS.return"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addNewCard()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "200px" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "translate-drawer",
          attrs: {
            title: _vm.$t("COMPONENTS.translate"),
            visible: _vm.drawer,
            size: "60%",
            "show-close": false,
            "modal-append-to-body": false,
            "append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
            opened: _vm.getIframe,
          },
        },
        [
          _vm.drawer
            ? _c("iframe", {
                ref: "iframe",
                staticClass: "iframe-class",
                attrs: { src: _vm.ifreamSrc, height: _vm.getClientHeight() },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }