var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 12 } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.title === "create"
                        ? _vm.$t("COMPONENTS.add")
                        : _vm.$t("COMPONENTS.edit")
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.oldStatus === "APPROVED" &&
                  _vm.checkSchedule() === "GOING"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.checkPermission("ACTIVITY.DELETE"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.stopSold()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.endEarly")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isNull(_vm.dataForm.id)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.checkPermission("ACTIVITY.DELETE"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteActivity(_vm.dataForm)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.delete")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkSavePermission(),
                      },
                      on: { click: _vm.saveData },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")) + "\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticClass: "search-form-inline",
          attrs: {
            model: _vm.dataForm,
            "status-icon": "",
            "label-position": "left",
            "label-width": "200px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Admin Tag"),
                                    prop: "adminTag",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "400px" },
                                    model: {
                                      value: _vm.dataForm.adminTag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "adminTag", $$v)
                                      },
                                      expression: "dataForm.adminTag",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t(
                                          "Admin.adminTag.tooltip"
                                        ),
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Admin Remark"),
                                    prop: "adminRemark",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "400px" },
                                    model: {
                                      value: _vm.dataForm.adminRemark,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "adminRemark",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.adminRemark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("productItem.shop"),
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                        disabled: _vm.oldStatus === "APPROVED",
                                      },
                                      model: {
                                        value: _vm.dataForm.shopId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "shopId", $$v)
                                        },
                                        expression: "dataForm.shopId",
                                      },
                                    },
                                    _vm._l(_vm.shopOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Activity Title"),
                                    required: "",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "400px" },
                                    model: {
                                      value: _vm.dataForm.activityTitle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "activityTitle",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.activityTitle",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: _vm.summernoteKey,
                                  attrs: {
                                    label: _vm.$t("Activity Description"),
                                  },
                                },
                                [
                                  _c("Summernote", {
                                    staticStyle: { width: "750px" },
                                    attrs: {
                                      value: _vm.dataForm.activityDesc,
                                      height: 150,
                                    },
                                    on: { change: _vm.changeDescription },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Activity Type"),
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                        disabled: _vm.oldStatus === "APPROVED",
                                      },
                                      model: {
                                        value: _vm.dataForm.activityType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "activityType",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.activityType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "NEW_REG",
                                          label: _vm.$t("NEW_REG.label"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.time"),
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "dateRange" } },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  type: "datetimerange",
                                                  "range-separator": "To",
                                                  "start-placeholder":
                                                    "Start date",
                                                  "end-placeholder": "End date",
                                                  format: "yyyy-MM-dd HH:mm:ss",
                                                  disabled:
                                                    _vm.oldStatus ===
                                                    "APPROVED",
                                                  "default-time":
                                                    _vm.defaultTime,
                                                },
                                                model: {
                                                  value: _vm.dataForm.date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "date",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "dataForm.date",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: { placement: "top" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "content",
                                                      fn: function () {
                                                        return [
                                                          _vm._v(
                                                            "\n\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "ACTIVITY_PAGE.NOTE.0"
                                                                )
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "ACTIVITY_PAGE.NOTE.1"
                                                                )
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "ACTIVITY_PAGE.NOTE.2"
                                                                )
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "ACTIVITY_PAGE.NOTE.3"
                                                                )
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "ACTIVITY_PAGE.NOTE.4"
                                                                )
                                                              )
                                                          ),
                                                          _c("br"),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Status"),
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "408px" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "COMPONENTS.inselect"
                                        ),
                                        disabled: _vm.oldStatus === "APPROVED",
                                      },
                                      model: {
                                        value: _vm.dataForm.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "status", $$v)
                                        },
                                        expression: "dataForm.status",
                                      },
                                    },
                                    _vm._l(_vm.statusOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                          disabled: item.disabled,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.dataForm.activityType === "NEW_REG"
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, xs: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Plan"),
                                        required: "",
                                      },
                                    },
                                    [
                                      _vm.oldStatus !== "APPROVED"
                                        ? _c(
                                            "div",
                                            [
                                              [
                                                _c("el-transfer", {
                                                  attrs: {
                                                    id: "activityPageTransfer",
                                                    "tag-list-unselected": "",
                                                    filterable: "",
                                                    titles: [
                                                      _vm.$t(
                                                        "tagList.unselected"
                                                      ),
                                                      _vm.$t(
                                                        "tagList.selected"
                                                      ),
                                                    ],
                                                    "filter-method":
                                                      _vm.filterMethod,
                                                    "filter-placeholder":
                                                      "Search here",
                                                    data: _vm.planOptions,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataForm.detailList,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dataForm,
                                                        "detailList",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dataForm.detailList",
                                                  },
                                                }),
                                              ],
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.oldStatus === "APPROVED"
                                        ? _c(
                                            "div",
                                            [
                                              _vm._l(
                                                _vm.planOptions,
                                                function (item, index) {
                                                  return [
                                                    _vm.dataForm.detailList.includes(
                                                      item.key
                                                    )
                                                      ? _c(
                                                          "li",
                                                          { key: index },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.label)
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }