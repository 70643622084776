<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="150px"
        >
          <el-row :gutter="20">
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('tagList.shop')">
                <el-select v-model="query.shop" :placeholder="$t('COMPONENTS.inselect')">
                  <el-option
                    v-for="item in temp.shopOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('tagList.tagName')">
                <el-input v-model="query.name" :placeholder="$t('COMPONENTS.incontent')" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('tagList.tagNum')">
                <el-input v-model="query.minNum" placeholder="" style="width:30%" />
                ~
                <el-input v-model="query.maxNum" placeholder="" style="width:30%" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-button :disabled="checkPermission('tag')" @click="loadData">{{ $t('COMPONENTS.search') }}</el-button>
            <el-button :disabled="checkPermission('tag.add')" @click="handleCreate">{{ $t('COMPONENTS.add') }}</el-button>
          </el-row>
        </el-form>
      </div>
    </transition>

    <el-table
      :key="tableKey"
      :data="list"
      tooltip-effect="dark"
      border
      fit
      highlight-current-row
      style="width: 100%;"
      header-row-class-name="table-header"
      size="mini"
      :max-height="$store.state.settings.tableMaxHeight"
      @selection-change="select => selectList = select"
      @sort-change="sortChange"
    >
      <el-table-column :label="$t('tagList.tagName')" prop="tagName" width="300" />
      <el-table-column :label="$t('tagList.tagNum')" prop="proNum" width="" />
      <el-table-column :label="$t('COMPONENTS.operation')" prop="action" lign="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="checkPermission('tag.edit')"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.edit') }}</el-button>
          <el-button
            size="mini"
            :disabled="checkPermission('tag.delete')"
            @click="handleDelete(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-col>
        <pagination
          v-show="query.total > 0"
          class="hidden-xs-only"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="query.total > 0"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="7"
          layout="pager, prev, next"
          @pagination="loadData"
        />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {
  toEdit,
  getTagList,
  deleteFromCfs
} from '@/api/tag'

import { shopList } from '@/api/productItem'
import { checkCfsPermission } from '@/utils/permission'
import { fetchLanguageList } from '@/api/languageApi'
import { inputSearch } from '@/api/salesItemDomainDataApi'
import waves from '@/directive/waves' // waves directive
// import { parseTime } from '@/utils'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import { getPageParm } from '@/utils/page-common'
export default {
  name: 'ComplexTable',
  components: { Pagination },
  directives: { waves },
  filters: {},
  data() {
    return {
      noChoosedMode: [],
      choosedMode: [],
      noChooseList: [],
      chooseList: [],
      tableKey: 0,
      list: null,
      selectList: [],
      listLoading: true,
      listQuery: {
        page: 0,
        size: 10,
        code: null,
        sort: null
      },
      temp: {
        id: '',
        num: '',
        version: 1,
        codeSubscriber: '',
        createdBy: '',
        createdDate: '',
        lastModifiedBy: '',
        lastModifiedDate: '',
        code: '',
        desc: '',
        valueLists: [],
        label: {
          en: '',
          zh_TW: ''
        },
        shopOptions: [],
        domainJson: '',
        domainObj: null,
        deleted: false,
        labelLanguage: [
          { code: '', value: '' }
        ]
      },
      language: [],
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: this.$t('common.editText'),
        create: this.$t('common.createText')
      },
      isShowSearchPanel: true,
      uploadFile: {
        fileList: [],
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false
      },
      pageStyle: {
        margin: '0px',
        padding: '5px'
      },
      query: {
        size: 10,
        page: 1,
        total: 100,
        name: '',
        minNum: null,
        maxNum: null,
        shop: ''
      }
    }
  },
  watch: {
    '$i18n.locale': function() {
      if (this.$refs['dataForm']) {
        this.$refs['dataForm'].fields.forEach(item => {
          if (item.validateState === 'error') {
            this.$refs['dataForm'].validateField(item.labelFor)
          }
        })
      }
    }
  },
  mounted() {
    this.getLanguageList()
    this.loadData()
    this.initData()
  },
  methods: {
    loadData() {
      var numReg = /^\d+(\.\d+)?$/
      var numRe = new RegExp(numReg)
      if (this.query.minNum !== null && this.query.minNum.length > 0) {
        if (!numRe.test(this.query.minNum)) {
          this.$message(this.$t('rule.Pleas-enter-the-number-of-goods'))
          return false
        }
      }

      if (this.query.maxNum !== null && this.query.maxNum.length > 0) {
        if (!numRe.test(this.query.maxNum)) {
          this.$message(this.$t('rule.Pleas-enter-the-number-of-goods'))
          return false
        }
      }

      var query = { ...this.query }
      var param = getPageParm(query)
      // searchList(param, query).then((resp) => {
      //   var data = resp.data
      //   this.query.total = resp.pageCount
      //   this.query.page = resp.pageNum
      //   this.query.size = resp.pageSize
      //   var resultData = [];
      //   if(data==null){
      //     this.list = null;
      //   }else{
      //     for(let i =0;i<data.length;i++){
      //           var d = data[i]
      //           resultData.push({
      //               tagName:d.tagName,
      //               proNum:d.proNum,
      //               id:d.id
      //           });
      //       }
      //     this.list = resultData;
      //   }
      // })

      getTagList(param, query).then(resp => {
        var data = resp.data
        this.query.total = resp.pageCount
        this.query.page = resp.pageNum
        this.query.size = resp.pageSize
        var resultData = []
        if (data === null) {
          this.list = null
        } else {
          for (let i = 0; i < data.length; i++) {
            var d = data[i]
            resultData.push({
              tagName: d.tagName,
              proNum: d.proNum,
              id: d.id
            })
          }
          this.list = resultData
        }
      })

      // var queryParam ={
      //     belongId:'DEFAULT',
      //     usageTypeCode:'tag'
      // }
      // getCfsTreeList(queryParam).then(res=>{
      //   console.log('tag tree ======>',res)
      //   var resultData = []
      //   if(res){
      //     res.data && res.data.map(item=>{
      //       resultData.push({
      //         tagName:item.treeItemTag.name,
      //         proNum:0,
      //         id:item.treeItemTag.id
      //       })
      //     })
      //   }
      //   this.list = resultData;
      // })
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    initData() {
      shopList().then((resp) => {
        var data = resp.data
        var resultData = []
        if (data !== null) {
          var shopStr = ''
          for (let i = 0; i < data.length; i++) {
            var d = data[i]
            if (i === 0) {
              shopStr = d.id
            } else {
              shopStr += ',' + d.id
            }
            resultData.push({
              value: d.id,
              label: d.name
            })
          }
          this.temp.shopAll = shopStr
        }
        this.temp.shopOptions = resultData
        this.query.shop = this.temp.shopOptions[0].value

        // var query = { ...this.query }
        // var param = getPageParm(query)
        // searchList(param, query).then((resp) => {
        //   var data = resp.data
        //   this.query.total = resp.pageCount
        //   this.query.page = resp.pageNum
        //   this.query.size = resp.pageSize
        //   var resultData = [];
        //   if(data==null){
        //     this.list = null;
        //   }else{
        //     for(let i =0;i<data.length;i++){
        //           var d = data[i]
        //           resultData.push({
        //               tagName:d.tagName,
        //               proNum:d.proNum,
        //               id:d.id
        //           });
        //       }
        //     this.list = resultData;
        //   }
        // })
        // var queryParam ={
        //   belongId:'DEFAULT',
        //   usageTypeCode:'tag'
        // }
        // getCfsTreeList(queryParam).then(res=>{
        //   console.log('tag tree ======>',res)
        //   var resultData = []
        //   if(res){
        //     res.data && res.data.map(item=>{
        //       resultData.push({
        //         tagName:item.treeItemTag.name,
        //         proNum:0,
        //         id:item.treeItemTag.id
        //       })
        //     })
        //   }
        //   this.list = resultData;
        // })
      })
    },
    handleEdit(index, row) {
      this.$router.push({ path: 'tag/edit', query: { attrId: row.id, tagName: row.tagName, shop: this.query.shop }})
    },
    handleCreate() {
      this.$router.push({ path: 'tag/create', query: { shop: this.query.shop }})
    },
    handleDelete(index, row) {
      this.$confirm(this.$t('COMPONENTS.delete_Title2'), this.$t('COMPONENTS.tip'), {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel')
      })
        .then(() => {
          // deleteTag(row.id).then(response => {
          deleteFromCfs(row.id).then(response => {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.delete_Success'),
              type: 'success'
            })

            // var query = { ...this.query }
            // var param = getPageParm(query)
            this.loadData()
            // searchList(param, query).then((resp) => {
            //   var data = resp.data
            //   this.query.total = resp.pageCount
            //   this.query.page = resp.pageNum
            //   this.query.size = resp.pageSize
            //   if (resp.data === null) {
            //     this.list = null
            //   } else {
            //     var resultData = []
            //     for (let i = 0; i < data.length; i++) {
            //       var d = data[i]
            //       resultData.push({
            //         tagName: d.tagName,
            //         proNum: d.proNum,
            //         id: d.id
            //       })
            //     }
            //     this.list = resultData
            //   }
            // })
          })
        })
    },
    getLanguageList() {
      fetchLanguageList().then(response => {
        this.language = response.data.items
      })
    },
    domainQuerySearchAsync(queryString, cb) {
      var key = { code: queryString }
      inputSearch(key).then(response => {
        var resultData = []
        for (let i = 0; i < response.content.length; i++) {
          var item = response.content[i]
          resultData.push({ value: item.code })
        }
        cb(resultData)
      })
    },
    handleFilter() {
      this.listQuery.page = 0
      this.getList()
    },
    sortChange(data) {
      const { prop, order } = data
      console.info('page data', data)
      var orderBy = order === 'ascending' ? 'asc' : 'desc'
      this.listQuery.sort = prop + ',' + orderBy
      this.handleFilter()
    },
    resetTemp() {
      this.temp = {
        id: '',
        name: '',
        version: 1,
        codeSubscriber: '',
        createdBy: '',
        createdDate: '',
        lastModifiedBy: '',
        lastModifiedDate: '',
        code: '',
        desc: '',
        valueLists: [],
        label: {
          en: '',
          zh_TW: ''
        },
        domainJson: '',
        domainObj: null,
        deleted: false,
        labelLanguage: [
          { code: '', value: '' }
        ]
      }
      this.uploadFile.fileList = []
    },
    getChoiceData(vid) {
      var params = {
        id: vid
      }
      toEdit(params).then(response => {
        var data = response
        var allPlist = data.allPlist
        var tag = data.tag
        var allNoChoose = []

        for (let i = 0; i < allPlist.length; i++) {
          const p = allPlist[i]
          allNoChoose.push({
            content: p.goodsName
          })
        }

        this.noChooseList = allNoChoose

        if (vid !== null) {
          this.temp = {
            id: tag.id,
            typeName: tag.name,
            urlName: tag.codeSubscriber
          }
          // this.temp.id = tag.id;
          // this.temp.typeName = tag.name;
          // this.temp.urlName = tag.codeSubscriber;
          var noChoosedPlist = data.noChoosedPlist
          var choosedPlist = data.choosedPlist

          var noChooseda = []
          var chooseda = []

          for (let i = 0; i < noChoosedPlist.length; i++) {
            const p = noChoosedPlist[i]
            noChooseda.push({
              content: p.goodsName
            })
          }

          for (let i = 0; i < choosedPlist.length; i++) {
            const p = choosedPlist[i]
            chooseda.push({
              content: p.goodsName
            })
          }
          this.noChooseList = noChooseda
          this.chooseList = chooseda
        }
      })
    },
    addPro() {
      for (let i = 0; i < this.noChoosedMode.length; i++) {
        const p = this.noChoosedMode[i]
        this.chooseList.push({
          content: p
        })
        this.noChooseList = this.noChooseList.filter(t => t.content !== p)
      }
      this.noChoosedMode = []
    },
    delPro() {
      for (let i = 0; i < this.choosedMode.length; i++) {
        const p = this.choosedMode[i]
        this.noChooseList.push({
          content: p
        })
        this.chooseList = this.chooseList.filter(t => t.content !== p)
      }
      this.choosedMode = []
    }
  }
}

</script>
