var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.randomKey, staticClass: "app-container" },
    [
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _vm.visible
              ? _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 24, md: 24, sm: 24, xs: 24 } },
                      [
                        _c(
                          "div",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.deleteAllCoupon },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.delete")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("ErgoAppFrame", {
        key: _vm.randomKey,
        attrs: {
          formkey: "Coupon",
          formView: "master",
          languageId: _vm.languageId,
          useShadowDom: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }