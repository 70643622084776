<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="header">{{ $t(headerTxt) }}&nbsp;</el-col>

        <el-col :span="12">
          <el-button @click="setting()">{{
            $t("COMPONENTS.translate")
          }}</el-button>
          <el-button @click="$router.back(-1)">{{
            $t("COMPONENTS.return")
          }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission('PAGE.EDIT')"
            @click="createData"
          >{{ $t("COMPONENTS.save") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form
      ref="dataForm"
      :rules="rules"
      :model="dataForm"
      status-icon
      label-position="left"
      label-width="120px"
      class="search-form-inline"
      label-suffix=":"
    >
      <el-row>
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('COMPONENTS.title')" prop="title">
                  <el-input v-model="dataForm.title" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Page Code')" prop="useageTypeCode">
                  <el-input v-model="dataForm.useageTypeCode" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('COMPONENTS.shopName')" prop="fromId">
                  <el-select
                    v-model="dataForm.fromId"
                    :placeholder="$t('rule.Please-select-shop')"
                  >
                    <el-option
                      v-for="item in shopList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('COMPONENTS.desc')" prop="description">
                  <el-input v-model="dataForm.description" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('COMPONENTS.state')" prop="status">
                  <el-radio v-model="dataForm.status" label="1">{{
                    $t("COMPONENTS.visible")
                  }}</el-radio>
                  <el-radio v-model="dataForm.status" label="0">{{
                    $t("COMPONENTS.invisible")
                  }}</el-radio>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- summernote -->
            <el-row :gutter="20" v-if="loaded">
              <el-col>
                <el-form-item
                  :label="$t('COMPONENTS.content')"
                  prop="contentInfo"
                >
                  <!-- <div id="summernote" style="width: 80%" /> -->
                  <Summernote :value="dataForm.contentInfo" @change="changeContentInfo" />
                </el-form-item>
                <br>
                <br>
                <br>
                <br>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
    <el-card class="card-box">
      <el-drawer
        class="translate-drawer"
        :title="$t('COMPONENTS.translate')"
        :visible.sync="drawer"
        size="60%"
        :show-close="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        destroy-on-close
        @opened="getIframe"
      >
        <iframe
          v-if="drawer"
          ref="iframe"
          :src="ifreamSrc"
          class="iframe-class"
          :height="getClientHeight()"
        />
      </el-drawer>
    </el-card>
  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { saveArticle, findArticleById } from '@/api/articleApi'
import { shopList } from '@/api/shop'
import { checkCfsPermission } from '@/utils/permission'
import Summernote from '@/components/Summernote' 

// 以下summernote import 開始
// import jQuery from 'jquery'
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'summernote'
// import 'summernote/dist/lang/summernote-zh-TW'
// import 'summernote/dist/summernote.css'
// summernote import 結束
import { getLanguage } from '../../lang'
import { API_CFS_SERVER_URL } from '@/Config'

export default {
  name: 'ProductAttributeCreate',
  components: {
    Sticky,
    Summernote
  },
  data() {
    var validateUseageTypeCode = (rule, value, callback) => {
      const hasSpace = value !== value.replace(/\s/g, '')
      if (value === '') {
        callback(new Error(this.$t('Page Code cannot be empty')))
      } else if (hasSpace) {
        callback(new Error(this.$t('Page Code cannot have space')))
      } else {
        callback()
      }
    }
    return {
      loaded:false,
      ifreamSrc: '',
      drawer: false,
      headerTxt: '',
      edit: true,
      shopList: [],
      id: null,
      mode: 'create',
      dataForm: {
        id: '',
        title: '',
        useageTypeCode: null,
        description: '',
        contentInfo: '',
        status: '1',
        fromId: 'NA'
      },
      rules: {
        title: [
          {
            required: true,
            message: this.$t('rule.Title-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        useageTypeCode: [
          {
            required: true,
            // message: this.$t('Page Code cannot be empty'),
            validator: validateUseageTypeCode,
            trigger: 'blur'
          }
        ],
        fromId: [
          {
            required: true,
            message: this.$t('Shop name cannot be empty'),
            trigger: 'blur'
          }
        ],
        contentInfo: [
          {
            required: true,
            message: this.$t('rule.Content-cannot-be-empty'),
            trigger: 'blur'
          }
        ]
        // status: [{ required: true, message: this.$t('rule.State-cannot-be-empty') , trigger: 'blur' }]
      }
    }
  },

  mounted() {
    this.loadData()
    this.headerTxt = this.$route.query.headerTxt
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {
    changeContentInfo(value) {
      this.dataForm.contentInfo = value;  
    },  
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    handleMessage(e) {
      console.log(e.data)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleDrawerClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleDrawerClose()
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleDrawerClose() {
      this.drawer = false
    },
    getIframe(e) {
      var defaultLang = getLanguage()
      var systemLanguageCodes = JSON.parse(localStorage.getItem('SYSTEM_LANGUAGE_CODES'))
      if (systemLanguageCodes && systemLanguageCodes.data && systemLanguageCodes.data.defaultLanguage) {
        defaultLang = systemLanguageCodes.data.defaultLanguage
      }
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      this.ifreamSrc =
        ifreamSrc +
        '?system=gallery&module=admin&function=productItem&isIframe=true&language=' +
        defaultLang + '&sytemLanguage=' + getLanguage() + '&no=' + Math.ceil(Math.random() * 100)
      var data = [
        { name: this.$t('COMPONENTS.desc'), key: this.dataForm.id + '.dataForm.contentInfo', type: 'summernote', value: this.dataForm.contentInfo }
      ]

      console.log('iframe 对象', this.$refs.iframe)
      // setTimeout(() => {

      //
      // }, 1000);
      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
      console.log('iframe 对象', this.$refs.iframe)
    },
    setting() {
      if (this.width > 0) {
        return
      }
      this.drawer = true
    },
    initSummernote() {
      const _this = this
      const contentInfo = _this.dataForm.contentInfo

      jQuery('#summernote').summernote('destroy')
      jQuery('#summernote').html(contentInfo)
      jQuery('#summernote').summernote({
        height: 350,
        codemirror: {
          mode: 'text/html',
          htmlMode: true,
          lineNumbers: true,
          theme: 'monokai'
        },
        callbacks: {
          onChange: function(contents, $editable) {
            _this.dataForm.contentInfo = contents
          }
        }
      })
    },

    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadData() {
      let mode = 'ADD'
      if (this.$route.query.id) {
        mode = 'EDIT'
      }

      if (mode === 'EDIT') {
        this.id = this.$route.query.id
        findArticleById(this.id).then((res) => {
          this.dataForm = res.data
          this.loaded = true;
          // this.initSummernote()
        })
      } else {
        this.loaded = true;
        // this.initSummernote()
      }

      shopList().then((res) => {
        const shopList = []

        shopList.push({
          id: 'NA',
          name: 'NA'
        })

        shopList.push(...res.data)

        this.shopList = shopList
      })
    },
    createData() {
      var article = this.dataForm

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          saveArticle(article).then((res) => {
            if (res.returnType === 'SUCCESS') {
              this.$message.success(this.$t('COMPONENTS.save_Success'))
              this.$router.back(-1)
            } else {
              const errorCode = res.msg
              if (errorCode === 'USE_TYPE_CODE_EXISTS') {
                const errorMsg = this.$t('Page code is exists in this shop')
                this.$message.error(errorMsg)
              } else {
                this.$message.error(res.msg)
              }
            }
          }).catch(() => {
            this.$message.error(this.$t('COMPONENTS.save_Filad'))
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}

.card-box {
  margin: 0.5rem 0.5rem 0;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}

// .component {
//   margin: 10px;
// }
// .tree-box {
//   margin: 15px 0 100px 55px;
//   border: #a6e0d8 solid 2px;
//   padding: 15px;
// }
// .form-item-head {
//   margin-bottom: 8px;
//   font-size: 12px;
// }
// /deep/.hide .el-upload--picture-card {
//   display: none;
// }
// /deep/.avatar-uploader .el-upload {
//   border: 1px dashed #d9d9d9;
//   border-radius: 6px;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
// }
// /deep/.avatar-uploader .el-upload:hover {
//   border-color: #409eff;
// }
// /deep/.avatar-uploader-icon {
//   font-size: 28px;
//   color: #8c939d;
//   width: 178px;
//   height: 178px;
//   line-height: 178px;
//   text-align: center;
// }
// .avatar {
//   width: 178px;
//   height: 178px;
//   display: block;
// }

.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}
.iframe-class {
  width: 100%;
  border: 0px;
}
/deep/ .el-drawer .el-drawer__header{
  background-color: #2ea6f1 !important;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
/deep/ .el-drawer .el-drawer__header :first-child{
  margin-bottom: 18px;
}
/deep/ .el-drawer .el-drawer__body{
  margin-top: -22px;
  overflow: hidden;
}
</style>
