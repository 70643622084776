import Layout from '@/layout'
import { checkCfsPermission } from '@/utils/permission'

function isMemberFunctionHide() {
  let hide = true

  if (!checkCfsPermission('MEMBER.SEARCH_REG_SELF')) {
    hide = false
  }
  if (!checkCfsPermission('MEMBER.SEARCH_OWN_CREATE')) {
    hide = false
  }
  if (!checkCfsPermission('MEMBER.SEARCH_OWN_PARTY_CREATE')) {
    hide = false
  }
  if (!checkCfsPermission('MEMBER.SEARCH_ALL')) {
    hide = false
  }

  return hide
}

const userRouter = {
  path: '/user',
  component: Layout,
  redirect: '/user/member',
  alwaysShow: true,
  name: 'user',
  meta: {
    title: 'navbar.user_Manage.label',
    icon: 'table'
  },
  hidden: false,
  children: [

    {
      path: 'shopPic',
      component: () => import('@/views/shopPic/shopPic'),
      name: 'shopPic',
      hidden: checkCfsPermission('shopPicture'),
      meta: { title: 'navbar.user_Manage.shopPic.label' }
    },
    {
      path: 'shopPic/addShopPic',
      component: () => import('@/views/shopPic/addShopPic'),
      name: 'addShopPic',
      meta: {
        title: 'shopPic.addShopPic.label',
        noCache: false
      },
      hidden: true
    },
    {
      path: 'shopPic/shopPicEdit',
      component: () => import('@/views/shopPic/shopPicEdit'),
      name: 'shopPicEdit',
      meta: {
        title: 'shopPic.shopPicEdit.label',
        noCache: false
      },
      hidden: true
    },

    {
      path: 'member',
      component: () => import('@/views/member/member'),
      name: 'member',
      hidden: isMemberFunctionHide(),
      meta: { title: 'navbar.user_Manage.member.label' }
    },
    {
      path: 'member/edit',
      component: () => import('@/views/member/memberEdit'),
      name: 'memberEdit',
      meta: {
        title: 'Edit Member',
        noCache: false
      },
      hidden: true
    },
    {
      path: 'member/create',
      component: () => import('@/views/member/memberEdit'),
      name: 'memberEdit',
      meta: {
        title: 'Add New Member',
        noCache: false
      },
      hidden: true
    },

    {
      path: 'deliveryAddress',
      component: () => import('@/views/table/dynamic-table/index'),
      name: 'deliveryAddress',
      hidden: checkCfsPermission('deliveryAddress'),
      meta: { title: 'navbar.user_Manage.deliveryAddress.label' }
    },
    {
      path: 'comment',
      component: () => import('@/views/comment/comment'),
      name: 'comment',
      hidden: checkCfsPermission('comment'),
      meta: { title: 'navbar.user_Manage.comment.label' }
    },
    {
      path: 'comment/edit',
      component: () => import('@/views/comment/commentEdit'),
      name: 'commentEdit',
      meta: {
        title: 'comment.commentEdit.label',
        noCache: false
      },
      hidden: true
    },
    {
      path: 'memberCollection',
      component: () => import('@/views/table/dynamic-table/index'),
      name: 'memberCollection',
      hidden: checkCfsPermission('memberCollection'),
      meta: { title: 'navbar.user_Manage.memberCollection.label' }
    },
    {
      path: 'memberFootprints',
      component: () => import('@/views/table/dynamic-table/index'),
      name: 'memberFootprints',
      hidden: checkCfsPermission('memberFootprints'),
      meta: { title: 'navbar.user_Manage.memberFootprints.label' }
    },
    {
      path: 'lookBack',
      component: () => import('@/views/table/dynamic-table/index'),
      name: 'lookBack',
      hidden: checkCfsPermission('memberFootprints'),
      meta: { title: 'navbar.user_Manage.lookBack.label' }
    }
  ]
}
export default userRouter
