import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'

export function treeList(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/categoryTrees',
    method: 'get',
    data: query
  })
}

export function deleteByItemParentId(itemId) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/deleteByItemParentId/' + itemId,
    method: 'delete'
  })
}

export function deleteByItemParentIdFormCfs(itemId) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/deleteByItemParentIdFormCfs/' + itemId,
    method: 'delete'
  })
}

export function treeItemSave(data) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/treeItemSave',
    method: 'post',
    data: data
  })
}
