var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 10 } },
                [
                  _vm._v(
                    _vm._s(_vm.$t("navbar.mall_Manage.daily_Manage.label"))
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("plan.add"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitBtn()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { offset: 1, span: 24 } }, [
            _c("br"),
            _vm._v(" "),
            _c(
              "span",
              { staticStyle: { "font-size": "16px", "font-weight": "700" } },
              [_vm._v(_vm._s(_vm.$t("Category")) + " :")]
            ),
            _vm._v("              \n      "),
            _c(
              "span",
              { staticStyle: { color: "gray", "font-size": "10px" } },
              [
                _vm._v(
                  "\n        (*" +
                    _vm._s(_vm.$t("daily_manage.tips")) +
                    ": " +
                    _vm._s(_vm.$t("daily_manage.tips_content")) +
                    ")\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { model: _vm.editingRecord, "label-width": "200px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { offset: 1, xl: 24 } },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      "node-key": "id",
                      props: _vm.props,
                      data: _vm.categories,
                      "default-checked-keys": _vm.defaultIds,
                      "check-strictly": true,
                      "default-expand-all": true,
                      "show-checkbox": "",
                    },
                    on: { check: _vm.handleCheckChange },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }