<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <!-- <template v-if="device!=='mobile'">
        <search id="header-search" class="right-menu-item" />

        <error-log class="errLog-container right-menu-item hover-effect" />

        <screenfull id="screenfull" class="right-menu-item hover-effect" />

        <el-tooltip content="Global Size" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>

      </template> -->

      <!-- <div>
        <el-select  name="code" placeholder="" clearable>
          <el-option v-for="item in language" :key="item.key" :label="item.label" :value="item.code" />
        </el-select>
      </div> -->
      <!-- <el-button class="right-menu-item hover-effect" @click="gotoSecurity">Security</el-button> -->

      <el-dropdown class="right-menu-item hover-effect" trigger="click">

        <div>
          <el-button round>
            {{ $store.getters.languageCode && $store.getters.languageCode.label }}
          </el-button>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in language" :key="index" @click.native="halderLanguage(item)">
            {{ item.label }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar" alt="">
          <span>{{ loginUser }}</span>
          <em class="el-icon-caret-bottom" />
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :disabled="hideCfsBtn" @click.native="gotoSecurity">
            <span style="display:block;">{{ $t('security.management') }}</span>
          </el-dropdown-item>

          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">{{ $t('loginOut') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
    <div class="right-menu"><span style="font-size:12px;color:#A0A0A0">Version:&nbsp;{{ VERSION_NO }}</span></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { logout } from '@/service/AuthService'
import { fetchLanguageList } from '@/api/languageApi'
import { API_CFS_SERVER_URL, VERSION_NO } from '@/Config'

export default {
  components: {
    Breadcrumb,
    Hamburger
  //  ErrorLog,
  //  Screenfull,
  //  SizeSelect,
  //  Search
  },
  inject: ['reload'],
  data() {
    return {
      language: [],
      VERSION_NO: VERSION_NO,
      hideCfsBtn: true,
      loginUser: null
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device'
    ])
  },

  mounted() {
    const userName = localStorage.getItem('user_profile')
    if (userName === 'admin') {
      this.hideCfsBtn = false
    }
    this.loginUser = userName

    this.getLanguageData()
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    gotoSecurity() {
      var partyCompanyName = localStorage.getItem('partyCompanyName')
      window.open(API_CFS_SERVER_URL + '?system=gallery&&partyCompanyName=' + partyCompanyName)
    },
    logout() {
      logout()
    },
    getLanguageData() {
      console.log('-------languageCode-----------', this.$store.getters.languageCode)
      fetchLanguageList().then(response => {
        localStorage.setItem('SYSTEM_LANGUAGE_CODES', JSON.stringify(response))
        if (response) {
          const { data = {}} = response
          data.supportLanguageList && data.supportLanguageList.forEach(element => {
            element.code = element.language
            element.label = element.title
            if (element.code === data.defaultLanguage) {
              if (!this.$store.getters.languageCode) {
                this.$i18n.locale = element.code
                this.$store.dispatch('app/setLangugeCode', { label: element.title, code: element.code })
              }
            }
          })
          this.language = response.data.supportLanguageList
        }
      })
    },
    halderLanguage(item) {
      this.$i18n.locale = item.code
      localStorage.setItem('languageCode', item.code)
      document.title = this.$t(this.$route.meta.title)
      this.$store.dispatch('app/setLangugeCode', item)
      localStorage.removeItem('SFAF_language_data')
      this.$router.go(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;
    color: #FFFFFF;
    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        span {
          position: relative;
          top: -15px;
        }

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 15px;
          font-size: 12px;
        }

        // span {
        //   margin:0 auto;
        // }
      }
    }
  }
}
</style>
