<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div class="app-container">
    <iframe
      ref="categoryIfream"
      :src="categoryIfreamSrc"
      class="iframe-class"
      :height="getClientHeight()"
    />
    <div class="old-container">
      <transition name="el-zoom-in-top">
        <div class="filter-container">
          <el-form
            class="search-form-inline"
            label-suffix=":"
            label-position="left"
            hide-required-asterisk
            label-width="75px"
          >
            <el-row class="title-top">
              <el-col class="title-add">
                <el-col>
                  <el-button class="btn" :disabled="checkPermission('category.add')" @click="showCreate()">{{ $t('COMPONENTS.add') }}</el-button>
                </el-col>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </transition>
      <el-row class="category-list-box">
        <el-col :span="8" style="float: right">
          <el-input
            id="search-input"
            v-model="name"
            :placeholder="$t('category.Quick-search-item-by-word')"
          >
            <i
              id="search-i"
              slot="suffix"
              class="el-input__icon el-icon-search"
              @click="CategoryItem"
            />
          </el-input>
        </el-col>
        <el-col :span="16" class="category-list-relationship-box">
          <draggable
            v-for="(categoryElement, elementIndex) in categoryElementList"
            :key="elementIndex"
            v-model="categoryElement.dataList"
            class="list-group"
            v-bind="dragOptions"
            :move="onMove"
            @start="isDragging = true"
            @end="isDragging = false"
            @add="onAdd"
          >
            <transition-group type="transition" name="flip-list">
              <li
                v-for="(category, index) in categoryElement.dataList"
                :key="category.itemId"
                class="list-group-item"
                @click="onAppend(elementIndex, category, index)"
              >
                <i
                  :class="category.fixed ? 'el-icon-lock' : 'el-icon-s-unfold'"
                  aria-hidden="true"
                />
                <span class="sequence">{{ index + 1 }}</span>
                <span class="name">{{ category.name }}</span>
                <div style="width: 100%; position: relative">
                  <el-button
                    class="append"
                    size="mini"
                    :disabled="checkPermission('category')"
                    @click="onAppend(elementIndex, category, index)"
                  >{{ $t('COMPONENTS.unfold') }}</el-button>
                  <!-- <el-button
                    class="append"
                    size="mini"
                    @click="delParent(category, index, elementIndex)"
                    :disabled='checkPermission("category.delete")'
                  >{{ $t('COMPONENTS.delete') }}</el-button> -->
                  <span
                    :class="
                      categoryElementList[elementIndex].dataList[index].isTree
                        ? 'el-icon-right'
                        : 1
                    "
                    style="float: right"
                  />
                </div>
              </li>
            </transition-group>
          </draggable>
        </el-col>
        <el-col :span="8" class="category-stateless-box">
          <draggable
            v-model="categoryNameAndCountList"
            class="list-group"
            v-bind="dragOptions"
            :move="onMove"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <transition-group type="transition" name="flip-list">
              <li
                v-for="( category, index) in categoryNameAndCountList"
                :key="category.itemId"
                class="list-group-item"
              >
                <i
                  :class="category.fixed ? 'el-icon-lock' : 'el-icon-s-unfold'"
                  aria-hidden="true"
                  @click="category.fixed = !category.fixed"
                />
                <span class="sequence">{{ index + 1 }}</span>
                <span class="name">{{ category.name }}</span>
                <span class="name">({{ category.num }})</span>
                <div style="width: 180px">
                  <!-- <el-button
                    size="mini"
                    class="append"
                    @click="dltId(category)"
                    :disabled='checkPermission("category.delete")'
                  >{{ $t('COMPONENTS.delete') }}</el-button> -->
                  <el-button
                    size="mini"
                    class="append"
                    :disabled="checkPermission('category.edit')"
                    @click="showEdit(category)"
                  >{{ $t('COMPONENTS.edit') }}</el-button>
                  <el-button
                    size="mini"
                    class="append"
                    :disabled="checkPermission('category.copy')"
                    @click="showCopy(category)"
                  >{{ $t('COMPONENTS.copy') }}</el-button>
                </div>
              </li>
            </transition-group>
          </draggable>
        </el-col>
      </el-row>

      <el-dialog :title="$t('category.item.title')" :visible.sync="dialogFormVisible" width="70%">
        <el-form>
          <el-form-item :label="$t('category.item.name')">
            <el-input v-model="map.name" class="text-name" />
          </el-form-item>
          <!-- <el-form-item label="Visible">
            <el-radio-group v-model="map.isVisible">
              <el-radio :label="1">YES</el-radio>
              <el-radio :label="0">NO</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-row>
            <el-col>
              <el-form-item
                :label="$t('category.item.mainImg')"
                prop="mainImgUrl"
              >
                <el-upload
                  action="#"
                  :http-request="uploadMainImages"
                  :file-list="map.mainBannerUrl"
                  accept=".jpg,.jpeg,.png,.gif"
                  class="avatar-uploader"
                  :show-file-list="false"
                >
                  <img v-if="map.mainImgUrl" :src="map.mainImgUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item :label="$t('category.item.product')">
            <el-row />
            <el-row style="text-align: center">
              <el-transfer
                v-model="map.parentId"
                style="text-align: left; display: inline-block"
                filterable
                :filter-placeholder="$t('rule.productName')"
                :titles="[$t('COMPONENTS.Not-choose'), $t('COMPONENTS.Choosed')]"
                :format="{
                  noChecked: '${total}',
                  hasChecked: '${checked}/${total}',
                }"
                :data="goodsList"
              />
            </el-row>
          </el-form-item>
        </el-form>
        <el-dialog
          width="60%"
          :title="$t('COMPONENTS.translate')"
          custom-class="dialog-class"
          :visible.sync="innerVisible"
          append-to-body
          @opened="getIframe"
        >
          <iframe
            ref="iframe"
            :src="ifreamSrc"
            class="iframe-class"
            height="100%"
          />
        </el-dialog>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button type="primary"@click="setting()">{{ $t('COMPONENTS.translate') }}</el-button> -->
          <el-button @click="dialogFormVisible = false">{{ $t('COMPONENTS.cancel') }}</el-button>
          <el-button type="primary" @click="categoryRelation()">{{ $t('COMPONENTS.sure') }}</el-button>
        </div>
      </el-dialog>

    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mgProductGoodsList } from '@/api/mgProductGoodsApi'
import { listParent, mgProductGoodsAddIntoCfs } from '@/api/mgShopCategoryRelationApi'
import { API_CFS_SERVER_URL } from '@/Config'
import {
  categoryListByCfs,
  categoryListCountByCfs,
  treeByParentByCfs,
  deleteByIdFromCfs
} from '@/api/mgMenuCategoryApi'
import { treeItemSave } from '@/api/mgDomainTreeItemApi'
import { getLanguage } from '../../lang'
import { checkCfsPermission } from '@/utils/permission'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import { uploadFileIntoCfs } from '@/api/cfsApi/uploadFileApi'
export default {
  name: 'NewCategory',
  components: { draggable },
  data() {
    return {
      mainImgUrl: '',
      mainBannerUrl: [],
      ifreamSrc: '',
      categoryIfreamSrc: '',
      outerVisible: false,
      innerVisible: false,
      XY: [],
      dialogTitle: 'Creat category item',
      idCard: true,
      name: '',
      category: {
        itemId: ''
      },
      categoryElementList: [],
      editable: true,
      isDragging: false,
      delayedDragging: false,
      categoryNameAndCountList: [],
      dialogFormVisible: false,
      draggedData: {},
      relatedId: [],
      categoryIndex: '',
      maps: {
        parentId: '',
        dataList: []
      },
      map: {
        name: '',
        isVisible: 1,
        id: '',
        parentId: [],
        mainImgUrl: '',
        mainBannerUrl: []
      },
      goodsList: [],
      saveOreditOrcopy: 0,
      parentId: null,
      treeId: ''
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost'
      }
    },
    dragOption() {
      return {
        animation: 0,
        group: 'item',
        disabled: !this.editable,
        ghostClass: 'ghost'
      }
    },
    listString() {
      return JSON.stringify(this.maps, null, 2)
    },
    list2String() {
      return JSON.stringify(this.list2, null, 2)
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true
        return
      }
      this.$nextTick(() => {
        this.delayedDragging = false
      })
    }
  },
  created() {
    this.ParentTree()
    this.CategoryItem()
    this.ProductGoods()
  },
  mounted() {
    // this.loadData();
    this.openCategoryIframe()
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {
    openCategoryIframe() {
      var categoryIfreamSrc = API_CFS_SERVER_URL + '/tree/treeItemDuplicatePage'
      this.categoryIfreamSrc =
        categoryIfreamSrc += '?userId=DEFAULT&usageTypeCode=category&sytemLanguage=' + getLanguage()
      var data = [

      ]
      if (this.$refs.categoryIfream.attachEvent) {
        this.$refs.categoryIfream.attachEvent('onload', () => {
          this.$refs.categoryIfream.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.categoryIfream.onload = () => {
          this.$refs.categoryIfream.contentWindow.postMessage(data, '*')
        }
      }
    },
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    uploadMainImages(file) {
      var newFile = file.file
      console.log('ffffffffffffff', newFile)
      var formData = new FormData()
      formData.append('files', newFile)
      uploadFileIntoCfs(formData).then((res) => {
        console.log('upload File result==>', res)
        var newMainBannerUrl = []
        newMainBannerUrl.push({
          id: res[0].id,
          name: res[0].url,
          url: getStaticSourcePath(res[0].url, 'cfsFile')
        })
        this.map.mainBannerUrl = newMainBannerUrl
        this.map.mainImgUrl = getStaticSourcePath(res[0].url, 'cfsFile')
        console.log('======ggggggggg=========>', this.map.mainBannerUrl)
      })
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    handleMessage(e) {
      console.log(e.data)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleClose()
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleClose() {
      this.innerVisible = false
    },
    getIframe() {
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      this.ifreamSrc =
        ifreamSrc + '?system=gallery&module=mobile&function=category&isIframe=true&language=' + getLanguage() + '&sytemLanguage=' + getLanguage()
      var data = [
        { name: this.$t('category.item.name'), key: this.map.id + '.categoryName', type: 'input', value: this.map.name }
      ]
      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
    },
    setting() {
      if (this.width > 0) {
        return
      }
      this.innerVisible = true
    },
    onAdd({ to, from, item, clone, oldIndex, newIndex }) {
      console.log(this.XY)
      console.log(to)
      console.log(from)
      console.log(this.draggedData)
      console.log(this.relatedId)
      console.log(this.categoryIndex)
      for (let i = 0; i < this.relatedId.length; i++) {
        if (this.relatedId[i].itemId === this.draggedData.itemId) {
          this.$message.error(this.$t('category.forbid-repeat'))
          var temp = this.categoryElementList[this.categoryIndex].dataList.splice(newIndex, 1)
          console.log('----temp---', temp)
          if (this.draggedData.id) {
            console.log(this.draggedData.id)
            this.categoryElementList[this.XY[0]].dataList.splice(this.XY[1], 0, temp[0])
          } else {
            this.CategoryItem()
          }
          return false
        }
      }
      for (var j = this.categoryIndex; j > 0; j--) {
        console.log(this.categoryElementList[j].parentId, this.draggedData.itemId)
        for (var i = 0; i < this.categoryElementList[j - 1].dataList.length; i++) {
          if (
            this.categoryElementList[j].parentId ===
              this.categoryElementList[j - 1].dataList[i].id &&
            this.categoryElementList[j - 1].dataList[i].itemId === this.draggedData.itemId
          ) {
            this.$message.error(this.$t('category.this-category-on-this-path'))
            var tempData = this.categoryElementList[this.categoryIndex].dataList.splice(newIndex, 1)
            if (this.draggedData.id) {
              console.log(this.draggedData.id)
              this.categoryElementList[this.XY[0]].dataList.splice(this.XY[1], 0, tempData[0])
            } else {
              this.CategoryItem()
            }
            return false
          }
        }
      }
      var parentId = ''
      if (this.categoryIndex > 0) {
        parentId = this.categoryElementList[this.categoryIndex].parentId
      }
      const itemId = this.draggedData.itemId
      const isVisible = this.draggedData.isVisible
      const name = this.draggedData.name
      const id = this.draggedData.id
      const treeId = this.treeId
      const data = {
        id: id,
        itemId: itemId,
        parentId: parentId,
        name: name,
        isVisible: isVisible,
        treeId: treeId
      }
      treeItemSave(data).then((response) => {
        if (response) {
          console.log('savaresponse', response)
          this.$notify({
            title: this.$t('COMPONENTS.success'),
            message: this.$t('COMPONENTS.save_Success'),
            type: 'success'
          })
          response.data.fixed = false
          response.data.order = newIndex + 1
          this.categoryElementList[this.categoryIndex].dataList.splice(
            newIndex,
            1,
            response.data
          )
          if (!this.draggedData.id) {
            this.CategoryItem()
          }
        }
      }).catch(() => {

      })
    },
    ParentTree() {
      // categoryList().then((response) => {
      categoryListByCfs().then((response) => {
        console.log('response', response)
        this.maps = response.data
        this.treeId = response.data.itemParentId
        const parentId = response.data.itemParentId
        const dataList = response.data.dataList.map((item, index) => {
          return { ...item, order: index + 1, fixed: false }
        })
        console.log('输出', dataList)
        this.categoryElementList.push({ parentId, dataList })
        console.log('elementList---', this.categoryElementList)
      })
    },
    CategoryItem() {
      const name = this.name
      // categoryListCount(name).then((response) => {
      categoryListCountByCfs(name).then((response) => {
        this.categoryNameAndCountList = response.data.map((item, index) => {
          return { ...item, order: index + 1, fixed: false }
        })
        // this.categoryNameAndCountList.push({ dataList })
      })
      console.log('this.categoryNameAndCountList', this.categoryNameAndCountList)
    },
    ProductGoods() {
      mgProductGoodsList().then((response) => {
        console.log(response.data)
        response.data.forEach((element) => {
          this.goodsList.push({
            key: element.id,
            label: element.name
          })
        })
      })
    },
    orderList() {
      this.list = this.list.sort((one, two) => {
        return one.order - two.order
      })
    },
    onMove(e) {
      console.log(e)
      this.relatedId = e.relatedContext.list
      this.draggedData = e.draggedContext.element
      for (var i = 0; i < this.categoryElementList.length; i++) {
        if (this.categoryElementList[i].dataList === e.relatedContext.list) {
          console.log(this.categoryElementList[i].dataList, e.relatedContext.list)
          this.categoryIndex = i
          console.log(this.categoryIndex)
        }
        for (var j = 0; j < this.categoryElementList[i].dataList.length; j++) {
          if (this.draggedData.id && this.categoryElementList[i].dataList[j].id === this.draggedData.id && this.categoryElementList[i].dataList[j].itemParentId === this.draggedData.itemParentId) {
            this.XY = [i, j]
            break
          }
        }
      }
      console.log(e.draggedContext)
      const relatedElement = e.relatedContext.element
      const draggedElement = e.draggedContext.element
      console.log('relatedContext, draggedContext', e.relatedContext, e.draggedContext)
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    },
    onAppend(elementIndex, category, categoryIndex) {
      console.log('category---', category)

      this.parentId = category.id
      console.log('展开后的Id', this.parentId)
      const itemParentId = category.id
      const treeId = category.treeId
      const parentId = category.id
      for (var i = 0; i < this.categoryElementList[elementIndex].dataList.length; i++) {
        if (i === categoryIndex) {
          this.categoryElementList[elementIndex].dataList[i].isTree = true
          this.categoryElementList[elementIndex].dataList[i].fixed = true
        } else {
          this.categoryElementList[elementIndex].dataList[i].isTree = false
          this.categoryElementList[elementIndex].dataList[i].fixed = false
        }
      }
      // treeByParent(treeId, itemParentId).then((response) => {
      treeByParentByCfs(treeId, itemParentId).then((response) => {
        console.log('response', response)
        this.maps = response.data
        var dataList = []
        if (response.data.dataList) {
          dataList = response.data.dataList.map((item, index) => {
            return { ...item, order: index + 1, fixed: false }
          })
        }
        this.categoryElementList.splice(
          elementIndex + 1,
          this.categoryElementList.length - elementIndex - 1,
          { parentId, dataList }
        )
      })
      console.log('this.maps', this.maps)
      console.log('-------111', this.categoryElementList)
    },
    showCreate() {
      this.dialogFormVisible = true
      this.dialogTitle = 'Creat category item'
      this.saveOreditOrcopy = 0
      this.map.id = ''
      this.map.name = ''
      this.map.parentId = []
    },
    categorySave() {
      console.log('树形结构', this.categoryElementList)
      const dataList = this.categoryElementList
      treeItemSave(dataList).then((response) => {
        if (response) {
          this.$notify({
            title: this.$t('COMPONENTS.success'),
            message: this.$t('COMPONENTS.save_Success'),
            type: 'success'
          })
        } else {
          this.$notify.error({
            title: this.$t('COMPONENTS.Filad'),
            message: this.$t('COMPONENTS.save_Filad'),
            type: 'success'
          })
        }
      })
    },
    categoryRelation() {
      var map = {
        name: this.map.name,
        isVisible: this.map.isVisible,
        id: '',
        parentId: this.map.parentId,
        imgId: this.map.mainBannerUrl.length > 0 ? this.map.mainBannerUrl[0].id : '',
        imgUrl: this.map.mainBannerUrl.length > 0 ? getStaticSourcePath(this.map.mainBannerUrl[0].url, 'cfsFile') : ''
      }
      // const map = { name: this.name, parentId: this.parentId, isVisible: this.isVisible }
      if (this.saveOreditOrcopy === 0) {
        // mgProductGoodsAdd(map).then((response) => {
        mgProductGoodsAddIntoCfs(map).then((response) => {
          if (response.data) {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.save_Success'),
              type: 'success'
            })
          } else {
            this.$notify.error({
              title: this.$t('COMPONENTS.Filad'),
              message: this.$t('COMPONENTS.save_Filad'),
              type: 'success'
            })
          }
          this.CategoryItem()
          this.dialogFormVisible = false
        })
      } else if (this.saveOreditOrcopy === 1) {
        map.id = this.map.id
        mgProductGoodsAddIntoCfs(map).then((response) => {
          if (response.data) {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.save_Success'),
              type: 'success'
            })
          } else {
            this.$notify.error({
              title: this.$t('COMPONENTS.Filad'),
              message: this.$t('COMPONENTS.save_Filad'),
              type: 'success'
            })
          }
          this.CategoryItem()
          this.dialogFormVisible = false
        })
      } else if (this.saveOreditOrcopy === 2) {
        mgProductGoodsAddIntoCfs(map).then((response) => {
          if (response.data) {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.save_Success'),
              type: 'success'
            })
          } else {
            this.$notify.error({
              title: this.$t('COMPONENTS.Filad'),
              message: this.$t('COMPONENTS.save_Filad'),
              type: 'success'
            })
          }
          this.CategoryItem()
          this.dialogFormVisible = false
        })
      }
    },
    showCopy(item) {
      console.log('11111111111', item.id)
      this.saveOreditOrcopy = 2
      this.dialogFormVisible = true
      const shopCategoryId = item.id
      this.map.id = item.id
      this.map.name = item.name
      this.map.isVisible = item.isVisible
      this.map.mainImgUrl = item.imgUrl ? getStaticSourcePath(item.imgUrl, 'cfsFile') : ''
      console.log('12345', this.map.name)
      console.log('1234', this.map.isVisible)
      listParent(shopCategoryId).then((response) => {
        console.log('结果', response)
        this.map.parentId = response.data
      })
    },
    showEdit(item) {
      console.log('11111111111', item)
      this.saveOreditOrcopy = 1
      this.dialogFormVisible = true
      this.dialogTitle = 'Edit category item'
      const shopCategoryId = item.itemId
      this.map.id = item.itemId
      this.map.name = item.name
      this.map.isVisible = item.isVisible
      this.map.mainImgUrl = item.imgUrl ? getStaticSourcePath(item.imgUrl, 'cfsFile') : ''
      console.log('12345', this.map.name)
      console.log('1234', this.map.isVisible)
      listParent(shopCategoryId).then((response) => {
        console.log('结果', response)
        this.map.parentId = response.data
      })
    },
    dltId(item) {
      console.log(item)
      const id = item.itemId
      this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        deleteByIdFromCfs(id).then((response) => {
          if (response) {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.delete_Success'),
              type: 'success'
            })
            this.CategoryItem()
          }
        })
      })
    },
    delParent(item, cIndex, itemIndex) {
      console.log(item)
      const itemId = item.id
      this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        deleteByIdFromCfs(itemId).then((response) => {
          if (response) {
            this.$notify({
              title: this.$t('COMPONENTS.delete'),
              message: this.$t('COMPONENTS.delete_Success'),
              type: 'success'
            })
          }
          if (item.isTree) {
            this.categoryElementList.splice(
              itemIndex + 1,
              this.categoryElementList.length - itemIndex - 1
            )
          }

          console.log('----', this.categoryElementList[itemIndex])
          this.categoryElementList[itemIndex].dataList.splice(cIndex, 1)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.category-list-box {
  border: 1px red solid;
  padding: 8px;
  position: relative;

  .category-list-relationship-box,
  .category-stateless-box {
    display: flex;
    overflow-y: hidden;
    min-height: 600px;
    padding: 1rem 0;
    .list-group {
      min-width: 300px;
      list-style: none;
      padding: 0.5rem;
      > span {
        display: block;
        min-height: 100%;
        border: 1px #c1c1c1 solid;
        padding: 0 8px;
        background-color: #f9f9f9;
      }
      .flip-list-move {
        transition: transform 0.5s;
      }
      .ghost {
        opacity: 0.5;
        background: #c8ebfb;
      }
      .list-group-item {
        cursor: move;
        border: 1px #c1c1c1 solid;
        margin: 0.5rem 0;
        padding: 1rem 0.5rem;
        background-color: white;
        position: relative;
        padding-right: 90px;
        padding-left: 36px;
        > i {
          cursor: pointer;
          position: absolute;
          left: 8px;
          top: 18px;
          z-index: 1;
        }
        > .sequence {
          border-radius: 15px;
          padding: 3px 6px;
          font-size: 12px;
          background-color: #6e6e6e;
          color: white;
          text-align: center;
        }
        > .append {
          position: absolute;
          right: 8px;
          bottom: 6px;
        }
      }
    }
  }

  .category-stateless-box {
    overflow: auto;
    .list-group {
      width: 100%;
    }
  }
}

.category-list-relationship-box {
  margin-top: 36px;
}

.title {
  color: #808080;
  font-size: 16px;
}

.title-text {
  color: #808080;
  font-size: 12px;
}

.search-nameAndcount {
  position: relative;
  left: 950px;
}
/deep/.el-button + .el-button {
  margin-left: 0px;
}
.title-add {
  float: right;
}

.btn {
  float: right;
}
.iframe-class {
  width: 100%;
  border: 0px;
}
/deep/.dialog-class{
  height: 600px;
  .el-dialog__body{
    height: 570px;
  }
  .el-dialog__header{
  background-color: #2ea6f1;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  .el-dialog__title{
    color: #ffffff;
  }
}
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
