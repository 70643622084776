<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="75px"
        >
          <el-row :gutter="20">
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('attributeGroup.name')">
                <el-input
                  v-model="query.name"
                  :placeholder="$t('COMPONENTS.incontent')"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('attributeGroup.oneNmu')">
                <el-input v-model="query.oneNum" style="width: 40%" />
                ~
                <el-input v-model="query.towNum" style="width: 40%" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-button :disabled="checkPermission('attributeGroup')" @click="loadData">{{ $t('COMPONENTS.search') }}</el-button>
            <el-button :disabled="checkPermission('attributeGroup.add')" @click="groupAdd()">{{ $t('COMPONENTS.add') }}</el-button>
          </el-row>
        </el-form>
      </div>
    </transition>
    <el-form>
      <el-table
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        header-row-class-name="table-header"
        size="mini"
      >
        <el-table-column
          :label="$t('attributeGroup.Attribute-group-name')"
          prop="name"
          width="600px"
          align="left"
        />
        <el-table-column
          :label="$t('attributeGroup.oneNmu')"
          prop="num"
          align="left"
        />
        <el-table-column width="40px" align="center" />
        <el-table-column :label="$t('COMPONENTS.operation')" width="300px" align="left" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              class="filter-item"
              :disabled="checkPermission('attributeGroup.edit')"
              @click="dltedit(scope.row)"
            >{{ $t('COMPONENTS.edit') }}</el-button>
            <el-button
              v-if="scope.row.copy"
              size="mini"
              class="filter-item"
              :disabled="checkPermission('attributeGroup.copy')"
              @click="dltcopy(scope.row)"
            >{{ $t('COMPONENTS.copy') }}</el-button>
            <el-button
              v-else
              size="mini"
              class="filter-item"
              :disabled="checkPermission('attributeGroup.add')"
              @click="dltsave(scope.row)"
            >{{ $t('COMPONENTS.save') }}</el-button>
            <el-button
              size="mini"
              class="filter-item"
              :disabled="checkPermission('attributeGroup.delete')"
              @click="dltId(scope.row, scope.$index)"
            >{{ $t('COMPONENTS.delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-row>
      <el-col>
        <pagination
          v-show="total > 0"
          class="hidden-xs-only"
          :total="total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="total > 0"
          :total="total"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="3"
          layout="pager, prev, next"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  productGroupList,
  deleteByGroupId,
  saveCopy
} from '@/api/MgProductAttrGroupApi'
import { checkCfsPermission } from '@/utils/permission'
import Pagination from '@/components/Pagination'
import { getPageParm } from '@/utils/page-common'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      add: false,
      list: [
        {
          id: '',
          name: '',
          num: '',
          edit: false,
          copy: true
        }
      ],
      total: 0,
      query: {
        size: 10,
        page: 1,
        name: '',
        oneNum: '',
        towNum: ''
      },
      id: '',
      name: '',
      saveOrcopy: true
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadData() {
      var quary = { ...this.query }
      var param = getPageParm(quary)
      productGroupList(param, quary).then((response) => {
        response.data &&
          response.data.forEach((item) => {
            item.edit = false
            item.copy = true
          })
        this.list = response.data
        console.log('输出结果---------', this.list)
        this.total = response.pageCount
        this.quary.page = response.pageNum
        this.quary.size = response.pageSize
      })
    },
    groupAdd() {
      this.add = true
      const add = this.add
      this.$router.push({
        path: 'attributeGroup/create',
        query: {
          add: add
        }
      })
    },
    dltId(row, rows) {
      console.log('edit==', row.edit)
      const id = row.id
      if (id) {
        console.log('进入有id删除')
        this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
          confirmButtonText: this.$t('COMPONENTS.sure'),
          cancelButtonText: this.$t('COMPONENTS.cancel'),
          type: 'warning'
        }).then(() => {
          deleteByGroupId(id).then((response) => {
            if (response) {
              this.$notify({
                title: this.$t('COMPONENTS.success'),
                message: this.$t('COMPONENTS.delete_Success'),
                type: 'success'
              })
              this.loadData()
            }
          })
        })
      } else {
        console.log('进入无id删除')
        this.list.splice(rows, 1)
      }
    },
    dltedit(row) {
      const id = row.id
      const name = row.name
      const dtlId = row.dtlId
      this.add = false
      const add = this.add
      console.log('name=---------', name)
      this.$router.push({
        path: 'attributeGroup/create',
        query: {
          id: id,
          name: name,
          dtlId: dtlId,
          add: add
        }
      })
    },
    dltcopy(row) {
      console.log('copy-------=', row)
      const gid = row.id
      const grpName = row.name
      console.log('gid-----------', gid)
      this.saveOrcopy = false
      const saveOrcopy = this.saveOrcopy
      console.log('复制------', saveOrcopy)
      this.$router.push({
        path: 'attributeGroup/create',
        query: {
          gid: gid,
          grpName: grpName,
          saveOrcopy: saveOrcopy
        }
      })
      // this.id = row.id
      // this.name = row.name
      // const name = this.name
      // const num = row.num
      // this.list.push({
      //   name: name,
      //   num: num,
      //   edit: true,
      //   copy: false
      // })
    },
    dltsave(row) {
      console.log('row是多少', row)
      const id = this.id
      const name = row.name
      if (name === this.name) {
        this.$message.error(this.$t('attributeGroup.attributeGroup-cannot-have-the-same-title'))
        return
      }
      const data = { id: id, name: name }
      saveCopy(data).then((response) => {
        if (response) {
          this.$notify({
            title: this.$t('COMPONENTS.success'),
            message: this.$t('COMPONENTS.save_Success'),
            type: 'success'
          })
          this.loadData()
        } else {
          this.$message.error(this.$t('COMPONENTS.save_Filad'))
        }
      })
    }
  }
}
</script>
<style>
.text-title {
  font-size: 18px;
  font-weight: bold;
}
.btn-title {
  display: flex;
  justify-content: space-between;
}
.btn-add {
  width: 70px;
  height: 40px;
}
</style>
