import request from '@/utils/request'

import { API_BASE_URL_MEMBER } from '../Config'

export function countryList() {
  return request({
    url: API_BASE_URL_MEMBER + '/public/countrys',
    method: 'get'
  })
}

export function loctionTypeList(countryId, locTypeId) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/country/locType/' + countryId + '/' + locTypeId,
    method: 'get'
  })
}

export function locList(countryId, parentId) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/country/' + countryId + '/' + parentId,
    method: 'get'
  })
}
