var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sti-appframe", {
        staticClass: "mytestappframe",
        attrs: {
          preview: _vm.preview,
          "form-key": _vm.formkey,
          "form-view": _vm.formView,
          "use-shadow-dom": _vm.useShadowDom,
          "page-key": _vm.pageKey,
          "object-id": _vm.objectId,
          "language-id": _vm.languageId,
          "use-default-popup-contaier": _vm.useDefaultPopupContaier,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }