<template>
  <div class="app-container">
    <!-- 查询和其他操作 -->
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="75px"
        >
          <el-row :gutter="20">
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('shopList.name')">
                <el-input
                  v-model="listQuery.name"
                  clearable
                  class="filter-item"
                  :placeholder="$t('shopList.inname')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('shopList.address')">
                <el-input
                  v-model="listQuery.address"
                  clearable
                  class="filter-item"
                  :placeholder="$t('shopList.inaddress')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('shopList.mobile')">
                <el-input
                  v-model="listQuery.mobile"
                  clearable
                  class="filter-item"
                  :placeholder="$t('shopList.inmobile')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('shopList.phone')">
                <el-input
                  v-model="listQuery.phone"
                  clearable
                  class="filter-item"
                  :placeholder="$t('shopList.inphone')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('shopList.fix')">
                <el-input
                  v-model="listQuery.fix"
                  clearable
                  class="filter-item"
                  :placeholder="$t('shopList.infix')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="8" :md="12" :sm="24" :xs="24">
              <el-form-item :label="$t('shopList.time')">
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-time-picker
                      v-model="listQuery.beginTime"
                      clearable
                      format="HH:mm"
                      value-format="HH:mm"
                      :placeholder="$t('shopList.beginTime')"
                      style="width: 100%"
                    />
                  </el-col>
                  <el-col :span="10">
                    <el-time-picker
                      v-model="listQuery.endTime"
                      clearable
                      format="HH:mm"
                      value-format="HH:mm"
                      :placeholder="$t('shopList.endTime')"
                      style="width: 100%"
                    />
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-button class="filter-item" :disabled="checkPermission('shop')" @click="handleFilter">{{ $t('COMPONENTS.search') }}</el-button>
            <!-- There is only one store for the time being -->
            <!-- <el-button class="filter-item" @click="handleCreate" :disabled='checkPermission("shop.add")'>{{ $t('COMPONENTS.add') }}</el-button> -->
          </el-row>
        </el-form>
      </div>
    </transition>

    <!-- 查询结果 -->
    <el-table
      :data="list"
      size="small"
      border
      default-expand-all
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      tooltip-effect="dark"
      header-row-class-name="table-header"
      style="width: 100%"
    >
      <el-table-column align="center" :label="$t('shopList.shopName')" prop="name" />
      <el-table-column align="center" :label="$t('shopList.address')" prop="address" />
      <el-table-column align="center" :label="$t('shopList.mobile')" prop="mobile" />
      <el-table-column align="center" :label="$t('shopList.phone')" prop="phone" />
      <el-table-column align="center" :label="$t('shopList.fix')" prop="fax" />
      <el-table-column align="center" label="logo">
        <template slot-scope="scope">
          <el-image :src="getImagePath(scope.row.logoUrl)" width="80px" />
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('shopList.businessTime')">
        <template slot-scope="scope">
          <span>{{ scope.row.openTime }}~{{ scope.row.endTime }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('COMPONENTS.operation')" width="180" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="checkPermission('shop.edit')"
            @click="handleUpdate(scope.row)"
          >{{ $t('COMPONENTS.edit') }}</el-button>
          <el-button
            size="mini"
            :disabled="checkPermission('shop.delete')"
            @click="handleDelete(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.size"
      @pagination="getList"
    />

    <el-tooltip placement="top" content="返回顶部">
      <back-to-top :visibility-height="100" />
    </el-tooltip>
  </div>
</template>

<style>
.table-expand {
  font-size: 0;
}
.table-expand label {
  width: 100px;
  color: #99a9bf;
}
.table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
}
.gallery {
  width: 80px;
  margin-right: 10px;
}
</style>

<script>
import { searchShopList, delShop } from '@/api/shop'
import BackToTop from '@/components/BackToTop'
import Pagination from '@/components/Pagination' // Secondary package based on el-pagination
import { getPageParm } from '@/utils/page-common'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import { fetchLanguageList } from '@/api/languageApi'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'ShopList',
  components: { BackToTop, Pagination },
  data() {
    return {
      language: [],
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 10,
        name: undefined,
        sort: '',
        order: 'desc',
        address: '',
        mobile: '',
        phone: '',
        fix: '',
        beginTime: '',
        endTime: ''
      }
    }
  },
  mounted() {
    this.loadData()
  },
  created() {
    this.getList()
  },
  methods: {
    loadData() {
      fetchLanguageList().then(response => {
        this.language = response.data.items
      })
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getImagePath(path) {
      return getStaticSourcePath(path)
    },
    getList() {
      var query = { ...this.listQuery }
      var param = getPageParm(query)
      searchShopList(param, query)
        .then((response) => {
          this.list = response.data
          this.total = response.pageCount
        })
        .catch(() => {
          this.list = []
          this.total = 0
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleCreate() {
      this.$router.push({ path: '/shop/shopCreate' })
    },
    handleUpdate(row) {
      this.$router.push({
        path: '/shop/shopEdit',
        query: { id: row.id, isLoad: true }
      })
    },
    handleDelete(index, row) {
      this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      })
        .then(() => {
          delShop(row.id).then((res) => {
            this.list.splice(index, 1)
            this.$notify.success({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.delete_Success')
            })
            this.getList()
          }).catch(e => {
            console.log(e)
          })
        })
    }
  }
}
</script>
