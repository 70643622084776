import decode from 'jwt-decode'
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
export const DATA_SERVER_URL = 'http://localhost:3000'
export const REQUEST_TIMEOUT = 60 * 60 * 1000 // 1 hour
import { keycloakConfig, domainUri } from '@/Config'

const ACCESS_TOKEN_KEY = 'admin_access_token'
const REFRESH_ACCESS_TOKEN_KEY = 'refresh_admin_access_token'
const USER_PROFILE = 'user_profile'

export function axiosInstance() {
  const cache = setupCache({
    maxAge: 15 * 60 * 1000
  })
  var config = {
    baseURL: `${DATA_SERVER_URL}`,
    timeout: REQUEST_TIMEOUT,
    adapter: cache.adapter
  }
  var accessToken = getAccessToken()
  if (accessToken) {
    config.headers = { 'x-access-token': getAccessToken() }
  }
  const instance = axios.create(config)
  return instance
}

export function get(url) {
  // get
  return new Promise((resolve, reject) => {
    const instance = axiosInstance()
    var targetUrl = url // urlAddRandomForGetMethod(url);
    // console.log('$ get url: ', targetUrl);
    instance
      .get(targetUrl)
      .then(response => {
        resolve(response.data)
        // console.log("response >>>>>>>> " , response );
      })
      .catch(error => {
        // console.error(error);
        try {
          reject(error.response.data)
        } catch (e) {
          reject(error.toString())
        }
      })
  })
}

export function post(url, data) {
  // post
  return new Promise((resolve, reject) => {
    const instance = axiosInstance()
    // console.log('$ post url/data: ', url, data);
    instance
      .post(url, data)
      .then(response => {
        // console.log(response);
        resolve(response.data)
      })
      .catch(error => {
        // console.error(error);
        try {
          if (error.response.data.errors) {
            reject(error.response.data.errors[0].msg)
          } else if (error.response.data.message) {
            reject(error.response.data.message)
          } else if (error.response.data) {
            reject(error.response.data)
          }
        } catch (e) {
          reject(error.toString())
        }
      })
  })
}

export function deleteData(url) {
  // delete
  return new Promise((resolve, reject) => {
    const instance = axiosInstance()
    var targetUrl = url // urlAddRandomForGetMethod(url);
    // console.log('$ get url: ', targetUrl);
    instance
      .delete(targetUrl)
      .then(response => {
        resolve(response.data)
        // console.log("response >>>>>>>> " , response );
      })
      .catch(error => {
        // console.error(error);
        try {
          reject(error.response.data)
        } catch (e) {
          reject(error.toString())
        }
      })
  })
}

export function removeToken() {
  localStorage.removeItem('mallAdmin_client_config_refresh_date')
  localStorage.removeItem('mallAdmin_client_config')
  localStorage.removeItem('getUserInfo_refresh_date')
  localStorage.removeItem('partyCompanyName')
  localStorage.removeItem('VERSION_NO')
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_ACCESS_TOKEN_KEY)
  localStorage.removeItem(USER_PROFILE)
}

export function logout() {
  console.log('開始登出')

  removeToken()
  const logoutUrl = keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/logout?redirect_uri=' + domainUri + 'malladmin/#/home'
  location.href = logoutUrl
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export function setAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
}

export function setRefreshAccessToken(accessToken) {
  localStorage.setItem(REFRESH_ACCESS_TOKEN_KEY, accessToken)
}

export function setUserName(value) {
  localStorage.setItem(USER_PROFILE, value)
}

export function getUserName() {
  localStorage.getItem(USER_PROFILE)
}

export function isLoggedIn() {
  const accessToken = getAccessToken()
  return !!accessToken && !isTokenExpired(accessToken)
}

export function getTokenExpirationInMillisecond() {
  const token = getAccessToken()
  const expirationDate = getTokenExpirationDate(token)
  const now = new Date()
  if (!expirationDate) {
    return 0
  }
  var ms = expirationDate.getTime() - now.getTime()
  return ms
}

function getTokenExpirationDate(encodedToken) {
  const date = new Date(0)
  try {
    if (encodedToken) {
      const token = decode(encodedToken)
      if (!token) {
        return null
      }
      if (!token.exp) {
        return null
      }
      date.setUTCSeconds(token.exp)
    }
  } catch (e) {
    // console.log(e);
    return null
  }
  return date
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}

export function getLoginUserId() {
  const accessToken = getAccessToken()
  if (accessToken) {
    const token = decode(accessToken)
    if (!token.id) {
      return null
    }
    return token.id
  }
  return null
}

export function isAdmin() {
  const result = localStorage.getItem('IS_ROLE')
  console.log('isAdmin()===>>>', result)
  return result === 'ADMIN'
}

export function setAdmin(value) {
  if (value === true) {
    value = 'ADMIN'
    console.log('Is admin role.')
  } else {
    value = 'USER'
    console.log('Is user role.')
  }
  localStorage.setItem('IS_ROLE', value)
}

const CURRENT_FUNCTION = 'CURRENT_FUNCTION'

export function getCurrentFunction() {
  return localStorage.getItem(CURRENT_FUNCTION)
}

export function setCurrentFunction(f) {
  localStorage.setItem(CURRENT_FUNCTION, f)
}

// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////

export function getWithAccessToken(url) {
  const config = { headers: { Authorization: 'Bearer ' + getAccessToken() }}
  const instance = axios.create(config)
  return new Promise((resolve, reject) => {
    instance
      .get(url)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        try {
          reject(error.response.data)
        } catch (e) {
          reject(error.toString())
        }
      })
  })
}

export function downloadWithAccessToken(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.setRequestHeader('Authorization', 'Bearer ' + getAccessToken())
    xhr.onload = function(e) {
      if (this.status === 200) {
        console.log(this)
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(xhr.response)
        link.download = 'export.xlsx'
        link.click()
        // document.getElementById("myImage").src="data:image/png;base64,"+base64;
      }
    }
    xhr.send()

    // var xhr = new XMLHttpRequest();
    // var param = "";
    // var paramIdx = url.indexOf("?");
    // if (paramIdx != -1) {
    //     param = url.substr(paramIdx+1);
    //     url = url.substr(0, paramIdx);
    // }
    // xhr.open('GET', url, true);

    // //Send the proper header information along with the request
    // xhr.setRequestHeader('Content-type', 'application/octet-stream');
    // xhr.setRequestHeader('Authorization', 'Bearer ' + getAccessToken() );
    // xhr.responseType = "blob";
    // console.log("http-before:", xhr.responseType);
    // xhr.onload = function() {//Call a function when the state changes.
    //     if (this.status === 200) {
    //         try {
    //             console.log("http-after", this);
    //             console.log("typeof response "+(typeof this.response));
    //             console.log("all http headers:", this.getAllResponseHeaders());
    //             var fileName = "export.xlsx";
    //             if (this.getAllResponseHeaders().indexOf("content-disposition") >= 0) {
    //                 console.log("header:", this.getResponseHeader("content-disposition:"));
    //                 var contentDispo = this.getResponseHeader('Content-Disposition');
    //                 // https://stackoverflow.com/a/23054920/
    //                 fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
    //             }
    //             saveOrOpenBlob(this.response, fileName);
    //             resolve("success");
    //         } catch (e) {
    //             reject(e);
    //         }
    //     } else {
    //         reject(xhr.response);
    //     }
    // }
    // xhr.send(param);
  })
}

/* function saveOrOpenBlob(blob, fileName) {
  window.requestFileSystem =
    window.requestFileSystem || window.webkitRequestFileSystem
  window.requestFileSystem(
    window.TEMPORARY,
    1024 * 1024,
    function(fs) {
      fs.root.getFile(
        fileName,
        { create: true },
        function(fileEntry) {
          fileEntry.createWriter(
            function(fileWriter) {
              fileWriter.addEventListener(
                'writeend',
                function() {
                  const link = document.createElement('a')
                  link.href = fileEntry.toURL()
                  link.setAttribute('download', fileName)
                  document.body.appendChild(link)
                  link.click()
                },
                false
              )
              fileWriter.write(blob, '_blank')
            },
            function() {}
          )
        },
        function() {}
      )
    },
    function() {}
  )
}
 */
export function postWithAccessToken(url, data) {
  const config = {
    headers: {
      Authorization: 'Bearer ' + getAccessToken(),
      'content-type': 'application/json;charset=UTF-8'
    }
  }
  const instance = axios.create(config)
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        try {
          if (error.response.data.errors) {
            reject(error.response.data.errors[0].msg)
          } else if (error.response.data.message) {
            reject(error.response.data.message)
          } else if (error.response.data) {
            reject(error.response.data)
          }
        } catch (e) {
          reject(error.toString())
        }
      })
  })
}

export function postMultipartWithAccessToken(url, data) {
  const config = {
    headers: {
      Authorization: 'Bearer ' + getAccessToken(),
      'content-type': 'multipart/form-data'
    }
  }
  const instance = axios.create(config)
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        try {
          if (error.response.data.errors) {
            reject(error.response.data.errors[0].msg)
          } else if (error.response.data.message) {
            reject(error.response.data.message)
          } else if (error.response.data) {
            reject(error.response.data)
          }
        } catch (e) {
          reject(error.toString())
        }
      })
  })
}

export function putWithAccessToken(url, data) {
  const config = {
    headers: {
      Authorization: 'Bearer ' + getAccessToken(),
      'content-type': 'application/json;charset=UTF-8'
    }
  }
  const instance = axios.create(config)
  return new Promise((resolve, reject) => {
    instance
      .put(url, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        try {
          if (error.response.data.errors) {
            reject(error.response.data.errors[0].msg)
          } else if (error.response.data.message) {
            reject(error.response.data.message)
          } else if (error.response.data) {
            reject(error.response.data)
          }
        } catch (e) {
          reject(error.toString())
        }
      })
  })
}

export function deleteWithAccessToken(url, data) {
  const config = {
    headers: {
      Authorization: 'Bearer ' + getAccessToken(),
      'content-type': 'application/json;charset=UTF-8'
    }
  }
  const instance = axios.create(config)
  return new Promise((resolve, reject) => {
    instance
      .delete(url, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        try {
          if (error.response.data.errors) {
            reject(error.response.data.errors[0].msg)
          } else if (error.response.data.message) {
            reject(error.response.data.message)
          } else if (error.response.data) {
            reject(error.response.data)
          }
        } catch (e) {
          reject(error.toString())
        }
      })
  })
}
