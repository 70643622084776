<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="150px"
        >
          <el-row :gutter="20">
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('productTypeTemplate.table.name')">
                <el-input v-model="query.name" style="width: 100%" :placeholder="$t('COMPONENTS.incontent')" clearable />
              </el-form-item>
            </el-col>
            <el-col :xl="24" :md="24" :sm="24" :xs="24">
              <el-button class="allButton" :disabled="checkPermission('PRODUCT_TYPE_TEMP.SEARCH')" @click="loadData">{{ $t('COMPONENTS.search') }}</el-button>
              <el-button class="allButton" :disabled="checkPermission('PRODUCT_TYPE_TEMP.EDIT')" @click="handleCreate">{{ $t('COMPONENTS.add') }}</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </transition>

    <el-table
      tooltip-effect="dark"
      :data="data"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
    >
      <el-table-column type="selection" align="center" />
      <el-table-column :label="$t('productTypeTemplate.table.name')" prop="name" align="left" />
      <el-table-column :label="$t('Created By')" prop="createdBy" align="left" />
      <el-table-column :label="$t('Last Modified By')" prop="lastModifiedBy" align="left" />
      <el-table-column :label="$t('COMPONENTS.operation')" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.view') }}</el-button>
          <el-button
            :disabled="(scope.row.goodsIds && scope.row.goodsIds.length > 0)"
            size="mini"
            @click="handleDelete(scope.$index, scope.row)"
          >{{ $t("COMPONENTS.delete") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-col>
        <pagination
          v-show="query.total > 0"
          class="hidden-xs-only"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="query.total > 0"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="7"
          layout="pager, prev, next"
          @pagination="loadData"
        />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import { listAllRemarkTplList, deleteById } from '@/api/remakTplApi'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
import moment from 'moment'
export default {
  name: 'ProductTypeTemplate',
  components: { Pagination },
  directives: { waves },
  filters: {},
  data() {
    return {
      data: [],
      query: {
        name: '',
        size: 10,
        page: 1,
        total: 100
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    isNull(data) {
      return data === null || data === '' || data === undefined || data === 'undefined'
    },
    formatDate(date) {
      if (!this.isNull(date)) {
        return moment(date).format('YYYY-MM-DD')
      }
    },
    loadData() {
      const query = { ...this.query }
      var param = getPageParm(query)
      this.loadAllRemarkTpl(param, query)
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadAllRemarkTpl(param, quary) {
      listAllRemarkTplList(param, quary).then((resp) => {
        this.data = resp.data
        this.query.total = resp.pageCount
        this.query.page = resp.pageNum + 1
        this.query.size = resp.pageSize
      })
    },

    handleEdit(index, row) {
      this.$router.push({ path: 'productTypeTemplate/edit', query: { remarkId: row.id }})
    },

    handleCreate() {
      this.$router.push({ path: 'productTypeTemplate/create' })
    },

    handleDelete(index, row) {
      this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        deleteById(row.id).then((resp) => {
          if (resp) {
            this.data.splice(index, 1)
            this.$notify.success({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.delete_Success')
            })
            this.loadData()
          }
        })
      })
    }
  }
}
</script>
