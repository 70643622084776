<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col v-if="disabledTranslateBtn" :span="10" class="page-header">{{ $t('COMPONENTS.add') }}</el-col>
        <el-col v-else :span="10" class="page-header">{{ $t("COMPONENTS.edit") }}</el-col>
        <el-col :span="14">
          <el-button
            @click="setting()"
          >{{
            $t("COMPONENTS.translate")
          }}</el-button>
          <el-button @click="back()">{{
            $t("COMPONENTS.return")
          }}</el-button>
          <el-button
            v-if="!!plan.id && plan.queryVersion !== plan.publicVersion && plan.planType === 'FOR_MEMBER_BUY'"
            @click="publicBtn('UP')"
          >{{ $t("Publish") }}</el-button>
          <el-button
            v-if="!!plan.id && plan.queryVersion === plan.publicVersion && plan.planType === 'FOR_MEMBER_BUY'"
            @click="publicBtn('DOWN')"
          >{{ $t("Discontinue") }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission('plan.add')"
            @click="submitBtn()"
          >{{ $t("COMPONENTS.save") }}
          </el-button>
        </el-col>

      </el-row>
    </sticky>
    <el-card>
      <el-row>
        <el-col>
          <el-form ref="temp" :model="plan" label-width="180px">
            <el-row v-if="pageType !== 'CREATE'">
              <el-form-item>
                <span slot="label">{{ $t("Basic ID") }}</span>
                {{ plan.baseProductId }}
              </el-form-item>

              <el-form-item>
                <span slot="label">{{ $t("Version ID") }}</span>
                {{ plan.id }}
              </el-form-item>

              <el-form-item :label="$t('Select Version')">
                <el-select
                  v-model="plan.queryVersion"
                  :placeholder="$t('COMPONENTS.inselect')"
                  style="width: 408px"
                  clearable
                  @change="onChangeVersion"
                >
                  <el-option
                    v-for="item in plan.productVersionHistory"
                    :key="item"
                    :label="item + (plan.publicVersion === item ? ( '  ('+$t('Publish') + ')' ):'')"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-row>

            <el-form-item>
              <span slot="label"><span style="color:red;">*</span>{{ $t("Admin Tag") }}</span>
              <el-input
                v-model="plan.adminTag"
                :value="plan.adminTag"
                style="width: 300px"
              />
              <el-tooltip class="item" effect="dark" :content="$t('Admin.adminTag.tooltip')" placement="top">
                <i class="el-icon-warning" />
              </el-tooltip>
            </el-form-item>
            <el-form-item prop="adminRemark" :label="$t('Admin Remark')">
              <el-input
                v-model="plan.adminRemark"
                :value="plan.adminRemark"
                style="width: 300px"
              />
              <el-tooltip class="item" effect="dark" :content="$t('Admin.adminRemark.tooltip')" placement="top">
                <i class="el-icon-warning" />
              </el-tooltip>

            </el-form-item>
            <el-form-item>
              <span slot="label"><span style="color:red;">*</span>{{ $t("plan.table.name") }}</span>
              <el-input
                v-model="plan.name"
                :value="plan.name"
                style="width: 300px"
              />
            </el-form-item>

            <el-form-item>
              <span slot="label"><span style="color:red;">*</span>{{ $t("productItem.status") }}</span>
              <el-select
                v-model="plan.itemStatus"
                :placeholder="$t('COMPONENTS.inselect')"
                style="width: 408px"
                :disabled="!allowEdit"
              >
                <el-option
                  v-for="item in itemStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                />
              </el-select>
            </el-form-item>

            <el-form-item v-if="loaded" :key="plan.description" :label="$t('plan.description')" prop="description">
              <Summernote :value="plan.description" @change="changeDescription" />
            </el-form-item>

            <el-form-item>
              <span slot="label"><span style="color:red;">*</span>{{ $t("Plan Type") }}</span>
              <template>

                <el-select
                  v-model="plan.planType"
                  :placeholder="$t('COMPONENTS.inselect')"
                  style="width: 400px"
                  :disabled="pageType !== 'CREATE'"
                  @change="selectPlanTypeChange"
                >

                  <!-- 開放讓會員購買 -->
                  <el-option key="FOR_MEMBER_BUY" :label="$t('planType.FOR_MEMBER_BUY.des')" value="FOR_MEMBER_BUY" :disabled="false" />
                  <!-- 僅供優惠券設定，會員無法直接購買 -->
                  <el-option key="FOR_COUPON_USE" :label="$t('planType.FOR_COUPON_USE.des')" value="FOR_COUPON_USE" :disabled="false" />
                  <!-- 僅供活動設定，會員無法直接購買 -->
                  <el-option key="FOR_ACTIVITY_USE" :label="$t('planType.FOR_ACTIVITY_USE.des')" value="FOR_ACTIVITY_USE" :disabled="false" />

                </el-select>
              </template>
            </el-form-item>

            <el-form-item v-if="plan.planType !== 'FOR_MEMBER_BUY'">
              <span slot="label"><span style="color:red;">*</span>{{ $t("Effective Days") }}</span>
              <el-input
                v-model="plan.days"
                :min="1"
                step="1"
                :value="plan.days"
                style="width: 150px"
                type="number"
                @input="filterInputToNum"
              />
              {{ $t('Days') +' (' + $t('Calculated from the date of holding') +')' }}
            </el-form-item>

            <el-form-item v-if="plan.planType === 'FOR_MEMBER_BUY'" prop="effectiveDateData" :label="$t('Release Date')">
              <el-date-picker
                v-model="effectiveDateData"
                type="daterange"
                range-separator="To"
                :start-placeholder="$t('Start date')"
                :end-placeholder="$t('End date')"
              />
            </el-form-item>

            <el-form-item v-if="plan.planType === 'FOR_MEMBER_BUY'">
              <span slot="label"><span style="color:red;">*</span>{{ $t("plan.card.table") }}</span>
              <el-table
                tooltip-effect="dark"
                :data="plan.cards"
                style="width: 60vw"
                header-row-class-name="table-header"
                size="mini"
              >
                <el-table-column :label="$t('plan.card.lable')" prop="cardName" align="left" />
                <el-table-column :label="$t('Apple Product ID')" prop="appleProductId" width="200px" align="left" />
                <el-table-column :label="$t('plan.card.days')" prop="days" width="150px" align="left" />
                <el-table-column :label="$t('plan.card.price')" prop="price" width="150px" align="left" />
                <el-table-column :label="$t('plan.card.amount')" prop="amount" width="150px" align="left" />
                <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" width="180px">
                  <template slot-scope="scope">
                    <el-button
                      :disabled="!allowEdit"
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                    >{{ $t('COMPONENTS.edit') }}</el-button>
                    <el-button
                      :disabled="!allowEdit"
                      size="mini"
                      @click="handleDelete(scope.$index, scope.row)"
                    >{{ $t('COMPONENTS.delete') }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <p />
              <el-button :disabled="!allowEdit" type="primary" style="width: 60vw" @click="addCard()">{{ $t('COMPONENTS.add') }}</el-button>

              <el-dialog
                custom-class="dialog-class"
                :title="$t('plan.card.title')"
                :visible.sync="dialogVisible"
                width="50%"
                :before-close="handleClose"
              >
                <el-form ref="temp" :model="card" label-width="180px">
                  <el-form-item prop="cardName" :label="$t('plan.card.lable')">
                    <el-input
                      v-model="card.cardName"
                      style="width: 200px"
                    />
                  </el-form-item>
                  <p />
                  <el-form-item prop="appleProductId" :label="$t('Apple Product ID')">
                    <el-input
                      v-model="card.appleProductId"
                      style="width: 100px"
                    />
                  </el-form-item>
                  <p />
                  <el-form-item :label="$t('plan.card.days')">
                    <el-input
                      v-model="card.days"
                      style="width: 200px"
                      type="number"
                      :min="0"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"
                      onafterpaste="this.value=this.value.replace(/\D/g,'')"
                    />
                    <span>{{ $t('Days') }}</span>
                  </el-form-item>
                  <p />

                  <el-form-item :label="$t('plan.card.price')">
                    <el-input
                      v-model="card.price"
                      on-key-press="if((event.keyCode<48 || event.keyCode>57) && event.keyCode!=46 || /\.\d\d$/.test(value))event.returnValue=false"
                      style="width: 200px"
                      clearable
                      type="number"
                      :min="0"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"
                      onafterpaste="this.value=this.value.replace(/\D/g,'')"
                    >
                      <template slot="prepend">HK$</template>
                    </el-input>
                  </el-form-item>
                  <p />

                  <el-form-item prop="amount" :label="$t('plan.card.amount')">
                    <el-input
                      v-model="card.amount"
                      on-key-press="if((event.keyCode<48 || event.keyCode>57) && event.keyCode!=46 || /\.\d\d$/.test(value))event.returnValue=false"
                      clearable
                      style="width: 200px"
                      type="number"
                      :min="0"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"
                      onafterpaste="this.value=this.value.replace(/\D/g,'')"
                    >
                      <template slot="prepend">HK$</template>
                    </el-input>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">{{ $t("COMPONENTS.return") }}</el-button>
                  <el-button type="primary" @click="addNewCard()">{{ $t("COMPONENTS.save") }}</el-button>
                </span>
              </el-dialog>

            </el-form-item>

            <el-form-item>
              <span slot="label"><span style="color:red;">*</span>{{ $t("Plan Detail") }}</span>
              <template>
                <el-select
                  v-model="plan.planDetail"
                  :placeholder="$t('COMPONENTS.inselect')"
                  style="width: 400px"
                  default-first-option="ALLOW_VIEW_SOME_RECIPE"
                  multiple
                  @change="selectChange"
                >
                  <!-- 解鎖多個食譜 -->
                  <el-option key="ALLOW_VIEW_SOME_RECIPE" :label="$t('planType.ALLOW_VIEW_SOME_RECIPE.des')" value="ALLOW_VIEW_SOME_RECIPE" />
                  <!-- 允許使用採購單功能 -->
                  <el-option key="ALLOW_PLAN_TO_BUY" :label="$t('planType.ALLOW_PLAN_TO_BUY.des')" value="ALLOW_PLAN_TO_BUY" />
                  <!-- 允許使用訂餐功能 -->
                  <el-option key="ALLOW_PLAN_TO_EAT" :label="$t('planType.ALLOW_PLAN_TO_EAT.des')" value="ALLOW_PLAN_TO_EAT" />
                  <!-- 允許使用家庭成員功能 -->
                  <el-option key="ALLOW_ADD_FAMILY" :label="$t('planType.ALLOW_ADD_FAMILY.des')" value="ALLOW_ADD_FAMILY" />
                </el-select>
              </template>
            </el-form-item>

            <el-form-item v-if="plan.planDetail.includes('ALLOW_VIEW_SOME_RECIPE')">
              <span slot="label"><span style="color:red;">*</span>{{ $t("plan.include.product") }}</span>
              <template>
                <el-transfer
                  id="planPageTransfer"
                  :key="plan.id"
                  v-model="plan.goodsSkuIds"
                  tag-list-unselected
                  filterable
                  :titles="[$t('tagList.unselected'), $t('tagList.selected')]"
                  :filter-method="filterMethod"
                  filter-placeholder="Search here"
                  :data="productList"
                />
              </template>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div style="height: 200px" />
    </el-card>
    <el-drawer
      class="translate-drawer"
      :title="$t('COMPONENTS.translate')"
      :visible.sync="drawer"
      size="60%"
      :show-close="false"
      :modal-append-to-body="false"
      :append-to-body="false"
      destroy-on-close
      @opened="getIframe"
    >
      <iframe
        v-if="drawer"
        ref="iframe"
        :src="ifreamSrc"
        class="iframe-class"
        :height="getClientHeight()"
      />
    </el-drawer>
  </div>
</template>

<script>
import Sticky from '@/components/Sticky'

import { findPlan, saveServicePlan } from '@/api/planApi'
import { queryProductForPlanCreate, publicProduct } from '@/api/productItem'
import { API_CFS_SERVER_URL } from '@/Config'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { getLanguage } from '../../lang'
// import { shopList, findShopConfig } from '../../api/shop'
import Summernote from '@/components/Summernote'
import { checkCfsPermission } from '@/utils/permission'
import { guid } from '@/utils'
import { findLanguageListByKey } from '@/api/languageApi'
export default {
  name: 'PlanEdit',
  components: { Sticky, Summernote },
  props: {
    attrId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      noPlanGoodsSkuIds: [],
      loaded: false,
      disabledTranslateBtn: false,
      allowEdit: false,
      itemStatusList: [],
      effectiveDateData: null,
      shopConfigValue: false,
      ifreamSrc: '',
      drawer: false,
      rowCount: 0,
      plan: {
        publicVersion: null,
        productVersionHistory: [],
        queryVersion: null,
        baseProductId: '',
        cards: [],
        itemStatus: 'NEW',
        planType: 'FOR_MEMBER_BUY',
        planDetail: []
      },
      productList: [],
      freeCard: {
        cardName: 'FREE',
        days: 0,
        cardPrice: [],
        times: -1
      },
      planTypeList: [],
      selectList: [],
      dialogVisible: false,
      cardPrice: {},
      card: {
        times: 0,
        cardName: '',
        price: undefined,
        amount: undefined,
        cardPrice: [
          {
            amount: 0,
            price: 0,
            edit: false
          }
        ]
      },
      rules: {
        cardName: [
          {
            required: true,
            message: this.$t('rule.attributeName-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        times: [
          {
            required: true,
            message: this.$t('attributeType-cannot-be-empty'),
            trigger: 'change'
          }
        ],
        value: [
          {
            required: true,
            message: this.$t('attributeValue-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        price: [
          {
            required: true,
            message: this.$t('attributeValue-cannot-be-empty'),
            trigger: 'blur'
          }
        ]
      },
      langList: [],
      pageType: 'CREATE'
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {
    load() {
      const productList = this.productList
      const goodsSkuIds = this.plan.goodsSkuIds || []
      let noPlanGoodsSkuIds = []
      if (productList && productList.length > 0) {
        noPlanGoodsSkuIds = productList.filter(item => {
          if (goodsSkuIds.includes(item.key)) {
            return true
          }
          return false
        })
      }
      this.noPlanGoodsSkuIds = noPlanGoodsSkuIds
    },
    changeDescription(value) {
      this.plan.description = value
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    handleMessage(e) {
      console.log(e.data)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleDrawerClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleDrawerClose()
      } else if (!!e.data && !!e.data.verctlRes) {
        this.langList = e.data.verctlRes
        console.log('lang list res===', this.langList)
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleDrawerClose() {
      this.drawer = false
    },
    filterInputToNum() {
      this.plan.days = this.plan.days.replace(/[^\d]/g, '').replace(/^0+/, '')
    },
    getIframe(e) {
      var defaultLang = getLanguage()
      var systemLanguageCodes = JSON.parse(localStorage.getItem('SYSTEM_LANGUAGE_CODES'))
      if (systemLanguageCodes && systemLanguageCodes.data && systemLanguageCodes.data.defaultLanguage) {
        defaultLang = systemLanguageCodes.data.defaultLanguage
      }
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      this.ifreamSrc =
        ifreamSrc +
        '?system=gallery&module=admin&function=productItem&isIframe=true&language=' +
        defaultLang + '&sytemLanguage=' + getLanguage() + '&no=' + Math.ceil(Math.random() * 100) +
        '&verctl=1' // version control
      // version control
      const fmtData = this.processMutilLangData()
      const data = { data: fmtData, langList: this.langList }
      console.log('iframe 对象', this.$refs.iframe)
      // setTimeout(() => {

      //
      // }, 1000);
      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
      console.log('iframe 对象', this.$refs.iframe)
    },
    processMutilLangData() {
      const data = []
      data.push({
        name: this.$t('plan.table.name'),
        key: this.plan.id + '.mul.productItem.productName',
        newKey: this.plan.newId + '.mul.productItem.productName',
        type: 'input', value: this.plan.name
      })
      data.push({
        name: this.$t('plan.description'),
        key: this.plan.id + '.planDescription',
        newKey: this.plan.newId + '.planDescription',
        type: 'summernote',
        value: this.plan.description
      })
      if (this.plan.planType === 'FOR_MEMBER_BUY') {
        if (this.plan.cards) {
          const { cards = [] } = this.plan
          cards.forEach((item) => {
            data.push({
              name: item.cardName,
              key: item.id + '.cardName',
              newKey: item.newId + '.cardName',
              type: 'input',
              value: item.cardName
            })
          })
        }
      }
      return data
    },
    selectChange(a, b, c) {
      console.log('selectChange selectChange', a, b, c)
    },
    selectPlanTypeChange(value) {
      // if (value === 'FOR_MEMBER_BUY') {
      //   this.plan.planDetail = 'ALLOW_VIEW_SOME_RECIPE'
      // } else if (value === 'FOR_COUPON_USE') {

      // } else if (value === 'FOR_ACTIVITY_USE') {
      //   this.plan.planDetail = 'ALLOW_VIEW_SOME_RECIPE'
      // }
      const langList = this.langList.filter(res => res.key.indexOf('.cardName') < 0)
      this.langList = langList
    },
    setting() {
      if (this.width > 0) {
        return
      }
      this.drawer = true
    },
    back() {
      const pathObj = {
        path: '/spread/plan'
      }
      this.$router.push(pathObj)
    },
    async loadData() {
      await this.getProductList()
      var baseProductId = this.$route.query.baseProductId
      // page control
      const pageType = this.$route.meta.type
      console.log('page type===', pageType)
      this.pageType = pageType

      if (baseProductId) {
        await this.getRowData(null)
      } else {
        this.allowEdit = true
        this.disabledTranslateBtn = true
        this.loaded = true
      }
      this.getStatus()
    },

    getStatus() {
      getCfsDomainData({ domainCode: 'SHOP_PLAN_STATUS' }).then(res => {
        const allPlanStatus = res.data
        this.itemStatusList = []
        allPlanStatus.map((data, i) => {
          this.itemStatusList.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')',
            disabled: checkCfsPermission('SHOP_PLAN_STATUS.' + data.domainDataCode)
          })
        })
        console.log('拿Plan的狀態>', allPlanStatus)
      })
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    addCard() {
      this.dialogVisible = true
      this.card = {}
      this.card.cardPrice = []
    },
    handleEdit(index, row) {
      console.log('edit card msg===>', row)
      console.log('edit card msg index===>', index)
      var newCard = {
        id: row.id,
        newId: row.newId,
        cardName: row.cardName,
        days: row.days,
        amount: row.amount,
        price: row.price,
        cardPriceId: row.cardPrice[0].id,
        index: index,
        appleProductId: row.appleProductId
      }
      this.card = newCard
      this.dialogVisible = true
      console.log('this card===>', this.card)
    },
    handleDelete(index, row) {
      this.$confirm(
        this.$t('COMPONENTS.delete_Title'),
        this.$t('COMPONENTS.tip'),
        {
          confirmButtonText: this.$t('COMPONENTS.sure'),
          cancelButtonText: this.$t('COMPONENTS.cancel'),
          type: 'warning'
        }
      ).then(() => {
        var cards = this.plan.cards
        var newCards = []
        if (cards.length > 0) {
          if (cards.length === 1) {
            this.plan.cards = []
          } else {
            for (var i = 0; i < cards.length; i++) {
              if (i !== index) {
                newCards.push(cards[i])
              }
            }
          }
        }
        this.plan.cards = newCards
      })
    },
    addRow() {
      var re = /^[1-9]+[0-9]*]*$/
      var rowCount = 1
      if (re.test(this.rowCount)) {
        rowCount = this.rowCount
      }
      if (!this.card.cardPrice) {
        this.card.cardPrice = []
      }
      for (var i = 0; i < rowCount; i++) {
        this.card.cardPrice.push({
          price: 0,
          amount: 0,
          taxInPrice: '',
          edit: true
        })
      }
    },
    editRow(row) {
      row.edit = !row.edit
      console.info('++++', this.card)
    },
    filterUnlessCodeInNub(value, filterStr) {
      return value.replace(filterStr, '')
    },
    checkCardData(data) {
      if (!data.cardName) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.cardName.empty') // 名称不能為空
        })
        return true
      }
      if (!data.appleProductId) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.appleProductId.empty') // APPLE 產品ID不能為空
        })
        return true
      }
      if (!data.days || parseInt(data.days) === 0) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.days.empty') // 天数不能為空或為0
        })
        return true
      }
      if (!data.price || parseInt(data.price) === 0) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.price.empty')
        })
        return true
      }
      if (parseInt(data.amount) === 0) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.amount.zero') // 優惠價格不能為0
        })
        return true
      }
      if (!this.isNull(data.amount) && !this.isNull(data.price) && Number(data.amount) >= Number(data.price)) {
        this.$message({
          type: 'error',
          message: this.$t('plan.rule.card.amount.lessthan.price') // 優惠售價應該更便宜
        })
        return true
      }
      return false
    },
    addNewCard() {
      const check = this.checkCardData(this.card)
      if (check) {
        return
      }
      this.checkCardsIsExist(this.card, this.plan.cards)
      var newCard = {
        days: Math.abs(this.card.days),
        cardName: this.card.cardName,
        id: this.card.id,
        newId: guid(), // version control
        appleProductId: this.card.appleProductId,
        cardPrice: [{
          id: this.card.cardPriceId,
          newId: guid(), // version control
          price: Math.abs(this.card.price || 0),
          amount: this.card.amount ? Math.abs(this.card.amount) : ''
        }],
        times: -1,
        price: Math.abs(this.card.price || 0),
        amount: this.card.amount ? Math.abs(this.card.amount) : ''
      }
      this.plan.cards.push(newCard)
      this.dialogVisible = false
    },
    checkCardsIsExist(card, cards) {
      // var id = card.id
      var newCards = []
      if (cards.length > 0) {
        if (cards.length === 1) {
          if (card.index === 0) {
            this.plan.cards = []
          }
        } else {
          for (var i = 0; i < cards.length; i++) {
            if (i !== card.index) {
              newCards.push(cards[i])
            }
          }
          this.plan.cards = newCards
        }
      }
    },
    handleClose(done) {
      this.$confirm(this.$t('COMPONENTS.close.tip'))
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    publicBtn(type) {
      const baseProductId = this.plan.baseProductId
      const version = this.plan.queryVersion
      publicProduct(baseProductId, version, type).then(resCode => {
        this.getRowData(version)

        // PRODUCT_IS_NOT_APPROVED      //狀態是"批准"才能上架
        // PUBLIC_PRODUCT_SUCCESS       //上架成功
        // DISCONTINUE_PRODUCT_SUCCESS  //下架成功

        const msg = this.$t(resCode)
        alert(msg)
      }).catch(e => {
        console.error('publicProduct', e)
      })
    },
    onChangeVersion(productVersion) {
      this.getRowData(productVersion)
    },
    async getRowData(productVersion) {
      var baseProductId = this.$route.query.baseProductId
      console.log('id====>', baseProductId)

      var parmas = { baseProductId: baseProductId, productVersion: productVersion }
      const resp = await findPlan(parmas)
      var currentData = resp.data
      var newData = {
        id: '',
        name: '',
        description: '',
        goodsSkuIds: [],
        cards: []
      }
      newData.id = currentData.id
      newData.newId = guid() // version control
      newData.name = currentData.name
      newData.description = currentData.description
      newData.goodsSkuIds = currentData.goodsSkuIds
      newData.itemStatus = currentData.itemStatus
      newData.adminTag = currentData.adminTag
      newData.adminRemark = currentData.adminRemark
      newData.planType = currentData.planType
      newData.goodsSellType = currentData.goodsSellType
      newData.days = currentData.days
      newData.planDetail = currentData.planDetail
      newData.baseProductId = currentData.baseProductId
      newData.publicVersion = currentData.publicVersion
      newData.productVersionHistory = currentData.productVersionHistory
      newData.queryVersion = currentData.queryVersion

      if (currentData.effectiveDate !== null && currentData.invalidDate !== null) {
        this.effectiveDateData = [currentData.effectiveDate, currentData.invalidDate]
      } else {
        this.effectiveDateData = null
      }
      console.log('findPlan currentData', currentData)

      // 翻譯前準備
      // 替換CARD的ID
      if (currentData && currentData.cards.length !== 0) {
        currentData.cards.forEach(card => {
          card.newId = guid() // version control
          if (card.cardPrice && card.cardPrice.length && card.cardPrice.length !== 0) {
            card.cardPrice.forEach(priceItem => {
              priceItem.newId = guid() // version control
            })
          }
        })
      }

      const planType = currentData.planType

      if (planType === 'FOR_MEMBER_BUY') {
        currentData &&
            currentData.cards &&
            currentData.cards.length > 0 &&
            currentData.cards.forEach((item) => {
              item.price = item.cardPrice[0].price
              item.amount = item.cardPrice[0].amount
              newData.cards.push(item)
            })
      } else if (planType === 'FOR_COUPON_USE' || planType === 'FOR_ACTIVITY_USE') {
        if (!this.isNull(currentData.cards)) {
          const card = currentData.cards[0]
          newData.days = card.days
        }
      }
      this.plan = newData

      // this.plan = resp.data
      console.log(typeof resp.data.goodsSkuIds)
      console.log('edit plan data =====>', this.plan)

      const langParams = this.processMutilLangData()
      const keyList = langParams.map(res => {
        return res.key
      })
      const langRes = await findLanguageListByKey({ queryKeyList: keyList })
      const langList = langRes.data || []
      langList.forEach(lang => {
        langParams.forEach(d => {
          if (d.key === lang.key) {
            lang.newKey = d.newKey
          }
        })
      })
      this.langList = langList
      console.log('lang List=======', langList)

      this.allowEdit = newData.itemStatus !== 'APPROVED'
      this.loaded = true
      this.load()
    },
    cleanDataBeforeSave() {
      if (this.plan.planType !== 'FOR_MEMBER_BUY') {
        this.plan.effectiveDate = null
        this.plan.invalidDate = null
      } else {
        if (this.effectiveDateData !== null) {
          this.plan.effectiveDate = this.effectiveDateData[0]
          this.plan.invalidDate = this.effectiveDateData[1]
        } else {
          this.plan.effectiveDate = null
          this.plan.invalidDate = null
        }
      }

      // 如果沒有給食譜，就清空不需要的數據
      if (!this.plan.planDetail.includes('ALLOW_VIEW_SOME_RECIPE')) {
        this.plan.goodsSkuIds = []
      }
    },
    submitBtn() {
      // console.log('submitBtn===>', this.effectiveDateData)
      // console.log('save data===>', this.plan)

      const check = this.checkData()
      if (check) {
        return
      }

      this.cleanDataBeforeSave()

      this.plan.id = this.plan.newId // version controll

      const param = JSON.parse(JSON.stringify(this.plan))
      if (this.plan.planType !== 'FOR_MEMBER_BUY') {
        this.freeCard.days = param.days
        param.cards = [this.freeCard]
      }

      // version controll
      param.cards && param.cards.forEach(card => {
        card.id = card.newId
        card.cardPrice && card.cardPrice.forEach(p => {
          p.id = p.newId
        })
      })

      // translation version control
      const langList = JSON.parse(JSON.stringify(this.langList || []))
      langList.forEach(item => {
        item.verctl = true
        item.key = item.newKey
        item.id = null
      })
      param.langList = langList

      saveServicePlan(param).then((resp) => {
        if (resp && resp.data && resp.data.resCode === 'DUPLICATE') {
          this.$notify({
            title: this.$t('COMPONENTS.tip'),
            message: this.$t('dupicate plan'),
            type: 'error'
          })
          return
        }
        if (resp && resp.data && resp.data.resCode === 'ADMIN_TAG_DUPLICATE') {
          this.$notify({
            title: this.$t('COMPONENTS.tip'),
            message: this.$t('Admin Tag is already used'),
            type: 'error'
          })
          return
        }
        if (resp) {
          this.$notify({
            title: this.$t('COMPONENTS.success'),
            message: this.$t('COMPONENTS.save_Success'),
            type: 'success'
          })
          this.back()
        } else {
          this.$notify({
            title: this.$t('COMPONENTS.tip'),
            message: this.$t('save plan error'),
            type: 'warning'
          })
          return
        }
      })
    },
    checkData() {
      const check = false
      const data = this.plan

      console.log('checkData', data)

      if (!data.name) {
        this.$message({
          type: 'error',
          message: this.$t('rule.plan.name')
        })
        return true
      }

      if (this.isNull(data.planDetail) || this.isNull(data.planDetail.length) || data.planDetail.length === 0) {
        this.$message({
          type: 'error',
          message: this.$t('Plan detail is required')
        })
        return true
      }

      if (data.planDetail.includes('ALLOW_VIEW_SOME_RECIPE')) {
        if (!data.goodsSkuIds || data.goodsSkuIds.length === 0) {
          this.$message({
            type: 'error',
            message: this.$t('rule.plan.product')
          })
          return true
        }
      }

      if (data.planType === 'FOR_MEMBER_BUY') {
        if (!data.cards || data.cards.length === 0) {
          this.$message({
            type: 'error',
            message: this.$t('rule.plan.cards')
          })
          return true
        }
      } else {
        if (this.isNull(data.days)) {
          this.$message({
            type: 'error',
            message: this.$t('Please enter the effective days')
          })
          return true
        }
      }
      if (!data.itemStatus) {
        this.$message({
          type: 'error',
          message: this.$t('rule.plan.itemStatus')
        })
        return true
      }
      return check
    },

    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    filterMethod(query, item) {
      let result = true
      if (!this.isNull(query)) {
        var queryArray = query.split(' ')
        if (!this.isNull(queryArray) && queryArray.length !== 0) {
          // result = true

          for (const data of queryArray) {
            if (item.pinyin.indexOf(data) === -1) {
              result = false
            }
          }
        }
      }
      return result
    },
    async getProductList() {
      const query = {}

      //  廚師在後台只能查自己建立的計畫
      //  CFS那邊有兩個權限
      //  SEARCH_ALL_PLAN
      //  SEARCH_OWN_PLAN

      query.createdBy = 'NA'

      // 只能搜尋自建立的計畫
      const searchOwn = !checkCfsPermission('SEARCH_OWN_PLAN')
      if (searchOwn) {
        const loginName = localStorage.getItem('user_profile')
        query.createdBy = loginName
      }

      // 可以搜尋所有人的計畫
      const searchAll = !checkCfsPermission('SEARCH_ALL_PLAN')
      if (searchAll) {
        query.createdBy = null
      }
      const resp = await queryProductForPlanCreate(query)
      console.log('product List===>', resp)
      var data = resp.data
      var productList = []
      data.forEach((item) => {
        const label = item.adminTag + ' (' + item.itemStatus + ')'
        productList.push({
          label: label,
          key: item.skuId,
          pinyin: label
        })
      })
      this.productList = productList
    }
  }
}
</script>
<style lang="scss" >
//左邊的框框
#planPageTransfer > .el-transfer-panel:first-child{
  width: 380px;
};
//右邊的框框
#planPageTransfer > .el-transfer-panel:last-child{
  width: 380px;
};

.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.component {
  margin: 10px;
}
.tree-box {
  margin: 15px 0 100px 55px;
  border: #a6e0d8 solid 2px;
  padding: 15px;
}
.form-item-head {
  margin-bottom: 8px;
  font-size: 12px;
}
.hide .el-upload--picture-card {
  display: none;
}
.iframe-class {
  width: 100%;
  border: 0px;
}
.el-drawer .el-drawer__header{
  background-color: #2ea6f1 !important;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
  margin-bottom: 22px;
}
.el-drawer .el-drawer__header :first-child{
  margin-bottom: 18px;
}
.el-drawer .el-drawer__body{
  margin-top: -22px;
}
</style>
