var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-top" } },
        [
          _c(
            "el-form",
            {
              staticClass: "search-form-inline",
              attrs: {
                "label-suffix": ":",
                "label-position": "left",
                "hide-required-asterisk": "",
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("productItem.shop") } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.inselect"),
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.query.shopId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "shopId", $$v)
                                },
                                expression: "query.shopId",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                { attrs: { label: "", value: "" } },
                                [_vm._v(_vm._s(_vm.$t("COMPONENTS.inselect")))]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.shop.shopName, function (shopName) {
                                return _c("el-option", {
                                  key: shopName.value,
                                  attrs: {
                                    label: shopName.label,
                                    value: shopName.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Member Account") } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.query.memberName,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "memberName", $$v)
                              },
                              expression: "query.memberName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("review.productName") } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.inselect"),
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.query.productId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "productId", $$v)
                                },
                                expression: "query.productId",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                { attrs: { label: "", value: "" } },
                                [_vm._v(_vm._s(_vm.$t("COMPONENTS.inselect")))]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.productList, function (product) {
                                return _c("el-option", {
                                  key: product.baseProductId,
                                  attrs: {
                                    label: product.adminTag,
                                    value: product.baseProductId,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("productItem.status") } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.inselect"),
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.query.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "status", $$v)
                                },
                                expression: "query.status",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                { attrs: { label: "", value: "" } },
                                [_vm._v(_vm._s(_vm.$t("COMPONENTS.inselect")))]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.statusList, function (status) {
                                return _c("el-option", {
                                  key: status.value,
                                  attrs: {
                                    label: status.label,
                                    value: status.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "allButton",
                      attrs: { disabled: _vm.checkPermission("comment") },
                      on: { click: _vm.loadData },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.search")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "allButton",
                      attrs: { disabled: _vm.checkPermission("comment.edit") },
                      on: { click: _vm.handleOneClickApproval },
                    },
                    [_vm._v(_vm._s(_vm.$t("review.oneClickApproval")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            "tooltip-effect": "dark",
            data: _vm.data,
            "header-row-class-name": "table-header",
            size: "mini",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _vm._v("\n    >\n    "),
          _c("el-table-column", {
            attrs: { type: "selection", align: "center", fixed: "left" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("review.productName"),
              prop: "productName",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Member Account"),
              prop: "memberName",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.shop"),
              prop: "shopName",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("review.rate"),
              prop: "reviewRate",
              align: "left",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-rate", {
                      attrs: { disabled: "" },
                      model: {
                        value: scope.row.reviewRate,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "reviewRate", $$v)
                        },
                        expression: "scope.row.reviewRate",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("review.title"),
              align: "left",
              prop: "reviewTitle",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("review.content"),
              align: "left",
              prop: "reviewContent",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.status"),
              align: "left",
              prop: "statusLabel",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("COMPONENTS.operation"),
              fixed: "right",
              "min-width": "180px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleView(scope.$index, scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("COMPONENTS.view")))]
                    ),
                    _vm._v(" "),
                    scope.row.status !== "APPROVED"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm.checkPermission("comment.edit"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveOneClickAction(
                                  [scope.row],
                                  "APPROVED"
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("review.approve")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status !== "REJECT"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm.checkPermission("comment.edit"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveOneClickAction(
                                  [scope.row],
                                  "REJECT"
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("review.reject")))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.query.total > 0,
                    expression: "query.total > 0",
                  },
                ],
                staticClass: "hidden-xs-only",
                attrs: {
                  total: _vm.query.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.loadData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                staticClass: "hidden-sm-and-up",
                attrs: {
                  "hide-on-single-pag": _vm.query.total > 0,
                  total: _vm.query.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  "pager-count": 7,
                  layout: "pager, prev, next",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.loadData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }