<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div :key="randomKey" class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-row v-if="visible" :gutter="20">
          <el-col :xl="24" :md="24" :sm="24" :xs="24">
            <div style="float: right;">
              <el-button type="primary" @click="deleteAllCoupon">{{ $t('COMPONENTS.delete') }}</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </transition>
    <ErgoAppFrame :key="randomKey" formkey="Coupon" formView="master" :languageId="languageId" :useShadowDom="true" />
  </div>
</template>

<script>
import ErgoAppFrame from '@/components/ErgoAppFrame'
import { getLanguage } from '@/lang/index'
import { deleteAllCoupons } from '@/api/couponApi'
export default {
  name: 'CouponList',
  components: { ErgoAppFrame },
  data() {
    return {
      languageId: getLanguage() === 'en-US' ? 'en' : getLanguage().replace('-', '_'),
      randomKey: 0,
      visible: process.env.NODE_ENV === 'development'
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.randomKey = Math.floor(Math.random() * 100) + 1
    },
    deleteAllCoupon() {
      deleteAllCoupons().then(res => {
        console.log('delete all coupon result===>', res)
        // 刪除成功
        if (res && res.data && res.data.returnCode === 'SUBMIT_SUCCESS') {
          this.$notify({
            title: this.$t('COMPONENTS.success'),
            message: this.$t('COMPONENTS.delete_Success'),
            type: 'success'
          })
          this.loadData()
        } else {
          this.$notify({
            title: this.$t('COMPONENTS.error'),
            message: this.$t('COMPONENTS.error'),
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style>

</style>
