<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">{{
          title === "create" ? $t('COMPONENTS.add') : $t('COMPONENTS.edit')
        }}</el-col>
        <el-col :span="12">
          <el-button v-if="oldStatus === 'APPROVED' && checkSchedule() === 'GOING'" :disabled="checkPermission('ACTIVITY.DELETE')" @click="stopSold()">{{ $t("COMPONENTS.endEarly") }}</el-button>        <!-- 提早結束 -->
          <el-button v-if="!isNull(dataForm.id)" :disabled="checkPermission('ACTIVITY.DELETE')" @click="deleteActivity(dataForm)">{{ $t("COMPONENTS.delete") }}</el-button>
          <el-button @click="back()">{{ $t('COMPONENTS.return') }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkSavePermission()"
            @click="saveData"
          >{{ $t('COMPONENTS.save') }}
          </el-button>

        </el-col>
      </el-row>
    </sticky>
    <el-form
      ref="dataForm"
      :model="dataForm"
      status-icon
      label-position="left"
      label-width="200px"
      class="search-form-inline"
      label-suffix=":"
    >
      <el-row>
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Admin Tag')" prop="adminTag">
                  <el-input v-model="dataForm.adminTag" style="width:400px" />
                  <el-tooltip class="item" effect="dark" :content="$t('Admin.adminTag.tooltip')" placement="top">
                    <i class="el-icon-warning" />
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Admin Remark')" prop="adminRemark">
                  <el-input v-model="dataForm.adminRemark" style="width:400px" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('productItem.shop')" required>
                  <el-select
                    v-model="dataForm.shopId"
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 400px"
                    :disabled="oldStatus === 'APPROVED'"
                  >
                    <el-option
                      v-for="item in shopOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Activity Title')" required>
                  <el-input v-model="dataForm.activityTitle" style="width:400px" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :key="summernoteKey" :label="$t('Activity Description')">
                  <Summernote :value="dataForm.activityDesc" :height="150" style="width:750px" @change="changeDescription" />
                </el-form-item>

              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Activity Type')" required>
                  <el-select
                    v-model="dataForm.activityType"
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width:400px"
                    :disabled="oldStatus === 'APPROVED'"
                  >
                    <el-option value="NEW_REG" :label="$t('NEW_REG.label')" />

                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('COMPONENTS.time')" required>
                  <el-row>

                    <el-col :span="24">
                      <el-form-item prop="dateRange">
                        <el-date-picker
                          v-model="dataForm.date"
                          type="datetimerange"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          format="yyyy-MM-dd HH:mm:ss"
                          :disabled="oldStatus === 'APPROVED'"
                          :default-time="defaultTime"
                        />
                        <el-tooltip placement="top">
                          <template #content>

                            {{ $t('ACTIVITY_PAGE.NOTE.0') }}<br>
                            {{ $t('ACTIVITY_PAGE.NOTE.1') }}<br>
                            {{ $t('ACTIVITY_PAGE.NOTE.2') }}<br>
                            {{ $t('ACTIVITY_PAGE.NOTE.3') }}<br>
                            {{ $t('ACTIVITY_PAGE.NOTE.4') }}<br>
                            <!-- 注意<br/>
                            1.活動結束時間不可小於今日<br/>
                            2.活動啟用後，起始日期不可以改，只能提早結束<br/>
                            3.活動結束後，不能再重複使用，但是可以再另外建立同樣的活動<br/>
                            4.提早結束活動時，活動結束時間會改當下的時間<br/> -->
                          </template>
                          <i class="el-icon-warning" />
                        </el-tooltip>
                      </el-form-item>
                    </el-col>

                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Status')" required>
                  <el-select
                    v-model="dataForm.status"
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 408px"
                    :disabled="oldStatus === 'APPROVED'"
                  >
                    <el-option
                      v-for="item in statusOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <br>

            <el-row v-if="dataForm.activityType === 'NEW_REG'" :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Plan')" required>
                  <div v-if="oldStatus !== 'APPROVED'">
                    <template>
                      <el-transfer
                        id="activityPageTransfer"
                        v-model="dataForm.detailList"
                        tag-list-unselected
                        filterable
                        :titles="[$t('tagList.unselected'), $t('tagList.selected')]"
                        :filter-method="filterMethod"
                        filter-placeholder="Search here"
                        :data="planOptions"
                      />
                    </template>
                  </div>
                  <div v-if="oldStatus === 'APPROVED'">
                    <template v-for="(item, index) in planOptions">
                      <li v-if="dataForm.detailList.includes(item.key)" :key="index">{{ item.label }}</li>
                    </template>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { adminSaveData, adminFoundOneData, formatDate, adminDeleteData, adminStopActivity } from '@/api/activityApi'
import { getLanguage } from '@/lang/index'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { shopList } from '@/api/shop'
import { findAllPlanList } from '@/api/planApi'
import { checkCfsPermission } from '@/utils/permission'
import Summernote from '@/components/Summernote'
export default {
  name: 'ProductAttributeCreate',
  components: { Sticky, Summernote },
  data() {
    return {
      defaultTime: ['00:00:00', '23:59:59'],
      title: 'create',
      loaded: true,
      shopOptions: [],
      planOptions: [],
      statusOptions: [],
      dataForm: {
        id: null,
        adminTag: null,
        adminRemark: null,
        shopId: null,
        activityTitle: '',
        activityDesc: '',
        activityType: 'NEW_REG',
        attrJson: '',
        startPublicDate: '',
        endPublicDate: '1',
        status: 'NEW',
        detailList: []
      },
      oldStatus: null,
      summernoteKey: 1
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    reloadSummernote() {
      this.summernoteKey = this.summernoteKey + 1
    },
    back() {
      const pathObj = {
        path: '/spread/activity'
      }
      this.$router.push(pathObj)
    },
    checkSavePermission() {
      if (this.title === 'update') {
        return checkCfsPermission('ACTIVITY.EDIT')
      } else {
        return checkCfsPermission('ACTIVITY.ADD')
      }
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getData() {
      this.title = this.$route.query.title
      if (this.title === 'update') {
        this.id = this.$route.query.id
        adminFoundOneData(this.id).then((res) => {
          console.log('article----', res)

          const dataForm = res

          if (dataForm.activityType === 'NEW_REG') {
            // 時間轉換
            dataForm.date = []
            dataForm.date.push(formatDate(new Date(dataForm.startPublicDate)))
            dataForm.date.push(formatDate(new Date(dataForm.endPublicDate)))

            // 計畫轉換給transfer穿梭框
            const oldDetailList = JSON.parse(JSON.stringify(dataForm.detailList))
            dataForm.detailList = []
            oldDetailList.map(item => {
              dataForm.detailList.push(item.parentId)
            })

            this.oldStatus = dataForm.status
          }

          this.dataForm = dataForm
          this.loaded = false
          this.reloadSummernote()
        })
      }
    },
    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    filterMethod(query, item) {
      let result = true
      if (!this.isNull(query)) {
        var queryArray = query.split(' ')
        if (!this.isNull(queryArray) && queryArray.length !== 0) {
          // result = true

          for (const data of queryArray) {
            if (item.pinyin.indexOf(data) === -1) {
              result = false
            }
          }
        }
      }
      return result
    },
    loadData() {
      var permissionList = []

      // 取得商品List
      permissionList.push(shopList())
      permissionList.push(findAllPlanList('', { planType: 'FOR_ACTIVITY_USE', itemStatus: 'APPROVED' }))
      permissionList.push(getCfsDomainData({ domainCode: 'SHOP_ACTIVITY_STATUS' }))

      Promise.all(permissionList).then((resp) => {
        let i = 0

        // ///////////////////////////////////////   拿所有的店(也只有一間店)
        const shopData = resp[i++].data
        this.shopOptions = []
        if (shopData && shopData.length) {
          shopData.map((d) => {
            this.shopOptions.push({
              value: d.id,
              label: d.name
            })
          })
          // 預設搜尋的商店ID
          this.dataForm.shopId = this.shopOptions[0].value
        }

        // ////////////////////////////////////////   拿活動的所有計畫
        const planData = resp[i++].data
        this.planOptions = []
        if (!this.isNull(planData) && !this.isNull(planData.length) && planData.length !== 0) {
          planData.map((item) => {
            this.planOptions.push({
              label: item.adminTag,
              key: item.baseProductId,
              pinyin: item.adminTag
            })
          })
        }

        // ////////////////////////////////////////   拿活動的所有狀態
        const allStatus = resp[i++].data
        this.statusOptions = []
        allStatus.map((data, i) => {
          this.statusOptions.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')',
            disabled: checkCfsPermission('SHOP_ACTIVITY_STATUS.' + data.domainDataCode)
          })
        })
        console.log('拿活動的所有狀態>', this.statusOptions)

        this.getData()
      })
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    checkData(activity) {
      const type = activity.activityType

      console.log('checkData activity', activity)

      if (type === 'NEW_REG') {
        if (this.isNull(activity.activityTitle)) {
          this.errorMsg(this.$t('Activity Title cannot be empty'))
          return false
        }

        // 一旦允許後，只需檢查部分數據，以下數據都不會保存
        const noNeedCheck = this.oldStatus !== 'APPROVED'
        if (noNeedCheck) {
          if (this.isNull(activity.date) || activity.date.length !== 2) {
            this.errorMsg(this.$t('Date cannot be empty'))
            return false
          }

          const startPublicDate = new Date(activity.date[0])
          const endPublicDate = new Date(activity.date[1])
          const now = new Date()
          // 注意
          // 1.活動結束時間不可小於今日
          if (endPublicDate < now) {
            this.errorMsg(this.$t('ACTIVITY_PAGE.check.1')) // 活動結束時間不可小於今日
            return false
          }

          if (+endPublicDate === +startPublicDate) {
            this.errorMsg(this.$t('ACTIVITY_PAGE.check.2')) // 活動時間錯誤，不可以為同一個時間
            return false
          }

          // 2.活動一旦啟用，起始日期不可以改，只能修改結束日期
          if (this.oldStatus === 'APPROVED' && this.dataForm.startPublicDate !== startPublicDate) {
            this.errorMsg(this.$t('ACTIVITY_PAGE.check.3')) // 活動一旦啟用，日期不可以改，只能提早結束
            return false
          }

          if (this.isNull(activity.detailList) || activity.detailList.length === 0) {
            this.errorMsg(this.$t('ACTIVITY_PAGE.check.4')) // 計畫不能為空
            return false
          }
        }
        return true
      }
      return false
    },
    saveData() {
      const activity = JSON.parse(JSON.stringify(this.dataForm))

      if (!this.checkData(activity)) {
        return
      }

      if (activity.activityType === 'NEW_REG') {
        if (activity.date[0]) {
          activity.startPublicDate = new Date(activity.date[0])
        }
        if (activity.date[1]) {
          activity.endPublicDate = new Date(activity.date[1])
        }
        activity.detailListForSave = activity.detailList
        activity.detailList = []
      }
      activity.lastModifiedDate = null
      activity.createdDate = null

      // this.$refs['dataForm'].validate((valid) => {
      // 原先是在這裡save
      // })

      adminSaveData(activity).then((res) => {
        if (res === 'ADMIN_TAG_DUPLICATE') {
          this.errorMsg(this.$t('Admin Tag is already used'))
        } else if (res === 'PLAN_PRODCUT_DUPLICATE') { // 請檢查計畫中的產品，產品不能重複
          this.errorMsg(this.$t('ACTIVITY_PAGE.PLAN_PRODCUT_DUPLICATE.error.msg'))
        } else if (res === 'SUCCESS') {
          this.$message.success(this.$t('COMPONENTS.save_Success'))
          this.back()
        } else {
          this.errorMsg('錯誤')
        }
      })
    },
    changeDescription(value) {
      this.dataForm.activityDesc = value
    },
    stopSold() {
      console.log('sdfgsdfg')
      const schedule = this.checkSchedule()
      const id = this.dataForm.id
      // 只有APPROVED且在運行中的活動
      // 才能夠提早結束
      if (schedule === 'GOING' && this.oldStatus === 'APPROVED') {
        this.confirmJob(this.$t('ACTIVITY_PAGE.stopActivity.confirm.msg'), () => { // 你確定要提早結束活動嗎
          adminStopActivity(id).then((response) => {
            if (response === 'SUCCESS') {
              this.getData()
              this.successMsg(this.$t('ACTIVITY_PAGE.stopActivity.success.msg')) // 活動已經提早結束
            } else {
              this.errorMsg(this.$t('ACTIVITY_PAGE.stopActivity.error.msg')) // 不允許提早結束
            }
          })
        })
      } else {
        this.errorMsg(this.$t('ACTIVITY_PAGE.stopActivity.error.msg')) // 不允許提早結束
      }
    },
    deleteActivity(row) {
      this.confirmJob(this.$t('ACTIVITY_PAGE.delete.msg'), () => { // 你確定要刪除活動嗎，如果這個活動沒有被使用，才允許刪除
        adminDeleteData({ id: row.id }).then((response) => {
          if (response === 'SUCCESS') {
            this.back()
            this.successMsg(this.$t('ACTIVITY_PAGE.delete.success.msg')) // 刪除活動成功
          } else if (response === 'ACTIVITY_IN_USED') {
            this.errorMsg(this.$t('ACTIVITY_PAGE.delete.error.inUsed.msg')) // 此活動已經會員使用，禁止刪除
          } else {
            this.errorMsg('錯誤')
          }
        })
      })
    },
    confirmJob(msg, job) {
      this.$confirm(msg, this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        job()
      })
    },
    errorMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.error'),
        message: message,
        type: 'error'
      })
    },
    successMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.success'),
        message: message,
        type: 'success'
      })
    },
    checkSchedule() {
      const row = this.dataForm
      let result = null
      if (!this.isNull(row.id)) {
        const now = new Date()
        const startPublicDate = new Date(row.startPublicDate)
        const endPublicDate = new Date(row.endPublicDate)

        if (this.oldStatus === 'APPROVED') {
          if (now < startPublicDate) {
            result = 'NON_START' // 活動未開始
          } else if (startPublicDate < now && now < endPublicDate) {
            result = 'GOING' // 活動進行中
          } else if (endPublicDate < now) {
            result = 'FINISHED' // 活動已結束
          }
        } else {
          result = 'WAIT_APPROVED' // 等待批准
        }
      }
      return result
    }

  }
}
</script>

<style lang="scss" >
//左邊的框框
#activityPageTransfer > .el-transfer-panel:first-child{
  width: 300px;
};
//右邊的框框
#activityPageTransfer > .el-transfer-panel:last-child{
  width: 300px;
};

//CSS 災奈...高度會被改
.card-box {
  height: unset !important;
}
</style>
