var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-video" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: "#",
            "list-type": "picture-card",
            "auto-upload": false,
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function (ref) {
                var file = ref.file
                return _c("div", {}, [
                  _c("img", {
                    staticClass: "el-upload-list__item-thumbnail",
                    attrs: { src: file.url, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "el-upload-list__item-actions" }, [
                    _c(
                      "span",
                      {
                        staticClass: "el-upload-list__item-preview",
                        on: {
                          click: function ($event) {
                            return _vm.handlePictureCardPreview(file)
                          },
                        },
                      },
                      [_c("em", { staticClass: "el-icon-zoom-in" })]
                    ),
                    _vm._v(" "),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function ($event) {
                                return _vm.handleDownload(file)
                              },
                            },
                          },
                          [_c("em", { staticClass: "el-icon-download" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(file)
                              },
                            },
                          },
                          [_c("em", { staticClass: "el-icon-delete" })]
                        )
                      : _vm._e(),
                  ]),
                ])
              },
            },
          ]),
        },
        [
          _c("em", {
            staticClass: "el-icon-plus",
            attrs: { slot: "default" },
            slot: "default",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }