import Vue from 'vue'
import Cookies from 'js-cookie'
import Element from 'element-ui'
import '@/styles/index.scss' // global css
import App from './App'
import store from './store'
import router from './router'
import jQuery from 'jquery'
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import elementEnLocale from 'element-plus/lib/locale/lang/en'
import * as filters from './filters' // global filters
import i18n from './lang'
import { keycloakConfig, SMART_FORM_URL } from './Config'
import { setAccessToken, setRefreshAccessToken, setUserName } from './service/AuthService'
import { fetchLanguageList } from '@/api/languageApi'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import './styles/element-variables.scss'
import 'element-ui/lib/theme-chalk/display.css'
import axios from 'axios'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import { systemConfig } from './api/mallorderApi/systemConfigApi'
import { getCfsUserInfoAndPermission } from './api/cfsApi/getUserAndPormission'
import _ from 'lodash'
import { getLanguage } from '@/lang/index'
window.$ = require('jquery')
initializeApp().then(() => {
  /**
   * If you don't want to use mock-server
   * you want to use MockJs for mock api
   * you can execute: mockXHR()
   *
   * Currently MockJs will be used in the production environment,
   * please remove it before going online ! ! !
   */
  if (process.env.NODE_ENV === 'production') {
    const { mockXHR } = require('../mock')
    mockXHR()
  }

  Vue.use(Element, {
    // i18n: (key, value) => i18n.t(key, value),
    elementEnLocale,
    size: Cookies.get('size') || 'medium' // set element-ui default size
  })

  // register global utility filters
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })

  Vue.prototype.$getLanaugeData = function(data) {
    let resultStr = ''
    var lang = i18n.locale
    if (data) {
      try {
        resultStr = evil('data.' + lang)
      } catch (err) {
        return resultStr
      }
    }
    return resultStr || ''
  }
  Vue.prototype.$addStoreValue = function(key, value) {
    var newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function(k, val) {
        localStorage.setItem(k, val)
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, value)
  }
  Vue.prototype.$getLanaugeFiled = function(data) {
    let resultStr = ''
    resultStr = data + '.' + i18n.locale
    return resultStr || ''
  }

  Vue.prototype.jQuery = jQuery

  Vue.prototype._ = _

  Vue.config.productionTip = false

  // let keycloak = VueKeyCloak({ onLoad: 'login-required', checkLoginIframe: false, });
  // Vue.prototype.$keycloak = keycloak

  Vue.use(VueKeyCloak, {
    init: {
      onLoad: 'login-required',
      checkLoginIframe: false
    },
    config: keycloakConfig,
    onReady: kc => {
      saveTokenJob(kc)
      loginSmartform(kc)

      getUserInfo().then(() => {
        new Vue({
          el: '#app',
          router,
          store,
          i18n,
          render: h => h(App)
        })
      }).catch(() => {
        // reload的目的是要寫入config
        window.location.reload(false)
      })

      // token有時效性，需定時更新Token
      setInterval(() => {
        kc.updateToken(70).success((refreshed) => {
          if (refreshed) {
            saveTokenJob(kc)
          }
        })
      }, 60000)
    },

    onInitError: kc => {
      console.log('OIDC Keyclaok onInitError', kc)
    },
    onAuthLogout: kc => {
      console.log('OIDC Keyclaok onAuthLogout', kc)
    },
    onAuthRefreshError: kc => {
      console.log('OIDC Keyclaok onAuthRefreshError', kc)
    },
    onAuthRefreshSuccess: kc => {
      console.log('OIDC Keyclaok onAuthRefreshSuccess', kc)
    },
    onInitSuccess: kc => {
      console.log('OIDC Keyclaok onInitSuccess', kc)
    }
  })
}).catch(() => {
  // reload的目的是要寫入config
  window.location.reload(false)
})

function saveTokenJob(kc) {
  if (kc) {
    console.log('OIDC Keyclaok Job.......kc=>', kc)
    console.log('OIDC Keyclaok Job.......user_name=>', kc.tokenParsed.user_name)
    console.log('OIDC Keyclaok Job.......refreshToken=>', kc.refreshToken)
    console.log('OIDC Keyclaok Job.......token=>', kc.token)
    setRefreshAccessToken(kc.refreshToken) // 目前沒有使用到refreshToken....
    setUserName(kc.tokenParsed.user_name)
    setAccessToken(kc.token)
  }
}

async function loginSmartform(keycloak) {
  console.log('loginSmartform start', keycloak, SMART_FORM_URL)
  if (!SMART_FORM_URL) return
  try {
    const language = getLanguage()
    await window.StimUtils.preloadSmartForm(SMART_FORM_URL)
    await window.StimUtils.signOut()
    const user = await window.StimUtils.signInWithKeycloak(keycloak)
    // const user = await window.StimUtils.signInWithEmailAndPassword('admin', 'admintest')
    console.log('loginSmartform user is ready!', language, user)
    // await window.StimUtils.setLanguage(language === 'en-US' ? 'en' : language.replace('-', '_'))
    localStorage.removeItem('SFAF_language_data')
  } catch (e) {
    console.log('login smartform error', e)
  }
}

function evil(fn) {
  var Fn = Function // 一个变量指向Function，防止有些前端编译工具报错
  return new Fn('return ' + fn)()
}

function initializeApp() {
  return new Promise((resolve, reject) => {
    let isResolve = false
    const config = localStorage.getItem('mallAdmin_client_config')
    const refreshDate = localStorage.getItem('mallAdmin_client_config_refresh_date')
    if (refreshDate && config) {
      const now = new Date().getTime()
      const timeDiff = now - Number(refreshDate)
      console.log('timeDiff', timeDiff)

      // 3秒內如果有更新config，就視為config已經抓到，就可以進行下一步，，所以回傳resolve()
      if (timeDiff < 3000) {
        isResolve = true
      }
    }

    if (isResolve) {
      resolve()
    } else {
      const publicPath = process.env.NODE_ENV !== 'development' ? '/malladmin/' : '/'
      const permissionList = []
      permissionList.push(axios.get(window.location.origin + publicPath + 'client-config.txt'))
      permissionList.push(axios.get(window.location.origin + publicPath + 'version.txt'))
      Promise.all(permissionList).then(resp => {
        console.log('resp info', resp)
        var result = resp[0]
        var versionResult = resp[1]
        localStorage.setItem('VERSION_NO', versionResult.data)
        let encrypted = null

        try {
          if (window.location.origin.indexOf('macdev-chopchopcook.twds.tophenix.com') !== -1) {
            encrypted = '8JUIsACRAK3KowCg59oY3NqWDwgMbBUFYAOauLVFFMUT9dudHWDgR/OlKrmUdDGeTZIE1vdRqivN1gvnbf98+yB0626ggsF9slWmu6zNXRQKqVV/p9W6zsu3QhxIt8aje2dFc+tT+TEK5v1hmFEelsVSvmlS81DGAVdL6bgY1RshpCv6iWILSqQdYCQzYmakItUsDlOxpUMQ/ObWPup74pXqkNZe/MYowPWcR2nOL3PHJ+Xa8My5M2zrxbavE7Th98M5c7JaBlhiV03Hhn/oznTd11cTf407rVNBJVGVDjkumYAUiBAVt34JDPCouvXM+i/v77uS3+y9Xg1cR0gexSZwFhwfAR+aBFuC8qR3zXiB/djETdV1Y3HdXQ+DrGtPxt9OtWFj/XJWx8JTOOOmYpF2RswTdlFNnRrkzarRnNm5w0XfwZhSLC0OHL1EyL//GACxieKy93003KSQ+5C64iXiYGhkING5DcpCzSKZ7sRe9TCHQlcQxMI+TS83HKbG8PVCBHP8OVWEjZWIRGCblZdkT5tO/utCcetGwz4yyzRjxKWgPH0i+GULkKu7rkprBuswJ9dcvSKnqIZqRhtVHhK8ZHu++CpuVP7NWRi95VP3sC8u+j/5JpYH9BonBuTLNWxhYuxAaiCtsEvU+gpwyD8NdiXuVU1Qi3GU+EOYFAn65rXJwHFp4zQvXh6NQyNi76x+JsbsdX8x2fjdXE9ILzcQOmv1HP7KdZrweMAmFW8UG0W6kV7MBMMMle5pqbC0qo3ulZRXVCiVGk3ZP77Dr9EvmffKvBFoOoe0Z9yopqCyUrbjCVmz7eMNw25eQY60'
          } else {
            encrypted = result.data
          }

          localStorage.setItem('mallAdmin_client_config', encrypted)
          localStorage.setItem('mallAdmin_client_config_refresh_date', new Date().getTime())

          // 更新config，此時需要reload，回傳reject()
          // 等等就會跑window.location.reload(false);
          setTimeout(_ => reject(), 50)

          console.log('啟動中.......initializeApp')
        } catch (e) {
          console.log('decrypte error', e)
        }
      })
    }
  })
}

function getUserInfo() {
  return new Promise((resolve, reject) => {
    let isResolve = false

    const refreshDate = localStorage.getItem('getUserInfo_refresh_date')
    if (refreshDate) {
      const now = new Date().getTime()
      const timeDiff = now - Number(refreshDate)
      console.log('timeDiff', timeDiff)

      // 3秒內如果有更新config，就視為config已經抓到，就可以進行下一步，，所以回傳resolve()
      if (timeDiff < 3000) {
        isResolve = true
      }
    }

    if (isResolve) {
      resolve()
    } else {
      fetchLanguageList().then(response => {
        localStorage.setItem('SYSTEM_LANGUAGE_CODES', JSON.stringify(response))
      })

      systemConfig().then((configDatas) => {
        if (configDatas && configDatas.length > 0) {
          let party = null
          let productMenuFunctionStr = null

          for (const config of configDatas) {
            if (config.moduleCode === 'LOGIN' && config.functionCode === 'LOGIN') {
              party = config.itemValue
            }
            if (config.moduleCode === 'ADMIN' && config.functionCode === 'MENU' && config.item === 'PRODUCT_SETTING') {
              productMenuFunctionStr = config.itemValue
            }
          }

          // setProductMenuFunction()
          localStorage.removeItem('productMenuFunction')
          localStorage.setItem('productMenuFunction', productMenuFunctionStr)

          localStorage.setItem('partyCompanyName', party)
          var query = {
            type: 'PARTY',
            partyCompanyName: party
          }
          localStorage.removeItem('userPartIds')
          localStorage.removeItem('permissionList')
          localStorage.removeItem('partyGroupId')
          getCfsUserInfoAndPermission(query).then((res) => {
            const { data } = res
            var permissionList = []
            const userPartIds = []

            var hash = {}
            data.groupReply &&
              data.groupReply.forEach((element) => {
                // const partyGroupName = element.description  //"Gallery admin最大權限"
                const partyGroupId = element.key // "000001"
                localStorage.setItem('partyGroupId', partyGroupId)
                // 業務邏輯上，一個USER只會分配給一個partyGroup，這行只會跑一次
                // partyGroupId給Member的功能搜尋用
                // 可以搜尋這個公司底下所有Agent

                userPartIds.push(partyGroupId)
                element.permissionReply &&
                  element.permissionReply.forEach((item) => {
                    if (!hash[item.id]) {
                      permissionList.push(item)
                      hash[item.id] = true
                    }
                  })
              })

            localStorage.setItem('userPartIds', JSON.stringify(userPartIds))
            localStorage.setItem('permissionList', JSON.stringify(permissionList))
            localStorage.setItem('getUserInfo_refresh_date', new Date().getTime())
            // 更新config，此時需要reload，回傳reject()
            // 等等就會跑window.location.reload(false);
            setTimeout(_ => reject(), 50)

            console.log('啟動中.......getUserInfo')
          })
        }
      })
    }
  })
}

// function setProductMenuFunction() {
//   localStorage.removeItem('productMenuFunction')

//   // 廚師系統只給廚師用，根據用途出現的column label會不一樣

//   // partyGrpId有哪些??
//   // 根據SELECT * FROM chopchopcook_cfs.sec_party_grp;
//   // 裡面gallery admin用到的有
//   // 000001(Gallery admin最大權限)
//   // b9416cf6b3f75b7577b18e4bb7c5ce61(Gallery admin店家使用的權限)

//   // categorys有哪些??
//   // 根據SELECT * FROM chopchopcook_cfs.domain_tree_item;
//   // 裡面category有很多..
//   // 8a8082d282a9fede0182afc8285f0005  (食譜)
//   // 8a8082f3886e08da018875b33c8d0085  (食材)
//   const productMenuFunction = [
//     {
//       id: 'f2d376e5-9d62-2a61-182c-fbc1a2cdfa9b',
//       menuName: [
//         { language: 'en-US', value: 'Product Maintenance' },
//         { language: 'zh-HK', value: '產品維護' },
//         { language: 'zh-TW', value: '產品維護' },
//         { language: 'zh-CN', value: '产品维护' },
//         { language: 'id-ID', value: 'Pemeliharaan Produk' },
//         { language: 'tl-PH', value: 'Pagpapanatili ng Produkto' }
//       ],
//       categorys: null, // null代表不限制，甚麼都可以查
//       partyGrpId: ['000001'], // 000001代表Gallery admin最大權限
//       label_1: [
//         { language: 'en-US', value: 'Product ID' },
//         { language: 'zh-HK', value: '產品ID' },
//         { language: 'zh-TW', value: '產品ID' },
//         { language: 'zh-CN', value: '产品ID' },
//         { language: 'id-ID', value: 'ID Produk' },
//         { language: 'tl-PH', value: 'ID ng produkto' }
//       ],
//       label_2: [
//         { language: 'en-US', value: 'Product Name' },
//         { language: 'zh-HK', value: '產品名稱' },
//         { language: 'zh-TW', value: '產品名稱' },
//         { language: 'zh-CN', value: '产品名称' },
//         { language: 'id-ID', value: 'Nama Produk' },
//         { language: 'tl-PH', value: 'Pangalan ng Produkto' }
//       ],
//       label_3: [
//         { language: 'en-US', value: 'Product Introduction' },
//         { language: 'zh-HK', value: '產品介紹' },
//         { language: 'zh-TW', value: '產品介紹' },
//         { language: 'zh-CN', value: '产品介绍' },
//         { language: 'id-ID', value: 'Perkenalan produk' },
//         { language: 'tl-PH', value: 'Panimula ng Produkto' }
//       ],
//     },
//     {
//       id: 'dcc58a51-e575-d740-84c0-0e63dc4b91a7',
//       menuName: [
//         { language: 'en-US', value: 'Recipe Maintenance' },
//         { language: 'zh-HK', value: '食譜維護' },
//         { language: 'zh-TW', value: '食譜維護' },
//         { language: 'zh-CN', value: '食谱维护' },
//         { language: 'id-ID', value: 'Pemeliharaan Resep' },
//         { language: 'tl-PH', value: 'Pagpapanatili ng Produkto' }
//       ],

//       // 食譜
//       categorys: ['8a8082d282a9fede0182afc8285f0005'],

//       // Gallery admin店家使用的權限
//       // Gallery admin最大權限
//       partyGrpId: [
//         '000001',
//         'b9416cf6b3f75b7577b18e4bb7c5ce61',
//         '8a80820d86bfac6f0186c019c5d70000'
//       ],

//       label_1: [
//         { language: 'en-US', value: 'Recipe ID' },
//         { language: 'zh-HK', value: '食譜ID' },
//         { language: 'zh-TW', value: '食譜ID' },
//         { language: 'zh-CN', value: '食谱ID' },
//         { language: 'id-ID', value: 'ID Resep' },
//         { language: 'tl-PH', value: 'ID ng Recipe' }
//       ],
//       label_2: [
//         { language: 'en-US', value: 'Recipe Name' },
//         { language: 'zh-HK', value: '食譜名稱' },
//         { language: 'zh-TW', value: '食譜名稱' },
//         { language: 'zh-CN', value: '食谱名称' },
//         { language: 'id-ID', value: 'Nama Resep' },
//         { language: 'tl-PH', value: 'Pangalan ng Recipe' }
//       ],
//       label_3: [
//         { language: 'en-US', value: 'Recipe Introduction' },
//         { language: 'zh-HK', value: '食譜簡介' },
//         { language: 'zh-TW', value: '食譜簡介' },
//         { language: 'zh-CN', value: '食谱介绍' },
//         { language: 'id-ID', value: 'Pengenalan Resep' },
//         { language: 'tl-PH', value: 'Panimula ng Recipe' }
//       ]
//     },
//     {
//       id: '41c2b8a4-b0b3-08bd-0461-796b457c9ef9',
//       menuName: [
//         { language: 'en-US', value: 'Ingredient Maintenance' },
//         { language: 'zh-HK', value: '食材維護' },
//         { language: 'zh-TW', value: '食材維護' },
//         { language: 'zh-CN', value: '食材维护' },
//         { language: 'id-ID', value: 'Pemeliharaan Bahan' },
//         { language: 'tl-PH', value: 'Pagpapanatili ng sangkap' }
//       ],

//       // 食材
//       categorys: ['8a8082f3886e08da018875b33c8d0085'],

//       // Gallery admin店家使用的權限
//       // Gallery admin最大權限
//       partyGrpId: [
//         '000001',
//         'b9416cf6b3f75b7577b18e4bb7c5ce61',
//         '8a80820d86bfac6f0186c019c5d70000'
//       ],
//       label_1: [
//         { language: 'en-US', value: 'Ingredient ID' },
//         { language: 'zh-HK', value: '食材ID' },
//         { language: 'zh-TW', value: '食材ID' },
//         { language: 'zh-CN', value: '食材ID' },
//         { language: 'id-ID', value: 'ID Bahan' },
//         { language: 'tl-PH', value: 'ID ng sangkap' }
//       ],
//       label_2: [
//         { language: 'en-US', value: 'Ingredient Name' },
//         { language: 'zh-HK', value: '食材名稱' },
//         { language: 'zh-TW', value: '食材名稱' },
//         { language: 'zh-CN', value: '食材名称' },
//         { language: 'id-ID', value: 'Nama Bahan' },
//         { language: 'tl-PH', value: 'Pangalan ng sangkap' }
//       ],
//       label_3: [
//         { language: 'en-US', value: 'Ingredient Introduction' },
//         { language: 'zh-HK', value: '食材簡介' },
//         { language: 'zh-TW', value: '食材簡介' },
//         { language: 'zh-CN', value: '食材介绍' },
//         { language: 'id-ID', value: 'Pengenalan Bahan' },
//         { language: 'tl-PH', value: 'Panimula ng sangkap' }
//       ]
//     }
//   ]

//   const productMenuFunctionStr = JSON.stringify(productMenuFunction)
//   localStorage.setItem('productMenuFunction', productMenuFunctionStr)
// }
