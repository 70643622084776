<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">{{ $t('comment.commentEdit.label') }}</el-col>
        <el-col :span="12">
          <el-button @click="$router.back(-1)">{{ $t('COMPONENTS.return') }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission('comment.edit')"
            @click="handleSave"
          >{{ $t('COMPONENTS.save') }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form
      ref="temp"
      class="search-form-inline"
      :model="review"
      label-suffix=":"
      label-position="left"
      label-width="200px"
    >
      <el-row>
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('Member Account')" prop="memberName">
                  <span>{{ review.memberName }}</span>
                  <!-- <el-input v-model="review.member" disabled /> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.shop')" prop="shopName">
                  <span>{{ review.shopName }}</span>
                  <!-- <el-input v-model="review.shopName" disabled  clearable /> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('review.productName')" prop="productName">
                  <span>{{ review.productName }}</span>
                  <!-- <el-input v-model="review.productName"  disabled /> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('review.rate')" prop="reviewRate">
                  <el-rate v-model="review.reviewRate" disabled />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('review.title')" prop="reviewTitle">
                  <span>{{ review.reviewTitle }}</span>
                  <!-- <el-input v-model="review.reviewTitle" disabled /> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('review.content')" prop="reviewContent">
                  <span>{{ review.reviewContent }}</span>
                  <!-- <el-input v-model="review.reviewContent"  disabled /> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('review.attachment')" prop="attachment">
                  <div
                    v-for="(item ,index) in review.fileList"
                    :key="index"
                  >
                    <img v-if="item.type === 'IMAGE'" :src="item.url" class="avatar">
                    <video v-if="item.type === 'VIDEO'" :src="item.url" class="avatar" controls="controls">
                      您的浏览器不支持视频播放
                    </video>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.status')">
                  <el-select v-model="review.status" :placeholder="$t('COMPONENTS.inselect')" filterable>
                    <el-option label="" value="">{{ $t('COMPONENTS.inselect') }}</el-option>
                    <el-option
                      v-for="status in statusList"
                      :key="status.value"
                      :label="status.label"
                      :value="status.value"
                      :disabled="status.disabled"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { checkCfsPermission } from '@/utils/permission'
import { getPageParm } from '@/utils/page-common'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { getAllCommentList, updateDataStatus } from '@/api/commentApi'
import { getLanguage } from '@/lang/index'
export default {
  name: 'CommentCreate',
  components: { Sticky },
  data() {
    return {
      edit: true,
      rowCount: 1,
      review: {
        id: null,
        memberName: null,
        shopName: null,
        productName: null,
        reviewRate: 0,
        title: '',
        content: '',
        status: ''
      },
      query: {
        size: 10,
        page: 1,
        total: 0,
        function: 'usercomment'
      },
      acceptVideoType: ['.mp4', '.AVI', '.mpg', '.wmv', '.mov'],
      acceptImageType: ['.jpg', '.jpeg', '.png', '.gif'],
      statusList: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadData() {
      const { reviewId, shopName } = this.$route.query
      const query = { ...this.query }
      const param = getPageParm(query)
      query.id = reviewId
      const permissionList = []
      permissionList.push(getAllCommentList(param, query))
      permissionList.push(getCfsDomainData({ domainCode: 'SHOP_PRODUCT_REVIEW_STATUS' }))
      Promise.all(permissionList).then(resp => {
        console.log('resp>>>>>>>>>>>>>>>>>', resp)
        const reviews = resp[0]
        const statusListRes = resp[1]
        let review = {}
        if (reviews && reviews.data) {
          review = reviews.data[0]
          review.fileList = review.fileList.map(i => {
            return {
              name: i.filePath,
              url: i.filePath,
              status: 'done',
              type: this.acceptVideoType.includes(i.filePath.substring(i.filePath.lastIndexOf('.'))) ? 'VIDEO' : 'IMAGE'
            }
          })
          review.shopName = shopName
          review.memberName = review.member ? (review.member.firstName + review.member.lastName + '(' + review.member.loginName + ')') : ''
        }
        if (statusListRes && statusListRes.data) {
          const statusList = []
          statusListRes.data.map((data, i) => {
            statusList.push({
              value: data.domainDataCode,
              label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')',
              disabled: checkCfsPermission('SHOP_PRODUCT_REVIEW_STATUS.' + data.domainDataCode)
            })
          })
          this.statusList = statusList
        }
        this.review = review
      })
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    handleSave() {
      updateDataStatus({ data: [this.review], status: this.review.status }).then((resp) => {
        if (resp) {
          this.$message.success(this.$t('COMPONENTS.save_Success'))
        } else {
          this.$message.error(this.$t('COMPONENTS.save_Filad'))
        }
        this.$router.back(-1)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.component {
  margin: 10px;
}
.tree-box {
  margin: 15px 0 100px 55px;
  border: #a6e0d8 solid 2px;
  padding: 15px;
}
.form-item-head {
  margin-bottom: 8px;
  font-size: 12px;
}
.hide .el-upload--picture-card {
  display: none;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  float: left;
  margin-right: 10px;
}

.value-table /deep/ .cell {
   padding: 0px 10px 16px 10px;
}

</style>
