import request from '@/utils/request'

import { API_SERVER_URL } from '../../Config'

export function systemConfig() {
  return request({
    url: API_SERVER_URL + '/api/v2/systemConfig',
    method: 'get',
    params: { codeSubscriber: 'NMT', useageTypeCode: 'PARTY_SHOP', parentId: '2c9d90877820830901783509926b0002' }
  })
}

export function fileSizeConfig(type) {
  return request({
    url: API_SERVER_URL + '/public/systemConfig/fileSize/' + type,
    method: 'get'
  })
}

export function findStorageConfig() {
  return request({
    url: API_SERVER_URL + '/public/systemConfig/relateStorage',
    method: 'get'
  })
}

export function getSystmeConfig(item) {
  return request({ url: API_SERVER_URL + '/public/systemConfig/findByItem/' + item, method: 'get' })
}
