<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="75px"
        >
          <!-- <el-row :gutter="20">
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('shopPic.imageName')">
                <el-input
                  v-model="query.memberName"
                  size="medium"
                  :placeholder="$t('shopPic.Please-enter-imageName')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('COMPONENTS.shopName')">
                <el-input
                  v-model="query.shopNmae"
                  size="medium"
                  :placeholder="$t('rule.Please-enter-shop')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="4" :md="6" :sm="12" :xs="24">
              <el-form-item :label="$t('shopPic.createData')">
                <el-input v-model="query.createData" :placeholder="$t('shopPic.Please-enter-createData')" />
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-button class="allButton" :disabled="checkPermission(&quot;shopPicture&quot;)" @click="loadData">{{ $t('COMPONENTS.search') }}</el-button>
            <el-button class="allButton" :disabled="checkPermission(&quot;shopPicture.add&quot;)" @click="handleCreate">{{ $t('COMPONENTS.add') }}</el-button>

          </el-row>
        </el-form>
      </div>
    </transition>

    <el-table
      tooltip-effect="dark"
      :data="data"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
    >
      <el-table-column type="selection" align="center" />
      <el-table-column :label="$t('shopPic.id')" prop="id" width="80px" align="center" />
      <el-table-column :label="$t('shopPic.img')" prop="urlPath" width="150px" align="center">
        <template slot-scope="{ row }">
          <img :src="row.urlPath" :alt="row.name" width="90%">
          <!-- <el-image :src="getStaticSourcePath(row.urlPath)" /> -->
        </template>
      </el-table-column>
      <el-table-column :label="$t('shopPic.imageName')" prop="name" width="100px" align="center" />
      <el-table-column :label="$t('shopPic.sizeInByte')" prop="sizeInByte" width="80px" align="center" />
      <el-table-column :label="$t('shopPic.createData')" prop="createdDate" width="100px" align="center" />
      <el-table-column :label="$t('shopPic.urlPath')" align="left" prop="urlPath" />
      <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" width="150px">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="checkPermission('shopPicture.edit')"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.edit') }}</el-button>
          <el-button
            size="mini"
            :disabled="checkPermission('shopPicture.delete')"
            @click="handleDelete(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-col>
        <pagination
          v-show="query.total > 0"
          class="hidden-xs-only"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="query > 0"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="7"
          layout="pager, prev, next"
          @pagination="loadData"
        />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import { searchAllPicList, deleteById } from '@/api/shopPicApi'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'

export default {
  name: 'ShopPic',
  components: { Pagination },
  directives: { waves },
  filters: {},
  data() {
    return {
      attribute: {},
      data: [],
      pageStyle: {
        margin: '0px',
        padding: '5px'
      },
      query: {
        size: 10,
        page: 1,
        total: 100

      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadData() {
      var quary = { ...this.query }
      var param = getPageParm(quary)
      searchAllPicList(param, quary).then((resp) => {
        var data = resp.data
        this.query.total = resp.pageCount
        this.query.page = resp.pageNum
        this.query.size = resp.pageSize
        if (data == null) {
          this.list = null
        } else {
          this.data = data
        }
      })
    },

    handleEdit(index, row) {
      this.$router.push({ path: 'shopPic/shopPicEdit', query: { attrId: row.id }})
    },

    handleCreate() {
      this.$router.push({ path: 'shopPic/addShopPic' })
    },

    handleDelete(index, row) {
      console.log(row.id)
      this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        deleteById(row.id).then((resp) => {
          if (resp) {
            this.data.splice(index, 1)
            this.$notify.success({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.delete_Success')
            })

            this.loadData()
          }
        })
      })
    }

  }
}
</script>
