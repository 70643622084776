<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">{{
          add ? $t('attributeGroup.Add-Attribute') : $t('attributeGroup.Edit-Attribute')
        }}</el-col>
        <el-col :span="12">
          <el-button @click="handleCancel">{{ $t('COMPONENTS.return') }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission('attributeGroup.add')"
            @click="handleFilter"
          >{{ $t('COMPONENTS.save') }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-card class="card-box">
      <transition name="el-zoom-in-top">
        <div class="filter-container">
          <el-form
            class="search-form-inline"
            label-suffix=":"
            label-position="left"
            hide-required-asterisk
            label-width="75px"
          >
            <el-row :gutter="20">
              <el-col :xl="4" :md="6" :sm="12" :xs="24">
                <el-form-item :label="$t('attributeGroup.name')">
                  <el-input
                    v-model="groupName"
                    clearable
                    class="filter-item"
                    style="width: 200px"
                    :placeholder="$t('attributeGroup.Please-enter-a-title-parameter-group-name')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </transition>
      <el-form>
        <div class="btn-titles">
          <p class="text-title">{{ $t('attributeGroup.Attribute-list') }}</p>
          <el-button class="btn-add" @click="attributeAdd()">{{ $t('COMPONENTS.add') }}</el-button>
        </div>
        <el-table
          :data="list"
          tooltip-effect="dark"
          style="width: 100%"
          header-row-class-name="table-header"
          size="mini"
        >
          <el-table-column width="60px" align="center" />
          <el-table-column
            :label="$t('attribute.attrName')"
            prop="name"
            align="left"
          />

          <el-table-column
            :label="$t('attributeGroup.Options-count')"
            prop="num"
            align="left"
          />

          <el-table-column :label="$t('attributeGroup.isVariable')" prop="isVariable" align="left" />

          <el-table-column
            :label="$t('attributeGroup.Required')"
            prop="mandatoryInd"
            align="left"
          />
          <el-table-column :label="$t('COMPONENTS.operation')" prop="name" width="350px" align="left">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.copy"
                class="filter-item"
                @click="dltcopy(scope.row)"
              >{{ $t('COMPONENTS.copy') }}</el-button>
              <el-button
                v-else
                class="filter-item"
                @click="dltsave(scope.row)"
              >{{ $t('COMPONENTS.save') }}</el-button>
              <el-button
                class="filter-item"
                @click="dltId(scope.row, scope.$index)"
              >{{ $t('COMPONENTS.delete') }}</el-button>
              <el-button
                class="filter-item"
                @click="dltedit(scope.row)"
              >{{ $t('COMPONENTS.edit') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
    <el-dialog
      :title="save ? $t('attributeGroup.Add-Attribute') : $t('attributeGroup.Edit-Attribute')"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form">
        <el-form-item>
          <el-lable
            style="color: #808080"
          >{{ $t('attribute.attrName') }}</el-lable>
          <el-autocomplete
            v-model="attr.name"
            :fetch-suggestions="querySearchAsync"
            :placeholder="$t('COMPONENTS.incontent')"
            clearable
            @select="handleSelect"
          />
        </el-form-item>
        <el-form-item>
          <el-form>
            <el-form-item :label="$t('attributeGroup.Type')">
              <el-radio v-model="multpleSelectInd" label="0">{{ $t('COMPONENTS.One-choice') }}</el-radio>
              <el-radio v-model="multpleSelectInd" label="1">{{ $t('COMPONENTS.Multiple-choice') }}</el-radio>
            </el-form-item>
            <el-form-item :label="$t('attributeGroup.isVariable')">
              <el-radio v-model="isVariable" label="0">NO</el-radio>
              <el-radio v-model="isVariable" label="1">YES</el-radio>
            </el-form-item>
            <el-form-item :label="$t('attributeGroup.Required')">
              <el-radio v-model="mandatoryInd" label="0">NO</el-radio>
              <el-radio v-model="mandatoryInd" label="1">YES</el-radio>
            </el-form-item>
          </el-form>
        </el-form-item>
        <el-form-item>
          <el-table :data="attr.attrValues" style="width: 100%">
            <el-table-column :label="$t('attributeGroup.Seq')" width="180">
              <template slot-scope="scope">
                <el-form-item :prop="'attrValues.' + scope.$index + '.seqNo'">
                  <el-input
                    v-if="scope.row.edit"
                    v-model="scope.row.seqNo"
                    style="width: 100%"
                    clearable
                  />
                  <span v-else>{{ scope.row.seqNo }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('attribute.attrName')" width="200">
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    v-if="scope.row.edit"
                    v-model="scope.row.name"
                    style="width: 100%"
                  />
                  <span v-else>{{ scope.row.name }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('COMPONENTS.operation')" width="200">
              <template slot-scope="scope">
                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="delRow(scope.$index, scope.row)"
                />
                <el-button
                  icon="el-icon-edit"
                  circle
                  @click="editRow(scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-circle-plus" @click="addLanguage()" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{ $t('COMPONENTS.cancel') }}</el-button>
        <el-button type="primary" @click="AttrSave()">{{ $t('COMPONENTS.sure') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findByAttrId, deleteById } from '@/api/productAttrbuteValueApi'
import {
  saveAttrGroup,
  findByGroupId,
  saveCopy
} from '@/api/MgProductAttrGroupApi'
import {
  deleteByAttrId,
  adminListAll,
  saveAttrValue,
  saveAttrCopy
} from '@/api/productAttributeApi'
import { checkCfsPermission } from '@/utils/permission'
import Sticky from '@/components/Sticky'

export default {
  components: { Sticky },
  data() {
    return {
      add: '',
      save: false,
      grpId: '',
      multpleSelectInd: '1',
      isVariable: '1',
      mandatoryInd: '1',
      groupName: '',
      list: [],
      dtlId: '',
      form: {},
      dialogFormVisible: false,
      attr: {
        seqNo: 0,
        name: '',
        attrValues: [
          {
            id: '',
            seqNo: 0,
            name: '',
            edit: true,
            copy: true
          }
        ]
      },
      attrList: [],
      id: '',
      name: '',
      groupDtlId: '',
      saveOrcopy: true,
      editOrcopy: 0,
      attrName: ''
    }
  },
  created() {
    this.add = this.$router.app._route.query.add

    if (this.$router.app._route.query.name) {
      this.groupName = this.$router.app._route.query.name
    }

    if (this.$router.app._route.query.grpName) {
      this.groupName = this.$router.app._route.query.grpName
      console.log('进入来了==============================', this.groupName)
    }

    this.loadData()
    // this.groupName = this.$router.app._route.query.groupName
  },
  methods: {
    checkPermission(value) {
      if (this.$router.app._route.query.add) {
        return checkCfsPermission('attributeGroup.add')
      } else if (this.$router.app._route.query.saveOrcopy) {
        return checkCfsPermission('attributeGroup.copy')
      } else {
        return checkCfsPermission('attributeGroup.edit')
      }
    },
    loadData() {
      // if (this.$router.app._route.query.attrGroupName) {
      //   const attrGroupName = this.$router.app._route.query.attrGroupName
      //   this.groupName = attrGroupName
      // }

      if (this.$router.app._route.query.id) {
        this.id = this.$router.app._route.query.id
        this.loadfindById()
      }

      if (this.$router.app._route.query.gid) {
        console.log(
          '复制过来了-0000-------',
          this.$router.app._route.query.gid
        )
        this.id = this.$router.app._route.query.gid
        this.loadfindById()
      }

      // var array = JSON.parse(localStorage.getItem('saveList')) || '[]'
      // console.log('为sda', array)
      // array.forEach((element) => {
      //   console.log('111111111111111111111111--', element)
      //   this.list.push(element)
      // })
    },
    loadfindById() {
      const id = this.id
      console.log('loadfindById', id)
      findByGroupId(id).then((response) => {
        this.list = response.data
        this.list.forEach((element) => {
          this.dtlId = element.dtlId
          this.grpId = element.grpId
          const listobj = {
            name: element.name,
            num: element.num,
            isVariable: element.isVariable,
            mandatoryInd: element.mandatoryInd,
            seqNo: element.seqNo,
            productAttrId: element.productAttrId,
            multpleSelectInd: element.multpleSelectInd
          }
          var tempList = JSON.parse(localStorage.getItem('list') || '[]')
          tempList.push(listobj)
          localStorage.setItem('list', JSON.stringify(tempList))
        })
      })
    },
    attributeAdd() {
      this.save = true
      this.editOrcopy = 0
      this.dialogFormVisible = true
      this.attrId = ''
      this.attr.name = ''
      this.attr.attrValues = []
    },
    AttrSave() {
      if (this.editOrcopy === 0) {
        console.log('进入添加属性')
        // const id = this.attrId;

        this.dialogFormVisible = false
        const multpleSelectInd = this.multpleSelectInd
        const isVariable = this.isVariable
        const mandatoryInd = this.mandatoryInd
        // const productAttrId = this.attrId
        // const name = this.attr.name
        // const num = this.attr.attrValues.length
        const req = {
          id: this.attrId,
          name: this.attr.name,
          attrValue: this.attr.attrValues,
          multpleSelectInd: multpleSelectInd,
          isVariable: isVariable,
          mandatoryInd: mandatoryInd
        }
        saveAttrValue(req).then((response) => {
          if (response) {
            this.dialogFormVisible = false
            const multpleSelectInd = response.data.multpleSelectInd
            const isVariable = response.data.isVariable
            const mandatoryInd = response.data.mandatoryInd
            const id = response.data.id
            const name = response.data.name
            const num = this.attr.attrValues.length
            const list = {
              productAttrId: id,
              multpleSelectInd: multpleSelectInd,
              isVariable: isVariable,
              mandatoryInd: mandatoryInd,
              name: name,
              num: num
            }
            if (this.save === true) {
              this.list.push(list)
            } else {
              var array = []
              array.push(list)
              this.list = array
              this.loadfindById()
            }
          } else {
            this.$message.success(this.$t('COMPONENTS.save_Filad'))
          }
        })
      } else if (this.editOrcopy === 1) {
        console.log('进入修改')
        const id = this.attrId
        const multpleSelectInd = this.multpleSelectInd
        const isVariable = this.isVariable
        const mandatoryInd = this.mandatoryInd
        const req = {
          id: id,
          name: this.attr.name,
          attrValue: this.attr.attrValues,
          multpleSelectInd: multpleSelectInd,
          isVariable: isVariable,
          mandatoryInd: mandatoryInd
        }
        saveAttrValue(req).then((response) => {
          if (response) {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.save_Success'),
              type: 'success'
            })
            this.dialogFormVisible = false
            const multpleSelectInd = response.data.multpleSelectInd
            const isVariable = response.data.isVariable
            const mandatoryInd = response.data.mandatoryInd
            const id = response.data.id
            const name = response.data.name
            const num = this.attr.attrValues.length
            const list = {
              productAttrId: id,
              multpleSelectInd: multpleSelectInd,
              isVariable: isVariable,
              mandatoryInd: mandatoryInd,
              name: name,
              num: num
            }
            if (this.save === true) {
              this.list.push(list)
            } else {
              var array = []
              array.push(list)
              this.list = array
              this.loadfindById()
            }
          } else {
            this.$message.success(this.$t('COMPONENTS.save_Filad'))
          }
        })
      } else if (this.editOrcopy === 2) {
        console.log('进入复制属性')
        const name = this.attr.name
        const attrName = this.attrName
        if (name === attrName) {
          this.$message.error(this.$t('attributeGroup.attributeGroup-cannot-have-the-same-title'))
          return
        }
        const multpleSelectInd = this.multpleSelectInd
        const isVariable = this.isVariable
        const mandatoryInd = this.mandatoryInd
        const num = this.attr.attrValues.length
        const productAttrId = this.attrId
        const dtlId = this.dtlId
        const list = {
          multpleSelectInd: multpleSelectInd,
          isVariable: isVariable,
          mandatoryInd: mandatoryInd,
          name: name,
          num: num,
          productAttrId: productAttrId,
          dtlId: dtlId
        }
        console.log('list-----------=', list)
        console.log('dtlId---', this.dtlId)
        this.dialogFormVisible = false
        this.list.push(list)
      }
    },
    handleCancel() {
      localStorage.removeItem('saveList')
      this.$router.push({ path: '/goods/attributeGroup' })
    },
    handleFilter() {
      console.log(
        'a==================',
        this.$router.app._route.query.saveOrcopy
      )
      if (this.$router.app._route.query.saveOrcopy === false) {
        console.log('进入了没有')
        this.saveOrcopy = this.$router.app._route.query.saveOrcopy
      }
      const saveOrcopy = this.saveOrcopy
      console.log('undefie', saveOrcopy)
      if (saveOrcopy) {
        console.log('进入save输出', saveOrcopy)
        if (!this.groupName) {
          this.$message.error(this.$t('attributeGroup.attributeGroup-cannot-have-the-same-title'))
          return
        }
        const groupData = this.list
        console.log('groupdata----------', groupData)
        if (groupData.length < 1) {
          this.$message.error(this.$t('attributeGroup.Please-add-attributes'))
          return
        }
        const data = {
          id: this.grpId,
          name: this.groupName,
          groupData: groupData
        }
        console.log('测啊US好的好的', data)
        saveAttrGroup(data).then((response) => {
          if (response) {
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success'
            })
            localStorage.removeItem('saveList')
            localStorage.removeItem('list')
            this.$router.push({ path: '/goods/attributeGroup' })
          } else {
            this.$message.error('save failed !')
          }
        })
      } else {
        console.log('进入copy')
        const grpname = this.$router.app._route.query.grpName
        const id = this.$router.app._route.query.gid
        const name = this.groupName
        if (grpname === name) {
          this.$message.error('属性组不能有相同的标题')
          return
        }
        const data = { id: id, name: name }
        saveCopy(data).then((response) => {
          if (response) {
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success'
            })
            this.$router.push({ path: '/goods/attributeGroup' })
          } else {
            this.$message.error('save failed !')
          }
        })
      }
    },
    dltedit(row) {
      this.save = false
      this.editOrcopy = 1
      this.dialogFormVisible = true
      this.attr.name = row.name
      this.mandatoryInd = row.mandatoryInd
      this.multpleSelectInd = row.multpleSelectInd
      this.attrId = row.productAttrId
      const id = this.attrId
      findByAttrId(id).then((response) => {
        this.attr.attrValues = response.data
      })
    },
    dltId(row, rows) {
      console.log()
      const id = row.productAttrId
      const dtlId = row.dtlId
      const ids = { id: id, dtlId: dtlId }
      if (id) {
        this.$confirm('此操作将删除此数据,是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteByAttrId(ids).then((response) => {
            if (response) {
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              })
              this.loadfindById()
            }
          })
        })
      } else {
        this.list.splice(rows, 1)
      }
    },
    handleSelect(item) {
      console.log('12345678910--------', item)
      this.attrId = item.id
      const id = this.attrId
      findByAttrId(id).then((response) => {
        var data = response.data
        if (data && data.length > 1) {
          console.log('进入list')
          this.attr.attrValues = data
        } else {
          this.attr.attrValues = []
          console.log('进入null')
        }
      })
    },
    querySearchAsync(queryString, cb) {
      adminListAll().then((response) => {
        var data = response.data
        data.forEach((element, index) => {
          this.attrList.splice(index, 1, {
            id: element.id,
            value: element.name
          })
        })
      })
      cb(this.attrList)
    },
    addLanguage() {
      this.attr.attrValues.push({
        seqNo: 0,
        name: '',
        edit: true
      })
    },
    delRow(row, rows) {
      const id = rows.id
      if (id) {
        this.$confirm('此操作将删除该数据,是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteById(id).then((response) => {
            if (response) {
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              })
              this.findByAttrId()
            }
          })
        })
      } else {
        this.attr.attrValues.splice(row, 1)
      }
    },
    editRow(row) {
      console.info('++++', row)
      row.edit = !row.edit
    },
    dltcopy(row) {
      this.editOrcopy = 2
      this.dialogFormVisible = true
      console.log(row)
      this.attrName = row.name
      this.attr.name = this.attrName
      this.mandatoryInd = row.mandatoryInd
      this.multpleSelectInd = row.multpleSelectInd
      this.attrId = row.productAttrId
      const id = this.attrId
      findByAttrId(id).then((response) => {
        this.attr.attrValues = response.data
      })
    },
    dltsave(row) {
      console.log('保存----=-', row)
      const name = row.name
      const id = row.productAttrId
      console.log('id---', id)
      const dtlId = this.dtlId
      console.log('dtlIdoadp', dtlId)
      if (name === this.name) {
        this.$message.error('不能有相同的属性标题')
        return
      }
      const data = { id: id, name: name, dtlId: dtlId }
      saveAttrCopy(data).then((response) => {
        if (response) {
          this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
          })
          this.loadfindById()
        } else {
          this.$message.error('save failed')
        }
      })
    }
  }
}
</script>
<style>
.text-title {
  font-size: 18px;
  font-weight: bold;
}
.btn-titles {
  display: flex;
  justify-content: space-between;
}
.btn-add {
  width: 70px;
  height: 40px;
}
.filter-container {
  display: flex;
  justify-content: space-between;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}

.card-box {
  margin: 0.5rem 0.5rem 0;
}
</style>
