var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 12 } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.edit
                          ? _vm.$t("Edit Member")
                          : _vm.$t("Add New Member")
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.checkPermission("MEMBER.DELETE") },
                      on: { click: _vm.showDeleteDialog },
                    },
                    [_vm._v(_vm._s(_vm.$t("Delete Member")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "success" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { model: _vm.member, "label-width": "160px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _vm.member.id !== null
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 12, xs: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: _vm.$t("Member ID") } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.member.id)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, xs: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "COMPONENTS.registerDate"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.member.registerDate)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Member Account") + "*",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { disabled: _vm.edit },
                                    model: {
                                      value: _vm.member.loginName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "loginName", $$v)
                                      },
                                      expression: "member.loginName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isProfileNeedGender
                            ? _c(
                                "el-col",
                                { attrs: { sm: 12, xs: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.$t("Gender") +
                                          (_vm.isProfileNeedGender ? "*" : ""),
                                        prop: "gender",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.member.gender,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.member,
                                                "gender",
                                                $$v
                                              )
                                            },
                                            expression: "member.gender",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v(_vm._s(_vm.$t("Man")))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "2" } },
                                            [_vm._v(_vm._s(_vm.$t("Woman")))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: _vm.$t("First Name") + "*" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.member.firstName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "firstName", $$v)
                                      },
                                      expression: "member.firstName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Last Name") + "*" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.member.lastName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "lastName", $$v)
                                      },
                                      expression: "member.lastName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.$t("Phone Number") +
                                      (_vm.isProfileNeedMobile ? "*" : ""),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.member.mobileNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "mobileNo", $$v)
                                      },
                                      expression: "member.mobileNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Password") + "*" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      type: "password",
                                      autocomplete: "new-password",
                                    },
                                    model: {
                                      value: _vm.member.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "password", $$v)
                                      },
                                      expression: "member.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Email") + "*" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.member.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "email", $$v)
                                      },
                                      expression: "member.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Email Verified") } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      prop: "emailVerified",
                                      "active-color": "#13ce66",
                                      "inactive-color": "#a9a9a9",
                                    },
                                    model: {
                                      value: _vm.member.emailVerified,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.member,
                                          "emailVerified",
                                          $$v
                                        )
                                      },
                                      expression: "member.emailVerified",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#707070",
                                        "font-size": "10px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  (" +
                                          _vm._s(
                                            _vm.$t(
                                              "Default need to send verify email"
                                            )
                                          ) +
                                          ")\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _vm.isProfileNeedBirthday
                            ? _c(
                                "el-col",
                                { attrs: { sm: 12, xs: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.$t("Birthday") +
                                          (_vm.isProfileNeedBirthday
                                            ? "*"
                                            : ""),
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          clearable: "",
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.member.birthday,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.member,
                                              "birthday",
                                              $$v
                                            )
                                          },
                                          expression: "member.birthday",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Enabled") } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      prop: "status",
                                      "active-color": "#13ce66",
                                      "inactive-color": "#a9a9a9",
                                    },
                                    on: { change: _vm.onChangeStatus },
                                    model: {
                                      value: _vm.member.enabled,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "enabled", $$v)
                                      },
                                      expression: "member.enabled",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { key: _vm.componentKey },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _vm.isProfileNeedAddrss
                                ? _c(
                                    "el-col",
                                    { attrs: { sm: 12, xs: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: _vm.$t("Country") } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                "v-model": _vm.member.country
                                                  ? _vm.member.country
                                                      .description
                                                  : "",
                                                value: _vm.member.country
                                                  ? _vm.member.country
                                                      .description
                                                  : "",
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: function (e) {
                                                  return _vm.handlerCountryOnChange(
                                                    e,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(
                                              _vm.countryList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.description,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, xs: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: _vm.$t("Language") } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.member.languageCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.member,
                                                "languageCode",
                                                $$v
                                              )
                                            },
                                            expression: "member.languageCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.languageOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isProfileNeedAddrss
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.locationTypes !== null,
                                      expression: "locationTypes !== null",
                                    },
                                  ],
                                },
                                _vm._l(
                                  _vm.locationTypes,
                                  function (item, index) {
                                    return _c(
                                      "el-row",
                                      { key: item.id, attrs: { gutter: 20 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { sm: 24, xs: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    item.description
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      clearable:
                                                        item.allowUserAdd,
                                                      "v-model": _vm.member
                                                        .addressLocations[index]
                                                        ? _vm.member
                                                            .addressLocations[
                                                            index
                                                          ].description
                                                        : "",
                                                      value: _vm.member
                                                        .addressLocations[index]
                                                        ? _vm.member
                                                            .addressLocations[
                                                            index
                                                          ].description
                                                        : "",
                                                      placeholder: "请选择",
                                                    },
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.addressChange(
                                                          value,
                                                          undefined,
                                                          item,
                                                          index,
                                                          undefined
                                                        )
                                                      },
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.locationList,
                                                    function (location) {
                                                      return _c("el-option", {
                                                        key: location.id,
                                                        attrs: {
                                                          label:
                                                            location.description,
                                                          value: location.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isProfileNeedAddrss
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, xs: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Detail Address"),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "600px" },
                                        attrs: { clearable: "" },
                                        model: {
                                          value: _vm.member.address,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.member, "address", $$v)
                                          },
                                          expression: "member.address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Store Contract") } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "600px" },
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.member.chefStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "chefStatus",
                                            $$v
                                          )
                                        },
                                        expression: "member.chefStatus",
                                      },
                                    },
                                    _vm._l(_vm.chefStatusList, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: _vm.$t("Member Created By") },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        readonly: !_vm.isAdmin,
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.member.memberCreatedBy,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "memberCreatedBy",
                                            $$v
                                          )
                                        },
                                        expression: "member.memberCreatedBy",
                                      },
                                    },
                                    _vm._l(
                                      _vm.memberCreatedByList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Admin Remark") } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "600px" },
                                    attrs: {
                                      type: "textarea",
                                      placeholder: _vm.$t(
                                        "Admin.adminRemark.tooltip"
                                      ),
                                      autosize: { minRows: 8 },
                                    },
                                    model: {
                                      value: _vm.member.adminRemark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "adminRemark", $$v)
                                      },
                                      expression: "member.adminRemark",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t(
                                          "Admin.adminRemark.tooltip"
                                        ),
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Delete member (Only for test team used)"),
            visible: _vm.deleteDialog,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 24, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Password") } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.deletePassword,
                              callback: function ($$v) {
                                _vm.deletePassword = $$v
                              },
                              expression: "deletePassword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDeleteDialog } }, [
                _vm._v(_vm._s(_vm.$t("COMPONENTS.cancel"))),
              ]),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.startDeleteMember } }, [
                _vm._v(_vm._s(_vm.$t("COMPONENTS.sure"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }