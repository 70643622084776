import request from '@/utils/request'
import { API_SERVER_URL } from '../Config'

export function saveFunctionDefaultCategory(functionSetting, param) {
//  functionSetting:
//	採購單=PLAN_TO_BUY_CATEGORIES
//	每日訂餐=PLAN_TO_EAT_CATEGORIES
  return request({
    url: API_SERVER_URL + '/api/admin/category/' + functionSetting + '/save',
    method: 'post',
    data: param
  })
}

export function getFunctionDefaultCategory(functionSetting) {
//  functionSetting:
//	採購單=PLAN_TO_BUY_CATEGORIES
//	每日訂餐=PLAN_TO_EAT_CATEGORIES
  return request({
    url: API_SERVER_URL + '/api/category/' + functionSetting + '/search',
    method: 'get'
  })
}

