<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col v-if="disabledTranslateBtn" :span="12" class="page-header">{{ $t('COMPONENTS.add') }}</el-col>
        <el-col v-else :span="12" class="page-header">{{ $t("COMPONENTS.edit") }}</el-col>
        <el-col :span="12">
          <el-button
            :disabled="disabledTranslateBtn"
            @click="setting()"
          >{{ $t('COMPONENTS.translate') }}</el-button>
          <el-button @click="$router.back(-1)">{{
            $t("COMPONENTS.return")
          }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            @click="submitBtn('temp')"
          >{{ $t("COMPONENTS.save") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-card class="card-box">
      <el-row>
        <el-col>
          <el-form ref="temp" :model="temp" :rules="rules" label-width="120px">
            <el-form-item prop="typeName" :label="$t('tagList.tagName')">
              <el-input
                v-model="temp.typeName"
                :value="temp.typeName"
                style="width: 38%"
              />
            </el-form-item>
            <el-form-item label="网址别名" style="display:none">
              <el-input v-model="temp.urlName" name="urlName" :value="temp.urlName" style="width: 38%;" />
            </el-form-item>
            <el-form-item :label="$t('tagList.Contains-product')">
              <div style="width:200px;float:left;height:260px;margin-top:-10px">
                <span style="margin-left:75px">{{ $t('tagList.unselected') }}</span>
                <ul v-infinite-scroll="load" class="infinite-list" style="overflow:auto;width:200px;height:200px;border:1px #DCDFE6 solid;list-style-type:none;margin-top:0px">
                  <li v-for="(item, index) in noChooseList" :key="index" style="margin-left:-25px">
                    <el-checkbox v-model="noChoosedMode" :label="item.content" border />
                  </li>
                </ul>
              </div>
              <div style="width:50px;float:left;margin-top:80px;margin-left:50px;">
                <el-button @click="addPro">&gt;</el-button>
                <el-button style="margin-left:0px;margin-top:10px" @click="delPro">&lt;</el-button>
              </div>
              <div style="width:200px;float:left;height:260px;overflow:auto;margin-left:60px;margin-top:-10px">
                <span style="margin-left:75px">{{ $t('tagList.selected') }}</span>
                <div style="width:200px;height:200px;border:1px #DCDFE6 solid;overflow:auto;">
                  <ul style="list-style-type:none;margin-left:-25px">
                    <li v-for="(item, index) in chooseList" :key="index">
                      <el-checkbox v-model="choosedMode" :label="item.content" border />
                    </li>
                  </ul>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-drawer
        class="translate-drawer"
        :title="$t('COMPONENTS.translate')"
        :visible.sync="drawer"
        size="60%"
        :show-close="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        @opened="getIframe"
      >
        <iframe
          ref="iframe"
          :src="ifreamSrc"
          class="iframe-class"
          :height="getClientHeight()"
        />
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
import { toEdit, saveOrUpdateToCfs } from '@/api/tag'
import Sticky from '@/components/Sticky'
import { API_CFS_SERVER_URL } from '@/Config'
import { getLanguage } from '../../lang'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'TagCreat',
  components: { Sticky },
  data() {
    var validateTypeName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('tagList.Please-enter-tag-name')))
      } else {
        callback()
      }
    }
    return {
      drawer: false,
      ifreamSrc: '',
      disabledTranslateBtn: false,
      rules: {
        typeName: [
          { required: true, validator: validateTypeName, trigger: 'blur' }
        ]
      },
      attr: {
        name: '',
        dataType: '',
        attrValues: [
          {
            id: '',
            name: '',
            description: ''
          }
        ]
      },
      noChoosedMode: [],
      choosedMode: [],
      allNoChoose: [],
      noChooseList: [],
      chooseList: [],
      temp: {
        typeName: '',
        id: '',
        name: '',
        version: 1,
        codeSubscriber: '',
        createdBy: '',
        createdDate: '',
        lastModifiedBy: '',
        lastModifiedDate: '',
        code: '',
        desc: '',
        valueLists: [],
        label: {
          en: '',
          zh_TW: ''
        },
        domainJson: '',
        domainObj: null,
        deleted: false
      }
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
      } else {
        clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    handleMessage(e) {
      console.log(e.data)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleClose()
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleClose() {
      this.drawer = false
    },
    getIframe() {
      var defaultLang = getLanguage()
      var systemLanguageCodes = JSON.parse(localStorage.getItem('SYSTEM_LANGUAGE_CODES'))
      if (systemLanguageCodes && systemLanguageCodes.data && systemLanguageCodes.data.defaultLanguage) {
        defaultLang = systemLanguageCodes.data.defaultLanguage
      }
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      // this.ifreamSrc =
      //   ifreamSrc + '?system=gallery&module=mobile&function=tag&isIframe=true&language=' + defaultLang + '&sytemLanguage=' + getLanguage()
      this.ifreamSrc =
        ifreamSrc + '?system=CFS&module=admin&function=DEFAULT&isIframe=true&language=' + defaultLang + '&sytemLanguage=' + getLanguage()

      var data = [
        { name: this.$t('tagList.tagName'), key: this.temp.id + '.node.name', type: 'input', value: this.temp.typeName, usageTypeCode: 'tag', refId: this.temp.id }
      ]
      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
    },
    setting() {
      if (this.width > 0) {
        return
      }
      this.drawer = true
    },
    load() {
      for (let i = 0; i < 2; i++) {
        if (this.allNoChoose.length > 0) {
          var obj = this.allNoChoose[0]
          this.noChooseList.push(obj)
          this.allNoChoose = this.allNoChoose.filter(
            (t) => t.content !== obj.content
          )
        }
      }
      console.log('noChooseList:', this.noChooseList)
      console.log('allNoChoose:', this.allNoChoose)
    },
    loadData() {
      var vid = this.$route.query.attrId
      var vshop = this.$route.query.shop
      var vName = this.$route.query.tagName
      var params = {
        id: vid,
        shop: vshop
      }
      if (!vid) this.disabledTranslateBtn = true
      toEdit(params).then(response => {
        console.log('response data is', response)
        const data = response.data
        const choosedPlist = data.choosedPlist
        var noChoosedPlist = data.noChoosedPlist
        const allPlist = data.allPlist
        if (!vid) {
          noChoosedPlist = allPlist.map(item => { return { name: item.name } })
        }
        this.temp = {
          id: data.id,
          typeName: vName,
          urlName: ''
        }
        this.noChooseList = noChoosedPlist.map(item => { return { content: item.name } })
        this.chooseList = choosedPlist.map(item => { return { content: item.name } })
      })
    },
    checkData() {
      const check = false
      const data = this.temp

      console.log('checkData', data)

      if (!data.typeName) {
        this.$message({
          type: 'error',
          message: this.$t('rule.tag.name')
        })
        return true
      }
      return check
    },
    submitBtn(formName) {
      var vid = this.temp.id
      var vtypeName = this.temp.typeName
      var vUrlName = this.temp.urlName
      var vcm = ''
      var vshop = this.$route.query.shop
      const check = this.checkData()
      if (check) {
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          for (var i = 0; i < this.chooseList.length; i++) {
            var cv = this.chooseList[i].content
            if (i === 0) {
              vcm = cv
            } else {
              vcm += ',' + cv
            }
          }

          var params = {
            id: vid,
            typeName: vtypeName,
            urlName: vUrlName,
            contentPro: vcm,
            shop: vshop
          }

          // saveOrUpdate(params).then(response => {
          saveOrUpdateToCfs(params).then(response => {
            var data = response.data
            if (data.code === '300') {
              this.$notify({
                title: this.$t('COMPONENTS.tip'),
                message: this.$t('tagList.TagName-Repeat'),
                type: 'warning'
              })
              return
            }
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.save_Success'),
              type: 'success'
            })
            this.$router.back(-1)
          })
        } else {
          return false
        }
      })
    },
    addPro() {
      for (let i = 0; i < this.noChoosedMode.length; i++) {
        const p = this.noChoosedMode[i]
        this.chooseList.push({
          content: p
        })
        this.noChooseList = this.noChooseList.filter((t) => t.content !== p)
      }
      this.noChoosedMode = []
    },
    delPro() {
      for (let i = 0; i < this.choosedMode.length; i++) {
        const p = this.choosedMode[i]
        this.noChooseList.push({
          content: p
        })
        this.chooseList = this.chooseList.filter((t) => t.content !== p)
      }
      this.choosedMode = []
    }
  }
}
</script>
<style lang="scss">
.el-upload-list__item.is-ready {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
#product-form {
  padding-bottom: 0.5rem;
}
.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}
.card-box {
  margin: 0.5rem 0.5rem 0;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.iframe-class {
  width: 100%;
  border: 0px;
}
.iframe-antho {
  height: 100%;
}
.el-drawer__header{
  background-color: #2ea6f1;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
  // margin-bottom: 30px;
}
.el-drawer__header>:first-child{
  margin-bottom: 18px;
}
.el-drawer__body{
  margin-top: -22px
}
</style>
