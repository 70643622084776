import { render, staticRenderFns } from "./attributeGroup.vue?vue&type=template&id=1ed16065"
import script from "./attributeGroup.vue?vue&type=script&lang=js"
export * from "./attributeGroup.vue?vue&type=script&lang=js"
import style0 from "./attributeGroup.vue?vue&type=style&index=0&id=1ed16065&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("W:\\project\\HK\\twdcettest-onlinetraining-dev\\mall-admin-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ed16065')) {
      api.createRecord('1ed16065', component.options)
    } else {
      api.reload('1ed16065', component.options)
    }
    module.hot.accept("./attributeGroup.vue?vue&type=template&id=1ed16065", function () {
      api.rerender('1ed16065', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/attributeGroup/attributeGroup.vue"
export default component.exports