<template>
  <div>
    <div :id="tagid" style="width: 80%" />
  </div>
</template>

<script>
import jQuery from 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'summernote'
import 'summernote/dist/lang/summernote-zh-TW'
import 'summernote/dist/summernote.css'

export default {
  name: 'Summernote',
  props: {
    value: { // 值
      type: String,
      default: ''
    },
    height: { // summernote富文本的編輯框高度
      type: Number,
      default: 200
    },
    codemirror: { // summernote富文本的頭部小組件配置
      type: Object,
      default() {
        return {
          mode: 'text/html',
          htmlMode: true,
          lineNumbers: true,
          theme: 'monokai'
        }
      }
    },
    tagid: { // 自定義標簽id選擇器，如果父組件沒有傳，默認值為summernote
      type: String,
      default: 'summernote'
    },
    disable: { // summernote富文本的編輯框高度
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.initSummernote()
  },
  methods: {
    initSummernote() {
      const content = this.value
      const disableValue = this.disable
      const _this = this
      jQuery(`#${this.tagid}`).summernote('destroy')
      jQuery(`#${this.tagid}`).html(content)

      if (disableValue) {
        jQuery(`#${this.tagid}`).summernote({
          toolbar: [],
          codemirror: _this.codemirror,
          callbacks: {
            onChange: function(contents, $editable) {
              _this.adjustEditorSize() // 内容变化时调整编辑器尺寸
              _this.$emit('change', contents)
            },
            onInit: () => {
              _this.adjustEditorSize() // 初始化时调整编辑器尺寸
            }
          }
        })
        jQuery(`#${this.tagid}`).summernote('disable')
      } else {
        jQuery(`#${this.tagid}`).summernote({
          height: _this.height,
          codemirror: _this.codemirror,
          callbacks: {
            onChange: function(contents, $editable) {
              _this.$emit('change', contents)
            }
          }
        })
      }
    },
    adjustEditorSize() {
      const editor = jQuery(`#${this.tagid}`)
      editor.height('auto')
      const contentHeight = editor.height()
      const minHeight = 100
      const newHeight = Math.max(contentHeight, minHeight)
      editor.height(newHeight)
    }

  }
}
</script>
