import request from '@/utils/request'
import { API_SERVER_URL } from '../Config'

export function findAllPlanList(param, query) {
  return request({
    url: API_SERVER_URL + '/api/admin/searchPlan' + param,
    method: 'post',
    data: query
  })
}

export function findPlan(query) {
  return request({
    url: API_SERVER_URL + '/api/admin/findPlan',
    method: 'get',
    params: query
  })
}

export function saveServicePlan(param) {
  return request({
    url: API_SERVER_URL + '/api/admin/saveServicePlan',
    method: 'post',
    data: param
  })
}

export function stopSoldById(baseProductId) {
  return request({
    url: API_SERVER_URL + '/api/admin/stopSoldById/' + baseProductId,
    method: 'post'
  })
}

export function deletePlanById(baseProductId) {
  return request({
    url: API_SERVER_URL + '/api/admin/deletePlanById/' + baseProductId,
    method: 'post'
  })
}
