var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _vm.disabledTranslateBtn
                ? _c(
                    "el-col",
                    { staticClass: "page-header", attrs: { span: 12 } },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                  )
                : _c(
                    "el-col",
                    { staticClass: "page-header", attrs: { span: 12 } },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                  ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.disabledTranslateBtn },
                      on: {
                        click: function ($event) {
                          return _vm.setting()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.translate")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.submitBtn("temp")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "card-box" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    {
                      ref: "temp",
                      attrs: {
                        model: _vm.temp,
                        rules: _vm.rules,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "typeName",
                            label: _vm.$t("tagList.tagName"),
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "38%" },
                            attrs: { value: _vm.temp.typeName },
                            model: {
                              value: _vm.temp.typeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "typeName", $$v)
                              },
                              expression: "temp.typeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { display: "none" },
                          attrs: { label: "网址别名" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "38%" },
                            attrs: { name: "urlName", value: _vm.temp.urlName },
                            model: {
                              value: _vm.temp.urlName,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "urlName", $$v)
                              },
                              expression: "temp.urlName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("tagList.Contains-product") },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "200px",
                                float: "left",
                                height: "260px",
                                "margin-top": "-10px",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "75px" } },
                                [_vm._v(_vm._s(_vm.$t("tagList.unselected")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "infinite-scroll",
                                      rawName: "v-infinite-scroll",
                                      value: _vm.load,
                                      expression: "load",
                                    },
                                  ],
                                  staticClass: "infinite-list",
                                  staticStyle: {
                                    overflow: "auto",
                                    width: "200px",
                                    height: "200px",
                                    border: "1px #DCDFE6 solid",
                                    "list-style-type": "none",
                                    "margin-top": "0px",
                                  },
                                },
                                _vm._l(
                                  _vm.noChooseList,
                                  function (item, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticStyle: { "margin-left": "-25px" },
                                      },
                                      [
                                        _c("el-checkbox", {
                                          attrs: {
                                            label: item.content,
                                            border: "",
                                          },
                                          model: {
                                            value: _vm.noChoosedMode,
                                            callback: function ($$v) {
                                              _vm.noChoosedMode = $$v
                                            },
                                            expression: "noChoosedMode",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "50px",
                                float: "left",
                                "margin-top": "80px",
                                "margin-left": "50px",
                              },
                            },
                            [
                              _c("el-button", { on: { click: _vm.addPro } }, [
                                _vm._v(">"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-left": "0px",
                                    "margin-top": "10px",
                                  },
                                  on: { click: _vm.delPro },
                                },
                                [_vm._v("<")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "200px",
                                float: "left",
                                height: "260px",
                                overflow: "auto",
                                "margin-left": "60px",
                                "margin-top": "-10px",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "75px" } },
                                [_vm._v(_vm._s(_vm.$t("tagList.selected")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    height: "200px",
                                    border: "1px #DCDFE6 solid",
                                    overflow: "auto",
                                  },
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      staticStyle: {
                                        "list-style-type": "none",
                                        "margin-left": "-25px",
                                      },
                                    },
                                    _vm._l(
                                      _vm.chooseList,
                                      function (item, index) {
                                        return _c(
                                          "li",
                                          { key: index },
                                          [
                                            _c("el-checkbox", {
                                              attrs: {
                                                label: item.content,
                                                border: "",
                                              },
                                              model: {
                                                value: _vm.choosedMode,
                                                callback: function ($$v) {
                                                  _vm.choosedMode = $$v
                                                },
                                                expression: "choosedMode",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-drawer",
            {
              staticClass: "translate-drawer",
              attrs: {
                title: _vm.$t("COMPONENTS.translate"),
                visible: _vm.drawer,
                size: "60%",
                "show-close": false,
                "modal-append-to-body": false,
                "append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
                opened: _vm.getIframe,
              },
            },
            [
              _c("iframe", {
                ref: "iframe",
                staticClass: "iframe-class",
                attrs: { src: _vm.ifreamSrc, height: _vm.getClientHeight() },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }