<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="150px"
        >
          <el-row :gutter="20">
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('attribute.attrName')">
                <el-input
                  v-model="query.attrName"
                  style="width: 100%"
                  :placeholder="$t('COMPONENTS.incontent')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('attribute.dataType')">
                <el-select v-model="query.dataType" :placeholder="$t('COMPONENTS.inselect')">
                  <el-option label="TEXT" value="TEXT">TEXT</el-option>
                  <el-option label="IMAGE" value="IMAGE">IMAGE</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$t('attribute.valueName')">
                <el-input v-model="query.valueName" placeholder="" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-button :disabled="checkPermission('attribute')" @click="loadData">{{ $t('COMPONENTS.search') }}</el-button>
            <el-button :disabled="checkPermission('attribute.add')" @click="handleCreate">{{ $t('COMPONENTS.add') }}</el-button>
          </el-row>
        </el-form>
      </div>
    </transition>

    <el-table
      tooltip-effect="dark"
      :data="data"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
    >
      <el-table-column type="selection" align="center" />
      <el-table-column :label="$t('attribute.attrName')" prop="name" width="150px" align="left" />
      <el-table-column :label="$t('attribute.dataType')" width="150px" align="left" prop="dataType" />
      <el-table-column :label="$t('attribute.valueName')" align="left">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.attrValues" :key="item.id">{{
            item.name
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('COMPONENTS.operation')" fixed="right" width="180px">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="checkPermission('attribute.edit')"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.edit') }}</el-button>
          <el-button
            size="mini"
            :disabled="checkPermission('attribute.delete')"
            @click="handleDelete(scope.$index, scope.row)"
          >{{ $t('COMPONENTS.delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="query.page"
      :limit.sync="query.size"
      :style="pageStyle"
      layout="total, sizes, prev, pager, next, jumper"
      @pagination="loadData"
    />
  </div>
</template>

<script>
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import { deleteAttrById, adminSearchList } from '@/api/productAttributeApi'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'Attribute',
  components: { Pagination },
  directives: { waves },
  filters: {},
  data() {
    return {
      attribute: {},
      total: 0,
      data: [],
      pageStyle: {
        margin: '0px',
        padding: '5px'
      },
      query: {
        size: 10,
        page: 1,
        attrName: null,
        valueName: null,
        datatype: null
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {

    loadData() {
      var quary = { ...this.query }
      var param = getPageParm(quary)
      adminSearchList(param, quary).then((resp) => {
        console.log(resp)
        this.data = resp.data
        this.total = resp.pageCount
        this.query.page = resp.pageNum
        this.query.size = resp.pageSize
      })
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    handleCreate() {
      this.$router.push({ path: 'attribute/create' })
    },
    handleEdit(index, row) {
      this.$router.push({ path: 'attribute/edit', query: { attrId: row.id }})
    },
    handleDelete(index, row) {
      this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        deleteAttrById(row.id).then((resp) => {
          if (resp) {
            this.data.splice(index, 1)
            this.$notify.success({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.delete_Success')
            })
            this.loadData()
          }
        })
      })
    }
  }
}
</script>
