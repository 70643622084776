<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">{{ $t('COMPONENTS.add') }}</el-col>
        <el-col :span="12">
          <el-button @click="$router.back(-1)">{{ $t('COMPONENTS.return') }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            @click="handleSave"
            :disabled='checkPermission("attribute.add")'
          >{{ $t('COMPONENTS.save') }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form ref="attr" :model="attr" :rules="rules" label-width="80px">
      <el-row>
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('attribute.attrName')" prop="name">
                  <el-input v-model="attr.name" style="width: 200px" clearable />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('attribute.dataType')" prop="dataType">
                  <el-select v-model="attr.dataType" :placeholder="$t('attribute.Please-select-type')">
                    <el-option label="TEXT" value="TEXT" />
                    <el-option label="IMAGE" value="IMAGE" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item :label="$t('attribute.rowNum')">
                  <el-row :gutter="20">
                    <el-col :sm="6" :xs="12">
                      <el-input v-model="rowCount" :placeholder="$t('attribute.Number-of-rows-added')" />
                    </el-col>
                    <el-col :sm="6" :xs="12">
                      <el-button @click="addLanguage()">{{ $t('COMPONENTS.add') }}</el-button>
                    </el-col>
                  </el-row>
                  <el-table :data="attr.attrValues" style="width: 100%" class="value-table">
                    <el-table-column :label="$t('attribute.valueName')" width="180px">
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="'attrValues.' + scope.$index + '.name'"
                          :rules="rules.value"
                        >
                          <el-input
                            v-if="scope.row.edit"
                            v-model="scope.row.name"
                            style="width: 160px"
                            clearable
                          />
                          <span v-else>{{ scope.row.name }}</span>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('attribute.ValueDescription')" width="200">
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-input
                            v-if="scope.row.edit"
                            v-model="scope.row.description"
                            style="width: 180px"
                          />
                          <span v-else>{{ scope.row.name }}</span>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('COMPONENTS.operation')" width="200">
                      <template slot-scope="scope">
                        <el-button
                          icon="el-icon-delete"
                          circle
                          @click="delRow(scope.$index)"
                          :disabled='checkPermission("attribute.add")'
                        />
                        <el-button
                          icon="el-icon-edit"
                          circle
                          @click="editRow(scope.row)"
                          :disabled='checkPermission("attribute.add")'
                        />
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { saveAttr } from '@/api/productAttributeApi'
import { checkCfsPermission } from '@/utils/permission';
export default {
  name: 'ProductAttributeCreate',
  components: { Sticky },
  data() {
    return {
      edit: true,
      rowCount: 1,
      attr: {
        name: null,
        dataType: null,
        attrValues: [
          {
            id: null,
            name: null,
            description: '',
            imageUrl: '',
            imageUrlId: '',
            edit: true
          }
        ]
      },
      rules: {
        name: [{ required: true, message: this.$t('rule.attributeName-cannot-be-empty') , trigger: 'blur' }],
        dataType: [{ required: true, message: this.$t('rule.attributeType-cannot-be-empty'), trigger: 'change' }],
        value: [{ required: true, message: this.$t('rule.attributeValue-cannot-be-empty'), trigger: 'blur' }]
      }
    }
  },
  mounted() {},
  methods: {
    handleSave() {
      this.$refs['attr'].validate((valid) => {
        if (valid) {
          saveAttr(this.attr).then((resp) => {
            var resData = resp.data
            if (resData) {
              if(resData.exist){
                this.$message.error(this.$t('rule.attributeName-is-exist'))
                return false
              }else{
                this.$message.success(this.$t('COMPONENTS.save_Success'))
              }
            } else {
              this.$message.error(this.$t('COMPONENTS.save_Filad'))
            }
            this.$router.back(-1)
          })
        } else {
          return false
        }
      })
    },
    checkPermission(value){
      return checkCfsPermission(value)
    },
    addLanguage() {
      var re = /^[1-9]+[0-9]*]*$/
      var rowCount = 1
      if (re.test(this.rowCount)) {
        rowCount = this.rowCount
      }
      for (var i = 0; i < rowCount; i++) {
        this.attr.attrValues.push({
          id: '',
          name: '',
          description: '',
          imageUrl: '',
          imageUrlId: '',
          edit: true
        })
      }
    },
    delRow(row) {
      console.log('row-----', row)
      if (this.attr.attrValues[row].name) {
        this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
          confirmButtonText: this.$t('COMPONENTS.sure'),
          cancelButtonText: this.$t('COMPONENTS.cancel'),
          type: 'warning'
        }).then(() => {
          this.attr.attrValues.splice(row, 1)
        })
      } else {
        this.attr.attrValues.splice(row, 1)
      }
    },
    editRow(row) {
      console.info('++++', row)
      row.edit = !row.edit
    }
  }
}
</script>

<style scoped lang="scss">
.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.component {
  margin: 10px;
}
.tree-box {
  margin: 15px 0 100px 55px;
  border: #a6e0d8 solid 2px;
  padding: 15px;
}
.form-item-head {
  margin-bottom: 8px;
  font-size: 12px;
}
.hide .el-upload--picture-card {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.value-table /deep/ .cell {
   padding: 0px 10px 16px 10px;
}

</style>
