import Layout from '@/layout'
import { checkCfsPermission } from '../../utils/permission'

export default {
  path: '/shop',
  component: Layout,
  redirect: '/shop/shopList',
  alwaysShow: true,
  name: 'shopManage',
  hidden: false,
  meta: {
    title: 'navbar.shop_Manage.label',
    icon: 'chart'
  },
  children: [
    {
      path: 'shopList',
      component: () => import('@/views/shop/shopList'),
      name: 'shopList',
      hidden: checkCfsPermission('shop'),
      meta: {
        title: 'navbar.shop_Manage.shopList.label',
        noCache: true,
        activeMenu: '/shop/shopList'
      }
    },
    {
      path: 'shopCreate',
      component: () => import('@/views/shop/shopCreate'),
      name: 'shopCreate',
      meta: {
        title: 'shopList.shopCreate.label',
        noCache: false
      },
      hidden: true
    },
    {
      path: 'shopEdit',
      component: () => import('@/views/shop/shopEdit'),
      name: 'shopEdit',
      meta: {
        title: 'shopList.shopEdit.label',
        noCache: false
      },
      hidden: true
    }
  ]
}
