var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 12 } },
                [
                  _vm._v(
                    "\n         " +
                      _vm._s(
                        _vm.edit
                          ? _vm.$t("Edit Ingredient")
                          : _vm.$t("Add New Ingredient")
                      ) +
                      "\n       "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.disabledTranslateBtn },
                      on: {
                        click: function ($event) {
                          return _vm.translate()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.translate")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "success" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { model: _vm.editingRecord, "label-width": "200px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Ingredient Name") } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    model: {
                                      value: _vm.editingRecord.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editingRecord, "name", $$v)
                                      },
                                      expression: "editingRecord.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "Ingredient Category (Product Page)"
                                    ),
                                  },
                                },
                                [
                                  _c("el-select-tree", {
                                    attrs: {
                                      clearable: "",
                                      data: _vm.ingredientProductTreeData,
                                      multiple: false,
                                      "check-strictly": "",
                                      "check-on-click-node": "",
                                      "render-after-expand": false,
                                    },
                                    model: {
                                      value:
                                        _vm.editingRecord
                                          .defaultIngredientProductTreeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editingRecord,
                                          "defaultIngredientProductTreeId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editingRecord.defaultIngredientProductTreeId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "Ingredient Category (Daily Menu Page)"
                                    ),
                                  },
                                },
                                [
                                  _c("el-select-tree", {
                                    attrs: {
                                      clearable: "",
                                      data: _vm.ingredientDailyMenuTreeData,
                                      multiple: false,
                                      "check-strictly": "",
                                      "check-on-click-node": "",
                                      "render-after-expand": false,
                                    },
                                    model: {
                                      value:
                                        _vm.editingRecord
                                          .defaultIngredientDailyMenuTreeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editingRecord,
                                          "defaultIngredientDailyMenuTreeId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editingRecord.defaultIngredientDailyMenuTreeId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Link Type") } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.editingRecord.linkType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingRecord,
                                            "linkType",
                                            $$v
                                          )
                                        },
                                        expression: "editingRecord.linkType",
                                      },
                                    },
                                    _vm._l(_vm.linkTypeList, function (data) {
                                      return _c("el-option", {
                                        key: data.value,
                                        attrs: {
                                          label: data.label,
                                          value: data.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.editingRecord.linkType === "ONE_PRODUCT_ID"
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, xs: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Please choose one product"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-transfer", {
                                        attrs: {
                                          id: "ingredientPageTransfer",
                                          filterable: "",
                                          titles: [
                                            _vm.$t("tagList.unselected"),
                                            _vm.$t("tagList.selected"),
                                          ],
                                          "filter-placeholder": "Search here",
                                          "filter-method": _vm.filterMethod,
                                          data: _vm.productList,
                                        },
                                        on: {
                                          change: _vm.handleTransferChange,
                                        },
                                        model: {
                                          value:
                                            _vm.editingRecord.selectProductId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editingRecord,
                                              "selectProductId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editingRecord.selectProductId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editingRecord.linkType === "URL"
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, xs: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: _vm.$t("URL") } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "500px" },
                                        model: {
                                          value: _vm.editingRecord.linkUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editingRecord,
                                              "linkUrl",
                                              $$v
                                            )
                                          },
                                          expression: "editingRecord.linkUrl",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Status") } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.editingRecord.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingRecord,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "editingRecord.status",
                                      },
                                    },
                                    _vm._l(
                                      _vm.statusOptions,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Wiki Url") } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    model: {
                                      value: _vm.editingRecord.wikiUrl,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editingRecord,
                                          "wikiUrl",
                                          $$v
                                        )
                                      },
                                      expression: "editingRecord.wikiUrl",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Image"),
                                    prop: "imgUrl2",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "avatar-uploader",
                                      attrs: {
                                        action: "#",
                                        "http-request": _vm.uploadImages,
                                        "file-list": _vm.elImgObj,
                                        accept: ".jpg,.jpeg,.png,.gif",
                                        "show-file-list": false,
                                        "before-upload": function (file) {
                                          return _vm.beforeFileUpload(
                                            file,
                                            "IMAGE"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.imgUrl2
                                        ? _c("img", {
                                            staticClass: "avatar",
                                            attrs: { src: _vm.imgUrl2 },
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-plus avatar-uploader-icon",
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("Description") } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "500px" },
                                    attrs: { type: "textarea", rows: 5 },
                                    model: {
                                      value: _vm.editingRecord.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editingRecord,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "editingRecord.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Ingredient Prepare Video"),
                                  },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      key: _vm.componentKey,
                                      staticStyle: { width: "800px" },
                                      attrs: {
                                        "tooltip-effect": "dark",
                                        data: _vm.editingRecord.videos,
                                        "header-row-class-name": "table-header",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label:
                                            _vm.$t("Video Tag") +
                                            " (" +
                                            _vm.$t("For admin page") +
                                            ")",
                                          prop: "tag",
                                          width: "250px",
                                          align: "left",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t("Video"),
                                          prop: "file",
                                          width: "200px",
                                          align: "left",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                row.file !== null
                                                  ? _c("video", {
                                                      staticClass: "avatar",
                                                      attrs: {
                                                        src: row.file.url,
                                                        controls: "controls",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "applyNum",
                                          width: "150px",
                                          align: "left",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  [
                                                    _vm._v(
                                                      "\n                         " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Apply Quantity"
                                                          )
                                                        ) +
                                                        "\n                         "
                                                    ),
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          aa: scope,
                                                          effect: "dark",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-question",
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                             " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "apply_quantity_remark"
                                                                  )
                                                                ) +
                                                                "\n                           "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t("COMPONENTS.operation"),
                                          fixed: "right",
                                          width: "200px",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editVideo(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "COMPONENTS.edit"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        scope.row.applyNum !==
                                                        0,
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteVideoItem(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "COMPONENTS.delete"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("p"),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { width: "750px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addVideo()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        "custom-class": "dialog-class",
                                        title: _vm.$t(
                                          "Ingredient Prepare Video"
                                        ),
                                        visible: _vm.showEditVideoDialog,
                                        width: "50%",
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.showEditVideoDialog = $event
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "temp",
                                          attrs: {
                                            model: _vm.videoDialogTempData,
                                            "label-width": "180px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "tag",
                                                label: _vm.$t("Video Tag"),
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "200px" },
                                                model: {
                                                  value:
                                                    _vm.videoDialogTempData.tag,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.videoDialogTempData,
                                                      "tag",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "videoDialogTempData.tag",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("p"),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: _vm.$t("Video") },
                                            },
                                            [
                                              _vm.videoDialogTempData.file ===
                                              null
                                                ? _c(
                                                    "el-upload",
                                                    {
                                                      attrs: {
                                                        action: "#",
                                                        "http-request":
                                                          _vm.uploadVideo,
                                                        accept:
                                                          _vm.acceptVideoType,
                                                        "list-type":
                                                          "picture-card",
                                                        "before-upload":
                                                          function (file) {
                                                            return _vm.beforeFileUpload(
                                                              file,
                                                              "VIDEO"
                                                            )
                                                          },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-plus",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.videoDialogTempData.file !==
                                              null
                                                ? _c("video", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      src: _vm
                                                        .videoDialogTempData
                                                        .file.url,
                                                      controls: "controls",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _vm.videoDialogTempData.file !==
                                              null
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:void(0)",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteVideoFile()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-delete",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("p"),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "dialog-footer",
                                          attrs: { slot: "footer" },
                                          slot: "footer",
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.showEditVideoDialog = false
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("COMPONENTS.return")
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveVideo()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("COMPONENTS.save")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "translate-drawer",
          attrs: {
            title: _vm.$t("COMPONENTS.translate"),
            visible: _vm.tDrawer,
            size: "60%",
            "show-close": false,
            "modal-append-to-body": false,
            "append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tDrawer = $event
            },
            opened: _vm.getIframe,
          },
        },
        [
          _vm.tDrawer
            ? _c("iframe", {
                ref: "iframe",
                staticClass: "iframe-class",
                attrs: { src: _vm.ifreamSrc, height: _vm.getClientHeight() },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }