<template>
  <div id="product-form">
    <sticky :z-index="999" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="header">{{ menuNameLabel }}</el-col>
        <el-col :span="12">
          <el-button @click="showTranslateDrawer()">{{
            $t("COMPONENTS.translate")
          }}</el-button>
          <!-- <el-button @click="transitionFunPage()">local{{
            $t("COMPONENTS.translate")
          }}</el-button> -->
          <el-button @click="back()">{{
            $t("COMPONENTS.return")
          }}</el-button>
          <el-button @click="submitBtn()">{{
            $t("COMPONENTS.save")
          }}</el-button>
          <el-button v-if="!!temp.id && temp.queryVersion !== temp.publicVersion" @click="publicBtn('UP')">{{ $t("Publish") }}</el-button>
          <el-button v-if="!!temp.id && temp.queryVersion === temp.publicVersion" @click="publicBtn('DOWN')">{{ $t("Discontinue") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form
      ref="temp"
      class="search-form-inline"
      :model="temp"
      :rules="rules"
      label-suffix=":"
      label-position="left"
      label-width="200px"
    >
      <el-row>
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row v-if="pageType !== 'CREATE'">
              <el-row :gutter="20">
                <el-col :sm="24" :xs="24">
                  <el-form-item>
                    <span slot="label">{{ $t("Basic ID") }}</span>
                    {{ temp.baseProductId }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :key="temp.id" :gutter="20">
                <el-col :sm="24" :xs="24">
                  <el-form-item>
                    <span slot="label">{{ $t("Version ID") }}</span>
                    {{ temp.id }}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <el-row v-if="pageType !== 'CREATE'" :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Select Version')">
                  <el-select
                    v-model="temp.queryVersion"
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 408px"
                    clearable
                    @change="onChangeVersion"
                  >
                    <el-option
                      v-for="item in temp.productVersionHistory"
                      :key="item"
                      :label="item + (temp.publicVersion === item ? ( '  ('+$t('Publish') + ')' ):'')"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item
                  prop="adminTag"
                  :label="$t('Admin Tag')"
                >
                  <el-input
                    v-model="temp.adminTag"
                    style="width: 408px"
                  />
                  <el-tooltip class="item" effect="dark" :content="$t('Admin.adminTag.tooltip')" placement="top">
                    <i class="el-icon-warning" />
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('Admin Remark')">
                  <el-input
                    v-model="temp.adminRemark"
                    :placeholder="$t('Admin.adminRemark.tooltip')"
                    style="width: 408px"
                  />
                  <el-tooltip class="item" effect="dark" :content="$t('Admin.adminRemark.tooltip')" placement="top">
                    <i class="el-icon-warning" />
                  </el-tooltip>

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item
                  prop="name"
                  :label="$t(productNameLabel)"
                >
                  <el-input
                    v-model="temp.name"
                    placeholder=""
                    style="width: 408px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('productItem.remarkTpl')">
                  <el-select
                    v-model="temp.remarkTmpId"
                    :disabled="pageType !== 'CREATE'"
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 408px"
                    clearable
                    @change="onChangeRemarkTpl"
                  >
                    <el-option
                      v-for="item in remarkTplList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item
                  prop="category"
                  :label="$t('Category/Subcategory')"
                >
                  <el-cascader
                    ref="cascader"
                    v-model="temp.category"
                    :options="temp.categoryOptions"
                    :props="props"
                    clearable
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 408px"
                    @visible-change="v=>visibleChange(v, 'cascader', addTreeNode,'category')"
                    @change="changeCategory"
                    @remove-tag="removeCatrhory"
                  />
                  <!-- <el-select v-model="temp.category" placeholder="请选择" multiple>
                    <el-option
                      v-for="item in temp.categoryOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <!-- <el-col :sm="4" :xs="4">
                <el-button icon="el-icon-plus" @click="addTreeNode('category')">{{ $t('COMPONENTS.add') }}</el-button>
              </el-col> -->
            </el-row>

            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item prop="tag" :label="$t('productItem.multiTag')">
                  <el-select
                    ref="tagRef"
                    v-model="temp.tag"
                    :disabled="checkPermission('SHOP_PRODUCT_TAG')"
                    :placeholder="$t('COMPONENTS.inselect')"
                    multiple
                    style="width: 408px"
                    :popper-append-to-body="true"
                    @visible-change="v=>visibleChange2(v, 'tagRef',addTreeNode,'tag')"
                  >
                    <el-option
                      v-for="item in temp.tagOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />

                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :sm="4" :xs="4">
                <el-button icon="el-icon-plus" @click="addTreeNode('tag')">{{ $t('COMPONENTS.add') }}</el-button>
              </el-col> -->
            </el-row>
            <el-dialog
              :title="$t('COMPONENTS.add')"
              :visible.sync="treeDialogVisible"
              width="38%"
            >
              <el-row :gutter="10">
                <el-col :xs="8" :sm="6" :md="8" :lg="8" :xl="8" style="font-weight:bold">{{ $t('tree.name') }}</el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-input
                    v-model="saveData.name"
                    placeholder=""
                    style="width: 250px"
                  />
                </el-col>
              </el-row>
              <br>
              <el-row :gutter="10" :style="treeIsTagType">
                <el-col :xs="8" :sm="6" :md="8" :lg="8" :xl="8" style="font-weight:bold">{{ $t('tree.nestLabelUnder') }}</el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-cascader
                    v-model="saveData.parentId"
                    :options="temp.categoryOptions"
                    :props="treeProps"
                    :get-checked-nodes="getCheckedNodes"
                    clearable
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 250px"
                  />
                </el-col>
              </el-row>
              <!-- <el-row :gutter="1">
                <el-col :sm="24" :xs="24">
                  <el-form-item
                    prop="name"
                    :label="$t('tree.name')"
                    hide-required-asterisk="true"
                  >
                    <el-input
                      v-model="saveData.name"
                      placeholder=""
                      style="width: 250px"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="1" :style="treeIsTagType">
                <el-col :sm="24" :xs="24">
                  <el-form-item
                    :label="$t('tree.nestLabelUnder')"
                  >
                    <el-cascader
                      v-model="saveData.parentId"
                      :options="temp.categoryOptions"
                      :props="treeProps"
                      :getCheckedNodes="getCheckedNodes"
                      clearable
                      :placeholder="$t('COMPONENTS.inselect')"
                      style="width: 250px"
                    />
                  </el-form-item>
                </el-col>
              </el-row> -->
              <span slot="footer" class="dialog-footer">
                <el-button @click="handCancelAddTree()">{{ $t("COMPONENTS.return") }}</el-button>
                <el-button type="primary" @click="submitNewTreeNode()">{{ $t("COMPONENTS.save") }}</el-button>
              </span>
            </el-dialog>
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item prop="brand" :label="$t('productItem.brand')">
                  <el-select
                    v-model="temp.brand"
                    :disabled="checkPermission('SHOP_PRODUCT_BRAND')"
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 408px"
                  >
                    <el-option
                      v-for="item in temp.brandList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item
                  :label="$t('productItem.class')"
                  prop="class"
                >
                  <el-select
                    v-model="temp.class"
                    :disabled="checkPermission('SHOP_PRODUCT_CLASS_TYPE')"
                    clearable
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 408px"
                  >
                    <el-option
                      v-for="item in temp.classList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('productItem.label')">
                  <el-input
                    v-model="temp.label"
                    :disabled="checkPermission('SHOP_PRODUCT_LEVEL_OF_SALES')"
                    placeholder=""
                    style="width: 408px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20" v-if="!temp.id"> -->
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('productItem.hot')">
                  <el-switch
                    v-model="temp.hot"
                    :disabled="checkPermission('SHOP_PRODUCT_HOT')"
                    active-color="#13ce66"
                    inactive-color="#808080"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('productItem.lastModifiedDate')">{{
                  temp.lastModifiedDate
                }}</el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!temp.id" :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item
                  v-if="!temp.id"
                  :label="$t('productItem.Outlets_limited')"
                  prop="shop"
                >
                  <el-input
                    v-model="temp.shopAll"
                    placeholder=""
                    style="display: none"
                  />
                  <el-radio-group
                    v-model="temp.shopRadio"
                    :disabled="checkPermission('SHOP_PRODUCT_SHOP_LIMITE')"
                    @change="checkShopRadio"
                  >
                    <el-radio label="false">{{
                      $t("productItem.all_Outlets")
                    }}</el-radio>
                    <el-radio label="true">
                      {{ $t("productItem.multiOutlets_limited") }}
                      <el-select
                        v-model="temp.shop"
                        :disabled="checkPermission('SHOP_PRODUCT_SHOP_LIMITE')"
                        :placeholder="$t('COMPONENTS.inselect')"
                        multiple
                        @change="checkShop"
                      >
                        <el-option
                          v-for="item in temp.shopOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item v-else :label="$t('productItem.Outlets_limited')">
                  {{ shopStr }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">

                <!--
                <el-form-item :label="$t('productItem.visible')">
                  <el-switch
                    v-model="temp.visible"
                    active-color="#13ce66"
                    inactive-color="#808080"
                  />
                </el-form-item>
                -->

                <el-form-item
                  :label="$t('productItem.status')"
                >
                  <el-select
                    v-model="temp.itemStatus"
                    :placeholder="$t('COMPONENTS.inselect')"
                    style="width: 408px"
                  >
                    <el-option
                      v-for="item in temp.itemStatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    />
                  </el-select>
                </el-form-item>

              </el-col>
            </el-row>

            <!--
            <el-row :gutter="20">
              <el-col :sm="24" :xs="24">
                <el-form-item :label="$t('productItem.status')">
                  <el-switch
                    v-model="temp.status"
                    active-color="#13ce66"
                    inactive-color="#808080"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            -->

          </el-card>
        </el-col>

        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <!-- 其他外觀設置 -->
              <el-col :span="24" class="card-title">{{ $t("productItem.css_setting.label") }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item :label="$t('productItem.css_setting.photo.style')">
                  <!-- 圖片 -->
                  <el-select v-model="temp.cssPhoto" style="width: 400px;" @change="onChangeCssPhoto(option.key)">
                    <el-option key="COVER" :label="$t('productItem.css_setting.photo.cover') + '(' + $t('Default') + ')'" value="COVER" />
                    <!-- 保持原有尺寸比例。但部分内容可能被剪切(預設值) -->
                    <el-option key="CONTAIN" :label="$t('productItem.css_setting.photo.contain')" value="CONTAIN" />
                    <!-- 保持原有尺寸比例。内容被缩放 -->
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>

        <!-- 產品資訊-->
        <el-col v-if="remarkReply.allowIngredient" :key="componentKey" :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{
                $t("Product Parameter")
              }}</el-col>
            </el-row>

            <el-row
              v-for="(item, index) in temp.infos"
              :key="index"
              :name="String(index)"
              :gutter="20"
            >
              <el-col>

                <el-form-item
                  :prop="item.infoKey"
                  :label="$t(item.infoKey)"
                >
                  <el-input
                    v-model="item.infoValue"
                    placeholder=""
                    style="width: 100px"
                  />

                </el-form-item>

              </el-col>
            </el-row>

          </el-card>
        </el-col>

        <!-- Remark type開始-->
        <el-col :key="remarkTypecomponentKey" :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{
                $t(productIntroductionLabel)
              }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-tabs
                  v-model="editableTabsValue"
                  type="card"
                  editable
                  class="demo-tabs"
                  @edit="handleTabsEdit"
                  @tab-click="tabClickEvent"
                >
                  <el-tab-pane
                    v-for="(item, index) in remarkReply.remarkSectionList"
                    :key="index"
                    :name="String(index)"
                    :closable="false"
                  >
                    <span slot="label"> {{ item.sectionName }}</span>

                    {{ $t("Tab name") }}： <!-- 標籤名稱 -->
                    <el-input v-model="item.sectionName" style="width: 200px" :disabled="!!item.remarkTplSectionId" />
                    &nbsp;&nbsp;&nbsp;&nbsp;

                    {{ $t("Tab position") }}：<!-- 標籤位置 -->
                    <el-button :disabled="index===0 || !!item.remarkTplSectionId || remarkReply.tempIndex === index-1" @click="moveSectionForward(index)">
                      <i class="el-icon-arrow-left" />
                    </el-button>

                    <el-button :disabled="index === remarkReply.remarkSectionList.length - 1 || !!item.remarkTplSectionId" @click="moveSectionBack(index)">
                      <i class="el-icon-arrow-right" />
                    </el-button>

                    <div style="float:right">
                      {{ $t("Pay to unlock") }}：<!-- 付費解鎖 -->
                      <el-switch
                        v-model="item.needPay"
                        active-color="#13ce66"
                        inactive-color="#808080"
                        :disabled="!!item.remarkTplSectionId"
                      />
                    </div>
                    <el-row :gutter="20">
                      <el-col v-if="item.optionType && item.optionType === 'INGREDIENTS'" :span="24">
                        <el-card class="ingredient-transfer-card">
                          <el-transfer
                            id="productIngredientTransfer"
                            v-model="ingredientDataSelectId"
                            target-order="unshift"
                            :titles="[$t('Source'), $t('Target')]"
                            filterable
                            :filter-placeholder="$t('Search')"
                            :data="ingredientData"
                            @change="selectIngredient"
                          >
                            <div slot-scope="{option}">
                              <template>
                                <el-row style="width: 920px;">
                                  <el-col :span="5"> {{ option.label }}</el-col>
                                  <el-col v-if="ingredientDataSelectId.includes(option.key)" :span="3">
                                    <!-- 如果是適量，則不顯示這個INPUT-->
                                    <el-input v-if="option.showQty" :key="option.key" v-model="option.qty" :placeholder="$t('Qty')" @change="onChangeQty(option.key, $event)" />
                                    <!-- 如果是適量，則不顯示這個INPUT，但是要占用一個欄位，不然排版會醜，所以放了這個DIV-->
                                    <div v-if="!option.showQty">&nbsp;</div>
                                  </el-col>
                                  <el-col v-if="ingredientDataSelectId.includes(option.key)" :span="4">
                                    <el-select :key="option.key" v-model="option.uomId" :placeholder="$t('Uom')" @change="onChangeUomId(option.key, $event)">
                                      <el-option
                                        v-for="i in ingredientUom"
                                        :key="i.value"
                                        :label="i.label"
                                        :value="i.value"
                                      />
                                    </el-select>
                                  </el-col>

                                  <!-- 食材在產品頁面的描述 -->
                                  <el-col v-if="ingredientDataSelectId.includes(option.key)" :span="4">
                                    <el-select-tree
                                      v-model="option.ingredientProductTreeId"
                                      a-c
                                      clearable
                                      :data="ingredientType"
                                      :multiple="false"
                                      check-strictly
                                      check-on-click-node
                                      :render-after-expand="false"
                                      @change="(data) => onChangeIngredientType(option.key, data)"
                                    />
                                  </el-col>

                                  <!-- 食材的描述 -->
                                  <el-col v-if="ingredientDataSelectId.includes(option.key)" :span="4">
                                    <el-input :key="option.key" v-model="option.productDescription" :placeholder="$t('Description')" @change="onChangeIngredientDescription(option.key, $event)" />
                                  </el-col>

                                  <!-- 食譜準備的影片 -->
                                  <el-col v-if="ingredientDataSelectId.includes(option.key) && option.videos.length !==0 " :span="4">
                                    <el-select :key="option.key" v-model="option.prepareMediaId" :placeholder="$t('Prepare Video')" clearable @change="onChangePrepareVideo(option.key, $event)">
                                      <el-option
                                        v-for="i in option.videos"
                                        :key="i.id"
                                        :label="i.tag"
                                        :value="i.id"
                                      />
                                    </el-select>
                                  </el-col>

                                </el-row>
                              </template>
                            </div>
                          </el-transfer>
                        </el-card>
                      </el-col>
                      <el-col v-else :span="24">
                        <el-card>
                          <el-card v-for="(remarkItem, remarkIndex) in item.remarkItemList" :key="remarkIndex" class="card-box">
                            <!-- <el-card v-for="(remarkItem, remarkIndex) in item.remarkItemList" :key="remarkIndex" > -->
                            <div>

                              <!-- 20230525暫時移除，功能太複雜 -->
                              <!--
                              <el-row :gutter="20">
                                <el-col :span="24" class="card-title">
                                  {{ $t("Content header") }}：
                                  <el-input v-model="remarkItem.itemName" style="width: 200px" />

                                  <div style="float:right">
                                    <el-button :disabled="remarkIndex===0" @click="moveItemForward(index, remarkIndex)">
                                      <i class="el-icon-arrow-up" />
                                    </el-button>
                                    <el-button :disabled="remarkIndex === item.remarkItemList.length - 1" @click="moveItemBack(index, remarkIndex)">
                                      <i class="el-icon-arrow-down" />
                                    </el-button>
                                    &nbsp;&nbsp;
                                    <el-button @click="removeRemarkItemHeld(index, remarkIndex)">
                                      <i class="el-icon-close" />
                                    </el-button>
                                  </div>
                                </el-col>
                              </el-row>
      -->
                              <el-row :gutter="20">
                                <!-- <el-col v-if="!item.notAllowUpdate"> -->
                                <el-col v-if="item.notAllowUpdate && !!item.remarkTplSectionId">
                                  <!-- <quill-editor v-model="remarkItem.itemValue" class="product-remark-quill" :disabled="item.notAllowUpdate" :options="hideOption" /> -->
                                  <div class="remark-value-disable">
                                    <Summernote
                                      :key="remarkItem.itemValue"
                                      disable="true"
                                      :tagid="generateKey(index)"
                                      :value="remarkItem.itemValue"
                                      @change="(value)=>changeRemarkItemValue(value,index,remarkIndex)"
                                    />
                                  </div>
                                </el-col>
                                <el-col v-else>
                                  <Summernote
                                    :tagid="generateKey(index)"
                                    :value="remarkItem.itemValue"
                                    @change="(value)=>changeRemarkItemValue(value,index,remarkIndex)"
                                  />
                                </el-col>
                                <!-- <el-col v-else>
                                    <div v-html="remarkItem.itemValue" />
                                </el-col> -->
                              </el-row>
                            </div>
                          </el-card>
                        </el-card>
                      </el-col>
                    </el-row>
                    <br>
                    <!-- 20230525暫時移除，功能太複雜 -->
                    <!--
                    <el-button
                      style="float:right"
                      @click="addRemarkItemHeld(index)"
                    >
                      <i class="el-icon-plus" />{{ $t("Add sub content") }}

                    </el-button>
                    &nbsp;&nbsp;&nbsp;
 -->
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <!-- Remark type結束 -->
        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{
                $t("Preview pictures and videos")
              }}</el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item
                  :label="$t('productItem.mainImg')"
                  prop="mainImgUrl"
                >
                  <el-upload
                    action="#"
                    :http-request="uploadMainImages"
                    :file-list="mainBannerUrl"
                    :accept="acceptImageType"
                    class="avatar-uploader"
                    :show-file-list="false"
                    :before-upload="(file) => beforeFileUpload(file, 'IMAGE')"
                  >
                    <img v-if="mainImgUrl" :src="mainImgUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon" />
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- 預覽圖片與影片 開始-------------------------------------------------------------------------------------------------------------------------------->
            <el-row>
              <el-col>
                <el-form-item :label="$t('Preview pictures')">
                  <div v-for="(item ,index) in previewImageListTemp" :key="index" class="avatar">
                    <img :src="item.url" width="145" height="145" style="object-fit: cover;">
                    <a href="javascript:void(0)" @click="previewImageHandleRemove(item.name)"><i class="el-icon-delete" /></a>
                    <a href="javascript:void(0)" @click="previewImageHandleReplace(item.name)"><i class="el-icon-edit" /></a>
                  </div>
                  <el-upload
                    action="#"
                    :http-request="uploadPreviewImage"
                    :accept="acceptImageType"
                    multiple
                    list-type="picture-card"
                    :before-upload="(file) => beforeFileUpload(file, 'IMAGE')"
                  >
                    <i class="el-icon-plus" />
                  </el-upload>
                  <!-- 這個是給edit button使用的，UI上面不可見，目的是要保留原來的ID，替換檔案用，避免多語言翻譯失敗 -->
                  <el-upload
                    v-show="false"
                    action="#"
                    :http-request="replacePreviewImage"
                    :accept="acceptImageType"
                    multiple
                    list-type="picture-card"
                    class="replacePreviewImageClass"
                    :before-upload="(file) => beforeFileUpload(file, 'IMAGE')"
                  />
                </el-form-item>
                <br><br>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <el-form-item :label="$t('Preview videos')">
                  <div
                    v-for="(item ,index) in previewVideoListTemp"
                    :key="index"
                    class="avatar"
                  >
                    <video :src="item.url" class="avatar" controls="controls">
                      您的浏览器不支持视频播放
                    </video>
                    <a href="javascript:void(0)" @click="previewVideoHandleRemove(item.name)"><i class="el-icon-delete" /></a>
                    <a href="javascript:void(0)" @click="previewVideoHandleReplace(item.name)"><i class="el-icon-edit" /></a>
                  </div>
                  <el-upload
                    action="#"
                    :http-request="uploadPreviewVideo"
                    :accept="acceptVideoType"
                    multiple
                    list-type="picture-card"
                    :before-upload="(file) => beforeFileUpload(file, 'VIDEO')"
                  >
                    <i class="el-icon-plus" />
                  </el-upload>

                  <!-- 這個是給edit button使用的，UI上面不可見，目的是要保留原來的ID，替換檔案用，避免多語言翻譯失敗 -->
                  <el-upload
                    v-show="false"
                    action="#"
                    :http-request="replacePreviewVideo"
                    :accept="acceptVideoType"
                    multiple
                    list-type="picture-card"
                    class="replacePreviewVideoClass"
                    :before-upload="(file) => beforeFileUpload(file, 'VIDEO')"
                  />

                </el-form-item>
              </el-col>
            </el-row>
            <!-- 預覽圖片與影片   結束-------------------------------------------------------------------------------------------------------------------------------->
          </el-card>
        </el-col>

        <!-- 付費後可以看見的圖片與影片    開始-------------------------------------------------------------------------------------------------------------------->
        <el-col v-if="temp.goodsType === 'VIRTUAL_GOODS'" :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">
                {{ $t("Pictures and videos displayed after purchase") }}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style="color:red; font-size: 12px; font-weight:normal;">{{ buyFileError }}</span>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <el-form-item :label="$t('Pictures after purchase')" prop="buyVideoOrImg">

                  <div v-for="(item ,index) in buyImageListTemp" :key="index" class="avatar">
                    <img :src="item.url" width="145" height="145" style="object-fit: cover;">
                    <a href="javascript:void(0)" @click="buyImageHandleRemove(item.name)"><i class="el-icon-delete" /></a>
                    <a href="javascript:void(0)" @click="buyImageHandleReplace(item.name)"><i class="el-icon-edit" /></a>
                  </div>
                  <el-upload
                    action="#"
                    :http-request="uploadBuyImage"
                    :accept="acceptImageType"
                    multiple
                    list-type="picture-card"
                    :before-upload="(file) => beforeFileUpload(file, 'IMAGE')"
                  >
                    <i class="el-icon-plus" />
                  </el-upload>
                  <!-- 這個是給edit button使用的，UI上面不可見，目的是要保留原來的ID，替換檔案用，避免多語言翻譯失敗 -->
                  <el-upload
                    v-show="false"
                    action="#"
                    :http-request="replaceBuyImage"
                    :accept="acceptImageType"
                    multiple
                    list-type="picture-card"
                    class="replaceBuyImageClass"
                    :before-upload="(file) => beforeFileUpload(file, 'IMAGE')"
                  />

                </el-form-item>
                <br><br>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item :label="$t('Videos after purchase')" prop="buyVideoOrImg">
                  <div
                    v-for="(item ,index) in buyVideoListTemp"
                    :key="index"
                    class="avatar"
                  >
                    <video :src="item.url" class="avatar" controls="controls">
                      您的浏览器不支持视频播放
                    </video>
                    <a href="javascript:void(0)" @click="buyVideoHandleRemove(item.name)"><i class="el-icon-delete" /></a>
                    <a href="javascript:void(0)" @click="buyVideoHandleReplace(item.name)"><i class="el-icon-edit" /></a>
                  </div>
                  <el-upload
                    action="#"
                    :http-request="uploadBuyVideo"
                    :accept="acceptVideoType"
                    multiple
                    list-type="picture-card"
                    :before-upload="(file) => beforeFileUpload(file, 'VIDEO')"
                  >
                    <i class="el-icon-plus" />
                  </el-upload>

                  <!-- 這個是給edit button使用的，UI上面不可見，目的是要保留原來的ID，替換檔案用，避免多語言翻譯失敗 -->
                  <el-upload
                    v-show="false"
                    action="#"
                    :http-request="replaceBuyVideo"
                    :accept="acceptVideoType"
                    multiple
                    list-type="picture-card"
                    class="replaceBuyVideoClass"
                    :before-upload="(file) => beforeFileUpload(file, 'VIDEO')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <!-- 付費後可以看見的圖片與影片    結束-------------------------------------------------------------------------------------------------------------------->

        <el-col :xl="24">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :span="24" class="card-title">{{
                $t("productItem.commodity_structure_definition")
              }}</el-col>
            </el-row>
            <el-row :gutter="20" class="commodity-structure">
              <el-col :xs="24">
                <el-form-item :label="$t('productItem.shopStruct')">
                  <el-select
                    v-model="temp.shopStruct"
                    style="width:200px"
                    :placeholder="$t('COMPONENTS.inselect')"
                    @change="selGoodsModel"
                  >
                    <el-option
                      :label="$t('productItem.SIMPLE-ITEM')"
                      value="SIMPLE-ITEM"
                      :disabled="checkPermission('SHOP_PRODUCT_STRUCTURE.SIMPLE_ITEM')"
                    />
                    <el-option
                      :label="$t('productItem.VARIABLE-ITEM')"
                      value="VARIABLE-ITEM"
                      :disabled="checkPermission('SHOP_PRODUCT_STRUCTURE.VARIABLE_ITEM')"
                    />
                    <el-option
                      :label="$t('productItem.Match-ITEM')"
                      value="MATCH-ITEM"
                      :disabled="checkPermission('SHOP_PRODUCT_STRUCTURE.MIX_MARTCH_ITEM')"
                    />
                  </el-select>

                  <!--
                  <el-radio-group v-if="temp.shopStruct === 'SIMPLE-ITEM'" v-model="temp.goodsType" :disabled="temp.goodsType==='FICTITIOUS'?true:false">
                    <el-radio label="FICTITIOUS">虚有产品</el-radio>
                    <el-radio label="SUBSTANCE">实体产品</el-radio>
                  </el-radio-group>
 -->
                </el-form-item>

                <el-form-item v-if="temp.shopStruct === 'SIMPLE-ITEM'" :label="$t('Goods type')">
                  <el-select
                    v-model="temp.goodsType"
                    style="width:500px"
                    :placeholder="$t('COMPONENTS.inselect')"
                  >
                    <el-option
                      :label="$t('Virtual goods - (Unlock pictures or videos after purchase)')"
                      value="VIRTUAL_GOODS"
                    />
                    <!-- 虛擬產品-購買後，解鎖圖片或影片 -->

                    <el-option
                      :label="$t('Virtual goods - (Go to the e-Training system to take classes after purchase)')"
                      value="VIRTUAL_ETRAINING_GOODS"
                      :disabled="true"
                    />
                    <!-- 虛擬產品-購買後前往e-Training系統上課 -->

                    <el-option
                      :label="$t('Physical goods - (Shipped to the customer after purchase)')"
                      value="PHYSICAL_GOODS"
                    />
                    <!-- 實體商品-購買後寄送給顧客 -->
                  </el-select>
                </el-form-item>

                <el-form-item v-if="temp.shopStruct === 'SIMPLE-ITEM'" :label="$t('Purchase Limit')"> <!-- 購買限制 -->
                  <el-select
                    v-model="temp.purchaseLimit"
                    style="width:500px"
                    :placeholder="$t('COMPONENTS.inselect')"
                    de
                  >
                    <el-option
                      :label="$t('Unlimited, can be purchased separately')"
                      value="NA"
                    />
                    <!-- 不限制，可以單獨購買 -->

                    <el-option
                      :label="$t('Can only be purchased through the plan')"
                      value="PLAN_ONLY"
                    />
                    <!-- 只能透過計畫購買 -->

                    <el-option
                      :label="$t('No purchase required, view only')"
                      value="VIEW_ONLY"
                    />
                    <!-- 無需購買，僅供查看 -->

                  </el-select>
                </el-form-item>

              </el-col>
              <el-col v-if="temp.purchaseLimit !== 'PLAN_ONLY' && temp.purchaseLimit !== 'VIEW_ONLY'" :xs="24">
                <simple-goods-tabs
                  v-if="sretailPrice || sretailPrice === 0 || (sisShow && pageType === 'CREATE')"
                  v-show="sisShow"
                  ref="simpleGoodsTabs"
                  :sretail-price="sretailPrice"
                  :spromotion-price="spromotionPrice"
                  :spromotion-type="spromotionType"
                  :spromotion-value="spromotionValue"
                  :sku-num="skuNum"
                  :goods-id="temp.id"
                  :sku-items="skuItems"
                  :shop-struct="temp.shopStruct"
                />
                <variable-goods-tabs
                  v-if="vretailPrice || (visShow && pageType === 'CREATE')"
                  v-show="visShow"
                  ref="variableGoodsTabs"
                  :vretail-price="vretailPrice"
                  :vpromotion-price="vpromotionPrice"
                  :vpromotion-type="vpromotionType"
                  :vpromotion-value="vpromotionValue"
                  :vgoods-id="vgoodsId"
                  :vsku-data="vskuData"
                  :vshop-struct="vshopStruct"
                />
                <match-goods-tabs
                  v-if=" misShow&&isRead"
                  v-show="misShow"
                  ref="matchGoodsTabs"
                  :mretail-price="mretailPrice"
                  :mpromotion-price="mpromotionPrice"
                  :msku-num="mskuNum"
                  :mgoods-id="mgoodsId"
                  :mshop-struct="mshopStruct"
                  :mproduct-list-by-data="mproductListByData"
                  :option-list="itemList"
                />
              </el-col>
            </el-row>
          </el-card>
        </el-col>

      </el-row>

    </el-form>

    <el-drawer
      class="translate-drawer"
      :title="$t('COMPONENTS.translate')"
      :visible.sync="tDrawer"
      size="60%"
      :show-close="false"
      :modal-append-to-body="false"
      :append-to-body="false"
      destroy-on-close
      @opened="getIframe"
    >
      <iframe
        v-if="tDrawer"
        ref="iframe"
        :src="ifreamSrc"
        class="iframe-class"
        :height="getClientHeight()"
      />
    </el-drawer>
  </div>
</template>

<script>
import { findAllRamrkTpl, findAllRemarkTplList, findById } from '@/api/remakTplApi'
import { save, getProductInfo, searchList, productUpdatedMediaLog, publicProduct } from '@/api/productItem'
import { API_CFS_SERVER_URL } from '@/Config'
import { getStaticSourcePath } from '@/utils/serverPathUtil'
import { imgUploadToOrderService } from '@/api/uploadApi'
import { getPageParm } from '@/utils/page-common'
// import { quillEditor } from 'vue-quill-editor'
import { getLanguage } from '@/lang/index'
import { getCfsTreeList, saveNewTreeNode } from '@/api/cfsApi/cfsTreeLIstApi'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { fetchLanguageList, findLanguageListByKey } from '@/api/languageApi'
import { checkCfsPermission } from '@/utils/permission'
import { API_SERVER_URL } from '@/Config.js'
import Sticky from '@/components/Sticky'
import SimpleGoodsTabs from './simpleGoodsTabs'
import VariableGoodsTabs from './variableGoodsTabs'
import MatchGoodsTabs from './matchGoodsTabs'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { findAllIngredient } from '@/api/ingredientApi'
import ElSelectTree from 'el-select-tree'
import { findStorageConfig } from '@/api/mallorderApi/systemConfigApi'
import { getFileType, guid, parseStrToInt } from '@/utils'
import { getLan } from '@/utils/productMenuFunction'
import Summernote from '@/components/Summernote'
import _ from 'lodash'

export default {
  name: 'ProductForm',
  components: {
    Sticky,
    ElSelectTree,
    // quillEditor,
    SimpleGoodsTabs,
    VariableGoodsTabs,
    MatchGoodsTabs,
    Summernote
  },
  props: {
    title: {
      type: String,
      default: 'Product Form',
      required: true
    }
  },
  data() {
    var validateShop = (rule, value, callback) => {
      if (value === '' && this.validateShopNum >= 2) {
        callback(new Error(this.$t('rule.validateShopNum')))
      } else {
        this.validateShopNum++
        callback()
      }
    }

    var checkBuyVideoOrImg = (rule, value, callback) => {
      console.log('checkBuyVideoOrImg', value)

      // 20230601暫時移除
      // 有的時候產品只想要一張圖片
      // if (this.temp.goodsType === 'VIRTUAL_GOODS') {
      //   const dataSize = this.buyVideoListTemp.length + this.buyImageListTemp.length
      //   if (dataSize === 0) {
      //     this.buyFileError = this.$t('Please upload at least one file')
      //     return callback('   ')
      //   }
      // }

      this.buyFileError = ''
      return callback()
    }
    // var validateTag = (rule, value, callback) => {
    //   if (value === '' && this.validateTagNum >= 1) {
    //     callback(new Error(this.$t('rule.validateTagNum')))
    //   } else {
    //     this.validateTagNum++
    //     callback()
    //   }
    // }
    // var validateBrand = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error(this.$t('rule.validateBrandNum')))
    //   } else {
    //     this.validateBrandNum++
    //     callback()
    //   }
    // }

    const checkInfos = (rule, value, callback) => {
      // value抓不到..之後可以再研究
      // 先從this抓
      const infos = this.temp.infos
      if (infos) {
        infos.map((item) => {
          const infoValue = item.infoValue
          const infoKey = item.infoKey
          if (infoKey === 'PRODUCT_INFO_SERVINGS') {
            if (infoValue) {
              const isNumber = parseFloat(infoValue).toString() !== 'NaN'
              if (!isNumber) {
                const error = this.$t('Servings needs to be integer') // 份量需要是整數
                return callback(new Error(error))
              }
              if (infoValue < 1) {
                const error = this.$t('Servings needs greater than 0') // 份量需要大於0
                return callback(new Error(error))
              }
              if (infoValue % 1 !== 0) {
                const error = this.$t('Servings needs to be integer') // 份量需要是整數
                return callback(new Error(error))
              }
            } else {
              const error = this.$t('rule.PRODUCT_INFO_SERVINGS') // 份量为必填
              return callback(new Error(error))
            }
          }
        })
      }
      return callback()
    }
    return {
      acceptVideoType: '.mp4,.AVI,.mpg,.wmv,.mov',
      acceptImageType: '.jpg,.jpeg,.png,.gif',
      rules: {

        adminTag: [
          {
            required: false,
            message: this.$t('rule.productName'),
            trigger: 'change'
          }
        ],
        adminRemark: [
          {
            required: false,
            message: this.$t('rule.productName'),
            trigger: 'change'
          }
        ],

        name: [
          {
            required: true,
            message: this.$t('rule.productName'),
            trigger: 'blur'
          }
        ],
        category: [
          {
            required: true,
            message: this.$t('rule.category'),
            trigger: 'change'
          }
        ],
        PRODUCT_INFO_SERVINGS: [
          { required: true, validator: checkInfos, trigger: 'change' }
        ],

        shop: [{ required: true, validator: validateShop, trigger: 'change' }],
        mainImgUrl: [
          {
            required: true,
            message: this.$t('rule.mainImgUrl'),
            trigger: 'change'
          }
        ],

        buyVideoOrImg: [
          { required: false, validator: checkBuyVideoOrImg, trigger: 'change' }
        ]

      },
      hideOption: {
        modules: {
          // 删除全部的工具栏
          toolbar: []
        }
        // 其它的配置
      },

      buyFileError: '',
      ingredientDataSelectId: [],
      ingredientDataSelectObj: [],
      ingredientUom: [],
      ingredientType: [],
      ingredientData: [
        // {
        //   label: "香菜",
        //   key: 0,
        //   ingredientId: "香菜的ID",
        // },
      ],
      remarkTplList: [],
      IngredientList: [],
      componentKey: 0,
      remarkTypecomponentKey: 0,
      saveData: {},
      treeDialogVisible: false,
      tDrawer: false,
      ifreamSrc: '',
      imageUrl: '',
      storeFileList: [],
      cfsLanguageData: [],
      checkList: [],
      checked: true,
      productNameLabel: 'productItem.productName', // 商品名稱
      menuNameLabel: 'Product Maintenance', // 商品維護
      productIntroductionLabel: 'Product Introduction', // 商品介紹
      productIdLabel: 'Recipe ID', // 食譜ID
      languageTableBaseData: [],
      languageTableIntroductionData: [],
      languageTableMainImgData: [],
      languageTableStorePicData: [],
      languageTableVideoData: [],
      languageTableStructureData: [],
      translateList: [],
      languageCode: '',
      src: '',
      translateDrawer: false,
      iframe: '',
      itemList: [],
      shopStr: '',
      validateShopNum: 0,
      validateTagNum: 0,
      validateBrandNum: 0,
      props: {
        multiple: true,
        checkStrictly: true
        // label: 'title',
        // value: 'itemId'
      },
      treeProps: {
        multiple: false,
        checkStrictly: true
        // label: 'title',
        // value: 'value'
      },
      mainImgUrl: '',
      mainBannerUrl: [],
      previewImageListTemp: [],
      buyImageListTemp: [],
      previewVideoListTemp: [],
      buyVideoListTemp: [],
      fileReplaceTempName: null, // 替換檔案時，需要保留原始ID
      sisShow: true,
      visShow: false,
      misShow: false,
      isRead: false,
      skuItems: [],
      sretailPrice: '',
      spromotionPrice: '',
      spromotionType: 'DISCOUNT',
      spromotionValue: '',
      skuNum: '',
      sruleForm: '',
      vretailPrice: '',
      vpromotionPrice: '',
      vpromotionType: 'DISCOUNT',
      vpromotionValue: '',
      vskuData: [],
      mretailPrice: '',
      mpromotionPrice: '',
      mskuNum: '',
      goodsId: '',
      vgoodsId: '',
      mgoodsId: '',
      // shopStruct: '',
      vshopStruct: '',
      mshopStruct: '',
      mproductListByData: [],
      videoUploadPercent: '',
      treeIsTagType: '',
      fileList: [],
      temp: {
        publicVersion: null,
        productVersionHistory: [],
        queryVersion: null,
        baseProductId: '',
        id: null,
        cssPhoto: 'COVER',
        adminTag: '',
        adminRemark: '',
        name: '',
        remarkTmpId: '',
        categoryOptions: [],
        category: '',
        tagOptions: [],
        tag: [],
        label: '',
        shopOptions: [{ label: '' }],
        shop: '',
        shopAll: '',
        hot: false,
        visible: true,
        status: true,
        shopRadio: 'false',
        accessoryEntityId: '',
        bannerGroupUrl: [],
        brandGoodsImgName: '',
        childImgNames: [],
        videoGame: '',
        dialogVisible: false,
        shopStruct: 'SIMPLE-ITEM',
        lastModifiedDate: '',
        mainBannerGroupUrl: [],
        itemsBannerGroupUrl: [],
        videoBannerGroupUrl: [],
        mainImgUrl: '',
        brand: '',
        brandList: [],
        class: [],
        classList: [],
        languageCode: '',
        purchaseLimit: 'PLAN_ONLY',
        goodsType: 'VIRTUAL_GOODS',
        itemStatusList: [
          // { value: 'NEW', lable: 'NEW' },
          // { value: 'DRAFT', lable: 'DRAFT' },
          // { value: 'SUBMIT', lable: 'SUBMIT' },
          // { value: 'APPROVED', lable: 'APPROVED' },
          // { value: 'REJECT', lable: 'REJECT' }
          // ////////////////////////////////////////////////// 以上數據改從CFS的domainValue取得
        ],
        itemStatus: 'NEW', // 預設NEW
        infos: [
          // { infoType: 'INFO', infoKey: 'PRODUCT_INFO_SERVINGS', infoValue: null, seqNo: 0 },
          // { infoType: 'INFO', infoKey: 'PRODUCT_INFO_CALORIES', infoValue: null, seqNo: 0 },
          // { infoType: 'INFO', infoKey: 'PRODUCT_INFO_PREP', infoValue: null, seqNo: 0 },
          // { infoType: 'INFO', infoKey: 'PRODUCT_INFO_COOK', infoValue: null, seqNo: 0 }
        ]

      },
      query: {
        size: 10000000,
        page: 0,
        shop: ''
      },
      activeName: 'first',
      remarkReply: {
        id: null,
        useageTypeCode: 'PRODUCT',
        remarkSectionList: []
      },
      editableTabsValue: '0',
      ingredientTypeQueue: [],
      fileImageSize: null,
      fileVideoSize: null,
      storageType: 'DISK',

      allowDefaultCategory: false,
      allowDefaultCategoryIds: [],
      functionSetting: null,

      langList: [],
      pageType: 'CREATE'
    }
  },

  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  computed: {

  },
  mounted() {
    this.checkRouterSetting()
    this.loadData(null)
    // var thisObject = this
    window.addEventListener('message', this.handleMessage)
    // this.iframeWin = this.$refs.iframe.contentWindow;
    this.forceRerender()
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {
    changeCategory(value, index) {
      console.log('changeCategory>>>>', value, index)
      this.temp.category = value
    },
    removeCatrhory(value, index) {
      console.log('remove changeCategory>>>>', value, index)
    },
    generateKey(index) {
      return 'summernote_' + index
    },
    changeRemarkItemValue(value, index, remarkIndex) {
      _.set(this.remarkReply, `remarkSectionList[${index}].remarkItemList[${remarkIndex}].itemValue`, value)
    },
    checkRouterSetting() {
      // 先給預設Label
      this.menuNameLabel = this.$t('Product Maintenance') // 商品維護
      this.productIntroductionLabel = this.$t('Product Introduction') // 商品介紹

      // 路由有category參數時執行此操作
      if (this.$parent) {
        if (this.$parent.$route) {
          if (this.$parent.$route.meta) {
            if (this.$parent.$route.meta.functionSetting) {
              const functionSetting = this.$parent.$route.meta.functionSetting
              console.log('adfgadfgaredher 把路由配置的設定，一起抓過來放到functionSetting', functionSetting)
              this.functionSetting = functionSetting

              // 一些label的替換
              this.menuNameLabel = getLan(functionSetting.menuName)

              this.productIdLabel = getLan(functionSetting.label_1)

              this.productNameLabel = getLan(functionSetting.label_2)

              this.productIntroductionLabel = getLan(functionSetting.label_3)

              this.pageType = this.$parent.$route.meta.type

              // category設定
              if (functionSetting.categorys) {
                this.allowDefaultCategory = true
                this.allowDefaultCategoryIds = [functionSetting.categorys]
              }
            }
          }
        }
      }
    },
    onChangeVersion(version) {
      this.loadData(version)
    },
    async onChangeRemarkTpl(value) {
      let allRemarkSectionList = []
      this.temp.remarkTmpId = value
      if (this.remarkReplyBak && (this.remarkReplyBak.remarkTmpId === value || !value)) {
        this.remarkReply = { ...this.remarkReplyBak }
        if (!value) {
          this.remarkReply.remarkSectionList = this.remarkReplyBak.remarkSectionList.map(i => {
            i.remarkTplSectionId = undefined; i.sectionName = i.sectionNameBak ? i.sectionNameBak : i.sectionName
            const remarkItemList = [...i.remarkItemList]
            i.remarkItemList = remarkItemList.map(j => { j.itemValue = i.contentBak ? i.contentBak : j.itemValue; return j })
            return i
          })
        }
        allRemarkSectionList = [...this.remarkReplyBak.remarkSectionList]
      } else {
        let selectedRemark = { tabs: [] }
        const selectedRes = await findById(value)
        if (selectedRes && selectedRes.data) {
          selectedRemark = selectedRes.data
        }
        // const selected = this.remarkTplList.filter(i => { if (i.id === value) return i })
        // const selectedRemark = selected[0] || { tabs: [] }
        let remarkSectionList = this.generateNewSectionlist([...selectedRemark.tabs])
        remarkSectionList = this.checkTameIsExist([...remarkSectionList], [...this.remarkReplyBak.remarkSectionList])
        this.remarkReply.allowIngredient = selectedRemark.allowIngredient
        this.remarkReply.remarkSectionList = remarkSectionList
        allRemarkSectionList = [...remarkSectionList]
      }
      this.remarkReply.remarkTmpId = value
      if (allRemarkSectionList && allRemarkSectionList.length > 0) {
        const templateTabs = allRemarkSectionList.filter(i => { return !!i.remarkTplSectionId }).sort((a, b) => { return a.seqNo - b.seqNo })
        this.remarkReply.tempIndex = templateTabs.length - 1
      }
      // version controll
      if (this.remarkReply.remarkSectionList) {
        this.remarkReply.remarkSectionList.forEach(i => {
          if (!i.newId) {
            i.newId = guid()
          }
          if (i.remarkItemList) {
            i.remarkItemList.forEach(l => {
              if (!l.newId) {
                l.newId = guid()
              }
            })
          }
        })
      }
      const langList = this.langList.filter(res => res.key.indexOf('.product.ingredient.productDescription') < 0)
      this.langList = langList
      console.log('model change===', langList)
    },
    checkNeedQty(uomId) {
      // 當UOM是以下這幾個
      // 則不需要顯示QTY
      let result = false
      console.log('checkNeedQty', uomId)
      const allowList = []
      allowList.push('fa7089533b244175812be40c8bbc04a7') // 適量
      allowList.push('229265ed5e0f4a7caad18b13ce2a64f7') // 少許
      allowList.push('ce86d212e664402f91edf3e995a7ab1a') // 足夠
      allowList.push('8a8082b8882019bc01882d15d57b03a3') // 大約

      result = !allowList.includes(uomId)
      return result
    },
    onChangeIngredientDescription(ingredientId, value) {
      this.ingredientDataSelectObj.map((item) => {
        if (item.ingredientId === ingredientId) {
          item.productDescription = value
        }
      })
      this.ingredientData.map((item) => {
        if (item.ingredientId === ingredientId) {
          item.productDescription = value
        }
      })

      this.forceRerender()
    },
    onChangePrepareVideo(ingredientId, value) {
      console.log('onChangePrepareVideo ', ingredientId, value)

      this.ingredientDataSelectObj.map((item) => {
        if (item.ingredientId === ingredientId) {
          item.prepareMediaId = value
        }
      })
      this.ingredientData.map((item) => {
        if (item.ingredientId === ingredientId) {
          item.prepareMediaId = value
        }
      })
      // this.forceRerender()
      this.forceRemarkType()
    },
    onChangeQty(ingredientId, qty) {
      console.log('onChangeQTY ', ingredientId, qty)

      if (isNaN(qty) || qty === '') {
        qty = null
      }

      this.ingredientDataSelectObj.map((item) => {
        if (item.ingredientId === ingredientId) {
          item.qty = qty
        }
      })
      this.ingredientData.map((item) => {
        if (item.ingredientId === ingredientId) {
          item.qty = qty
        }
      })
      this.forceRerender()
    },
    onChangeUomId(ingredientId, uomId) {
      console.log('onChangeUOM ', ingredientId, uomId)
      let isModerate = false

      // 如果是"適量"
      // QTY為NULL
      // 檢查是否需要QTY
      if (!this.checkNeedQty(uomId)) {
        isModerate = true
      }

      this.ingredientDataSelectObj.map((item) => {
        if (item.ingredientId === ingredientId) {
          item.uomId = uomId
          if (isModerate) {
            item.qty = null
          }
        }
      })
      this.ingredientData.map((item) => {
        if (item.key === ingredientId) {
          item.uomId = uomId
          item.showQty = true
          if (isModerate) {
            item.qty = null
            item.showQty = false
          }
        }
      })
    },
    onChangeCssPhoto(key) {
      this.temp.cssPhoto = key
    },
    onChangeIngredientType(ingredientId, selectTeeeId) {
      console.log('onChangeIngredientType2222222 ', ingredientId, selectTeeeId)
      if (selectTeeeId) {
        this.ingredientDataSelectObj.map((item) => {
          if (item.ingredientId === ingredientId) {
            item.ingredientProductTreeId = selectTeeeId
          }
        })
      }
    },
    selectIngredient(allSelectId, direction, movedKeys) {
      const isAdd = direction === 'right'
      if (isAdd) {
        movedKeys.map((moveId) => {
          let ingredientProductTreeId = null

          // 找預設的種類
          let desc = null
          this.ingredientData.map(item => {
            if (item.ingredientId === moveId) {
              ingredientProductTreeId = item.ingredientProductTreeId
              desc = item.productDescription
            }
          })

          const genId = guid()
          this.ingredientDataSelectObj.push({
            newProductIngredientEntityId: genId,
            productIngredientEntityId: genId,
            productDescription: desc,
            uomId: 'fa7089533b244175812be40c8bbc04a7', // 預設值適量
            ingredientId: moveId,
            qty: null,
            ingredientProductTreeId: ingredientProductTreeId
          })
        })
      } else {
        this.ingredientDataSelectObj = this.ingredientDataSelectObj.filter(obj => !movedKeys.includes(obj.ingredientId))
      }
      this.ingredientDataSelectId = allSelectId
    },
    forceRerender() {
      // 用途:重新渲染
      // (實際測試好像在這個UI裡面沒甚麼用....)
      this.componentKey += 1
      this.remarkTypecomponentKey += this.componentKey + Math.random()
    },
    forceRemarkType() {
      // 用途:重新渲染
      // (實際測試好像在這個UI裡面沒甚麼用....)
      this.remarkTypecomponentKey = Math.random()
    },

    // 左
    moveSectionForward(index) {
      var remarkSectionList = this.remarkReply.remarkSectionList
      var currentSection = JSON.parse(JSON.stringify(remarkSectionList[index]))
      var frontSection = JSON.parse(JSON.stringify(remarkSectionList[index - 1]))
      frontSection.seqNo = frontSection.seqNo + 1
      remarkSectionList[index] = frontSection
      currentSection.seqNo = currentSection.seqNo - 1
      remarkSectionList[index - 1] = currentSection
      this.remarkReply.remarkSectionList = remarkSectionList.map((item, index) => { item.seqNo = index + 1; return item })
      this.editableTabsValue = String(index - 1)
      this.forceRerender()
    },
    // 右
    moveSectionBack(index) {
      var remarkSectionList = this.remarkReply.remarkSectionList
      var currentSection = JSON.parse(JSON.stringify(remarkSectionList[index]))
      var behindSection = JSON.parse(JSON.stringify(remarkSectionList[index + 1]))
      behindSection.seqNo = behindSection.seqNo - 1
      remarkSectionList[index] = behindSection
      currentSection.seqNo = currentSection.seqNo + 1
      remarkSectionList[index + 1] = currentSection
      this.remarkReply.remarkSectionList = remarkSectionList.map((item, index) => { item.seqNo = index + 1; return item })
      this.editableTabsValue = String(index + 1)
      this.forceRerender()
    },

    // 下
    moveItemBack(proIndex, index) {
      var remarkSectionList = this.remarkReply.remarkSectionList
      var currentSection = JSON.parse(JSON.stringify(remarkSectionList[proIndex].remarkItemList[index]))
      var behindSection = JSON.parse(JSON.stringify(remarkSectionList[proIndex].remarkItemList[index + 1]))
      behindSection.seqNo = behindSection.seqNo - 1
      currentSection.seqNo = currentSection.seqNo + 1
      remarkSectionList[proIndex].remarkItemList[index] = behindSection
      remarkSectionList[proIndex].remarkItemList[index + 1] = currentSection
      this.remarkReply.remarkSectionList = remarkSectionList
      this.forceRerender()
    },
    // 上
    moveItemForward(proIndex, index) {
      var remarkSectionList = this.remarkReply.remarkSectionList
      var currentItem = JSON.parse(JSON.stringify(remarkSectionList[proIndex].remarkItemList[index]))
      var frontItem = JSON.parse(JSON.stringify(remarkSectionList[proIndex].remarkItemList[index - 1]))
      frontItem.seqNo = frontItem.seqNo + 1
      currentItem.seqNo = currentItem.seqNo - 1
      remarkSectionList[proIndex].remarkItemList[index] = frontItem
      remarkSectionList[proIndex].remarkItemList[index - 1] = currentItem
      this.remarkReply.remarkSectionList = remarkSectionList
      this.forceRerender()
    },

    removeRemarkItemHeld(proIndex, index) {
      var remarkSectionList = this.remarkReply.remarkSectionList
      remarkSectionList[proIndex].remarkItemList.splice(index, 1)
      this.remarkReply.remarkSectionList = remarkSectionList
      this.forceRerender()
    },
    addRemarkItemHeld(index) {
      var remarkSectionList = this.remarkReply.remarkSectionList
      const seqNo = remarkSectionList[index].remarkItemList ? remarkSectionList[index].remarkItemList.length : 0

      const remarkItem = {
        id: null,
        itemName: '',
        itemValue: '',
        seqNo: seqNo,
        status: 'A',
        versionStamp: 0
      }

      const smlSectionItem = JSON.parse(JSON.stringify(remarkItem))
      if ((remarkSectionList[index].remarkItemList) === null || !(remarkSectionList[index].remarkItemList)) {
        smlSectionItem.seqNo = 1
        remarkSectionList[index].remarkItemList = []
        remarkSectionList[index].remarkItemList.push(smlSectionItem)
      } else {
        smlSectionItem.seqNo = remarkSectionList[index].remarkItemList.length + 1
        remarkSectionList[index].remarkItemList.push(smlSectionItem)
      }
      this.remarkReply.remarkSectionList = remarkSectionList
      this.forceRerender()
    },

    handleClick(tab, event) {
      console.log('handleClick', tab, event)
    },
    tabClickEvent(targetName, action) {
      console.log('tabClickEvent', targetName, action)
    },
    handleTabsEdit(targetIndex, action) {
      console.log('handleTabsEdit', targetIndex, action)
      if (action === 'add') {
        const seqNo = this.remarkReply.remarkSectionList.length
        const remarkItem = {
          id: null,
          itemName: '',
          itemValue: '',
          seqNo: 0,
          status: 'A',
          versionStamp: 0
        }
        const newRemark = {
          id: null,
          sectionName: 'New section',
          seqNo: seqNo + 1,
          commonRemarkId: null,
          needPay: false,
          useageTypeCode: 'PRODUCT',
          remarkItemList: [remarkItem]
        }

        this.remarkReply.remarkSectionList.push(newRemark)

        this.editableTabsValue = String(this.remarkReply.remarkSectionList.length - 1)
      } else if (action === 'remove') {
        let remarkSectionList = this.remarkReply.remarkSectionList

        // if (remarkSectionList.length === 1) {
        //   const error = this.$t('Deletion is not allowed, please keep at least one tag')
        //   alert(error)
        //   return
        // }

        if (remarkSectionList[targetIndex] && remarkSectionList[targetIndex].remarkTplSectionId) {
          const error = this.$t('Deletion from templates tabs is not allowed')
          alert(error)
          return
        }
        remarkSectionList = remarkSectionList.filter((data, index) => index !== parseInt(targetIndex)).map((i, index) => { i.seqNo = index + 1; return i }).sort((a, b) => { return a.seqNo - b.seqNo })
        this.remarkReply.remarkSectionList = remarkSectionList

        // 刪除後
        // 找出上一個Tab
        let activeIndex = parseInt(targetIndex) - 1
        if (activeIndex < 0) {
          activeIndex = 0
        }
        this.editableTabsValue = String(activeIndex)
      }
      this.forceRerender()
    },

    getCheckedNodes(leafOnly) {
      console.log('select node ===>', leafOnly)
    },
    visibleChange(visible, refName, onclick, type) {
      if (visible) {
        const ref = this.$refs[refName]
        let popper = ref.$refs.popper
        if (popper.$el) popper = popper.$el
        if (!Array.from(popper.children).some(v => v.className === 'el-cascader-menu__list')) {
          const el = document.createElement('ul')
          el.className = 'el-cascader-menu__list'
          el.style = 'border-top: solid 1px #E4E7ED; padding:0; color: #606266;'
          el.innerHTML = `<li class="el-cascader-node" style="height:38px;line-height: 38px">
      <i class="el-icon-plus"></i>
      <span class="el-cascader-node__label">` + this.$t('COMPONENTS.add') + `</span>
      <i class="el-cascader-node__postfix"/>
      </li>`
          popper.appendChild(el)
          el.onclick = () => {
          // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
            onclick && onclick(type)

            // 以下代码实现点击后弹层隐藏 不需要可以删掉
            if (ref.toggleDropDownVisible) {
              ref.toggleDropDownVisible(false)
            } else {
              ref.visible = false
            }
          }
        }
      }
    },
    visibleChange2(visible, refName, onclick, type) {
      if (visible) {
        const ref = this.$refs[refName]
        let popper = ref.$refs.popper
        if (popper.$el) popper = popper.$el
        if (!Array.from(popper.children).some(v => v.className === 'el-cascader-menu__list')) {
          const el = document.createElement('ul')
          el.className = 'el-cascader-menu__list'
          el.style = 'border-top: solid 1px #E4E7ED; padding:0; color: #606266;'
          el.innerHTML = `<li class="el-cascader-node" style="height:38px;line-height: 38px">
      <i class="el-icon-plus"></i>
      <span class="el-cascader-node__label">` + this.$t('COMPONENTS.add') + `</span>
      <i class="el-cascader-node__postfix"/>
      </li>`
          popper.appendChild(el)
          el.onclick = () => {
          // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
            onclick && onclick(type)

            // 以下代码实现点击后弹层隐藏 不需要可以删掉
            if (ref.toggleDropDownVisible) {
              ref.toggleDropDownVisible(false)
            } else {
              ref.visible = false
            }
          }
        }
      }
    },
    submitNewTreeNode() {
      var treeUseType = this.treeUseType
      var data = this.saveData
      if (!data || !data.name) {
        this.$message(this.$t('tree.treeNode.tip'))
        return
      }
      var saveData = {
        parentId: '',
        name: data.name,
        usageTypeCode: treeUseType,
        belongId: 'DEFAULT'
      }
      if (data && data.parentId && data.parentId.length > 0) {
        saveData.parentId = data.parentId[data.parentId.length - 1]
      }
      console.log('select data=====>', data)
      console.log('treeUseType====>', treeUseType)
      console.log('saveData====>', saveData)
      saveNewTreeNode(saveData).then(res => {
        this.saveData = {}
        this.treeDialogVisible = false
        // this.loadData()
        this.getNewTagTree(treeUseType)
      })
    },
    getNewTagTree(treeUseType) {
      var query = {
        belongId: 'DEFAULT',
        usageTypeCode: treeUseType
      }
      getCfsTreeList(query).then(res => {
        console.log('new tree==>', res)
        var data = res.data
        if (data && treeUseType === 'tag') {
          var tagListByCfs = []
          data && data.map(item => {
            tagListByCfs.push({
              value: item.treeItemTag.id,
              label: item.treeItemTag.name
            })
          })
          this.temp.tagOptions = tagListByCfs
        }
        if (data && treeUseType === 'category') {
          var categoryListByCfs = []
          categoryListByCfs = this.handTreeStructure(data, categoryListByCfs)
          if (categoryListByCfs && categoryListByCfs.length > 0) {
            categoryListByCfs = this.disabledCategoryItem(categoryListByCfs)

            console.log('sdfasdfasdv', categoryListByCfs)

            this.temp.categoryOptions = this.convertTree(categoryListByCfs)
          }
        }
      })
    },
    handCancelAddTree() {
      this.saveData = {}
      this.treeDialogVisible = false
    },
    addTreeNode(type) {
      console.log('tree type ====>', type)
      var treeIsTagType = ''
      if (type === 'tag') {
        treeIsTagType = 'display:none;'
      }
      this.treeUseType = type
      this.treeIsTagType = treeIsTagType
      this.treeDialogVisible = true
    },
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    handleMessage(e) {
      console.log(e.data, e, API_CFS_SERVER_URL)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleDrawerClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleDrawerClose()
      } else if (e.data && e.data.res) {
        setTimeout(() => {
          this.iframeUpdateVideoAddLog(e.data.res)
        }, 1000)
      } else if (!!e.data && !!e.data.verctlRes) {
        this.langList = e.data.verctlRes
        console.log('lang list res===', this.langList)
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleDrawerClose() {
      this.tDrawer = false
    },
    iframeUpdateVideoAddLog(objData) {
      try {
        var vid = this.temp.id
        var vname = this.temp.name
        var adminTag = this.temp.adminTag
        let jsonData = null
        if (objData) {
          jsonData = JSON.stringify(objData)
        }
        const val = { refId: vid, refName: vname, refAdminTag: adminTag, jsonData: jsonData }
        console.log('iframe add log', val)
        productUpdatedMediaLog(val).then(res => {
          console.log('add log success?', res.data)
        })
      } catch (error) {
        console.error(error)
      }
    },
    getIframe(e) {
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      var languageCode = this.temp.languageCode
      if (!languageCode) {
        languageCode = getLanguage()
      }
      console.log('language=======', this.temp.languageCode, getLanguage())
      this.ifreamSrc =
        ifreamSrc +
        '?system=gallery&module=admin&function=productItem&isIframe=true' +
        '&language=' + languageCode +
        '&sytemLanguage=' + getLanguage() +
        '&verctl=1' +
        '&no=' + Math.ceil(Math.random() * 100)

      // languageCode = en-US
      // sytemLanguage = zh-HK
      const fmtData = this.processMutilLangData()
      const data = { data: fmtData, langList: this.langList }
      console.log('data--', data)
      console.log('iframe 对象', this.$refs.iframe)

      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
      console.log('iframe 对象', this.$refs.iframe)
    },
    processMutilLangData() {
      const imgPreviewUrl = API_SERVER_URL + '/static'
      // const videoPreviewUrl = this.storageType === 'GOOGLE' ? API_SERVER_URL + '/static/preview/download' : imgPreviewUrl
      const videoPreviewUrl = API_SERVER_URL + '/static/preview/download'
      const uploadUrl = API_SERVER_URL + '/public/api/file/upload'
      const levelOfSalesPermission = !this.checkPermission('SHOP_PRODUCT_LEVEL_OF_SALES')
      var data = []
      const productIds = this.productIds
      data.push({
        name: this.$t(this.productNameLabel),
        key: productIds + '.mul.productItem.productName',
        newKey: this.newProductId + '.mul.productItem.productName',
        type: 'input', value: this.temp.name
      })
      if (levelOfSalesPermission) {
        data.push({
          name: this.$t('productItem.label'),
          key: productIds + '.mul.productItem.label',
          newKey: this.newProductId + '.mul.productItem.label',
          type: 'input', value: this.temp.label
        })
      }
      // 封面圖片
      const newAccessoryEntityId = this.temp.newAccessoryEntityId
      data.push({
        name: this.$t('productItem.mainImg'),
        key: this.temp.accessoryEntityId + '.mul.productItem.mainImg',
        newKey: newAccessoryEntityId + '.mul.productItem.mainImg',
        type: 'uploadTwo',
        value: {
          downloadUrl: imgPreviewUrl,
          imgName: this.temp.mainImgUrl,
          materialType: 'jpg',
          maxCount: 1,
          url: uploadUrl,
          accept: this.acceptImageType,
          fileAllowType: 'IMAGE',
          fileAllowTypeErrMsg: this.$t('file.formatErrMsg'),
          fileSize: this.fileImageSize,
          fileSizeErrMsg: this.$t('file.sizeLimitMsg')
        }})
      // 翻譯圖片(免費)
      this.previewImageListTemp && this.previewImageListTemp.map(item => {
        var imgItem = {
          name: this.$t('Preview pictures'),
          key: item.entity.id + '.mul.productItem.videoAndImgStore',
          newKey: item.entity.newId + '.mul.productItem.videoAndImgStore',
          type: 'uploadTwo',
          value: {
            downloadUrl: imgPreviewUrl,
            imgName: item.name,
            materialType: 'jpg',
            maxCount: 1,
            url: uploadUrl,
            accept: this.acceptImageType,
            fileAllowType: 'IMAGE',
            fileAllowTypeErrMsg: this.$t('file.formatErrMsg'),
            fileSize: this.fileImageSize,
            fileSizeErrMsg: this.$t('file.sizeLimitMsg')
          }
        }
        data.push(imgItem)
      })
      // 翻譯影片(免費)
      this.previewVideoListTemp && this.previewVideoListTemp.map(item => {
        var videoItem = {
          name: this.$t('Preview videos'),
          key: item.entity.id + '.mul.productItem.videoAndImgStore',
          newKey: item.entity.newId + '.mul.productItem.videoAndImgStore',
          type: 'uploadTwo',
          value: {
            downloadUrl: videoPreviewUrl,
            imgName: item.name,
            materialType: 'jpg',
            maxCount: 1,
            url: uploadUrl,
            accept: this.acceptVideoType,
            fileAllowType: 'VIDEO',
            fileAllowTypeErrMsg: this.$t('file.formatErrMsg'),
            fileSize: this.fileVideoSize,
            fileSizeErrMsg: this.$t('file.sizeLimitMsg'),
            formField: 'previewVideo'
          }
        }
        data.push(videoItem)
      })
      // 翻譯圖片(付費)
      this.buyImageListTemp && this.buyImageListTemp.map(item => {
        var imgItem = {
          name: this.$t('Pictures after purchase'),
          key: item.entity.id + '.mul.productItem.videoAndImgStore',
          newKey: item.entity.newId + '.mul.productItem.videoAndImgStore',
          type: 'uploadTwo',
          value: {
            downloadUrl: imgPreviewUrl,
            imgName: item.name,
            materialType: 'jpg',
            maxCount: 1,
            url: uploadUrl,
            accept: this.acceptImageType,
            fileAllowType: 'IMAGE',
            fileAllowTypeErrMsg: this.$t('file.formatErrMsg'),
            fileSize: this.fileImageSize,
            fileSizeErrMsg: this.$t('file.sizeLimitMsg')
          }
        }
        data.push(imgItem)
      })
      // 翻譯影片(付費)
      this.buyVideoListTemp && this.buyVideoListTemp.map(item => {
        var videoItem = {
          name: this.$t('Videos after purchase'),
          key: item.entity.id + '.mul.productItem.videoAndImgStore',
          newKey: item.entity.newId + '.mul.productItem.videoAndImgStore',
          type: 'uploadTwo',
          value: {
            downloadUrl: videoPreviewUrl,
            imgName: item.name,
            materialType: 'jpg',
            maxCount: 1,
            url: uploadUrl,
            accept: this.acceptVideoType,
            fileAllowType: 'VIDEO',
            fileAllowTypeErrMsg: this.$t('file.formatErrMsg'),
            fileSize: this.fileVideoSize,
            fileSizeErrMsg: this.$t('file.sizeLimitMsg'),
            formField: 'buyVideo'
          }
        }
        data.push(videoItem)
      })
      const infos = this.temp.infos
      if (infos) {
        infos.map((item) => {
          const infoValue = item.infoValue
          const infoKey = item.infoKey

          if (infoKey !== 'PRODUCT_INFO_SERVINGS') {
            const id = item.id
            const name = this.$t(infoKey)
            data.push({
              name: name,
              key: id + '.edu.product.info.infoValue',
              newKey: item.newId + '.edu.product.info.infoValue',
              type: 'input',
              value: infoValue
            })
          }
        })
      }
      const remarkSectionList = this.remarkReply.remarkSectionList
      if (remarkSectionList) {
        // remarkSectionList.filter((i)=>{(i.remarkTplSectionId ? true : false) }).map((item, tabIndex) => {
        remarkSectionList.map((item, tabIndex) => {
          tabIndex++
          if (!item.remarkTplSectionId) {
            data.push({
              name: this.$t('Tab name') + '(' + tabIndex + ')',
              key: item.id + '.edu.product.remark.section',
              newKey: item.newId + '.edu.product.remark.section',
              type: 'input',
              value: item.sectionName
            })
          }
          item.remarkItemList && item.remarkItemList.map((it, itemIndex) => {
            itemIndex++
            // 20230525 移除，這個欄位先暫時移除
            // if (it.itemName) {
            //   const name = this.$t('Content header') + '(' + tabIndex + '-' + itemIndex + ')'
            //   data.push({ name: name, key: it.id + '.edu.product.remark.section.itemName', type: 'input', value: it.itemName })
            // }
            if (!item.notAllowUpdate && (!item.optionType || item.optionType === 'SIMPLE')) {
              const name = this.$t('Content detail') + '(' + tabIndex + '-' + itemIndex + ')'
              data.push({
                name: name,
                key: it.id + '.edu.product.remark.section.itemValue',
                newKey: it.newId + '.edu.product.remark.section.itemValue',
                type: 'summernote',
                value: it.itemValue
              })
            }
          })
        })
      }
      if (this.ingredientDataSelectObj && this.ingredientDataSelectObj.length !== 0) {
        this.ingredientDataSelectObj.map((item) => {
          const newId = item.newProductIngredientEntityId
          const name = this.$t('Ingredient remark') + '(' + this.getIngredientLabel(item.ingredientId) + ')'
          data.push({
            name: name,
            key: item.productIngredientEntityId + '.product.ingredient.productDescription',
            newKey: newId + '.product.ingredient.productDescription',
            type: 'input',
            value: item.productDescription
          })
        })
      }
      return data
    },
    showTranslateDrawer() {
      if (this.width > 0) {
        return
      }
      this.tDrawer = true
    },
    getIngredientLabel(ingredientId) {
      let label = ''
      const data = this.ingredientData
      if (data && data.length !== 0) {
        data.map(item => {
          if (item.ingredientId === ingredientId) {
            label = item.label
          }
        })
      }
      return label
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getButton(e) { console.log('msg====>', e) },
    getStaticSourcePath,
    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////詢問
    confirmDeleteFileJob(job) {
      this.$confirm(
        this.$t('COMPONENTS.delete_file_ask'), // 此操作會刪除檔案，包含翻譯，請問仍然要繼續?
        this.$t('COMPONENTS.tip'),
        {
          confirmButtonText: this.$t('COMPONENTS.sure'),
          cancelButtonText: this.$t('COMPONENTS.cancel'),
          type: 'warning'
        }
      ).then(() => {
        job()
      })
    },
    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////檔案刪除前確認
    previewVideoHandleRemove(fileName) {
      this.confirmDeleteFileJob(() => {
        this.previewVideoListTemp = this.previewVideoListTemp.filter((t) => t.name !== fileName)
      })
    },
    previewImageHandleRemove(fileName) {
      this.confirmDeleteFileJob(() => {
        this.previewImageListTemp = this.previewImageListTemp.filter((t) => t.name !== fileName)
      })
    },
    buyVideoHandleRemove(fileName) {
      this.confirmDeleteFileJob(() => {
        this.buyVideoListTemp = this.buyVideoListTemp.filter((t) => t.name !== fileName)
      })
    },
    buyImageHandleRemove(fileName) {
      this.confirmDeleteFileJob(() => {
        this.buyImageListTemp = this.buyImageListTemp.filter((t) => t.name !== fileName)
      })
    },
    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    previewVideoHandleReplace(fileName) {
      this.fileReplaceTempName = fileName // 傳值，等等上傳方法裡面會調用
      document.querySelector('.replacePreviewVideoClass .el-upload').click() // 模擬點下upload  (筆圖案的button)
    },
    previewImageHandleReplace(fileName) {
      this.fileReplaceTempName = fileName // 傳值，等等上傳方法裡面會調用
      document.querySelector('.replacePreviewImageClass .el-upload').click() // 模擬點下upload  (筆圖案的button)
    },
    buyVideoHandleReplace(fileName) {
      this.fileReplaceTempName = fileName // 傳值，等等上傳方法裡面會調用
      document.querySelector('.replaceBuyVideoClass .el-upload').click() // 模擬點下upload  (筆圖案的button)
    },
    buyImageHandleReplace(fileName) {
      this.fileReplaceTempName = fileName // 傳值，等等上傳方法裡面會調用
      document.querySelector('.replaceBuyImageClass .el-upload').click() // 模擬點下upload  (筆圖案的button)
    },

    beforeFileUpload(file, type) {
      const fileType = getFileType(file)
      if (fileType && fileType !== type) {
        this.$message.error(this.$t('file.formatErrMsg'))
        return false
      }
      const size = file.size
      let defaultSize = -1
      if (type === 'IMAGE') {
        defaultSize = this.fileImageSize
      } else if (type === 'VIDEO') {
        defaultSize = this.fileVideoSize
      }
      if (defaultSize && defaultSize > 0) {
        const filterSize = defaultSize * 1024
        if (size > filterSize) {
          const msg = this.$t('file.sizeLimitMsg') + ': ' + defaultSize + 'KB'
          this.$message.error(msg)
          return false
        }
      }
      return true
    },
    // ///////////////////////////////////////////////////////////////////////upload file
    uploadMainImages(file) {
      var newFile = file.file

      var formData = new FormData()
      formData.append('files', newFile)
      imgUploadToOrderService(formData).then((res) => {
        // res回傳
        // [
        //     {
        //         "apiUrl": "https://dev.twdc.tophenix.com/mallorder/static/",
        //         "url": "mp4/20230512105317888.mp4",
        //         "name": "20230512105317888.mp4",
        //         "type": "mp4"
        //     }
        // ]
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl
        this.mainBannerUrl = [{
          name: name,
          url: url
        }]
        this.mainImgUrl = absoluteUrl
        this.temp.mainImgUrl = url
        this.temp.accessoryEntityId = guid()
      })
    },
    getFormData(file) {
      const newFile = file.file
      const formData = new FormData()
      formData.append('files', newFile)
      return formData
    },
    uploadPreviewImage(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl
        const genId = guid()
        this.previewImageListTemp.push({
          name: name,
          url: absoluteUrl,
          urlPath: url,
          entity: { id: genId, newId: genId, urlPath: url }
        })
      })
    },
    uploadPreviewVideo(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl
        const genId = guid()
        this.previewVideoListTemp.push({
          name: name,
          url: absoluteUrl,
          urlPath: url,
          entity: { id: genId, newId: genId, urlPath: url }
        })
      })
    },
    uploadBuyImage(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl
        const genId = guid()
        this.buyImageListTemp.push({
          name: name,
          url: absoluteUrl,
          urlPath: url,
          entity: { id: genId, newId: genId, urlPath: url }
        })
      })
    },
    uploadBuyVideo(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl
        const genId = guid()
        this.buyVideoListTemp.push({
          name: name,
          url: absoluteUrl,
          urlPath: url,
          entity: { id: genId, newId: genId, urlPath: url }
        })
      })
    },

    // ///////////////////////////////////////////////////////////////////////replace file
    replacePreviewVideo(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl
        const newList = []
        this.previewVideoListTemp.map(item => {
          if (this.fileReplaceTempName === item.name) {
            // if (item.entity) { // case1原本就存在DB裡面，但是ID要保存，為了讓CFS翻譯關聯
            //   item.name = name
            //   item.urlPath = url
            //   item.url = absoluteUrl
            //   item.entity.urlPath = url
            //   newList.push(item)
            // } else { // case2沒有存到DB裡面，要找出file並取代
            //   newList.push({ name: name, url: absoluteUrl, urlPath: url })
            // }
            const genId = guid()
            newList.push({ name: name, url: absoluteUrl, urlPath: url, entity: { id: genId, newId: genId, urlPath: url }})
          } else {
            newList.push(item)
          }
        })
        this.previewVideoListTemp = newList
        this.fileReplaceTempName = null
      })
    },

    replacePreviewImage(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl

        const newList = []
        this.previewImageListTemp.map(item => {
          if (this.fileReplaceTempName === item.name) {
            // if (item.entity) { // case1原本就存在DB裡面，但是ID要保存，為了讓CFS翻譯關聯
            //   item.name = name
            //   item.urlPath = url
            //   item.url = absoluteUrl
            //   item.entity.urlPath = url
            //   newList.push(item)
            // } else { // case2沒有存到DB裡面，要找出file並取代
            //   newList.push({ name: name, url: absoluteUrl, urlPath: url })
            // }
            const genId = guid()
            newList.push({ name: name, url: absoluteUrl, urlPath: url, entity: { id: genId, newId: genId, urlPath: url }})
          } else {
            newList.push(item)
          }
        })
        this.previewImageListTemp = newList
        this.fileReplaceTempName = null
      })
    },
    replaceBuyVideo(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl

        const newList = []
        this.buyVideoListTemp.map(item => {
          if (this.fileReplaceTempName === item.name) {
            // if (item.entity) { // case1原本就存在DB裡面，但是ID要保存，為了讓CFS翻譯關聯
            //   item.name = name
            //   item.urlPath = url
            //   item.url = absoluteUrl
            //   item.entity.urlPath = url
            //   newList.push(item)
            // } else { // case2沒有存到DB裡面，要找出file並取代
            //   newList.push({ name: name, url: absoluteUrl, urlPath: url })
            // }
            const genId = guid()
            newList.push({ name: name, url: absoluteUrl, urlPath: url, entity: { id: genId, newId: genId, urlPath: url }})
          } else {
            newList.push(item)
          }
        })
        this.buyVideoListTemp = newList
        this.fileReplaceTempName = null
      })
    },
    replaceBuyImage(file) {
      const formData = this.getFormData(file)
      imgUploadToOrderService(formData).then((res) => {
        const name = res[0].name
        const url = res[0].url
        // const apiUrl = res[0].apiUrl
        // const absoluteUrl = apiUrl + url
        const absoluteUrl = res[0].mediaUrl

        const newList = []
        this.buyImageListTemp.map(item => {
          if (this.fileReplaceTempName === item.name) {
            // if (item.entity) { // case1原本就存在DB裡面，但是ID要保存，為了讓CFS翻譯關聯
            //   item.name = name
            //   item.urlPath = url
            //   item.url = absoluteUrl
            //   item.entity.urlPath = url
            //   newList.push(item)
            // } else { // case2沒有存到DB裡面，要找出file並取代
            //   newList.push({ name: name, url: absoluteUrl, urlPath: url })
            // }
            const genId = guid()
            newList.push({ name: name, url: absoluteUrl, urlPath: url, entity: { id: genId, newId: genId, urlPath: url }})
          } else {
            newList.push(item)
          }
        })
        this.buyImageListTemp = newList
        this.fileReplaceTempName = null
      })
    },
    checkShopRadio() {
      if (this.temp.shopRadio === 'false') {
        var shopIds = this.temp.shopAll.split(',')
        this.temp.shop = shopIds
        this.checkShop()
      } else {
        this.temp.shop = []
      }
    },
    checkShop() {
      for (var i = 0; i < this.temp.shop.length; i++) {
        var shopId = this.temp.shop[i]
        this.query.shop = shopId
        var query = { ...this.query }
        var param = getPageParm(query)
        var array = []
        searchList(param, query).then((resp) => {
          var data = resp.data
          if (data !== null && data.length > 0) {
            for (var t = 0; t < data.length; t++) {
              var replay = data[t]
              array.push({
                id: replay.id,
                img: replay.imgUrl,
                name: replay.name
              })
            }
          }
          this.mproductListByData = array
          this.isRead = true
          console.log('this.mproductListByData', this.mproductListByData)
        })
      }
    },
    selGoodsModel() {
      if (this.temp.shopStruct === 'SIMPLE-ITEM') {
        this.sisShow = true
        this.visShow = false
        this.misShow = false
        this.isRead = false
      }
      if (this.temp.shopStruct === 'VARIABLE-ITEM') {
        this.sisShow = false
        this.visShow = true
        this.misShow = false
        this.isRead = false
      }
      if (this.temp.shopStruct === 'MATCH-ITEM') {
        this.sisShow = false
        this.visShow = false
        this.misShow = true
        this.isRead = true
      }
    },
    handleSuccess(res, file) {
      this.temp.accessoryEntityId = res.accessoryEntityId
    },
    onExceed(res, file) {},
    beforeUpload(file) {},
    saveUserImg() {},
    generateNewSectionlist(list) {
      return list.map(item => {
        return {
          id: null,
          sectionName: item.name,
          seqNo: item.sequence,
          commonRemarkId: null,
          needPay: item.paidUnlock,
          notAllowUpdate: item.notAllowUpdate,
          remarkTplSectionId: item.id,
          optionType: item.optionType,
          remarkItemList: [
            {
              id: null,
              itemName: '',
              seqNo: 1,
              remarkSectionId: null,
              itemValue: item.contentInfo || '<p>NA</p>'
            }
          ]
        }
      })
    },
    checkTameIsExist(defaultList, oldList) {
      let length = defaultList.length
      const oldListData = [...oldList]
      oldListData.forEach(i => {
        length += 1
        i.seqNo = length
      })
      const newList = defaultList.filter(i => {
        let isExist = false
        oldListData.forEach(j => {
          const itemVlaue = j.remarkItemList[0].itemValue
          if (i.sectionName === j.sectionName) {
            isExist = true
            j.seqNo = i.seqNo
            j.optionType = i.optionType
            j.notAllowUpdate = i.notAllowUpdate
            j.remarkTplSectionId = i.remarkTplSectionId
            j.contentBak = i.notAllowUpdate ? itemVlaue : ''
            j.remarkItemList = i.notAllowUpdate ? i.remarkItemList : j.remarkItemList
          }
        })
        return !isExist
      })
      const generateData = [...newList, ...oldListData]
      const result = generateData.sort((a, b) => { return a.seqNo - b.seqNo })
      return result
    },
    renderTree(treeData, ingredientTypeQueue) {
      const tree = []
      treeData.map((node, i) => {
        const id = node.id
        ingredientTypeQueue.push(id) // 順便記住tree的順序用，其他function會用
        const title = node.treeItemTag.name
        const children = node.children
        const hasChildren = node.children.length !== 0
        const date = {
          value: id,
          label: title
        }
        if (hasChildren) {
          date.children = this.renderTree(children, ingredientTypeQueue)
        }
        tree.push(date)
      })
      return tree
    },
    loadData(productVersion) {
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours()
      const mf =
        new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()
      const ss =
        new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()

      var baseProductId = this.$route.query.baseProductId

      // this.temp.id = null
      this.mainImgUrl = ''
      this.mainBannerUrl = []
      this.previewImageListTemp = []
      this.buyImageListTemp = []
      this.previewVideoListTemp = []
      this.buyVideoListTemp = []
      this.remarkReply = {
        id: null,
        useageTypeCode: 'PRODUCT',
        remarkSectionList: []
      }
      this.ingredientDataSelectId = []
      this.ingredientDataSelectObj = []
      this.ingredientData = []

      console.log('baseProductId:', baseProductId)

      if (this.temp.id !== undefined) {
        this.temp.lastModifiedDate =
          yy + '/' + mm + '/' + dd + ' ' + hh + ':' + mf + ':' + ss
      }

      var permissionList = []

      // var queryTagParam = {
      //   belongId: 'DEFAULT',
      //   usageTypeCode: 'tag'
      // }
      // permissionList.push(getCfsTreeList(queryTagParam))

      // var queryCategoryParam = {
      //   belongId: 'DEFAULT',
      //   usageTypeCode: 'category'
      // }
      // permissionList.push(getCfsTreeList(queryCategoryParam))

      var parmas = { baseProductId: baseProductId, productVersion: productVersion }
      permissionList.push(getProductInfo(parmas))

      // const ingredientQuery = { status: 'A', size: 99999, page: 1 }
      // var ingredientParam = getPageParm(ingredientQuery)
      // permissionList.push(findIngredient(ingredientParam, ingredientQuery))
      permissionList.push(findAllIngredient())

      permissionList.push(getCfsDomainData({ domainCode: 'UOM' }))

      permissionList.push(getCfsDomainData({ domainCode: 'SHOP_PRODUCT_STATUS' }))

      // permissionList.push(getCfsDomainData({ domainCode: 'INGREDIENT_TYPE' }))
      permissionList.push(getCfsTreeList({ belongId: 'DEFAULT', usageTypeCode: 'PRODUCT_INGREDIENT' }))

      permissionList.push(findAllRamrkTpl())
      const defaultQuery = { size: 10000000, page: 0, name: 'DEFAULT' }
      permissionList.push(findAllRemarkTplList(getPageParm(defaultQuery), defaultQuery))
      permissionList.push(findStorageConfig())
      Promise.all(permissionList).then(async(respon) => {
        console.log('all data ======>', respon)
        const allConfig = respon[7]
        if (allConfig) {
          console.log('config data ======>', respon[7])
          this.fileImageSize = parseStrToInt(allConfig['IMAGE'].itemValue)
          this.fileVideoSize = parseStrToInt(allConfig['VIDEO'].itemValue)
          this.storageType = parseStrToInt(allConfig['STORAGETYPE'].itemValue)
        }
        // ////////////////////////////////////////  拿食材
        const allIngredient = respon[1].data
        allIngredient.map((data, i) => {
          this.ingredientData.push({
            ingredientId: data.id,
            label: data.name,
            key: data.id,
            qty: null,
            uomId: 'fa7089533b244175812be40c8bbc04a7', // 預設值適量
            showQty: false,
            ingredientProductTreeId: data.defaultIngredientProductTreeId, // 先給食材預設的種類
            productDescription: null,
            videos: data.videos
          })
        })
        console.log('拿食材==>', this.ingredientData)

        // ////////////////////////////////////////   拿產品的所有狀態
        const allProductStatus = respon[3].data
        this.temp.itemStatusList = []
        allProductStatus.map((data, i) => {
          this.temp.itemStatusList.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')',
            disabled: checkCfsPermission('SHOP_PRODUCT_STATUS.' + data.domainDataCode)
          })
        })
        console.log('拿產品的狀態>', allProductStatus)

        // ////////////////////////////////////////   拿食材的單位UOM
        const allIngredientUom = respon[2].data
        allIngredientUom.map((data, i) => {
          this.ingredientUom.push({
            value: data.id,
            label: this.translateCfsValue(data)
          })
        })
        console.log('拿食材的單位UOM==>', allIngredientUom)

        // //////////////////////////////////////  拿食材的種類，
        // 每個產品可以自定義食材的種類，這裡取出預設值，用戶自行去修改
        // const allIngredientType = respon[4].data
        // allIngredientType.map((data, i) => {
        //   this.ingredientType.push({
        //     value: data.id,
        //     label: this.translateCfsValue(data)
        //   })
        // })

        const allIngredientType = respon[4].data

        const ingredientTypeQueue = [] // 用來記住這些tree順序

        this.ingredientType = this.renderTree(allIngredientType, ingredientTypeQueue)

        console.log('拿食材的種類==>', allIngredientType)

        console.log('拿食材tree的順序==>', ingredientTypeQueue)

        var resp = respon[0]
        this.productIds = resp.data.id
        this.newProductId = guid() // version control
        var data = resp.data
        console.log('aaaaaa==>', data)

        var languagecode = data.language
        // var categoryList = data.categoryList

        if (resp.data.itemList) {
          this.itemList = resp.data.itemList
        }

        // 處理remark type
        if (data.remarkReply) {
          this.remarkReply = { ...data.remarkReply }
          this.remarkReplyBak = { ...data.remarkReply, isSave: true }
          if (data.remarkReply.remarkTmpId) {
            this.temp.remarkTmpId = data.remarkReply.remarkTmpId
          }
          if (data.remarkReply.remarkSectionList) {
            const templateTabs = data.remarkReply.remarkSectionList.filter(i => { return !!i.remarkTplSectionId }).sort((a, b) => { return a.seqNo - b.seqNo })
            this.remarkReply.tempIndex = templateTabs.length - 1

            // version control
            data.remarkReply.remarkSectionList.forEach(item => {
              item.newId = guid()
              item.remarkItemList && item.remarkItemList.forEach((it) => {
                it.newId = guid()
              })
            })
          }
        }

        const allRemarkTpl = respon[5].data || []
        this.remarkTplList = allRemarkTpl.map(item => {
          return { value: item.id, label: item.name, ...item }
        })

        // const defaultRemarkTpl = respon[6].data
        // 暂时不要影响旧产品
        // if ((!vid || !data.remarkReply.remarkTmpId) && defaultRemarkTpl && defaultRemarkTpl.length > 0) {
        // if (!vid && defaultRemarkTpl && defaultRemarkTpl.length > 0) {
        if (!baseProductId) {
          // this.temp.remarkTmpId = defaultRemarkTpl[0].id
          // const sectionList = defaultRemarkTpl[0].tabs || []
          // this.remarkReply.allowIngredient = defaultRemarkTpl[0].allowIngredient
          // console.log('defaultRemarkTpl',defaultRemarkTpl)
          // let remarkSectionList = this.generateNewSectionlist(sectionList)
          // let oldList = []
          // if(data.remarkReply && data.remarkReply.remarkSectionList) oldList = [...data.remarkReply.remarkSectionList]
          // if (!data.remarkReply || (data.remarkReply && !data.remarkReply.remarkTmpId)) remarkSectionList = this.checkTameIsExist([...remarkSectionList], [...oldList])

          // this.remarkReply.remarkSectionList = remarkSectionList
          // this.remarkReply.remarkTmpId = defaultRemarkTpl[0].id
          // this.remarkReplyBak = { remarkTmpId: defaultRemarkTpl[0].id, remarkSectionList: remarkSectionList, isSave: false }
          this.remarkReplyBak = { remarkSectionList: [], isSave: false }
        }

        // 處理食材
        if (resp.data.ingredientList) {
          const ingredientList = resp.data.ingredientList

          ingredientList.map((item) => {
            console.log('fgsdfghsdfgsdgrf ingredientList', item)

            const ingredientId = item.id
            const productDescription = item.productDescription
            const productIngredientEntityId = item.productIngredientEntityId

            let ingredientProductTreeId = item.ingredientProductTreeId
            const isExist = ingredientTypeQueue.includes(ingredientProductTreeId)
            if (!isExist) {
              ingredientProductTreeId = null
              console.log('發現不存在的ingredientProductTreeId', ingredientProductTreeId)
            }

            // ingredientDataSelectObj是拿來SAVE用的數據

            const obj = {
              newProductIngredientEntityId: guid(), // version control
              productIngredientEntityId: productIngredientEntityId,
              uomId: item.uomId,
              ingredientId: ingredientId,
              qty: item.qty,
              ingredientProductTreeId: ingredientProductTreeId,
              productDescription: productDescription
            }
            if (item.prepareVideo && item.prepareVideo.id) {
              obj.prepareMediaId = item.prepareVideo.id
            }

            this.ingredientDataSelectObj.push(obj)

            // ingredientData是所有的食材
            // 但是UOM跟QTY的書據都依賴這個Array
            // Set進去得以顯示出來
            this.ingredientData.map((item2) => {
              if (item2.key === ingredientId) {
                item2.qty = item.qty
                item2.uomId = item.uomId
                item2.showQty = this.checkNeedQty(item.uomId)
                item2.ingredientProductTreeId = ingredientProductTreeId
                item2.productDescription = productDescription
                if (item.prepareVideo && item.prepareVideo.id) {
                  item2.prepareMediaId = item.prepareVideo.id
                }
              }
            })
          })

          // ingredientDataSelectId是拿來給EL組件知道現在那些東西要放在右邊
          // 先準備好順序，等等放到 ingredientDataSelectId 裡面
          // 食材的順序要跟tree一樣的順序 - start
          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          const ingredientQueueList = []
          // 開始排序
          ingredientTypeQueue.map((treeId) => {
            ingredientList.map((item) => {
              const ingredientProductTreeId = item.ingredientProductTreeId
              if (ingredientProductTreeId === treeId) {
                ingredientQueueList.push(item)
              }
            })
          })

          // 萬一tree突然被刪了，也要處理遺留的食材放進來
          ingredientList.map((item) => {
            const ingredientProductTreeId = item.ingredientProductTreeId
            if (!ingredientTypeQueue.includes(ingredientProductTreeId)) {
              ingredientQueueList.push(item)
            }
          })

          this.ingredientTypeQueue = ingredientTypeQueue
          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // 食材的順序要跟tree一樣的順序 - end

          // 準備好順序了，開始放到ingredientDataSelectId
          ingredientQueueList.map((item) => {
            const ingredientId = item.id
            this.ingredientDataSelectId.push(ingredientId)
          })

          this.forceRerender()
        }

        // this.temp.categoryOptions = categoryList
        var categoryListByCfs = []
        if (data.categoryListByCfs) {
          categoryListByCfs = this.handTreeStructure(data.categoryListByCfs, categoryListByCfs)

          console.log('sdfasdfasdv 1', categoryListByCfs)

          categoryListByCfs = this.disabledCategoryItem(categoryListByCfs)

          console.log('sdfasdfasdv 2', categoryListByCfs)
        }
        if (categoryListByCfs && categoryListByCfs.length > 0) {
          this.temp.categoryOptions = this.convertTree(categoryListByCfs)
        }
        var tagListByCfs = []
        if (data.tagListByCfs) {
          data.tagListByCfs && data.tagListByCfs.map(item => {
            tagListByCfs.push({
              value: item.treeItemTag.id,
              label: item.treeItemTag.name
            })
          })
        }
        this.temp.tagOptions = tagListByCfs

        const infos = data.infos
        console.log('infos....', infos)
        // version control
        infos.forEach((item) => {
          item.newId = guid()
        })
        this.temp.infos = infos

        var brandList = data.brandList
        var resultData2 = []
        if (brandList !== null) {
          for (var i = 0; i < brandList.length; i++) {
            var obj = brandList[i]
            resultData2.push({
              value: obj.id,
              label: obj.name
            })
          }
        }
        this.temp.brandList = resultData2

        var shopList = data.shopList
        var resultData3 = []
        if (shopList !== null) {
          var shopStr = ''
          for (i = 0; i < shopList.length; i++) {
            var d = shopList[i]
            if (i === 0) {
              shopStr = d.id
            } else {
              shopStr += ',' + d.id
            }
            resultData3.push({
              value: d.id,
              label: d.name
            })
          }
          this.temp.shopAll = shopStr
        }
        this.temp.shopOptions = resultData3

        var classList = data.classList
        var resultData4 = []
        if (classList !== null) {
          for (i = 0; i < classList.length; i++) {
            d = classList[i]
            resultData4.push({
              value: d[0],
              label: d[1]
            })
          }
        }
        this.temp.classList = resultData4
        this.temp.newAccessoryEntityId = guid() // version control
        if (baseProductId) {
          console.log('shopList1', data.shopList)
          console.log('shopStr1', this.shopStr)
          console.log('this.temp.shopStruct', this.temp.shopStruct)
          console.log('this.temp.baseProductId', baseProductId)
          console.log(
            '-------------------------------------------',
            data.shopStruct
          )
          this.temp.shopStruct = data.shopStruct
          // ----------组合商品用到----------------//
          if (this.temp.shopStruct === 'MATCH-ITEM') {
            var array = []
            this.temp.shop.push(this.temp.shopOptions[0].value)
            var shopId = data.shopId
            this.query.shop = shopId
            var query = { ...this.query }
            var param = getPageParm(query)
            searchList(param, query).then((resp) => {
              var data = resp.data
              if (data !== null && data.length > 0) {
                for (var t = 0; t < data.length; t++) {
                  var replay = data[t]
                  array.push({
                    id: replay.id,
                    img: replay.imgUrl,
                    name: replay.name
                  })
                }
              }
              this.mproductListByData = array
              this.isRead = true
              console.log('this.mproductListByData', this.mproductListByData)
            })
          }
          // ----------组合商品用到----------------//

          this.temp.name = data.name
          this.temp.accessoryEntityId = data.accessoryEntityId
          this.temp.shopStruct = data.shopStruct
          this.temp.label = data.label
          this.temp.brand = data.brandId
          this.temp.class = data.classId
          this.temp.languageCode = languagecode

          this.temp.purchaseLimit = data.purchaseLimit
          this.temp.cssPhoto = data.cssPhoto

          // 版本管理
          this.temp.id = data.id
          this.temp.queryVersion = data.queryVersion
          this.temp.baseProductId = data.baseProductId
          this.temp.productVersionHistory = data.productVersionHistory

          // var language = ''
          fetchLanguageList().then(response => {
            if (response) {
              var res = response.data
              res && res.supportLanguageList && res.supportLanguageList.forEach(element => {
                if (languagecode === element.language) {
                  this.temp.language = element.title
                }
                element.value = element.language
                element.label = element.title
                this.cfsLanguageData = res.supportLanguageList
              })
            }
          })
          if (this.temp.shopStruct === 'SIMPLE-ITEM') {
            this.temp.goodsType = data.goodsType
            this.sretailPrice = data.retailPrice
            this.spromotionPrice = data.promotionPrice
            this.spromotionType = data.promotionType
            this.spromotionValue = data.promotionValue
            this.skuNum = data.skuNum
            this.sisShow = true
            this.visShow = false
            this.misShow = false
          }

          if (this.temp.shopStruct === 'VARIABLE-ITEM') {
            this.vretailPrice = data.retailPrice
            this.vpromotionPrice = data.promotionPrice
            this.vpromotionType = data.promotionType
            this.vpromotionValue = data.promotionValue
            this.vgoodsId = data.id
            this.vshopStruct = 'VARIABLE-ITEM'

            this.sisShow = false
            this.visShow = true
            this.misShow = false
          }
          if (this.temp.shopStruct === 'MATCH-ITEM') {
            this.mretailPrice = data.retailPrice
            this.mpromotionPrice = data.promotionPrice
            this.mgoodsId = data.id
            this.mshopStruct = 'MATCH-ITEM'
            this.mskuNum = data.skuNum

            this.sisShow = false
            this.visShow = false
            this.misShow = true
          }

          this.temp.itemStatus = data.itemStatus
          this.temp.hot = data.hot
          this.temp.visible = data.visible
          this.temp.lastModifiedDate = data.lastModifiedDate
          this.temp.adminTag = data.adminTag
          this.temp.adminRemark = data.adminRemark
          this.temp.publicVersion = data.publicVersion

          // this.temp.itemContentInfo = data.itemContentInfo   //20220912..MAC移除

          if (data.categorys !== null) {
            // 似乎沒有必要去檢查CFS那邊有沒有刪除的檔案，應該改到後台先處理好再回傳
            // var newCategoryList = data.categorys
            // let categoryData = respon[1].data
            // newCategoryList = this.filterCategoryByDeleted(newCategoryList, categoryData)
            // this.temp.category = newCategoryList

            this.temp.category = data.categorys
          }

          if (data.tagId !== null) {
            // 似乎沒有必要去檢查CFS那邊有沒有刪除的檔案，應該改到後台先處理好再回傳
            // var tagIds = data.tagId.split(',')
            // var newTags = tagIds
            // if (respon[0] && respon[0].data) {
            //   newTags = this.filterTagByDeleted(tagIds, respon[0].data)
            // }
            // this.temp.tag = newTags
            var tagIds = data.tagId.split(',')
            this.temp.tag = tagIds
          }

          console.log('data.shopId', data.shopId)

          if (data.shopId) {
            this.temp.shop = data.shopId

            for (i = 0; i < data.shopList.length; i++) {
              d = data.shopList[i]

              if (d.id === data.shopId) {
                this.shopStr = d.name
              }
            }
          }

          if (this.temp.shopRadio === 'false') {
            var shopIds = this.temp.shopAll.split(',')
            this.temp.shop = shopIds
          }

          if (data.img != null) {
            this.mainBannerUrl = [{
              name: data.imgName,
              url: data.img
            }]

            this.mainImgUrl = data.mediaUrl
            this.temp.mainImgUrl = data.img
          }

          if (data.previewImageList !== null && data.previewImageList.length > 0) {
            for (i = 0; i < data.previewImageList.length; i++) {
              // const url = data.previewImageList[i].apiUrl + data.previewImageList[i].urlPath
              // version control
              const d = data.previewImageList[i]
              d.newId = guid()

              const url = data.previewImageList[i].mediaUrl
              const fileName = data.previewImageList[i].urlPath
              this.previewImageListTemp.push({
                name: fileName,
                url: url,
                entity: d
              })
            }
          }
          // console.log('this.previewImageListTemp', this.previewImageListTemp)

          if (data.previewVideoList !== null && data.previewVideoList.length > 0) {
            for (i = 0; i < data.previewVideoList.length; i++) {
              // const url = data.previewVideoList[i].apiUrl + data.previewVideoList[i].urlPath
              // version control
              const d = data.previewVideoList[i]
              d.newId = guid()

              const url = data.previewVideoList[i].mediaUrl
              const fileName = data.previewVideoList[i].urlPath
              this.previewVideoListTemp.push({
                name: fileName,
                url: url,
                entity: d
              })
            }
          }
          // console.log('this.previewVideoListTemp', this.previewVideoListTemp)

          if (data.buyImageList !== null && data.buyImageList.length > 0) {
            for (i = 0; i < data.buyImageList.length; i++) {
              // const url = data.buyImageList[i].apiUrl + data.buyImageList[i].urlPath
              // version control
              const d = data.buyImageList[i]
              d.newId = guid()

              const url = data.buyImageList[i].mediaUrl
              const fileName = data.buyImageList[i].urlPath
              this.buyImageListTemp.push({
                name: fileName,
                url: url,
                entity: d
              })
            }
          }
          // console.log('this.buyImageListTemp', this.buyImageListTemp)

          if (data.buyVideoList !== null && data.buyVideoList.length > 0) {
            for (i = 0; i < data.buyVideoList.length; i++) {
              // const url = data.buyVideoList[i].apiUrl + data.buyVideoList[i].urlPath
              // version control
              const d = data.buyVideoList[i]
              d.newId = guid()

              const url = data.buyVideoList[i].mediaUrl
              const fileName = data.buyVideoList[i].urlPath
              this.buyVideoListTemp.push({
                name: fileName,
                url: url,
                entity: d
              })
            }
          }
          // console.log('this.buyVideoListTemp', this.buyVideoListTemp)

          console.log('temp data====>', this.temp)

          // handle cfs translation data
        }
        const langParams = this.processMutilLangData()
        const keyList = langParams.map(res => {
          return res.key
        })
        const langRes = await findLanguageListByKey({ queryKeyList: keyList })
        const langList = langRes.data || []
        langList.forEach(lang => {
          langParams.forEach(d => {
            if (d.key === lang.key) {
              lang.newKey = d.newKey
            }
          })
        })
        this.langList = langList
        console.log('lang List=======', langList)
      })
    },
    convertTree(tree) {
      return tree.map(node => ({
        value: node.itemId,
        label: node.title,
        children: this.convertTree(node.children)
      }))
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    // 改到後台去處理，回傳後會過濾CFS刪除後的資料
    // filterTagByDeleted(tags, tagListByCfs) {
    //   var newTags = []
    //   tags && tags.map(item => {
    //     tagListByCfs && tagListByCfs.map(it => {
    //       if (item === it.treeItemTag.id) {
    //         newTags.push(item)
    //       }
    //     })
    //   })
    //   return newTags
    // },

    // 改到後台去處理，回傳後會過濾CFS刪除後的資料
    // filterCategoryByDeleted(categorys, categoryListByCfs) {
    //   var newCategorys = []
    //   categorys && categorys.map(item => {
    //     categoryListByCfs && categoryListByCfs.map(it => {
    //       if (item[item.length - 1] === it.id) {
    //         newCategorys.push(item)
    //       }
    //     })
    //   })
    //   return newCategorys
    // },
    disabledCategoryItem(list, isParentDisabled) {
      list &&
        list.map((item) => {
          if (this.allowDefaultCategory) {
            if (isParentDisabled === undefined) {
              console.log('adfgadfgaredher', this.functionSetting)
              item.disabled = !this.functionSetting.categorys.includes(item.id)
            } else {
              item.disabled = isParentDisabled
            }

            if (item.children && item.children.length > 0) {
              this.disabledCategoryItem(item.children, item.disabled)
            }
          }
        })
      console.log('disabledCategoryItem list', list)
      return list
    },
    handTreeStructure(data, itemParentId) {
      data && data.map(item => {
        item.value = item.id
        item.key = item.id
        item.title = item.treeItemTag.name
        item.itemId = item.id
        item.itemParentId = itemParentId || ''
        if (item.children && item.children.length > 0) {
          this.handTreeStructure(item.children, item.id)
        }
      })
      return data
    },
    checkIngredientQtyData() {
      let result = true

      if (!this.isProdcutTypeAllowIngredient()) { // 如果產品本身不需要食材，那不用去檢查
        return true
      }

      const data = this.ingredientDataSelectObj
      if (data) {
        data.map(item => {
          console.log('aergcwrthv3rcwt', this.checkNeedQty(item.uomId))

          if (this.checkNeedQty(item.uomId)) {
            if (item.qty === null) {
              result = false
            }
          }
        })
      }
      return result
    },

    isProdcutTypeAllowIngredient() {
      let result = false

      const prodcutTypeId = this.temp.remarkTmpId
      const remarkTplList = this.remarkTplList
      if (prodcutTypeId && remarkTplList && remarkTplList.length !== 0) {
        remarkTplList.map(item => {
          if (item.id === prodcutTypeId) {
            if (item.allowIngredient) {
              result = true
            }
          }
        })
      }

      return result
    },
    checkIngredientCategoryData() { // 檢查食材Tree有沒有被刪除
      let result = true

      const vid = this.temp.id
      // 如果是create的時候，不用檢查Tree有沒有被刪除
      if (!vid) {
        return true
      }

      if (!this.isProdcutTypeAllowIngredient()) { // 如果產品本身不需要食材，那不用去檢查
        return true
      }

      const data = this.ingredientDataSelectObj
      const allTreeId = this.ingredientTypeQueue

      if (data && data.length !== 0) {
        if (allTreeId && allTreeId.length !== 0) {
          data.map(item => {
            const ingredientProductTreeId = item.ingredientProductTreeId
            if (!allTreeId.includes(ingredientProductTreeId)) {
              result = false
            }
          })
        } else {
          result = false
        }
      }

      return result
    },
    checkRemarkData() {
      let result = true
      if (this.remarkReply.remarkSectionList) {
        this.remarkReply.remarkSectionList.map(item => {
          const tabName = item.sectionName
          if (!tabName) {
            result = false
          }
        })
      }
      return result
    },
    back() {
      const functionSetting = this.functionSetting
      const pathObj = {
        path: '/goods/productItem'
      }
      if (functionSetting !== null) {
        pathObj.path = '/goods/' + functionSetting.id
      }
      this.$router.push(pathObj)
    },
    publicBtn(type) {
      const baseProductId = this.temp.baseProductId
      const version = this.temp.queryVersion
      publicProduct(baseProductId, version, type).then(resCode => {
        this.loadData(version)

        // PRODUCT_IS_NOT_APPROVED      //狀態是"批准"才能上架
        // PUBLIC_PRODUCT_SUCCESS       //上架成功
        // DISCONTINUE_PRODUCT_SUCCESS  //下架成功

        const msg = this.$t(resCode)
        alert(msg)
      }).catch(e => {
        console.error('publicProduct', e)
      })
    },
    submitBtn() {
      console.log('提交按鈕點下去了', this.ingredientDataSelectObj)

      const formName = 'temp'

      let goSave = true

      if (!this.checkRemarkData()) {
        const error = this.$t('Please enter the tab name')
        alert(error)
        return
      }

      if (!this.checkIngredientQtyData()) {
        const error = this.$t('Please enter the qty of ingredient')
        alert(error)
        return
      }

      if (!this.checkIngredientCategoryData()) {
        const error = this.$t('The Category of ingredients is a required field')
        alert(error)
        return
      }

      // 順序1 - 檢查上面的form
      this.$refs[formName].validate((valid, fields) => {
        // 坑
        // 如果validate方法沒有進來，代表callback()沒有寫進來
        if (!valid) goSave = valid
      })

      // 順序2 - 檢查下面的form
      if (this.temp.purchaseLimit === 'NA') {
        if (this.temp.shopStruct === 'SIMPLE-ITEM') {
          this.$refs.simpleGoodsTabs.$refs['simpleForm'].validate((valid, fields) => {
            if (!valid) goSave = valid
          })
        } else if (this.temp.shopStruct === 'VARIABLE-ITEM') {
          this.$refs.variableGoodsTabs.$refs['variableForm'].validate((valid, fields) => {
            if (!valid) goSave = valid
          })
        }
      }

      if (!goSave) {
        return
      }
      // 檢查完畢，開始SAVE

      console.log('this.temp.goodsType', this.temp.goodsType)
      // return

      // var vid = this.temp.id
      const vid = this.newProductId // version control
      var vname = this.temp.name
      var vcategory = this.temp.category
      var vtag = this.temp.tag
      var vlable = this.temp.label
      var vshop = this.temp.shopAll
      var vshopRadio = this.temp.shopRadio
      var vhot = this.temp.hot
      // var vvisible = this.temp.visible
      // var vstatus = this.temp.itemStatus
      // var vaccessoryEntityId = this.temp.accessoryEntityId
      var vaccessoryEntityId = this.temp.newAccessoryEntityId // version control
      // var vitemContentInfo = this.temp.itemContentInfo  //20220912..MAC移除
      var vshopAll = this.temp.shopAll
      var vshopStruct = this.temp.shopStruct
      var vbrand = this.temp.brand
      var goodsType = this.temp.goodsType
      var purchaseLimit = this.temp.purchaseLimit

      var vskuData
      var vretailPrice
      var vpromotionPrice
      var vskuNum
      var attributeList
      var skuItems

      var vpromotionType
      var vpromotionValue

      var numReg = /^\d+(\.\d+)?$/
      var numRe = new RegExp(numReg)

      if (this.temp.shopStruct === '') {
        this.temp.shopStruct = 'SIMPLE-ITEM'
      }

      var itemList = []

      if (purchaseLimit === 'NA') {
        if (this.temp.shopStruct === 'SIMPLE-ITEM') {
          vretailPrice = this.$refs.simpleGoodsTabs.simpleForm.retailPrice
          vpromotionPrice = this.$refs.simpleGoodsTabs.simpleForm
            .promotionPrice
          vskuNum = this.$refs.simpleGoodsTabs.simpleForm.kcNum
          vpromotionType = this.$refs.simpleGoodsTabs.simpleForm
            .promotionType
          vpromotionValue = this.$refs.simpleGoodsTabs.simpleForm
            .promotionValue

          if (
            vretailPrice === '' ||
            !numRe.test(vretailPrice)
          ) {
            return false
          }

          attributeList = this.$refs.simpleGoodsTabs.list
          skuItems = this.$refs.simpleGoodsTabs.skuItems
        }

        if (this.temp.shopStruct === 'VARIABLE-ITEM') {
          vretailPrice = this.$refs.variableGoodsTabs.variableForm
            .retailPrice
          vpromotionPrice = this.$refs.variableGoodsTabs.variableForm
            .promotionPrice
          vpromotionType = this.$refs.variableGoodsTabs.variableForm
            .promotionType
          vpromotionValue = this.$refs.variableGoodsTabs.variableForm
            .promotionValue

          if (vretailPrice === '' || !numRe.test(vretailPrice)) {
            return false
          }

          attributeList = this.$refs.variableGoodsTabs.list
          vskuData = this.$refs.variableGoodsTabs.skuData
          var skuData2 = this.$refs.variableGoodsTabs.skuData2
          vskuData = vskuData.concat(skuData2)
        }

        if (this.temp.shopStruct === 'MATCH-ITEM') {
          vretailPrice = this.$refs.matchGoodsTabs.mixForm.retailPrice
          vpromotionPrice = this.$refs.matchGoodsTabs.mixForm.promotionPrice
          vskuNum = this.$refs.matchGoodsTabs.mixForm.kcNum
          itemList = this.$refs.matchGoodsTabs.itemList
          attributeList = this.$refs.matchGoodsTabs.list
        }
      }
      const previewImageList = []
      this.previewImageListTemp && this.previewImageListTemp.map(item => {
        let imgReply = item.entity
        if (!imgReply) {
          imgReply = {
            id: guid(),
            urlPath: item.urlPath,
            apiUrl: null
          }
        } else {
          imgReply.id = item.entity.newId // version control
        }
        previewImageList.push(imgReply)
      })

      const buyImageList = []
      this.buyImageListTemp && this.buyImageListTemp.map(item => {
        let imgReply = item.entity
        if (!imgReply) {
          imgReply = {
            id: guid(),
            urlPath: item.urlPath,
            apiUrl: null
          }
        } else {
          imgReply.id = item.entity.newId // version control
        }
        buyImageList.push(imgReply)
      })

      const previewVideoList = []
      this.previewVideoListTemp && this.previewVideoListTemp.map(item => {
        let imgReply = item.entity
        if (!imgReply) {
          imgReply = {
            id: guid(),
            urlPath: item.urlPath,
            apiUrl: null
          }
        } else {
          imgReply.id = item.entity.newId // version control
        }
        previewVideoList.push(imgReply)
      })

      const buyVideoList = []
      this.buyVideoListTemp && this.buyVideoListTemp.map(item => {
        let imgReply = item.entity
        if (!imgReply) {
          imgReply = {
            id: guid(),
            urlPath: item.urlPath,
            apiUrl: null
          }
        } else {
          imgReply.id = item.entity.newId // version control
        }
        buyVideoList.push(imgReply)
      })

      // version control
      const infos = this.temp.infos || []
      infos.forEach(item => {
        item.id = item.newId
      })

      const isAdmin = !checkCfsPermission('SHOP_PRODUCT_STATUS.APPROVED')

      const newRemarkRely = this.remarkReply
      if (this.remarkReplyBak && this.remarkReplyBak.isSave)newRemarkRely.oldReamarkTmpId = this.remarkReplyBak.remarkTmpId

      // version control
      const remarkSectionList = newRemarkRely.remarkSectionList || []
      remarkSectionList.forEach(item => {
        item.id = item.newId
        const remarkItemList = item.remarkItemList || []
        remarkItemList.forEach(it => {
          it.id = it.newId
        })
      })

      // version control
      const ingredientDataSelectObj = this.ingredientDataSelectObj || []
      ingredientDataSelectObj.forEach(item => {
        item.productIngredientEntityId = item.newProductIngredientEntityId
      })

      // version control
      const langList = JSON.parse(JSON.stringify(this.langList || []))
      langList.forEach(item => {
        item.verctl = true
        item.key = item.newKey
        item.id = null
      })

      // 只有admin可以任意修改
      // 廚師只能再NEW、DRAFT、SUBMIT狀態中修改
      if (!isAdmin && this.temp.itemStatus === 'APPROVED') {
        const error = this.$t('It cannot be edited in the APPROVED state. If you need to edit, please modify it to another state before you save it')
        // APPROVED狀態下無法編輯，如需編輯，請修改成其他狀態再儲存
        alert(error)
      } else {
        var params = {
          baseProductId: this.temp.baseProductId,
          id: vid,
          name: vname,
          category: vcategory,
          tag: vtag + '',
          label: vlable,
          shop: vshop + '',
          shopRadio: vshopRadio,
          hot: vhot,
          // visible: vvisible,
          // status2: vstatus,
          itemStatus: this.temp.itemStatus,
          accessoryEntityId: vaccessoryEntityId,
          // itemContentInfo: vitemContentInfo,   //20220912..MAC移除
          shopAll: vshopAll,
          shopStruct: vshopStruct,
          retailPrice: vretailPrice,
          promotionPrice: vpromotionPrice,
          attributeList: attributeList,
          skuItems: skuItems,
          mainBannerUrl: this.mainBannerUrl,

          // itemsBannerUrl: this.itemsBannerUrl,
          // videoBannerUrl: this.videoBannerUrl,
          // itemsBannerUrl2: this.itemsBannerUrl2,
          // videoBannerUrl2: this.videoBannerUrl2,

          previewImageList,
          buyImageList,
          previewVideoList,
          buyVideoList,

          skuNum: vskuNum,
          skuData: vskuData,
          itemList: itemList,
          promotionType: vpromotionType,
          promotionValue: vpromotionValue,
          brand: vbrand,
          // productClassId: this.temp.class,
          productClassId: null,
          goodsType: goodsType,
          remarkReply: newRemarkRely,

          ingredientList: this.ingredientDataSelectObj,

          infos: this.temp.infos,
          purchaseLimit: purchaseLimit,
          adminTag: this.temp.adminTag,
          adminRemark: this.temp.adminRemark,
          cssPhoto: this.temp.cssPhoto,

          // version control
          langList
        }
        // console.log('=====param=====', params)
        // return
        const _this = this
        save(params).then((resp) => {
          const returnType = resp.returnType
          console.log('save result', resp)
          if (returnType === 'ADMIN_TAG_DUPLICATE') {
            this.$notify({
              title: this.$t('COMPONENTS.tip'),
              message: this.$t('Admin Tag is already used'),
              type: 'error'
            })
          } else if (returnType === 'SUCCESS') {
            this.$notify({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.save_Success'),
              type: 'success'
            })
            _this.handleVedioUpdateLog(resp.data)
            _this.back()
          } else {
            this.$notify({
              title: this.$t('COMPONENTS.tip'),
              message: '未知的錯誤',
              type: 'error'
            })
          }
        })
      }
    },
    handleVedioUpdateLog(data) {
      console.log('add update vedio log', data)
      if (data) {
        try {
          const buyVideoList = data.buyVideoList || []
          const buyVideos = this.convertLogJsonData(buyVideoList)
          const previewVideoList = data.previewVideoList || []
          const previewVideos = this.convertLogJsonData(previewVideoList)
          const objData = {}
          objData.buyVideos = buyVideos
          objData.previewVideos = previewVideos
          let jsonData = null
          if (objData) {
            jsonData = JSON.stringify(objData)
          }
          const val = { refId: data.id, refName: data.name, refAdminTag: data.adminTag, jsonData: jsonData }
          console.log('log data', val)
          productUpdatedMediaLog(val).then(res => {
            console.log('add log success?', res.data)
          })
        } catch (error) {
          console.error(error)
        }
      }
    },
    convertLogJsonData(videoList) {
      const list = []
      if (videoList && videoList.length > 0) {
        for (const v of videoList) {
          const vsplits = v.urlPath ? v.urlPath.split('/') : []
          const vname = vsplits.length > 0 ? vsplits[vsplits.length - 1] : null
          if (vname) {
            list.push({ type: 'default', name: vname })
          }
        }
      }
      return list
    }
  }
}
</script>

<style lang="scss">
.el-upload-list__item.is-ready {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  float: left;
  margin-right: 10px;
}
#product-form {
  padding-bottom: 0.5rem;
}
.header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: white;
}
.card-box {
  margin: 0.5rem 0.5rem 0;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}
.el-drawer{
    overflow: scroll
  }
.el-drawer__body {
  margin-left: 20px;
}
.el-drawer__header{
  background-color: #2ea6f1;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.el-drawer__header>:first-child{
  margin-bottom: 18px;
}
.el-drawer__body{
  margin-top: -22px
}
.iframe-class {
  width: 100%;
  border: 0px;
}
// .el-transfer-panel{
//   width:auto;
// }
.el-transfer__buttons{
  padding: 0 13px;
}
.el-transfer-panel__item{
  height: 38px;
}
.el-transfer-panel__item.el-checkbox .el-checkbox__label{
  overflow: visible;
}
// .ingredient-transfer-card .el-transfer-panel{
//   width: 40%;
// }
.product-remark-quill .ql-toolbar{
  display: none;
}
.product-remark-quill .ql-container.ql-snow{
  border:unset !important
}

//左邊的框框
#productIngredientTransfer > .el-transfer-panel:first-child{
  width: 250px;
};
//右邊的框框
#productIngredientTransfer > .el-transfer-panel:last-child{
  width: 1000px;
};

.el-tabs__new-tab {
  background-color: #2a92f1;
  height: 25px;
  width: 25px;
  .el-icon-plus{
    font-size: 24px;
  }
};
//vue-quill-editor輸入框最小高度
.allowUpdate-quill .ql-toolbar.ql-snow + .ql-container.ql-snow{
  min-height: 220px;
}
.remark-value-disable .note-editor.note-frame .note-editing-area .note-editable[contenteditable=false], .note-editor.note-airframe .note-editing-area .note-editable[contenteditable=false]{
    background-color:#fff !important
}
.remark-value-disable .note-statusbar{
    display: none;
}
</style>
