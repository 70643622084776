<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="150px"
        >
          <el-row :gutter="20">
            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('productItem.shop')">
                <el-select
                  v-model="listQuery.shopId"
                  :placeholder="$t('COMPONENTS.inselect')"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in shopOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('Admin Tag')">
                <el-input
                  v-model="listQuery.adminTag"
                  clearable
                  class="filter-item"
                />
              </el-form-item>
            </el-col>

          </el-row>
          <el-row :gutter="20">

            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('Activity Type')">
                <el-select
                  v-model="listQuery.activityType"
                  clearable
                  :placeholder="$t('COMPONENTS.inselect')"
                  style="width: 100%"
                >
                  <el-option value="NEW_REG" :label="$t('NEW_REG.label')" />

                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('Status')">
                <el-select
                  v-model="listQuery.status"
                  clearable
                  :placeholder="$t('COMPONENTS.inselect')"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                  />
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <div style="float: right;">
              <el-button
                :disabled="checkPermission('ACTIVITY.ADD')"
                @click="handleCreate"
              >{{ $t("COMPONENTS.add") }}</el-button>
              <el-button
                :disabled="checkPermission('ACTIVITY.SEARCH')"
                type="primary"
                @click="searchJob"
              >{{ $t("COMPONENTS.search") }}</el-button>
            </div>
          </el-row>
        </el-form>
      </div>
    </transition>

    <!-- 查询结果 -->
    <el-table
      header-row-class-name="table-header"
      :data="list"
      size="small"
      border
      fit
      highlight-current-row
    >

      <el-table-column
        :label="$t('Schedule')"
        prop="schedule"
        width="190"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.schedule === 'WAIT_APPROVED'" type="danger">{{ $t('WAIT_APPROVED.label') }}</el-tag>
          <el-tag v-if="scope.row.schedule === 'NON_START'" type="info">{{ $t('NON_START.label') }}</el-tag>
          <el-tag v-if="scope.row.schedule === 'GOING'" type="success">{{ $t('GOING.label') }}</el-tag>
          <el-tag v-if="scope.row.schedule === 'FINISHED'" type="info">{{ $t('FINISHED.label') }}</el-tag>
        </template>

      </el-table-column>
      <el-table-column :label="$t('Admin Tag')" prop="adminTag" width="200" />

      <el-table-column
        :label="$t('productItem.shop')"
        prop="shopId"
        width="140"
      >
        <template slot-scope="scope">
          {{ getShopName(scope.row.shopId) }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('Activity Title')"
        prop="activityTitle"
        width="200"
      />

      <el-table-column
        :label="$t('Activity Type')"
        prop="activityType"
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.activityType==='NEW_REG'">{{ $t('NEW_REG.label') }}</span>     <!-- 註冊禮 -->
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMPONENTS.time')" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.startPublicDate !== null">{{
            scope.row.startPublicDate
          }}</span>
          <span
            v-if="scope.row.endPublicDate !== null"
          >&nbsp;&nbsp;~<br>{{ scope.row.endPublicDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('productItem.status')"
        prop="newStatus"
        width="150px"
        align="left"
      />

      <el-table-column
        :label="$t('productItem.lastModifiedDate')"
        prop="lastModifiedDate"
        width="150px"
      />
      <el-table-column
        :label="$t('Last Modified By')"
        prop="lastModifiedBy"
        width="140px"
      />

      <el-table-column align="center" :label="$t('COMPONENTS.operation')" fixed="right">
        <template slot-scope="scope">
          <el-dropdown>
            <el-button size="mini" round>
              <em class="el-icon-more" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="checkPermission('ACTIVITY.EDIT')" icon="el-icon-edit" @click.native="handleUpdate(scope.row)">
                {{ $t('COMPONENTS.edit') }}
              </el-dropdown-item>
              <el-dropdown-item :disabled="checkPermission('ACTIVITY.DELETE')" icon="el-icon-close" @click.native="deleteActivity(scope.row)">
                {{ $t('COMPONENTS.delete') }}
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.schedule === 'GOING'" :disabled="checkPermission('ACTIVITY.DELETE')" icon="el-icon-time" @click.native="stopSold(scope.row)">
                {{ $t('COMPONENTS.endEarly') }}
                <!-- 提早結束 -->
              </el-dropdown-item>
            </el-dropdown-menu>

          </el-dropdown>
        </template>
      </el-table-column>

    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.size"
      @pagination="searchData"
    />

    <el-tooltip placement="top" :content="$t('COMPONENTS.return-top')">
      <back-to-top :visibility-height="100" />
    </el-tooltip>
  </div>
</template>

<script>
import { adminFoundAllData, adminDeleteData, formatDate, adminStopActivity } from '@/api/activityApi'
import { getLanguage } from '@/lang/index'
import { getCfsDomainData } from '@/api/cfsApi/cfsDomainApi'
import { shopList } from '@/api/shop'
import { getPageParm } from '@/utils/page-common'
import Pagination from '@/components/Pagination'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'ActivityList',
  components: { Pagination },
  data() {
    return {
      list: [],
      shopOptions: [],
      statusOptions: [],
      total: 0,
      listQuery: {
        page: 1,
        size: 10,
        shopId: null,
        adminTag: null,
        activityType: null
      }
    }
  },
  created() {
    this.searchData()
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getShopName(shopId) {
      let txt = ''
      if (this.shopOptions && this.shopOptions.lengt !== 0) {
        this.shopOptions.map((shop) => {
          if (shop.value === shopId) {
            txt = shop.label
          }
        })
      }
      return txt
    },
    loadData() {
      var permissionList = []

      // 取得商品List
      permissionList.push(shopList())
      permissionList.push(getCfsDomainData({ domainCode: 'SHOP_ACTIVITY_STATUS' }))
      Promise.all(permissionList).then((resp) => {
        let i = 0

        // ////////////////////////////////////////   拿所有的店(也只有一間店)
        const shopData = resp[i++].data
        if (shopData && shopData.length) {
          shopData.map((d) => {
            this.shopOptions.push({
              value: d.id,
              label: d.name
            })
          })
          // 預設搜尋的商店ID
          this.listQuery.shopId = this.shopOptions[0].value
        }

        // ////////////////////////////////////////   拿活動的所有狀態
        const allStatus = resp[i++].data
        this.statusOptions = []
        allStatus.map((data, i) => {
          this.statusOptions.push({
            value: data.domainDataCode,
            label: this.translateCfsValue(data) + ' (' + data.domainDataCode + ')'

            // 搜尋允許所有的狀態
            // disabled: checkCfsPermission('SHOP_ACTIVITY_STATUS.' + data.domainDataCode)
          })
        })
        console.log('拿活動的所有狀態>', this.statusOptions)

        this.searchData()
      })
    },
    translateCfsValue(value) {
      const languageCode = getLanguage()

      let text = null
      if (value && value.languages) {
        text = value.domainDataCode
        for (const data of value.languages) {
          if (languageCode === data.language) {
            text = data.value
            break
          }
        }
      }
      return text
    },
    searchData() {
      const query = { ...this.listQuery }
      const param = getPageParm(query)

      adminFoundAllData(param, query).then((response) => {
        const list = response.data
        if (list !== null && list.length !== 0) {
          list.map(item => {
            if (item.startPublicDate !== null) {
              item.startPublicDate = formatDate(new Date(item.startPublicDate))
            }
            if (item.endPublicDate !== null) {
              item.endPublicDate = formatDate(new Date(item.endPublicDate))
            }
            const oldStatus = item.status

            this.statusOptions.map((data, i) => {
              if (data.value === oldStatus) {
                item.newStatus = data.label
              }
            })

            item.schedule = this.checkSchedule(item)
          })
        }
        this.list = list
        this.total = response.pageCount
      })
        .catch(() => {
          this.list = []
          this.total = 0
        })
    },
    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    searchJob() {
      this.listQuery.page = 1
      this.searchData()
    },
    handleCreate() {
      this.$router.push({ path: 'activityForm', query: { title: 'create' }})
    },
    handleUpdate(row) {
      this.$router.push({
        path: 'activityForm',
        query: { title: 'update', id: row.id }
      })
    },

    confirmJob(msg, job) {
      this.$confirm(msg, this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        job()
      })
    },
    errorMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.error'),
        message: message,
        type: 'error'
      })
    },
    successMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.success'),
        message: message,
        type: 'success'
      })
    },
    checkSchedule(row) {
      console.log('checkSchedule', row)

      let result = null
      if (!this.isNull(row.id)) {
        const now = new Date()
        const startPublicDate = new Date(row.startPublicDate)
        const endPublicDate = new Date(row.endPublicDate)

        if (row.status === 'APPROVED') {
          if (now < startPublicDate) {
            result = 'NON_START' // 活動未開始
          } else if (startPublicDate < now && now < endPublicDate) {
            result = 'GOING' // 活動進行中
          } else if (endPublicDate < now) {
            result = 'FINISHED' // 活動已結束
          }
        } else {
          result = 'WAIT_APPROVED' // 等待批准
        }
      }
      return result
    },
    stopSold(row) {
      // 只有APPROVED且在運行中的活動
      // 才能夠提早結束
      if (row.schedule === 'GOING' && row.status === 'APPROVED') {
        this.confirmJob(this.$t('ACTIVITY_PAGE.stopActivity.confirm.msg'), () => { // 你確定要提早結束活動嗎
          adminStopActivity(row.id).then((response) => {
            if (response === 'SUCCESS') {
              this.searchJob()
              this.successMsg(this.$t('ACTIVITY_PAGE.stopActivity.success.msg')) // 活動已經提早結束
            } else {
              this.errorMsg(this.$t('ACTIVITY_PAGE.stopActivity.error.msg')) // 不允許提早結束
            }
          })
        })
      } else {
        this.errorMsg(this.$t('ACTIVITY_PAGE.stopActivity.error.msg')) // 不允許提早結束
      }
    },
    deleteActivity(row) {
      this.confirmJob(this.$t('ACTIVITY_PAGE.delete.msg'), () => { // 你確定要刪除活動嗎，如果這個活動沒有被使用，才允許刪除
        adminDeleteData({ id: row.id }).then((response) => {
          if (response === 'SUCCESS') {
            this.searchJob()
            this.successMsg(this.$t('ACTIVITY_PAGE.delete.success.msg')) // 刪除活動成功
          } else if (response === 'ACTIVITY_IN_USED') {
            this.errorMsg(this.$t('ACTIVITY_PAGE.delete.error.inUsed.msg')) // 此活動已經會員使用，禁止刪除
          } else {
            this.errorMsg('錯誤')
          }
        })
      })
    }
  }
}
</script>
