var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _vm.disabledTranslateBtn
                ? _c(
                    "el-col",
                    { staticClass: "page-header", attrs: { span: 12 } },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                  )
                : _c(
                    "el-col",
                    { staticClass: "page-header", attrs: { span: 12 } },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                  ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.disabledTranslateBtn },
                      on: {
                        click: function ($event) {
                          return _vm.setting()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.translate")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.submitBtn("temp")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "card-box" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    {
                      ref: "temp",
                      attrs: {
                        model: _vm.temp,
                        rules: _vm.rules,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "name",
                            label: _vm.$t("brand.brandName"),
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "38%" },
                            attrs: { value: _vm.temp.name },
                            model: {
                              value: _vm.temp.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "name", $$v)
                              },
                              expression: "temp.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "logoUrl",
                            label: _vm.$t("brand.brandImg"),
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: "#",
                                "http-request": _vm.uploadMainImages,
                                "file-list": _vm.mainBannerUrl,
                                accept: ".jpg,.jpeg,.png,.gif",
                                "show-file-list": false,
                                "before-upload": _vm.beforeFileUpload,
                              },
                            },
                            [
                              _vm.logoUrl
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: _vm.logoUrl, alt: "" },
                                  })
                                : _c("em", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "remark",
                            label: _vm.$t("brand.remark"),
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "38%" },
                            attrs: { value: _vm.temp.remark },
                            model: {
                              value: _vm.temp.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "remark", $$v)
                              },
                              expression: "temp.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-drawer",
            {
              staticClass: "translate-drawer",
              attrs: {
                title: _vm.$t("COMPONENTS.translate"),
                visible: _vm.drawer,
                size: "60%",
                "show-close": false,
                "modal-append-to-body": false,
                "append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
                opened: _vm.getIframe,
              },
            },
            [
              _c("iframe", {
                ref: "iframe",
                staticClass: "iframe-class",
                attrs: {
                  src: _vm.ifreamSrc,
                  height: _vm.getClientHeight(),
                  title: "",
                },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }