import request from '@/utils/request'

import { API_SERVER_URL, API_MEMBER_SERVER_URL } from '../Config'

export function homeInfo() {
  return request({
    url: API_SERVER_URL + '/public/admin/home/info',
    method: 'get'
  })
}

export function mhomeInfo() {
  return request({
    url: API_MEMBER_SERVER_URL + '/public/admin/home/info',
    method: 'get'
  })
}
