import request from '@/utils/request'
import { API_BASE_URL_MEMBER } from '../Config'

export function findAllList(query) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/shop/findAllShopPic',
    method: 'get',
    data: query
  })
}
export function searchAllPicList(param, quary) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/shop/findAllShopPic' + param,
    method: 'post',
    data: quary
  })
}

export function findById(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/findPicById/' + id,
    method: 'get'
  })
}

export function submitUpload(shopPic) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/shop/addShopPic',
    method: 'post',
    data: shopPic
  })
}

export function updatePicById(shopPic) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/shop/updatePicById',
    method: 'post',
    data: shopPic
  })
}

export function deleteById(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/deleteShopPicById/' + id,
    method: 'delete',
    data: id
  })
}

