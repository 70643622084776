import Layout from '@/layout'
import { checkCfsPermission } from '../../utils/permission'

const spreadRouter = {
  path: '/spread',
  component: Layout,
  redirect: '/spread/activity',
  alwaysShow: true,
  name: 'spread',
  hidden: false,
  meta: {
    title: 'navbar.spread_Manage.label',
    icon: 'table'
  },
  children: [

    {
      path: 'plan',
      component: () => import('@/views/plan/plan'),
      name: 'plan',
      meta: { title: 'plan.title' },
      hidden: checkCfsPermission('plan')
    },
    {
      path: 'plan/create',
      component: () => import('@/views/plan/planEdit'),
      name: 'planEdit',
      meta: { title: 'plan.add', noCache: false, activeMenu: 'plan/main', type: 'CREATE' },
      hidden: true
    },
    {
      path: 'plan/edit',
      component: () => import('@/views/plan/planEdit'),
      name: 'planEdit',
      meta: { title: 'plan.edit', noCache: false, activeMenu: 'plan/main', type: 'EDIT' },
      hidden: true
    },
    {
      path: 'family',
      component: () => import('@/views/family/family'),
      name: 'familyList',
      meta: { title: 'familyProgram.title' },
      hidden: checkCfsPermission('FAMILY.SEARCH')
    },
    {
      path: 'familyEdit',
      component: () => import('@/views/family/familyEdit'),
      name: 'familyEdit',
      meta: { title: 'familyProgram.title' },
      hidden: true
    },
    {
      path: 'activity',
      component: () => import('@/views/spread/activity'),
      name: 'activity',
      meta: {
        title: 'navbar.spread_Manage.activity.label',
        noCache: false
      },
      hidden: checkCfsPermission('ACTIVITY.SEARCH')
    },
    {
      path: 'activityForm',
      component: () => import('@/views/spread/activityForm'),
      name: 'activityForm',
      meta: {
        title: 'navbar.spread_Manage.activity.label',
        noCache: false
      },
      hidden: true
    },
    {
      path: 'coupon',
      component: () => import('@/views/spread/coupon'),
      name: 'coupon',
      meta: {
        title: 'navbar.spread_Manage.coupon.label',
        noCache: false
      },
      hidden: checkCfsPermission('COUPON.SEARCH')
    },
    {
      path: 'couponHistory',
      component: () => import('@/views/spread/couponHistory'),
      name: 'couponHistory',
      meta: {
        title: 'coupon.usageHistory.label',
        noCache: false
      },
      hidden: checkCfsPermission('COUPON.SEARCH')
    },
    {
      path: 'couponForm',
      component: () => import('@/views/spread/couponForm'),
      name: 'couponForm',
      meta: {
        title: 'coupon.couponForm.label',
        noCache: false
      },
      hidden: true
    },
    // {
    //   path: 'notice',
    //   component: () => import('@/views/spread/noticeList'),
    //   name: 'notice',
    //   meta: {
    //     title: 'navbar.spread_Manage.notice.label',
    //     noCache: false
    //   },
    //   hidden: checkCfsPermission('notice')
    // },
    {
      path: 'page',
      component: () => import('@/views/spread/page'),
      name: 'page',
      meta: {
        title: 'navbar.spread_Manage.Page',
        noCache: false
      },
      hidden: checkCfsPermission('PAGE')
    },
    {
      path: 'pageEdit',
      component: () => import('@/views/spread/pageEdit'),
      name: 'page',
      meta: {
        title: 'Edit Page',
        noCache: false
      },
      hidden: true
    },
    {
      path: 'pageAdd',
      component: () => import('@/views/spread/pageEdit'),
      name: 'page',
      meta: {
        title: 'Add Page',
        noCache: false
      },
      hidden: true
    },
    {
      path: 'releaseNotice',
      component: () => import('@/views/spread/noticeSave'),
      name: 'releaseNotice',
      meta: {
        title: 'notice.releaseNotice.label',
        noCache: false
      },
      hidden: true
    }
  ]
}

export default spreadRouter
