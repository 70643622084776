<template>
  <div>
    <sti-appframe
      class="mytestappframe"
      :preview="preview"
      :form-key="formkey"
      :form-view="formView"
      :use-shadow-dom="useShadowDom"
      :page-key="pageKey"
      :object-id="objectId"
      :language-id="languageId"
      :use-default-popup-contaier="useDefaultPopupContaier"
    />
  </div>
</template>

<script>
export default {
  name: 'ErgoAppFrame',
  props: {
    formkey: {
      type: String,
      default: ''
    },
    formView: {
      type: String,
      default: 'master'
    },
    pageKey: {
      type: String,
      default: ''
    },
    objectId: {
      type: String,
      default: ''
    },
    preview: {
      type: Boolean,
      default: false
    },
    useShadowDom: {
      type: Boolean,
      default: false
    },
    languageId: {
      type: String,
      default: 'en'
    },
    useDefaultPopupContaier: {
      type: Boolean,
      default: true
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style>
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: #fafafa;
  }
.ant-picker-dropdown{
  position: relative;
  left: 0%;
  top: 0%;
}
.ant-message {
  z-index: 9999;
}
</style>
