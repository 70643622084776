var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "variable-goods-tabs" },
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "left" } },
        [
          [
            _c(
              "el-tab-pane",
              { attrs: { label: _vm.$t("productItem.basic_price") } },
              [
                _c(
                  "el-form",
                  {
                    ref: "variableForm",
                    attrs: { model: _vm.variableForm, rules: _vm.rules },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("productItem.retailPrice"),
                                  prop: "retailPrice",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.variableForm.retailPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.variableForm,
                                          "retailPrice",
                                          $$v
                                        )
                                      },
                                      expression: "variableForm.retailPrice",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("HK$"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "productItem.discount_information"
                                  ),
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { change: _vm.changePromotionType },
                                    model: {
                                      value: _vm.variableForm.promotionType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.variableForm,
                                          "promotionType",
                                          $$v
                                        )
                                      },
                                      expression: "variableForm.promotionType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t("productItem.NODISCOUNT"),
                                        value: "NODISCOUNT",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t("productItem.DISCOUNT"),
                                        value: "DISCOUNT",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "productItem.PRICE_REDUCTION"
                                        ),
                                        value: "PRICE_REDUCTION",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.variableForm.promotionType === "DISCOUNT"
                                  ? _c("el-input-number", {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        precision: 2,
                                        step: 0.01,
                                        max: 0.99,
                                      },
                                      on: { change: _vm.countDValue },
                                      model: {
                                        value: _vm.variableForm.dValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.variableForm,
                                            "dValue",
                                            $$v
                                          )
                                        },
                                        expression: "variableForm.dValue",
                                      },
                                    })
                                  : _c("el-input-number", {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        precision: 2,
                                        step: 0.01,
                                        max: 10000,
                                      },
                                      on: { change: _vm.countPValue },
                                      model: {
                                        value: _vm.variableForm.pValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.variableForm,
                                            "pValue",
                                            $$v
                                          )
                                        },
                                        expression: "variableForm.pValue",
                                      },
                                    }),
                                _vm._v(" "),
                                _vm.variableForm.promotionType === "DISCOUNT"
                                  ? _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.$t("productItem.play")) +
                                          _vm._s(
                                            _vm.variableForm.promotionValue
                                          ) +
                                          _vm._s(_vm.$t("productItem.fold"))
                                      ),
                                    ])
                                  : _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("productItem.REDUCTION")
                                        ) +
                                          _vm._s(
                                            _vm.variableForm.promotionValue
                                          ) +
                                          _vm._s(_vm.$t("productItem.RMB"))
                                      ),
                                    ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("productItem.DISCOUNTINFO"))
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 8, md: 8, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("productItem.promotionPrice"),
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.variableForm.promotionPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.variableForm,
                                          "promotionPrice",
                                          $$v
                                        )
                                      },
                                      expression: "variableForm.promotionPrice",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("HK$"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: _vm.$t("productItem.attribute"),
                  disabled: _vm.checkPermission("SHOP_PRODUCT_ATTRIBUTE"),
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { "margin-left": "0.5rem" },
                            attrs: {
                              placeholder: _vm.$t("COMPONENTS.inselect"),
                            },
                            on: { change: _vm.selProductAttribute },
                            model: {
                              value: _vm.productAttribute,
                              callback: function ($$v) {
                                _vm.productAttribute = $$v
                              },
                              expression: "productAttribute",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("productItem.custom-attribute"),
                                value: "",
                              },
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.attributesGroup, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.addCard } }, [
                          _vm._v(_vm._s(_vm.$t("COMPONENTS.add"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            on: { click: _vm.saveAttribute },
                          },
                          [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.customAttribute,
                        expression: "customAttribute",
                      },
                    ],
                    attrs: { gutter: 20 },
                  },
                  [
                    _c(
                      "el-col",
                      _vm._l(_vm.list, function (item) {
                        return _c(
                          "el-card",
                          {
                            key: item.id,
                            staticClass: "card-box",
                            style: item.styleObject,
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", [
                                  _c("i", {
                                    staticClass: "el-icon-close",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-left": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCard(item.id)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-minus",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.showUp,
                                        expression: "item.showUp",
                                      },
                                    ],
                                    staticClass: "el-icon-arrow-up",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-right": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showRow(item)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.showDown,
                                        expression: "item.showDown",
                                      },
                                    ],
                                    staticClass: "el-icon-arrow-down",
                                    staticStyle: {
                                      float: "right",
                                      "margin-top": "-10px",
                                      "margin-right": "5px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hideRow(item)
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isShow,
                                    expression: "item.isShow",
                                  },
                                ],
                                attrs: { gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  { staticStyle: { "padding-top": "20px" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "productItem.attributeTitle"
                                              ),
                                            },
                                          },
                                          [
                                            _c("el-autocomplete", {
                                              ref: "attrs_demo",
                                              refInFor: true,
                                              attrs: {
                                                "fetch-suggestions":
                                                  _vm.querySearchAsync,
                                                placeholder: _vm.$t(
                                                  "COMPONENTS.incontent"
                                                ),
                                              },
                                              on: {
                                                select: function ($event) {
                                                  return _vm.handleSelect(
                                                    item.attrTitle,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.attrTitle,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "attrTitle",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.attrTitle",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "productItem.attribute"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  border: "1px solid #d4d4d4",
                                                  height: "120px",
                                                  width: "580px",
                                                  padding: "10px",
                                                },
                                              },
                                              [
                                                _vm._l(
                                                  item.attributeContents,
                                                  function (tag) {
                                                    return _c(
                                                      "el-tag",
                                                      {
                                                        key: tag,
                                                        attrs: {
                                                          closable: "",
                                                          "disable-transitions": false,
                                                        },
                                                        on: {
                                                          close: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleClose(
                                                              item,
                                                              tag
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(tag) +
                                                            "\n                        "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                item.inputVisible
                                                  ? _c("el-input", {
                                                      ref: "saveTagInput",
                                                      refInFor: true,
                                                      staticClass:
                                                        "input-new-tag",
                                                      attrs: { size: "small" },
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleInputConfirm(
                                                            item
                                                          )
                                                        },
                                                      },
                                                      nativeOn: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.handleInputConfirm(
                                                            item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.inputValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "inputValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.inputValue",
                                                      },
                                                    })
                                                  : _c(
                                                      "el-button",
                                                      {
                                                        staticClass:
                                                          "button-new-tag",
                                                        attrs: {
                                                          size: "small",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showInput(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("+ New Tag")]
                                                    ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: _vm.$t("productItem.store") } },
              [
                _vm.vgoodsId === ""
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          _vm._l(_vm.list, function (item) {
                            return _c(
                              "el-form-item",
                              {
                                key: item.id,
                                attrs: { label: item.attrTitle },
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: item.attributeGroup,
                                      callback: function ($$v) {
                                        _vm.$set(item, "attributeGroup", $$v)
                                      },
                                      expression: "item.attributeGroup",
                                    },
                                  },
                                  _vm._l(
                                    item.attributeContents,
                                    function (value) {
                                      return _c("el-checkbox", {
                                        key: value,
                                        attrs: { label: value },
                                        on: {
                                          change: function ($event) {
                                            return _vm.checkAttrValue(
                                              item.attrTitle,
                                              value
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.storeInfo") } },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  data: _vm.skuData,
                                  "tooltipeffect-": "dark",
                                  "header-row-class-name": "table-header",
                                  size: "mini",
                                },
                              },
                              [
                                _vm._l(_vm.list, function (item, i) {
                                  return _c("el-table-column", {
                                    key: item.attrTitle,
                                    attrs: {
                                      label: item.attrTitle,
                                      width: "150px",
                                      align: "left",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.values[i]) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("productItem.price"),
                                    prop: "itemPrice",
                                    width: "150px",
                                    align: "left",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              value: scope.row.itemPrice,
                                            },
                                            model: {
                                              value: scope.row.itemPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "itemPrice",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.itemPrice",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("productItem.store"),
                                    prop: "stockQty",
                                    width: "150px",
                                    align: "left",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              value: scope.row.stockQty,
                                            },
                                            model: {
                                              value: scope.row.stockQty,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "stockQty",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.stockQty",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.operation"),
                                    prop: "name",
                                    width: "150px",
                                    align: "left",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("COMPONENTS.delete")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.vgoodsId != ""
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: _vm.$t("COMPONENTS.operation") } },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          _vm._l(_vm.list2, function (item2) {
                            return _c(
                              "el-form-item",
                              {
                                key: item2.id,
                                attrs: { label: item2.attrTitle },
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: item2.attributeGroup,
                                      callback: function ($$v) {
                                        _vm.$set(item2, "attributeGroup", $$v)
                                      },
                                      expression: "item2.attributeGroup",
                                    },
                                  },
                                  _vm._l(
                                    item2.attributeContents,
                                    function (value) {
                                      return _c("el-checkbox", {
                                        key: value,
                                        attrs: { label: value },
                                        on: {
                                          change: function ($event) {
                                            return _vm.checkAttrValue2(
                                              item2.attrTitle,
                                              value
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("COMPONENTS.storeInfo"),
                                },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.skuData2,
                                      "tooltipeffect-": "dark",
                                      "header-row-class-name": "table-header",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.list2, function (item2, i) {
                                      return _c("el-table-column", {
                                        key: item2.attrTitle,
                                        attrs: {
                                          label: item2.attrTitle,
                                          width: "150px",
                                          align: "left",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        scope.row.values[i]
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: _vm.$t("productItem.price"),
                                        prop: "itemPrice2",
                                        width: "150px",
                                        align: "left",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "HK$",
                                                    value: scope.row.itemPrice,
                                                  },
                                                  model: {
                                                    value: scope.row.itemPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "itemPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.itemPrice",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        596703866
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: _vm.$t("productItem.store"),
                                        prop: "stockQty2",
                                        width: "150px",
                                        align: "left",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "",
                                                    value: scope.row.stockQty,
                                                  },
                                                  model: {
                                                    value: scope.row.stockQty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "stockQty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.stockQty",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3806853469
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: _vm.$t("COMPONENTS.operation"),
                                        prop: "name",
                                        width: "150px",
                                        align: "left",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDelete2(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "COMPONENTS.delete"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1438649585
                                      ),
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }