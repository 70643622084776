import request from '@/utils/request'

import { API_CFS_SERVICE_URL } from '../../Config'

export function getCfsUserInfoAndPermission(data) {
  return request({
    url: API_CFS_SERVICE_URL + '/auth/cfs/cfsuser/getCfsUserInfo',
    method: 'post',
    data: data
  })
}

export function getSysGrpBySysName(data) {
  return request({
    url: API_CFS_SERVICE_URL + '/service/sysGroup/listBySysName',
    method: 'post',
    data: data
  })
}

export function searchRoleGroupByLoginName(sysName, loginName) {
  return request({
    url: API_CFS_SERVICE_URL + '/service/sysGroup/searchRoleGroupByLoginName',
    method: 'post',
    data: {
      'systemName': sysName,
      'loginName': loginName
    }
  })
}

export function updateWithSysGrp(preffixUnitName, loginName, userName, sysId, sysGrpIds) {
  return request({
    url: API_CFS_SERVICE_URL + '/auth/cfs/cfsuser/updateWithSysGrp',
    method: 'post',
    data: {
      preffixUnitName: preffixUnitName,
      loginName: loginName,
      userName: userName,
      sysId: sysId,
      sysGrpIds: sysGrpIds
    }
  })
}

export function deleteUserByGalleryAdmin(preffixUnitName, loginName, sysId) {
  return request({
    url: API_CFS_SERVICE_URL + '/auth/cfs/cfsuser/deleteUserByGalleryAdmin',
    method: 'post',
    data: {
      preffixUnitName: preffixUnitName,
      loginName: loginName,
      sysId: sysId
    }
  })
}

export function findBySysName(sysName) {
  return request({
    url: API_CFS_SERVICE_URL + '/service/system/findBySysName',
    method: 'post',
    data: {
      'systemName': sysName
    }
  })
}

export function foundUserByPartyGroupId(partyGroupId) {
  return request({
    url: API_CFS_SERVICE_URL + '/service/partyGroupUser/foundUserByPartyGroupId',
    method: 'post',
    data: {
      'partyGroupId': partyGroupId
    }
  })
}

export function foundAddAdminUser() {
  return request({
    url: API_CFS_SERVICE_URL + '/service/partyGroupUser/foundAddAdminUser',
    method: 'post'
  })
}

