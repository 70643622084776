import { API_BASE_URL_MEMBER, API_CFS_SERVICE_URL } from '@/Config.js'

export function getStaticSourcePath(path, type) {
  console.log('getStaticSourcePath', path, type)
  var server = API_BASE_URL_MEMBER
  if (type) {
    server = API_CFS_SERVICE_URL
  }

  const isStorage = localStorage.getItem('IS_STORAGE')
  if (isStorage) {
    const previewUrl = API_BASE_URL_MEMBER+'/public/download/'+path
    return previewUrl
  }
  
  if (typeof (path) === 'string') {
    const resultUrl = `${server}/static/${path}`
    if (/(http|https):\/\//.test(path)) {
      return path
    }
    return resultUrl
  } else {
    const resultUrl = `${server}/static/${path.urlPath}`
    if (/(http|https):\/\//.test(path.urlPath)) {
      return path.urlPath
    }
    return resultUrl
  }
}

// const checkRelative = (sourceStaticKey, url) => {
//   let resultUrl = `${DATA_SERVER_URL[sourceStaticKey]}/static/${url}`;
//   if (/(http|https):\/\//.test(url)) {
//       return url;
//   }
//   return resultUrl;
// }
