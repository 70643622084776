import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'
import { getAccessToken } from '@/service/AuthService'
import { getLanguage } from '@/lang'

export function searchOrderList(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/order/searchAllOrder' + param,
    method: 'post',
    data: query
  })
}

export function updateOrderStatus(param, status) {
  param.state = status
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/order/updateOrderStatus',
    method: 'post',
    data: param
  })
}

export function updateOrderShippingStatus(param) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/order/updateOrderShippingStatus',
    method: 'post',
    data: param
  })
}

export function updateOrderAdminRemark(param) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/order/updateOrderAdminRemark',
    method: 'post',
    data: param
  })
}

export function updateOrderShippingNo(param) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/order/updateOrderShippingNo',
    method: 'post',
    data: param
  })
}

export function restoreAppleOrder(appleOrderId, orderId) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/apple/restoreAppleOrder/' + appleOrderId + '/' + orderId,
    method: 'get'
  })
}

export function reCheckAppleOrder(orderId) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/apple/reCheckAppleOrder/' + orderId,
    method: 'get'
  })
}

export function exportOrderList(param, query) {
  const requestHeaders = {
    'Content-Type': 'application/json'
  }
  if (getAccessToken()) {
    requestHeaders['Authorization'] = 'Bearer ' + getAccessToken()
  }
  if (getLanguage()) {
    requestHeaders['language'] = getLanguage()
  }
  return fetch(API_BASE_URL_PRODUCT + '/api/admin/order/exportOrderList' + param, {
    method: 'POST',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify(query)
  })
}

