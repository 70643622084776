<template>
  <div class="app-container">
    <div class="filter-container">
      <div>
        <el-lable
          style="color:#808080"
        >Attribute Lable Name*(属性标题)</el-lable>
        <el-autocomplete
          v-model="attr.name"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入内容"
          clearable
          @select="handleSelect"
        />
      </div>
      <div>
        <el-button
          class="filter-item"
          :disabled="checkPermission('attributeGroup.save')"
          @click="handleSave"
        >{{ $t("COMPONENTS.save") }}</el-button>
        <el-button class="filter-item" @click="handleCancel">{{
          $t("COMPONENTS.return")
        }}</el-button>
      </div>
    </div>
    <div>
      <el-form>
        <el-form-item label="Type*(选择方式)">
          <el-radio v-model="multpleSelectInd" label="0">单选</el-radio>
          <el-radio v-model="multpleSelectInd" label="1">多选</el-radio>
        </el-form-item>
        <el-form-item label="用于变化类型">
          <el-radio v-model="isVariable" label="0">NO</el-radio>
          <el-radio v-model="isVariable" label="1">YES</el-radio>
        </el-form-item>
        <el-form-item label="required*(是否必填)">
          <el-radio v-model="mandatoryInd" label="0">NO</el-radio>
          <el-radio v-model="mandatoryInd" label="1">YES</el-radio>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn-titless">
      <p class="text-title">Attribute list</p>
    </div>
    <el-form>
      <el-form-item>
        <el-table :data="attr.attrValues" style="width: 100%">
          <el-table-column label="Seq(顺序)" width="180">
            <template slot-scope="scope">
              <el-form-item :prop="'attrValues.' + scope.$index + '.seqNo'">
                <el-input
                  v-if="scope.row.edit"
                  v-model="scope.row.seqNo"
                  style="width: 100%"
                  clearable
                />
                <span v-else>{{ scope.row.seqNo }}</span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="Attribute name(属性名称)" width="200">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  v-if="scope.row.edit"
                  v-model="scope.row.name"
                  style="width: 100%"
                />
                <span v-else>{{ scope.row.name }}</span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                icon="el-icon-delete"
                circle
                @click="delRow(scope.$index)"
              />
              <el-button
                icon="el-icon-edit"
                circle
                @click="editRow(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-circle-plus" @click="addLanguage()" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { adminListAll, saveAttrValue } from '@/api/productAttributeApi'
import { findByAttrId } from '@/api/productAttrbuteValueApi'
import { checkCfsPermission } from '@/utils/permission'
export default {
  data() {
    return {
      counter: [],
      attrList: [],
      attributeList: [],
      list: [],
      attributeReq: {
        id: '',
        name: ''
      },
      multpleSelectInd: '1',
      isVariable: '1',
      mandatoryInd: '1',
      attr: {
        seqNo: 0,
        name: '',
        attrValues: [
          {
            id: '',
            seqNo: 0,
            name: '',
            edit: true
          }
        ]
      },
      attrId: '',
      id: '',
      name: '',
      groupName: '',
      dtlId: '',
      grpId: ''
    }
  },
  created() {
    this.loadData()
    this.attrId = this.$router.app._route.query.productAttrId
    this.attr.name = this.$router.app._route.query.name
    if (this.attr.name) {
      console.log('进入啦-------------=-=')
      const id = this.attrId
      console.log('进入id------------', id)
      findByAttrId(id).then(response => {
        this.attr.attrValues = response
        console.log('进入查询=-----------', this.attr.attrValues)
      })
    }
    this.groupName = this.$router.app._route.query.groupName
    console.log('groupname', this.groupName)
    this.dtlId = this.$router.app._route.query.dtlId
    console.log('dtlId', this.dtlId)
    this.grpId = this.$router.app._route.query.grpId
    console.log('grpId', this.grpId)
    this.findByAttrValueId()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    loadData() {
      adminListAll().then(response => {
        var data = response.data
        this.attrList = data
      })
    },
    findByAttrValueId() {
      const id = this.attrId
      findByAttrId(id).then(response => {
        this.attr.attrValues = response
      })
    },
    // attrValueList() {
    //   const id = this.attributeReq.id
    //   console.log('reqid------',this.attributeReq.id)
    //   console.log(id)
    //   findById(id).then((response) => {
    //     this.list = response
    //     console.log('list------------',this.list)
    //   })
    // },
    handleCancel() {
      localStorage.removeItem('list')
      this.$router.push({ path: 'create' })
    },
    handleSave() {
      const id = this.attrId
      const req = {
        id: id,
        name: this.attr.name,
        attrValue: this.attr.attrValues
      }
      saveAttrValue(req).then(response => {
        if (response) {
          this.$message.success('save success !')
          const multpleSelectInd = this.multpleSelectInd
          const isVariable = this.isVariable
          const mandatoryInd = this.mandatoryInd
          const id = response.id
          const name = response.name
          const num = this.attr.attrValues.length
          const attrGroupName = this.groupName
          console.log('123', attrGroupName)
          const groupDtlId = this.dtlId
          const grpId = this.grpId
          const list = {
            productAttrId: id,
            multpleSelectInd: multpleSelectInd,
            isVariable: isVariable,
            mandatoryInd: mandatoryInd,
            name: name,
            num: num
          }
          console.log('<-----------===============', list)
          var tempList = JSON.parse(localStorage.getItem('list') || '[]')
          console.log('读取之前的数据', tempList)
          tempList.push(list)
          localStorage.setItem('list', JSON.stringify(tempList))

          this.$router.push({
            path: 'create',
            query: {
              attrGroupName: attrGroupName,
              groupDtlId: groupDtlId,
              grpId: grpId
            }
          })
        } else {
          this.$message.error('save failed !')
        }
      })
    },
    deleteItem(item, index) {
      this.counter.splice(index, 1)
    },
    addLanguage() {
      this.attr.attrValues.push({
        seqNo: 0,
        name: '',
        edit: true
      })
    },
    delRow(row) {
      this.$confirm('此操作将删除该数据,是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.attr.attrValues.splice(row, 1)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    editRow(row) {
      console.info('++++', row)
      row.edit = !row.edit
    },
    querySearchAsync(queryString, cb) {
      adminListAll().then(response => {
        var data = response.data
        data.forEach((element, index) => {
          this.attrList.splice(index, 1, {
            id: element.id,
            value: element.name
          })
        })
      })
      cb(this.attrList)
    },
    handleSelect(item) {
      this.attrId = item.id
      const id = this.attrId
      findByAttrId(id).then(response => {
        console.log('查询到了没有-------', response)
        if (response && response.length > 1) {
          console.log('进入list')
          this.attr.attrValues = response
        } else {
          this.attr.attrValues = []
          console.log('进入null')
        }
      })
    }
  }
}
</script>
<style>
text-title {
  font-size: 18px;
  font-weight: bold;
}
.btn-titless {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.btn-add {
  width: 70px;
  height: 40px;
}
.filter-container {
  display: flex;
  justify-content: space-between;
}
</style>
