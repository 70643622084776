import request from '@/utils/request'
import { API_BASE_URL_MEMBER } from '../Config'

export function findAgentAllMemberTag() {
  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/findAgentAllMemberTag',
    method: 'get'
  })
}

export function deleteTagObject(tagId, memberId) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/deleteTagObject',
    method: 'delete',
    data: {
      memberId,
      tagId
    }
  })
}

export function deleteTag(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/deleteTag',
    method: 'delete',
    data: { id }
  })
}

export function isAgentTagRepeat(tag) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/isAgentTagRepeat',
    method: 'post',
    data: {
      tag: tag
    }
  })
}

export function addMemeberTag(addTagValue) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/addTag',
    method: 'post',
    data: {
      tag: addTagValue,
      tagType: 'MEMBER'
    }
  })
}

export function addTagObject() {
  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/addTagObject',
    method: 'post'
    // data: query
  })
}

export function resetMemberTag(memberIds, tagIds) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/resetMemberTag',
    method: 'post',
    data: {
      memberIds,
      tagIds
    }
  })
}

export function resetMemberTagByQuery(query, tagIds) {
  const data = JSON.parse(JSON.stringify(query))
  data.newTagIds = tagIds

  return request({
    url: API_BASE_URL_MEMBER + '/api/admin/resetMemberTagByQuery',
    method: 'post',
    data: data
  })
}

