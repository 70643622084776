import request from '@/utils/request'

import { API_CFS_SERVICE_URL } from '../../Config'

export function uploadFileIntoCfs(data) {
  return request({
    url: API_CFS_SERVICE_URL + '/api/file/vue/upload',
    method: 'post',
    data: data
  })
}