import defaultSettings from '@/settings'
import lang from '@/lang'

const title = defaultSettings.title

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${lang.t(pageTitle)} - ${lang.t(title)}`
  }
  return `${lang.t(title)}`
}
