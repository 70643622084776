<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="12" class="page-header">{{ $t('COMPONENTS.add') }}</el-col>
        <el-col :span="12">
          <el-button @click="setting()">{{ $t('COMPONENTS.translate') }}</el-button>
          <el-button @click="$router.back(-1)">{{ $t('COMPONENTS.return') }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission(&quot;attribute.edit&quot;)"
            @click="handleSave"
          >{{ $t('COMPONENTS.save') }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-form ref="attr" :model="attr" :rules="rules" label-width="80px">
      <el-row>
        <el-col :xl="12">
          <el-card class="card-box">
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('attribute.attrName')" prop="name">
                  <el-input v-model="attr.name" style="width: 200px" clearable />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item :label="$t('attribute.dataType')" prop="dataType">
                  <el-select v-model="attr.dataType" :placeholder="$t('attribute.Please-select-type')">
                    <el-option label="TEXT" value="TEXT" />
                    <el-option label="IMAGE" value="IMAGE" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item :label="$t('attribute.rowNum')">
                  <el-row :gutter="20">
                    <el-col :sm="6" :xs="12">
                      <el-input v-model="rowCount" :placeholder="$t('attribute.Number-of-rows-added')" />
                    </el-col>
                    <el-col :sm="6" :xs="12">
                      <el-button @click="addLanguage()">{{ $t('COMPONENTS.add') }}</el-button>
                    </el-col>
                  </el-row>
                  <el-table
                    :data="attr.attrValues"
                    style="width: 100%"
                    class="value-table"
                  >
                    <el-table-column :label="$t('attribute.valueName')" width="180px">
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="'attrValues.' + scope.$index + '.name'"
                          :rules="rules.value"
                        >
                          <el-input
                            v-if="scope.row.edit"
                            v-model="scope.row.name"
                            clearable
                          />
                          <span v-else>{{ scope.row.name }}</span>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('attribute.ValueDescription')" width="200">
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-input
                            v-if="scope.row.edit"
                            v-model="scope.row.description"
                            style="width: 180px"
                          />
                          <span v-else>{{ scope.row.description }}</span>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('COMPONENTS.operation')" width="200">
                      <template slot-scope="scope">
                        <el-button
                          icon="el-icon-delete"
                          circle
                          @click="delRow(scope.$index)"
                        />
                        <el-button
                          icon="el-icon-edit"
                          circle
                          @click="editRow(scope.row)"
                        />
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <el-drawer
        class="translate-drawer"
        :title="$t('COMPONENTS.translate')"
        :visible.sync="drawer"
        size="60%"
        :show-close="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        @opened="getIframe"
      >
        <iframe
          ref="iframe"
          :src="ifreamSrc"
          class="iframe-class"
          :height="getClientHeight()"
        />
      </el-drawer>
    </el-form>
  </div>
</template>
<script>
import Sticky from '@/components/Sticky'
import { saveAttr, adminFindById, deleteAttrValById } from '@/api/productAttributeApi'
import { API_CFS_SERVER_URL } from '@/Config'
import { getLanguage } from '../../lang'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'ProductAttributeCreate',
  components: { Sticky },
  props: {
    attrId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ifreamSrc: '',
      mainBannerUrl: [],
      count: 0,
      drawer: false,
      edit: false,
      rowCount: 0,
      attr: {
        name: '',
        dataType: '',
        attrValues: [
          {
            id: '',
            name: '',
            description: '',
            imageUrl: '',
            imageUrlId: '',
            edit: false
          }
        ]
      },
      rules: {
        name: [{ required: true, message: this.$t('rule.attributeName-cannot-be-empty'), trigger: 'blur' }],
        dataType: [{ required: true, message: this.$t('rule.attributeType-cannot-be-empty'), trigger: 'change' }],
        value: [{ required: true, message: this.$t('rule.attributeValue-cannot-be-empty'), trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('message', this.handleMessage)
    console.log('remove')
  },
  methods: {
    getClientHeight() {
      let clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
      } else {
        clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
      }
      clientHeight = clientHeight - 120
      return clientHeight
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    handleMessage(e) {
      console.log(e.data)
      if (!e.origin) return
      if (API_CFS_SERVER_URL.indexOf(e.origin) === -1) return
      if (e.data === 'save') {
        setTimeout(() => {
          this.handleClose()
        }, 1000)
      } else if (e.data === 'cancle') {
        this.handleClose()
      }
      var data = e.data
      if (data.data) {
        console.log(data.data)
      }
    },
    handleClose() {
      this.drawer = false
    },
    getIframe() {
      var defaultLang = getLanguage()
      var systemLanguageCodes = JSON.parse(localStorage.getItem('SYSTEM_LANGUAGE_CODES'))
      if (systemLanguageCodes && systemLanguageCodes.data && systemLanguageCodes.data.defaultLanguage) {
        defaultLang = systemLanguageCodes.data.defaultLanguage
      }
      var ifreamSrc = API_CFS_SERVER_URL + '/multipleLanguage/commonTranslateUtilPage'
      this.ifreamSrc =
        ifreamSrc + '?system=gallery&module=mobile&function=attribute&isIframe=true&language=' + defaultLang + '&sytemLanguage=' + getLanguage()
      console.log('this 对象', this)
      var data = [
        { name: this.$t('attribute.attrName'), key: this.attr.id + '.attributeName', type: 'input', value: this.attr.name }
        // { name: "Data Type", key: this.attr.id + ".dataType" },
      ]
      var dataList = this.attr.attrValues
      dataList && dataList.map(item => {
        console.log('isNaN(item.name)', isNaN(item.name))
        // var isNumOrBrief = this.checknumabs(item.name)
        // console.log('isNaN(item.name)',isNumOrBrief)
        if (isNaN(item.name)) {
          var obj = {
            name: item.name,
            key: item.id + '.attrValueName',
            type: 'input',
            value: item.name
          }
          data.push(obj)
        }
      })
      if (this.$refs.iframe.attachEvent) {
        this.$refs.iframe.attachEvent('onload', () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        })
      } else {
        this.$refs.iframe.onload = () => {
          this.$refs.iframe.contentWindow.postMessage(data, '*')
        }
      }
    },
    setting() {
      if (this.width > 0) {
        return
      }
      this.drawer = true
    },
    checknumabs(cont) {
      var c = new RegExp()
      c = /^[A-Za-z0-9]+$/
      if (!c.test(cont)) {
        return true
      }
      return false
    },
    loadData() {
      var id = this.$route.query.attrId
      adminFindById(id).then((resp) => {
        resp.data.attrValues &&
          resp.data.attrValues.forEach((item) => {
            item.edit = false
          })
        this.attr = resp.data
      })
    },
    handleSave() {
      this.$refs['attr'].validate((val) => {
        if (val) {
          saveAttr(this.attr).then((resp) => {
            if (resp.data) {
              this.$message.success(this.$t('COMPONENTS.save_Success'))
            } else {
              this.$message.error(this.$t('COMPONENTS.save_Filad'))
            }
            this.$router.back(-1)
          })
        } else {
          return false
        }
      })
    },
    addLanguage() {
      var re = /^[1-9]+[0-9]*]*$/
      var rowCount = 1
      if (re.test(this.rowCount)) {
        rowCount = this.rowCount
      }
      for (var i = 0; i < rowCount; i++) {
        this.attr.attrValues.push({
          id: '',
          name: '',
          description: '',
          imageUrl: '',
          imageUrlId: '',
          edit: true
        })
      }
    },
    delRow(row) {
      if (this.attr.attrValues[row].name) {
        this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
          confirmButtonText: this.$t('COMPONENTS.sure'),
          cancelButtonText: this.$t('COMPONENTS.cancel'),
          type: 'warning'
        }).then(() => {
          var val = this.attr.attrValues.splice(row, 1)
          console.log('=====', val[0].id || '')
          if (val[0].id || '') {
            deleteAttrValById(val[0].id).then((resp) => {
              if (resp) {
                this.$message.success(this.$t('COMPONENTS.delete_Success'))
              } else {
                this.$message.success(this.$t('COMPONENTS.delete_Filad'))
              }
            })
          }
        })
      } else {
        this.attr.attrValues.splice(row, 1)
      }
    },
    editRow(row) {
      console.info('++++', row)
      row.edit = !row.edit
    }
  }
}
</script>

<style>
.el-form {
  margin-top: 10px;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.component {
  margin: 10px;
}
.tree-box {
  margin: 15px 0 100px 55px;
  border: #a6e0d8 solid 2px;
  padding: 15px;
}
.form-item-head {
  margin-bottom: 8px;
  font-size: 12px;
}
.hide .el-upload--picture-card {
  display: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.value-table /deep/ .cell {
   padding: 0px 10px 16px 10px;
}
.iframe-class {
  width: 100%;
  border: 0px;
}
.el-drawer__header{
  background-color: #2ea6f1;
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
.el-drawer__header>:first-child{
  margin-bottom: 18px;
}
.el-drawer__body{
  margin-top: -22px
}
</style>
