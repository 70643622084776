var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-form",
              {
                staticClass: "search-form-inline",
                attrs: {
                  "label-suffix": ":",
                  "label-position": "left",
                  "hide-required-asterisk": "",
                  "label-width": "230px",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Admin Tag") } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.query.adminTag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "adminTag", $$v)
                                },
                                expression: "query.adminTag",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t(_vm.productNameLabel) } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.incontent"),
                              },
                              model: {
                                value: _vm.query.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "name", $$v)
                                },
                                expression: "query.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Category/Subcategory") } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.temp.categoryOptions,
                                props: _vm.props,
                                placeholder: _vm.$t("COMPONENTS.inselect"),
                              },
                              model: {
                                value: _vm.query.category,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "category", $$v)
                                },
                                expression: "query.category",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.tag") } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                },
                                model: {
                                  value: _vm.query.tag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "tag", $$v)
                                  },
                                  expression: "query.tag",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { label: "", value: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("COMPONENTS.inselect"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.temp.tagOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.label") } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.incontent"),
                              },
                              model: {
                                value: _vm.query.label,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "label", $$v)
                                },
                                expression: "query.label",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.price") } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "40%" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.query.minPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "minPrice", $$v)
                                },
                                expression: "query.minPrice",
                              },
                            }),
                            _vm._v("\n               ~\n               "),
                            _c("el-input", {
                              staticStyle: { width: "40%" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.query.maxPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "maxPrice", $$v)
                                },
                                expression: "query.maxPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.shop") } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                },
                                model: {
                                  value: _vm.query.shop,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "shop", $$v)
                                  },
                                  expression: "query.shop",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { label: "", value: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("COMPONENTS.inselect"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.temp.shopOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.brand") } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                },
                                model: {
                                  value: _vm.query.brand,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "brand", $$v)
                                  },
                                  expression: "query.brand",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { label: "", value: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("COMPONENTS.inselect"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.temp.brandList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.status") } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                  multiple: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.query.itemStatusForSearch,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.query,
                                      "itemStatusForSearch",
                                      $$v
                                    )
                                  },
                                  expression: "query.itemStatusForSearch",
                                },
                              },
                              _vm._l(_vm.temp.itemStatusList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("Created By") } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.query.createdBy,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "createdBy", $$v)
                                },
                                expression: "query.createdBy",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 8, md: 12, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("New Version Release Status"),
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("COMPONENTS.inselect"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.query.publicStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "publicStatus", $$v)
                                  },
                                  expression: "query.publicStatus",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("Published"),
                                    value: "HAS_PUBLISHED",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t(
                                      "Published (Already the latest version)"
                                    ),
                                    value: "LAST_VERSION_HAS_PUBLISHED",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t(
                                      "Published (There is a new version)"
                                    ),
                                    value: "LAST_VERSION_NOT_PUBLISHED",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("Not yet published"),
                                    value: "NOT_YET_PUBLISHED",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        attrs: { xl: 8, md: 12, sm: 12, xs: 24 },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.hot") } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "true" },
                                model: {
                                  value: _vm.query.hot,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "hot", $$v)
                                  },
                                  expression: "query.hot",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.true")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "false" },
                                model: {
                                  value: _vm.query.hot,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "hot", $$v)
                                  },
                                  expression: "query.hot",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.false")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        attrs: { xl: 8, md: 12, sm: 12, xs: 24 },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("productItem.store") } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "true" },
                                model: {
                                  value: _vm.query.qtyStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "qtyStatus", $$v)
                                  },
                                  expression: "query.qtyStatus",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.have")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "false" },
                                model: {
                                  value: _vm.query.qtyStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "qtyStatus", $$v)
                                  },
                                  expression: "query.qtyStatus",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.without")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-row", [
                  _c(
                    "div",
                    { staticStyle: { float: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.checkPermission("SHOP_PRODUCT.ADD"),
                          },
                          on: { click: _vm.addProduct },
                        },
                        [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.checkPermission("SHOP_PRODUCT.LIST"),
                          },
                          on: { click: _vm.searchBtnJob },
                        },
                        [
                          _vm._v(
                            "\n               " +
                              _vm._s(_vm.$t("COMPONENTS.search")) +
                              "\n             "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.data,
            "tooltip-effect": "dark",
            "header-row-class-name": "table-header",
            size: "mini",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Admin Tag"),
              prop: "adminTag",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t(_vm.productNameLabel),
              prop: "name",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("New Version Release Status"),
              width: "190px",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticStyle: { "font-size": "10px" } }, [
                      row.publishStatus === "HAS_PUBLISHED"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("Published")))])
                        : _vm._e(),
                      _vm._v(" "),
                      row.publishStatus === "LAST_VERSION_HAS_PUBLISHED"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Published (Already the latest version)")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      row.publishStatus === "LAST_VERSION_NOT_PUBLISHED"
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Published (There is a new version)")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      row.publishStatus === "NOT_YET_PUBLISHED"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("Not yet published"))),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.img"),
              prop: "imgUrl",
              width: "120px",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("el-image", { attrs: { src: row.mainImage } })]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.shop"),
              prop: "shopName",
              width: "110px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.shopStruct"),
              prop: "shopStruct",
              width: "110px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.price"),
              prop: "priceStr",
              width: "100px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.status"),
              prop: "itemStatus",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Total Views"),
              prop: "pageRefreshCount",
              width: "85px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Category/Subcategory"),
              prop: "category",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.tag"),
              prop: "tag",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.hot"),
              prop: "hot",
              width: "80px",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.hot ? "是" : "否"))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.lastModifiedDate"),
              prop: "lastModifiedDate",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.skuStatus"),
              prop: "visible",
              width: "150px",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.qtyStatus ? _c("span", [_vm._v("有库存")]) : _vm._e(),
                    _vm._v(" "),
                    !row.qtyStatus
                      ? _c("span", [_vm._v("没有库存")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Created By"),
              prop: "createdBy",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Last Modified By"),
              prop: "lastModifiedBy",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("COMPONENTS.operation"),
              prop: "name",
              width: "180px",
              align: "left",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope !== null ? _c("span") : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        key: 1,
                        attrs: {
                          size: "mini",
                          type: "primary",
                          disabled: _vm.checkPermission("SHOP_PRODUCT.EDIT"),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        key: 2,
                        attrs: {
                          size: "mini",
                          disabled: _vm.checkPermission("SHOP_PRODUCT.DELETE"),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("COMPONENTS.delete")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        style: _vm.pageStyle,
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.size,
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "size", $event)
          },
          pagination: _vm.searchJob,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }