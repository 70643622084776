<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <el-form
        class="search-form-inline"
        label-suffix=":"
        label-position="left"
        hide-required-asterisk
        label-width="158px"
      >
        <el-row :gutter="20">
          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('Ingredient Name')">
              <el-input
                v-model="query.name"
                style="max-width: 210px"
                clearable
                class="filter-item"
              />
            </el-form-item>
          </el-col>

          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('Status')">
              <el-select v-model="query.status">
                <el-option
                  v-for="(item, index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('Ingredient Category (Product Page)')">
              <el-select-tree
                v-model="query.defaultIngredientProductTreeId"
                clearable
                :data="ingredientProductTreeData"
                :multiple="false"
                check-strictly
                check-on-click-node
                :render-after-expand="false"
              />

            </el-form-item>
          </el-col>

          <el-col :xl="6" :md="8" :sm="12" :xs="24">
            <el-form-item :label="$t('Ingredient Category (Daily Menu Page)')">
              <el-select-tree
                v-model="query.defaultIngredientDailyMenuTreeId"
                clearable
                :data="ingredientDailyMenuTreeData"
                :multiple="false"
                check-strictly
                check-on-click-node
                :render-after-expand="false"
              />
            </el-form-item>
          </el-col>

        </el-row>

        <el-row style="margin: 5px;">

          <div style="float: right;">
            <el-button
              :disabled="checkPermission('INGREDIENT.ADD')"
              @click="handleCreate"
            >{{ $t("COMPONENTS.add") }}
            </el-button>
            <el-button
              :disabled="checkPermission('INGREDIENT.LIST')"
              type="primary"
              @click="search"
            >{{ $t("COMPONENTS.search") }}
            </el-button>
          </div>

        </el-row>
      </el-form>
    </transition>

    <el-table
      tooltip-effect="dark"
      :data="data"
      style="width: 100%"
      header-row-class-name="table-header"
      size="mini"
    >
      <el-table-column
        :label="$t('Ingredient Name')"
        prop="name"
        width="250px"
        align="left"
      />

      <el-table-column
        :label="$t('Image')"
        prop="imgUrl"
        width="150px"
        align="left"
      >
        <!-- :src="row.apiUrl + row.imgUrl" -->
        <template slot-scope="{ row }">
          <el-image
            v-if="row.imgUrl"
            :src="row.mediaUrl"
            style="width: 100px; height: 100px"
            fit="cover"
          />
          <span v-if="!row.imgUrl">-</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('Ingredient Category (Product Page)')"
        prop="defaultIngredientProductTreeId"
        width="250px"
        align="left"
      >
        <template slot-scope="{ row }">
          <div v-if="row.defaultIngredientProductTreeId">
            <span>{{ renderCategoryName(ingredientProductTreeData, row.defaultIngredientProductTreeId) }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('Ingredient Category (Daily Menu Page)')"
        prop="lastName"
        width="250px"
        align="left"
      >
        <template slot-scope="{ row }">
          <div v-if="row.defaultIngredientDailyMenuTreeId">
            <div v-if="row.defaultIngredientDailyMenuTreeId">
              <span>{{ renderCategoryName(ingredientDailyMenuTreeData, row.defaultIngredientDailyMenuTreeId) }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('productItem.lastModifiedDate')"
        prop="lastModifiedDate"
        width="150px"
        align="left"
      />

      <el-table-column :label="$t('Status')" align="left" prop="status">
        <template slot-scope="{ row }">
          <span v-if="row.status === 'A'">{{ $t("Active") }} </span>
          <span v-if="row.status === 'I'">{{ $t("Inactive") }} </span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMPONENTS.operation')"
        fixed="right"
        width="180px"
      >
        <template slot-scope="scope">
          <el-button
            :disabled="checkPermission('INGREDIENT.EDIT')"
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
          >{{ $t("COMPONENTS.edit") }}
          </el-button>
          <el-button
            :disabled="checkPermission('INGREDIENT.DELETE')"
            size="mini"
            @click="handleDelete(scope.$index, scope.row)"
          >{{ $t("COMPONENTS.delete") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-col>
        <pagination
          v-show="query.total > 0"
          class="hidden-xs-only"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <pagination
          class="hidden-sm-and-up"
          :hide-on-single-pag="query.total > 0"
          :total="query.total"
          :page.sync="query.page"
          :limit.sync="query.size"
          :pager-count="7"
          layout="pager, prev, next"
          @pagination="loadData"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import { adminFindIngredient, adminDeleteIngredient } from '@/api/ingredientApi'
import { getCfsTreeList } from '@/api/cfsApi/cfsTreeLIstApi'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
import ElSelectTree from 'el-select-tree'
import 'el-select-tree/lib/element-ui'
export default {
  name: 'Ingredient',
  components: { Pagination, ElSelectTree },
  directives: { waves },
  filters: {},
  data() {
    return {
      data: [],
      query: {
        id: undefined,
        name: '',
        description: '',
        wikiUrl: '',
        defaultIngredientDailyMenuTreeId: null,
        defaultIngredientProductTreeId: null,
        imgUrl: '',
        status: 'A',
        size: 10,
        page: 1,
        total: 100,
        sort: 'lastModifiedDate,desc'
      },
      statusOptions: [
        { value: 'A', label: this.$t('Active') },
        { value: 'I', label: this.$t('Inactive') }
      ],
      ingredientDailyMenuTreeData: [],
      ingredientProductTreeData: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    renderCategoryName(tree, id, path = []) {
      console.log('renderCategoryName', tree, id, path)
      // 網路上抄來的...超複雜
      // 來源https://developpaper.com/question/js-tree-structure-data-a-child-node-is-known-to-get-all-parent-nodes-up-at-once/
      // 目的：取得所有父name的Array
      if (!id.length || id.length === 0) {
        return ' - '
      }
      if (!tree) return []
      for (const data of tree) {
        // Here you can store the last returned content according to your needs
        const title = data.label
        path.push(title)
        if (data.value === id) {
          return this.toTreeString(path)
        }
        if (data.children) {
          const findChildren = this.renderCategoryName(data.children, id, path)
          if (findChildren.length) return findChildren
        }
        path.pop()
      }
      return ''
    },
    toTreeString(path) {
      // 網路上抄來的...超複雜
      // 來源https://developpaper.com/question/js-tree-structure-data-a-child-node-is-known-to-get-all-parent-nodes-up-at-once/
      // 目的：取得所有父name的Array

      let text = ''
      for (const data of path) {
        if (text !== '') text += ' > '
        text += data
      }
      return text
    },
    loadData() {
      const permissionList = []
      permissionList.push(getCfsTreeList({ belongId: 'DEFAULT', usageTypeCode: 'PRODUCT_INGREDIENT' }))
      permissionList.push(getCfsTreeList({ belongId: 'DEFAULT', usageTypeCode: 'INGREDIENT' }))

      let i = 0
      Promise.all(permissionList).then((respon) => {
        const ingredientProductTree = respon[i++].data
        this.ingredientProductTreeData = this.renderTree(ingredientProductTree)

        const ingredientTree = respon[i++].data
        this.ingredientDailyMenuTreeData = this.renderTree(ingredientTree)

        this.search()
      })
    },

    handleEdit(index, row) {
      this.$router.push({ path: 'ingredient/edit', query: { attrId: row.id }})
    },
    handleDelete(index, row) {
      // Are you sure you want to delete, if used by other recipes, it will be disassociated
      // 你確定要刪除嗎，如果有其他食譜使用，則會取消關聯

      this.$confirm(this.$t('Ingredient_delete_confirm_msg'), this.$t('COMPONENTS.tip'), {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel')
      })
        .then(() => {
          adminDeleteIngredient(row.id).then(response => {
            if (response === 'DELETE_SUCCESS') {
              this.$notify({
                title: this.$t('COMPONENTS.success'),
                message: this.$t('COMPONENTS.delete_Success'),
                type: 'success'
              })

              this.search()
            } else {
              this.$notify.error({
                title: this.$t('COMPONENTS.Filad'),
                message: this.$t('COMPONENTS.delete_Filad'),
                type: 'success'
              })
            }
          })
        })
    },
    handleCreate() {
      this.$router.push({ path: 'ingredient/create' })
    },
    search() {
      var query = { ...this.query }
      var param = getPageParm(query)

      console.log('sfthxczvb', query)

      adminFindIngredient(param, query).then((res) => {
        console.log('findIngredient==>', res)
        this.data = res.data
        this.query.total = res.pageCount
        this.query.page = res.pageNum
        this.query.size = res.pageSize
      })
    },
    renderTree(treeData) {
      const tree = []
      treeData.map((node, i) => {
        const id = node.id
        const title = node.treeItemTag.name
        const children = node.children
        // const hasChildren = node.children.length !== 0
        let hasChildren = false
        if (children && children.length !== 0) {
          hasChildren = true
        }
        const date = {
          value: id,
          label: title
        }
        if (hasChildren) {
          date.children = this.renderTree(children)
        }
        tree.push(date)
      })
      return tree
    }
  }
}
</script>
