<template>
  <div class="variable-goods-tabs">
    <el-tabs tab-position="left">
      <template>
        <el-tab-pane :label="$t('productItem.basic_price')">
          <el-form ref="variableForm" :model="variableForm" :rules="rules">
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.retailPrice')" prop="retailPrice">
                  <el-input v-model="variableForm.retailPrice" placeholder="">
                    <template slot="prepend">HK$</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.discount_information')">
                  <el-select v-model="variableForm.promotionType" style="width:80px" @change="changePromotionType">
                    <el-option :label="$t('productItem.NODISCOUNT')" value="NODISCOUNT" />
                    <el-option :label="$t('productItem.DISCOUNT')" value="DISCOUNT" />
                    <el-option :label="$t('productItem.PRICE_REDUCTION')" value="PRICE_REDUCTION" />
                  </el-select>
                  <el-input-number v-if="variableForm.promotionType==='DISCOUNT'" v-model="variableForm.dValue" :precision="2" :step="0.01" :max="0.99" style="width:150px" @change="countDValue" />
                  <el-input-number v-else v-model="variableForm.pValue" :precision="2" :step="0.01" :max="10000" style="width:150px" @change="countPValue" />
                  <label v-if="variableForm.promotionType==='DISCOUNT'">{{ $t('productItem.play') }}{{ variableForm.promotionValue }}{{ $t('productItem.fold') }}</label>
                  <label v-else>{{ $t('productItem.REDUCTION') }}{{ variableForm.promotionValue }}{{ $t('productItem.RMB') }}</label>
                  <br>
                  <span style="color:red;">{{ $t('productItem.DISCOUNTINFO') }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.promotionPrice')">
                  <el-input v-model="variableForm.promotionPrice" placeholder="">
                    <template slot="prepend">HK$</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('productItem.attribute')" :disabled="checkPermission('SHOP_PRODUCT_ATTRIBUTE')">
          <el-row :gutter="20">
            <el-col>
              <el-select
                v-model="productAttribute"
                :placeholder="$t('COMPONENTS.inselect')"
                style="margin-left: 0.5rem"
                @change="selProductAttribute"
              >
                <el-option :label="$t('productItem.custom-attribute')" value="" />
                <el-option
                  v-for="item in attributesGroup"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-button @click="addCard">{{ $t('COMPONENTS.add') }}</el-button>
              <el-button v-show="false" @click="saveAttribute">{{ $t('COMPONENTS.save') }}</el-button>
            </el-col>
          </el-row>

          <el-row v-show="customAttribute" :gutter="20">
            <el-col>
              <el-card
                v-for="item in list"
                :key="item.id"
                class="card-box"
                :style="item.styleObject"
              >
                <el-row>
                  <el-col>
                    <i
                      class="el-icon-close"
                      style="float: right; margin-top: -10px; margin-left: 5px"
                      @click="deleteCard(item.id)"
                    />
                    <i
                      class="el-icon-minus"
                      style="float: right; margin-top: -10px"
                    />
                    <i
                      v-show="item.showUp"
                      class="el-icon-arrow-up"
                      style="float: right; margin-top: -10px; margin-right: 5px"
                      @click="showRow(item)"
                    />
                    <i
                      v-show="item.showDown"
                      class="el-icon-arrow-down"
                      style="float: right; margin-top: -10px; margin-right: 5px"
                      @click="hideRow(item)"
                    />
                  </el-col>
                </el-row>
                <el-row v-show="item.isShow" :gutter="20">
                  <el-col style="padding-top: 20px">
                    <el-col :span="8">
                      <el-form-item :label="$t('productItem.attributeTitle')">
                        <el-autocomplete
                          ref="attrs_demo"
                          v-model="item.attrTitle"
                          :fetch-suggestions="querySearchAsync"
                          :placeholder="$t('COMPONENTS.incontent')"
                          @select="handleSelect(item.attrTitle, item)"
                        />
                      </el-form-item>

                      <el-form-item :label="$t('productItem.attribute')">
                        <div
                          style="
                            border: 1px solid #d4d4d4;
                            height: 120px;
                            width: 580px;
                            padding: 10px;
                          "
                        >
                          <el-tag
                            v-for="tag in item.attributeContents"
                            :key="tag"
                            closable
                            :disable-transitions="false"
                            @close="handleClose(item, tag)"
                          >
                            {{ tag }}
                          </el-tag>
                          <el-input
                            v-if="item.inputVisible"
                            ref="saveTagInput"
                            v-model="item.inputValue"
                            class="input-new-tag"
                            size="small"
                            @keyup.enter.native="handleInputConfirm(item)"
                            @blur="handleInputConfirm(item)"
                          />
                          <el-button
                            v-else
                            class="button-new-tag"
                            size="small"
                            @click="showInput(item)"
                          >+ New Tag</el-button>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('productItem.store')">
          <el-row v-if="vgoodsId===''" :gutter="20">
            <el-col>
              <el-form-item
                v-for="item in list"
                :key="item.id"
                :label="item.attrTitle"
              >
                <el-checkbox-group v-model="item.attributeGroup">
                  <el-checkbox
                    v-for="value in item.attributeContents"
                    :key="value"
                    :label="value"
                    @change="checkAttrValue(item.attrTitle, value)"
                  />
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col>
              <el-form-item :label="$t('productItem.storeInfo')">
                <el-table
                  :data="skuData"
                  tooltipeffect-="dark"
                  style="width: 100%"
                  header-row-class-name="table-header"
                  size="mini"
                >
                  <el-table-column
                    v-for="(item,i) in list"
                    :key="item.attrTitle"
                    :label="item.attrTitle"
                    width="150px"
                    align="left"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.values[i] }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('productItem.price')"
                    prop="itemPrice"
                    width="150px"
                    align="left"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.itemPrice"
                        placeholder=""
                        :value="scope.row.itemPrice"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('productItem.store')"
                    prop="stockQty"
                    width="150px"
                    align="left"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.stockQty"
                        placeholder=""
                        :value="scope.row.stockQty"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('COMPONENTS.operation')"
                    prop="name"
                    width="150px"
                    align="left"
                  >
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.row)"
                      >{{ $t('COMPONENTS.delete') }}</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane v-if="vgoodsId!=''" :label="$t('COMPONENTS.operation')">
          <el-row :gutter="20">
            <el-col>
              <el-form-item
                v-for="item2 in list2"
                :key="item2.id"
                :label="item2.attrTitle"
              >
                <el-checkbox-group v-model="item2.attributeGroup">
                  <el-checkbox
                    v-for="value in item2.attributeContents"
                    :key="value"
                    :label="value"
                    @change="checkAttrValue2(item2.attrTitle, value)"
                  />
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col>
              <el-form-item :label="$t('COMPONENTS.storeInfo')">
                <el-table
                  :data="skuData2"
                  tooltipeffect-="dark"
                  style="width: 100%"
                  header-row-class-name="table-header"
                  size="mini"
                >
                  <el-table-column
                    v-for="(item2,i) in list2"
                    :key="item2.attrTitle"
                    :label="item2.attrTitle"
                    width="150px"
                    align="left"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.values[i] }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('productItem.price')"
                    prop="itemPrice2"
                    width="150px"
                    align="left"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.itemPrice"
                        placeholder="HK$"
                        :value="scope.row.itemPrice"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('productItem.store')"
                    prop="stockQty2"
                    width="150px"
                    align="left"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.stockQty"
                        placeholder=""
                        :value="scope.row.stockQty"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('COMPONENTS.operation')"
                    prop="name"
                    width="150px"
                    align="left"
                  >
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete2(scope.row)"
                      >{{ $t('COMPONENTS.delete') }}</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import {
  getAttributes,
  saveAttributes,
  getTemplate,
  getGroupTemplate,
  productGroupList,
  getVariableSku
} from '@/api/productItem'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'VariableGoodsTabs',
  props: ['vretailPrice', 'vpromotionPrice', 'vpromotionType', 'vpromotionValue', 'vgoodsId', 'vskuData', 'vshopStruct'],
  data() {
    var checkNumber = (rule, value, callback) => {
      var numReg = /^\d+(\.\d+)?$/
      var numRe = new RegExp(numReg)

      if (!numRe.test(value)) {
        return callback(new Error(this.$t('rule.checkNumber')))
      }
    }
    return {
      rules: {
        retailPrice: [
          { required: true, message: this.$t('rule.retailPrice'), trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' }
        ],
        promotionPrice: [
          { required: true, message: this.$t('rule.promotionPrice'), trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' }
        ]
      },
      save: false,
      attributeValues: [],
      query: {
        size: 10000,
        page: 1,
        attrName: '',
        valueName: ''
      },
      variableForm: {
        retailPrice: '',
        promotionPrice: '',
        kcNum: '',
        promotionType: 'DISCOUNT',
        promotionValue: '',
        dValue: '',
        pValue: ''
      },
      attributeTitles: [],
      attributes: [],
      attributesGroup: [],
      skuData: [],
      skuData2: [],
      productAttribute: '',
      customAttribute: true,
      macAttribute: false,
      list: [
        {
          id: 0,
          isShow: true,
          showUp: false,
          showDown: true,
          styleObject: {
            height: '240px'
          },
          attrTitle: '',
          attributeContents: [],
          attributeGroup: [],
          inputVisible: false,
          inputValue: ''
        }
      ],
      list2: [
        {
          id: 0,
          isShow: true,
          showUp: false,
          showDown: true,
          styleObject: {
            height: '240px'
          },
          attrTitle: '',
          attributeContents: [],
          attributeGroup: [],
          inputVisible: false,
          inputValue: ''
        }
      ]
    }
  },
  mounted() {
    const self = this
    self.$nextTick(() => {
      this.loadData()
    })
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    changePromotionType() {
      this.variableForm.dValue = 0
      this.variableForm.pValue = 0
      this.variableForm.promotionPrice = 0
      this.variableForm.promotionValue = 0
    },
    countDValue() {
      var dValue = parseFloat(this.variableForm.dValue).toFixed(2)
      var vretailPrice = this.variableForm.retailPrice
      if (vretailPrice === '') {
        vretailPrice = 0
      }
      var vpromotionPrice = parseFloat(vretailPrice * dValue).toFixed(2)
      this.variableForm.promotionPrice = vpromotionPrice
      this.variableForm.promotionValue = parseFloat(dValue * 10).toFixed(2)
    },
    countPValue() {
      var pValue = parseFloat(this.variableForm.pValue).toFixed(2)
      var vretailPrice = this.variableForm.retailPrice
      if (vretailPrice === '') {
        vretailPrice = 0
      }
      var vpromotionPrice = vretailPrice - pValue
      this.variableForm.promotionPrice = vpromotionPrice
      this.variableForm.promotionValue = pValue
    },
    handleDelete(row) {
      console.log('row:', row)
      this.$confirm('是否确定删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.skuData = this.skuData.filter((t) => t.values !== row.values)
      })
    },
    handleDelete2(row) {
      console.log('row:', row)
      this.$confirm('是否确定删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.skuData2 = this.skuData2.filter((t) => t.values !== row.values)
      })
    },
    checkAttrValue(title, value) {
      console.log('skuData:', this.skuData)
      var array = []
      for (var i = 0; i < this.list.length; i++) {
        var d = this.list[i]
        title = d.attrTitle
        if (d.attributeGroup != null && d.attributeGroup.length > 0) {
          for (var j = 0; j < d.attributeGroup.length; j++) {
            array.push({
              aTitle: title,
              aValue: d.attributeGroup[j]
            })
          }
        } else {
          array.push({
            aTitle: title,
            aValue: ''
          })
        }
      }

      console.log('array', array)

      var titles = []

      if (array.length > 0) {
        titles.push(array[0].aTitle)
      }

      for (i = 0; i < array.length; i++) {
        var obj = array[i]
        var flag = false
        for (j = 0; j < titles.length; j++) {
          if (titles[j] === obj.aTitle) {
            flag = true
            break
          }
        }
        if (!flag) {
          titles.push(obj.aTitle)
        }
      }

      console.log('titles:', titles)

      var array2 = []

      for (i = 0; i < titles.length; i++) {
        title = titles[i]
        var array3 = []
        for (j = 0; j < array.length; j++) {
          if (title === array[j].aTitle) {
            array3.push(title + '_' + array[j].aValue)
          }
        }
        array2.push(array3)
      }

      const arrv = array2

      console.log('arrv', array2)

      const allArr = genSku(...arrv)
      console.log('allArrallArrallArrallArr', allArr)

      this.skuData = []

      for (i = 0; i < allArr.length; i++) {
        var row = []
        array = allArr[i]
        for (j = 0; j < array.length; j++) {
          var v = array[j]

          title = v.split('_')[0]
          value = v.split('_')[1]

          row.push(value)
        }

        this.skuData.push({
          values: row
        })
      }

      console.log('skuData', this.skuData)
    },
    checkAttrValue2(title, value) {
      console.log('skuData2:', this.skuData2)
      var array = []
      for (var i = 0; i < this.list2.length; i++) {
        var d = this.list2[i]
        title = d.attrTitle
        if (d.attributeGroup != null && d.attributeGroup.length > 0) {
          for (var j = 0; j < d.attributeGroup.length; j++) {
            array.push({
              aTitle: title,
              aValue: d.attributeGroup[j]
            })
          }
        } else {
          array.push({
            aTitle: title,
            aValue: ''
          })
        }
      }

      console.log('array', array)

      var titles = []

      if (array.length > 0) {
        titles.push(array[0].aTitle)
      }

      for (i = 0; i < array.length; i++) {
        var obj = array[i]
        var flag = false
        for (j = 0; j < titles.length; j++) {
          if (titles[j] === obj.aTitle) {
            flag = true
            break
          }
        }
        if (!flag) {
          titles.push(obj.aTitle)
        }
      }

      console.log('titles:', titles)

      var array2 = []

      for (i = 0; i < titles.length; i++) {
        title = titles[i]
        var array3 = []
        for (j = 0; j < array.length; j++) {
          if (title === array[j].aTitle) {
            array3.push(title + '_' + array[j].aValue)
          }
        }
        array2.push(array3)
      }

      const arrv = array2

      console.log('arrv', array2)

      const allArr = genSku(...arrv)
      console.log('allArrallArrallArrallArr', allArr)

      this.skuData2 = []

      for (i = 0; i < allArr.length; i++) {
        var row = []
        array = allArr[i]
        for (j = 0; j < array.length; j++) {
          var v = array[j]

          title = v.split('_')[0]
          value = v.split('_')[1]

          row.push(value)
        }

        this.skuData2.push({
          values: row
        })
      }

      var delValue = []

      this.skuData2.forEach(sku2 => {
        var values2 = sku2.values
        var flag = false
        for (var j = 0; j < this.skuData.length; j++) {
          var sku = this.skuData[j]
          var values = sku.values
          if (arrEuqalse(values2, values)) {
            flag = true
            break
          }
        }
        if (flag) {
          delValue.push(values2)
        }
      })

      if (delValue != null && delValue.length > 0) {
        for (i = 0; i < delValue.length; i++) {
          value = delValue[i]
          this.skuData2 = this.skuData2.filter((t) => t.values !== value)
        }
      }

      console.log('skuData2', this.skuData2)
    },
    loadAll() {
      var array = []

      var query = { ...this.query }
      var param = getPageParm(query)

      var attributeNamesArray = []

      getAttributes(param, query).then((resp) => {
        var data = resp.data
        if (data != null) {
          for (var i = 0; i < data.length; i++) {
            var d = data[i]
            array.push({
              id: d.id,
              name: d.name,
              attrValues: d.attrValues
            })
            attributeNamesArray.push({
              id: d.id,
              name: d.name
            })
          }
        }
      })

      this.attributeNamesOptions = attributeNamesArray

      return array
    },
    querySearchAsync(queryString, cb) {
      var results = []

      for (var i = 0; i < this.attributes.length; i++) {
        var attribute = this.attributes[i]

        if (attribute.name.indexOf(queryString) !== -1) {
          results.push({
            value: attribute.name,
            id: attribute.id
          })
        }
      }
      cb(results)
    },
    selProductAttribute() {
      if (this.productAttribute === '') {
        this.save = false
        this.list = [
          {
            id: 0,
            isShow: true,
            showUp: false,
            showDown: true,
            styleObject: {
              height: '240px'
            },
            attrTitle: '',
            attributeContents: [],
            attributeGroup: [],
            inputVisible: false,
            inputValue: ''
          }
        ]
        // this.customAttribute = true;
        // this.macAttribute = false;
      } else {
        this.save = true
        // 商品组模板属性
        getGroupTemplate(this.productAttribute).then((resp) => {
          var data = resp.data
          var templates = data.groupTemplateList
          if (templates != null && templates.length > 0) {
            var array = []
            for (var i = 0; i < templates.length; i++) {
              var obj = templates[i]
              array.push({
                id: i,
                isShow: true,
                showUp: false,
                showDown: true,
                styleObject: {
                  height: '240px'
                },
                attrTitle: obj.attributeNameByGroup,
                attributeContents: obj.attributeValueByGroup,
                attributeGroup: [],
                inputVisible: false,
                inputValue: ''
              })
            }
            this.list = array
            this.list2 = array
          }
        })
        // this.customAttribute = false;
        // this.macAttribute = true;
      }
    },
    handleSelect(attrTitle, item) {
      var array = []
      for (var i = 0; i < this.attributes.length; i++) {
        var attribute = this.attributes[i]
        if (attrTitle === attribute.name) {
          var contents = attribute.attrValues
          for (var j = 0; j < contents.length; j++) {
            var content = contents[j]
            array.push(content.name)
          }
        }
      }
      item.attributeContents = array
    },
    loadData() {
      // setTimeout(() =>{
      this.skuData = this.vskuData
      this.variableForm.retailPrice = this.vretailPrice
      this.variableForm.promotionPrice = this.vpromotionPrice
      this.variableForm.promotionType = this.vpromotionType
      this.variableForm.promotionValue = this.vpromotionValue
      if (this.variableForm.promotionType === 'DISCOUNT') {
        this.variableForm.dValue = parseFloat(this.variableForm.promotionValue * 0.1).toFixed(2)
      } else {
        this.variableForm.pValue = this.variableForm.promotionValue
      }

      this.attributes = this.loadAll()

      var query = { ...this.query }
      var param = getPageParm(query)
      productGroupList(param, query).then((response) => {
        this.attributesGroup = response.data
      })

      if (this.vgoodsId !== '' && this.vshopStruct === 'VARIABLE-ITEM') {
        getTemplate(this.vgoodsId).then((resp) => {
          var data = resp.data
          var templates = data.templateList
          if (templates != null && templates.length > 0) {
            var array = []
            for (var i = 0; i < templates.length; i++) {
              var obj = templates[i]
              array.push({
                id: i,
                isShow: true,
                showUp: false,
                showDown: true,
                styleObject: {
                  height: '240px'
                },
                attrTitle: obj.attributeName,
                isRequired: obj.isRequired,
                isMultple: obj.isMultple,
                attributeContents: obj.attributeValue,
                attributeGroup: [],
                inputVisible: false,
                inputValue: ''
              })
            }
            this.list = array
            this.list2 = array
          }
        })

        // 查询库存
        getVariableSku(this.vgoodsId).then((resp) => {
          var data = resp.data
          var skuList = data.skuList
          console.log('skuList', skuList)

          // 给list的attributeGroup属性赋值用于加载时复选框勾选
          if (this.list != null && this.list.length > 0) {
            for (var i = 0; i < this.list.length; i++) {
              var obj = this.list[i]
              var attributeGroup = obj.attributeGroup

              // 遍历skuList获取复选框值
              for (var j = 0; j < skuList.length; j++) {
                var sku = skuList[j]
                var values = sku.values
                var value = values[i]

                if (attributeGroup.indexOf(value) === -1) {
                  attributeGroup.push(value)
                }
              }
              this.list[i].attributeGroup = attributeGroup
            }
          }

          // 给库存详细添加库存数量和库存价格
          if (skuList != null && skuList.length > 0) {
            this.skuData = []

            for (i = 0; i < skuList.length; i++) {
              obj = skuList[i]
              this.skuData.push({
                itemPrice: obj.itemPrice,
                stockQty: obj.stockQty,
                values: obj.values
              })
            }
          }

          if (this.list2 != null && this.list2.length > 0) {
            for (i = 0; i < this.list2.length; i++) {
              this.list2[i].attributeGroup = []
            }
          }
        })
      }
      // },2000);
    },
    addCard() {
      var length = this.list.length
      var obj = {
        id: length,
        isShow: true,
        showUp: false,
        showDown: true,
        styleObject: {
          height: '240px'
        },
        attrTitle: '',
        attributeContents: [],
        attributeGroup: [],
        inputVisible: false,
        inputValue: ''
      }
      this.list.push(obj)
    },
    hideRow(item) {
      this.$set(item, 'isShow', false)
      item.showUp = true
      item.showDown = false
      item.styleObject = {
        height: '60px'
      }
    },
    showRow(item) {
      this.$set(item, 'isShow', true)
      item.showUp = false
      item.showDown = true
      item.styleObject = {
        height: '240px'
      }
    },
    deleteCard(id) {
      for (var i = 0; i < this.list.length; i++) {
        var did = this.list[i].id
        if (id === did) {
          this.list.splice(id, 1)
        }
      }
    },
    saveAttribute() {
      var vattributeList = []

      for (var i = 0; i < this.list.length; i++) {
        var obj = this.list[i]
        vattributeList.push({
          attrTitle: obj.attrTitle,
          isRequired: obj.isRequired,
          isMultple: obj.isMultple,
          attributeContents: obj.attributeContents
        })
      }

      var query = {
        attributeGroupId: this.productAttribute,
        attributeList: vattributeList
      }

      saveAttributes(query).then((resp) => {
        this.$notify({
          title: '成功',
          message: '属性组保存成功',
          type: 'success'
        })
      })
    },
    handleClose(item, tag) {
      item.attributeContents.splice(item.attributeContents.indexOf(tag), 1)
    },
    showInput(item) {
      item.inputVisible = true
      this.$nextTick(_ => {
        console.log('thiss@@@@@@@@@@@@@', this.$refs, this.$ref)
        this.$refs.saveTagInput[0].focus()
      })
    },
    handleInputConfirm(item) {
      const inputValue = item.inputValue
      if (inputValue) {
        this.$set(item.attributeGroup, [])
        item.attributeContents.push(inputValue)
      }
      item.inputVisible = false
      item.inputValue = ''
    }
  }
}

function arrEuqalse(arr1, arr2) {
  if (!arr1 || !arr2) {
    return false
  }

  if (arr1.length !== arr2.length) {
    return false
  }

  for (var i = 0; i < arr2.length; i++) {
    if (arr1.indexOf(arr2[i]) === -1) {
      return false
    }
  }

  for (i = 0; i < arr1.length; i++) {
    if (arr2.indexOf(arr1[i]) === -1) {
      return false
    }
  }
  return true
}

function genSku() {
  return Array.prototype.reduce.call(
    arguments,
    function(a, b) {
      var ret = []
      a.forEach(function(a) {
        b.forEach(function(b) {
          ret.push(a.concat([b]))
        })
      })
      return ret
    },
    [[]]
  )
}
</script>

<style lang="scss" scoped>
.card-box {
  border: 1px solid #d4d4d4;
  margin: 0.5rem 0.5rem 0;
  height: 240px;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
