<template>
  <div class="app-container">
    <transition name="el-zoom-in-top">
      <div class="filter-container">
        <el-form
          class="search-form-inline"
          label-suffix=":"
          label-position="left"
          hide-required-asterisk
          label-width="150px"
        >
          <el-row :gutter="20">
            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('Sending Method')">
                <el-select
                  v-model="listQuery.sendFromType"
                  clearable
                  :placeholder="$t('COMPONENTS.inselect')"
                  style="width: 100%"
                >
                  <el-option key="SYS_SEND" :label="$t('Administrator Redemption')" value="SYS_SEND" />
                  <el-option key="MEMBER_SEND_SELF" :label="$t('Member Redemption')" value="MEMBER_SEND_SELF" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('Transation ID')">
                <el-input
                  v-model="listQuery.transationId"
                  clearable
                  class="filter-item"
                />
              </el-form-item>
            </el-col>

          </el-row>
          <el-row :gutter="20">
            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('Member Account')">
                <el-input
                  v-model="listQuery.memberAccount"
                  clearable
                  class="filter-item"
                />
              </el-form-item>
            </el-col>

            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('Coupon Code')">
                <el-input
                  v-model="listQuery.couponCode"
                  clearable
                  class="filter-item"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :xl="8" :md="10" :sm="12" :xs="24">
              <el-form-item :label="$t('COMPONENTS.time')">
                <el-date-picker
                  v-model="listQuery.date"
                  clearable
                  type="daterange"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>

            <div style="float: right;">
              <el-button type="primary" :disabled="checkPermission('COUPON.SEARCH')" @click="searchJob">{{ $t("COMPONENTS.search") }}</el-button>
            </div>

          </el-row>
        </el-form>
      </div>
    </transition>

    <!-- 查询结果 -->
    <el-table
      header-row-class-name="table-header"
      :data="list"
      size="small"
      border
      fit
      highlight-current-row
    >

      <el-table-column :label="$t('Transation ID')" prop="transationId" width="140" />
      <el-table-column :label="$t('Sending Method')" prop="sendFromType" width="190">
        <template slot-scope="scope">
          <span v-if="scope.row.sendFromType === 'SYS_SEND'">{{ $t('Administrator Redemption') }}</span>
          <span v-if="scope.row.sendFromType === 'MEMBER_SEND_SELF'">{{ $t('Member Redemption') }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Coupon Code')" prop="couponCode" width="150" />
      <el-table-column :label="$t('Issued By')" prop="issuedBy" width="150" />
      <el-table-column :label="$t('Member Login Name')" prop="memberLoginName" width="150" />
      <el-table-column :label="$t('COMPONENTS.time')" prop="createdDate" min-width="150" />
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.size"
      @pagination="searchData"
    />

    <el-tooltip placement="top" :content="$t('COMPONENTS.return-top')">
      <back-to-top :visibility-height="100" />
    </el-tooltip>
  </div>
</template>

<script>
import { adminFoundAllCouponLog } from '@/api/couponApi'
import { getPageParm } from '@/utils/page-common'
import Pagination from '@/components/Pagination'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'CouponHistory',
  components: { Pagination },
  data() {
    return {
      list: [],
      shopOptions: [],
      statusOptions: [],
      total: 0,
      listQuery: {
        page: 1,
        size: 10,
        sendFromType: null,
        transationId: null,
        memberAccount: null,
        couponCode: null,
        date: null
      }
    }
  },
  created() {
    this.searchData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },

    searchData() {
      const query = { ...this.listQuery }
      const param = getPageParm(query)
      adminFoundAllCouponLog(param, query).then((response) => {
        const list = response.data
        this.list = list
        this.total = response.pageCount
      })
        .catch(() => {
          this.list = []
          this.total = 0
        })
    },
    isNull(data) {
      return data === null || data === '' || data === undefined
    },
    searchJob() {
      this.listQuery.page = 1
      this.searchData()
    },
    errorMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.error'),
        message: message,
        type: 'error'
      })
    },
    successMsg(message) {
      this.$notify({
        title: this.$t('COMPONENTS.success'),
        message: message,
        type: 'success'
      })
    }
  }
}
</script>
