import request from '@/utils/request'
import { API_SERVER_URL } from '../Config'

export function findAllRemarkTplList(param, query) {
  return request({
    url: API_SERVER_URL + '/api/admin/remarkTpl/searchAll' + param,
    method: 'post',
    data: query
  })
}

export function listAllRemarkTplList(param, query) {
  return request({
    url: API_SERVER_URL + '/api/admin/remarkTpl/listAll' + param,
    method: 'post',
    data: query
  })
}

export function findAllRamrkTpl() {
  return request({
    url: API_SERVER_URL + '/api/admin/remarkTpl/searchAllRemarkType',
    method: 'post'
  })
}

export function findById(id) {
  return request({
    url: API_SERVER_URL + '/api/admin/remarkTplById/' + id,
    method: 'get'
  })
}

export function saveRemarkTpl(param) {
  return request({
    url: API_SERVER_URL + '/api/admin/remarkTpl/save',
    method: 'post',
    data: param
  })
}

export function deleteById(id) {
  return request({
    url: API_SERVER_URL + '/api/admin/deleteRemarkTpl/' + id,
    method: 'post'
  })
}

