import request from '@/utils/request'

import { API_BASE_URL_PRODUCT } from '../Config'

export function fetchList() {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/findAll',
    method: 'get'
  })
}

export function adminSearchList(param, quary) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/product/attr/findAll' + param,
    method: 'post',
    data: quary
  })
}

export function saveAttr(attr) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/save',
    method: 'post',
    data: attr
  })
}

export function findById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/find/' + id,
    method: 'get'
  })
}

export function adminFindById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/product/attr/find/' + id,
    method: 'get'
  })
}

export function deleteAttrValById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attrVal/del/' + id,
    method: 'delete'
  })
}

export function deleteAttrById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/del/' + id,
    method: 'delete'
  })
}

export function optionList() {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/list',
    method: 'get'
  })
}

export function adminListAll() {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/product/attr/list',
    method: 'get'
  })
}

export function saveAttrValue(req) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/attrvalue/save',
    method: 'post',
    data: req
  })
}

export function deleteByAttrId(ids) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attrGroup/del',
    method: 'delete',
    data: ids
  })
}

export function saveAttrCopy(data) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/product/attr/attrAddCopy/save',
    method: 'post',
    data: data
  })
}
