import request from '@/utils/request'
import { API_CFS_SERVICE_URL } from '../../Config'

export function getCfsDomainData(data) {
  return request({
    url: API_CFS_SERVICE_URL + '/service/domainData/search',
    method: 'post',
    data: data
  })
}

