import request from '@/utils/request'
import { API_BASE_URL_MEMBER } from '../Config'

export function noticeList(param, query) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/findNoticeByTitle' + param,
    method: 'post',
    data: query
  })
}

export function mbNoticeSave(save) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/noticeAdd',
    method: 'post',
    data: save
  })
}

export function deleteById(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/deleteById/' + id,
    method: 'get'
  })
}
