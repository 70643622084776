var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "page-header", attrs: { span: 12 } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.add
                        ? _vm.$t("attributeGroup.Add-Attribute")
                        : _vm.$t("attributeGroup.Edit-Attribute")
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v(_vm._s(_vm.$t("COMPONENTS.return"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("attributeGroup.add"),
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "card-box" },
        [
          _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "search-form-inline",
                    attrs: {
                      "label-suffix": ":",
                      "label-position": "left",
                      "hide-required-asterisk": "",
                      "label-width": "75px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xl: 4, md: 6, sm: 12, xs: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: _vm.$t("attributeGroup.name") },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "attributeGroup.Please-enter-a-title-parameter-group-name"
                                    ),
                                  },
                                  model: {
                                    value: _vm.groupName,
                                    callback: function ($$v) {
                                      _vm.groupName = $$v
                                    },
                                    expression: "groupName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            [
              _c(
                "div",
                { staticClass: "btn-titles" },
                [
                  _c("p", { staticClass: "text-title" }, [
                    _vm._v(_vm._s(_vm.$t("attributeGroup.Attribute-list"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-add",
                      on: {
                        click: function ($event) {
                          return _vm.attributeAdd()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    "tooltip-effect": "dark",
                    "header-row-class-name": "table-header",
                    size: "mini",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "60px", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("attribute.attrName"),
                      prop: "name",
                      align: "left",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("attributeGroup.Options-count"),
                      prop: "num",
                      align: "left",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("attributeGroup.isVariable"),
                      prop: "isVariable",
                      align: "left",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("attributeGroup.Required"),
                      prop: "mandatoryInd",
                      align: "left",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("COMPONENTS.operation"),
                      prop: "name",
                      width: "350px",
                      align: "left",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.copy
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "filter-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.dltcopy(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("COMPONENTS.copy")))]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticClass: "filter-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.dltsave(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                                ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "filter-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.dltId(scope.row, scope.$index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.delete")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "filter-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.dltedit(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.save
              ? _vm.$t("attributeGroup.Add-Attribute")
              : _vm.$t("attributeGroup.Edit-Attribute"),
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-lable", { staticStyle: { color: "#808080" } }, [
                    _vm._v(_vm._s(_vm.$t("attribute.attrName"))),
                  ]),
                  _vm._v(" "),
                  _c("el-autocomplete", {
                    attrs: {
                      "fetch-suggestions": _vm.querySearchAsync,
                      placeholder: _vm.$t("COMPONENTS.incontent"),
                      clearable: "",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.attr.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.attr, "name", $$v)
                      },
                      expression: "attr.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("attributeGroup.Type") } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "0" },
                              model: {
                                value: _vm.multpleSelectInd,
                                callback: function ($$v) {
                                  _vm.multpleSelectInd = $$v
                                },
                                expression: "multpleSelectInd",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("COMPONENTS.One-choice")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.multpleSelectInd,
                                callback: function ($$v) {
                                  _vm.multpleSelectInd = $$v
                                },
                                expression: "multpleSelectInd",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("COMPONENTS.Multiple-choice"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("attributeGroup.isVariable") },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "0" },
                              model: {
                                value: _vm.isVariable,
                                callback: function ($$v) {
                                  _vm.isVariable = $$v
                                },
                                expression: "isVariable",
                              },
                            },
                            [_vm._v("NO")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.isVariable,
                                callback: function ($$v) {
                                  _vm.isVariable = $$v
                                },
                                expression: "isVariable",
                              },
                            },
                            [_vm._v("YES")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("attributeGroup.Required") } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "0" },
                              model: {
                                value: _vm.mandatoryInd,
                                callback: function ($$v) {
                                  _vm.mandatoryInd = $$v
                                },
                                expression: "mandatoryInd",
                              },
                            },
                            [_vm._v("NO")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.mandatoryInd,
                                callback: function ($$v) {
                                  _vm.mandatoryInd = $$v
                                },
                                expression: "mandatoryInd",
                              },
                            },
                            [_vm._v("YES")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.attr.attrValues },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("attributeGroup.Seq"),
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "attrValues." + scope.$index + ".seqNo",
                                    },
                                  },
                                  [
                                    scope.row.edit
                                      ? _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: { clearable: "" },
                                          model: {
                                            value: scope.row.seqNo,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "seqNo", $$v)
                                            },
                                            expression: "scope.row.seqNo",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(scope.row.seqNo)),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("attribute.attrName"),
                          width: "200",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  [
                                    scope.row.edit
                                      ? _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          model: {
                                            value: scope.row.name,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "name", $$v)
                                            },
                                            expression: "scope.row.name",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(scope.row.name)),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("COMPONENTS.operation"),
                          width: "200",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-button", {
                                  attrs: { icon: "el-icon-delete", circle: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delRow(scope.$index, scope.row)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-button", {
                                  attrs: { icon: "el-icon-edit", circle: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editRow(scope.row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-circle-plus" },
                    on: {
                      click: function ($event) {
                        return _vm.addLanguage()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.AttrSave()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("COMPONENTS.sure")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }