import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'

export function mgProductGoodsList(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/queryAll',
    method: 'get',
    data: query
  })
}


export function mgProductGoodsListByRemark(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/mgProductGoods/listByRemark/'+ id,
    method: 'get'
  })
}
