import request from '@/utils/request'

import { API_BASE_URL_MEMBER, API_SERVER_URL } from '../Config'

export function imgUpload(data) {
  return request({
    url: API_BASE_URL_MEMBER + '/api/file/upload',
    method: 'post',
    data: data
  })
}

export function imgUploadToOrderService(data) {
  return request({
    url: API_SERVER_URL + '/api/file/upload',
    method: 'post',
    data: data
  })
}
