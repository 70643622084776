var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-top" } },
        [
          _c(
            "el-form",
            {
              staticClass: "search-form-inline",
              attrs: {
                "label-suffix": ":",
                "label-position": "left",
                "hide-required-asterisk": "",
                "label-width": "158px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Ingredient Name") } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { "max-width": "210px" },
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.query.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "name", $$v)
                              },
                              expression: "query.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Status") } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.query.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "status", $$v)
                                },
                                expression: "query.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Ingredient Category (Product Page)"),
                          },
                        },
                        [
                          _c("el-select-tree", {
                            attrs: {
                              clearable: "",
                              data: _vm.ingredientProductTreeData,
                              multiple: false,
                              "check-strictly": "",
                              "check-on-click-node": "",
                              "render-after-expand": false,
                            },
                            model: {
                              value: _vm.query.defaultIngredientProductTreeId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.query,
                                  "defaultIngredientProductTreeId",
                                  $$v
                                )
                              },
                              expression:
                                "query.defaultIngredientProductTreeId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 6, md: 8, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "Ingredient Category (Daily Menu Page)"
                            ),
                          },
                        },
                        [
                          _c("el-select-tree", {
                            attrs: {
                              clearable: "",
                              data: _vm.ingredientDailyMenuTreeData,
                              multiple: false,
                              "check-strictly": "",
                              "check-on-click-node": "",
                              "render-after-expand": false,
                            },
                            model: {
                              value: _vm.query.defaultIngredientDailyMenuTreeId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.query,
                                  "defaultIngredientDailyMenuTreeId",
                                  $$v
                                )
                              },
                              expression:
                                "query.defaultIngredientDailyMenuTreeId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-row", { staticStyle: { margin: "5px" } }, [
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.checkPermission("INGREDIENT.ADD"),
                        },
                        on: { click: _vm.handleCreate },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("COMPONENTS.add")) + "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.checkPermission("INGREDIENT.LIST"),
                          type: "primary",
                        },
                        on: { click: _vm.search },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("COMPONENTS.search")) + "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            "tooltip-effect": "dark",
            data: _vm.data,
            "header-row-class-name": "table-header",
            size: "mini",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Ingredient Name"),
              prop: "name",
              width: "250px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Image"),
              prop: "imgUrl",
              width: "150px",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.imgUrl
                      ? _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: row.mediaUrl, fit: "cover" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !row.imgUrl ? _c("span", [_vm._v("-")]) : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Ingredient Category (Product Page)"),
              prop: "defaultIngredientProductTreeId",
              width: "250px",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.defaultIngredientProductTreeId
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.renderCategoryName(
                                  _vm.ingredientProductTreeData,
                                  row.defaultIngredientProductTreeId
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Ingredient Category (Daily Menu Page)"),
              prop: "lastName",
              width: "250px",
              align: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.defaultIngredientDailyMenuTreeId
                      ? _c("div", [
                          row.defaultIngredientDailyMenuTreeId
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.renderCategoryName(
                                        _vm.ingredientDailyMenuTreeData,
                                        row.defaultIngredientDailyMenuTreeId
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("productItem.lastModifiedDate"),
              prop: "lastModifiedDate",
              width: "150px",
              align: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Status"), align: "left", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status === "A"
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("Active")) + " ")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.status === "I"
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("Inactive")) + " ")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("COMPONENTS.operation"),
              fixed: "right",
              width: "180px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.checkPermission("INGREDIENT.EDIT"),
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.$index, scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")) + "\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.checkPermission("INGREDIENT.DELETE"),
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.$index, scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("COMPONENTS.delete")) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.query.total > 0,
                    expression: "query.total > 0",
                  },
                ],
                staticClass: "hidden-xs-only",
                attrs: {
                  total: _vm.query.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.loadData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                staticClass: "hidden-sm-and-up",
                attrs: {
                  "hide-on-single-pag": _vm.query.total > 0,
                  total: _vm.query.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  "pager-count": 7,
                  layout: "pager, prev, next",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.loadData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }