<template>
  <div class="simple-goods-tabs">
    <el-tabs tab-position="left">
      <template>
        <el-tab-pane :label="$t('productItem.basic_price')">
          <el-form ref="simpleForm" :model="simpleForm" :rules="rules">
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.retailPrice')" prop="retailPrice">
                  <el-input v-model="simpleForm.retailPrice" placeholder="" @change="onChangeRetailPrice($event)" @input="onChangeRetailPrice($event)">
                    <template slot="prepend">HK$</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!--
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.discount_information')">
                  <el-select v-model="simpleForm.promotionType" style="width:180px" @change="changePromotionType">
                    <el-option :label="$t('productItem.NODISCOUNT')" value="NODISCOUNT" />
                    <el-option :label="$t('productItem.DISCOUNT')" value="DISCOUNT" />
                    <el-option :label="$t('productItem.PRICE_REDUCTION')" value="PRICE_REDUCTION" />
                  </el-select>
                  <el-input-number v-if="simpleForm.promotionType=='DISCOUNT'" v-model="simpleForm.dValue" :precision="2" :step="0.01" :max="0.99" style="width:150px" @change="countDValue" />
                  <el-input-number v-else v-model="simpleForm.pValue" :precision="2" :step="0.01" :max="10000" style="width:150px" @change="countPValue" />
                  <label v-if="simpleForm.promotionType=='DISCOUNT'">{{ $t('productItem.play') }}{{ simpleForm.promotionValue }}{{ $t('productItem.fold') }}</label>
                  <label v-else>{{ $t('productItem.REDUCTION') }}{{ simpleForm.promotionValue }}{{ $t('productItem.RMB') }}</label>
                  <br>
                  <span style="color:red;">{{ $t('productItem.DISCOUNTINFO') }}</span>
                </el-form-item>
              </el-col>
            </el-row>
 -->

            <el-row :key="componentKey9" :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.promotionPrice')" prop="promotionPrice">
                  <el-input v-model="simpleForm.promotionPrice" placeholder="">
                    <template slot="prepend">HK$</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xl="8" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('productItem.kcNum')">
                  <el-input v-model="simpleForm.kcNum" placeholder="" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('productItem.attribute')" :disabled="checkPermission('SHOP_PRODUCT_ATTRIBUTE')">
          <el-row :gutter="20">
            <el-col>
              <el-select v-model="productAttribute" :placeholder="$t('COMPONENTS.inselect')" style="margin-left:0.5rem" @change="selProductAttribute">
                <el-option :label="$t('productItem.custom-attribute')" value="" />
                <el-option v-for="item in attributesGroup" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
              <el-button @click="addCard">{{ $t('COMPONENTS.add') }}</el-button>
              <el-button v-show="false" @click="saveAttribute">{{ $t('COMPONENTS.save') }}</el-button>
            </el-col>
          </el-row>

          <el-row v-show="customAttribute" :gutter="20">
            <el-col>
              <el-card v-for="item in list" :key="item.id" class="card-box" :style="item.styleObject">
                <el-row>
                  <el-col>
                    <i class="el-icon-close" style="float:right;margin-top:-10px;margin-left:5px" @click="deleteCard(item.id)" />
                    <i class="el-icon-minus" style="float:right;margin-top:-10px" />
                    <i v-show="item.showUp" class="el-icon-arrow-up" style="float:right;margin-top:-10px;margin-right:5px" @click="showRow(item)" />
                    <i v-show="item.showDown" class="el-icon-arrow-down" style="float:right;margin-top:-10px;margin-right:5px" @click="hideRow(item)" />
                  </el-col>
                </el-row>
                <el-row v-show="item.isShow" :gutter="20">
                  <el-col style="padding-top:20px;">
                    <el-col :span="8">
                      <span style="font-size: 14px;color: #606266;font-weight: 700;">{{ $t('productItem.attributeTitle') }}</span>
                      <el-autocomplete
                        ref="attrs_demo"
                        v-model="item.attrTitle"
                        :fetch-suggestions="querySearchAsync"
                        :placeholder="$t('COMPONENTS.incontent')"
                        @select="handleSelect(item.attrTitle,item)"
                      />
                      <el-form-item :label="$t('productItem.isRequired')" style="margin-top:15px">
                        <el-radio-group v-model="item.isRequired">
                          <el-radio label="0">{{ $t('COMPONENTS.true') }}</el-radio>
                          <el-radio label="1">{{ $t('COMPONENTS.false') }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item :label="$t('productItem.isMultple')" style="margin-top:15px">
                        <el-radio-group v-model="item.isMultple">
                          <el-radio label="0">{{ $t('COMPONENTS.true') }}</el-radio>
                          <el-radio label="1">{{ $t('COMPONENTS.false') }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <span style="font-size: 14px;color: #606266;font-weight: 700;">{{ $t('productItem.attribute') }}</span>
                      <br>
                      <div style="border:1px solid #d4d4d4;height:140px;width:580px;padding:10px">
                        <el-tag
                          v-for="tag in item.attributeContents"
                          :key="tag"
                          closable
                          :disable-transitions="false"
                          @close="handleClose(item,tag)"
                        >
                          {{ tag }}
                        </el-tag>
                        <el-input
                          v-if="item.inputVisible"
                          ref="saveTagInput"
                          v-model="item.inputValue"
                          class="input-new-tag"
                          size="small"
                          @keyup.enter.native="handleInputConfirm(item)"
                          @blur="handleInputConfirm(item)"
                        />
                        <el-button v-else class="button-new-tag" size="small" @click="showInput(item)">+ New Tag</el-button>
                      </div>
                    </el-col>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import {
  getAttributes,
  saveAttributes,
  getTemplate,
  getGroupTemplate,
  productGroupList
} from '@/api/productItem'
import { getPageParm } from '@/utils/page-common'
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'SimpleGoodsTabs',
  props: ['sretailPrice', 'spromotionPrice', 'spromotionType', 'spromotionValue', 'skuNum', 'goodsId', 'skuItems', 'shopStruct'],
  data() {
    const checkRetailPrice = (rule, value, callback) => {
      console.log('checkRetailPrice', value)
      const isNull = value === undefined || value === null || value === ''
      if (isNull) {
        return callback(new Error(this.$t('rule.retailPrice')))
      }

      const isNaN = Number.isNaN(value)
      if (isNaN) {
        return callback(new Error(this.$t('Please enter the number')))
      }

      const isLessThenZero = Number(value) < 0
      if (isLessThenZero) {
        return callback(new Error(this.$t('Cannot be less than 0')))
      }

      return callback()
    }
    const checkPromotionPrice = (rule, value, callback) => {
      console.log('checkPromotionPrice', value)

      const isNull = value === undefined || value === null || value === ''
      if (isNull) {
        return callback(new Error(this.$t('rule.promotionPrice')))
      }

      const isNaN = Number.isNaN(value)
      if (isNaN) {
        return callback(new Error(this.$t('Please enter the number')))
      }

      const isLessThenZero = Number(value) < 0
      if (isLessThenZero) {
        return callback(new Error(this.$t('Cannot be less than 0')))
      }

      if (Number(this.simpleForm.retailPrice) < Number(value)) {
        return callback(new Error(this.$t('Cannot be bigger than the original price'))) // 不能大於原價
      }

      return callback()
    }

    return {
      componentKey9: 0,
      rules: {
        retailPrice: [
          { required: true, validator: checkRetailPrice, trigger: 'change' }
        ],
        promotionPrice: [
          { required: true, validator: checkPromotionPrice, trigger: 'change' }
        ]
        // kcNum: [
        //   { required: true, message: this.$t('rule.kcNum'), trigger: 'blur' },
        //   { validator: checkNumber, trigger: 'blur' }
        // ]
      },
      query: {
        size: 10000,
        page: 1,
        attrName: '',
        valueName: ''
      },
      simpleForm: {
        retailPrice: '',
        promotionPrice: '',
        kcNum: '',
        promotionType: 'DISCOUNT',
        promotionValue: '',
        dValue: '',
        pValue: ''
      },
      activeName: '',
      save: false,
      attributes: [],
      attributesGroup: [],
      productAttribute: '',
      customAttribute: true,
      macAttribute: false,
      list: [
        { 'id': 0,
          'isShow': true,
          'showUp': false,
          'showDown': true,
          'styleObject': {
            height: '240px'
          },
          'attrTitle': '',
          'isRequired': '0',
          'isMultple': '0',
          'attributeContents': [],
          'inputVisible': false,
          'inputValue': ''
        }
      ]
    }
  },
  watch: {
    $refs: {
      deep: true,
      handler(val) {
        console.log(val, '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
      }
    }
  },
  mounted() {
    const self = this
    self.$nextTick(() => {
      this.loadData()
    })
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    forceRerender() {
      this.componentKey9 += 1
    },
    onChangeRetailPrice(price) {
      this.simpleForm.promotionPrice = price
      this.forceRerender()
    },
    loadAll() {
      var array = []
      var query = { ...this.query }
      var param = getPageParm(query)
      getAttributes(param, query).then((resp) => {
        var data = resp.data
        if (data !== null) {
          for (var i = 0; i < data.length; i++) {
            var d = data[i]
            array.push({
              id: d.id,
              name: d.name,
              attrValues: d.attrValues
            })
          }
        }
      })

      return array
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    querySearchAsync(queryString, cb) {
      var results = []

      for (var i = 0; i < this.attributes.length; i++) {
        var attribute = this.attributes[i]

        if (attribute.name.indexOf(queryString) !== -1) {
          results.push({
            value: attribute.name,
            id: attribute.id
          })
        }
      }
      cb(results)
    },
    selProductAttribute() {
      if (this.productAttribute === '') {
        this.save = false
        this.list = [
          { 'id': 0,
            'isShow': true,
            'showUp': false,
            'showDown': true,
            'styleObject': {
              height: '240px'
            },
            'attrTitle': '',
            'attributeContents': [],
            'inputVisible': false,
            'inputValue': '',
            'isMultple': '0',
            'isRequired': '0'
          }
        ]
        // this.customAttribute = true;
        // this.macAttribute = false;
      } else {
        this.save = true
        // 商品组模板属性
        getGroupTemplate(this.productAttribute).then((resp) => {
          var data = resp.data
          var templates = data.groupTemplateList
          if (templates !== null && templates.length > 0) {
            var array = []
            for (var i = 0; i < templates.length; i++) {
              var obj = templates[i]
              array.push({
                'id': i,
                'isShow': true,
                'showUp': false,
                'showDown': true,
                'styleObject': {
                  height: '240px'
                },
                'attrTitle': obj.attributeNameByGroup,
                'attributeContents': obj.attributeValueByGroup,
                'inputVisible': false,
                'inputValue': '',
                'isMultple': '0',
                'isRequired': '0'
              })
            }
            this.list = array
          }
        })
        // this.customAttribute = false;
        // this.macAttribute = true;
      }
      console.log('customAttribute:' + this.customAttribute + 'macAttribute:' + this.macAttribute)
    },
    changePromotionType() {
      this.simpleForm.dValue = 0
      this.simpleForm.pValue = 0
      this.simpleForm.promotionPrice = 0
      this.simpleForm.promotionValue = 0
    },
    countDValue() {
      var dValue = parseFloat(this.simpleForm.dValue).toFixed(2)
      var vretailPrice = this.simpleForm.retailPrice
      if (vretailPrice === '') {
        vretailPrice = 0
      }
      var vpromotionPrice = parseFloat(vretailPrice * dValue).toFixed(2)
      this.simpleForm.promotionPrice = vpromotionPrice
      this.simpleForm.promotionValue = parseFloat(dValue * 10).toFixed(2)
    },
    countPValue() {
      var pValue = parseFloat(this.simpleForm.pValue).toFixed(2)
      var vretailPrice = this.simpleForm.retailPrice
      if (vretailPrice === '') {
        vretailPrice = 0
      }
      var vpromotionPrice = vretailPrice - pValue
      this.simpleForm.promotionPrice = vpromotionPrice
      this.simpleForm.promotionValue = pValue
    },
    handleSelect(attrTitle, item) {
      // console.log("attrTitle:"+attrTitle+";item:"+item);
      var array = []
      for (var i = 0; i < this.attributes.length; i++) {
        var attribute = this.attributes[i]
        if (attrTitle === attribute.name) {
          var contents = attribute.attrValues
          for (var j = 0; j < contents.length; j++) {
            var content = contents[j]
            array.push(content.name)
          }
        }
      }
      item.attributeContents = array
      item.isRequired = '0'
      item.isMultple = '0'
    },
    loadData() {
      // setTimeout(() =>{
      this.simpleForm.retailPrice = this.sretailPrice
      this.simpleForm.promotionPrice = this.spromotionPrice
      if (this.spromotionType) {
        this.simpleForm.promotionType = 'NODISCOUNT'
      } else {
        this.simpleForm.promotionType = this.spromotionType
      }
      this.simpleForm.promotionValue = this.spromotionValue
      if (this.simpleForm.promotionType === 'DISCOUNT') {
        this.simpleForm.dValue = parseFloat(this.simpleForm.promotionValue * 0.1).toFixed(2)
      } else {
        this.simpleForm.pValue = this.simpleForm.promotionValue
      }
      this.simpleForm.kcNum = this.skuNum
      var vgoodsId = this.goodsId

      this.attributes = this.loadAll()

      var query = { ...this.query }
      var param = getPageParm(query)
      productGroupList(param, query).then((response) => {
        this.attributesGroup = response.data
      })

      // 商品属性
      if (vgoodsId !== '' && this.shopStruct === 'SIMPLE-ITEM') {
        getTemplate(vgoodsId).then((resp) => {
          var data = resp.data
          var templates = data.templateList
          if (templates !== null && templates.length > 0) {
            var array = []
            for (var i = 0; i < templates.length; i++) {
              var obj = templates[i]
              array.push({
                'id': i,
                'isShow': true,
                'showUp': false,
                'showDown': true,
                'styleObject': {
                  height: '240px'
                },
                'attrTitle': obj.attributeName,
                'isRequired': obj.isRequired,
                'isMultple': obj.isMultple,
                'attributeContents': obj.attributeValue,
                'inputVisible': false,
                'inputValue': ''
              })
            }
            this.list = array
          }
        })
      }

      // },2000);
    },
    addCard() {
      var length = this.list.length
      var obj = {
        'id': length,
        'isShow': true,
        'showUp': false,
        'showDown': true,
        'styleObject': {
          height: '240px'
        },
        'attrTitle': '',
        'isRequired': '1',
        'isMultple': '1',
        'attributeContents': [],
        'inputVisible': false,
        'inputValue': ''
      }
      this.list.push(obj)
    },
    hideRow(item) {
      this.$set(item, 'isShow', false)
      item.showUp = true
      item.showDown = false
      item.styleObject = {
        height: '60px'
      }
    },
    showRow(item) {
      this.$set(item, 'isShow', true)
      item.showUp = false
      item.showDown = true
      item.styleObject = {
        height: '240px'
      }
    },
    deleteCard(id) {
      for (var i = 0; i < this.list.length; i++) {
        var did = this.list[i].id
        if (id === did) {
          this.list.splice(id, 1)
        }
      }
    },
    saveAttribute() {
      var vattributeList = []

      for (var i = 0; i < this.list.length; i++) {
        var obj = this.list[i]
        vattributeList.push({
          attrTitle: obj.attrTitle,
          isRequired: obj.isRequired,
          isMultple: obj.isMultple,
          attributeContents: obj.attributeContents
        })
      }

      var query = {
        attributeGroupId: this.productAttribute,
        attributeList: vattributeList
      }

      saveAttributes(query).then((resp) => {
        this.$message('属性组保存成功')
      })
    },
    handleClose(item, tag) {
      item.attributeContents.splice(item.attributeContents.indexOf(tag), 1)
    },
    showInput(item) {
      item.inputVisible = true

      this.$nextTick(_ => {
        console.log('thiss@@@@@@@@@@@@@', this.$refs, this.$ref)
        this.$refs.saveTagInput[0].focus()
      })
    },
    handleInputConfirm(item) {
      const inputValue = item.inputValue
      if (inputValue) {
        item.attributeContents.push(inputValue)
      }
      item.inputVisible = false
      item.inputValue = ''
    }
  }
}

</script>

<style lang="scss" scoped>

.card-box {
  border:1px solid #d4d4d4;
  margin: 0.5rem 0.5rem 0;
  height: 240px;
  .card-title {
    font-weight: bold;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px #d4d4d4 solid;
  }
  .commodity-structure {
    min-height: 300px;
  }
}
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

</style>
