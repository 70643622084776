import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'

export function categoryList() {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/categoryTree',
    method: 'get'
  })
}

export function categoryListByCfs() {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/categoryListByCfs',
    method: 'get'
  })
}

export function categoryListCount(name) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/menuCategoryCount?name=' + name,
    method: 'get'
  })
}

export function categoryListCountByCfs(name) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/menuCategoryCountByCfs?name=' + name,
    method: 'get'
  })
}

export function treeByParentByCfs(treeId, itemParentId) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/treeByParentByCfs/' + treeId + '/' + itemParentId,
    method: 'get'
  })
}

export function treeByParent(treeId, itemParentId) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/treeByParent/' + treeId + '/' + itemParentId,
    method: 'get'
  })
}

export function deleteByIdFromCfs(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/deleteByIdFromCfs/' + id,
    method: 'get'
  })
}


export function deleteById(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/deleteById/' + id,
    method: 'get'
  })
}

export function findByNameLike(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/findByNameLike',
    method: 'get',
    data: query
  })
}

export function TreeList(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/menuCategoryRule',
    method: 'get',
    data: query
  })
}

export function updateVisible(data) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/public/admin/updateVisible',
    method: 'post',
    data: data
  })
}
