<template>
  <div>
    <sticky :z-index="10" :sticky-bottom="100" class-name="sub-navbar">
      <el-row>
        <el-col :span="10" class="page-header">{{
          $t("navbar.mall_Manage.daily_Manage.label")
        }}</el-col>
        <el-col :span="14">
          <el-button
            style="margin-left: 10px"
            type="success"
            :disabled="checkPermission('plan.add')"
            @click="submitBtn()"
          >{{ $t("COMPONENTS.save") }}</el-button>
        </el-col>
      </el-row>
    </sticky>
    <el-row :gutter="20">
      <el-col :offset="1" :span="24">
        <br>
        <span style="font-size: 16px; font-weight: 700;">{{ $t('Category') }} :</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="color:gray; font-size: 10px;">
          (*{{ $t("daily_manage.tips") }}: {{ $t("daily_manage.tips_content") }})
        </span>
      </el-col>
    </el-row>
    <el-form :model="editingRecord" label-width="200px">
      <el-row>
        <el-col :offset="1" :xl="24">
          <el-tree
            ref="tree"
            node-key="id"
            :props="props"
            :data="categories"
            :default-checked-keys="defaultIds"
            :check-strictly="true"
            :default-expand-all="true"
            show-checkbox
            @check="handleCheckChange"
          />
          <br>
          <br>
          <br>
          <br>
          <br>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import Sticky from '@/components/Sticky'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { checkCfsPermission } from '@/utils/permission'
import { getCfsTreeList } from '@/api/cfsApi/cfsTreeLIstApi'
import { saveFunctionDefaultCategory, getFunctionDefaultCategory } from '@/api/functionDefaultCategoryApi'
export default {
  name: 'DaliyEdit',
  components: { Sticky },
  props: {
    attrId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      functionSetting: '',
      categories: [],
      defaultIds: [],
      props: {
        label: 'label',
        children: 'children'
      },
      editingRecord: {
        selectCategoryId: []
      }
    }
  },
  mounted() {
    this.getFunctionType()
    this.loadData()
  },
  methods: {
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    getFunctionType() {
      // 路由有category參數時執行此操作
      if (this.$parent) {
        if (this.$parent.$route) {
          if (this.$parent.$route.meta) {
            if (this.$parent.$route.meta.functionSetting) {
              const functionSetting = this.$parent.$route.meta.functionSetting
              console.log('把路由配置的設定，一起抓過來放到functionSetting', functionSetting)
              this.functionSetting = functionSetting
            }
          }
        }
      }
    },
    async loadData() {
      const res = await getCfsTreeList({ belongId: 'DEFAULT', usageTypeCode: 'category' })
      const res2 = await getFunctionDefaultCategory(this.functionSetting)
      let list = res.data
      const daily = res2.data
      let defaultIds = []
      if (!list) list = []
      if (daily) {
        const ival = daily.itemValue
        if (ival) {
          const ivals = ival.split(',')
          if (ivals && ivals.length > 0) {
            defaultIds = [...ivals]
          }
        }
      }
      const ls = this.formatTreeData(list)
      this.categories = ls
      this.defaultIds = defaultIds
    },
    formatTreeData(list) {
      if (list) {
        list = list.map(category => {
          category.key = category.id
          category.label = category.treeItemTag.name
          if (category.children) {
            const chList = this.formatTreeData(category.children)
            category.children = chList
          }
          return category
        })
      }
      return list
    },
    handleCheckChange(data, checked) {
      const currIds = checked.checkedKeys
      this.setCheckedKeys(data, currIds)
    },
    setCheckedKeys(data, currIds) {
      const ls = this.categories
      const cid = data.id
      const checked = currIds.includes(cid)
      console.log('checked:', checked)
      let allCheckedIds = [...currIds]
      const childIds = this.findCheckedChildKeys(data)
      const parentIds = this.findCheckedParentKeys(data.parentId, ls)
      console.log('parentIds:', parentIds)
      console.log('childIds:', childIds)
      if (checked) {
        allCheckedIds = allCheckedIds.concat([...parentIds, cid, ...childIds])
      } else {
        allCheckedIds = allCheckedIds.filter(d => {
          for (const cid of childIds) {
            if (cid === d) {
              return false
            }
          }
          return true
        })
        allCheckedIds = allCheckedIds.concat([...parentIds])
      }
      const newList = Array.from(new Set(allCheckedIds))
      console.log('newList:', newList)
      this.$refs.tree.setCheckedKeys(newList)
    },
    findCheckedParentKeys(code, tree) {
      let arr = []
      if (code) {
        for (let i = 0; i < tree.length; i++) {
          const item = tree[i]
          arr = []
          arr.push(item.id)
          if (code === item.id) {
            return arr
          } else {
            if (item.children && item.children.length > 0) {
              arr = arr.concat(this.findCheckedParentKeys(code, item.children))
              if (arr.includes(code)) {
                return arr
              }
            }
          }
        }
      }
      return arr
    },
    findCheckedChildKeys(tree) {
      let childList = []
      const children = tree.children
      if (children) {
        for (const child of children) {
          childList.push(child.id)
          const cd = child.children
          if (cd) {
            childList = childList.concat(this.findCheckedChildKeys(child))
          }
        }
      }
      return childList
    },
    handleNodeClick(data) {
      console.log(data)
    },
    submitBtn() {
      const checkedKeys = this.$refs.tree.getCheckedKeys()
      const functionSetting = this.functionSetting
      saveFunctionDefaultCategory(functionSetting, { categories: checkedKeys }).then((resp) => {
        if (resp) {
          this.$notify({
            title: this.$t('COMPONENTS.success'),
            message: this.$t('COMPONENTS.save_Success'),
            type: 'success'
          })
        } else {
          this.$notify({
            title: this.$t('COMPONENTS.tip'),
            message: this.$t('save error'),
            type: 'warning'
          })
          return
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form {
  margin: 2rem 0 0 0;
}
.page-header {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  color: #ffffff;
}
</style>
