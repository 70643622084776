export function checkCfsPermission(value) {
  let hidden = true
  if (value) {
    var permissionList = localStorage.getItem('permissionList')
    if (permissionList) {
      permissionList = JSON.parse(permissionList)
      for (var item of permissionList) {
        if (value === item.permissionName) {
          hidden = false
        }
      }
    }
  }
  return hidden
}

export function checkUserInPartyId(funPartyIds, menuName) {
  let isAllow = false

  if (funPartyIds && funPartyIds.length !== 0) {
    var userPartIds = localStorage.getItem('userPartIds')

    if (userPartIds) {
      userPartIds = JSON.parse(userPartIds)

      for (const funPartId of funPartyIds) {
        if (userPartIds.includes(funPartId)) {
          isAllow = true
        }
      }

      // console.log('checkUserInPartyId User屬於的party id:', userPartIds);
      // console.log('checkUserInPartyId Menu屬於的party id:', funPartyIds);
      console.log('checkUserInPartyId 這個Menu(' + menuName + ')' + (isAllow ? '允許訪問' : '不允許訪問'))
    }
  } else {
    // funPartyIds如果沒有給，那就放寬條件，讓USER可以訪問
    isAllow = true
  }
  return isAllow
}
