import request from '@/utils/request'

import { API_BASE_URL_MEMBER, API_BASE_URL_PRODUCT } from '../Config'

export function couponList() {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/coupon/findAll',
    method: 'get'
  })
}

export function searchList(quary) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/coupon/search',
    method: 'post',
    data: quary
  })
}

export function couponSave(data) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/coupon/save',
    method: 'post',
    data: data
  })
}

export function deleteById(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/admin/coupon/deleteById/' + id,
    method: 'get'
  })
}

export function findById(id) {
  return request({
    url: API_BASE_URL_MEMBER + '/public/api/findCouponById/' + id,
    method: 'get'
  })
}

export function adminFoundAllCouponLog(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/coupon/log' + param,
    method: 'post',
    data: query
  })
}

export function adminSendCoupon(data) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/coupon/adminSendCoupon',
    method: 'post',
    data: data
  })
}

export function deleteAllCoupons(data) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/coupon/deleteAll',
    method: 'post'
  })
}
