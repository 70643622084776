import { render, staticRenderFns } from "./index.vue?vue&type=template&id=739dc2c4"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=739dc2c4&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("W:\\project\\HK\\twdcettest-onlinetraining-dev\\mall-admin-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('739dc2c4')) {
      api.createRecord('739dc2c4', component.options)
    } else {
      api.reload('739dc2c4', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=739dc2c4", function () {
      api.rerender('739dc2c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/productItem/components/productForm/index.vue"
export default component.exports