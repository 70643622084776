import request from '@/utils/request'
import { API_BASE_URL_PRODUCT } from '../Config'

export function saveOrUpdate(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/brand/saveOrUpdate',
    method: 'post',
    params: query
  })
}

export function deleteBrand(id) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/brand/delete/' + id,
    method: 'delete'
  })
}

export function searchList(param, query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/brand/list' + param,
    method: 'post',
    data: query
  })
}

export function toEdit(query) {
  return request({
    url: API_BASE_URL_PRODUCT + '/api/admin/brand/toEdit/'+query,
    method: 'get'
  })
}
