var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-form",
              {
                staticClass: "search-form-inline",
                attrs: {
                  "label-suffix": ":",
                  "label-position": "left",
                  "hide-required-asterisk": "",
                  "label-width": "75px",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xl: 4, md: 6, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("attributeGroup.name") } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("COMPONENTS.incontent"),
                              },
                              model: {
                                value: _vm.query.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "name", $$v)
                                },
                                expression: "query.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xl: 4, md: 6, sm: 12, xs: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("attributeGroup.oneNmu") } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "40%" },
                              model: {
                                value: _vm.query.oneNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "oneNum", $$v)
                                },
                                expression: "query.oneNum",
                              },
                            }),
                            _vm._v("\n              ~\n              "),
                            _c("el-input", {
                              staticStyle: { width: "40%" },
                              model: {
                                value: _vm.query.towNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query, "towNum", $$v)
                                },
                                expression: "query.towNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.checkPermission("attributeGroup"),
                        },
                        on: { click: _vm.loadData },
                      },
                      [_vm._v(_vm._s(_vm.$t("COMPONENTS.search")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.checkPermission("attributeGroup.add"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.groupAdd()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("COMPONENTS.add")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                "tooltip-effect": "dark",
                "header-row-class-name": "table-header",
                size: "mini",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("attributeGroup.Attribute-group-name"),
                  prop: "name",
                  width: "600px",
                  align: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("attributeGroup.oneNmu"),
                  prop: "num",
                  align: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "40px", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("COMPONENTS.operation"),
                  width: "300px",
                  align: "left",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: _vm.checkPermission(
                                "attributeGroup.edit"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.dltedit(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("COMPONENTS.edit")))]
                        ),
                        _vm._v(" "),
                        scope.row.copy
                          ? _c(
                              "el-button",
                              {
                                staticClass: "filter-item",
                                attrs: {
                                  size: "mini",
                                  disabled: _vm.checkPermission(
                                    "attributeGroup.copy"
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.dltcopy(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.copy")))]
                            )
                          : _c(
                              "el-button",
                              {
                                staticClass: "filter-item",
                                attrs: {
                                  size: "mini",
                                  disabled:
                                    _vm.checkPermission("attributeGroup.add"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.dltsave(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                            ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              size: "mini",
                              disabled: _vm.checkPermission(
                                "attributeGroup.delete"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.dltId(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("COMPONENTS.delete")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticClass: "hidden-xs-only",
                attrs: {
                  total: _vm.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.loadData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "el-col",
            [
              _c("pagination", {
                staticClass: "hidden-sm-and-up",
                attrs: {
                  "hide-on-single-pag": _vm.total > 0,
                  total: _vm.total,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  "pager-count": 3,
                  layout: "pager, prev, next",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.loadData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }