<template>
  <div class="app-container">
    <div class="filter-container">
      <transition name="el-zoom-in-top">
        <div class="filter-container">
          <el-form
            class="search-form-inline"
            label-suffix=":"
            label-position="left"
            hide-required-asterisk
            label-width="120px"
          >
            <el-row :gutter="20">
              <el-col :xl="6" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('COMPONENTS.shopName')">
                  <el-select v-model="listQuery.fromId" clearable style="width: 100%;">
                    <el-option
                      v-for="item in shopList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl="6" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('Page Code')">
                  <el-input
                    v-model="listQuery.useageTypeCode"
                    clearable
                    class="filter-item"
                  />
                </el-form-item>
              </el-col>
              <el-col :xl="6" :md="8" :sm="12" :xs="24">
                <el-form-item :label="$t('COMPONENTS.title')">
                  <el-input
                    v-model="listQuery.title"
                    clearable
                    class="filter-item"
                    :placeholder="$t('rule.Please-enter-title')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>

              <div style="float: right;">
                <el-button :disabled="checkPermission('PAGE.ADD')" @click="handleCreate">{{ $t('COMPONENTS.add') }}</el-button>
                <el-button type="primary" :disabled="checkPermission('PAGE')" @click="handleFilter">{{ $t('COMPONENTS.search') }}</el-button>
              </div>

            </el-row>
          </el-form>
        </div>
      </transition>

    </div>

    <!-- 查询结果 -->
    <el-table
      :data="list"
      size="small"
      border
      fit
      highlight-current-row
      header-row-class-name="table-header"
      tooltip-effect="dark"
    >

      <el-table-column :label="$t('COMPONENTS.shopName')" prop="FromId">
        <template slot-scope="{ row }">
          <span>{{ renderShopName(shopList, row.fromId) }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Page Code')" prop="useageTypeCode" />
      <el-table-column :label="$t('COMPONENTS.title')" prop="title" />
      <el-table-column :label="$t('productItem.lastModifiedDate')" prop="lastModifiedDate" width="150px" />
      <el-table-column :label="$t('COMPONENTS.operation')" width="300px" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="checkPermission('PAGE.EDIT')"
            @click="handleUpdate(scope.row)"
          >{{ $t('COMPONENTS.edit') }}</el-button>
          <el-button
            size="mini"
            :disabled="checkPermission('PAGE.DELETE')"
            @click="handleDelete(scope.row)"
          >{{ $t('COMPONENTS.delete') }}</el-button>
          <el-button
            size="mini"
            @click="copyLink(scope.row)"
          >{{ $t('COMPONENTS.copyLink') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.size"
      @pagination="getList"
    />

    <el-tooltip placement="top" :content="$t('COMPONENTS.return-top')">
      <back-to-top :visibility-height="100" />
    </el-tooltip>
  </div>
</template>

<style>
.table-expand {
  font-size: 0;
}
.table-expand label {
  width: 100px;
  color: #99a9bf;
}
.table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
}
.gallery {
  width: 80px;
  margin-right: 10px;
}
.demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>

<script>
import { searchArticleList, articleSave, deleteArticleById } from '@/api/articleApi'
// import { quillEditor } from 'vue-quill-editor'
import { shopList } from '@/api/shop'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import BackToTop from '@/components/BackToTop'
import Pagination from '@/components/Pagination' // Secondary package based on el-pagination
import { checkCfsPermission } from '@/utils/permission'
export default {
  name: 'ArticleList',
  components: { BackToTop, Pagination },
  data() {
    return {
      shopList: [],
      dialogStatus: '',
      textMap: {
        update: this.$t('COMPONENTS.edit'),
        create: this.$t('COMPONENTS.add')
      },
      dataForm: {
        id: '',
        title: '',
        useageTypeCode: '',
        description: '',
        contentInfo: '',
        status: '1',
        score: 5,
        shopId: ''
      },
      dialogFormVisible: false,
      content: '',
      editorOption: {},
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 10,
        title: undefined,
        shopId: '',
        useageTypeCode: '',
        desc: '',
        sort: 'add_time',
        order: 'desc'
      },
      rules: {
        title: [{ required: true, message: this.$t('rule.Title-cannot-be-empty'), trigger: 'blur' }],
        type: [{ required: true, message: this.$t('rule.Type-cannot-be-empty'), trigger: 'blur' }],
        contentInfo: [{ required: true, message: this.$t('rule.Content-cannot-be-empty'), trigger: 'blur' }],
        status: [{ required: true, message: this.$t('rule.State-cannot-be-empty'), trigger: 'blur' }],
        score: [{ required: true, message: this.$t('rule.Numerical-cannot-be-empty'), trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getList()
    this.getShop()
  },
  methods: {
    renderShopName(shopList, fromId) {
      let result = fromId

      if (shopList && shopList.length !== 0) {
        shopList.map(item => {
          if (item.id === fromId) {
            result = item.name
          }
        })
      }
      return result
    },
    checkPermission(value) {
      return checkCfsPermission(value)
    },
    createData() {
      var article = this.dataForm
      console.info('+++', article)
      articleSave(article)
        .then((res) => {
          this.dialogFormVisible = false
          this.$message.success(this.$t('COMPONENTS.save_Success'))
          this.getList()
        })
        .catch(() => {
          this.$message.error(this.$t('COMPONENTS.save_Filad'))
        })
    },
    getShop() {
      shopList().then((res) => {
        this.shopList = res.data
        console.info('+++++', this.shopList)
      })
    },
    getList() {
      var quary = { ...this.listQuery }
      quary.page = this.listQuery.page - 1
      searchArticleList(quary)
        .then((response) => {
          this.list = response.data
          this.total = response.pageCount
        })
        .catch(() => {
          this.list = []
          this.total = 0
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleCreate() {
      this.$router.push({ path: 'pageAdd', query: { headerTxt: 'Add Page' }})
    },
    handleUpdate(row) {
      this.$router.push({ path: 'pageEdit', query: { headerTxt: 'Edit Page', id: row.id }})
    },

    handleDelete(row) {
      const id = row.id
      this.$confirm(this.$t('COMPONENTS.delete_Title'), this.$t('COMPONENTS.tip'), {
        confirmButtonText: this.$t('COMPONENTS.sure'),
        cancelButtonText: this.$t('COMPONENTS.cancel'),
        type: 'warning'
      }).then(() => {
        deleteArticleById(id).then((response) => {
          if (response) {
            this.$notify.success({
              title: this.$t('COMPONENTS.success'),
              message: this.$t('COMPONENTS.delete_Success')
            })
            this.$router.go(0)
          }
        })
      })
    },
    copyLink(row) {
      let link = '/page/commonPage?'
      link += 'fromId=' + row.fromId
      link += '&useageTypeCode=' + row.useageTypeCode
      const msg = this.$t('Copy Success')
      navigator.clipboard.writeText(link).then(() => {
        alert(msg)
      })
    }
  }
}
</script>
