var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 10,
            "sticky-bottom": 100,
            "class-name": "sub-navbar",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "header", attrs: { span: 12 } }, [
                _vm._v(_vm._s(_vm.$t(_vm.headerTxt)) + " "),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.setting()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.translate")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.return")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "success",
                        disabled: _vm.checkPermission("PAGE.EDIT"),
                      },
                      on: { click: _vm.createData },
                    },
                    [_vm._v(_vm._s(_vm.$t("COMPONENTS.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticClass: "search-form-inline",
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "status-icon": "",
            "label-position": "left",
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xl: 24 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.title"),
                                    prop: "title",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataForm.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "title", $$v)
                                      },
                                      expression: "dataForm.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Page Code"),
                                    prop: "useageTypeCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataForm.useageTypeCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "useageTypeCode",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.useageTypeCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.shopName"),
                                    prop: "fromId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "rule.Please-select-shop"
                                        ),
                                      },
                                      model: {
                                        value: _vm.dataForm.fromId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "fromId", $$v)
                                        },
                                        expression: "dataForm.fromId",
                                      },
                                    },
                                    _vm._l(_vm.shopList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.desc"),
                                    prop: "description",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataForm.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("COMPONENTS.state"),
                                    prop: "status",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1" },
                                      model: {
                                        value: _vm.dataForm.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "status", $$v)
                                        },
                                        expression: "dataForm.status",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("COMPONENTS.visible"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "0" },
                                      model: {
                                        value: _vm.dataForm.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "status", $$v)
                                        },
                                        expression: "dataForm.status",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("COMPONENTS.invisible"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.loaded
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("COMPONENTS.content"),
                                        prop: "contentInfo",
                                      },
                                    },
                                    [
                                      _c("Summernote", {
                                        attrs: {
                                          value: _vm.dataForm.contentInfo,
                                        },
                                        on: { change: _vm.changeContentInfo },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("br"),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "card-box" },
        [
          _c(
            "el-drawer",
            {
              staticClass: "translate-drawer",
              attrs: {
                title: _vm.$t("COMPONENTS.translate"),
                visible: _vm.drawer,
                size: "60%",
                "show-close": false,
                "modal-append-to-body": false,
                "append-to-body": false,
                "destroy-on-close": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
                opened: _vm.getIframe,
              },
            },
            [
              _vm.drawer
                ? _c("iframe", {
                    ref: "iframe",
                    staticClass: "iframe-class",
                    attrs: {
                      src: _vm.ifreamSrc,
                      height: _vm.getClientHeight(),
                    },
                  })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }